import { base64EmptyV1, base64EmptyV2 } from "./base64";
import renderBOScreens from "./renderBoScreens";
import renderButtonScreens from "./renderButtonScreens";
import renderLabelScreens from "./renderLabelScreens";
import addListOneToManyScreens from "./list/oneToMany/addListOneToManyScreens";
import updateListOneToManyScreens from "./list/oneToMany/updateListOneToManyScreens";
import renderImageScreens from "./renderImageScreens";
import { BO, BUTTON, ICON, IMAGE, LABEL } from "src/constants/PageConfig";
import renderIconScreens from "./renderIconScreens";

export default function renderListContainer(
  mainContainer,
  screenElement,
  screen,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page
) {
  if (pageName == "mainPage") {
    renderListContainerMain(
      mainContainer,
      screenElement,
      screen,
      maxColumn,
      filehubUrlImage,
      screensEditWithPosition,
      JsonCONFIGSCREEN,
      pageName,
      page
    );
  } else if (pageName == "addPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      addListOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      addListScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName
      );
    }
  } else if (pageName == "editPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      updateListOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      updateListScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName
      );
    }
  }
}

// สร้าง form search
function createSearchForm(screen) {
  const searchForm = document.createElement("form");
  searchForm.id = "search-form-" + screen.configType + "-" + screen.key;
  searchForm.className = "search-form";

  const searchIcon = document.createElement("i");
  searchIcon.className = "anticon anticon-search";
  searchIcon.style.position = "absolute";
  searchIcon.style.marginLeft = "5px";
  searchIcon.style.color = "rgba(171, 171, 171, 1)";

  const clearIcon = document.createElement("i");
  clearIcon.className = "fas fa-times clear-icon";
  clearIcon.style.display = "none";

  const searchButton = document.createElement("button");
  searchButton.id = "searchButton-" + screen.configType + "-" + screen.key;
  searchButton.setAttribute("type", "button");
  searchButton.textContent = "Search";
  searchButton.className = "ant-btn ant-btn-primary search-button";

  let searchInput = `
        <input 
            placeholder="Search..." 
            type="text" 
            id="search-input-${screen.configType}-${screen.key}"
            class="search-input dt-search" 
            pg-state="State.search"
        >
    `;

  searchForm.insertAdjacentHTML("beforeend", searchInput);
  searchForm.appendChild(searchIcon);
  searchForm.appendChild(clearIcon);
  searchForm.appendChild(searchButton);

  return searchForm;
}

function renderListContainerMain(
  mainContainer,
  screenElement,
  screen,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page
) {
  let addPageName = page.addPage.pageName;

  let gridColumn = `1 / span ${maxColumn}`;
  screenElement.style.zIndex = 9;
  screenElement.style.gridColumn = gridColumn;

  // สร้าง Search Form
  const searchForm = createSearchForm(screen);
  screenElement.appendChild(searchForm);

  // สร้าง Add button
  const contentAddButton = document.createElement("div");
  contentAddButton.id = "box-content-add-button-" + screen.key;
  contentAddButton.className = "content-add-button";

  let addBtnText = `<div id="content-add-button-${screen.key}">
     <a id="add-btn-icon-${screen.key}" pg-ref="${addPageName}">
         <span class="ow ion-android-add-circle span-0bccf55774-responsive btn-icon"  style="font-size: 30px;cursor: pointer;"></span>
     </a></div>`;

  contentAddButton.insertAdjacentHTML("beforeend", addBtnText);
  screenElement.appendChild(contentAddButton);

  // สร้าง List
  const listContainer = document.createElement("div");
  listContainer.id = "list-container-" + screen.configType + "-" + screen.key;
  listContainer.className = "list-container";

  let imageKey = "";
  let contentCard = "";
  let i = 0;
  screen.card.forEach((itemp) => {
    if (itemp.configType == "image_input") {
      imageKey = itemp.key;
    } else {
      i = i + 1;
      let text = `${itemp.key} Text`;
      let weight = "bold";
      // let marginTop ="0px;"
      if (i == 1) {
        // text = "Head Text";
        // weight = "bold";
        // marginTop ="20px;"
      }
      contentCard =
        contentCard +
        `
            <div class="text-container"> 
                <span class="ng-binding" pg-store="${itemp.key}"
                style="font-weight: ${weight};"
                pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list">${text}</span>
                <div class="text-info" id="div7" title="${itemp.key}"> 
                    <span 
                    style="font-size: 14px; color: #979797; top: -5px; position: relative;" 
                    id="text5" 
                    class="ng-binding">${itemp.key}</span>
                </div>
            </div >         
            `;
    }
  });

  let base64Empty = base64EmptyV1;
  var listContentText = `
    
    <div id="list-content-${screen.configType}-${screen.key}">
        <div class="card ng-scope"  style="cursor:pointer;display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;" id="card-${screen.configType}-${screen.key}-{{$index}}"  pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;]" pg-trackby="$index"
            pg-store="${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list" pg-is-list="true" >
            <div class="card-content card-list-card" id="card-content-${screen.configType}-${screen.key}-{{$index}}">
                <div class="imgContent-list" ><img  class="image-list"
                    style=" 
                        background: url(${base64Empty}) center center / cover no-repeat; 
                        background: url(&quot;{{SCOPE_URL_IMAGE}}{{Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list[$index].${imageKey}}}&quot;) center center / cover no-repeat, url(${base64Empty}) center center / cover no-repeat; 
                    ">
                </div>
                <div class="list-text">
                    ${contentCard}
                </div>
            </div>
            <div id="button-remove-${screen.configType}_{{$index}}"  style="display: flex;
    align-items: flex-end;
    padding-right: 10px;
    padding-left: 10px;"><span style="font-size: 25px;" class="ow ion-android-delete btn-icon" id="text9"></span></div>
        </div>
    </div>
   
        `;

  var paginationContainer = ` <div id="pagination-container-${screen.configType}-${screen.key}" class="pagination-container">
    <span
        id="item-count-text-${screen.configType}-${screen.key}" class="item-count-text">
        </span>
        <div id="pagination-button-${screen.configType}-${screen.key}" class="pagination-button-content">
          <button id="previous-button-${screen.configType}-${screen.key}" class="pagination-button previous-button">Previous</button>
          <button id="next-button-${screen.configType}-${screen.key}" class="pagination-button next-button">Next</button>
        </div>
      </div>`;

  listContainer.insertAdjacentHTML("beforeend", listContentText);
  screenElement.appendChild(listContainer);

  screenElement.insertAdjacentHTML("beforeend", paginationContainer);
}

function addListScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-add-list-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-container-add-Wrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "add");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "add", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
}

function updateListScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-edit-list-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-containerWrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "edit");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "edit", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
}
