import React from "react";

const StoryEditComponentComputer = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      className={className}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 0.667969H3.33333C2.4496 0.669027 1.60237 1.02056 0.97748 1.64545C0.352588 2.27034 0.00105857 3.11757 0 4.0013L0 9.33464C0.00105857 10.2184 0.352588 11.0656 0.97748 11.6905C1.60237 12.3154 2.4496 12.6669 3.33333 12.668H7.33333V14.0013H4.66667C4.48986 14.0013 4.32029 14.0715 4.19526 14.1966C4.07024 14.3216 4 14.4912 4 14.668C4 14.8448 4.07024 15.0143 4.19526 15.1394C4.32029 15.2644 4.48986 15.3346 4.66667 15.3346H11.3333C11.5101 15.3346 11.6797 15.2644 11.8047 15.1394C11.9298 15.0143 12 14.8448 12 14.668C12 14.4912 11.9298 14.3216 11.8047 14.1966C11.6797 14.0715 11.5101 14.0013 11.3333 14.0013H8.66667V12.668H12.6667C13.5504 12.6669 14.3976 12.3154 15.0225 11.6905C15.6474 11.0656 15.9989 10.2184 16 9.33464V4.0013C15.9989 3.11757 15.6474 2.27034 15.0225 1.64545C14.3976 1.02056 13.5504 0.669027 12.6667 0.667969ZM3.33333 2.0013H12.6667C13.1971 2.0013 13.7058 2.21202 14.0809 2.58709C14.456 2.96216 14.6667 3.47087 14.6667 4.0013V8.66797H1.33333V4.0013C1.33333 3.47087 1.54405 2.96216 1.91912 2.58709C2.29419 2.21202 2.8029 2.0013 3.33333 2.0013ZM12.6667 11.3346H3.33333C2.92116 11.3329 2.51958 11.2039 2.18353 10.9652C1.84749 10.7266 1.59339 10.3899 1.456 10.0013H14.544C14.4066 10.3899 14.1525 10.7266 13.8165 10.9652C13.4804 11.2039 13.0788 11.3329 12.6667 11.3346Z"
        fill="#374957"
      />
    </svg>
  );
};
export default StoryEditComponentComputer;
