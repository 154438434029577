import React from "react";
import {Layout} from "antd";
import {useTranslation} from "react-i18next";

import styles from "./UIFirstPage.module.scss";

const { Header, Footer, Sider, Content } = Layout;

export default function LogoLoading() {
  return (
    <div className="logo-loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
        viewBox="0 0 4267 4267"
        width="15em"
        height="15em"
      >
        <path d="M3622 1547a1561 1561 0 10-24 1223 202 202 0 00-368-166 1155 1155 0 01-2198-301 1157 1157 0 012215-605 202 202 0 00375-151z">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="-50 2133.5 2133.5"
            to="0 2133.5 2133.5"
            dur="0.6s"
          />
        </path>
        <path d="M2456 2303a335 335 0 11-9-360 269 269 0 00446-301 871 871 0 00-1597 488 873 873 0 001621 451 269 269 0 00-461-278z">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="50 2133.5 2133.5"
            to="0 2133.5 2133.5"
            dur="0.5s"
          />
        </path>
      </svg>
    </div>
  );
}
export function LogoLoadingPage(props:any) {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        height: "100vh",
        fontSize: "20px",
      }}
    >
      <div className={styles.boxIcon} style={{ width: "100%" }}>
        <div className={styles.boxIconCenter}>
          <LogoLoading  />
        </div>
      </div>
    </div>
  );
}

export function LogoLoadingModal(props:any) {
  return <LogoLoading />;
}
