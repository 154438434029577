import React from "react";

const StoryEditEntGenPageArrowDown = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 14"
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.912 4.78974C10.8578 4.73506 10.7933 4.69166 10.7222 4.66205C10.6511 4.63243 10.5749 4.61719 10.4979 4.61719C10.4209 4.61719 10.3446 4.63243 10.2735 4.66205C10.2024 4.69166 10.1379 4.73506 10.0837 4.78974L7.41203 7.4614C7.3578 7.51607 7.29328 7.55947 7.2222 7.58908C7.15111 7.6187 7.07487 7.63395 6.99786 7.63395C6.92085 7.63395 6.84461 7.6187 6.77352 7.58908C6.70244 7.55947 6.63792 7.51607 6.58369 7.4614L3.91202 4.78974C3.8578 4.73506 3.79328 4.69166 3.72219 4.66205C3.65111 4.63243 3.57487 4.61719 3.49786 4.61719C3.42085 4.61719 3.34461 4.63243 3.27352 4.66205C3.20244 4.69166 3.13792 4.73506 3.08369 4.78974C2.97505 4.89903 2.91406 5.04688 2.91406 5.20099C2.91406 5.35509 2.97505 5.50294 3.08369 5.61223L5.76119 8.28973C6.08932 8.61745 6.53411 8.80152 6.99786 8.80152C7.46161 8.80152 7.9064 8.61745 8.23453 8.28973L10.912 5.61223C11.0207 5.50294 11.0817 5.35509 11.0817 5.20099C11.0817 5.04688 11.0207 4.89903 10.912 4.78974Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryEditEntGenPageArrowDown;
