import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-fetch-backend'; // fallback http load
// import moment from "moment";
// import HttpApi from "i18next-http-backend"; // fallback http load

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: 'en',
      debug: false,
      lng:"en",
      preload: ['en', 'th'],
      ns: ["translation"],
      defaultNS: 'common',
      fallbackNS: 'common',
      backend: {
        backends: [
          LocalStorageBackend, // fallback
          HttpApi, // primary
        ],
        backendOptions: [
          {
            /* below options */
            expirationTime: 12 * 60 * 60 * 1000,
            defaultVersion: '1',
            // prefix: "lang_res_",
          },
          {
            // queryStringParams: { t: new Date().getHours() },
            // parsePayload: function (namespace, key, fallbackValue) {
            //   console.log("parsePayload", namespace, key, fallbackValue);
            //   return { key };
            // },
            // request: function (options, url, payload, callback) {
            //   console.log("save missing key", url, payload);
            // },
            // path to post missing resources
            loadPath: window['env']['REACT_APP_ZERO_URL'] + `/api/v1/langs/dl/{{lng}}?ns={{ns}}`, // xhr load path for my own fallback
          },
        ],
      },
    },
    (err, t) => {
      if (err) return console.error('[ERRORi18n]', err);
    },
  );
i18n.on('languageChanged', function (lng) {
  localStorage.setItem('lang', lng);
  // moment.locale(lng);
});

export default i18n;
