export const UPLOAD_LIBS = [
  // { fileName: "all.min.css", filePath: "/zero/assets/genpage-libs/all.min.css" },
  { fileName: "antd.min.css", filePath: "/zero/assets/genpage-libs/antd.min.css" },
  // { fileName: "antd.min.js", filePath: "/zero/assets/genpage-libs/antd.min.js" },
  {
    fileName: "bulma.min.css",
    filePath: "/zero/assets/genpage-libs/bulma.min.css",
  },
  {
    fileName: "dataTables.bulma.css",
    filePath: "/zero/assets/genpage-libs/dataTables.bulma.css",
  },
  {
    fileName: "uuid.min.js",
    filePath: "/zero/assets/genpage-libs/uuid.min.js",
  },
];
