import React, { useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Space, Typography } from "antd";
import styles from "../../database-connection/drawer/DrawerEditConnection.module.scss";
import { LeftOutlined } from "@ant-design/icons";
import DeleteIcon from "src/assets/svg/delete";
import {
  CreateDBConnectorsProps,
  DBConnectors,
  DBConnectorsTestProps,
  GetAllDBConnectorsByAspIDProps,
  UpdateDBConnectorsByIdProps,
} from "src/types/databaseConnectors.type";
import ModalDelete from "../modal/ModalDelete";
import { useDeleteDBConnector } from "src/hooks/database-connection/useDatabaseConnectorMutation";
import { useMutation } from "@tanstack/react-query";
import { dbConnectorsTest } from "src/services/databaseConnectors.service";
import { DatabaseConnectorsContext } from "src/page/context/DatabaseConnectors.context";

type DrawerEditConnectionProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedCard: DBConnectors | null;
  onSaveEditConfig: (formValue: any) => void;
  dbConnectorsQuery: GetAllDBConnectorsByAspIDProps;
  showModalFail: () => void;
  onDataName: (
    name: string,
    title: string,
    description: string,
    type?: string
  ) => void;
  showModalSuccess: () => void;
};

function DrawerEditConnection({
  isOpen,
  onClose,
  selectedCard,
  onSaveEditConfig,
  dbConnectorsQuery,
  showModalFail,
  onDataName,
  showModalSuccess,
}: DrawerEditConnectionProps) {
  const dbContext = useContext(DatabaseConnectorsContext);
  const deleteDBConnectorMutation = useDeleteDBConnector(dbConnectorsQuery);

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [updateDBConnector, setUpdateDBConnect] = useState<
    UpdateDBConnectorsByIdProps | undefined
  >(undefined);

  const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);
  const [form] = Form.useForm();

  function showModalDelete() {
    console.log("===== showModalDelete");
    setOpenModalDelete(true);
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  }
  function onCloseModalDelete() {
    console.log("===== onCloseModalDelete");
    setOpenModalDelete(false);
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  }

  useEffect(() => {
    setLoadingButtonSave(false);
    if (!selectedCard) return;
    console.log("selectedCard :: ", selectedCard);
    const {
      id,
      db_type,
      connection_name,
      item_name,
      db_name,
      host_name,
      host_port,
      db_username,
      db_password,
    } = selectedCard;
    if (selectedCard) {
      form.setFieldsValue({
        [`DatabaseType`]: db_type,
        [`DatabaseName`]: connection_name,
        [`Name`]: item_name,
        [`Host`]: host_name,
        [`Port`]: host_port,
        [`Username`]: db_username,
        [`Password`]: db_password,
      });
    }
  }, [selectedCard]);

  const handleTestConnection = () => {
    if (!selectedCard) return;
    console.log("handleTestConnection => ", form.getFieldsValue());
    const { DatabaseName, Name, DatabaseType, Host, Password, Port, Username } =
      form.getFieldsValue();
    form
      .validateFields()
      .then(() => {
        let asp_id = String(localStorage.getItem("asp_id"));
        const payload: UpdateDBConnectorsByIdProps = {
          id: selectedCard?.id,
          jndi_name: "",
          connection_url: "",
          connection_name: "",
          db_type: DatabaseType?.toLowerCase(),
          host_port: Port,
          host_name: Host,
          db_name: DatabaseName,
          item_name: Name,
          db_username: Username,
          db_password: Password,
          type_create: "manual",
          asp_id: asp_id,
          created_by: "admin",
          updated_by: "admin",
          is_active: true,
        };
        setUpdateDBConnect(payload);
        dbTestMutation.mutate(payload);
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const dbTestMutation = useMutation({
    mutationFn: dbConnectorsTest,
    onSuccess: () => {
      const values = form.getFieldsValue();
      onDataName(
        values.ConnectionName,
        "Connected",
        "Test Connection Successful"
      );
      showModalSuccess();
      dbContext.setIsTestConnectionPass(true);
    },
    onError: () => {
      const values = form.getFieldsValue();
      onDataName(
        values.ConnectionName,
        "Connected Fail",
        "Please try to connect again."
      );
      showModalFail();
      dbContext.setIsTestConnectionPass(false);
    },
  });

  const values = Form.useWatch([], form);
  useEffect(() => {
    dbContext.setIsTestConnectionPass(false);
  }, [values]);

  const onClickButtonSave = () => {
    form.submit();
    setLoadingButtonSave(true);
  };

  return (
    <>
      <Drawer
        title={
          <div className={styles.title}>
            Config Database
            <div className={styles.cursor} onClick={() => showModalDelete()}>
              <DeleteIcon />
            </div>
          </div>
        }
        closeIcon={<LeftOutlined />}
        onClose={onClose}
        open={isOpen}
        footer={[
          <Button
            key="cancel"
            onClick={handleTestConnection}
            className={styles.buttonStyle}
          >
            Test Connection
          </Button>,
          <Button
            disabled={!dbContext.isTestConnectionPass}
            key="ok"
            type="primary"
            onClick={onClickButtonSave}
            className={styles.buttonStyle}
            loading={loadingButtonSave}
          >
            Save
          </Button>,
        ]}
      >
        <div className={styles.formContainer}>Database Information</div>
        <Form
          form={form}
          layout="vertical"
          onFinish={() => onSaveEditConfig(updateDBConnector)}
          autoComplete="off"
        >
          <span className={styles.labelContainer}>
            Database Type
            <span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item name="DatabaseType" className={styles.formItem}>
            <Input
              className={styles.inputField}
              value={form.getFieldValue("DatabaseType")}
              disabled
            />
          </Form.Item>

          <span className={styles.labelContainer}>
            Name<span className={styles.requiredIndicator}>*</span>
          </span>

          <Form.Item
            name="Name"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <Input className={styles.inputField} />
          </Form.Item>

          <span className={styles.labelContainer}>
            Database Name<span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item
            name="DatabaseName"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <Input className={styles.inputField} />
          </Form.Item>

          <span className={styles.labelContainer}>
            Host
            <span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item
            name="Host"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <Input className={styles.inputField} />
          </Form.Item>

          <span className={styles.labelContainer}>
            Port
            <span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item
            name="Port"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <Input className={styles.inputField} />
          </Form.Item>

          <div className={styles.authenticationTitle}>Authentication</div>

          <span className={styles.labelContainer}>
            Username
            <span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item
            name="Username"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <Input className={styles.inputField} />
          </Form.Item>

          <span className={styles.labelContainer}>
            Password
            <span className={styles.requiredIndicator}>*</span>
          </span>
          <Form.Item
            name="Password"
            rules={[{ required: true }]}
            className={styles.passwordField}
          >
            <Input.Password className={styles.inputField} />
          </Form.Item>
        </Form>
      </Drawer>

      <ModalDelete
        title="Delete Database ?"
        description="Are you sure want to delete this database?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => onCloseModalDelete()}
        onOk={() => {
          if (!selectedCard) return;
          deleteDBConnectorMutation.mutate(
            { id: selectedCard?.id },
            {
              onSuccess: () => {
                onCloseModalDelete();
                onClose();
              },
            }
          );
        }}
        open={openModalDelete}
      />
    </>
  );
}
export default DrawerEditConnection;
