export function decodeBase64ToObject(decodeBase64: string) {
    if(!decodeBase64) return;

    const decodedString = atob(decodeBase64);
    const parsedObject = JSON.parse(decodedString);
    return parsedObject;
}

// ฟังก์ชันสำหรับสร้างเลขและอักษรสุ่ม
export const generateRandomSuffix = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    const randomNumber = Math.floor(Math.random() * 1000); // สร้างเลขสุ่มตั้งแต่ 0 ถึง 999
    return `${randomLetter}${randomNumber}`;
  };