import React from "react";

const StoryEditEntAddMoreSetting = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "34"}
      height={height || "34"}
      className={className}
      style={style}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="33" height="33" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="33" height="33" rx="3.5" stroke="#E9E9E9" />
      <path
        d="M18.2 12.2C18.2 12.0424 18.169 11.8864 18.1087 11.7408C18.0483 11.5952 17.96 11.4629 17.8485 11.3515C17.7371 11.24 17.6048 11.1516 17.4592 11.0913C17.3136 11.031 17.1576 11 17 11C16.6817 11 16.3765 11.1264 16.1515 11.3515C15.9264 11.5765 15.8 11.8817 15.8 12.2V15.8H12.2C11.8817 15.8 11.5765 15.9264 11.3515 16.1515C11.1264 16.3765 11 16.6817 11 17C11 17.3183 11.1264 17.6235 11.3515 17.8485C11.5765 18.0736 11.8817 18.2 12.2 18.2H15.8V21.8C15.8 22.1183 15.9264 22.4235 16.1515 22.6485C16.3765 22.8736 16.6817 23 17 23C17.3183 23 17.6235 22.8736 17.8485 22.6485C18.0736 22.4235 18.2 22.1183 18.2 21.8V18.2H21.8C22.1183 18.2 22.4235 18.0736 22.6485 17.8485C22.8736 17.6235 23 17.3183 23 17C23 16.6817 22.8736 16.3765 22.6485 16.1515C22.4235 15.9264 22.1183 15.8 21.8 15.8H18.2V12.2Z"
        fill="#1875F0"
      />
    </svg>
  );
};
export default StoryEditEntAddMoreSetting;
