import React from "react";

const StoryEditEntAdd = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      viewBox="0 0 17 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.45 3.2C9.45 3.04241 9.41896 2.88637 9.35865 2.74078C9.29835 2.59519 9.20996 2.4629 9.09853 2.35147C8.9871 2.24004 8.85481 2.15165 8.70922 2.09134C8.56363 2.03104 8.40759 2 8.25 2C7.93174 2 7.62652 2.12643 7.40147 2.35147C7.17643 2.57651 7.05 2.88174 7.05 3.2V6.8H3.45C3.13174 6.8 2.82651 6.92643 2.60147 7.15147C2.37643 7.37652 2.25 7.68174 2.25 8C2.25 8.31826 2.37643 8.62348 2.60147 8.84853C2.82651 9.07357 3.13174 9.2 3.45 9.2H7.05V12.8C7.05 13.1183 7.17643 13.4235 7.40147 13.6485C7.62652 13.8736 7.93174 14 8.25 14C8.56826 14 8.87348 13.8736 9.09853 13.6485C9.32357 13.4235 9.45 13.1183 9.45 12.8V9.2H13.05C13.3683 9.2 13.6735 9.07357 13.8985 8.84853C14.1236 8.62348 14.25 8.31826 14.25 8C14.25 7.68174 14.1236 7.37652 13.8985 7.15147C13.6735 6.92643 13.3683 6.8 13.05 6.8H9.45V3.2Z"
        fill="#1875F0"
      />
    </svg>
  );
};
export default StoryEditEntAdd;
