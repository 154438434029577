import { Avatar, Breadcrumb, Menu, Layout, theme } from "antd";
import React, {
  useState,
  useDeferredValue,
  Fragment,
  useEffect,
  useContext,
} from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { pathRoutes } from "../props/util.props";
import _ from "lodash";
import { AuthKeys, checkEmpty } from "src/services/util.service";

const { Header, Content, Footer, Sider } = Layout;

function LoadDashboardPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token: any = urlParams.get("token");
    const asp_id: any = urlParams.get("asp_id");

    if (!checkEmpty(asp_id)) {
      localStorage.setItem("asp_id", asp_id);
    }
    if (!checkEmpty(token)) {
      localStorage.setItem(AuthKeys.token, token);

      // ลบ parameter token ออกจาก URL
      const newUrl = window.location.href.split('?')[0]; // เอาเฉพาะ path ไม่รวม query parameters
      window.history.replaceState({}, document.title, newUrl);
    }
    console.log("loading token...");
    navigate(`/${pathRoutes.MainDashboard}`)
  }, []);



  return (
    <>

    </>
  );
}

export default LoadDashboardPage;
