export let jsText = `
function init(){
    console.log("initMenu")

    // event เปิด/ปิด เมนู
    let toggleCollap = document.getElementsByClassName("layout-sider-trigger")
    toggleCollap[0].addEventListener("click" , () => {
        let el = document.getElementsByClassName("vertical");
        for (let i = 0; i < el.length; i++) {
            let element = el[i];
            element.classList.toggle("collap");
            if (element.classList.contains("collap")) {
                element.style.maxWidth = '200px';
                element.style.minWidth = '200px';
                element.style.width = '200px';
                element.style.flex = '0 0 200px';
                
            } else {
                element.style.maxWidth = '';
                element.style.minWidth = '';
                element.style.width = '';
                element.style.flex = '';
            }
        }
    })
}

`;