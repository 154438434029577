import React, { useState, useEffect, useContext } from "react";
import { Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "./Login.module.scss";
import TextChild from "./TextChild";
import axios from "axios";

import { useTranslation } from "react-i18next";
import { AppContext } from "../context/App.context";
import { CONFIG_SCREEN, generatePageFile } from "src/services/page-service";
function Login(props) {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [isValid, setValid] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [data, setData] = useState("waitBackEbd");
  const context = useContext(AppContext);
  let usernameValid = "Test";
  let passwordValid = "Password";

  const openNotification = () => {
    notification.warning({
      message: `Login`,
      description: `Username or Password not valid!`,
      placement: "bottomRight",
    });
  };

  const clickLogin = () => {
    if (username === usernameValid && passwordValid === password) {
      setValid(true);
      context.setUsername(username);
      context.setPassword(password);
      props.history.push("/profile", { name: "Jack" });
    } else {
      setValid(false);
      openNotification();
    }
  };
  const getBackend = async () => {
    let response = await axios.get(
      window['env']['REACT_APP_ZERO_URL'] + `/api/v1/object-access/get-object-access`
    );
    console.log("response", response);
    setData(response.data.data);
  };

  useEffect(() => {
    getBackend();
    generatePageFile(CONFIG_SCREEN)
  }, []);

  return (
    <div>
      <div style={{ textAlign: "-webkit-center" }}>
        <h2>Login</h2>
        <div style={{ width: "50%" }}>
          <Input
            placeholder="username"
            prefix={<UserOutlined />}
            style={{ borderRadius: "5px" }}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input.Password
            placeholder="Password"
            style={{ borderRadius: "5px", marginTop: "10px" }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            onClick={clickLogin}
            type="primary"
            className={styles.btnLogin}
          >
            Login
          </Button>
        </div>
      </div>
      <div>
        <div>
          <div style={{ marginTop: 50, float: "left" }}>
            <div>Username : {username}</div>
            <div>Password : {password}</div>
            <div>Data from back-end : {data}</div>

            {isValid == false && (
              <div style={{ marginTop: 15, color: "red" }}>
                <div style={styles.textRecommendHeader}>Please use this</div>
                <div>
                  <div style={styles.textRecommend}>
                    Username : {usernameValid}
                  </div>
                </div>
                <div>
                  <div style={styles.textRecommend}>
                    Password : {passwordValid}
                  </div>
                </div>
              </div>
            )}

            <div>Start List</div>
            {/* {ListValues?.map((item, index) => { */}
            {[{ name: "t1" }, { name: "t2" }]?.map((item, index) => {
              return (
                <div
                  key={"ListValues" + index}
                  style={{ margin: 15, borderColor: "red", borderWidth: 1 }}
                >
                  <div>index:{index}</div>
                  <TextChild {...props} usernameValid={item.name}></TextChild>
                </div>
              );
            })}

            <Button
              onClick={() => {
                let v = "th";
                if (i18n.language == "th") v = "en";
                i18n.changeLanguage(v);
              }}
            >
              <div>{t("Change Language")}</div>
            </Button>

            <div>{t("Just Test")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
