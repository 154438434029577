import React from "react";

const StoryEditEntHistory = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 16 16"
      width={width || "16"}
      height={height || "16"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5448_14857)">
        <path
          d="M8 0C6.03034 0.00140425 4.13075 0.731081 2.66667 2.04867V0.666667C2.66667 0.489856 2.59643 0.320286 2.4714 0.195262C2.34638 0.0702379 2.17681 0 2 0C1.82319 0 1.65362 0.0702379 1.5286 0.195262C1.40357 0.320286 1.33333 0.489856 1.33333 0.666667V2.66667C1.33333 3.1971 1.54405 3.70581 1.91912 4.08088C2.29419 4.45595 2.8029 4.66667 3.33333 4.66667H5.33333C5.51015 4.66667 5.67971 4.59643 5.80474 4.47141C5.92976 4.34638 6 4.17681 6 4C6 3.82319 5.92976 3.65362 5.80474 3.5286C5.67971 3.40357 5.51015 3.33333 5.33333 3.33333H3.33333C3.31085 3.33 3.28859 3.32533 3.26667 3.31933C4.35301 2.22522 5.78542 1.54205 7.31938 1.38644C8.85333 1.23084 10.3938 1.61244 11.6777 2.46611C12.9616 3.31978 13.9095 4.5926 14.3595 6.0673C14.8094 7.54201 14.7337 9.12717 14.1451 10.5522C13.5564 11.9773 12.4915 13.1539 11.132 13.8812C9.77246 14.6085 8.20266 14.8414 6.69054 14.5401C5.17843 14.2389 3.81772 13.4222 2.8407 12.2295C1.86368 11.0367 1.33091 9.54183 1.33333 8C1.33333 7.82319 1.2631 7.65362 1.13807 7.5286C1.01305 7.40357 0.843478 7.33333 0.666667 7.33333C0.489856 7.33333 0.320286 7.40357 0.195262 7.5286C0.0702379 7.65362 0 7.82319 0 8C0 9.58225 0.469192 11.129 1.34824 12.4446C2.22729 13.7602 3.47672 14.7855 4.93853 15.391C6.40034 15.9965 8.00887 16.155 9.56072 15.8463C11.1126 15.5376 12.538 14.7757 13.6569 13.6569C14.7757 12.538 15.5376 11.1126 15.8463 9.56072C16.155 8.00887 15.9965 6.40034 15.391 4.93853C14.7855 3.47672 13.7602 2.22729 12.4446 1.34824C11.129 0.469192 9.58225 0 8 0V0Z"
          fill="#ABABAB"
        />
        <path
          d="M8.0026 4C7.82579 4 7.65622 4.07024 7.5312 4.19526C7.40618 4.32029 7.33594 4.48986 7.33594 4.66667V8C7.33598 8.1768 7.40624 8.34634 7.53127 8.47133L9.53127 10.4713C9.65701 10.5928 9.82541 10.66 10.0002 10.6584C10.175 10.6569 10.3422 10.5868 10.4658 10.4632C10.5894 10.3396 10.6595 10.1724 10.6611 9.9976C10.6626 9.8228 10.5954 9.6544 10.4739 9.52867L8.66927 7.724V4.66667C8.66927 4.48986 8.59903 4.32029 8.47401 4.19526C8.34898 4.07024 8.17942 4 8.0026 4Z"
          fill="#ABABAB"
        />
      </g>
      <defs>
        <clipPath id="clip0_5448_14857">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default StoryEditEntHistory;
