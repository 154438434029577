import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Category from "../page/example/Profile";

import AppComponent from "src/page/app-component/AppComponent";
import DashboardZeroContent from "src/page/component/DashboardZeroContent";
import DataConnection from "src/page/component/database-connection/DataConnectionContainer";
import DataReference from "src/page/data-reference/DataReference";
import Test from "src/page/example/Test";
import Generate from "src/page/generate/Generate";
import LoginMain from "src/page/generate/login/LoginMain";
import Specifications from "src/page/generate/specification/Specifications";
import GojsTest from "src/page/gojstest/GojsTest";
import LoadDashboardPage from "src/page/menu/LoadDashboardPage";
import { pathRoutes } from "src/page/props/util.props";
import CompletePage from "src/page/settings/CompletePage";
import Subscription from "src/page/settings/SubscriptionPage";
import Story from "src/page/story/Story";
import EditStory from "src/page/story/story-edit/EditStory";
import EditStoryEntityGenPageContainer from "src/page/story/story-edit/entity/genpage/EditStoryEntityGenPageContainer";
import UIFirstPage from "src/shared/loading/UIFirstPage";
import "../App.css";
import Topics from "../page/example/Topics";
import DashboardPage from "../page/menu/DashboardPage";
import Products from "../page/products/Products";
import PrivateRoute from "./PrivateRoute";
import WorkFlowPage from "src/page/workflow/WorkFlowPage";

function MainRoutes() {
  return (
    //@ts-ignore
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <React.Suspense fallback={<UILoading />}> */}

      <Routes>
        <Route path="/" element={<Test />} />

        <Route path={pathRoutes.Login} element={<LoginMain />} />
        <Route path={"/"} element={<UIFirstPage />} />
        <Route path={pathRoutes.Root} element={<UIFirstPage />} />

        {/* Protected Routes */}
        {/* <Route path='/' element={<PrivateRoute />}> */}
        {/* <Route path={pathRoutes.Generate} element={<Generate />} /> */}
        {/* <Route path={`${pathRoutes.Generate}/${pathRoutes.Specifications}`} element={<Specifications />} /> */}

        <Route path="/" element={<PrivateRoute />}>
          <Route path={`${pathRoutes.Generate}`} element={<Generate />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Specifications}`}
            element={<Specifications />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Subscription}`}
            element={<Subscription />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Complete}`}
            element={<CompletePage />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Workflow}`}
            element={<WorkFlowPage />}
          />
        </Route>

        {/* <Route path="/stlight" element={<LighterTest/>} /> */}

        <Route path="/testgojs" element={<GojsTest />} />
        <Route path={pathRoutes.Dashboard} element={<LoadDashboardPage />} />
        <Route path={pathRoutes.MainDashboard} element={<DashboardPage />}>
          <Route index element={<DataConnection />} />
          <Route path="profile" element={<Category />} />
          <Route path="topics" element={<Topics />} />
          <Route path="products" element={<Products />} />
          <Route path="story" element={<Story />} />
          <Route path="database-connection" element={<DataConnection />} />
          <Route path="app-component" element={<AppComponent />} />
          <Route path="story/:id" element={<EditStory />} />
          <Route
            path="story/:id/genpage"
            element={<EditStoryEntityGenPageContainer />}
          />
          <Route path="" element={<DashboardZeroContent />} />
          <Route path="data-reference" element={<DataReference />} />
        </Route>
        {/* Payment Subscription */}
        {/* <Route
          path={`${pathRoutes.Subscription}`}
          element={<SettingsPage />}
        />
        <Route
          path={`${pathRoutes.Complete}`}
          element={<CompletePage />}
        /> */}
        {/* <Route path={pathRoutes.Workflow} element={<WorkFlowPage />} /> */}
      </Routes>

      {/* </React.Suspense> */}
    </BrowserRouter>
  );
}

export default MainRoutes;
