import React from "react";

const ComponentListIcon = (props) => {
    const { className, style, width, height,fill,opacity} = props;
    return (

        <svg
            width={width || "13"}
            height={height || "12"}
            className={className}
            style={style}
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2817_18594)">
                <path d="M4 3H12C12.1326 3 12.2598 2.94732 12.3536 2.85355C12.4473 2.75978 12.5 2.63261 12.5 2.5C12.5 2.36739 12.4473 2.24021 12.3536 2.14645C12.2598 2.05268 12.1326 2 12 2H4C3.86739 2 3.74021 2.05268 3.64645 2.14645C3.55268 2.24021 3.5 2.36739 3.5 2.5C3.5 2.63261 3.55268 2.75978 3.64645 2.85355C3.74021 2.94732 3.86739 3 4 3Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M12 5.5H4C3.86739 5.5 3.74021 5.55268 3.64645 5.64645C3.55268 5.74022 3.5 5.86739 3.5 6C3.5 6.13261 3.55268 6.25979 3.64645 6.35356C3.74021 6.44733 3.86739 6.50001 4 6.50001H12C12.1326 6.50001 12.2598 6.44733 12.3536 6.35356C12.4473 6.25979 12.5 6.13261 12.5 6C12.5 5.86739 12.4473 5.74022 12.3536 5.64645C12.2598 5.55268 12.1326 5.5 12 5.5Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M12 9H4C3.86739 9 3.74021 9.05268 3.64645 9.14645C3.55268 9.24022 3.5 9.36739 3.5 9.5C3.5 9.63261 3.55268 9.75979 3.64645 9.85356C3.74021 9.94733 3.86739 10 4 10H12C12.1326 10 12.2598 9.94733 12.3536 9.85356C12.4473 9.75979 12.5 9.63261 12.5 9.5C12.5 9.36739 12.4473 9.24022 12.3536 9.14645C12.2598 9.05268 12.1326 9 12 9Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M1.5 3.5C2.05228 3.5 2.5 3.05228 2.5 2.5C2.5 1.94771 2.05228 1.5 1.5 1.5C0.947715 1.5 0.5 1.94771 0.5 2.5C0.5 3.05228 0.947715 3.5 1.5 3.5Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : "0.5"} />
                <path d="M1.5 7C2.05228 7 2.5 6.55228 2.5 6C2.5 5.44771 2.05228 5 1.5 5C0.947715 5 0.5 5.44771 0.5 6C0.5 6.55228 0.947715 7 1.5 7Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : "0.5"} />
                <path d="M1.5 10.5C2.05228 10.5 2.5 10.0523 2.5 9.5C2.5 8.94771 2.05228 8.5 1.5 8.5C0.947715 8.5 0.5 8.94771 0.5 9.5C0.5 10.0523 0.947715 10.5 1.5 10.5Z" fill={fill ||"#272727"} fillOpacity={opacity ? opacity : "0.5"} />
            </g>
            <defs>
                <clipPath id="clip0_2817_18594">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ComponentListIcon;
