import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateBoModal.module.scss";
import { Button, Input, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";
import { SELECT_DATA_TYPE } from "src/utils/create-bo/DataTypeSelect";
import AddBoInputItem from "./components/AddBoInputItem";
import MessageNofify from "../../notification/MessageNotify";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { LAYER_LIMIT } from "src/utils/create-bo/createBo.util";
import { CreateBoType } from "src/types/BoMappingType";
import { BO_INIT_KEY } from "src/constants/DiagramInitKey";

type Props = {
  modalDetail: { title: string };
  isCreateMode: boolean;
  isModalOpen: boolean;
  nodeSelected: any;
  onOkCreate: (parentKey: string, createBoFields: CreateBoType[]) => void;
  onOkEdit: (createBoFields: CreateBoType[]) => void;
  onCancelCreateAndEditBo: () => void;
  mappingParam: any;
};

// type CreateBoType = {
//   itemId?: string;
//   fieldName: string;
//   group?: string;
//   dataType: string;
//   isPrimaryKey: boolean;
//   foreignKey: string;
// };

function CreateBoModal({
  modalDetail,
  isCreateMode,
  isModalOpen,
  nodeSelected,
  onOkCreate,
  onOkEdit,
  onCancelCreateAndEditBo,
  mappingParam,
}: Props) {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  //INPUT FIELD STATE
  let [selectDataTypes, setSelectDataTypes] =
    useState<{ value: string; label: string }[]>(SELECT_DATA_TYPE);

  let [parentField, setParentField] = useState<{
    label: string;
    value: string;
  }>({ label: "Business Object", value: BO_INIT_KEY });
  let [isTypeObject, setIsTypeObject] = useState<boolean>(false);
  let [createBoFieldItems, setCreateBoFieldItems] = useState<CreateBoType[]>(
    []
  );
  let [layerOfParent, setLayerOfParent] = useState<number>(0);
  let [fKSelects, setFKSelects] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  //NOTITY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  //CONFIRM DELETE MODAL STATE
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  let [itemToDelete, setItemToDelete] = useState<string>("");

  //EDIT MODE STATE
  let [updateData, setUpdateData] = useState<
    | {
        itemId: string;
        fieldName: string;
        dataType: string;
        isPrimaryKey: boolean;
        foreignKey: string;
      }
    | undefined
  >();

  useEffect(() => {
    if (isModalOpen && isCreateMode) {
      setDataOnCreateMode();
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else if (isModalOpen && !isCreateMode) {
      setIsTypeObject(false);
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!nodeSelected) return;
    let typeDesc = nodeSelected?.typeDesc;
    let paramName = nodeSelected?.paramName;
    let key = nodeSelected?.key;
    let parentKey = nodeSelected?.parentKey;
    let parentParamName = mappingParam.getParamNameByKey(parentKey);
    let isPrimaryKey = nodeSelected?.isPrimaryKey;
    let foreignKey = nodeSelected?.foreignKey;

    console.log(
      `typeDesc: ${typeDesc}, paramName: ${paramName}, key: ${key}, parentKey: ${parentKey}, parentParamName: ${parentParamName}`
    );
    let layerOfKey = mappingParam.getLayerByParentField(key);
    setLayerOfParent(layerOfKey);

    //GET SELECT FOREIGN KEY LIST
    let fkSelectList = [];
    if (typeDesc === "Object") {
      fkSelectList = mappingParam.getForeignKeySelectsByParentKey(key);
    } else {
      fkSelectList = mappingParam.getForeignKeySelectsByParentKey(parentKey);
    }

    console.log("result select list fk => ", fkSelectList);
    setFKSelects(fkSelectList);

    //CREATE MODE
    if (isCreateMode) {
      if (typeDesc === "BoInit") {
        setParentField({ label: "Business Object", value: BO_INIT_KEY });
        setIsTypeObject(true);
      } else if (typeDesc === "Object") {
        if (layerOfKey > LAYER_LIMIT) {
          setParentField({ label: parentParamName, value: parentKey }); // change focus to parent if layer more than identified.
        } else {
          setParentField({ label: paramName, value: key });
        }
      }

      //EDIT MODE
    } else {
      if (typeDesc === "Object") setIsTypeObject(true);

      let newCreateBoFieldItem = {
        itemId: key,
        fieldName: paramName,
        dataType: typeDesc.toString().toLowerCase(),
        isPrimaryKey,
        foreignKey,
      };
      setParentField({ label: parentParamName, value: parentKey });

      setUpdateData(newCreateBoFieldItem);
      setCreateBoFieldItems([newCreateBoFieldItem]);
    }
  }, [nodeSelected]);

  const updateFieldByItemId = (
    itemId: string,
    fieldName: string,
    dataType: string,
    isPrimaryKey: boolean,
    foreignKey: string
  ) => {
    console.log(
      `updateFieldByItemId => itemId: ${itemId}, fieldName: ${fieldName}, dataType: ${dataType}, isPrimaryKey: ${isPrimaryKey}, foreignKey: ${foreignKey}`
    );
    let newCreateBoFieldItem = createBoFieldItems.map((item) => {
      if (item.itemId === itemId) {
        return {
          ...item,
          fieldName,
          dataType,
          isPrimaryKey,
          foreignKey,
        };
      } else {
        return item;
      }
    });
    setCreateBoFieldItems(newCreateBoFieldItem);
  };

  const onChangeInputParentFieldHandler = (parentFieldName: string) => {
    console.log(
      "onChangeInputParentFieldHandler parentFieldName : ",
      parentFieldName
    );
  };

  const onConfirmCreateAndEditBo = () => {
    let reqCreateBoFields = [];
    let group = nodeSelected?.group;
    let parentFieldValue = parentField.value;

    //CREATE_BO_FIELD ITEMS FOR CREATE
    for (const cfItem of createBoFieldItems) {
      let dataTypeObj = selectDataTypes.find(
        (data) => data.value === cfItem.dataType
      );
      let dataTypeLabel = dataTypeObj?.label;
      if (!cfItem.fieldName) continue; //SKIP IF FIELD_NAME NULL
      let reqCreateBoField = {
        itemId: cfItem.itemId as string,
        fieldName: cfItem.fieldName,
        dataType: dataTypeLabel as string,
        isPrimaryKey: cfItem.isPrimaryKey,
        foreignKey: cfItem.foreignKey,
        group,
      };
      reqCreateBoFields.push(reqCreateBoField);
    }

    if (reqCreateBoFields.length === 0) return; //STOP IF CREATE_BO_FIELD ITEMS IS NULL

    //VALIDATE INPUT FORM
    let { isPass, resultError } = isPassValidateOnSubmit(
      reqCreateBoFields,
      parentFieldValue
    );
    // console.log(`isPass: ${isPass}, resultError: ${resultError}`);
    if (!isPass) {
      let title = "Duplicate Name!";
      let description = `Field Name : [ ${resultError.join(", ")} ]`;
      openNotifyError(title, description);
      return;
    }

    //CREATE OR UPDATE
    if (isCreateMode) {
      onOkCreate(parentField.value, reqCreateBoFields);
    } else {
      onOkEdit(reqCreateBoFields);
    }
  };

  const onAddNewItemsHandler = () => {
    const id = uuidv4();
    let newCreateBoFieldItem = {
      itemId: id,
      fieldName: "",
      dataType: "",
      isPrimaryKey: false,
      foreignKey: "",
    };
    setCreateBoFieldItems((prev) => [...prev, newCreateBoFieldItem]);

    scrollToBottom();
  };

  //NOTIFY HANDLER
  const openNotifyError = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(false);
  };

  //CONFIRM DELETE MODAL HANDLER
  const onConfirmDeleteHandler = () => {
    if (itemToDelete) {
      let newCreateBoFieldItems = createBoFieldItems.filter(
        (item) => item.itemId !== itemToDelete
      );
      setCreateBoFieldItems(newCreateBoFieldItems);
    }
    setIsOpenDeleteModal(false);
  };
  const onCancelDeleteHandler = () => {
    setIsOpenDeleteModal(false);
  };
  const onSetItemToDelete = (item: string) => {
    setItemToDelete(item);
    setIsOpenDeleteModal(true);
  };

  //UTILITY FUNCTION
  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollableDivRef.current) {
        const { scrollHeight, clientHeight } = scrollableDivRef.current;
        const maxScrollTop = scrollHeight - clientHeight;
        if (scrollableDivRef.current.scrollTo) {
          scrollableDivRef.current.scrollTo({
            top: maxScrollTop,
            behavior: "smooth",
          });
        } else {
          scrollableDivRef.current.scrollTop = maxScrollTop;
        }
      }
    }, 100);
  };

  const isExistFieldNameInBoMapping = (newNodes: any[], parentKey?: string) => {
    if (newNodes.length === 0) return [];

    let respExistField = [];
    let allNodeMapping = mappingParam.getDataFromDiagram();
    let nodes = [...allNodeMapping.nodeDataArray];

    //REMOVE RECORD ON UPDATE
    if (!isCreateMode) {
      nodes = nodes.filter((item) => item.key !== newNodes[0].itemId);
    }

    for (const newNode of newNodes) {
      let isExistField = nodes.some(
        (node) =>
          node.paramName === newNode.fieldName &&
          node.parentKey === parentKey &&
          node.group === "boGroup"
      );
      if (isExistField) respExistField.push(newNode.fieldName);
    }
    return respExistField;
  };

  const isDuplicateNewFieldName = (newNodes: any[]) => {
    let resultDup = [];
    for (let i in newNodes) {
      let dataToCompare = [...newNodes];
      dataToCompare.splice(parseInt(i), 1);
      let isDuplicate = dataToCompare.some(
        (d) => d.fieldName === newNodes[i].fieldName
      );
      if (isDuplicate) resultDup.push(newNodes[i].fieldName);
    }
    if (resultDup.length > 0) {
      resultDup = Array.from(new Set(resultDup));
    }
    return resultDup;
  };

  const isPassValidateOnSubmit = (newNodes: any[], parentKey?: string) => {
    let isPass = true;
    let resultError: any = [];
    let resultExist = isExistFieldNameInBoMapping(newNodes, parentKey);
    let resultDup = isDuplicateNewFieldName(newNodes);
    resultError = [...resultExist, ...resultDup];
    if (resultError.length > 0) isPass = false;
    return { isPass, resultError };
  };

  const setDataOnCreateMode = () => {
    setIsTypeObject(false);
    setSelectDataTypes(SELECT_DATA_TYPE);

    const id = uuidv4();
    setCreateBoFieldItems([
      {
        itemId: id,
        fieldName: "",
        dataType: "",
        isPrimaryKey: false,
        foreignKey: "",
      },
    ]);
  };

  return (
    <>
      <Modal
        title={<div className={styles.modalTitle}>{modalDetail.title}</div>}
        footer={
          <div className={styles.modalFooter}>
            {isCreateMode && (
              <Button
                className={styles.addBtn}
                key="add"
                onClick={onAddNewItemsHandler}
              >
                Add
              </Button>
            )}
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancelCreateAndEditBo}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="ok"
              onClick={onConfirmCreateAndEditBo}
              type="primary"
            >
              {isCreateMode ? "Create" : "Update"}
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={onConfirmCreateAndEditBo}
        onCancel={onCancelCreateAndEditBo}
        centered={true}
        width={createBoFieldItems.length > 1 ? 750 : 450}
      >
        <div className={styles.modalContent}>
          <div className={styles.selectWrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.textHeader}>Parent Object</span>
            </div>
            <div
              className={styles.actionWrapper}
              style={{ marginBottom: "10px" }}
            >
              <Input
                placeholder={"Parent Field"}
                value={parentField.label}
                className={styles.inputField}
                onChange={(e) =>
                  onChangeInputParentFieldHandler(e.currentTarget.value)
                }
                disabled={true}
              />
            </div>
            <div className={styles.inputFieldContainer} ref={scrollableDivRef}>
              {createBoFieldItems.map((item, index) => {
                return (
                  <AddBoInputItem
                    key={item.itemId}
                    itemId={item.itemId as string}
                    itemLength={createBoFieldItems.length}
                    isTypeObject={isTypeObject}
                    updateFieldByItemId={updateFieldByItemId}
                    onSetItemToDelete={onSetItemToDelete}
                    isCreateMode={isCreateMode}
                    updateData={updateData}
                    layerOfParent={layerOfParent}
                    fkSelects={fKSelects}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Modal>

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />

      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Field?",
          description: `Are you sure you want to delete this field? This action cannot be
        undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onConfirmDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />
    </>
  );
}

export default CreateBoModal;
