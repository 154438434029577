import React from "react";

const AppsIcon = (props) => {
    const { className, style, width, height, fill, color } = props;
    return (
        <svg 
        width={width || "15"} 
        height={height ||"14"} 
        className= {className}
        color={color || "#000000"}
        viewBox="0 0 15 14" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2938_22150)">
                <path d="M4.55599 0H2.80599C1.51733 0 0.472656 1.04467 0.472656 2.33333V4.08333C0.472656 5.372 1.51733 6.41667 2.80599 6.41667H4.55599C5.84465 6.41667 6.88932 5.372 6.88932 4.08333V2.33333C6.88932 1.04467 5.84465 0 4.55599 0Z" fill={ fill || "#ABABAB"} />
                <path d="M12.138 0H10.388C9.09935 0 8.05469 1.04467 8.05469 2.33333V4.08334C8.05469 5.372 9.09935 6.41667 10.388 6.41667H12.138C13.4267 6.41667 14.4713 5.372 14.4713 4.08334V2.33333C14.4713 1.04467 13.4267 0 12.138 0Z" fill={ fill ||"#ABABAB"} />
                <path d="M4.55599 7.58203H2.80599C1.51733 7.58203 0.472656 8.6267 0.472656 9.91537V11.6654C0.472656 12.954 1.51733 13.9987 2.80599 13.9987H4.55599C5.84465 13.9987 6.88932 12.954 6.88932 11.6654V9.91537C6.88932 8.6267 5.84465 7.58203 4.55599 7.58203Z" fill={ fill ||"#ABABAB"} />
                <path d="M12.138 7.58203H10.388C9.09935 7.58203 8.05469 8.6267 8.05469 9.91537V11.6654C8.05469 12.954 9.09935 13.9987 10.388 13.9987H12.138C13.4267 13.9987 14.4713 12.954 14.4713 11.6654V9.91537C14.4713 8.6267 13.4267 7.58203 12.138 7.58203Z" fill={ fill ||"#ABABAB"} />
            </g>
            <defs>
                <clipPath id="clip0_2938_22150">
                    <rect width="14" height="14" fill="white" transform="translate(0.472656)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default AppsIcon;
