import React, { useEffect, useState } from "react";
import styles from "./GenerateMFLoading.module.scss";

type Props = {
  isLoading: boolean;
};

function GenerateMFLoading({ isLoading }: Props) {
  let [loadingNumber, setLoadingNumber] = useState<number>(0);
  let [isLoadingDelay, setIsLoadingDelay] = useState<boolean>(isLoading);
  let [showProgressNumber, setShowProgressNumber] = useState<string>("");
  const onCountLoadingNumber = () => {
    setLoadingNumber((prev) => prev + 1);
  };
  //COUNT PROGRESS
  useEffect(() => {
    if (loadingNumber > 0) {
      let numShow = 95;
      // if (loadingNumber >= 1 && loadingNumber < 20) {
      //   numShow = loadingNumber;
      // } else if (loadingNumber >= 20 && loadingNumber < 50) {
      //   numShow = 35;
      // } else if (loadingNumber >= 35 && loadingNumber < 60) {
      //   numShow = loadingNumber;
      // } else if (loadingNumber >= 60 && loadingNumber < 80) {
      //   numShow = 75;
      // } else if (loadingNumber > 80) {
      //   numShow = 95;
      // }

      if (loadingNumber >= 1 && loadingNumber < 20) {
        numShow = 15;
      } else if (loadingNumber >= 20 && loadingNumber < 100) {
        numShow = 35;
      } else if (loadingNumber >= 100 && loadingNumber < 240) {
        numShow = 50;
      } else if (loadingNumber >= 240 && loadingNumber < 360) {
        numShow = 65;
      } else if (loadingNumber >= 360 && loadingNumber < 450) {
        numShow = 80;
      } else if (loadingNumber > 450) {
        numShow = 95;
      }

      let showProgress = numShow + "%";
      setShowProgressNumber(showProgress);
    }
  }, [loadingNumber]);

  useEffect(() => {
    let intervalId: any;
    let displayTime = 10;
    let intervalTime = (displayTime * 1000) / 100;

    if (isLoading) {
      setShowProgressNumber("");
      setLoadingNumber(0);

      intervalId = setInterval(onCountLoadingNumber, intervalTime);
      setIsLoadingDelay(true);
      onOpenGenerateMFLoading();
    } else {
      setShowProgressNumber("100%");
      clearInterval(intervalId);
      onCloseGenerateMFLoading();

      setTimeout(() => {
        setIsLoadingDelay(false);
      }, 200);
    }

    return () => clearInterval(intervalId);
  }, [isLoading]);

  //FIX ON SHOW EFFECT TO MAIN APP
  const onOpenGenerateMFLoading = () => {
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  };
  const onCloseGenerateMFLoading = () => {
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  };

  return (
    <div
      className={
        isLoadingDelay ? styles.genMFLoadingOpen : styles.genMFLoadingHide
      }
    >
      <div className={styles.circle}>
        <div className={styles.wave}>
          <h2>{showProgressNumber}</h2>
        </div>
      </div>
    </div>
  );
}

export default GenerateMFLoading;
