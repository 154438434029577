import { SHOW_AS_LABEL, TEXT } from "src/constants/InputType";

const getBoConfigItemDefault = (
    id: string,
    boName: string = "BO_NAME",
    dataType: string = "varchar"
  ) => {
    return {
      id,
      boName,
      dataType,
      inputType: TEXT,
      displayView: SHOW_AS_LABEL,
      displayEdit: "Lorem ipsum dolor sit",
      isHidden: false,
    };
  };

  export default getBoConfigItemDefault