import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface DatabaseConnectorsContextProps {
  isTestConnectionPass: boolean;
  setIsTestConnectionPass: (value: boolean) => void;
}

const DatabaseConnectorsContext = createContext<DatabaseConnectorsContextProps>({
  isTestConnectionPass: false,
  setIsTestConnectionPass:() => {},
});

const DatabaseConnectorsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isTestConnectionPass, setIsTestConnectionPass] = useState<boolean>(false);

  const contextValue: DatabaseConnectorsContextProps = {
    isTestConnectionPass,
    setIsTestConnectionPass
  };

  return (
    <DatabaseConnectorsContext.Provider value={contextValue}>
      {children}
    </DatabaseConnectorsContext.Provider>
  );
};

// const useDatabaseConnectors = (): DatabaseConnectorsContextProps => {
//   const context = useContext(DatabaseConnectorsContext);
//   if (!context) {
//     throw new Error(
//       "useDatabaseConnectors must be used within a DatabaseConnectorsProvider"
//     );
//   }
//   return context;
// };

export { DatabaseConnectorsProvider,  DatabaseConnectorsContext };
