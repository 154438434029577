import React from "react";

const SearchIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width||"14"} 
        height={height||"15"}
        className={className}
        style={style}
        viewBox="0 0 14 15" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3281_11269)">
                <path d="M13.8282 13.5034L10.3463 10.0215C11.2952 8.86099 11.7617 7.38019 11.6494 5.88538C11.5371 4.39058 10.8545 2.99612 9.7429 1.99044C8.63128 0.984772 7.17565 0.444828 5.6771 0.482296C4.17854 0.519765 2.75172 1.13178 1.69175 2.19175C0.631779 3.25172 0.0197647 4.67854 -0.0177039 6.1771C-0.0551725 7.67565 0.484772 9.13128 1.49044 10.2429C2.49612 11.3545 3.89058 12.0371 5.38538 12.1494C6.88019 12.2617 8.36099 11.7952 9.52147 10.8463L13.0034 14.3282C13.1134 14.4345 13.2608 14.4933 13.4137 14.492C13.5667 14.4906 13.713 14.4293 13.8211 14.3211C13.9293 14.213 13.9906 14.0667 13.992 13.9137C13.9933 13.7608 13.9345 13.6134 13.8282 13.5034ZM5.83247 10.9991C4.90949 10.9991 4.00724 10.7254 3.23981 10.2127C2.47238 9.69989 1.87424 8.97105 1.52104 8.11833C1.16783 7.26561 1.07541 6.3273 1.25548 5.42205C1.43554 4.51681 1.88 3.68529 2.53264 3.03264C3.18529 2.38 4.01681 1.93554 4.92205 1.75548C5.8273 1.57541 6.76561 1.66783 7.61833 2.02104C8.47105 2.37424 9.19989 2.97238 9.71267 3.73981C10.2254 4.50724 10.4991 5.40949 10.4991 6.33247C10.4978 7.56972 10.0056 8.7559 9.13077 9.63077C8.2559 10.5056 7.06972 10.9978 5.83247 10.9991Z" fill={fill||"#ABABAB"} opacity={opacity||""}/>
            </g>
            <defs>
                <clipPath id="clip0_3281_11269">
                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default SearchIcon;
