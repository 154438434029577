import React, { useContext, useEffect, useState } from "react";
import styles from "./ChooseTemplateStep1.module.scss";
import "./ChooseTemplateStep1.scss";
import { Radio } from "antd";
import StoryEditEntGenPageCard from "src/assets/svg/story-edit-ent-genpage-card";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import { GetTemplateResponse, TemplateItem } from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import { GET_TEMPLATE_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";

import StoryGenpageTile from "src/assets/png/story-genpage-tile.png";
import StoryGenpageCard from "src/assets/png/story-genpage-card.png";
import StoryGenpageTable from "src/assets/png/story-genpage-table.png";
import StoryGenpageList from "src/assets/png/story-genpage-list.png";

type Props = {
  // storyGenPageHandler: StoryGeneratePageHandler;
};

type ChooseTemplateType = {
  groupId: number;
  titleName: string;
  groupItems: { id: string; img: string; title: string; value: string }[];
  groupItemSelected: string;
};

type TemplateDictionaryType = {
  [key: string]: string;
};
const imageOfTemplateDataDict: TemplateDictionaryType = {
  card: StoryGenpageCard,
  table: StoryGenpageTable,
  list: StoryGenpageList,
  tile: StoryGenpageTile,
  form: StoryGenpageTile,
  tab: StoryGenpageCard,
  breadcrumb: StoryGenpageTable,
};

const GET_TEMPLATE_FK = "getTmpFK";

function ChooseTemplateStep1({}: Props) {
  const { loading, error, responseData, fetchData, fetchKey } =
    useFetch<GetTemplateResponse>();
  let genPageContext = useContext(StoryGeneratePageContext);
  let [chooseTempItems, setChooseTempItems] = useState<ChooseTemplateType[]>(
    []
  ); //CHOOSE_TEMPLATE_ITEMS

  useEffect(() => {
    console.log("onload ChooseTemplateStep1", genPageContext);
    getAllTemplateController();
  }, []);

  //SET CHOOSE TEMPLATE FROM SAVED
  useEffect(() => {
    // console.log("chooseTempItems => ", chooseTempItems)
    // console.log("genPageContext.chooseTemplate => ", genPageContext.chooseTemplate)
    let newChooseTempItems = chooseTempItems.map(item => {
      if(item.groupId === 1){
        return {
          ...item,
          groupItemSelected: genPageContext.chooseTemplate.list_template_id
        }
      }else{
        return {
          ...item,
          groupItemSelected: genPageContext.chooseTemplate.create_template_id
        }
      } 
    })
    // console.log("newChooseTempItems => ", newChooseTempItems)

    setChooseTempItems(newChooseTempItems)
  }, [genPageContext.isVersionGenerated])

  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) {
        genPageContext.setIsLoadingBtnGeneratePage(false);
        return;
      } 

      switch (fetchKey) {
        case GET_TEMPLATE_FK: {
          let respGetAllTemplate = responseData;
          console.log(
            "ChooseTemplateStep1 respGetAllTemplate",
            respGetAllTemplate
          );

          let listItems = respGetAllTemplate?.data.List || [];
          let cuItems = respGetAllTemplate?.data.create_update || [];

          if (listItems.length === 0 || cuItems.length === 0) {
            disableLoadingBtn();
            return;
          } 
          let respItems = getChooseTemplateFromFetcher(listItems, cuItems);
          console.log("ChooseTemplateStep1 respItems", respItems);
          
          setChooseTempItems(respItems);
          disableLoadingBtn();
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //SAVE DATA TRIGGER FROM PARENT
  useEffect(() => {
    let isSave = genPageContext.isSaveChooseTemplate;
    let currentStep = genPageContext.currentStep;
    let nextStep = currentStep + 1;

    if (isSave) {
      let dataForSave = getChooseTemplateDataForSave();
      console.log("ChooseTemplateStep1 onsave dataForSave => ", dataForSave);

      genPageContext.setChooseTemplate(dataForSave);
      genPageContext.setIsSaveChooseTemplate(false);
      genPageContext.setCurrentStep(nextStep);
    }
  }, [genPageContext.isSaveChooseTemplate]);

  const getGroupObjBySelectValue = (value: string) => {
    for (const tempItem of chooseTempItems) {
      let isThisGroup = tempItem.groupItems.some(
        (item) => item.value === value
      );
      if (isThisGroup) return tempItem;
    }
  };
  const onChangeTemplateByGroup = (value: string) => {
    let groupObj = getGroupObjBySelectValue(value);
    let newChooseTemplates = [...chooseTempItems].map(
      (tempItem: ChooseTemplateType) => {
        if (tempItem.groupId === groupObj?.groupId) {
          return {
            ...tempItem,
            groupItemSelected: value,
          };
        } else {
          return tempItem;
        }
      }
    );
    setChooseTempItems(newChooseTemplates);
    // updateStoryGeneratePageHandler(newChooseTemplates);
    console.log("check data onchange => ", newChooseTemplates);
  };

  //STORY_GENERATE_PAGE HANDLER
  // const updateStoryGeneratePageHandler = (
  //   newChooseTemplates: ChooseTemplateType[]
  // ) => {
  //   storyGenPageHandler.setChooseTemplateConfig(newChooseTemplates);
  // };

  //UTILITY FUNCTION
  const getChooseTemplateDataForSave = () => {
    let listTemplateId = chooseTempItems[0].groupItemSelected;
    let createTemplateId = chooseTempItems[1].groupItemSelected;
    return {
      list_template_id: listTemplateId,
      create_template_id: createTemplateId,
    };
  };
  const getChooseTemplateFromFetcher = (
    listData: TemplateItem[],
    createUpdateData: TemplateItem[]
  ) => {
    let result = [];
    let listItems = [];
    let cuItems = [];

    //LIST_ITEMS
    for (const ls of listData) {
      let obj = {
        id: ls.id,
        title: ls.template_name,
        value: ls.template_key,
        img: imageOfTemplateDataDict[ls.template_name.toLowerCase()],
      };
      listItems.push(obj);
    }

    //CREATE_AND_UPDATE_ITEMS
    for (const cu of createUpdateData) {
      let obj = {
        id: cu.id,
        title: cu.template_name,
        value: cu.template_key,
        img: imageOfTemplateDataDict[cu.template_name.toLowerCase()],
      };
      cuItems.push(obj);
    }

    let oldListSelected = genPageContext.chooseTemplate?.list_template_id;
    let oldCuSelected = genPageContext.chooseTemplate?.create_template_id;
    result = [
      {
        groupId: 1,
        titleName: "List",
        groupItemSelected: oldListSelected || listItems[0]?.value,
        groupItems: listItems,
      },
      {
        groupId: 2,
        titleName: "Create & Update",
        groupItemSelected: oldCuSelected || cuItems[0]?.value,
        groupItems: cuItems,
      },
    ];

    return result;
  };

  const enableLoadingBtn = () => {
    genPageContext.setIsLoadingBtnGeneratePage(true)
  }

  const disableLoadingBtn = () => {
    genPageContext.setIsLoadingBtnGeneratePage(false)
  }

  //API CONTROLLER FUNCTION
  const getAllTemplateController = () => {
    enableLoadingBtn()
    fetchData(
      {
        endpoint: GET_TEMPLATE_ENDPOINT,
        method: "GET",
      },
      GET_TEMPLATE_FK
    );
  };
  return (
    <div className={styles.chooseTempContainer}>
      {chooseTempItems.map((groupItem) => {
        return (
          <Radio.Group
            size={"large"}
            key={groupItem.groupId}
            onChange={(e) => onChangeTemplateByGroup(e.target.value)}
            value={groupItem.groupItemSelected}
            // defaultValue={groupItem.groupItemSelected}
          >
            <div
              key={groupItem.groupId}
              className={styles.chooseTempItemWrapper}
            >
              <div className={styles.titleWrapper}>{groupItem.titleName}</div>
              <div className={styles.itemWrapper}>
                {groupItem.groupItems.map((item) => {
                  return (
                    <div key={item.id} className={styles.cardItem}>
                      <div className={styles.imgWrapper}>
                        <img
                          src={item.img as string}
                          alt=""
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div className={styles.contentWrapper}>
                        <StoryEditEntGenPageCard
                          style={{ marginRight: "5px" }}
                        />
                        {item.title}
                      </div>
                      <div
                        id="choose-temp-radio"
                        className={styles.radioWrapper}
                      >
                        <Radio value={item.value}></Radio>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Radio.Group>
        );
      })}
    </div>
  );
}

export default ChooseTemplateStep1;
