import React, { useContext, useEffect, useState } from "react";
import styles from "./CreateEditPageConfigStep4.module.scss";
import { Input } from "antd";
import SearchIcon from "src/assets/svg/searchIcon";
import StoryEditEntGenPageButton from "src/assets/svg/story-edit-ent-genpage-button";
import StoryEditEntGenPageTab from "src/assets/svg/story-edit-ent-genpage-tab";
import StoryEditEntGenPageBreadCrump from "src/assets/svg/story-edit-ent-genpage-breadcrump";
import StoryEditEntGenPageIcon from "src/assets/svg/story-edit-ent-genpage-icon";
import StoryEditEntGenPageImage from "src/assets/svg/story-edit-ent-genpage-image";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import StoryEditEntGenPageArrowDown from "src/assets/svg/story-edit-ent-genpage-arrow-down";
import ControlItem from "./components/ControlItem";
import BoFieldItem from "./components/BoFieldItem";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import {
  getNewUpdateColInTabBySubComponent,
  getToolPosition,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import BoFieldTool from "./components/BoFieldTool";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import CellTool from "./components/CellTool";
import { v4 as uuidv4 } from "uuid";
import ControlTab from "./components/control-items/tab/ControlTab";
import ControlBreadcrump from "./components/control-items/breadcrump/ControlBreadcrump";
import ControlButton from "./components/control-items/button/ControlButton";
import ControlIcon from "./components/control-items/icon/ControlIcon";
import ControlImage from "./components/control-items/image/ControlImage";
import {
  defineRowIdInControlComponent,
  getColIdByRowIdAndColId,
  getColIdOneColumnInRowByRowId,
  getRowIdFromCellId,
  removeChildInCell,
  removeComponentInCell,
  replaceChildInsideCellInGridPanelItems,
  replaceRowWithNewRowItemByRowId,
  updateChildInCellWithBoFieldItem,
} from "./utils/controls.util";
import StoryEditEntGenPageText from "src/assets/svg/story-edit-ent-genpage-text";
import ControlText from "./components/control-items/text/ControlText";
import {
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  TAB,
  TABLE,
} from "src/constants/PageConfig";
import ControlTable from "./components/control-items/table/ControlTable";
import StoryEditEntGenPageTable from "src/assets/svg/story-edit-ent-genpage-table";
import CellComponentTool from "./components/CellComponentTool";
import CellComponentTableTool from "./components/CellComponentTableTool";
import { TEXT } from "src/constants/InputType";
type Props = {};

type ControlItemType = {
  id: string;
  controlName: string;
  icon: React.JSX.Element;
  isRowReplace: boolean;
};

function CreateEditPageConfigStep4({}: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  const BO = "bo";
  const CONTROL = "control";
  const MAX_COL_NUM = 4;

  let [isHideBoMenu, setIsHideBoMenu] = useState<boolean>(true);
  let [isHideControlMenu, setIsHideControlMenu] = useState<boolean>(true);
  let [gridPanelItems, setGridPanelItems] = useState([]);
  let [colNum, setColNum] = useState<number>(2);
  let [rowNum, setRowNum] = useState<number>(9);
  let [dragOverItem, setDragOverItem] = useState<string>("");
  // let [focusBoItem, setFocusBoItem] = useState<string>("");
  let [isFocusOnParent, setIsFocusOnParent] = useState<boolean>(false);
  let [isFirstLoad, setisFirstLoad] = useState<boolean>(false);

  //MODAL DELETE CONFIRMATION STATE
  let [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState<boolean>(false);

  //TOOL STATE
  let [isCellComponentToolOpen, setIsCellComponentToolOpen] =
    useState<boolean>(false);
  let [isCellComponentTableToolOpen, setIsCellComponentTableToolOpen] =
    useState<boolean>(false);
  let [isCellToolOpen, setIsCellToolOpen] = useState<boolean>(false);
  let [isBoFieldToolOpen, setIsBoFieldToolOpen] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  }>();

  //STATE FOR CHECK ON SUB COMPONENT UPDATE
  let [isSubComUpdate, setIsSubComUpdate] = useState<boolean>(false);
  let [subComUpdate, setSubComUpdate] = useState<any>({});

  //STATE FOR CHECK ON MAIN COMPONENT UPDATE
  let [isMainComUpdate, setIsMainComUpdate] = useState<boolean>(false);
  let [mainComUpdate, setMainComUpdate] = useState<any>({});

  let [boFieldItems, setBoFieldItems] = useState<any[]>([]); //CREATE_EDIT_BO_FIELD_ITEMS
  let [controlItems, setControlItems] = useState<ControlItemType[]>([]);

  //FOR SERACH STATE
  let [boFieldItemsSearch, setBoFieldItemsSearch] = useState([]);
  let [controlItemsSearch, setControlItemsSearch] = useState([]);

  //FOR REMOVE
  let [isRemoveComponentField, setIsRemoveComponentField] =
    useState<boolean>(false);

  const initControlItems: ControlItemType[] = [
    {
      id: "ct1",
      controlName: "Tab",
      icon: <StoryEditEntGenPageTab width={"20px"} height={"20px"} />,
      isRowReplace: true,
    },
    {
      id: "ct2",
      controlName: "Breadcrump",
      icon: <StoryEditEntGenPageBreadCrump width={"30px"} height={"30px"} />,
      isRowReplace: true,
    },
    {
      id: "ct3",
      controlName: "Button",
      icon: <StoryEditEntGenPageButton width={"20px"} height={"20px"} />,
      isRowReplace: false,
    },
    {
      id: "ct4",
      controlName: "Icon",
      icon: <StoryEditEntGenPageIcon width={"20px"} height={"20px"} />,
      isRowReplace: false,
    },
    {
      id: "ct5",
      controlName: "Image",
      icon: <StoryEditEntGenPageImage width={"20px"} height={"20px"} />,
      isRowReplace: false,
    },
    {
      id: "ct6",
      controlName: "Text",
      icon: <StoryEditEntGenPageText width={"20px"} height={"20px"} />,
      isRowReplace: false,
    },
    {
      id: "ct7",
      controlName: "Table",
      icon: <StoryEditEntGenPageTable width={"20px"} height={"20px"} />,
      isRowReplace: true,
    },
  ];

  useEffect(() => {
    console.log("onload CreateEditPageConfigStep4 ", genPageContext);
    actionPrepareMenuItemsOnload();
  }, []);

  useEffect(() => {
    console.log("onload is version generated ", genPageContext);
    // setGridPanelItems(genPageContext.generateVersionStorage);
    // setPageConfigPanelItems(newGridPanelItems);
    // setPageConfigPanelItems(genPageContext.panelItems)
    setisFirstLoad(true);
  }, [genPageContext.isVersionGenerated]);

  useEffect(() => {
    console.log("genPageContext =>", genPageContext);
  }, [genPageContext.panelItems]);

  //DRAG DROP HANDLER
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    console.log("handleDrop");
    let cellId = e.currentTarget.id;
    console.log("handleDrop in cellId : ", cellId);
    setDragOverItem("");

    let dropItemDetailStr = e.dataTransfer.getData("text/plain");
    let dropItemDetailObj: any =
      getDropItemObjFromDataTransfer(dropItemDetailStr);
    console.log("Item dropped droppedItemId ", dropItemDetailObj);
    let { componentId, componentType, isRowReplace } = dropItemDetailObj;

    if (componentType === BO) {
      // console.log("BO item drop => ", componentId);
      onAddChildInCellByBofieldItem(cellId, componentId);
    } else if (componentType === CONTROL) {
      // console.log("CONTROL item drop => ", componentId);
      onAddChildInCellByControlItem(cellId, componentId, isRowReplace);
    }
    // onAddChildInCellByBofieldItem(cellId, componentId);
  };

  const getDropItemObjFromDataTransfer = (dropItemDetailStr: string) => {
    let result = {};

    let dropItemDetailObj = JSON.parse(dropItemDetailStr);
    let { componentId, componentType } = dropItemDetailObj;

    if (componentType === CONTROL) {
      let itemObjFromControlItem = controlItems.find(
        (item) => item.id === componentId
      );

      if (!itemObjFromControlItem) result = dropItemDetailObj;
      result = {
        ...dropItemDetailObj,
        isRowReplace: (itemObjFromControlItem as ControlItemType).isRowReplace,
      };
    } else {
      result = dropItemDetailObj;
    }

    return result;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    let id = e.currentTarget.id;
    console.log("handleDragOver id : ", id);
    setDragOverItem(id);
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragLeave id : ", id);
    setDragOverItem("");
  };

  //GET CONTROL COMPONENT BY CONTROL_ID
  const getControlComponentByControlId = (
    controlId: string
  ): React.JSX.Element => {
    const controlComponents: any = {
      ct1: (
        <ControlTab
          rowNum={2}
          colNum={2}
          boFieldItems={boFieldItems}
          controlItems={controlItems}
          getControlComponentByControlId={getControlComponentByControlId}
          onUpdateItems={onUpdateComponentTabItems}
        />
      ),
      ct2: (
        <ControlBreadcrump
          rowNum={2}
          colNum={2}
          boFieldItems={boFieldItems}
          controlItems={controlItems}
          getControlComponentByControlId={getControlComponentByControlId}
          onUpdateItems={onUpdateComponentBreadcrumpItems}
        />
      ),
      ct3: (
        <ControlButton
          title={"Button"}
          onUpdate={onUpdateComponentButtonItem}
          onRemove={onRemoveComponentField}
          configType={BUTTON}
        />
      ),
      ct4: (
        <ControlIcon
          onUpdate={onUpdateComponentIconItem}
          onRemove={onRemoveComponentField}
          configType={ICON}
        />
      ),
      ct5: (
        <ControlImage
          onUpdate={onUpdateComponentImageItem}
          onRemove={onRemoveComponentField}
          configType={IMAGE}
        />
      ),
      ct6: (
        <ControlText
          onUpdate={onUpdateComponentTextItem}
          onRemove={onRemoveComponentField}
          configType={LABEL}
        />
      ),
      ct7: (
        <ControlTable
          colNum={2}
          boFieldItems={boFieldItems}
          onUpdateItems={onUpdateComponentTableItems}
        />
      ),
    };

    return controlComponents[controlId];
  };

  //--------------------------UPDATE MAIN COMPONENT HANDLER--------------------------//
  const onUpdateComponentTabItems = (tabItems: any, rowId: string) => {
    let pagePanelItems = genPageContext.panelItems;
    console.log("onUpdateComponentTabItems pagePanelItems => ", pagePanelItems);
    console.log("onUpdateComponentTabItems gridPanelItems => ", gridPanelItems);
    console.log(
      "Main onUpdateComponentTabItems tabItems => ",
      tabItems,
      ", rowId => ",
      rowId
    );

    let newUpdateComponentObj = {
      rowId,
      configType: TAB,
      componentConfig: tabItems,
    };

    setIsMainComUpdate(true);
    setMainComUpdate(newUpdateComponentObj);
  };

  const onUpdateComponentTableItems = (tableItems: any, rowId: string) => {
    console.log(
      "Main onUpdateComponentTableItems  => ",
      tableItems,
      ", rowId => ",
      rowId
    );
    console.log(
      "genPageContext.parentSelectedComponentTable => ",
      genPageContext.parentSelectedComponentTable
    );
    let updateFilter = genPageContext.parentSelectedComponentTable.filter(
      (item: any) => item.rowId && item.rowId === rowId
    );
    console.log("updateFilter ", updateFilter);
    let newUpdateComponentObj = {
      rowId,
      configType: TABLE,
      componentConfig: tableItems,
      parentSelected:
        updateFilter.length > 0 ? updateFilter[0].parentSelected : "",
    };
    console.log(
      "onUpdateComponentTableItems newUpdateComponentObj => ",
      newUpdateComponentObj
    );

    setIsMainComUpdate(true);
    setMainComUpdate(newUpdateComponentObj);
  };

  const onUpdateComponentBreadcrumpItems = (
    breadcrumpItems: any,
    rowId: string
  ) => {
    let pagePanelItems = genPageContext.panelItems;
    console.log(
      "onUpdateComponentBreadcrumpItems pagePanelItems => ",
      pagePanelItems
    );
    console.log(
      "Main onUpdateComponentBreadcrumpItems  => ",
      breadcrumpItems,
      ", rowId => ",
      rowId
    );

    let newUpdateComponentObj = {
      rowId,
      configType: BREADCRUMP,
      componentConfig: breadcrumpItems,
    };

    setIsMainComUpdate(true);
    setMainComUpdate(newUpdateComponentObj);
  };

  useEffect(() => {
    if (isMainComUpdate && mainComUpdate) {
      // console.log(
      //   "ON MAIN COMPONENT UPDATE => ",
      //   genPageContext.updateComponents
      // );
      // console.log("ON MAIN COMPONENT mainComUpdate => ", mainComUpdate);
      // console.log("ON MAIN COMPONENT gridPanelItems => ", gridPanelItems);

      let updateComponentsContext = genPageContext.updateComponents;
      let compContext = updateComponentsContext.filter(
        (item: any) => item?.rowId !== mainComUpdate?.rowId
      );
      let newUpdateComponent = [...compContext, mainComUpdate];
      genPageContext.setUpdateComponents(newUpdateComponent);

      setIsMainComUpdate(false);
      setMainComUpdate(null);
      // Update control config context
      let controlConfigsContext = genPageContext.conTrolconfigs;
      let controlConfContext = controlConfigsContext.filter(
        (item: any) => item?.rowId !== mainComUpdate?.rowId
      );
      let newcontrolConfigs = [...controlConfContext, mainComUpdate];
      genPageContext.setConTrolconfigs(newcontrolConfigs);
    }
  }, [isMainComUpdate, mainComUpdate]);

  //CHECK ON UPDATE_COMPONENTS
  useEffect(() => {
    if (genPageContext.updateComponents) {
      console.log(
        "CHECK ON UPDATE_COMPONENTS => ",
        genPageContext.updateComponents
      );
    }
  }, [genPageContext.updateComponents]);

  //CHECK ON CONTROL_CONFIGS
  useEffect(() => {
    if (genPageContext.conTrolconfigs) {
      console.log(
        "CHECK ON CONTROL_CONFIGS => ",
        genPageContext.conTrolconfigs
      );
    }
  }, [genPageContext.conTrolconfigs]);

  //--------------------------^UPDATE MAIN COMPONENT HANDLER^--------------------------//

  //--------------------------UPDATE SUB COMPONENT HANDLER--------------------------//
  //CHECK SUB COM UPDATE
  useEffect(() => {
    if (isSubComUpdate) {
      console.log("Sub ColId for update", subComUpdate);
      console.log(
        "SubComponent genPageContext.updateComponents checking => ",
        genPageContext.updateComponents
      );
      let updateComponents = genPageContext.updateComponents;

      let panelItems = genPageContext.panelItems;
      console.log("ON SUB-COMPONENT panelItems => ", panelItems);

      //PARAMS
      let cellId = subComUpdate?.cellId;
      let updateDetail = subComUpdate?.detail;
      let newUpdateComponents = getNewUpdateColInTabBySubComponent(
        updateComponents,
        cellId,
        updateDetail
      );
      console.log("newUpdateComponents => ", newUpdateComponents);
      genPageContext.setUpdateComponents(newUpdateComponents);

      // Update control configs context
      // let controlConfigsContext = genPageContext.conTrolconfigs;
      // let controlConfContext = controlConfigsContext.filter(
      //   (item: any) => item?.colId !== cellId
      // );
      // let newcontrolConfigs = [...controlConfContext, newUpdateComponentObj];
      genPageContext.setConTrolconfigs(newUpdateComponents);

      setIsSubComUpdate(false);
      setSubComUpdate({});
    }
  }, [isSubComUpdate]);

  const onUpdateComponentButtonItem = (detail: any, cellId: string) => {
    console.log(
      "Main onUpdateComponentButtonItem  => ",
      detail,
      ", cellId => ",
      cellId
    );

    updateComponentPageConfigByComponentConfig(BUTTON, cellId, detail);
  };

  const onUpdateComponentTextItem = (detail: any, cellId: string) => {
    console.log(
      "Main onUpdateComponentTextItem  => ",
      detail,
      ", cellId => ",
      cellId
    );

    updateComponentPageConfigByComponentConfig(LABEL, cellId, detail);
  };

  //     let updateComponentsContext = genPageContext.updateComponents;
  //     let compContext = updateComponentsContext?.filter(
  //       (item: any) => item?.colId !== cellId
  //     );
  //     let newUpdateComponent = [...compContext, newUpdateComponentObj];
  //     genPageContext.setUpdateComponents(newUpdateComponent);
  //   }
  // };

  const onUpdateComponentIconItem = (detail: any, cellId: string) => {
    console.log(
      "Main onUpdateComponentIconItem  => ",
      detail,
      ", cellId => ",
      cellId
    );

    updateComponentPageConfigByComponentConfig(ICON, cellId, detail);
  };

  //     let updateComponentsContext = genPageContext.updateComponents;
  //     let compContext = updateComponentsContext?.filter(
  //       (item: any) => item?.colId !== cellId
  //     );
  //     let newUpdateComponent = [...compContext, newUpdateComponentObj];
  //     genPageContext.setUpdateComponents(newUpdateComponent);
  //   }
  // };

  const onUpdateComponentImageItem = (detail: any, cellId: string) => {
    console.log(
      "Main onUpdateComponentImageItem  => ",
      detail,
      ", cellId => ",
      cellId
    );

    updateComponentPageConfigByComponentConfig(IMAGE, cellId, detail);
  };

  const updateComponentPageConfigByComponentConfig = (
    configType: string,
    cellId: string,
    detail: any
  ) => {
    if (cellId.includes("sub")) {
      updateSubFieldComponentConfig(configType, cellId, detail);
    } else if (cellId.includes("main")) {
      updateMainFieldComponentConfig(configType, cellId, detail);
    }
  };

  const updateSubFieldComponentConfig = (
    configType: string,
    cellId: string,
    detail: any
  ) => {
    let newUpdateComponentObj: any;
    switch (configType) {
      case BUTTON:
        newUpdateComponentObj = {
          title: detail?.title,
          buttonType: detail?.buttonType,
          configType: BUTTON,
        };
        break;

      case LABEL:
        newUpdateComponentObj = {
          text: detail?.text,
          labelType: detail?.labelType,
          configType: LABEL,
        };
        break;

      case ICON:
        newUpdateComponentObj = {
          iconPath: detail?.iconPath,
          configType: ICON,
        };
        break;

      case IMAGE:
        newUpdateComponentObj = {
          iconPath: detail?.iconPath,
          configType: IMAGE,
        };
        break;
    }

    setSubComUpdate({ cellId, detail: newUpdateComponentObj });
    setIsSubComUpdate(true);
  };

  const updateMainFieldComponentConfig = (
    configType: string,
    cellId: string,
    detail: any
  ) => {
    let newUpdateComponentObj: any;
    switch (configType) {
      case BUTTON:
        newUpdateComponentObj = {
          colId: cellId,
          detail: {
            title: detail?.title,
            buttonType: detail?.buttonType,
            configType: BUTTON,
          },
        };
        break;

      case LABEL:
        newUpdateComponentObj = {
          colId: cellId,
          detail: {
            text: detail?.text,
            labelType: detail?.labelType,
            configType: LABEL,
          },
        };
        break;

      case ICON:
        newUpdateComponentObj = {
          colId: cellId,
          detail: {
            iconPath: detail?.iconPath,
            configType: ICON,
          },
        };
        break;

      case IMAGE:
        newUpdateComponentObj = {
          colId: cellId,
          detail: {
            imagePath: detail?.imagePath,
            configType: IMAGE,
          },
        };
        break;
    }

    let updateComponentsContext = genPageContext.updateComponents;
    let compContext = updateComponentsContext?.filter(
      (item: any) => item?.colId !== cellId
    );
    let newUpdateComponent = [...compContext, newUpdateComponentObj];
    genPageContext.setUpdateComponents(newUpdateComponent);

    // Update control configs context
    let controlConfigsContext = genPageContext.conTrolconfigs;
    let controlConfContext = controlConfigsContext.filter(
      (item: any) => item?.colId !== cellId
    );
    let newcontrolConfigs = [...controlConfContext, newUpdateComponentObj];
    genPageContext.setConTrolconfigs(newcontrolConfigs);
  };

  //--------------------------^UPDATE SUB COMPONENT HANDLER^--------------------------//

  //-----------------------REMOVE FIELD IN PANEL CONFIG-----------------------//
  const onRemoveComponentField = (cellId: string) => {
    console.log("Main onRemoveComponentItem  => ", cellId);
    let focusBoItemSplit = cellId.split("_");
    let idForRemove = focusBoItemSplit[0] + "_" + focusBoItemSplit[1]; //focusBoItem.split("_")[1];
    console.log("onRemoveComponentItem idForRemove => ", idForRemove);
    setIsRemoveComponentField(true);
  };

  useEffect(() => {
    if (isRemoveComponentField) {
      let cellId = genPageContext.itemFocusOnConfigPage;
      console.log("Main onRemoveComponentItem  => ", cellId);
      let focusBoItemSplit = cellId.split("_");
      let idForRemove = focusBoItemSplit[0] + "_" + focusBoItemSplit[1]; //focusBoItem.split("_")[1];
      console.log("onRemoveComponentItem idForRemove => ", idForRemove);

      //GET NEW ROW AFTER REMOVE CHILD

      let newRowItem = removeChildInCell(idForRemove, gridPanelItems);
      // console.log("onRemoveComponentItem newRowItem => ", newRowItem);

      //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
      let newGridPanelItems: any = replaceChildInsideCellInGridPanelItems(
        idForRemove,
        newRowItem,
        gridPanelItems
      );
      console.log(
        "onRemoveComponentItem newGridPanelItems => ",
        newGridPanelItems
      );
      setPageConfigPanelItems(newGridPanelItems);
      genPageContext.setItemFocusOnConfigPage("");
      setIsRemoveComponentField(false);
      // Remove control configs context
      let controlConfigs = genPageContext.conTrolconfigs;
      let ControlContext = controlConfigs?.filter(
        (item: any) => item?.colId !== idForRemove
      );
      // genPageContext.setConTrolconfigs([])
      genPageContext.setConTrolconfigs(ControlContext);
    }
  }, [isRemoveComponentField]);
  //-----------------------^REMOVE FIELD IN PANEL CONFIG^-----------------------//

  //ROW_NUM AND COL_NUM HAS CHANGED
  useEffect(() => {
    console.log(`Row: ${rowNum}, Col: ${colNum}`);
    let rowColItems = getRowColItems();
    setPageConfigPanelItems(rowColItems);
  }, [rowNum, colNum]);

  //isFirstLoad HAS CHANGE TO SET PANELS TO SET COMPONENT ELEMENT
  useEffect(() => {
    if (isFirstLoad && genPageContext.isVersionGenerated) {
      console.log("genPageContext =>", genPageContext);
      let versionStorage = genPageContext.generateVersionStorage;
      let versionStorageForUpdate = genPageContext.generateVersionStorage;
      let newGridPanelItems: any[] = versionStorage;
      let newRowItem: any;
      versionStorage.forEach((row: any) => {
        row.cols.forEach((col: any) => {
          if (col.isControlItem) {
            if (
              col.controlComponent === "ct1" ||
              col.controlComponent === "ct2" ||
              col.controlComponent === "ct7"
            ) {
              newRowItem = updateChildInCellWithControlItemRowReplace(
                col.colId,
                col.controlComponent
              );
              setPrepareUpdateComponentByCellIdAndComponentId(
                col.colId,
                col.controlComponent
              );
              // console.log("<<<<< newRowItem >>>>> ", newRowItem)
            } else {
              // newRowItem = updateChildInCellWithControlItemNotRowReplace(
              //   col.colId,
              //   col.controlComponent
              // );
              let cellId = col.colId;
              let controlComponent = getControlComponentByControlId(
                col.controlComponent
              );

              let props = controlComponent?.props;
              controlComponent = {
                ...controlComponent,
                props: {
                  ...props,
                  cellId,
                },
              };

              //GET NEW_ROW_ITEM
              let rowId = cellId.split("-")[1]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
              console.log("rowId ", rowId);
              console.log("versionStorageForUpdate ", versionStorageForUpdate);
              let newRow: any = versionStorageForUpdate.find(
                (item: any) => item.rowId === rowId
              );
              console.log("+++++ newRowItem +++++ ", newRow);
              //ADJUST DATA IN COLS
              let colsInRowItem = newRow.cols.map((item: any) => {
                if (item.colId === cellId) {
                  return {
                    ...item,
                    childIdRef: "",
                    isControlItem: true,
                    controlComponent,
                  };
                } else {
                  return item;
                }
              });
              console.log("+++++ colsInRowItem +++++ ", colsInRowItem);
              newRowItem = {
                ...newRow,
                cols: colsInRowItem,
              };
              console.log("+++++ newRow +++++ ", newRow);

              versionStorageForUpdate = replaceChildInsideCellInGridPanelItems(
                col.colId,
                newRowItem,
                versionStorageForUpdate
              );
            }
            console.log("***** newRowItem ***** ", newRowItem);
            newGridPanelItems = replaceChildInsideCellInGridPanelItems(
              col.colId,
              newRowItem,
              versionStorage
            );
            versionStorage = newGridPanelItems;
          }
        });
      });
      console.log("newGridPanelItems => ********** ", newGridPanelItems);
      setPageConfigPanelItems(newGridPanelItems);
      // setComponentConfigVersion()
      setisFirstLoad(false);
    }
  }, [isFirstLoad]);

  const setComponentConfigVersion = () => {
    let versionConfigs = genPageContext.versionSubComponent;
    genPageContext.setVersionSubComponent(versionConfigs);
  };

  //ON FOCUS FIELD
  useEffect(() => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;

    if (!focusBoItem) return;
    console.log("main check onclick focusBoItem : ", focusBoItem);

    if (focusBoItem.includes("sub")) {
      clearOnClickBlankSpaceMainPageConfig();
      return;
    }

    let cellId = getCellIdFromFocusBoItem();
    let cellFocusEle = document.getElementById(cellId);
    let outerDivEle = document.getElementById("gridPanel");

    if (cellFocusEle && outerDivEle) {
      let { top, left } = getToolPosition(cellFocusEle, outerDivEle);

      if (isComponentTableField(focusBoItem)) {
        //main_component-r3
        setUpStateForTableComponent();
      } else if (isFocusOnComponent(focusBoItem)) {
        //main_component-r3
        setUpStateForComponent();
      } else if (isFocusOnField(focusBoItem)) {
        //main_cell-r1-c1_field
        setUpStateForField();
      } else if (isFocusOnComponentField(focusBoItem)) {
        //main_cell-r1-c2_control
        setUpStateForComponentField();
      } else {
        //main_cell-r1-c1
        setUpStateForCell();
      }

      setPositionTool({ left, top });
    } else {
      console.log("Element with ID 'myCell' not found.");
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //ACTION HANDLER
  const onChangeSearchCreateEditPageHandler = (input: string) => {
    // console.log("onChangeSearchCreateEditPageHandler input :: ", input);
    setMenuItemsOnSearch(input);

    //SHOW ALL MENU_ITEM
    showAllMenuItems();
  };

  const onChangeShowMenu = (type: string) => {
    // console.log("onChangeShowMenu type: ", type);
    if (type === BO) {
      setIsHideBoMenu((prev) => !prev);
    } else if (type === CONTROL) {
      setIsHideControlMenu((prev) => !prev);
    }
  };

  const addRowHandler = () => {
    setRowNum((prevRowNum) => prevRowNum + 1);
  };

  const addColHandler = () => {
    if (colNum > MAX_COL_NUM) return;
    setColNum((prevColNum) => prevColNum + 1);
  };

  const onAddChildInCellByBofieldItem = (
    cellId: string,
    componentId: string
  ) => {
    // console.log(
    //   "onAddChildInCellByBofieldItem item componentId => ",
    //   componentId
    // );

    let newRowItem = updateChildInCellWithBoFieldItem(
      cellId,
      componentId,
      gridPanelItems
    );
    let newGridPanelItems: any = replaceChildInsideCellInGridPanelItems(
      cellId,
      newRowItem,
      gridPanelItems
    );
    // setGridPanelItems(newGridPanelItems);
    setPageConfigPanelItems(newGridPanelItems);
  };

  const onAddChildInCellByControlItem = (
    cellId: string,
    componentId: string,
    isRowReplace?: boolean
  ) => {
    let newRowItem: any;
    if (isRowReplace) {
      newRowItem = updateChildInCellWithControlItemRowReplace(
        cellId,
        componentId
      );
      setPrepareUpdateComponentByCellIdAndComponentId(cellId, componentId);
    } else {
      newRowItem = updateChildInCellWithControlItemNotRowReplace(
        cellId,
        componentId
      );
    }

    // console.log(
    //   "onAddChildInCellByControlItem item newRowItem => ",
    //   newRowItem
    // );

    let newGridPanelItems: any = replaceChildInsideCellInGridPanelItems(
      cellId,
      newRowItem,
      gridPanelItems
    );
    // setGridPanelItems(newGridPanelItems);
    setPageConfigPanelItems(newGridPanelItems);
    onSetScreenControlConfigs(componentId, cellId);
  };

  const onSetScreenControlConfigs = (componentId: string, cellId: string) => {
    // console.log("cellId => ", cellId)
    switch (componentId) {
      case "ct1": //TAB
        setControlConfigRow(cellId, TAB);
        break;
      case "ct2": //BREADCRUMP
        setControlConfigRow(cellId, BREADCRUMP);
        break;
      case "ct3": //BUTTON
        setControlConfigCell(cellId, BUTTON);
        break;
      case "ct4": //ICON
        setControlConfigCell(cellId, ICON);
        break;
      case "ct5": //IMAGE
        setControlConfigCell(cellId, IMAGE);
        break;
      case "ct6": //TEXT
        setControlConfigCell(cellId, TEXT);
        break;
      case "ct7": //TABLE
        setControlConfigRow(cellId, TABLE);
        break;
    }
  };

  const setControlConfigCell = (cellId: string, confType: string) => {
    // console.log("genPageContext.conTrolconfigs => ", genPageContext.conTrolconfigs)
    let detailObj: any = {
      title: "Button",
      buttonType: "none",
      configType: BUTTON,
    };

    if (confType === ICON) {
      detailObj = {
        iconPath: "",
        configType: ICON,
      };
    } else if (confType === IMAGE) {
      detailObj = {
        imagePath: "",
        configType: IMAGE,
      };
    } else if (confType === TEXT) {
      detailObj = {
        text: "Show text",
        labelType: "NORMAL",
        configType: LABEL,
      };
    }

    let newUpdateComponentObj = {
      colId: cellId,
      detail: detailObj,
    };

    let updateConTrolconfigs = genPageContext.conTrolconfigs;
    let ControlContext = updateConTrolconfigs?.filter(
      (item: any) => item?.colId !== cellId
    );
    let newControlConf = [...ControlContext, newUpdateComponentObj];
    genPageContext.setConTrolconfigs(newControlConf);
    // console.log("newControlConf cell => ", newControlConf)
  };

  const setControlConfigRow = (cellId: string, confType: string) => {
    // console.log("Controls Config Type : ", confType)
    let rowId = cellId.split("-")[1];
    let newUpdateComponentObj = {
      rowId,
      configType: confType,
      componentConfig: [],
      parentSelected: "",
    };

    let updateConTrolconfigs = genPageContext.conTrolconfigs;
    let ControlContext = updateConTrolconfigs?.filter(
      (item: any) => item?.rowId !== rowId
    );
    let newControlConf = [...ControlContext, newUpdateComponentObj];
    genPageContext.setConTrolconfigs(newControlConf);
    // console.log("newControlConf row => ", newControlConf)
  };

  const onRemoveChildInCell = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    let focusBoItemSplit = focusBoItem.split("_");
    let idForRemove = focusBoItemSplit[0] + "_" + focusBoItemSplit[1]; //focusBoItem.split("_")[1];
    console.log("onOkConfirmDeleteHandler idForRemove => ", idForRemove);

    //GET NEW ROW AFTER REMOVE CHILD
    let newRowItem = removeChildInCell(idForRemove, gridPanelItems);
    // console.log("onOkConfirmDeleteHandler newRowItem => ", newRowItem);

    //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
    let newGridPanelItems: any = replaceChildInsideCellInGridPanelItems(
      idForRemove,
      newRowItem,
      gridPanelItems
    );
    // setGridPanelItems(newGridPanelItems);
    setPageConfigPanelItems(newGridPanelItems);

    // setFocusBoItem("");
    genPageContext.setItemFocusOnConfigPage("");

    //setIsCellToolOpen(false);
    setIsBoFieldToolOpen(false);
  };

  const setPrepareUpdateComponentByCellIdAndComponentId = (
    cellId: string,
    componentId: string
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let controlItem = controlItems.find(
      (item: any) => item?.id === componentId
    );
    let configType = controlItem?.controlName.toUpperCase();

    let newUpdateComponentObj = {
      rowId,
      configType,
      componentConfig: [],
      parentSelected: "",
    };

    let updateComponentsContext = genPageContext.updateComponents;
    let compContext = updateComponentsContext.filter(
      (item: any) => item?.rowId !== rowId
    );
    let newUpdateComponent = [...compContext, newUpdateComponentObj];
    genPageContext.setUpdateComponents(newUpdateComponent);
  };

  const onRemoveComponentInCell = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    let compPosition = focusBoItem.split("_")[1];
    let rowIdForRemove = compPosition.split("-")[1];
    // console.log("onRemoveComponentInCell rowIdForRemove => ", rowIdForRemove);

    //GET NEW ROW AFTER REMOVE CHILD
    let newRowItem = removeComponentInCell(
      rowIdForRemove,
      colNum,
      gridPanelItems
    );
    // console.log("onRemoveComponentInCell newRowItem => ", newRowItem);

    let newGridPanelItems: any = replaceRowWithNewRowItemByRowId(
      rowIdForRemove,
      newRowItem,
      gridPanelItems
    );
    console.log(
      "onRemoveComponentInCell newGridPanelItems => ",
      newGridPanelItems
    );
    // setGridPanelItems(newGridPanelItems);
    setPageConfigPanelItems(newGridPanelItems);

    // setFocusBoItem("");
    genPageContext.setItemFocusOnConfigPage("");
    //setIsCellToolOpen(false);
    setIsBoFieldToolOpen(false);

    let controlConfigs = genPageContext.conTrolconfigs;
    let ControlContext = controlConfigs?.filter(
      (item: any) => item?.rowId !== rowIdForRemove
    );
    genPageContext.setConTrolconfigs(ControlContext);
  };

  const addRowColInComponent = (type: "row" | "col") => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    let rowDetail = focusBoItem.split("_")[1];
    let rowId = rowDetail.split("-")[1];
    // console.log("onAddRowInComponentByRowId rowId => ", rowId);

    let rowItem: any = gridPanelItems.find((item: any) => item.rowId === rowId);
    console.log("onAddRowInComponentByRowId rowItem => ", rowItem);
    if (!rowItem) return;

    //GET DATA FROM PROPS OF COMPONENT
    let props = rowItem?.controlComponent?.props;
    let oRow = rowItem?.controlComponent?.props.rowNum;
    let oCol = rowItem?.controlComponent?.props.colNum;
    let oMainRowId = rowItem?.rowId; //rowItem?.controlComponent?.props.colNum;

    //ADD ROW OR COL IN COMPONENT PROPS
    let rowNum = type === "row" ? oRow + 1 : oRow;
    let colNum = type === "col" ? oCol + 1 : oCol;

    //GET NEW_ROW_ITEM WITH PROPS AND NEW ROW_COL_NUMBER
    let newRowItem: any = {
      ...rowItem,
      controlComponent: {
        ...rowItem?.controlComponent,
        props: {
          ...props,
          mainRowId: oMainRowId,
          isFocusParent: isFocusOnParent,
          boFieldItems,
          controlItems,
          rowNum,
          colNum,
        },
      },
    };
    // console.log("onAddRowInComponentByRowId newRowItem => ", newRowItem);

    //GET NEW_PANEL_ITEMS WITH NEW UPDATE COMPONENT
    let newGridPanelItems: any = replaceRowWithNewRowItemByRowId(
      rowId,
      newRowItem,
      gridPanelItems
    );
    // console.log(
    //   "onAddRowInComponentByRowId newGridPanelItems => ",
    //   newGridPanelItems
    // );
    // setGridPanelItems(newGridPanelItems);
    setPageConfigPanelItems(newGridPanelItems);
    onClickBlankSpace();
  };

  const onAddRowInComponentByRowId = () => {
    addRowColInComponent("row");
  };

  const onAddColInComponentByRowId = () => {
    addRowColInComponent("col");
  };

  //UTILITY FUNCTION
  const isComponentTableField = (focusBoItem: string) => {
    let rowId = focusBoItem.split("-")[1];
    let updateComponents = genPageContext.updateComponents;
    let updateItem = updateComponents.find(
      (item: any) => item?.rowId === rowId
    );
    return updateItem?.configType;
  };

  const isFocusOnComponent = (focusBoItem: string) => {
    // TAB, BREADCRUMP
    return focusBoItem.includes("component");
  };

  const isFocusOnField = (focusBoItem: string) => {
    // BO_FIELD
    return focusBoItem.includes("field");
  };

  const isFocusOnComponentField = (focusBoItem: string) => {
    //BUTTON, ICON ...
    return focusBoItem.includes("control");
  };

  const setUpStateForComponent = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsCellComponentToolOpen(true);
    setIsCellComponentTableToolOpen(false);
  };

  const setUpStateForTableComponent = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsCellComponentToolOpen(false);
    setIsCellComponentTableToolOpen(true);
  };

  const setUpStateForComponentField = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsCellComponentToolOpen(false);
    setIsCellComponentTableToolOpen(false);
  };

  const setUpStateForField = () => {
    setIsBoFieldToolOpen(true);
    setIsCellToolOpen(false);
    setIsCellComponentToolOpen(false);
    setIsCellComponentTableToolOpen(false);
  };

  const setUpStateForCell = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(true);
    setIsCellComponentToolOpen(false);
    setIsCellComponentTableToolOpen(false);
  };

  const setPageConfigPanelItems = (newGridItems: any) => {
    setGridPanelItems(newGridItems);
    genPageContext.setPanelItems(newGridItems);
  };

  const getCellIdFromFocusBoItem = (): string => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    // console.log("getCellIdFromFocusFocusBoItem focusBoItem => ", focusBoItem);
    if (!focusBoItem) return "";

    if (focusBoItem.includes("component")) {
      //main_component-r3
      return focusBoItem.split("-")[1]; //r3
    } else if (focusBoItem.includes("field")) {
      //main_cell-r2-c1_field
      let focusBoItemSplit = focusBoItem.split("_");
      return focusBoItemSplit[0] + "_" + focusBoItemSplit[1]; //main_cell-r2-c1
    } else {
      return focusBoItem; //main_cell-r2-c1
    }
  };
  const showAllMenuItems = () => {
    setIsHideBoMenu(false);
    setIsHideControlMenu(false);
  };

  const setMenuItemsOnSearch = (search: string) => {
    let searchWord: string = search.toLowerCase();

    if (!search) {
      setBoFieldItems([...boFieldItemsSearch]);
      setControlItems([...controlItemsSearch]);
    }

    //BO_FIELD_ITEMS
    let newBoFieldItems = [...boFieldItemsSearch].filter((item: any) => {
      let itemName = item.boField.toLowerCase();
      return itemName.includes(searchWord);
    });
    setBoFieldItems(newBoFieldItems);

    //CONTROL_ITEMS
    let newControlItems = [...controlItemsSearch].filter((item: any) => {
      let itemName = item.controlName.toLowerCase();
      return itemName.includes(searchWord);
    });
    setControlItems(newControlItems);
  };

  const setNewBoFieldItems = (boFieldItems: any) => {
    setBoFieldItems(boFieldItems);
    setBoFieldItemsSearch(boFieldItems);
    genPageContext.setBoFieldForConfig(boFieldItems);
  };

  const setNewControlItems = (controlItems: any) => {
    setControlItems(controlItems);
    setControlItemsSearch(controlItems);
  };

  const actionPrepareMenuItemsOnload = () => {
    //BO_FIELD_ITEMS
    let boFieldItems = genPageContext.boFieldForData;
    let newBoFieldItems = getBoFieldItemsFromContextItems(boFieldItems);
    // console.log(
    //   "actionPrepareBoFieldItemsOnload newBoFieldItems ",
    //   newBoFieldItems
    // );
    setNewBoFieldItems(newBoFieldItems);

    //CONTROL_ITEMS
    setNewControlItems([...initControlItems]);
  };

  const getBoFieldItemsFromContextItems = (contextItems: any) => {
    let result = [];
    result = contextItems.map((item: any) => {
      const id = uuidv4();
      let boFieldName = item.boName + "." + item.boFieldName;
      return {
        id: item?.key || id,
        boField: boFieldName,
      };
    });
    return result;
  };

  const getRowColItems = () => {
    let row: number = rowNum;
    let col: number = colNum;

    let result: any = [];
    //ROW
    for (let r = 1; r <= row; r++) {
      let rowObj: any = {
        rowId: "r" + r,
        isControlItem: false,
        cols: [],
      };

      //COLUMN main_cell-r2-c1
      for (let c = 1; c <= col; c++) {
        let colId = getColIdByRowIdAndColId(r, c);
        rowObj.cols.push({
          colId,
          childIdRef: "",
          isControlItem: false,
          controlComponent: null,
        });
      }
      result.push(rowObj);
    }
    // console.log("getRowColItems => ", result);
    return result;
  };

  const onSetFocusItem = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    console.log("onSetFocusItem id => ", id);
    e.stopPropagation();
    console.log("genPageContext =>", genPageContext);
    // setFocusBoItem(id);
    genPageContext.setItemFocusOnConfigPage(id);
  };

  const getBoNameFromBoId = (id: string) => {
    // console.log("getBoNameFromBoId id => ", id);
    let boItem = boFieldItems.find((item) => item.id === id);
    return boItem?.boField;
  };

  const updateChildInCellWithControlItemNotRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);

    let props = controlComponent?.props;
    controlComponent = {
      ...controlComponent,
      props: {
        ...props,
        cellId,
      },
    };

    //GET NEW_ROW_ITEM
    let rowId = cellId.split("-")[1]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
    let newRowItem: any = gridPanelItems.find(
      (item: any) => item.rowId === rowId
    );
    console.log("gridPanelItems <<<<<>>>>> ", gridPanelItems);
    //ADJUST DATA IN COLS
    let colsInRowItem = newRowItem.cols.map((item: any) => {
      if (item.colId === cellId) {
        return {
          ...item,
          childIdRef: "",
          isControlItem: true,
          controlComponent,
        };
      } else {
        return item;
      }
    });
    // console.log("new colsInRowItem => ", colsInRowItem);
    newRowItem = {
      ...newRowItem,
      cols: colsInRowItem,
    };
    return newRowItem;
  };

  const updateChildInCellWithControlItemRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);
    console.log(
      "updateChildInCellWithControlItem controlComponent => ",
      controlComponent
    );

    let rowId = cellId.split("-")[1]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
    let newRowItem: any = gridPanelItems.find(
      (item: any) => item.rowId === rowId
    );

    let newControlComponent = defineRowIdInControlComponent(
      controlComponent,
      rowId
    );
    // console.log(
    //   "updateChildInCellWithControlItem newControlComponent => ",
    //   newControlComponent
    // );

    let colId = getColIdOneColumnInRowByRowId(rowId);
    let gPanelItem = [
      {
        colId,
        childRef: "",
      },
    ];
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
      isControlItem: true,
      controlComponent: newControlComponent,
    };
    // console.log("newRowItem => ", newRowItem);
    return newRowItem;
  };

  //MODAL DELETE CONFIRMATION
  const onClickRemoveChildHandler = () => {
    setIsOpenDeleteConfirm(true);
  };

  const onOkConfirmDeleteHandler = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    if (!focusBoItem) return;

    if (focusBoItem.includes("component")) {
      onRemoveComponentInCell();
    } else {
      onRemoveChildInCell();
    }

    clearOnClickBlankSpaceMainPageConfig();
    setIsOpenDeleteConfirm(false);
  };

  const onCancelConfirmDeleteHandler = () => {
    setIsOpenDeleteConfirm(false);
  };

  const onClickBlankSpace = () => {
    console.log("onClickBlankSpaceMainPageConfig");
    clearOnClickBlankSpaceMainPageConfig();
    genPageContext.setItemFocusOnConfigPage("");
  };

  const clearOnClickBlankSpaceMainPageConfig = () => {
    console.log("clearOnClickBlankSpaceMainPageConfig");
    // setFocusBoItem("");

    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsCellComponentToolOpen(false);
    setIsCellComponentTableToolOpen(false);
  };

  return (
    <div className={styles.createEditPageContainer}>
      <div className={styles.toolSideContainer}>
        <div className={styles.toolSideWrapper}>
          {/* <div className={styles.titleWrapper}>Tools</div> */}
          <div className={styles.searchWrapper}>
            <Input
              placeholder={"Search"}
              prefix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
              className={styles.searchInput}
              onChange={(e) =>
                onChangeSearchCreateEditPageHandler(e.currentTarget.value)
              }
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.boFieldWrapper}>
              <div
                className={styles.titleWrapper}
                onClick={() => onChangeShowMenu(BO)}
              >
                <div className={styles.title}>BO Field</div>
                <StoryEditEntGenPageArrowDown />
              </div>

              <div
                className={isHideBoMenu ? styles.boItemsHide : styles.boItems}
              >
                {boFieldItems.map((item) => {
                  return (
                    <BoFieldItem
                      key={item.id}
                      item={item}
                      itemStyles={styles.boItem}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.controlWrapper}>
              <div
                className={styles.titleWrapper}
                onClick={() => onChangeShowMenu(CONTROL)}
              >
                <div className={styles.title}>Controls</div>
                <StoryEditEntGenPageArrowDown />
              </div>

              <div
                className={
                  isHideControlMenu
                    ? styles.controlItemsHide
                    : styles.controlItems
                }
              >
                {controlItems.map((item) => {
                  return (
                    <ControlItem
                      key={item.id}
                      item={item}
                      // itemStyles={
                      //   item.id === "ct2"
                      //     ? styles.controlItem2Col
                      //     : styles.controlItem
                      // }
                      itemStyles={styles.controlItem}
                      itemTextStyles={styles.controlItemText}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contentSideContainer} onClick={onClickBlankSpace}>
        <div className={styles.pageConfigWrapper}>
          <table id="gridPanel" className={styles.table}>
            <tbody>
              {gridPanelItems.map((item: any) => {
                // CHECK CONTROL_ITEM CONDITION HERE
                return item.isControlItem ? (
                  // IS CONTROL_ITEM
                  <tr
                    key={item.rowId}
                    id={item.rowId}
                    className={styles.tableTr}
                    onClick={(e) =>
                      onSetFocusItem(e, "main_component-" + item.rowId)
                    }
                  >
                    <td
                      colSpan={colNum}
                      className={
                        genPageContext.itemFocusOnConfigPage ===
                        "main_component-" + item.rowId
                          ? styles.tableTdComponentFocus
                          : styles.tableTd
                      }
                    >
                      {item.controlComponent}
                    </td>
                  </tr>
                ) : (
                  // IS BOFIELD_ITEM
                  <tr
                    key={item.rowId}
                    id={item.rowId}
                    className={styles.tableTr}
                  >
                    {item.cols.map((item: any) => {
                      return (
                        <td
                          key={item.colId}
                          id={item.colId}
                          className={[
                            dragOverItem === item.colId
                              ? styles.tableTdDragOver
                              : styles.tableTd,
                            genPageContext.itemFocusOnConfigPage === item.colId
                              ? styles.tableTdFocus
                              : "",
                          ].join(" ")}
                          onDrop={(e) => handleDrop(e)}
                          onDragOver={(e) => handleDragOver(e)}
                          onDragLeave={(e) => handleDragLeave(e)}
                          onClick={(e) => onSetFocusItem(e, item.colId)}
                        >
                          {/* COMPONENT FIELD */}
                          {item.isControlItem ? (
                            <div
                              className={
                                genPageContext.itemFocusOnConfigPage ===
                                item.colId + "_control"
                                  ? styles.controlComponentCellFocus
                                  : styles.controlComponentCell
                              }
                              onClick={(e) =>
                                onSetFocusItem(e, item.colId + "_control")
                              }
                            >
                              {item.controlComponent}
                            </div>
                          ) : item.childIdRef ? (
                            // BO FIELD
                            <div
                              className={
                                genPageContext.itemFocusOnConfigPage ===
                                item.colId + "_field"
                                  ? styles.insideTableTdFocus
                                  : styles.insideTableTd
                              }
                              onClick={(e) =>
                                onSetFocusItem(e, item.colId + "_field")
                              }
                            >
                              {getBoNameFromBoId(item.childIdRef)}
                            </div>
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={styles.addColBtn} onClick={() => addColHandler()}>
            <StoryEditEntAdd className={styles.actionIcon} />
          </div>
          <div className={styles.addRowBtnContainer}>
            <div className={styles.addRowBtn} onClick={() => addRowHandler()}>
              <StoryEditEntAdd className={styles.actionIcon} />
            </div>
          </div>

          {/* TOOL */}
          <BoFieldTool
            isShow={isBoFieldToolOpen}
            left={positionTool?.left as number}
            top={positionTool?.top as number}
            onClick={onClickRemoveChildHandler}
          />
          <CellTool
            isShow={isCellToolOpen}
            left={positionTool?.left as number}
            top={positionTool?.top as number}
            onClickDelete={onClickRemoveChildHandler}
          />
          <CellComponentTool
            isShow={isCellComponentToolOpen}
            left={positionTool?.left as number}
            top={positionTool?.top as number}
            onRemoveComponent={onClickRemoveChildHandler}
            onAddRow={onAddRowInComponentByRowId}
            onAddCol={onAddColInComponentByRowId}
          />
          <CellComponentTableTool
            isShow={isCellComponentTableToolOpen}
            left={positionTool?.left as number}
            top={positionTool?.top as number}
            onRemoveComponent={onClickRemoveChildHandler}
            onAddCol={onAddColInComponentByRowId}
          />

          {/* CONFIRM DELETE */}
          <ConfirmDeleteModal
            modalDetail={{
              title: "Delete BO Field?",
              description: `Are you sure you want to delete this BO Field? This action cannot be
      undone.`,
            }}
            isModalOpen={isOpenDeleteConfirm}
            onOk={onOkConfirmDeleteHandler}
            onCancel={onCancelConfirmDeleteHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateEditPageConfigStep4;
