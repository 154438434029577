import TextArea from 'antd/es/input/TextArea';
import React, { ChangeEvent, useEffect, useState } from 'react'

type Props = {  processMessage: string;
    onChange: (value: string) => void;}

export default function ResultEditor({ processMessage, onChange }: Props) {
    let [textValue, setTextValue] = useState<string>(processMessage);

    useEffect(() => {
      if(textValue) {
          onChange(textValue)
      }
  
    }, [textValue])
  
    const onChangeResult = (e: ChangeEvent<HTMLTextAreaElement>) => {
      let newValue = e.currentTarget.value;
      setTextValue(newValue);
    };
    return (
      <>
        <TextArea
          value={textValue}
          onChange={onChangeResult}
          cols={100}
          autoSize={true}
        />
      </>
    );
}