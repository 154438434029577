import React from "react";

const StoryEditEntGenPageRemove = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 11 10"
      width={width || "11"}
      height={height || "10"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0018 0.650239C10.8455 0.494013 10.6336 0.40625 10.4126 0.40625C10.1917 0.40625 9.97974 0.494013 9.82347 0.650239L6.0018 4.47191L2.18013 0.650239C2.02386 0.494013 1.81194 0.40625 1.59097 0.40625C1.37 0.40625 1.15807 0.494013 1.0018 0.650239C0.845575 0.806512 0.757812 1.01844 0.757812 1.23941C0.757812 1.46038 0.845575 1.6723 1.0018 1.82857L4.82347 5.65024L1.0018 9.47191C0.845575 9.62818 0.757813 9.8401 0.757812 10.0611C0.757813 10.282 0.845575 10.494 1.0018 10.6502C1.15807 10.8065 1.37 10.8942 1.59097 10.8942C1.81194 10.8942 2.02386 10.8065 2.18013 10.6502L6.0018 6.82857L9.82347 10.6502C9.97974 10.8065 10.1917 10.8942 10.4126 10.8942C10.6336 10.8942 10.8455 10.8065 11.0018 10.6502C11.158 10.494 11.2458 10.282 11.2458 10.0611C11.2458 9.8401 11.158 9.62818 11.0018 9.47191L7.18014 5.65024L11.0018 1.82857C11.158 1.6723 11.2458 1.46038 11.2458 1.23941C11.2458 1.01844 11.158 0.806512 11.0018 0.650239Z"
        // fill="white"
      />
    </svg>
  );
};
export default StoryEditEntGenPageRemove;
