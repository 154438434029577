type DisplayViewSelectTypes = {
  [key: string]: {
    value: string;
    label: string;
  }[];
};
const DISPLAY_VIEW_SELECT: DisplayViewSelectTypes = {
  text: [
    { value: "text-show_as_label", label: "Show as Label" },
    { value: "text-input_read_only", label: "Input Text Read Only" },
  ],
  textarea: [
    { value: "textarea-show_as_label", label: "Show as Label" },
    { value: "textarea-input_read_only", label: "Input Text Read Only" },
  ],
  password: [
    { value: "show_password", label: "Show Password" },
    { value: "hide_password", label: "Hide Password" },
  ],
  email: [
    { value: "show_as_label", label: "Show as Label" },
  ],
  date: [
    { value: "cs", label: "C.S." },
    { value: "be", label: "B.E." },
  ],
  radio: [
    { value: "radio-show_as_label", label: "Show as Label" },
    { value: "radio-read_only", label: "Read Only" },
  ],
  number: [
    { value: "currency", label: "Currency($)" },
    { value: "percent", label: "Percent(%)" },
    { value: "phone_number", label: "Phone Number(9)" },
    { value: "mobile_number", label: "Mobile Number(10)" },
    { value: "decimal_2_unit", label: "Decimal(2 Unit) 00.00" },
    { value: "zipcode", label: "Zip Code (5)" },
    { value: "passport", label: "Passport (9)" },
    { value: "ccv", label: "CCV (3)" },
    { value: "credit_debit", label: "Credit/Debit Card (16)" },
    { value: "citizen_id", label: "Citizen ID (13)" },
    { value: "otp", label: "OTP (6)" },
    { value: "lat", label: "Latitude (6 Unit) 00.000000" },
    { value: "lon", label: "Longtitude (6 Unit) 00.000000" },
    { value: "temperature", label: "Temperature" },
    { value: "height", label: "Height" },
    { value: "weight", label: "Weight" },
    { value: "area", label: "Area" },
    { value: "distance", label: "Distance" },
    { value: "attitude", label: "Attitude" },
  ],
  dropdown: [
    { value: "dropdown", label: "Dropdown" },
  ],
  file_input: [
    { value: "filename", label: "Filename" },
    { value: "filename_with_icon", label: "Filename with icon" },
  ],
  image_input: [{ value: "display_image", label: "Display Image" }],
  checkbox: [
    { value: "checkbox-show_as_label", label: "Show as Label" },
    { value: "checkbox-input_read_only", label: "Input Text Read Only" },
  ],
};

export { DISPLAY_VIEW_SELECT };
