type SelectType = {
  value: string;
  label: string;
  children?: ChildrenType[];
};
type ChildrenType = {
  value: string;
  label: string;
  children?: SelectType[];
};
type DisplayEditSelectTypes = {
  [key: string]: {
    fieldType: "dropdown" | "text" | "cascader";
    showText?: string;
    selects?: SelectType[];
  };
};

const DISPLAY_EDIT_SELECT: DisplayEditSelectTypes = {
  text: {
    fieldType: "dropdown",
    selects: [
      { value: "text", label: "Text" },
      { value: "textarea", label: "Text Area" },
      { value: "password_input", label: "Password Input" },
    ],
  },
  textarea: {
    fieldType: "text",
    showText: "Text Area",
  },
  password: {
    fieldType: "text",
    showText: "Password",
  },
  email: {
    fieldType: "text",
    showText: "example@oneweb.com",
  },
  date: {
    fieldType: "text",
    showText: "DD/MM/YYYY",
  },
  radio: {
    fieldType: "dropdown",
    selects: [{ value: "radio", label: "Radio" }],
  },
  dropdown: {
    fieldType: "dropdown",
    selects: [{ value: "dropdown", label: "Dropdown" }],
  },
  file_input: {
    fieldType: "dropdown",
    selects: [{ value: "drag_drop", label: "Drag and Drop" }],
  },
  image_input: {
    fieldType: "text",
    showText: "Upload Image",
  },
  checkbox: {
    fieldType: "text",
    showText: "CheckBox",
  },
  //NEED TO CHANGE
  number: {
    fieldType: "cascader",
    selects: [
      {
        value: "currency",
        label: "Currency",
        children: [
          { value: "thb", label: "THB (฿)" },
          { value: "usd", label: "USD ($)" },
          { value: "gbp", label: "GBP (£)" },
          { value: "eur", label: "EUR (€)" },
          { value: "jpy", label: "JPY (¥)" },
          { value: "hkd", label: "HKD (HK$)" },
          { value: "sgd", label: "SGD (S$)" },
          { value: "cny", label: "CNY (CN¥)" },
        ],
      },
      { value: "percent", label: "Percent(%)" },
      { value: "phone_number", label: "Phone Number(9)" },
      {
        value: "mobile_number",
        label: "Mobile Number(10)",
        children: [
          { value: "mobile_country", label: "+66 2 1234 5678" },
          { value: "mobile_zero", label: "01 2345 6789" },
        ],
      },
      { value: "decimal_2_unit", label: "Decimal(2 Unit) 00.00" },
      { value: "zipcode", label: "Zip Code (5)" },
      { value: "passport", label: "Passport (9)" },
      { value: "ccv", label: "CCV (3)" },
      { value: "credit_debit", label: "Credit/Debit Card (16)" },
      { value: "citizen_id", label: "Citizen ID (13)" },
      { value: "otp", label: "OTP (6)" },
      { value: "lat", label: "Latitude (6 Unit) 00.000000" },
      { value: "lon", label: "Longtitude (6 Unit) 00.000000" },
      {
        value: "temperature",
        label: "Temperature",
        children: [
          { value: "c", label: "°C" },
          { value: "f", label: "°F" },
        ],
      },
      {
        value: "height",
        label: "Height",
        children: [
          { value: "cm", label: "cm" },
          { value: "m", label: "m" },
        ],
      },
      {
        value: "weight",
        label: "Weight",
        children: [
          { value: "g", label: "g" },
          { value: "kg", label: "kg" },
          { value: "lb", label: "lb" },
        ],
      },
      {
        value: "area",
        label: "Area",
        children: [
          { value: "sq_wa", label: "sq wa" },
          { value: "ngan", label: "Ngan" },
          { value: "rai", label: "Rai" },
          { value: "acre", label: "Acre" },
          { value: "sq_ft", label: "sq ft" },
        ],
      },
      {
        value: "distance",
        label: "Distance",
        children: [
          { value: "dis_in", label: "in" },
          { value: "dis_mi", label: "mi" },
          { value: "dis_ft", label: "ft" },
        ],
      },
      {
        value: "attitude",
        label: "Attitude",
        children: [
          { value: "at_in", label: "in" },
          { value: "at_mi", label: "mi" },
          { value: "at_ft", label: "ft" },
        ],
      },
    ],
  },
};

export { DISPLAY_EDIT_SELECT };
