import React from "react";

const StoryEditComponentPlay = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 11 14"
      width={width || "11"}
      height={height || "14"}
      className={className}
      style={style}
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.60092 4.81487L4.43342 1.0232C4.02981 0.727661 3.55225 0.549674 3.05366 0.508978C2.55508 0.468282 2.05497 0.566467 1.60878 0.792646C1.1626 1.01882 0.787771 1.36416 0.525865 1.79036C0.263959 2.21656 0.125209 2.70696 0.125 3.2072V10.7905C0.125089 11.291 0.263847 11.7817 0.525874 12.208C0.787901 12.6344 1.16295 12.9799 1.60939 13.2061C2.05584 13.4322 2.55622 13.5303 3.05501 13.4893C3.5538 13.4483 4.03148 13.27 4.43504 12.974L9.60254 9.18233C9.94556 8.93078 10.2245 8.60196 10.4168 8.22253C10.6091 7.84309 10.7092 7.4237 10.7092 6.99833C10.7092 6.57296 10.6091 6.15357 10.4168 5.77413C10.2245 5.39469 9.94556 5.06588 9.60254 4.81433L9.60092 4.81487ZM8.95958 8.30862L3.79208 12.1003C3.54997 12.2772 3.26362 12.3837 2.96473 12.4079C2.66584 12.4322 2.36607 12.3733 2.0986 12.2377C1.83113 12.1021 1.60639 11.8951 1.44927 11.6397C1.29214 11.3843 1.20875 11.0904 1.20833 10.7905V3.2072C1.20533 2.90677 1.28714 2.61158 1.44438 2.35555C1.60162 2.09953 1.82789 1.89306 2.09721 1.75987C2.32627 1.64335 2.57959 1.58248 2.83658 1.5822C3.18102 1.58352 3.51595 1.69535 3.79208 1.90124L8.95958 5.69291C9.16514 5.84387 9.33228 6.0411 9.44749 6.26863C9.56269 6.49617 9.62272 6.74762 9.62272 7.00266C9.62272 7.2577 9.56269 7.50916 9.44749 7.73669C9.33228 7.96423 9.16514 8.16145 8.95958 8.31241V8.30862Z"
        // fill="#ABABAB"
      />
    </svg>
  );
};
export default StoryEditComponentPlay;
