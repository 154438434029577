import { Checkbox, CheckboxProps, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./SearchFilterConfigStep2.module.scss";

type Props = {
  searchFilterItem: SearchFilterItemType;
  onChangeDefaultSearchItem: (id: string, defaultSearch: boolean) => void;
  onRemoveItem: (id: string) => void;
};
type SearchFilterItemType = {
  id: string;
  boFieldName: string;
  isDefaultSearch: boolean;
};

function SearchFilterItem({
  searchFilterItem,
  onChangeDefaultSearchItem,
  onRemoveItem,
}: Props) {
  let [isDefaultSearch, setIsDefaultSearch] = useState<boolean>(
    searchFilterItem.isDefaultSearch
  );
  let [searchFilterItemID, setSearchFilterItemID] = useState<string>("");

  useEffect(() => {
    let sFilterItemID = searchFilterItem.id;
    setSearchFilterItemID(sFilterItemID);
  }, []);

  const onChangeIsDefaultSearch: CheckboxProps["onChange"] = (e) => {
    let isChecked = e.target.checked;
    onChangeDefaultSearchItem(searchFilterItem.id, isChecked);
    setIsDefaultSearch(isChecked);
  };
  const onClickDeleteSearchFilterHandler = (id: string) => {
    console.log("onClickDeleteSearchFilterHandler id :: ", id);
    onRemoveItem(id);
  };
  return (
    <tr key={searchFilterItem.id}>
      <td>{searchFilterItem.boFieldName}</td>
      <td style={{ textAlign: "center" }}>
        <Checkbox
          checked={isDefaultSearch}
          onChange={onChangeIsDefaultSearch}
        />
      </td>
      <td className={styles.tdSetCenter}>
        <div
          className={styles.actionIconWrapper}
          onClick={() => onClickDeleteSearchFilterHandler(searchFilterItem.id)}
        >
          <StoryEditEntDelete className={styles.actionIcon} />
        </div>
      </td>
    </tr>
  );
}

export default SearchFilterItem;
