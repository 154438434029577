import { sortBy } from "lodash";
import { BO_INIT_KEY, TABLE_INIT_KEY } from "src/constants/DiagramInitKey";
import {
  FlowBo,
  FlowMapping,
  FlowTable,
  GenerateMFRequest,
  GetTableAndColumnRequest,
} from "src/types/request.type";
import { TableType } from "src/types/response.type";

const getNewBoWithMoveChildToUnderParent = (allBo: any) => {
  //GET NEW RESULT TO MOVE CHILD TO UNDER OF PARENT
  let BoForRemove: any = [];
  let newResult: any = [];
  for (const bo of allBo) {
    //bolist
    let fields: any = bo?.fields;
    let boAndField: any = {
      boName: bo.boName,
      fields: [],
    };
    let allFieldInBo = [];
    for (const field of fields) {
      let obj = {};
      if (field?.type === "Object") {
        let fieldTypeObject = allBo.find(
          (item: any) => item.boName === field?.fields
        );
        BoForRemove.push(field?.fields);
        obj = {
          fieldName: field.fieldName,
          type: field?.type,
          fields: fieldTypeObject?.fields,
        };
      } else {
        obj = {
          fieldName: field.fieldName,
          type: field?.type,
        };
      }
      allFieldInBo.push(obj);
    }
    boAndField.fields = allFieldInBo;
    newResult.push(boAndField);
  }
  //  console.log("getBoFromJsonBoMapping forRemove => ", BoForRemove)
  //  console.log("getBoFromJsonBoMapping newResult => ", newResult)

  //REMOVE WITH REMOVE_LIST
  let newResp = newResult.filter(
    (item: any) => !BoForRemove.includes(item.boName)
  );

  return newResp;
};

export const getBoFromJsonBoMapping = (boMapping: any) => {
  let boList = boMapping?.nodeDataArray;

  //GET ONLY BO SIDE FROM BO_MAPPING
  let onlyBo = boList.filter(
    (bo: any) => bo.group === "boGroup" && bo.key !== BO_INIT_KEY
  );

  //COLLECT ONLY TYPE OBJECT
  let collectTypeObjects = onlyBo.filter(
    (bo: any) => bo.typeDesc.toLowerCase() === "object"
  );

  //CREATE BO_LIST BY OBJECT TYPE
  let allBo = [];
  for (const objType of collectTypeObjects) {
    let cols: any = onlyBo.filter(
      (item: any) => objType.key === item.parentKey
    );

    let fields = [];
    // console.log("getBoFromJsonBoMapping cols => ", cols)
    for (const col of cols) {
      let type = col.typeDesc.toLowerCase();
      let field = {};
      if (type === "object") {
        field = {
          fieldName: col.paramName,
          type: col.typeDesc,
          fields: col.paramName,
        };
      } else {
        field = {
          fieldName: col.paramName,
          type: col.typeDesc,
        };
      }
      fields.push(field);
    }

    let boGroup = {
      boName: objType.paramName || "",
      fields,
    };
    allBo.push(boGroup);
  }

  console.log("getBoFromJsonBoMapping allBo => ", allBo);

  //MOVE CHILD TO PARENT
  let newResp = getNewBoWithMoveChildToUnderParent(allBo);
  console.log("getBoFromJsonBoMapping newResp => ", newResp);

  return newResp;
};

export const getSelectBoFieldFromJsonBoMapping = (boMapping: any) => {
  let result = [];
  let boList = boMapping?.nodeDataArray;

  //GET ONLY BO SIDE FROM BO_MAPPING
  let onlyBo = boList.filter(
    (bo: any) => bo.group === "boGroup" && bo.key !== BO_INIT_KEY
  );

  //COLLECT ONLY TYPE OBJECT
  let collectTypeObjects = onlyBo.filter(
    (bo: any) => bo.typeDesc.toLowerCase() === "object"
  );
  console.log("collectTypeObjects => ", collectTypeObjects);
  //CREATE BO_LIST BY OBJECT TYPE
  for (const objType of collectTypeObjects) {
    let cols: any = onlyBo.filter(
      (item: any) => objType.key === item.parentKey
    );
    let boGroup = cols.map((item: any) => {
      return {
        boName: objType.paramName || "",
        boFieldName: item.paramName,
        key: item.key,
        type: item.typeDesc,
        isPrimaryKey: item.isPrimaryKey || false,
        foreignKey: item.foreignKey || "",
        rootPath: getFieldRootPathByKey(item.key, onlyBo),
      };
    });
    result.push(...boGroup);
  }
  return result;
};

const getFieldRootPathByKey = (key: string, boGroupList: any) => {
  let result = "";

  const getParentRecursive = (curKey: string) => {
    if (!curKey) return;

    let curParentByKey = boGroupList.find((item: any) => item.key === curKey);
    if (!curParentByKey) return;

    result = result
      ? curParentByKey.paramName + "." + result
      : curParentByKey.paramName;

    getParentRecursive(curParentByKey.parentKey);
  };

  getParentRecursive(key);
  return result;
};

export const getMappingRootPathFromJsonBoMapping = (
  boMapping: any
): FlowMapping[] => {
  let result: FlowMapping[] = [];
  let boList = boMapping?.nodeDataArray;
  let removedInit = boList.filter(
    (bo: any) => bo.key !== TABLE_INIT_KEY && bo.key !== BO_INIT_KEY
  );
  let linkDataArray = boMapping?.linkDataArray;

  let mappingList = linkDataArray.filter(
    (link: any) => link.category === "Mapping"
  );
  for (const mapping of mappingList) {
    let rootPathFrom = getFieldRootPathByKey(mapping.from, removedInit);
    let rootPathTo = getFieldRootPathByKey(mapping.to, removedInit);
    let mappingItem = {
      pathFrom: rootPathFrom,
      pathTo: rootPathTo,
    };
    // console.log(
    //   "getMappingRootPathFromJsonBoMapping mappingItem => ",
    //   mappingItem
    // );
    result.push(mappingItem);
  }
  return result;
};

export const getDBFromDBConnection = (dbConnect: GetTableAndColumnRequest) => {
  let jdbcUrl = "";

  let dbType = dbConnect.db_type.toLowerCase();
  let hostName = dbConnect.host_name;
  let port = dbConnect.host_port;
  let dbName = dbConnect.db_name;
  let driver = dbConnect.db_type;

  // driver
  // PostgreSQL
  // MySql
  // Oracle
  // DB2
  // Tibero
  switch (dbType) {
    case "postgresql":
      jdbcUrl = `jdbc:postgresql://${hostName}:${port}/${dbName}`;
      driver = "PostgreSQL";
      break;
    case "mysql":
      jdbcUrl = `jdbc:mysql://${hostName}:${port}/${dbName}`;
      driver = "MySql";
      break;
    case "oracle":
      jdbcUrl = `jdbc:oracle:thin:@${hostName}:${port}:${dbName}`;
      driver = "Oracle";
      break;
    case "db2":
      jdbcUrl = `jdbc:db2://${hostName}:${port}/${dbName}`;
      driver = "DB2";
      break;
    case "tibero":
      jdbcUrl = `jdbc:tibero:thin:@${hostName}:${port}:${dbName}`;
      driver = "Tibero";
      break;
    default:
      jdbcUrl = `jdbc:postgresql://${hostName}:${port}/${dbName}`;
      driver = "PostgreSQL";
      break;
  }
  let result = {
    jndi: dbConnect.jndi_name,
    driver: driver,
    jdbcUrl,
    jdbcUsername: dbConnect.db_username,
    jdbcPassword: dbConnect.db_password,
  };
  return result;
};

export const getMappingFromJsonBoMapping = (
  boMapping: any,
  tableColResp: TableType[]
) => {
  let result: any = [];
  let boList = boMapping?.nodeDataArray;
  let linkDataArray = boMapping?.linkDataArray;

  //MAPPING DATA FROM TABLE_COL AND BO_MAPPING
  let mappingList = linkDataArray.filter(
    (link: any) => link.category === "Mapping"
  );
  let mappingWithDetail: any = [];
  for (const mp of mappingList) {
    //MAPPING DATA FOR PATH_FROM
    let pathFrom = boList.find((bo: any) => bo.key === mp.from);
    pathFrom = {
      ...pathFrom,
      parentDetail: boList.find((bo: any) => bo.key === pathFrom.parentKey),
    };

    //MAPPING DATA FOR PATH_TO
    let pathTo = boList.find((bo: any) => bo.key === mp.to);
    pathTo = {
      ...pathTo,
      parentDetail: boList.find((bo: any) => bo.key === pathTo.parentKey),
    };
    mappingWithDetail.push({ pathFrom, pathTo });
  }

  for (const mpd of mappingWithDetail) {
    let pathFrom = "";
    let pathTo = "";

    let pathFromType = mpd.pathFrom.typeDesc.toLowerCase();
    let pathToType = mpd.pathTo.typeDesc.toLowerCase();
    if (pathFromType === "object") {
      pathFrom = mpd.pathFrom.paramName;
    } else {
      pathFrom =
        mpd.pathFrom.parentDetail.paramName + "." + mpd.pathFrom.paramName;
    }

    if (pathToType === "table") {
      pathTo = mpd.pathTo.paramName;
    } else {
      pathTo = mpd.pathTo.parentDetail.paramName + "." + mpd.pathTo.paramName;
    }

    result.push({ pathFrom, pathTo });
  }

  return result;
};

export const foundPathTo = (pathTo: string, mapping: FlowMapping[]) => {
  for (const m of mapping) {
    if (m.pathTo == pathTo) return true;
  }
  return false;
};

const getBoAndTableMapping = (boMapping: any) => {
  // console.log("getBoAndTableMapping boMapping => ", boMapping);
  let linkDataArray = boMapping?.linkDataArray;
  let nodeDataArray = boMapping?.nodeDataArray;
  let mappingList: any = [];
  for (const linkData of linkDataArray) {
    let bo = nodeDataArray.find((item: any) => item.key === linkData.from);
    let table = nodeDataArray.find((item: any) => item.key === linkData.to);
    let mapping = {
      bo: bo.pathName,
      table: table.pathName,
    };
    mappingList.push(mapping);
  }
  // console.log("getBoAndTableMapping mappingList => ", mappingList);
  return mappingList;
};

const getTablePathByBoPath = (boMapping: any, boPath: string) => {
  let boTableMappingList = getBoAndTableMapping(boMapping);
  let mappingObj = boTableMappingList.find((item: any) => item.bo === boPath);
  return mappingObj?.table;
};

export const getTableFromBoMapping = (
  boMapping: any,
  tableColResp: TableType[],
  searchConfigData: any,
  searchConfig: any,
  mapping: FlowMapping[]
) => {
  let result = [];
  let boList = boMapping?.nodeDataArray;
  // console.log("getTableFromBoMapping boList => ", boList);
  // console.log("getTableFromBoMapping boMapping => ", boMapping);
  let boAndTableMapping = getBoAndTableMapping(boMapping);
  // console.log("getTableFromBoMapping boAndTableMapping => ", boAndTableMapping);
  // console.log("getTableFromBoMapping mapping => ", mapping);
  // console.log("getTableFromBoMapping tableColResp => ", tableColResp);
  // console.log("getTableFromBoMapping searchConfigData => ", searchConfigData);
  // console.log("getTableFromBoMapping searchConfig => ", searchConfig);

  //GET ALL CUSTOM WHERE
  //defaultOrderby => (project_name || project_desc like '%search%' )
  let searchFilterItems = searchConfig.filter(
    (item: any) => item.bo_config_type === "SEARCH_FILTER"
  );
  let customWhereList = getCustomWhereByFromSearchFilterList(
    searchFilterItems,
    boMapping,
    mapping
  );
  // console.log("getTableFromBoMapping customWhereList ", customWhereList);

  //GET ALL DEFAULT ORDER
  //customWhere => order by column_1 desc, column_2 asc
  let searchResultItems = searchConfig.filter(
    (item: any) => item.bo_config_type === "SEARCH_RESULT"
  );
  let defaultOrderList = getDefaultOrderByFromSearchResultList(
    searchResultItems,
    boMapping,
    mapping
  );
  // console.log("getTableFromBoMapping defaultOrderList ", defaultOrderList);

  //GET ONLY BO SIDE FROM BO_MAPPING
  let onlyTable = boList.filter(
    (bo: any) => bo.group === "tableGroup" && bo.key !== TABLE_INIT_KEY
  );

  //COLLECT ONLY TYPE OBJECT
  let collectTypeTables = onlyTable.filter(
    (table: any) => table.typeDesc.toLowerCase() === "table"
  );
  // console.log("getTableFromBoMapping collectTypeTables ", collectTypeTables);

  //CREATE BO_LIST BY OBJECT TYPE
  for (const tablType of collectTypeTables) {
    let cols: any = tableColResp.find(
      (item: any) => item.table_name === tablType.paramName
    );
    if (!cols) return;

    let columns: any[] = [];
    let tableName = cols.table_name;
    let allColumn = cols?.name_column || [];

    for (const col of allColumn) {
      let colName = col.column_name;

      //CHECK FK
      let fkPath = getForeignKeyFromColumnName(
        boList,
        mapping,
        boAndTableMapping,
        tableName + "." + colName
      );

      let fkObj = searchConfigData.find(
        (item: any) => item.boName === tableName && item.boFieldName === colName
      );
      let colObj = {
        column: colName,
        type: col.column_type,
        primaryKey: col?.primary_key && !fkObj?.foreignKey,
        whereKey: col?.primary_key && !fkObj?.foreignKey,
        foreignKey: fkPath, //fkObj?.foreignKey || "",
      };
      columns.push(colObj);
    }

    //REMOVE PRIMARY_KEY UNDEFINED
    // columns = Object.assign([],columns.filter((item) => typeof item.primaryKey !== "undefined"));
    let newcolumns: any[] = [];
    for (const col of columns) {
      let pathTo = tableName + "." + col.column;
      if (foundPathTo(pathTo, mapping)) {
        newcolumns.push(col);
      }
    }
    columns = Object.assign([], newcolumns);
    // columns = Object.assign([], columns.filter((item) => {
    //   let pathTo = tableName + "." + item.column
    //   console.log("pathTo => ", pathTo)
    //   return foundPathTo(pathTo, mapping)
    // }));
    // console.log("check table column new columns => ", columns);

    //GET CUSTOM_WHERE FROM CUSTOM_WHERE_LIST
    let customWhereItem: any = customWhereList.find(
      (item: any) => item.tableName === tablType.paramName
    );
    // console.log("customWhereItem => ", customWhereItem);

    //GET DEFAULT_ORDER_BY FROM DEFAULT_ORDER_LIST
    let defaultOrderItem: any = defaultOrderList.find(
      (item: any) => item.tableName === tablType.paramName
    );
    // console.log("defaultOrderItem => ", defaultOrderItem);

    //GET DEFAULT_ORDER BY TABLE_NAME
    // let defaultOrderby = getDefaultOrderByColsAndTableName(
    //   defaultOrderItem?.defaultOrderCols,
    //   tablType?.paramName
    // );
    // console.log("getTableFromBoMapping defaultOrderby => ", defaultOrderby);
    let defaultOrderby = newGetDefaultOrderByColsAndTableName(
      boMapping,
      defaultOrderItem?.colsByTableName
    );
    console.log("getTableFromBoMapping defaultOrderby => ", defaultOrderby);
    //GET SEARCH BY TABLE_NAME
    // let search = getSearchByColsAndTablename(
    //   customWhereItem?.customWhereCols,
    //   tablType?.paramName
    // );
    // console.log("getTableFromBoMapping search => ", search);

    let search = newGetSearchByColsAndTableName(
      boMapping,
      customWhereItem?.colsByTableName
    );
    console.log("getTableFromBoMapping search => ", search);

    let tablGroup = {
      tableName: tablType?.paramName || "", //defaultOrderItem?.tablePath,
      // search: customWhereItem?.customWhere || "",
      search,
      customWhere: "",
      // defaultOrderby: defaultOrderItem?.defaultOrder || "",
      defaultOrderby,
      columns,
    };
    result.push(tablGroup);
  }
  // console.log("getTableFromBoMapping result => ", result);
  return result;
};

const getForeignKeyFromColumnName = (
  boList: any,
  mapping: any,
  boAndTableMapping: any,
  colPath: string
) => {
  //GET MAPPING FROM COLUMN PATH
  let mappingBoTable = mapping.find((item: any) => item?.pathTo === colPath);
  if (!mappingBoTable) return "";

  //GET BO OBJECT
  let boObj = boList.find(
    (item: any) => item?.pathName === mappingBoTable?.pathFrom
  );
  if (!boObj) return "";

  //IF HAVE FK GET COLUMN PATH OF TABLE FROM BO PATH
  if (boObj?.foreignKey) {
    let fkPath = boAndTableMapping.find(
      (item: any) => item?.bo === boObj?.foreignKey
    );
    if (!fkPath) return "";
    return fkPath?.table;
  }

  return "";
};

const newGetDefaultOrderByColsAndTableName = (
  boMapping: any,
  colsByTableName: any[]
) => {
  if (!colsByTableName) return "";

  let allCols: { tablePath: string; sortBy: string }[] = [];
  for (const colTable of colsByTableName) {
    let tablePath = getTablePathByBoPath(boMapping, colTable?.boPath);
    allCols.push({ tablePath, sortBy: colTable?.sortBy });
  }

  if (allCols?.length === 0) return "";

  let defaultOrder = "";
  let defaultOrderStartWord = "order by ";
  for (const indexCol in allCols) {
    let colName = allCols[indexCol].tablePath;
    let sortBy = allCols[indexCol].sortBy;
    // let tableAndCol = `${tableName}.${colName}`;
    let lastIndex = allCols.length - 1;
    defaultOrder =
      parseInt(indexCol) === lastIndex
        ? defaultOrderStartWord + defaultOrder + colName + " " + sortBy //order by column desc
        : defaultOrder + colName + " " + sortBy + ", "; //column desc,
  }
  return defaultOrder;
};

const getDefaultOrderByColsAndTableName = (
  cols: { colName: string; sortBy: string }[],
  tableName: string
): string => {
  if (cols?.length === 0) return "";

  let defaultOrder = "";
  let defaultOrderStartWord = "order by ";
  for (const indexCol in cols) {
    let colName = cols[indexCol].colName;
    let sortBy = cols[indexCol].sortBy;
    let tableAndCol = `${tableName}.${colName}`;
    let lastIndex = cols.length - 1;
    defaultOrder =
      parseInt(indexCol) === lastIndex
        ? defaultOrderStartWord + defaultOrder + tableAndCol + " " + sortBy //order by column desc
        : defaultOrder + tableAndCol + " " + sortBy + ", "; //column desc,
  }
  return defaultOrder;
};

const newGetSearchByColsAndTableName = (
  boMapping: any,
  colsByTableName: any[]
) => {
  if (!colsByTableName) return "";

  let allCols = [];
  for (const colTable of colsByTableName) {
    let tablePath = getTablePathByBoPath(boMapping, colTable?.boPath);
    allCols.push(tablePath);
  }

  if (allCols?.length === 0) return "";

  let search = "";
  let searchLastWord = " like '%search%'";

  for (const indexCol in allCols) {
    let colName = allCols[indexCol];
    // let tableAndCol = `${tableName}.${colName}`;
    let lastIndex = allCols.length - 1;
    search =
      parseInt(indexCol) === lastIndex
        ? search + colName + searchLastWord
        : search + colName + " || ";
  }

  return search;
};

const getSearchByColsAndTablename = (
  cols: string[],
  tableName: string
): string => {
  if (cols?.length === 0) return "";

  let search = "";
  let searchLastWord = " like '%search%'";

  for (const indexCol in cols) {
    let colName = cols[indexCol];
    let tableAndCol = `${tableName}.${colName}`;
    let lastIndex = cols.length - 1;
    search =
      parseInt(indexCol) === lastIndex
        ? search + tableAndCol + searchLastWord
        : search + tableAndCol + " || ";
  }

  return search;
};

export const getDefaultOrderByFromSearchResultList = (
  searchResultItems: any,
  boMapping: any,
  mapping: any
) => {
  let allColumn = getColumnListFromSearchResult(
    searchResultItems,
    boMapping,
    mapping
  );
  // console.log("getDefaultOrderByFromSearchResultList allColumn => ", allColumn);
  let allTableName = getAllTableNameFromColumnList(allColumn);

  let tableSearchResult = [];
  for (const tbName of allTableName) {
    // console.log("tableName tbName => ", tbName)
    let defaultOrder = "";
    let defaultOrderStartWord = "order by ";
    let tableObj = {};
    let colsByTableName =
      allColumn?.filter((item) => item.tableNameMain === tbName) || [];

    for (const indexCol in colsByTableName) {
      let lastIndex = colsByTableName.length - 1;
      let colName = `${colsByTableName[indexCol].tableName}.${colsByTableName[indexCol].columnName}`;
      let sortBy = colsByTableName[indexCol].sortBy;
      defaultOrder =
        parseInt(indexCol) === lastIndex
          ? defaultOrderStartWord + defaultOrder + colName + " " + sortBy //order by column desc
          : defaultOrder + colName + " " + sortBy + ", "; //column desc,
    }
    let tablePath = getTablePathFromColumnListAndTableName(
      allColumn,
      tbName as string
    );
    // console.log(
    //   "getDefaultOrderByFromSearchResultList tablePath => ",
    //   tablePath
    // );

    //GET ALL COLS IN ORDER_LIST
    let defaultOrderCols: { colName: string; sortBy: string }[] =
      colsByTableName.map((col) => {
        return {
          colName: col.columnName,
          sortBy: col.sortBy,
        };
      });
    // console.log(
    //   "getDefaultOrderByFromSearchResultList defaultOrderCols => ",
    //   defaultOrderCols
    // );

    tableObj = {
      tableName: tbName,
      tablePath,
      colsByTableName,
      defaultOrder,
      defaultOrderCols,
    };

    tableSearchResult.push(tableObj);
  }

  return tableSearchResult;
};

const getColumnListFromSearchResult = (
  searchResultItems: any,
  boMapping: any,
  mapping: any
) => {
  let result = [];
  for (const index in searchResultItems) {
    let rootPath = searchResultItems[index].enyity_bo_item_id.split(".");
    if (rootPath.length === 0) return;

    let indexColumn = rootPath.length - 1;
    let columnName = rootPath[indexColumn];

    let indexTable = indexColumn - 1;
    let tableName = rootPath[indexTable];

    let tablePath = getTablePathByRootPath([...rootPath]);
    // console.log("getColumnListFromSearchResult tablePath => ", tablePath);
    let boPath = searchResultItems[index].enyity_bo_item_id;
    // console.log("getColumnListFromSearchResult boPath => ", boPath);
    let tableNameMain = getTableByBoPath(boPath, boMapping, mapping);
    // console.log(
    //   "getColumnListFromSearchResult tableNameMain => ",
    //   tableNameMain
    // );

    let srObj = {
      tableName, //: tableNameMain,
      tablePath,
      tableNameMain,
      columnName,
      sortBy: searchResultItems[index].sort_by,
      boPath,
    };
    result.push(srObj);
  }

  return result;
};

const getTablePathByRootPath = (rootPathArr: any) => {
  rootPathArr.pop();
  return rootPathArr.join(".");
};

export const getCustomWhereByFromSearchFilterList = (
  searchFilterItems: any,
  boMapping: any,
  mapping: any
) => {
  //(project_name || project_desc like '%search%' )

  let allColumn = getColumnListFromSearchFilter(
    searchFilterItems,
    boMapping,
    mapping
  );
  console.log("getDefaultOrderByFromSearchResultList allColumn => ", allColumn);
  let allTableName = getAllTableNameFromColumnList(allColumn);
  console.log(
    "getCustomWhereByFromSearchFilterList allTableName => ",
    allTableName
  );

  let tableSearchFilter = [];
  let customWhereLastWord = " like '%search%'";

  for (const tbName of allTableName) {
    let customWhere = "";
    let tableObj = {};
    let colsByTableName =
      allColumn?.filter((item) => item.tableNameMain === tbName) || [];
    console.log(
      "getCustomWhereByFromSearchFilterList colsByTableName => ",
      colsByTableName
    );

    for (const indexCol in colsByTableName) {
      let lastIndex = colsByTableName.length - 1;
      let tableColSearch = `${colsByTableName[indexCol].tableName}.${colsByTableName[indexCol].columnName}`;
      customWhere =
        parseInt(indexCol) === lastIndex
          ? customWhere + tableColSearch + customWhereLastWord
          : customWhere + tableColSearch + " || ";
    }
    let tablePath = getTablePathFromColumnListAndTableName(
      allColumn,
      tbName as string
    );

    //GET ALL COLS IN SEARCH
    let customWhereCols: string[] = colsByTableName.map(
      (col) => col.columnName
    );
    // console.log(
    //   "getColumnListFromSearchFilter customWhereCols => ",
    //   customWhereCols
    // );

    tableObj = {
      tableName: tbName,
      tablePath,
      colsByTableName,
      customWhere,
      customWhereCols,
    };

    tableSearchFilter.push(tableObj);
  }

  // console.log(
  //   "getDefaultOrderByFromSearchResultList tableSearchFilter => ",
  //   tableSearchFilter
  // );
  return tableSearchFilter;
};

const getTableByBoPath = (boPath: string, boMapping: any, mapping: any) => {
  let pathTo = "";

  //find  pathTo
  for (const m of mapping) {
    if (m.pathFrom == boPath) {
      pathTo = m.pathTo;
    }
  }
  console.log("pathTo", pathTo);
  //find node
  let currentNode;
  let boList = boMapping?.nodeDataArray;
  for (const bo of boList) {
    if (bo.pathName == pathTo) {
      currentNode = bo;
    }
  }

  // console.log("currentNode", currentNode);
  // support mapp object
  if (currentNode?.typeDesc == "Object") {
    return currentNode?.paramName;
  }

  //find parent node
  let parenNode;
  for (const bo of boList) {
    if (bo.key == currentNode?.parentKey) {
      parenNode = bo;
    }
  }
  // console.log("parenNode", parenNode);
  return parenNode?.paramName;
};

const getColumnListFromSearchFilter = (
  searchFilterItems: any,
  boMapping: any,
  mapping: any
) => {
  let result = [];
  // console.log("boMapping", boMapping);
  // console.log("mapping", mapping);
  for (const index in searchFilterItems) {
    let rootPath = searchFilterItems[index].enyity_bo_item_id.split(".");
    // console.log("getColumnListFromSearchFilter rootPath => ", rootPath);
    if (rootPath.length === 0) return;

    //TABLE NAME BY TABLE PATH
    let tablePath = getTablePathByRootPath([...rootPath]);

    let indexColumn = rootPath.length - 1;
    let columnName = rootPath[indexColumn];

    let indexTable = indexColumn - 1;
    let tableName = rootPath[indexTable];
    // <<<<<<< HEAD
    //     let boPath = searchFilterItems[index].enyity_bo_item_id;
    //     // let tableNameMain = getTableByBoPath(boPath, boMapping, mapping);
    //     // console.log(
    //     //   "getColumnListFromSearchFilter tableNameMain => ",
    //     //   tableNameMain
    //     // );

    //     let srObj = {
    //       tableName, //: tableNameMain,
    //       tablePath,
    // =======
    let boPath = searchFilterItems[index].enyity_bo_item_id;
    // console.log("boPath", boPath);
    let tableNameMain = getTableByBoPath(boPath, boMapping, mapping);

    let srObj = {
      tableName, //: tableNameMain,
      tablePath,
      tableNameMain,
      columnName,
      defaultSearch: Boolean(searchFilterItems[index].default_search),
      boPath,
    };
    result.push(srObj);
  }

  return result;
};

const getAllTableNameFromColumnList = (columnList: any) => {
  // let allTableName = columnList.map((item: any) => item.tableName);
  // let uniqueTableName = Array.from(new Set(allTableName));
  let allTable = columnList.map((item: any) => item.tableNameMain);
  let uniqueTableName = Array.from(new Set(allTable));
  return uniqueTableName;
};

const getTablePathFromColumnListAndTableName = (
  columnList: any,
  tableName: string
) => {
  let tableObj = columnList.find((item: any) => item.tableName === tableName);
  return tableObj?.tablePath;
};

export const getTableFromJsonBoMapping = (
  boMapping: any,
  tableColResp: TableType[]
) => {
  let result = [];
  let boList = boMapping?.nodeDataArray;

  //GET ONLY BO SIDE FROM BO_MAPPING
  let onlyTable = boList.filter(
    (bo: any) => bo.group === "tableGroup" && bo.key !== TABLE_INIT_KEY
  );

  //COLLECT ONLY TYPE OBJECT
  let collectTypeTables = onlyTable.filter(
    (table: any) => table.typeDesc.toLowerCase() === "table"
  );

  //CREATE BO_LIST BY OBJECT TYPE
  for (const tablType of collectTypeTables) {
    let cols: any = tableColResp.find(
      (item: any) => item.table_name === tablType.paramName
    );
    let columns = cols.name_column.map((item: any) => {
      return {
        fieldName: item.column_name,
        type: item.column_type,
        primaryKey: item.primary_key,
        whereKey: item.primary_key,
      };
    });
    let tablGroup = {
      tableName: tablType.paramName || "",
      customWhere: "",
      defaultOrderby: "",
      columns,
    };
    result.push(tablGroup);
  }
  return result;
};

export const getGenerateMFRequest = (
  jsonBoMapping: any,
  tableColResp: TableType[],
  boId: string,
  aspId: string,
  storyId: string,
  dbConnect: GetTableAndColumnRequest,
  versionName: string,
  searchConfigData: any,
  searchConfig: any
): GenerateMFRequest => {
  let boMapping = JSON.parse(jsonBoMapping?.json_mapping_bo);

  let bo = getBoFromJsonBoMapping(boMapping) as FlowBo[];
  let mapping = getMappingRootPathFromJsonBoMapping(boMapping);
  let table = getTableFromBoMapping(
    boMapping,
    tableColResp,
    searchConfigData,
    searchConfig,
    mapping
  ) as FlowTable[]; //getTableFromJsonBoMapping(boMapping, tableColResp);

  let result: GenerateMFRequest = {
    appId: aspId as string,
    flowName: "flow_" + jsonBoMapping.bo_name + "_" + versionName,
    type: "select_all,insert,update,delete,select_one,select_where",
    includeOne: "Y",
    enyity_bo_id: boId as string,
    story_id: storyId,
    bo,
    mapping,
    table,
    db: getDBFromDBConnection(dbConnect),
  };
  // console.log("getGenerateMFRequest result => ", result);
  return result;
};
