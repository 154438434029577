import React, { useContext, useEffect, useState } from "react";
import styles from "../database-connection/DataConnectionContainer.module.scss";
import EmptyInboxIcon from "src/assets/svg/emptyInbox";
import AddButtonIcon from "src/assets/svg/add";

import DashboardZeroContent from "../DashboardZeroContent";
import CardItem from "./card/CardItem";
import CardCreate from "./card/CradCreate";
import { Card } from "antd";
import DrawerDataConnection from "./drawer/DrawerDatabaseConnection";
import ModalSuccess from "./modal/ModalSuccess";
import ModalFail from "./modal/ModalFail";
import DrawerEditConnection from "./drawer/DrawerEditConnection";
import { useAllDBConnectorsByAspID } from "src/hooks/database-connection/useAllDBConnectorsByAspID";
import { useInView } from "react-intersection-observer";
import {
  DBConnectors,
  GetAllDBConnectorsByAspIDProps,
  SortOrder,
} from "src/types/databaseConnectors.type";
import { useUpdateDBConnector } from "src/hooks/database-connection/useDatabaseConnectorMutation";
import useViewMode from "src/hooks/useViewMode";
import { CARD_MODE, LIST_MODE } from "src/constants/ViewMode";
import ListItem from "./list/ListItem";
import PlusSmallColorIcon from "src/assets/svg/plus-small-color";
import {
  DatabaseConnectorsContext,
  DatabaseConnectorsProvider,
} from "src/page/context/DatabaseConnectors.context";
import { AppContext } from "src/page/context/App.context";
import { useLocation, useNavigate } from "react-router-dom";

const { Meta } = Card;

type FormValues = {
  ConnectionType: string;
  ConnectionName: string;
  Host: string;
  Port: string;
  Username: string;
  Password: string;
};

type ConnectionProp = {
  id: number;
  connectionName: string;
  userProfile: string;
  email: string;
  uploadDate: string;
  value: FormValues;
};

function DataConnection() {
  return (
    <DatabaseConnectorsProvider>
      <DataConnectionContainer />
    </DatabaseConnectorsProvider>
  );
}

function DataConnectionContainer() {
  const dbContext = useContext(DatabaseConnectorsContext);
  const { ref, inView } = useInView();
  const appContext: any = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const text_create = "Create a connection to save your data";
  const text_title = "My Database Connection";
  const searchParams = new URLSearchParams(location.search);

  const [openDrawerCreate, setOpenDrawerCreate] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openFail, setOpenFail] = useState<boolean>(false);
  const [valueDatabaseType, setValueDatabaseType] = useState<string>("MongoDB");
  const [activeDatabaseIndex, setActiveDatabaseIndex] = useState<number | null>(
    1
  );
  const [activeAPIIndex, setActiveAPIIndex] = useState<number | null>(null);

  const [modalNameText, setModalNameText] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<DBConnectors | null>(null);
  const [titleText, setTitleText] = useState<string>("");
  const [descriptionText, setDescriptionText] = useState<string>("");
  const [isType, setisType] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>(
    searchParams.get("search") || ""
  );
  const [viewMode, setViewMode] = useViewMode();

  const sortOrder: SortOrder =
    (searchParams.get("sortOrder") as SortOrder) || "DESC";

  let asp_id = String(localStorage.getItem("asp_id"));
  const dbConnectorsQuery: GetAllDBConnectorsByAspIDProps = {
    asp_id: asp_id,
    pageSize: 10,
    search: searchInput,
    sortOrder: sortOrder,
    filter: appContext.selectedFilter,
  };
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useAllDBConnectorsByAspID(dbConnectorsQuery);
  const databaseConnectorsByAspID = data
    ? data.pages.flatMap((page) => page?.data?.databaseConnectors ?? [])
    : [];

  const updateDBConnector = useUpdateDBConnector();

  console.log("hasNextPage=> ", hasNextPage);

  // const databaseConnectorsByAspID =  data ? data.pages.flatMap((page => page)) : [];

  console.log("data =1> ", data);
  console.log("data =2> ", databaseConnectorsByAspID);
  // console.log("data =2> ", databaseConnectorsByAspIDTest);
  console.log(
    "data => ===============================",
    data ? data.pages.flatMap((page) => page) : []
  );

  useEffect(() => {
    if (appContext.countConnector !== databaseConnectorsByAspID.length) {
      appContext.setCountConnector(databaseConnectorsByAspID.length);
    }
  }, [databaseConnectorsByAspID.length]);

  useEffect(() => {
    console.log("isFetching => ", isFetching);
  }, [isFetching]);

  useEffect(() => {
    console.log("selectedSort => ", appContext.selectedSort);
  }, [appContext.selectedSort, appContext.selectedFilter]);

  useEffect(() => {
    console.log("inView => ", inView);
    if (inView) fetchNextPage();
  }, [inView, fetchNextPage]);

  const handleCardClick = (card: any) => {
    console.log("===== showDrawerEdit");
    console.log("card", card);
    setSelectedCard(card);
    setIsDrawerOpen(true);
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  };

  const onCloseDrawerEdit = () => {
    console.log("===== onCloseDrawerEdit");

    setIsDrawerOpen(false);
    dbContext.setIsTestConnectionPass(false);
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  };

  const showModalSuccess = () => {
    console.log("===== showModalSuccess");
    setOpenSuccess(true);
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  };

  const onCloseModalSuccess = () => {
    console.log("===== onCloseModalSuccess");
    setOpenSuccess(false);
    onReset();
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  };

  const showModalFail = () => {
    console.log("===== showModalFail");
    setOpenFail(true);
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  };

  const onCloseModalFail = () => {
    console.log("===== onCloseModalFail");
    setOpenFail(false);
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  };

  const onDataName = (
    name: string,
    title: string,
    description: string,
    type?: string
  ) => {
    setModalNameText(name);
    setTitleText(title);
    setDescriptionText(description);
    if (type) {
      setisType(type);
    }
  };

  const handleDatabaseCardClick = (index: number, title: string) => {
    console.log("title", title);
    console.log("index", index);

    setValueDatabaseType(title);
    setActiveDatabaseIndex(index === activeDatabaseIndex ? null : index);
    setActiveAPIIndex(null);
  };

  const handleAPICardClick = (index: number, title: string) => {
    console.log("title", title);
    console.log("index", index);

    setValueDatabaseType(title);
    setActiveAPIIndex(index === activeAPIIndex ? null : index);
    setActiveDatabaseIndex(null);
  };

  const onSaveEditConfig = (formValue: any) => {
    console.log("onSaveEditConfig:: ", formValue);
    updateDBConnector.mutate(formValue, {
      onSuccess: () => {
        onCloseDrawerEdit();
      },
    });
  };

  const onClickCreate = () => {
    console.log("======= onClickCreate");
    setOpenDrawerCreate(true);
    setActiveAPIIndex(null);
    setActiveDatabaseIndex(1);
    setValueDatabaseType("MongoDB");
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  };

  const onCloseDrawerCreate = () => {
    console.log("======= onCloseDrawerCreate");
    setOpenDrawerCreate(false);
    setActiveDatabaseIndex(null);
    setActiveAPIIndex(null);
    setValueDatabaseType("");
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  };

  const onReset = () => {
    setModalNameText("");
  };

  const onChangeSearch = (value: any) => {
    setSearchInput(value);
    searchParams.set("search", value);
    navigate({
      search: searchParams.toString(), // new search params
    });
  };

  useEffect(() => {
    console.log("isTestConnectionPass", dbContext.isTestConnectionPass);
  }, [dbContext.isTestConnectionPass]);

  const content = (
    <div className={styles.container}>
      <div className={styles.headerTitle}>
        <div className={styles.title}>
          {text_title} {`(${databaseConnectorsByAspID?.length || 0})`}
        </div>
        {viewMode === LIST_MODE && databaseConnectorsByAspID?.length > 0 && (
          <div style={{ cursor: "pointer" }} onClick={() => onClickCreate()}>
            <PlusSmallColorIcon width={30} height={30} />
          </div>
        )}
      </div>
      <div className={styles.customScrollbar}>
        {databaseConnectorsByAspID?.length > 0 ? (
          viewMode === CARD_MODE ? (
            <>
              <div className={styles.cardGrid}>
                <CardCreate onClickCreate={onClickCreate} />
                {databaseConnectorsByAspID?.map((databaseConnector) => (
                  <CardItem
                    key={databaseConnector?.id}
                    id={databaseConnector?.id}
                    connectionName={
                      databaseConnector?.item_name != ""
                        ? databaseConnector?.item_name
                        : databaseConnector?.connection_name
                    }
                    dbType={databaseConnector?.db_type}
                    userProfile={
                      "https://i.pinimg.com/236x/ab/24/0a/ab240a0632380ffdd977c6208724a280.jpg"
                    }
                    username={databaseConnector?.created_by}
                    uploadDate={databaseConnector?.updated_at}
                    // onClick={() => { }}
                    onClick={() => handleCardClick(databaseConnector)}
                  />
                ))}
                {hasNextPage && (
                  <div
                    ref={ref}
                    style={{ width: "100%", height: "1px", background: "red" }}
                  ></div>
                )}
              </div>
            </>
          ) : (
            <>
              <ListItem
                data={databaseConnectorsByAspID}
                onClick={handleCardClick}
                dbConnectorsQuery={dbConnectorsQuery}
              />
            </>
          )
        ) : (
          <div className={styles.centeredColumn}>
            <div className={styles.centeredText}>
              <EmptyInboxIcon />
              <div className={styles.infoText}>{text_create}</div>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => onClickCreate()}>
              <AddButtonIcon />
            </div>
          </div>
        )}

        <DrawerDataConnection
          isOpen={openDrawerCreate}
          onClose={onCloseDrawerCreate}
          // onCreate={onCreateDatabaseConnection}
          valueDatabaseType={valueDatabaseType}
          activeDatabaseIndex={activeDatabaseIndex}
          handleAPICardClick={handleAPICardClick}
          handleDatabaseCardClick={handleDatabaseCardClick}
          activeAPIIndex={activeAPIIndex}
          showModalFail={showModalFail}
          onDataName={onDataName}
          showModalSuccess={showModalSuccess}
          dbConnectorsQuery={dbConnectorsQuery}
        />

        <DrawerEditConnection
          isOpen={isDrawerOpen}
          onClose={onCloseDrawerEdit}
          selectedCard={selectedCard}
          onSaveEditConfig={onSaveEditConfig}
          dbConnectorsQuery={dbConnectorsQuery}
          showModalFail={showModalFail}
          onDataName={onDataName}
          showModalSuccess={showModalSuccess}
        />

        <ModalSuccess
          open={openSuccess}
          onClose={onCloseModalSuccess}
          name={modalNameText}
          title={titleText}
          description={descriptionText}
        />

        <ModalFail
          open={openFail}
          onClose={onCloseModalFail}
          name={modalNameText}
          title={titleText}
          description={descriptionText}
          isType={isType}
        />
      </div>
    </div>
  );

  return (
    <>
      <div style={{ height: "100%" }}>
        <DashboardZeroContent content={content} onSearch={onChangeSearch} />
      </div>
    </>
  );
}
export default DataConnection;
