import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type StripeState = {
  dpmCheckerLink: string;
  loading: boolean;
};

const initialValues: StripeState = {
  dpmCheckerLink: "",
  loading: false,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState: initialValues,
  reducers: {
    setDpmCheckerLink: (state: StripeState, data: PayloadAction<string>) => {
      state.dpmCheckerLink = data.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setDpmCheckerLink } = stripeSlice.actions;
export const stripeSelector = (store: RootState) => store.stripeReducer;
export default stripeSlice.reducer;
