export let cssText = `

[pg-page-name] {
  height: unset !important;
}

.footer-text-head{
  font-size: 15px;
}
.footer-li{
  font-size: 14px;
}
.footer-li:hover{
  text-decoration: underline;
}

.footerRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    /* margin-left: -15px; */
    /* background-color: ; */
    /* padding: 90px 20px; */
    margin: 0 0 0 0;
    /* background-color: rgba(28, 28, 28, .99);*/
    color: #fff;
  }

  .footer-col-xl-3 {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }

  .footer-col-xl-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .footer-col-xl-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footer-col-xl-1 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .h1-footer {
    /* font-family: 'Helvetica Neue LT W01 75 Bold'; */
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .h1-footer>label {
    cursor: pointer;
  }

  .ul-footer {
    padding: 0;
    font-family: 'Helvetica Neue LT W05 45 Light';
    font-size: 13px;
    list-style: none !important;
  }

  .ul-footer>li {
    margin-bottom: 5px;
    cursor: pointer;
    list-style: none;
  }

  .a-footer {
    color: #fff !important;
    /* color: #007bff; */
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  .padding-footer {
    padding: 90px 100px;
    margin: 0 0 0 0;
    background-color: #003D60;
    color: #fff;
  }

  .row-social>img {
    margin-right: 30px;
    height: 20px;
    margin-top: 10px;
  }
  .cursor-pointer{
    cursor: pointer;
  }

`;