import React from "react";

const AddIcon = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      style={style}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#E9E9E9"
      />
      <path
        d="M12 6V18M6 12H18"
        stroke="#007BFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIcon;
