import { renderTabScreens, clearTabContent } from './renderTabScreens';

export default function renderBreadcrumbScreens(screenElement, container, screen, maxColumn) {
    let gridColumn = `1 / span ${maxColumn}`;
    screenElement.style.gridColumn = gridColumn; // กำหนด grid column ของ screenElement 

    const breadcrumb = document.createElement('div'); // สร้าง element สำหรับ breadcrumb
    breadcrumb.className = 'ant-breadcrumb'; // กำหนด class ของ breadcrumb

    const breadcrumbItem = document.createElement('div'); // สร้าง element สำหรับ breadcrumb item
    breadcrumbItem.className = 'ant-breadcrumb-item'; // กำหนด class ของ breadcrumb item

    const stepsComponent = document.createElement('div'); // สร้าง element สำหรับ steps component
    stepsComponent.className = 'ant-steps'; // กำหนด class ของ steps component

    if (!screen?.steps) return;

    let maxRowBreadcrumb = 0;
    let maxColumnBreadcrumb = 0;
    screen?.steps.forEach(steps => {
        steps.tabScreens.forEach(tabScreens => {
            // คำนวณหาค่า max row และ max column ของ breadcrumb โดยวนลูปผ่าน tabScreens ของทุก steps
            maxRowBreadcrumb = Math.max(maxRowBreadcrumb, tabScreens.rownumber);
            maxColumnBreadcrumb = Math.max(maxColumnBreadcrumb, tabScreens.columnnumber);
        })
    });

    // console.log("maxRowBreadcrumb", maxRowBreadcrumb);
    // console.log("maxColumnBreadcrumb", maxColumnBreadcrumb);

    // จัดเรียง steps ตามลำดับ seq และสร้าง element สำหรับแต่ละ step
    screen?.steps.sort((a, b) => a.seq - b.seq).forEach((step) => {
        const stepItem = document.createElement('div'); // สร้าง element สำหรับแต่ละ step
        stepItem.id = 'steps-item-' + step.tabName;
        stepItem.className = 'ant-steps-item'; // กำหนด class ของ step item

        const stepIconContainer = document.createElement('div'); // สร้าง container สำหรับ icon และชื่อของขั้นตอน
        stepIconContainer.className = 'step-icon-container'; // เพิ่ม class สำหรับ container ของ icon และชื่อ

        const stepIcon = document.createElement('div'); // สร้าง element สำหรับ icon
        stepIcon.className = 'ant-steps-item-icon'; // เพิ่ม class สำหรับ icon
        stepIcon.textContent = step.seq; // กำหนดข้อความให้กับ icon โดยใช้ seq ของ step

        const stepTitle = document.createElement('div'); // สร้าง element สำหรับชื่อของขั้นตอน
        stepTitle.className = 'ant-steps-item-title'; // กำหนด class ของ step title
        stepTitle.textContent = step.tabName; // กำหนดข้อความให้กับ step title โดยใช้ tabName ของ step

        // stepIconContainer.appendChild(stepIcon); // เพิ่ม icon ลงใน container
        stepIconContainer.appendChild(stepTitle); // เพิ่มชื่อของขั้นตอนลงใน container
        stepItem.appendChild(stepIconContainer); // เพิ่ม container ลงใน stepItem
        stepsComponent.appendChild(stepItem); // เพิ่ม step item ลงใน steps component

        // เพิ่ม event listener เมื่อคลิกที่ step item เพื่อแสดง tab ที่เกี่ยวข้อง
        stepItem.addEventListener('click', () => handleBreadcrumbStepClick(step, container, screen));

        // เรียกใช้งาน renderTabScreens เพื่อแสดง tab ที่เกี่ยวข้องกับ step นั้น
        renderTabScreens(step, container, screen, maxRowBreadcrumb);
    });

    breadcrumbItem.appendChild(stepsComponent);// เพิ่ม steps component ลงใน breadcrumb item
    breadcrumb.appendChild(breadcrumbItem); // เพิ่ม breadcrumb item ลงใน breadcrumb
    screenElement.appendChild(breadcrumb);// เพิ่ม breadcrumb ลงใน screenElement
}

function handleBreadcrumbStepClick(step, container, screen) {
    // เรียกใช้งาน clearTabContent เพื่อล้างเนื้อหาทั้งหมดใน container
    clearTabContent(container, screen);
    // ค้นหา element ของ tab ที่เกี่ยวข้องกับ step และแสดงผล
    const stepContent = document.getElementById(`tab-${step.tabName}`);
    console.log("stepContent:: :", stepContent);
    if (stepContent) {
        stepContent.style.display = 'grid'; // แสดง tab content โดยกำหนดให้มีรูปแบบ grid
    }
};
