import {Button} from "antd";
import React from "react";
import styles from "./Button.module.scss";

type centerBtnProps = {
    icon?: any;
    small? : boolean;
    className?: any;
    style? : React.CSSProperties;
    shape?: any
    type?: any
    ghost?: boolean
    disabled? : boolean;
    onClick?(): void;
    loading?: boolean;
    children?:any
}

const CenterButton: React.FC<centerBtnProps> = ({
    style,
    icon,
    disabled,
    loading,
    small,
    shape= "round",
    type = "primary",
    ghost,
    onClick,
    className,
    children
}) => {
  return (
      <Button
          className={`${className ? className : !ghost && styles.CenterBtn}`}
          style={style || {}}
          icon={icon}
          disabled={disabled}
          loading={loading}
          type={!ghost ? type : "default"}
          // ghost={ghost}
          shape={shape}
          size={small ? "middle" : "large"}
          onClick={onClick}
      >
        {children}
      </Button>
    // <Button
    //   style={props?.style || {}}
    //   onClick={props.onClick}
    //   className={classname}
    //   type="primary"
    //   loading={props.loading}
    //   disabled={props?.disabled}
    // >
    //   {props.children}
    // </Button>
  );
}

export default CenterButton;
