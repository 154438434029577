import React, { useContext, useEffect, useState, } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Input, Layout, notification, Row, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./LoginMain.module.scss";
import { useTranslation } from "react-i18next";
import logo_img from "src/assets/png/mainLogo.png";
import login_img from "src/assets/png/login_img.png";

import * as Auth from "src/services/auth.service";


import { useMutation, useQuery } from "@tanstack/react-query";
import { checkEmpty } from "src/services/util.service";
import { pathRoutes } from "src/page/props/util.props";
import CenterButton from "src/shared/button/Button";
import LogoLoading from "src/shared/loading/LogoLoading";
import { jwtDecode } from "jwt-decode";

const { Header, Footer, Sider, Content } = Layout;

const { Text } = Typography;

const resetUser = () => {

    localStorage.removeItem(Auth.AuthKeys.openID);

};

function LoginMain(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLogin, setIsLogin] = useState(true);


    const [isError, setError] = useState(false);
    const [dataError, setDataError] = useState("");

    const [appId, setAppId] = useState("257681758767259");
    const [logInWithEmail, setLogInWithEmail] = useState(true);
    const userTypeFromStorage = localStorage.getItem(Auth.AuthKeys._key_user);
    const auth: any = localStorage.getItem(Auth.AuthKeys.openID);
    const parsedAuth = JSON.parse(auth);

    const openNotification = () => {
        notification.warning({
            message: `LoginMain`,
            description: `Username or Password not valid!`,
            placement: "bottomRight",
        });
    };

    const [doingLogin, setDoingLogin] = useState(false);

    function expireAllCookies(name: any, paths: any) {
        console.log("Start Expire Cookie (Function)");
        var expires = new Date(0).toUTCString();

        // expire null-path cookies as well
        document.cookie = name + "=; expires=" + expires;

        for (var i = 0, l = paths.length; i < l; i++) {
            document.cookie = name + "=; path=" + paths[i] + "; expires=" + expires;
        }
        console.log("End Expire Cookie (Function)");
    }


    const mutation = useMutation({
        mutationFn: Auth.login,
        onSuccess: (data) => {
            console.log('User created successfully:', data);
            // You can handle additional logic here, like updating cache or state
        },
        onError: (error) => {
            console.error('Error creating user:', error);
        }
    });

    const clickLogin = async () => {
        if (doingLogin) return;
        resetUser();
        if (
            (!checkEmpty(username)) && !checkEmpty(password)
        ) {
            setError(false);
            setDoingLogin(true);
            try {
                // let fcmToken: any = await askForPermissionToReceiveNotifications();

                const { data }: any = await mutation.mutateAsync({ username, password });

                console.log("data : ", data);
                if (data && data.access_token) {
                    localStorage.setItem(Auth.AuthKeys.openID, JSON.stringify(data));

                    const decodedToken: any = jwtDecode(data.access_token);
                    localStorage.setItem(Auth.AuthKeys.user, JSON.stringify(decodedToken));
                    localStorage.setItem(Auth.AuthKeys._key_user, 'user');
                }
                setTimeout(() => {
                    // props.refresh();
                    console.log("go to first ");
                    navigate(pathRoutes.Root);
                    setDoingLogin(false);
                }, 1);
            } catch (e: any) {
                setDoingLogin(false);
                console.log("login error", e);
                //@ts-ignore
                let hasNetError = (e.message + "").indexOf("Network Error");
                console.log("hasNetError : ", hasNetError);
                if (e.message.includes('timeout')) {
                    setDataError(t("The request timed out") + ".");
                    setError(true);
                } else if (e.response && e.response.data && e.response.data.error) {
                    setDataError(t(e.response.data.error));
                    setError(true);
                } else {
                    setDataError(t("The username or password is incorrect"));
                    setError(true);
                }
            }
        } else if (checkEmpty(password)) {
            setDataError(t("Please enter password"));
            setError(true);
        } else {
            setDataError(t("Please enter username"));
            setError(true);
        }
    };

    const handleUsername = (event: any) => {
        event.preventDefault();
        setUsername(event.target.value);
    };

    const handlePassword = (event: any) => {
        event.preventDefault();
        setPassword(event.target.value);
    };



    const antIcon = (
        <LoadingOutlined style={{ fontSize: 25, color: "#FFFFFF" }} spin />
    );

    // const handleForgetPassword = () => {
    //     navigate("/forgot-password");
    // };

    const resetFormData = () => {
        setUsername("");
        setPassword("");
    };

    const handleSwitchLogInType = () => {
        resetFormData();
        setLogInWithEmail(!logInWithEmail);
    };


    const location = useLocation();
    useEffect(() => {
        // if (!checkEmpty(Auth.getToken())) {
        //   navigate(pathRoutes.Root);
        // }

        // console.log("location", location);
        // const queryString = new URLSearchParams(location.search);
        //@ts-ignore
        const err: any = location.state?.message;
        if (!checkEmpty(err)) {
            setError(true);
            setDataError(err);
        } else {
            setTimeout(() => {
                // let check = Auth.checkLogin(props);
                // console.log("check : ", check);

                if (auth && parsedAuth?.access_token && userTypeFromStorage === "user") {
                    const decodedToken = jwtDecode(parsedAuth?.access_token);
                    const currentTime = Date.now() / 1000;

                    if (decodedToken.exp && decodedToken.exp < currentTime) {
                        resetUser();
                        navigate(pathRoutes.Login);
                    } else {

                        console.log("go to main ");
                        console.log("Check Login");
                        navigate(pathRoutes.Root);
                    }
                } else {
                    // setIsLogin(true);
                    console.log("go to login ");
                    // navigate(pathRoutes.Login);
                }
            }, 0);
        }
    }, [auth]);


    useEffect(() => {
        expireAllCookies("JSESSIONID", ["/", "/IAM2RESTService"]);
        document.cookie =
            "JSESSIONID=; path=/IAM2RESTService; expires=" +
            new Date(0).toUTCString();
        console.log("End Expire Cookie");
    }, []);

    return (
        <>
            {isLogin ? (
                <Layout>
                    <Content>
                        <Row className={styles.contentLogin}>
                            <Col
                                className={styles.boxIconWidth}
                                style={{
                                    background: `url(${login_img})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}
                            >
                                <div className={styles.boxIconCenter}>
                                    <img className={styles.logoLoginApp} src={logo_img} />
                                </div>
                            </Col>
                            <Col className={[styles.boxLoginInfoWidth].join(" ")}>
                                <div className={styles.boxLogin}>
                                    <div className={styles.titleLogin}>
                                        <div className={styles.textWellcome}>
                                            {t("Welcome To PromptX")}
                                        </div>
                                        {/* <div className={styles.textDetail}>
                                            {t(
                                                "Start chatting and collaboration with your team and friends"
                                            )}
                                        </div> */}

                                    </div>
                                    {/* <NotificationButton></NotificationButton> */}
                                    <div style={{ paddingTop: 50, width: "100%" }}>
                                        <div className={styles.inputContainer}>
                                            <div style={{ display: "flex", width: "100%", marginBottom: "5px" }}>
                                                <div style={{ width: "100%", fontSize: "1rem" }}>
                                                    {t("Username")}
                                                </div>
                                            </div>


                                            <Input
                                                className={styles.inputBox}
                                                placeholder={t("Username")}
                                                value={username}
                                                onChange={handleUsername}
                                            />

                                            <div style={{ marginTop: "15px", alignSelf: "flex-start", fontSize: "1rem" }}>
                                                {t("Password")}
                                            </div>
                                            <Input.Password
                                                className={styles.inputBoxPassword}
                                                placeholder={t("Password")}
                                                value={password}
                                                onChange={handlePassword}
                                            />
                                            {isError ? (
                                                <div className={styles.BErrorMessage}>

                                                    <Text type="danger">
                                                        {dataError}
                                                    </Text>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                marginTop: "40px",
                                                marginBottom: "24px",
                                            }}
                                        >
                                            <CenterButton
                                                style={{ backgroundColor: "#428DFF", borderColor: "#428DFF", width: "100%" }}
                                                onClick={clickLogin}
                                            >
                                                {doingLogin ? (
                                                    <Spin indicator={antIcon} />
                                                ) : (
                                                    t("Sign in")
                                                )}
                                            </CenterButton>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            ) : (
                <Layout>
                    <Content>
                        <Row
                            style={{
                                height: "100vh",
                                fontSize: "20px",
                            }}
                        >
                            <Col className={styles.boxIcon} style={{ width: "100%" }}>
                                <div className={styles.boxIconCenter}>
                                    <LogoLoading {...props} />
                                </div>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            )}
        </>
    );
}

export default LoginMain;
