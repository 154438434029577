import { Modal, Space, Table } from "antd";
import styles from "./ListItem.module.scss";
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "src/assets/svg/delete";
import EditIcon from "src/assets/svg/editIcon";
import {
  formatISODateToCustomFormat,
  formatISODateInReact,
} from "src/page/utils/FormatDate";
import ModalDelete from "../modal/ModalDelete";
import { useDeleteDBConnector } from "src/hooks/database-connection/useDatabaseConnectorMutation";
import { GetAllDBConnectorsByAspIDProps } from "src/types/databaseConnectors.type";

type ListItemProps = {
  data: any;
  onClick: (dbConnector: any) => void;
  dbConnectorsQuery: GetAllDBConnectorsByAspIDProps;
};

function ListItem({ data, onClick, dbConnectorsQuery }: ListItemProps) {
  const deleteDBConnectorMutation = useDeleteDBConnector(dbConnectorsQuery);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  function handleDeleteConfirmation(id: string) {
    setSelectedId(id);
    showModalDelete();
    console.log("handleDeleteConfirmation", id);
  }

  function showModalDelete() {
    console.log("===== showModalDelete");
    setOpenModalDelete(true);
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
  }
  function onCloseModalDelete() {
    console.log("===== onCloseModalDelete");
    setOpenModalDelete(false);
    window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
  }

  const handleOk = () => {
    console.log("handleDeleteConfirmation=> ", selectedId);
    if (!selectedId) return;
    deleteDBConnectorMutation.mutate(
      { id: selectedId },
      {
        onSuccess: () => {
          onCloseModalDelete();
          setSelectedId(null);
        },
      }
    );
  };

  const columns = [
    {
      title: "Data Connection Name",
      key: "db_name",
      dataIndex: "db_name",
      render: (text: any) => <>{text}</>,
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      key: "date",
      render: (text: any) => <>{formatISODateToCustomFormat(text)}</>,
    },
    {
      title: "Owner",
      dataIndex: "created_by",
      key: "owner",
      render: (text: any) => <>{text}</>,
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <div className={styles.iconContainerStyle}>
          <div
            id={record.id}
            onClick={() => onClick(record)}
            className={styles.editIconStyle}
          >
            <EditIcon />
          </div>
          <div
            className={styles.deleteIconStyle}
            onClick={() => handleDeleteConfirmation(record.id)}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <Table className={styles.tableBody} columns={columns} dataSource={data} />

      <ModalDelete
        title="Delete Database ?"
        description="Are you sure want to delete this database?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => onCloseModalDelete()}
        onOk={() => handleOk()}
        open={openModalDelete}
      />
    </>
  );
}
export default ListItem;
