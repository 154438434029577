import React, { useEffect, useRef, useState } from "react";
import styles from "../html-spec/HtmlSpec.module.scss";

import clipboardCopy from 'clipboard-copy';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';

import CopyIcon from "src/assets/svg/copyIcon";
import ChatMessage from "../../chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../constants/genstep.constant";

type HtmlSpecProps = {
    code: any;
    setCode: any;
    processing:any;
};


function HtmlSpec({ code, setCode, processing }: HtmlSpecProps) {

    const editorRef = useRef<any>(null);
    const copyText = () => {
        clipboardCopy(code);
        // alert('คัดลอกข้อความเรียบร้อยแล้ว!');
    };


    useEffect(() => {
        if (code) {
            // console.log("code>>> ::", code);
        }
    }, [code])

    return (
        <div className={styles.chatContainer}>

            {code?.map((msg: any, index: number) => (
                <ChatMessage
                    key={index}
                    agent={msg?.agent}
                    message={msg?.text}
                    setMessage={setCode}
                    profileImage={msg?.profileImage}
                    chatColor={msg?.chatColor}
                    processing={processing}
                    step={GENERATE_STEP.HTML}
                />
            ))}
        </div>
    );
}
export default HtmlSpec;