import React, { useEffect, useState } from "react";
import StoryEdit from "src/assets/svg/story-edit";
import styles from "./EditStory.module.scss";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import "./EditStory.scss";
import EditStoryDBConnection from "./database-connection/EditStoryDBConnection";
import EditStoryEntity from "./entity/EditStoryEntity";
import EditStoryComponent from "./component/EditStoryComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { idText } from "typescript";

type Props = {};
type TabItemType = {
  key: string;
  tabName: string;
  content: React.JSX.Element;
};

function EditStory({}: Props) {
  // let params = useParams();
  const params = useParams<{ id: string }>();
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    // console.log("EditStory  state => ", location);
    let isFromGenMF = location.state?.isFromGenMF;
    if (isFromGenMF) {
      activeComponentTab();
      clearStateFromGenMF();
    }

  }, []);
  let [storyID, setStoryID] = useState<string | null>();
  let [activeTab, setActiveTab] = useState<string>("1");

  //ACTIVE TAB ON MICROFLOW GENERATE
  const activeComponentTab = () => {
    setActiveTab("3");
  };

  const tabItems: TabItemType[] = [
    {
      key: "1",
      tabName: "Database Connection",
      content: (
        <EditStoryDBConnection
          storyID={params.id!}
          isOpen={activeTab === "1" ? true : false}
        />
      ),
    },
    {
      key: "2",
      tabName: "Entity",
      content: (
        <EditStoryEntity
          afterGenMF={activeComponentTab}
          isOpen={activeTab === "2" ? true : false}
        />
      ),
    },
    {
      key: "3",
      tabName: "Component",
      content: <EditStoryComponent isOpen={activeTab === "3" ? true : false} />,
    },
  ];

  useEffect(() => {
    let sId = params.id;
    setStoryID(sId);
  }, []);

  const handleTabClick = (key: any) => {
    setActiveTab(key);
  };

  const clearStateFromGenMF = () => {
    navigate(location.pathname, {});
  };

  const customTabBar = (props: any, DefaultTabBar: any) => {
    return (
      <DefaultTabBar {...props} className={styles.customTabBar}>
        {(node: any) => {
          const isActive = activeTab === node.key;
          return (
            <div
              role="tab"
              aria-selected={isActive}
              className={`ant-tabs-tab ${
                isActive ? "ant-tabs-tab-active" : ""
              }`}
              onClick={() => handleTabClick(node.key)}
            >
              <div className={isActive ? styles.tabActive : styles.tab}>
                {node}
              </div>
            </div>
          );
        }}
      </DefaultTabBar>
    );
  };
  return (
    <div className={styles.editStoryContainer}>
      <div className={styles.storyNameHeader}>
        <div className={styles.storyName}>
          <p>{storyID}</p>
          {/* <input type="text" name="" id="" className={styles.inputStoryName} /> */}
        </div>
        <div className={styles.editBtn}>
          <StoryEdit />
        </div>
      </div>
      <div className={styles.storyContent}>
        <div className={styles.storyTab}>
          <Tabs
            defaultActiveKey={"1"}
            activeKey={activeTab}
            onChange={handleTabClick}
            renderTabBar={customTabBar}
            tabBarStyle={{ position: "initial" }}
          >
            {tabItems.map((item) => {
              return (
                <TabPane tab={item.tabName} key={item.key}>
                  {item.content}
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default EditStory;
