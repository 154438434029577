import React from "react";

const StoryEdit = (props) => {
  const { className, width, height } = props;
  return (
    <svg
    width={width || "18"}
    height={height || "18"}
    className={className}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4853 0.765302C15.0321 0.312767 14.4178 0.0585938 13.7773 0.0585938C13.1369 0.0585938 12.5226 0.312767 12.0693 0.765302L1.22668 11.608C0.916178 11.9167 0.669985 12.284 0.502342 12.6885C0.334699 13.093 0.248935 13.5268 0.25001 13.9646V15.3333C0.25001 15.5101 0.320248 15.6797 0.445272 15.8047C0.570296 15.9297 0.739866 16 0.916677 16H2.28534C2.72319 16.0012 3.15692 15.9156 3.56145 15.748C3.96597 15.5805 4.33325 15.3344 4.64201 15.024L15.4853 4.18064C15.9377 3.72743 16.1917 3.11328 16.1917 2.47297C16.1917 1.83266 15.9377 1.21851 15.4853 0.765302ZM3.69934 14.0813C3.32335 14.4548 2.81532 14.6651 2.28534 14.6666H1.58334V13.9646C1.58267 13.7019 1.63411 13.4416 1.7347 13.1989C1.83529 12.9562 1.98302 12.7359 2.16934 12.5506L10.398 4.32197L11.9313 5.8553L3.69934 14.0813ZM14.542 3.23797L12.8713 4.9093L11.338 3.3793L13.0093 1.70797C13.11 1.60751 13.2295 1.52786 13.361 1.47358C13.4924 1.41929 13.6333 1.39143 13.7755 1.39158C13.9178 1.39174 14.0586 1.41991 14.1899 1.47448C14.3212 1.52905 14.4405 1.60896 14.541 1.70964C14.6415 1.81032 14.7211 1.9298 14.7754 2.06126C14.8297 2.19272 14.8576 2.33359 14.8574 2.47581C14.8572 2.61804 14.8291 2.75885 14.7745 2.89019C14.7199 3.02153 14.64 3.14084 14.5393 3.2413L14.542 3.23797Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
export default StoryEdit;
