import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import styles from "./SummaryForm.module.scss";
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { plansSelector } from 'src/store/slices/plansSlice';
import { useEffect, useState } from 'react';
import { Product } from 'src/types/PlanProduct.type';
import { useAppDispatch } from 'src/store/store';

function SummaryForm() {
    const dispatch = useAppDispatch();
    const {
        currPlan,
        billing_cycle,
    } = useSelector(plansSelector);

    useEffect(() => {
    }, [dispatch])


    const textStyle = {
        fontSize: 16,
        fontWeight: 'regular',
        lineHeight: '0.1em',
        mb: '2px',
    };

    function capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const currencyFormat = (price: number) => Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
        price,
    );

    return (
        <List>
            <ListItem>
                <ListItemText
                    id="packet_name"
                    primary="Packet:"
                    primaryTypographyProps={textStyle} />
                <p className={styles.textstyle}>{capitalizeFirstLetter(currPlan?.name ?? "")} Plan ({currPlan?.metadata.no_of_apps} Apps)</p>
            </ListItem>
            <ListItem>
                <ListItemText
                    id="BillingCycle"
                    primary="BillingCycle:"
                    primaryTypographyProps={textStyle}
                />

                <p className={styles.textstyle}>{billing_cycle} {billing_cycle==='monthly' ?  '(12 months)' : ''}</p>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText
                    id="translations"
                    primary={billing_cycle==='monthly' ? "Translations (Price per month):" : "Translations :"}
                    primaryTypographyProps={textStyle}
                />
                <p className={styles.textstyle}>
                    {currencyFormat(billing_cycle === 'one_time' ?
                        currPlan?.one_time_prices.unit_amount ?? 0
                        : (currPlan?.monthly_prices.unit_amount ?? 0))}
                </p>
            </ListItem>
            <ListItem>
                <ListItemText
                    id="discount_for_points:"
                    primary="Discount for points:"
                    primaryTypographyProps={textStyle}
                />
                <p className={styles.textstyle}>{currencyFormat(0)}</p>
            </ListItem>
            <ListItem>
                <ListItemText
                    id="VAT"
                    primary="VAT:"
                    primaryTypographyProps={textStyle}
                />
                <p className={styles.textstyle}>{currencyFormat(0)}</p>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText
                    id="total"
                    primary="Total:"
                    primaryTypographyProps={textStyle}
                />
                <p className={styles.textstyle}>
                    {currencyFormat(billing_cycle === 'one_time' ?
                        currPlan?.one_time_prices.unit_amount ?? 0
                        : ((currPlan?.monthly_prices.unit_amount ?? 0) * 12))}
                </p>
            </ListItem>
        </List>
    );
}

export default SummaryForm;