import React, { useContext, useEffect, useState } from "react";
import styles from "../ControlItem.module.scss";
import UploadIconModal from "./modal/UploadIconModal";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import {
  getPositionToolInMainPanel,
  getToolPosition,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import { getMainRowIdFromCellId } from "../../../utils/controls.util";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import ImageIconTool from "../../ImageIconTool";

//IMAGE MOCKUP
export const controlIconMock = "https://picsum.photos/50/50";

type Props = {
  cellId?: string;
  onUpdate: (detail: any, cellId: string) => void;
  onRemove: (cellId: string) => void;
  configType?: string;
};

function ControlIcon({ cellId, onUpdate, onRemove }: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  let [isOpenTool, setIsOpenTool] = useState<boolean>(false);
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  let [isOpenUploadIconModal, setIsOpenUploadIconModal] =
    useState<boolean>(false);
  let [iconDisplay, setIconDisplay] = useState<string>("");
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();

  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);

  //CHECK PROPS
  useEffect(() => {
    // console.log(`ControlIcon props cellId: ${cellId}`);
    // console.log(`ControlIcon iconDisplay: ${iconDisplay}`);
    if (cellId?.includes("sub")) {
      setIsOpenUploadIconModal(false);
      setIconDisplay(controlIconMock);
    } else if (cellId?.includes("main") && !genPageContext.isVersionGenerated) {
      setIsOpenUploadIconModal(true);
    }

    if (!genPageContext.isIconItemSeted && genPageContext.isVersionGenerated) {
      setIsFirstLoad(true);
    } else {
      setIsFirstLoad(false);
    }
  }, []);

  //CHECK FROM VERSION SAVED
  useEffect(() => {
    console.log(`isFirstLoad change: `, isFirstLoad);
    console.log(`Panels: `, genPageContext.panelItems);
    if (!genPageContext.isIconItemSeted && isFirstLoad) {
      let component = genPageContext.versionSubComponent.filter(
        (item: any) => item.detail && item.detail.configType === "ICON"
      );
      console.log("component ******* ", component);
      if (component.length > 0) {
        let icon = component[0].detail.iconPath;
        icon = "pathmockup";
        // console.log("<<<<< icon >>>>> ", icon as React.JSX.Element )
        setIconDisplay(icon);
        onUpdate({ iconPath: icon }, cellId as string);
        genPageContext.setIsIconItemSeted(true);
      } else {
        console.log("************************************************");
        setIsOpenUploadIconModal(true);
      }
    } else if (
      genPageContext.isIconItemSeted ||
      (!genPageContext.isIconItemSeted &&
        genPageContext.isVersionGenerated &&
        isFirstLoad)
    ) {
      console.log("################################################");
      setIsOpenUploadIconModal(true);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    console.log(
      `ControlIcon genPageContext.itemFocusOnConfigPage: ${genPageContext.itemFocusOnConfigPage}`
    );
    if (!genPageContext.itemFocusOnConfigPage) {
      setIsOpenTool(false);
    }
    if (!genPageContext.itemFocusOnConfigPage.includes(cellId as string)) {
      setIsOpenTool(false);
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //ACTION HANDLER
  const onClickIcon = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log("onClickIcon controlIcon cellId => ", cellId);
    e.stopPropagation();

    if (cellId?.includes("main")) {
      let cellFocusEle = document.getElementById(cellId);
      let outerDivEle = document.getElementById("gridPanel");
      let { top, left } = getToolPosition(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage(cellId + "_control");
    } else {
      let cellFocusEle = document.getElementById(cellId as string); //GET CELL POSITION BY ELEMENT_ID
      let mainRowId = getMainRowIdFromCellId(cellId as string);
      let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID
      let { top, left } = getPositionToolInMainPanel(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage((cellId + "_control") as string);
    }
  };

  const onOkUploadIconModal = () => {
    setIsOpenUploadIconModal(false);
  };

  const onCancelUploadIconModal = () => {
    setIsOpenUploadIconModal(false);
  };

  const onSaveIconModal = (icon: string) => {
    setIconDisplay(icon);
    onUpdate({ iconPath: icon || controlIconMock }, cellId as string);
  };

  //MODAL DELETE HANDLER
  const onOkDeleteHandler = () => {
    onRemove(cellId as string);
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  const onCancelDeleteHandler = () => {
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  //TOOL HANDLER
  const onClickRemoveIcon = () => {
    setIsOpenDeleteModal(true);
  };

  const onClickUploadImage = () => {
    setIsOpenUploadIconModal(true);
    setIsOpenTool(false);
  };

  return (
    <>
      {iconDisplay && (
        <div
          onClick={(e) => {
            onClickIcon(e);
          }}
          className={iconDisplay ? styles.controlImageContainer : ""}
        >
          <img
            src={iconDisplay}
            alt="show-img"
            width={"50px"}
            height={"50px"}
            style={{ borderRadius: "100%" }}
          />
        </div>
      )}

      <UploadIconModal
        isModalOpen={isOpenUploadIconModal}
        modalDetail={{ title: "Upload icon", description: "Upload icon" }}
        onOk={onOkUploadIconModal}
        onCancel={onCancelUploadIconModal}
        onSaveIcon={onSaveIconModal}
      />

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Icon?",
          description: `Are you sure you want to delete this Icon? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onOkDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />

      {/* TOOL */}
      <ImageIconTool
        isShow={isOpenTool}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onRemove={onClickRemoveIcon}
        onUpload={onClickUploadImage}
      />
    </>
  );
}

export default ControlIcon;
