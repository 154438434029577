export default function renderLabelScreens(screenElement, screen) {
    const label = document.createElement('label');
    label.textContent = screen.label;
  
    if (screen.labelType === 'HEAD') {
      label.style.fontSize = '18px';
      label.style.fontWeight = 'bold';
    } else if (screen.labelType === 'NORMAL') {
      label.style.fontSize = '15px';
      label.style.fontWeight = 'normal';
    }
    screenElement.appendChild(label);
  }