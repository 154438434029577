export const BoMappingInit = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    //BO JSON
    {
      loc: "0, 0",
      text: "BusinessObject",
      isGroup: true,
      typeDesc: "BoInit",
      key: "boGroup",
      seq: 0
    },
    {
      paramName: "BusinessObject",
      category: "sourceInitGroup",
      isGroup: false,
      typeDesc: "BoInit",
      key: "boKeyInit",
      group: "boGroup",
      seq: 1,
      // isTreeExpanded: true
    },

    //TABLE JSON
    {
      loc: "400, 0",
      text: "Table",
      isGroup: true,
      typeDesc: "TableInit",
      key: "tableGroup",
    },
    {
      paramName: "Table",
      category: "targetInitGroup",
      isGroup: false,
      typeDesc: "TableInit",
      key: "tableKeyInit",
      group: "tableGroup",
    },
  ],
  linkDataArray: [],
};
