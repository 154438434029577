import React, { useEffect, useRef, useState } from "react";
import styles from "../screen-spec/ScreenSpec.module.scss";

import clipboardCopy from 'clipboard-copy';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';

import ChatMessage from "../../chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../constants/genstep.constant";

type ScreenSpecProps = {
  messages: any;
  setMessages: any;
};


function ScreenSpec({ messages, setMessages }: ScreenSpecProps) {

  useEffect(() => {
    if (messages) {
      // console.log("ScreenSpec messages>>>",messages);
    }
  }, [messages])

  return (
    <div className={styles.chatContainer}>

      {messages?.map((msg:any, index:number) => (
        <ChatMessage
          key={index}
          agent={msg?.agent}
          message={msg?.text}
          profileImage={msg?.profileImage} 
          chatColor={msg?.chatColor}
          step={GENERATE_STEP.SCREEN_SPECIFICATIONS}
          />
      ))}
    </div>
  );
}
export default ScreenSpec;