import { Avatar, Breadcrumb, Menu, Layout, theme } from "antd";
import React, {
  useState,
  useDeferredValue,
  Fragment,
  useEffect,
  useContext,
} from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import AppComponentIcon from "src/assets/svg/app-component";
import ComponentListIcon from "src/assets/svg/component-list";
import DatabaseConnectionIcon from "src/assets/svg/database-connection";
import StoryIcon from "src/assets/svg/story-icon";
import styles from "../../page/menu/DashboardPage.module.scss";
import HeaderDashboardZero from "../header-dashboard/HeaderDashboardZero";
import { pathRoutes } from "../props/util.props";
import _ from "lodash";
import { AppContext } from "../context/App.context";
import { handleClickTabs } from "../utils/NavigationUtils";
import { callApiMethodGet, getAspId } from "src/services/util.service";
import { COUNT_CONNECTOR_AND_STORY } from "src/utils/endpoint/createbo.endpoint";

const { Header, Content, Footer, Sider } = Layout;

function MenuPage() {
  const appContext: any = useContext(AppContext);
  let location = useLocation();
  const navigate = useNavigate();
  let [titleName, setTitleName] = useState<string>("");
  let [createdBy, setCreatedBy] = useState<string>("");
  let [version, setVersion] = useState<string>("");

  useEffect(() => {
    APIGetAppSpace();
    APICountConnectorAndStory();
  }, []);

  const APIGetAppSpace = async () => {
    try {
      const response = await callApiMethodGet(
        "https://test.oneweb.tech/AppSpace/v1/appspace/w-find/" + getAspId(),
        {}
      );
      setTitleName(response.content.aspName);
      setCreatedBy(response.content.createBy);
      setVersion(response.content.version);
    } catch (error) {
      setTitleName("Order Management");
      setCreatedBy("internal30");
      setVersion("5.0.21.09");
      console.log("APIGetAppSpace Error : ", error);
    }
  };

  const APICountConnectorAndStory = async () => {
    try {
      const response = await callApiMethodGet(COUNT_CONNECTOR_AND_STORY, {
        asp_id: getAspId(),
      });
      appContext.setCountConnector(response.data.connector);
      appContext.setCountStory(response.data.story);
    } catch (error) {
      console.log("APICountConnectorAndStory Error : ", error);
    }
  };

  function getMenuItems() {
    return [
      {
        key: "database-connection",
        path: `/zero/${pathRoutes.MainDashboard}/database-connection`,
        children: (
          <div
            className={
              current === "database-connection"
                ? styles.menuItemActiveContainer
                : styles.menuItemContainer
            }
          >
            <div className={styles.menuItemContent}>
              <DatabaseConnectionIcon
                fill={current === "database-connection" ? "#1C87F2" : "#272727"}
                opacity={current === "database-connection" ? "" : "0.5"}
              />
              <label className={styles.menuItemLabel}>
                Database Connection
              </label>
            </div>
            <label>{appContext.countConnector}</label>
          </div>
        ),
      },
      {
        key: "story",
        path: `/zero/${pathRoutes.MainDashboard}/story`,
        children: (
          <div
            className={
              current === "story"
                ? styles.menuItemActiveContainer
                : styles.menuItemContainer
            }
          >
            <div className={styles.menuItemContent}>
              <StoryIcon
                fill={current === "story" ? "#1C87F2" : "#272727"}
                opacity={current === "story" ? "" : "0.5"}
              />
              <label className={styles.menuItemLabel}>Story</label>
            </div>
            <label>{appContext.countStory}</label>
          </div>
        ),
      },
      {
        key: "app-component",
        path: `/zero/${pathRoutes.MainDashboard}/app-component`,
        children: (
          <div
            className={
              current === "app-component"
                ? styles.menuItemActiveContainer
                : styles.menuItemContainer
            }
          >
            <div className={styles.menuItemContent}>
              <AppComponentIcon
                fill={current === "app-component" ? "#1C87F2" : "#272727"}
                opacity={current === "app-component" ? "" : "0.5"}
              />
              <label className={styles.menuItemLabel}>App Component</label>
            </div>
            <label>3</label>
          </div>
        ),
      },
      {
        key: "component-list",
        path: `/zero/${pathRoutes.MainDashboard}/story`,
        children: (
          <div
            onClick={() => {
              console.log("go to component_asp");
              window.parent.postMessage(
                { event: "component_asp", key: "" },
                "*"
              );
            }}
            className={
              current === "component-list"
                ? styles.menuItemContent
                : styles.menuItemListContent
            }
          >
            <ComponentListIcon
              fill={current === "component-list" ? "#1C87F2" : "#272727"}
              opacity={current === "component-list" ? "" : "0.5"}
            />
            <label className={styles.menuItemLabel}>Component List</label>
          </div>
        ),
      },
    ];
  }

  const handleMenuClick = (e: any) => {
    handleClickTabs(e.key, navigate);
  };

  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "dasboard"
      : location.pathname.split("/")[1]
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        let currentLocate = location.pathname.split("/");
        if (currentLocate[2]) {
          setCurrent(currentLocate[2]);
        } else {
          setCurrent("database-connection");
        }
      }
    }
  }, [location, current]);

  const AppMenu = () => {
    const items = getMenuItems();
    return (
      <Menu
        mode="vertical"
        style={{
          background: "#F2F2F2",
          border: "#F2F2F2",
          height: "100%",
        }}
        // defaultactiveKeys={['1']}
        selectedKeys={[current]}
        onClick={handleMenuClick}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} className={styles.textMenu}>
            {item.children}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Layout style={{ height: "100dvh", overflow: "hidden" }}>
        <Sider width="160px" className={styles.siderStyle}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.imageContainer}>
                  <Avatar
                    className={styles.image}
                    icon={
                      // <YourImageIcon />
                      <div className={styles.letter}>
                        {titleName.substring(0, 1)}
                      </div>
                    }
                  />
                </div>

                <div className={styles.titleContent}>
                  <div className={styles.title}>{titleName}</div>

                  <div className={styles.createdBy}>
                    <div>created by :  </div>
                   <div className={styles.nameCreate}> {createdBy}</div>
                  </div>

                  <div className={styles.version}>version : {version}</div>
                </div>

              </div>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <AppMenu />
            </div>
          </div>
        </Sider>
        <Layout>
          <HeaderDashboardZero />
          <div style={{ height: "53px", backgroundColor: "white" }}></div>
          <Content
            className={styles.customScrollbar}
            style={{
              padding: "20px 30px",
              backgroundColor: "white",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default MenuPage;
