import { pathRoutes } from "../props/util.props";
import { NavigateFunction } from "react-router-dom";

export const handleClickTabs = (key:any, navigate:NavigateFunction) => {
    switch (key) {
        case 'database-connection':
            return navigate(`/${pathRoutes.MainDashboard}/${pathRoutes.DataConnection}`);
        case 'story':
            return navigate(`/${pathRoutes.MainDashboard}/${pathRoutes.Story}`);
        case 'app-component':
            return navigate(`/${pathRoutes.MainDashboard}/${pathRoutes.AppComponent}`);
        case 'data-reference':
            return navigate(`/${pathRoutes.MainDashboard}/${pathRoutes.DataReference}`);
        default:
            return null;
    }
};