import {ParameterTemplate} from '../ParameterTemplate';
import * as go from "gojs";
//@ts-ignore
// let go = window.go;
export function TreeNode() {
	//@ts-ignore
	go.Node.call(this);
}

go.Diagram.inherit(TreeNode, go.Node);


/** @override */
TreeNode.prototype.findVisibleNode = function() {
	let n = this;
	while (n !== null && !n.isVisible()) {
		n = n.findTreeParentNode();
	}
	return n;
};

export class ParamInitialGroup {
	
	// AbstractParameter(this);

	// ParamInitialGroup.prototype = Object.create(AbstractParameter.prototype);

	public createButtonEvent($$:any, paramTemplate:ParameterTemplate) {
		return {};
	};

	public createParameter($$:any, paramTemplate:ParameterTemplate){
		return $$(TreeNode, 
				{
					movable : false 
					,selectionChanged: function(part:any) {
						// MyDiagramState.startTransaction("Edit Visible");	
						// MyDiagramState.model.setDataProperty(part.data, "visible", part.isSelected ? true : false);
						// MyDiagramState.commitTransaction("Edit Visible");
					}
				}
		
			,$$(go.Panel, "Vertical"
				,$$(go.Panel, "Horizontal" 
					,{ width: 350,  contextMenu: $$(go.Adornment)  ,height: 28  }
					,paramTemplate.treeSetting(new go.Margin(3, 0, 0, 0))
			
					,$$(go.TextBlock, {margin:5, font:"14px bold"},
							new go.Binding("text", "paramName")
					)
					
					,this.createButtonEvent($$, paramTemplate)
					
				)//Horizontal
				
				,$$(go.Panel, "Horizontal" 
					,$$(go.Shape, "LineH", { stroke: "#FFFFFF", width: 350, height: 1 })
				)// Horizontal	
			)// Vertical
			
		); // end Node
	} ;

}

