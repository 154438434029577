import React from "react";

const StoryEditDbRedBin = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "56"}
      height={height || "56"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.25" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
      <rect
        x="4.25"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke="#FEF3F2"
        strokeWidth="8"
      />
      <path
        d="M32.25 22V21.2C32.25 20.0799 32.25 19.5198 32.032 19.092C31.8403 18.7157 31.5343 18.4097 31.158 18.218C30.7302 18 30.1701 18 29.05 18H27.45C26.3299 18 25.7698 18 25.342 18.218C24.9657 18.4097 24.6597 18.7157 24.468 19.092C24.25 19.5198 24.25 20.0799 24.25 21.2V22M26.25 27.5V32.5M30.25 27.5V32.5M19.25 22H37.25M35.25 22V33.2C35.25 34.8802 35.25 35.7202 34.923 36.362C34.6354 36.9265 34.1765 37.3854 33.612 37.673C32.9702 38 32.1302 38 30.45 38H26.05C24.3698 38 23.5298 38 22.888 37.673C22.3235 37.3854 21.8646 36.9265 21.577 36.362C21.25 35.7202 21.25 34.8802 21.25 33.2V22"
        stroke="#D92D20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default StoryEditDbRedBin;
