import React from "react";

const StoryEditEntGenPageTable = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 16 16"
      width={width || "16"}
      height={height || "16"}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <path d="M13.5 2h-12l-.5.5v11l.5.5h12l.5-.5v-11l-.5-.5zM2 3h11v1H2V3zm7 4H6V5h3v2zm0 1v2H6V8h3zM2 5h3v2H2V5zm0 3h3v2H2V8zm0 5v-2h3v2H2zm4 0v-2h3v2H6zm7 0h-3v-2h3v2zm0-3h-3V8h3v2zm-3-3V5h3v2h-3z" />
    </svg>
  );
};
export default StoryEditEntGenPageTable;
