import React from 'react';

import styles from "./Products.module.scss";


function Products() {
    return (
      <h2 className={styles.green}>Products</h2>
      );
}

export default Products;