import React, { useState } from "react";
import { FaCaretRight, FaCopy } from "react-icons/fa";
import styles from "./CopyButton.module.scss";
import { Button } from "antd";

type CopyButtonProps = {
  onClick: () => void;
  copied: boolean;
};

const CopyButton = ({ onClick, copied }: CopyButtonProps) => {
  return (
    <div
      className={styles.copy}
      onClick={onClick}
      title={copied ? "Copied" : "Copy"}
    >
      {/* <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8411_121456)">
          <path d="M8.66634 13.3333C9.55007 13.3323 10.3973 12.9808 11.0222 12.3559C11.6471 11.731 11.9986 10.8837 11.9997 10V4.16201C12.0007 3.81158 11.9322 3.46444 11.7981 3.14069C11.6639 2.81694 11.4669 2.52304 11.2183 2.27601L9.72367 0.781345C9.47665 0.532794 9.18274 0.335747 8.859 0.201625C8.53525 0.0675033 8.1881 -0.0010253 7.83767 1.15931e-05H4.66634C3.78261 0.00107016 2.93538 0.3526 2.31049 0.977492C1.6856 1.60238 1.33407 2.44961 1.33301 3.33334V10C1.33407 10.8837 1.6856 11.731 2.31049 12.3559C2.93538 12.9808 3.78261 13.3323 4.66634 13.3333H8.66634ZM2.66634 10V3.33334C2.66634 2.80291 2.87705 2.2942 3.25213 1.91913C3.6272 1.54406 4.13591 1.33334 4.66634 1.33334C4.66634 1.33334 7.94567 1.34268 7.99967 1.34934V2.66668C7.99967 3.0203 8.14015 3.35944 8.3902 3.60949C8.64025 3.85954 8.97939 4.00001 9.33301 4.00001H10.6503C10.657 4.05401 10.6663 10 10.6663 10C10.6663 10.5304 10.4556 11.0392 10.0806 11.4142C9.70548 11.7893 9.19677 12 8.66634 12H4.66634C4.13591 12 3.6272 11.7893 3.25213 11.4142C2.87705 11.0392 2.66634 10.5304 2.66634 10ZM14.6663 5.33334V12.6667C14.6653 13.5504 14.3138 14.3976 13.6889 15.0225C13.064 15.6474 12.2167 15.999 11.333 16H5.33301C5.1562 16 4.98663 15.9298 4.8616 15.8048C4.73658 15.6797 4.66634 15.5102 4.66634 15.3333C4.66634 15.1565 4.73658 14.987 4.8616 14.8619C4.98663 14.7369 5.1562 14.6667 5.33301 14.6667H11.333C11.8634 14.6667 12.3721 14.456 12.7472 14.0809C13.1223 13.7058 13.333 13.1971 13.333 12.6667V5.33334C13.333 5.15653 13.4032 4.98696 13.5283 4.86194C13.6533 4.73692 13.8229 4.66668 13.9997 4.66668C14.1765 4.66668 14.3461 4.73692 14.4711 4.86194C14.5961 4.98696 14.6663 5.15653 14.6663 5.33334Z" fill="#60605F" />
        </g>
        <defs>
          <clipPath id="clip0_8411_121456">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg> */}

      {copied ? (
        <Button onClick={onClick}>
          <FaCaretRight size={18} />
        </Button>
      ) : (
        <Button onClick={onClick}>
          <FaCopy size={18} />
        </Button>
      )}
    </div>
  );
};

export default CopyButton;
