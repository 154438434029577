import React, { useEffect, useState } from "react";
import styles from "./UploadIconModal.module.scss";
import { Button, Modal } from "antd";
import StoryEditDbRedBin from "src/assets/svg/story-edit-db-red-bin";

import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { controlIconMock } from "../ControlIcon";

const { Dragger } = Upload;

type Props = {
  modalDetail: { title: string; description: string };
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  onSaveIcon: (icon: string) => void;
};

export default function UploadIconModal({
  modalDetail,
  isModalOpen,
  onOk,
  onCancel,
  onSaveIcon,
}: Props) {
  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  //ACTION HANDLER
  const onClickOk = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOk();
    onSaveIcon(controlIconMock)
  };

  const onClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCancel();
  };

  //UPLOAD COMPONENT PROPS
  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://test.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.modalTitle}>
            {modalDetail.title}
            {/* <StoryEditDbRedBin /> */}
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={(e) => onClickCancel(e)}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="ok"
              onClick={(e) => onClickOk(e)}
              type="primary"
            >
              Continue
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        centered={true}
        width={400}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          {/* <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p> */}
          <p className="ant-upload-hint">
          Click or drag file to this area to upload.
          </p>
        </Dragger>
      </Modal>
    </>
  );
}
