import { useContext } from "react";
import { CARD_MODE } from "src/constants/ViewMode";
import { AppContext } from "src/page/context/App.context";
const useViewMode = (defaultMode: string = CARD_MODE) => {
  let appContext: any = useContext(AppContext);
  const viewMode: string = appContext.viewMode || defaultMode;
  const setViewMode: (mode: string) => void = (mode: string) => {
    appContext.setViewMode(mode);
  };
  return [viewMode, setViewMode] as const;
};
export default useViewMode;
