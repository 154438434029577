import axios from "axios";

const api = axios.create({
  //@ts-ignore
  baseURL: `${window["env"]["REACT_APP_ZERO_URL"]}/api/v1/`,
});

export const AuthKeys = {
  token: "zero_token",
  custom_menu: "custom_menu",
  expiresDate: "expiresDate",
  tokenType: "tokenType",
  _key_use: "_key_use",
  asp_id: "asp_id",
};

export function getToken() {
  return (
    localStorage.getItem(AuthKeys.token) ||
    sessionStorage.getItem(AuthKeys.token)
  );
}

export function getAspId() {
  return (
    localStorage.getItem(AuthKeys.asp_id) ||
    sessionStorage.getItem(AuthKeys.asp_id)
  );
}

export const getAuthorization = () => {
  let token = getToken();
  let authorization = "Bearer " + token;

  return authorization;
};
export const checkEmpty = (val: any) => {
  if (
    val === undefined ||
    val === null ||
    val === "null" ||
    val === "undefined"
  )
    return true;
  if (val.length === 0) return true;

  return false;
};

export function parseJwt(token: any) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
export const isTrue = (val: any) => {
  if (val === true || val === "true" || val === "y" || val === "Y") return true;
  else return false;
};

export const getnameOfUser = (userdata: any) => {
  let res = userdata.username;
  if (!checkEmpty(userdata.firstname)) {
    res = userdata.firstname;
    if (!checkEmpty(userdata.lastname)) {
      res = userdata.firstname + " " + userdata.lastname;
    }
  }
  return res;
};

export const getUserID = (userdata: any) => {
  console.log("User ID, ", userdata.userID);
  return userdata.userID;
};

export async function getUrlBlob(url: any) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      var arrayBufferView = new Uint8Array(this.response);
      var blob = new Blob([arrayBufferView]);
      var urlCreator = window.URL || window.webkitURL;
      let urlT = urlCreator.createObjectURL(blob);
      if (checkEmpty(urlT)) {
        resolve(url);
      } else {
        resolve(urlT);
      }
    };
    xhr.addEventListener("error", function () {
      resolve(url);
    });
    xhr.send();
  });
}

export const checkMobile = () => {
  return window.innerWidth <= 768;
};

export function getPathWss(path: any) {
  if (path?.startsWith("ws")) {
    return path;
  }
  var loc = window.location,
    new_uri;
  if (loc.protocol === "https:") {
    new_uri = "wss:";
  } else {
    new_uri = "ws:";
  }
  new_uri += "//" + loc.host;
  new_uri += path;
  return new_uri;
}

export function getUrlParameter(sParam: any, url: any) {
  try {
    var temp = url.split("?")[1];
    var sPageURL = ("?" + temp).substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function saveFileJsToLocal(result: any, key: any) {
  // var fileReader = new FileReader();

  // fileReader.onload = function (evt: any) {
  //   var result = evt.target.result;

  //   try {

  //   } catch (e) {
  //     console.log("Storage failed: " + e);
  //   }
  // };

  // fileReader.readAsDataURL(file);
  sessionStorage.setItem(key, result);
}
export function getFileJSFromLocal(key: any) {
  return sessionStorage.getItem(key);
}

export function setCookie(cName: any, cValue: any, expDays: any) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
export function getCookie(cName: any) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export function updateQueryStringParameter(uri: any, key: any, value: any) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export async function getApi(url: any, params: any) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function postApi(url: any, body: any) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function callApiMethodGet(url: string, params = {}) {
  try {
    const response = await axios.get(url, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodDelete(url: string, params = {}) {
  try {
    const response = await axios.delete(url, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodPost(url: string, data = {}) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/* send root response*/
export async function callApiMethodMFDGet(url: string, params = {}) {
  try {
    const response = await axios.get(url, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        accesstoken: getToken(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodPagePost(url: string, data = {}) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        accesstoken: getToken(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodZeroPost(url: string, data = {}) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        // accesstoken: getToken(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodPagePut(url: string, data = {}) {
  try {
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        accesstoken: getToken(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodPageGet(url: string, params = {}) {
  try {
    const response = await axios.get(url, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        accesstoken: getToken(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
/* send root response*/

//GENERATE PAGE LAYOUT
export async function callApiMethodPagePatch(url: string, data = {}) {
  try {
    const response = await axios.patch(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function callApiMethodUploadLibsPost(
  url: string,
  data = {},
  params = {}
) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getAuthorization(),
      },
      params,
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const getAuthorizationPmtx = () => {
  let openIDStr: any = localStorage.getItem("openID");
  let token = ""
  if (!checkEmpty(openIDStr)) {
    token = JSON.parse(openIDStr).access_token
  }
  let authorization = "Bearer " + token;

  return authorization;
};
