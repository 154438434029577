export const HEADER = "header";
export const MENU = "menu";
export const FOOTER = "footer";
export const WEB = "web";
export const HEADER_TEMPLATE = "Header";
export const MENU_TEMPLATE = "Menu";
export const NOTIFICATION = "notification";
export const USER_PROFILE = "user profile";
export const LOGO = "logo";
export const DROPDOWN = "dropdown";
export const WITH_ICON = "with icon";
export const ALIGN_ITEM = "align item";
export const INSTAGRAM = "instagram";
export const FACEBOOK = "facebook";
export const YOUTUBE = "youtube";
export const SEARCH = "search";
export const STORY = "story"
export const URL = "url"
