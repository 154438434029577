import React from 'react';
import { useParams } from 'react-router';


function Topic() {
  console.log("Topic");
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}

export default Topic;