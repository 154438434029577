export let cssText = `
.btn-general {
    background-color: #003D60 !important;
    color: white !important;
}
.btn-icon{
    color: #003D60;
  }

.btn-icon:hover{
    color: #777777
  }

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
body {
   
    font-family: sans-serif;
}

.card {
    margin-top: 10px;
    margin-bottom: 10px;
    border: unset !important;
    cursor: pointer;
    box-shadow: rgba(120, 203, 253, 0.1) 5px 5px -1px 1px;
    border-radius: 11px !important;
    padding: 10px 10px 10px 10px;
    background-color: rgb(255, 255, 255);

}
    
.info-card-tile {
    width: 300px;
}

.card-item {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 10px;
}

.card-content {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
}

.button-group-list-card {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.image-list-card {
    width: 200px;
    height: 70px;
}

.ant-form-item-label {
    color: #948e8e;
}

.divInput {
    display: flex;
    position: relative;
}

.email-container {
    display: flex;
    gap: 16px;
}

.custom-position-input {
    position: relative;
}

.password-toggle-button {
    position: absolute;
    padding: 10px;
    right: 0;
    cursor: pointer;
}

.password-toggle-button i {
    color: #c2c1c1;
}

.radio-group input[type="radio"],
.radio-group label {
    display: inline-block;
    margin-right: 10px;
    /* Adjust the margin as needed */
}

.radio-container {
    display: flex;
    align-items: center;
}


textarea {
    width: 100%;
    padding: 10px 16px 10px 16px;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
}

input[type="file"] {
    width: 100%;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
select {
    width: 100%;
    height: 40px;
    padding: 10px 16px 10px 16px;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
}

input[type="text"]:focus-visible,
input[type="number"]:focus-visible,
input[type="password"]:focus-visible,
input[type="date"]:focus-visible,
textarea:focus-visible,
select:focus-visible {
    outline: none;
    border-color: #274EAA;
}

input[type="text"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="date"]::placeholder,
textarea::placeholder,
select::placeholder {
    color: #bebebe;
}

input[type="text"]:hover,
input[type="number"]:hover,
input[type="password"]:hover,
input[type="date"]:hover,
textarea:hover,
select:hover {
    border: 1px solid #274EAA;
}

.ant-steps {
    flex: 1;
    min-width: 30%;
}

.ant-steps-item {
    flex: none;
}

.ant-steps-item-title {
    cursor: pointer;
}

.ant-steps-item-title:after {
    display: none;
}

/* Ant Tabs */
.ant-tab {
    margin-right: 12px;
    padding: 6px 20px;

}

.ant-tabs>.ant-tabs-nav {
    width: 100%;
}

.ant-tabs-tab-active {
    color: white;
    font-weight: 500;
    border: 1px solid #60A4FF;
    background-color: #60A4FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-bottom: 2px solid #1890ff; */
}

.ant-tabs-tab {
    font-size: 15px;
    padding: 0;
    border-bottom: 1px solid #D6D6D6;
    width: 100%;
}

.ant-tabs-tab:hover {
    color: currentColor;
}

.ant-btn {
    border-radius: 5px;
}

/* form-search */
.search-form {
    display: flex;
    position: relative;
    align-items: center;
}

.search-button {
    position: absolute;
    right: 5px;

    background-color: #003D60 !important;
    color: white;
    border: 2px solid #003D60 !important;
    border-radius: 4px;
}

.group-button,
.button-group-list {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px 0;
}

/* .remove-button,.add-button{
   border-radius: 100%;
   height: 40px;
} */

/* table.dataTable thead>tr>th.dt-ordering-asc {
    padding-right: 10px;
} */

table.dataTable>tbody>tr:hover {
    background-color: rgb(242, 248, 254);
}

table.dataTable thead>tr>th.dt-ordering-asc:first-child span.dt-column-order {
    display: none;
}

table.dataTable th.dt-type-numeric,
table.dataTable td.dt-type-numeric {
    text-align: center;
}

.table thead {
    background-color: #dfdfdf !important;
}

.table {
    background-color: aliceblue;
    border: 1px solid #dbdbdb;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-collapse: separate;
}

.table th {
    /* border: none; */
}

.table tbody {
    background-color: white;
}

div.dt-container.dt-empty-footer tbody>tr:last-child>* {
    border-bottom: none;
    border-inline: none;
}

.dt-layout-row:last-child {
    display: flex;
    justify-content: space-between;
}

/* ปุ่ม pagination */
.dt-paging-button {
    background-color: #ffffff;
    color: rgba(89, 89, 89, 1);
    padding: 0 8px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

/* ปุ่ม pagination เมื่อ hover */
.dt-paging-button:hover {
    background-color: rgba(237, 246, 255, 1);
}

/* ปุ่ม pagination เมื่อ focus */
.dt-paging-button:focus {
    background-color: #60A4FF;
    color: #ffffff;
}

/* ปุ่ม "Next" และ "Previous" */
.dt-paging-button[data-dt-idx="next"],
.dt-paging-button[data-dt-idx="previous"],
.pagination-button,
.previous-button, 
.next-button {
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(89, 89, 89, 1);
    border: 1px solid rgba(235, 235, 239, 1);
    padding: 0 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 6px;
    cursor: pointer;
    border-radius: 5px;
}

.dt-paging-button[data-dt-idx="next"]:hover,
.dt-paging-button[data-dt-idx="previous"]:hover,
.pagination-button:hover {
    background-color: rgb(245, 243, 243);
}

/* ปุ่ม "Next" */
/* .dt-paging-button[data-dt-idx="next"] {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDcgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjU0OTk3IDQuNDEwMTFMMi4xMDc0NyAwLjk2NzYwNkMxLjk2Njk1IDAuODI3OTE4IDEuNzc2ODYgMC43NDk1MTIgMS41Nzg3MiAwLjc0OTUxMkMxLjM4MDU4IDAuNzQ5NTEyIDEuMTkwNSAwLjgyNzkxOCAxLjA0OTk3IDAuOTY3NjA2QzAuOTc5Njc3IDEuMDM3MzMgMC45MjM4ODIgMS4xMjAyOCAwLjg4NTgwNSAxLjIxMTY3QzAuODQ3NzI5IDEuMzAzMDcgMC44MjgxMjUgMS40MDExIDAuODI4MTI1IDEuNTAwMTFDMC44MjgxMjUgMS41OTkxMiAwLjg0NzcyOSAxLjY5NzE0IDAuODg1ODA1IDEuNzg4NTRDMC45MjM4ODIgMS44Nzk5MyAwLjk3OTY3NyAxLjk2Mjg4IDEuMDQ5OTcgMi4wMzI2MUw0LjQ5OTk3IDUuNDY3NjFDNC41NzAyNyA1LjUzNzMzIDQuNjI2MDcgNS42MjAyOCA0LjY2NDE0IDUuNzExNjdDNC43MDIyMiA1LjgwMzA3IDQuNzIxODIgNS45MDExIDQuNzIxODIgNi4wMDAxMUM0LjcyMTgyIDYuMDk5MTIgNC43MDIyMiA2LjE5NzE0IDQuNjY0MTQgNi4yODg1NEM0LjYyNjA3IDYuMzc5OTMgNC41NzAyNyA2LjQ2Mjg4IDQuNDk5OTcgNi41MzI2MUwxLjA0OTk3IDkuOTY3NjFDMC45MDg3NDYgMTAuMTA3OCAwLjgyOTAxIDEwLjI5ODQgMC44MjgzMDcgMTAuNDk3NUMwLjgyNzYwMyAxMC42OTY1IDAuOTA1OTkxIDEwLjg4NzYgMS4wNDYyMiAxMS4wMjg5QzEuMTg2NDYgMTEuMTcwMSAxLjM3NzA1IDExLjI0OTggMS41NzYwNyAxMS4yNTA1QzEuNzc1MSAxMS4yNTEyIDEuOTY2MjUgMTEuMTcyOCAyLjEwNzQ3IDExLjAzMjZMNS41NDk5NyA3LjU5MDExQzUuOTcxMzMgNy4xNjgyMyA2LjIwOCA2LjU5NjM2IDYuMjA4IDYuMDAwMTFDNi4yMDggNS40MDM4NSA1Ljk3MTMzIDQuODMxOTggNS41NDk5NyA0LjQxMDExWiIgZmlsbD0iIzU5NTk1OSIvPgo8L3N2Zz4K');
    background-position-x: right;
} */

/* ปุ่ม "Previous" */
/* .dt-paging-button[data-dt-idx="previous"] {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDYgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjk0OTkgNi41MzI2MUMxLjg3OTYgNi40NjI4OCAxLjgyMzggNi4zNzk5MyAxLjc4NTczIDYuMjg4NTRDMS43NDc2NSA2LjE5NzE0IDEuNzI4MDUgNi4wOTkxMiAxLjcyODA1IDYuMDAwMTFDMS43MjgwNSA1LjkwMTEgMS43NDc2NSA1LjgwMzA3IDEuNzg1NzMgNS43MTE2N0MxLjgyMzggNS42MjAyOCAxLjg3OTYgNS41MzczMyAxLjk0OTkgNS40Njc2MUw1LjM5MjQgMi4wMzI2MUM1LjQ2MjY5IDEuOTYyODggNS41MTg0OSAxLjg3OTkzIDUuNTU2NTYgMS43ODg1NEM1LjU5NDY0IDEuNjk3MTQgNS42MTQyNCAxLjU5OTEyIDUuNjE0MjQgMS41MDAxMUM1LjYxNDI0IDEuNDAxMSA1LjU5NDY0IDEuMzAzMDcgNS41NTY1NiAxLjIxMTY3QzUuNTE4NDkgMS4xMjAyOCA1LjQ2MjY5IDEuMDM3MzMgNS4zOTI0IDAuOTY3NjA2QzUuMjUxODcgMC44Mjc5MTggNS4wNjE3OSAwLjc0OTUxMiA0Ljg2MzY1IDAuNzQ5NTEyQzQuNjY1NTEgMC43NDk1MTIgNC40NzU0MiAwLjgyNzkxOCA0LjMzNDkgMC45Njc2MDZMMC44OTIzOTUgNC40MTAxMUMwLjQ3MTA0MyA0LjgzMTk4IDAuMjM0Mzc1IDUuNDAzODUgMC4yMzQzNzUgNi4wMDAxMUMwLjIzNDM3NSA2LjU5NjM2IDAuNDcxMDQzIDcuMTY4MjMgMC44OTIzOTUgNy41OTAxMUw0LjMzNDkgMTEuMDMyNkM0LjQ3NDU5IDExLjE3MTIgNC42NjMxNCAxMS4yNDkzIDQuODU5OSAxMS4yNTAxQzQuOTU4NiAxMS4yNTA3IDUuMDU2NDUgMTEuMjMxOCA1LjE0NzgzIDExLjE5NDRDNS4yMzkyIDExLjE1NzEgNS4zMjIzMiAxMS4xMDIxIDUuMzkyNCAxMS4wMzI2QzUuNDYyNjkgMTAuOTYyOSA1LjUxODQ5IDEwLjg3OTkgNS41NTY1NiAxMC43ODg1QzUuNTk0NjQgMTAuNjk3MSA1LjYxNDI0IDEwLjU5OTEgNS42MTQyNCAxMC41MDAxQzUuNjE0MjQgMTAuNDAxMSA1LjU5NDY0IDEwLjMwMzEgNS41NTY1NiAxMC4yMTE3QzUuNTE4NDkgMTAuMTIwMyA1LjQ2MjY5IDEwLjAzNzMgNS4zOTI0IDkuOTY3NjFMMS45NDk5IDYuNTMyNjFaIiBmaWxsPSIjNTk1OTU5Ii8+Cjwvc3ZnPgo=');
    background-position-x: left;
} */

/* ปุ่ม "First" และ "Last" ที่อยู่ในสถานะ disabled */
.dt-paging-button[data-dt-idx="first"],
.dt-paging-button[data-dt-idx="last"] {
    display: none;
}

.content-add-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 15px;
}

.list-tile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-gap: 44px;
}

.card-tile {
    display: inline-block;
}

.imgContent-list-tile {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.image-list-tile{
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}
.image-list{
    height: 60px;
    width: 100px;
}
.back-btn{
    margin-top: 15px;
    margin-left: 10px;
}


.upload-div{
    width: 104px;
      height: 104px;
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      vertical-align: top;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
      cursor: pointer;
      transition: border-color .3s;
  
  }
  .upload-input-text{
    display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
  }

  .current-page{
    color: #ffffff;
    background-color: #003D60;
}
    .current-page:hover{
    color: #ffffff;
    background-color: #0b4f76;
}

.pagination-button{
    font-size: 14px;
     padding-top: 2px;
     padding-bottom: 2px;
 }

.content-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    gap: 10px;
}
.new-row{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
}
.delete-middle{
    vertical-align: middle;
}

.card-list-card {
    gap: 30px;
    min-width: 600px;
    align-items: normal
}

.image-card-list-card {
    width: 400px;
    height: 200px;
    object-fit: cover;
}

.list-text {
    width: 100%;
    display: flex;
    gap: 30px;
    flex: 1;
}

.list-text>div:nth-child(1) {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-text>div:last-child {
    margin-left: auto;
    justify-content: flex-end;
}

.list-card-text {
    width: 100px;
    flex: 1;
}

.flex-text {
    flex: 1;
}
    
.image-card-list-table{
    width: 100px;
    height: 60px;
}
  
`;