import {
  Checkbox,
  CheckboxProps,
  Input,
  Select,
  Switch,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BoConfigType } from "src/types/BoConfigType";
import { DISPLAY_VIEW_SELECT } from "src/utils/create-bo/DisplayViewSelect";
import {
  DATA_TYPE_SELECTS,
  INPUT_TYPE_SELECTS,
} from "src/utils/create-bo/InputTypeSelect";
import styles from "./BusinessObjectConfig.module.scss";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { getUpdateBoConfigItemsByID } from "src/utils/create-bo/createBo.util";
import { DISPLAY_EDIT_SELECT } from "src/utils/create-bo/DisplayEditSelect";
import MessageNofify from "../../notification/MessageNotify";
// import error from "../../assets/png/error.png";
import error from "../../../../../assets/png/error.png";

type Props = {
  boObjectItem: BoConfigType;
  onSelectRow: (rowId: string) => void;
  rowSelected: string;
  listData: any;
};
type EditFieldType = "dropdown" | "text" | "cascader" | "";
function BoObjectConfigItem({
  boObjectItem,
  onSelectRow,
  rowSelected,
  listData,
}: Props) {
  const displayEditsConst = DISPLAY_EDIT_SELECT;
  const displayViewsConst = DISPLAY_VIEW_SELECT;
  const inputTypes = INPUT_TYPE_SELECTS;
  const dataTypes = DATA_TYPE_SELECTS;

  let storyEntityContext = useContext(StoryEntityContext);
  let [isCheckItem, setIsCheckItem] = useState<boolean>(false);
  let [fieldName, setFieldName] = useState<string>("");
  let [displayViews, setDisplayViews] = useState<
    { value: string; label: string }[]
  >([]);
  let [displayEdits, setDisplayEdits] = useState<
    { value: string; label: string }[]
  >([]);
  let [inputType, setInputType] = useState<string>("");
  let [dataType, setDataType] = useState<string>("");
  let [displayView, setDisplayView] = useState<string>("");
  let [displayEdit, setDisplayEdit] = useState<string>("");
  let [isHidden, setIsHidden] = useState<boolean>(false);
  let [isParent, setIsParent] = useState<string>("");
  let [editFieldType, setEditFieldType] = useState<EditFieldType>("");
  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  let [isDisabledParent, setIsDisabledParent] = useState<boolean>(false);

  //MESSAGE NOTIFY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  let [parentOption, IsParentOption] = useState<any[]>([
    {
      id: "Not Data",
      bo_name: "Not Data",
      data_type: "Object",
    },
    ...listData,
  ]);
  useEffect(() => {
    setIsDisabledParent(boObjectItem.data_type == "Object" ? true : false);
    setIsHidden(boObjectItem.show_status);

    setDataOnLoad();
  }, []);

  //CHECK DATA DISPLAY VIEW ON CHAGED
  useEffect(() => {
    if (inputType.toLocaleLowerCase()) {
      storyEntityContext.setBoConfigItems(listData)
      console.log("on inputType => ", inputType.toLocaleLowerCase());
      let displayEditData = displayEditsConst[inputType.toLocaleLowerCase()];

      let displayEditFieldType = displayEditData?.fieldType;
      setEditFieldType(displayEditFieldType);
      
      let displayViewData = displayViewsConst[inputType.toLocaleLowerCase()];
      setDisplayViews(displayViewData);
      console.log("displayViewData => ",displayViewData)

      //CHECK SAVED DATA ON BACK FROM STEP 2
      setupDisplayViewOnInputTypeChange(displayViewData);
    }
  }, [inputType]);

  useEffect(() => {
    if (displayView) {
      console.log("on displayView => ", displayView);

      let displayEditData = displayEditsConst[inputType];
      // let defaultDisplayText = displayEditData?.showText;
      let displayEditInSelects = displayEditData?.selects;

      let displayEditItem = displayEditInSelects?.find(
        (item) => item.value === displayView
      );
      let displayEditInChildren: any = displayEditItem?.children || [];

      let displayEditSelectItems: any = [];
      if (editFieldType === "dropdown") {
        displayEditSelectItems = displayEditInSelects;
      } else if (editFieldType === "cascader") {
        displayEditSelectItems = displayEditInChildren;
      }
      setDisplayEdits(displayEditSelectItems);

      //CHECK SAVED DATA ON BACK FROM STEP 2
      setupDisplayEditOnDisplayViewChange(
        displayEditData,
        displayEditSelectItems
      );
    }
  }, [displayView]);

  //ROW ACTION HANDLER
  const onChangeCheckItem: CheckboxProps["onChange"] = (e) => {
    let isChecked = e.target.checked;
    console.log(`onChangeIsCheckItem= ${isChecked}`);
    setIsCheckItem(isChecked);
    updateBoConfigItems({
      id: boObjectItem.id,
      ischecked: isChecked,
    });
  };

  const onChangeEditFieldName = (newFieldName: string) => {
    setIsFirstLoad(false);
    setFieldName(newFieldName);
    updateBoConfigItems({
      id: boObjectItem.id,
      bo_name: newFieldName,
    });
  };

  const onChangeDataTypeHandler = (data_type: string) => {
    setIsFirstLoad(false);
    setDataType(data_type);
    setIsDisabledParent(data_type == "Object" ? true : false);
    updateBoConfigItems({
      id: boObjectItem.id,
      data_type,
    });
  };

  const onChangeInputTypeHandler = (input_type: string) => {
    setIsFirstLoad(false);
    setInputType(input_type);
    updateBoConfigItems({
      id: boObjectItem.id,
      input_type,
    });
  };

  const onChangeDisplayViewHandler = (dpView: string) => {
    setIsFirstLoad(false);
    updateDisplayView(dpView);
  };

  const onInputDisplayEditHandler = (dbEdit: string) => {
    setIsFirstLoad(false);
    updateDisplayEdit(dbEdit);
  };

  const updateDisplayView = (dpView: string) => {
    setDisplayView(dpView);
    updateBoConfigItems({
      id: boObjectItem.id,
      display_view: dpView,
    });
  };

  const updateDisplayEdit = (dbEdit: string) => {
    setIsFirstLoad(false);
    setDisplayEdit(dbEdit);
    updateBoConfigItems({
      id: boObjectItem.id,
      display_edit: dbEdit,
    });
  };

  const onChangeIsHiddenHandler = (show_status: boolean) => {
    setIsFirstLoad(false);
    setIsHidden(show_status);
    updateBoConfigItems({
      id: boObjectItem.id,
      show_status,
    });
  };

  const onChangeIsParent = (ref_id: string) => {
    setIsFirstLoad(false);
    setIsParent(ref_id);
    updateBoConfigItems({
      id: boObjectItem.id,
      ref_id,
    });
  };

  //UTILITY FUNCTION
  const setupDisplayViewOnInputTypeChange = (displayViewData: any) => {
    console.log('displayViewData =>', displayViewData)
    let displayViewOld = boObjectItem?.display_view;
    if (displayViewOld && isFirstLoad) {
      displayViewData.length > 0
        ? updateDisplayView(displayViewOld)
        : updateDisplayView("");
    } else {
      displayViewData.length > 0
        ? updateDisplayView(displayViewData[0].value)
        : updateDisplayView("");
    }
  };

  const setupDisplayEditOnDisplayViewChange = (
    displayEditData: any,
    displayEditSelectItems: any
  ) => {
    let defaultDisplayText = displayEditData?.showText;
    if (isFirstLoad) {
      let displayEditText = boObjectItem?.display_edit;
      updateDisplayEdit(displayEditText);
    } else {
      displayEditSelectItems.length > 0
        ? updateDisplayEdit(displayEditSelectItems[0].value)
        : updateDisplayEdit(defaultDisplayText as string);
    }
  };

  const setDataOnLoad = () => {
    setFieldName(boObjectItem.bo_name);
    setInputType(boObjectItem.input_type);
    setIsHidden(boObjectItem.show_status);
  };

  const updateBoConfigItems = (inputChange: any) => {
    console.log("inputChange => ", inputChange);
    let boConfigItems = storyEntityContext.boConfigItems;
    let newBoConfigItems = getUpdateBoConfigItemsByID(
      { ...inputChange },
      boConfigItems
    );
    storyEntityContext.setBoConfigItems(newBoConfigItems);
    console.log("boConfigItems => ",boConfigItems)
  };

  return (
    <>
      <tr
        key={boObjectItem.id}
        onClick={() => onSelectRow(boObjectItem.id)}
        className={
          rowSelected === boObjectItem.id ? styles.rowTrSelected : styles.rowTr
        }
        style={{ backgroundColor: boObjectItem.data_type == "Object" ? "#e3e3e3" : "white" }}
      >
        {/* <td style={{ width: "15px" }}>
        <Checkbox checked={isCheckItem} onChange={onChangeCheckItem} />
      </td> */}
        <td>
          <Input
            value={fieldName}
            placeholder="BO Name"
            className={styles.searchInput}
            onChange={(e) => onChangeEditFieldName(e.currentTarget.value)}
          />
        </td>
        {/* <td>{boObjectItem.data_type}</td> */}
        <td>
          <Select
            variant={"borderless"}
            defaultValue={boObjectItem.data_type}
            style={{ width: 120 }}
            onChange={onChangeDataTypeHandler}
            options={dataTypes.map((dataType) => ({
              label: dataType.label,
              value: dataType.value,
            }))}
          />
        </td>
        <td>
          <Select
            variant={"borderless"}
            defaultValue={boObjectItem.input_type}
            style={{ width: 120 }}
            onChange={onChangeInputTypeHandler}
            disabled={isDisabledParent}
            options={inputTypes.map((inputType) => ({
              label: inputType.label,
              value: inputType.value,
            }))}
          />
        </td>
        <td>
          <Select
            variant={"borderless"}
            placeholder={"Please select"}
            value={displayView}
            style={{ width: 120 }}
            onChange={onChangeDisplayViewHandler}
            disabled={isDisabledParent}
            options={displayViews?.map((view: any) => ({
              label: view.label,
              value: view.value,
            }))}
          />
        </td>

        {/* DISPLAY EDIT */}
        <td>
          {editFieldType === "text" && (
            <Input
              onChange={(e) => onInputDisplayEditHandler(e.currentTarget.value)}
              // className={styles.inputSearchApp}
              defaultValue={boObjectItem.display_edit}
              placeholder={"Display Edit"}
              value={displayEdit}
            />
          )}
          {editFieldType === "dropdown" && (
            <Select
              variant={"borderless"}
              placeholder={"Please select"}
              value={displayEdit}
              style={{ width: 120 }}
              onChange={onInputDisplayEditHandler}
              disabled={isDisabledParent}
              options={displayEdits.map((view: any) => ({
                label: view.label,
                value: view.value,
              }))}
            />
          )}
          {editFieldType === "cascader" &&
            (displayEdits.length > 0 ? (
              <Select
                variant={"borderless"}
                placeholder={"Please select"}
                value={displayEdit}
                style={{ width: 120 }}
                onChange={onInputDisplayEditHandler}
                options={displayEdits.map((view: any) => ({
                  label: view.label,
                  value: view.value,
                }))}
              />
            ) : (
              <Input
                onChange={(e) =>
                  onInputDisplayEditHandler(e.currentTarget.value)
                }
                // className={styles.inputSearchApp}
                // defaultValue={boObjectItem.display_edit}
                placeholder={"Display Edit"}
                value={displayEdit}
              />
            ))}
        </td>
        {/* DISPLAY EDIT */}

        <td>
          <Switch
            size="small"
            value={isHidden}
            disabled={isDisabledParent}
            onChange={onChangeIsHiddenHandler}
          />
        </td>
        <td>
          <Select
            variant={"borderless"}
            placeholder={"Parent select"}
            value={boObjectItem.ref_id}
            style={{ width: 120 }}
            onChange={onChangeIsParent}
            // disabled={!isDisabledParent}
            options={parentOption
              .filter((view: any) => view.bo_name !== fieldName)
              .filter((view: any) => view.data_type === "Object")
              .map((view: any) => ({
                label: view.bo_name,
                value: view.id,
              }))}
          />
        </td>
      </tr>
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );
}

export default BoObjectConfigItem;
