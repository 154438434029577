import {ParamConstant} from '../../ParamConstant';
import {ParameterTemplate} from '../ParameterTemplate';
import * as go from "gojs";
//@ts-ignore
// let go = window.go;
export function TreeNode() {
	//@ts-ignore
	go.Node.call(this);
}

go.Diagram.inherit(TreeNode, go.Node);


/** @override */
TreeNode.prototype.findVisibleNode = function() {
	let n = this;
	while (n !== null && !n.isVisible()) {
		n = n.findTreeParentNode();
	}
	return n;
};

export class  ParameterIsGroup {
	
	// AbstractParameter(this);

	public linkable(){
		return { fromLinkable: true, toLinkable: true };
	};
	
	public createButtonEvent($$:any, paramTemplate:ParameterTemplate) {
		return {};
	};
	
	public createParameter($$:any, paramTemplate:ParameterTemplate){
		return $$(TreeNode,
			{
				movable : false 
				,selectionChanged: function(part:any) {				
					// MyDiagramState.startTransaction("Edit Visible");	
					// MyDiagramState.model.setDataProperty(part.data, "visible", part.isSelected ? true : false);
					// MyDiagramState.commitTransaction("EEdit Visible");
					
				}
			},
			{	toolTip:  // define a tooltip for each node that displays the color as text
			        $$(go.Adornment, "Auto",
			                $$(go.Shape, { fill: "#FFFFFF" }),
			                $$(go.TextBlock, { margin: 4 }
			                ,new go.Binding("text", "mustRef",function(s:boolean) { 
								if(s)
									return "When parent object is copied, this parameter will be reference key."; 
								return "When parent object is copied, this parameter not be reference key."; 
							})
			                )
			        )  // end of Adornment
			}
			,this.linkable()
				
			,$$(go.Panel, "Vertical"
				
				,$$(go.Panel, "Horizontal" 
						,{ width: 350,  contextMenu: $$(go.Adornment) ,height: 30  }
							
							,$$(go.Panel, "Horizontal"
								,{ width: 200  }
								
								,$$(go.Panel, "Horizontal"
									,paramTemplate.treeSetting(new go.Margin(1, 5, 1, 1))									
									,$$(go.TextBlock
										,{font: "bold 12px sans-serif",stroke:"#000000"} 
										,new go.Binding("text", "paramName",function(s:string,o:any) {
											if(s.length>=35)
												return s.substring(0,25)+"...";
											return s;
										})
										,new go.Binding("stroke", "willBeMapping", function(s:boolean){ return  s ? "#113F8C": "#000000";})
										,new go.Binding("font", "willBeMapping", function(s:boolean,o:any){ 
											return  s ||o.part.data.typeDesc == ParamConstant.OBJ_DESC ? "bold 12px sans-serif": "12px sans-serif";})
									)
									//show button event
									,this.createButtonEvent($$, paramTemplate)
									
									,new go.Binding("margin", "margin", function(s:string){										
										var oldMarginArr = s.split(" ");
										var calMargin = parseInt(oldMarginArr[3])-25;
										var newMargin =  oldMarginArr[0]+" "+oldMarginArr[1]+" "+oldMarginArr[2]+" "+calMargin;										
										return go.Margin.parse(newMargin);
									})
								)
							)							
							,$$(go.Panel, "Horizontal"
								,{ width: 80  }
								,$$(go.TextBlock
									,{font: "bold 12px sans-serif",stroke:"#778CA2"} 
									,new go.Binding("text", "typeDesc", function(s:string,o:any) { 
										return o.part.data.isList ? "List "+s+"" :""+s+""; 
									})
									,new go.Binding("stroke", "willBeMapping", function(s:boolean){ return  s ? "#113F8C": "#778CA2";})
									,new go.Binding("font", "willBeMapping", function(s:boolean,o:any){ 
										return  s ||o.part.data.typeDesc == ParamConstant.OBJ_DESC ? "bold 12px sans-serif": "12px sans-serif";})
								)
							)
					)// end Horizontal Panel
					
					,$$(go.Panel, "Horizontal" 
							,$$(go.Shape, "LineH", { stroke: "#FFFFFF", width: 350, height: 1 })
					 )
				
				)// end Vertical Panel
	); // end Node
	};
	

};


// ParameterIsGroup.prototype = Object.create(AbstractParameter.prototype);

