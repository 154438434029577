import React from "react";

const StoryCreateIcon = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      className={className}
      style={style}
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7566 14.1667H21.1491V2.83333C21.1491 2.08189 20.8434 1.36122 20.2992 0.829865C19.755 0.298512 19.0169 0 18.2472 0C17.4776 0 16.7395 0.298512 16.1953 0.829865C15.6511 1.36122 15.3453 2.08189 15.3453 2.83333V14.1667H3.73782C2.96819 14.1667 2.23009 14.4652 1.68588 14.9965C1.14167 15.5279 0.835938 16.2486 0.835938 17C0.835938 17.7514 1.14167 18.4721 1.68588 19.0035C2.23009 19.5348 2.96819 19.8333 3.73782 19.8333H15.3453V31.1667C15.3453 31.9181 15.6511 32.6388 16.1953 33.1701C16.7395 33.7015 17.4776 34 18.2472 34C19.0169 34 19.755 33.7015 20.2992 33.1701C20.8434 32.6388 21.1491 31.9181 21.1491 31.1667V19.8333H32.7566C33.5263 19.8333 34.2644 19.5348 34.8086 19.0035C35.3528 18.4721 35.6585 17.7514 35.6585 17C35.6585 16.2486 35.3528 15.5279 34.8086 14.9965C34.2644 14.4652 33.5263 14.1667 32.7566 14.1667Z"
        fill="#ABABAB"
        fillOpacity="0.4"
      />
    </svg>
  );
};
export default StoryCreateIcon;
