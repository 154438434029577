import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./HistoryVersionDrawer.module.scss";
import { GET_VERSION_PAGE_GENERATE_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";
import { GetHistoryVersionModel } from "../model/model";
import { callApiMethodGet } from "src/services/util.service";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";
import {
  getPathToGenPageWithBoIdAndVersionID
} from "src/utils/routes-util/RoutesUtility";

import { useLocation, useNavigate, useParams } from "react-router";

type Props = {
  isOpen: boolean;
  enyityBoID: string;
  onCreate: () => void;
  onClose: () => void;
};

function HistoryVersionDrawer({
  isOpen,
  enyityBoID,
  onCreate,
  onClose,
}: Props) {
  const [isGetHistoryVersionModel, setIsGetHistoryVersionModel] = useState<
    GetHistoryVersionModel[]
  >([]);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (isOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
    apiGetHistoryVersion();
  }, [isOpen]);

  const openGenerateByVersionID = (versionID:string) => {
    console.log('Open version id => ', versionID)
    console.log('enyityBoID => ', enyityBoID)
    let currentPath = location.pathname;
    let genPage = getPathToGenPageWithBoIdAndVersionID(currentPath, enyityBoID, versionID);
    console.log("genPage => ", genPage)
    navigate(genPage);
  }

  async function apiGetHistoryVersion() {
    try {
      const response = await callApiMethodGet(
        GET_VERSION_PAGE_GENERATE_ENDPOINT,
        {
          enyity_bo_id: enyityBoID,
        }
      );
      if (response.status == 200) {
        setIsGetHistoryVersionModel(response.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setIsGetHistoryVersionModel([]);
    }
  }

  const FooterDrawer = () => {
    return (
      <div className={styles.footerContainer} style={{display: 'none'}}>
        <button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </button>
        <button
          className={styles.btnCreate}
          onClick={onCreate}
          // disabled={isDisabled}
        >
          Create
        </button>
      </div>
    );
  };

  return (
    <>
      <Drawer
        title="Version History"
        closeIcon={<LeftOutlined />}
        open={isOpen}
        onClose={onClose}
        footer={<FooterDrawer />}
      >
        <Timeline>
          {isGetHistoryVersionModel.map((item) => (
            <Timeline.Item>
              <div className={styles.timeLineItem}>
                <div className={styles.hearderTimeline}>
                  <div>
                    <p>{item.name}</p>
                    <p>{item.detail}</p>
                  </div>
                  <div className={styles.divbtnOpenHistory}>
                    <button 
                      className={styles.btnOpen} 
                      onClick={() => openGenerateByVersionID(item.id)} 
                    >
                      Open
                    </button>
                  </div>
                </div>
                <div className={styles.profile}>
                  <div className={styles.circularImage}>
                    <img
                      src={
                        "https://i.pinimg.com/236x/ab/24/0a/ab240a0632380ffdd977c6208724a280.jpg"
                      }
                      alt="profile"
                    />
                  </div>
                  <p style={{ margin: 10 }}>{item.created_by}</p>
                </div>
                <p>{formatISODateToCustomFormat(item.created_at)}</p>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>
    </>
  );
}

export default HistoryVersionDrawer;
