import { cssText } from "src/template/header/cssText"
import { jsText } from "src/template/header/jsText"

let headerTest = {
    "refTemplate": "Header",
    "configType": "Center", //Left, Center, Right,None
    "notification": "Y",//N
    "userProfile": "Y",//N
    "logo": "Y",//N
    "dropdown": "Y",//N
    "header": [
        {
            "seq": 3,
            "label": "Home",
            "link": {
                "type": "url", //url,story
                "url": "1",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": "",
                    "pageName": ""
                }
            },
            "childs": [
                {
                    "seq": 1,
                    "label": "childs 1",
                    "link": {
                        "type": "url", //url,story
                        "url": "childs 1",
                        "story": {
                            "storyId": "",
                            "storyName": "",
                            "boName": "",
                            "lastVersion": "",
                            "pageName": ""
                        }
                    }
                },
                {
                    "seq": 2,
                    "label": "child 2",
                    "link": {
                        "type": "url", //url,story
                        "url": "https://oneweb.tech/",
                        "story": {
                            "storyId": "",
                            "storyName": "",
                            "boName": "",
                            "lastVersion": "",
                            "pageName": ""
                        }
                    }
                },
            ]
        },
        {
            "seq": 2,
            "label": "Feature",
            "link": {
                "type": "url", //url,story
                "url": "https://oneweb.tech/features",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": "",
                    "pageName": ""
                }
            },
            "childs": []
        },
        {
            "seq": 1,
            "label": "Blog",
            "link": {
                "type": "url", //url,story
                "url": "",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": "",
                    "pageName": ""
                }
            },
            "childs": [{
                "seq": 1,
                "label": "Learning",
                "link": {
                    "type": "url", //url,story
                    "url": "https://oneweb.tech/",
                    "story": {
                        "storyId": "",
                        "storyName": "",
                        "boName": "",
                        "lastVersion": "",
                        "pageName": ""
                    }
                }
            },]
        }
    ]
}
interface LINK {
    type: string,
    url: string,
    story: {
        storyId: string,
        storyName: string,
        boName: string,
        lastVersion: string,
        pageName: string
    }
}

interface HEADER {
    seq: number,
    label: string,
    link: LINK
    childs: CHILD[];
}

interface CHILD {
    seq: number,
    label: string,
    link: LINK
}

interface JSON_HEADER {
    refTemplate: string,
    configType: string,
    notification: string,
    userProfile: string,
    logo: string,
    dropdown: string, //Y/N
    header: HEADER[]
}

interface JSON_HEADER_RES {
    htmlText: string,
    jsText: string,
    cssText: string,
    functionName: string
}

const getLiContents = (columnObj: HEADER): string => {
    console.log("childs:> ", columnObj);

    let liContents = "";
    let pghref = "";
    let href = "";
    let subLi = "";


    columnObj.childs.sort((a, b) => a.seq - b.seq);
    if (columnObj.childs.length > 0) {
        if (columnObj.link.type === "url") {
            pghref = `href="${columnObj.link.url}" target="_blank"`;
        } else if (columnObj.link.type === "story") {
            pghref = `href="./${columnObj.link.story?.pageName}"`;
        }

        for (let a = 0; a < columnObj.childs.length; a++) {
            let child = columnObj.childs[a];
            console.log("childs:> ", child);
            if (child.link.type === "url") {
                href = `href="${child.link.url}" target="_blank"`;
            } else if (child.link.type === "story") {
                href = `href="./${child.link.story?.pageName}"`;
            }

            // subLi += `<li><a ${href} class="dropdown-link">${child.label} </a></li>`;
            subLi += `<li><a ${href}  >${child.label}</a></li>`
        }

        // liContents = `<li class="nav-li-item dropdown">
        //         <a ${pghref} class="nav-link">${columnObj.label} <span class="dropdown-arrow">
        //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
        //             <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        //         </svg>
        //     </span></a>
        //         <ul class="dropdown-menu">
        //         ${subLi}
        //         </ul></li>`;

        liContents = `
                        <li><a ${pghref} class="dropdown-toggle">${columnObj.label} </a>
                            <ul class="dropdown">
                            ${subLi}
                            </ul>
                        </li>
                    `;
    } else {
        if (columnObj.link.type === "url") {
            pghref = `href="${columnObj.link.url}" target="_blank"`;
        } else if (columnObj.link.type === "story") {
            pghref = `href="./${columnObj.link.story?.pageName}"`;
        }
        liContents = `<li><a ${pghref}>${columnObj.label} </a></li>`;
    }
    return liContents;
}

const navbarContainer = (jsonHeader: JSON_HEADER): string => {

    let logoContainer = "";
    let classNav = "";
    let menuContainer = "";
    let li = "";
    console.log("jsonHeader", jsonHeader)

    switch (jsonHeader.configType.toLowerCase()) {
        case 'left' || 'none':
            classNav = 'justify-content-left';
            break;
        case 'right':
            classNav = 'justify-content-right';
            break;
        case 'center':
            classNav = 'justify-content-center';
            break;
    }

    if (jsonHeader.logo === "Y") {
        logoContainer += `<div class="logo-container"><img alt="logo-image"
        src="https://oneweb.tech/img/e4f74c59-c07b-379c-886a-80c2a9a6161b.png"
        class="logo-image">
        </div>`;
    }

    if (jsonHeader.header.length < 0) {
        console.error("configType with jsonHeader.header not math")
        return "";
    }

    let headerSort = jsonHeader.header.sort((a, b) => a.seq - b.seq);
    for (let a = 0; a < headerSort.length; a++) {
        let columnObj = headerSort[a];

        let liContents = getLiContents(columnObj)
        li += liContents;
    }
    let uiContent = `<ul class="ul-header">${li}</ul>`;
    menuContainer = `<nav role="navigation" class="primary-navigation">${uiContent}</nav>`

    let iconContainer = getIconContainer(jsonHeader);

    let navContent = `
         <div class="navbar-container ${classNav}">
            ${logoContainer}
            ${menuContainer}
            ${iconContainer}
         </div>`;

    return navContent;
}

const getIconContainer = (jsonHeader: JSON_HEADER): string => {
    let content = "";
    if (jsonHeader.notification === "Y") {
        content += `<img src="https://test.oneweb.tech/PageDesigner/img/7c067bf2-c071-3f32-89d0-8bf6e84e285d.png"
                        alt="appointment-reminders--v1" class="notification-img" /> `;
    }
    if (jsonHeader.userProfile === "Y") {
        content += ` <img src="https://test.oneweb.tech/PageDesigner/img/0940ad37-187d-3459-9a0a-40b27394424a.png" alt="Avatar" class="avatar">`;
    }

    const iconContainer = `<div class="icon-container"> ${content} </div>`;
    return iconContainer;
}



export function generatePageFileHeader(jsonHeader: JSON_HEADER, pageName: string, datetime: string): any {
    let textContent = "";
    if (pageName == "header") {
        textContent = navbarContainer(jsonHeader);
        textContent = `
        <div class="navbar">
          ${textContent}
        </div>`;
    }

    let root = `<header>${textContent}</header>`;
    let { jsTextReflector, functionName } = transformText(jsText, datetime, pageName);

    return {
        htmlText: root,
        cssText: cssText,
        jsText: jsTextReflector,
        functionName: functionName
    }
}

function transformText(jsTextSource: string, datetime: string, pageName: string) {


    let jsText = jsTextSource.replace(/function (\w+)\(/g, `function $1_${pageName}_${datetime}(`);

    let jsTextTemp = jsText


    let fns = ["init"]
    let propsStatic: any = []


    let propsReplaceMore: any = [];

    for (let a = 0; a < fns.length; a++) {
        const resultArr = jsTextTemp.split(`${fns[a]}(`);
        jsTextTemp = (resultArr.join(`${fns[a]}_${pageName}_${datetime}(`));
    }

    for (let a = 0; a < propsStatic.length; a++) {
        const resultArr = jsTextTemp.split(`${propsStatic[a]}`);
        jsTextTemp = (resultArr.join(`${propsStatic[a]}_${pageName}_${datetime}`));
    }

    for (let a = 0; a < propsReplaceMore.length; a++) {
        const resultArr = jsTextTemp.split(`${propsReplaceMore[a].from}`);
        jsTextTemp = (resultArr.join(`${propsReplaceMore[a].to}`));
    }

    jsText = jsTextTemp;
    const functionName = `init_${pageName}_${datetime}`;
    return { functionName: functionName, jsTextReflector: jsText };
}

export const testHeaderFn = async () => {
    let datetime = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
    let convertHtml, htmlToGen;
    let x: JSON_HEADER_RES = generatePageFileHeader(headerTest as any, "header", datetime)
    // console.log("header div2Content =>", x.div2Content)
    console.log("header div2Content =>", x.htmlText)
    console.log("header jsText =>", x.jsText)
    //@ts-ignore
    convertHtml = window?.CONVERT_HTML_TO_OBJECT_HEADER_FOOTER(x.htmlText, "header");
    console.log("header convertHtml =>", convertHtml)
}

/* html ref nav

 <nav role="navigation" class="primary-navigation">
    <ul>
      <li><a href="#">Home</a></li>
      <li><a href="#">Work &dtrif;</a>
        <ul class="dropdown">
          <li><a href="#">Web Development</a></li>
          <li><a href="#">Web Design</a></li>
          <li><a href="#">Illustration</a></li>
          <li><a href="#">Iconography</a></li>
        </ul>
      </li>
      <li><a href="#">About</a></li>
      <li><a href="#">Contact</a></li>
    </ul>
  </nav>

*/