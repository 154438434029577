import { renderTabScreens, clearTabContent } from './renderTabScreens';

export default function renderTabsScreenContainer(screenElement, container, screen, maxColumn) {
    let gridColumn = `1 / span ${maxColumn}`;
    screenElement.style.gridColumn = gridColumn;

    const tabsContainer = document.createElement('div');
    tabsContainer.className = 'ant-tabs';

    const nav = document.createElement('div');
    nav.className = 'ant-tabs-nav';

    const tabsNavWrap = document.createElement('div');
    tabsNavWrap.className = 'ant-tabs-nav-wrap';


    const tabComponent = document.createElement('div');
    tabComponent.id = 'tab-component-' + screen.key;
    tabComponent.className = 'ant-tabs-tab';

    console.log("screen.tabs: ", screen.tabs);
    console.log("screen: ", screen);
    // if (!screen.tabs) return [];

    screen.tabs.sort((a, b) => a.seq - b.seq).forEach((tab, index) => {
        console.log("tab.tabName:: ", tab.tabName);
        const tabElement = document.createElement('div');
        tabElement.id = 'tab-id-' + tab.tabName;
        tabElement.className = 'ant-tab';
        tabElement.textContent = tab.tabName;


        if (index === 0) {
            tabElement.classList.add('ant-tabs-tab-active'); // เพิ่ม class .ant-tabs-tab-active ให้แท็บแรก
        }
        renderTabScreens(tab, container, screen, maxColumn);


        tabElement.addEventListener('click', () => {
            // ลบ class .ant-tabs-tab-active จากแท็บที่มีอยู่
            const activeTab = tabComponent.querySelector('.ant-tabs-tab-active');
            if (activeTab) {
                activeTab.classList.remove('ant-tabs-tab-active');
            }
            // เพิ่ม class .ant-tabs-tab-active ให้แท็บปัจจุบัน
            tabElement.classList.add('ant-tabs-tab-active');

            handleTabsClick(tab, container, screen, maxColumn);
        });
        tabComponent.appendChild(tabElement);
        tabsNavWrap.appendChild(tabComponent);
    });

    nav.appendChild(tabsNavWrap);
    tabsContainer.appendChild(nav);
    screenElement.appendChild(tabsContainer);
}

function handleTabsClick(tab, container, screen, maxColumn) {
    clearTabContent(container, screen);
    console.log("stepElement:", tab.tabName);
    const stepContent = document.getElementById('tab-' + tab.tabName);
    console.log("stepContent:: :", stepContent);
    if (stepContent) {
        stepContent.style.display = 'grid';
    }
};