import * as go from "gojs";
// import {GojsConstant} from "src/constants/GojsConstant";
import { ParameterTemplate } from "./param/ParameterTemplate";
import { ParamInitialGroup } from "./param/initGroup/ParamInitialGroup";
import { checkEmpty, ParamConstant } from "./ParamConstant";
import { ParameterIsGroup } from "./param/isGroup/ParameterIsGroup";
// import {MyDiagramStateJson} from "src/constants/GojsConstantTest";
import { ParamIsNotGroup } from "./param/isNotGroup/ParamIsNotGroup";
import { ParameterElement } from "./param/ParameterElement";
import { BO_INIT_KEY, TABLE_INIT_KEY } from "../../constants/DiagramInitKey";
import _ from "lodash";
import NodeInit from "src/utils/bo-initial-data/NodeInit";
import { TableType } from "src/types/response.type";

export let MyDiagramState: any;
export let MyDiagramStateJson: any;
export let IsAlreadyAddLinkDrawnState = false;
export let SelectionNodeState: any;
export let SelectedNodeJson: any;
let BoNode: any[] = [];

// import { ParameterTemplate } from './param/ParameterTemplate';
// import { ParamConstant } from 'src/constants/ParamConstant';
// import { InputMappInitialGroup } from './param/initGroup/InputMappInitialGroup';
// import { ParamInitialGroup } from './param/initGroup/ParamInitialGroup';
export const JsonInitBoGojs = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    {
      loc: "0, 0",
      text: "BusinessObject",
      isGroup: true,
      key: "sourceMainGroup",
    },
    {
      paramName: "BusinessObject",
      category: "sourceInitGroup",
      isGroup: false,
      key: "sourceKeyInit",
      group: "sourceMainGroup",
    },
    {
      loc: "550, 0",
      text: "BusinessObject",
      isGroup: true,
      key: "targetMainGroup",
    },
    {
      paramName: "BusinessObject",
      category: "targetInitGroup",
      isGroup: false,
      key: "targetKeyInit",
      group: "targetMainGroup",
    },
  ],
  linkDataArray: [],
};

export const JsonInitMappingGojs = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    {
      loc: "0, 0",
      text: "Source",
      isGroup: true,
      key: "sourceMainGroup",
    },
    {
      paramName: "Source",
      category: "sourceInitGroup",
      isGroup: false,
      key: "sourceKeyInit",
      group: "sourceMainGroup",
    },
    {
      loc: "550, 0",
      text: "Target",
      isGroup: true,
      key: "targetMainGroup",
    },
    {
      paramName: "Target",
      category: "targetInitGroup",
      isGroup: false,
      key: "targetKeyInit",
      group: "targetMainGroup",
    },
  ],
  linkDataArray: [],
};

export const JsonTestGojs = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    {
      loc: "0, 0",
      text: "Source",
      isGroup: true,
      key: "sourceMainGroup",
    },
    {
      paramName: "Source",
      category: "sourceInitGroup",
      isGroup: false,
      key: "sourceKeyInit",
      group: "sourceMainGroup",
    },
    {
      pathName: "User3",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "User3",
      isList: true,
      parentKey: "sourceKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "User3",
      seq: 1,
      group: "sourceMainGroup",
    },
    {
      pathName: "department_code",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "department_code",
      isList: true,
      parentKey: "User3",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3",
      seq: 1,
      group: "sourceMainGroup",
    },
    {
      pathName: "User1",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "User1",
      isList: false,
      parentKey: "sourceKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "User1",
      seq: 1,
      group: "sourceMainGroup",
    },
    {
      pathName: "department_code",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "department_code",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1",
      seq: 1,
      group: "sourceMainGroup",
    },
    {
      pathName: "division_code",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "division_code",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da2",
      seq: 2,
      group: "sourceMainGroup",
    },
    {
      pathName: "doc_no",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "doc_no",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da3",
      seq: 3,
      group: "sourceMainGroup",
    },
    {
      pathName: "doc_owner",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "doc_owner",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da4",
      seq: 4,
      group: "sourceMainGroup",
    },
    {
      pathName: "doc_subject",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "doc_subject",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da5",
      seq: 5,
      group: "sourceMainGroup",
    },

    {
      loc: "550, 0",
      text: "Target",
      isGroup: true,
      key: "targetMainGroup",
    },
    {
      paramName: "Target",
      category: "targetInitGroup",
      isGroup: false,
      key: "targetKeyInit",
      group: "targetMainGroup",
    },
    {
      pathName: "User3_target",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "User3_target",
      isList: true,
      parentKey: "targetKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "User3_target",
      seq: 1,
      group: "targetMainGroup",
    },
    {
      pathName: "department_code_target",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "department_code_target",
      isList: true,
      parentKey: "User3_target",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3_target",
      seq: 1,
      group: "targetMainGroup",
    },
  ],
  linkDataArray: [
    {
      from: "sourceKeyInit",
      to: "User3",
    },
    {
      from: "User3",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3",
    },
    {
      from: "targetKeyInit",
      to: "User3_target",
    },
    {
      from: "User3_target",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3_target",
    },
    {
      from: "sourceKeyInit",
      to: "User1",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da2",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da3",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da4",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da5",
    },
    {
      from: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1_3_target",
      category: "Mapping",
    },
  ],
};

export const JsonTestGojsCreateWidget = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    {
      loc: "0, 0",
      text: "Source",
      isGroup: true,
      key: "sourceMainGroup",
    },
    {
      paramName: "Source",
      category: "sourceInitGroup",
      isGroup: false,
      key: "sourceKeyInit",
      group: "sourceMainGroup",
    },
    {
      loc: "650, 0",
      text: "Target",
      isGroup: true,
      key: "targetMainGroup",
    },
    {
      paramName: "Target",
      category: "targetInitGroup",
      isGroup: false,
      key: "targetKeyInit",
      group: "targetMainGroup",
    },
    {
      pathName: "User1",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "User1",
      isList: false,
      parentKey: "sourceKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "User1",
      seq: 1,
      group: "sourceMainGroup",
    },

    {
      pathName: "Table1",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "Table1",
      isList: false,
      parentKey: "targetKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "Table1",
      seq: 1,
      group: "targetMainGroup",
    },
    {
      pathName: "column1",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "column1",
      isList: false,
      parentKey: "Table1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1",
      seq: 1,
      group: "targetMainGroup",
    },
    {
      pathName: "name",
      margin: "0 0 0 50",
      typeDesc: "String",
      paramName: "name",
      isList: false,
      parentKey: "User1",
      category: "nodeIsNotGroup",
      isGroup: false,
      key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da2",
      seq: 2,
      group: "sourceMainGroup",
    },
  ],
  linkDataArray: [
    {
      from: "sourceKeyInit",
      to: "User1",
    },
    {
      from: "targetKeyInit",
      to: "Table1",
    },
    {
      from: "Table1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da1",
    },
    {
      from: "User1",
      to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da2",
    },
  ],
};

export const testObj = {
  class: "go.GraphLinksModel",
  nodeDataArray: [
    {
      loc: "0, 0",
      text: "Source",
      isGroup: true,
      key: "sourceMainGroup",
    },
    {
      paramName: "Source",
      category: "sourceInitGroup",
      isGroup: false,
      key: "sourceKeyInit",
      group: "sourceMainGroup",
    },
    {
      loc: "650, 0",
      text: "Widget",
      isGroup: true,
      key: "targetMainGroup",
    },
    {
      paramName: "Widget",
      category: "targetInitGroup",
      isGroup: false,
      key: "targetKeyInit",
      group: "targetMainGroup",
    },
    {
      pathName: "GenerateFlow",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "GenerateFlow",
      isList: false,
      parentKey: "sourceKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "GenerateFlow",
      seq: 1,
      group: "sourceMainGroup",
    },
    {
      pathName: "InputWidget",
      margin: "0 0 0 30",
      typeDesc: "Object",
      paramName: "InputWidget",
      isList: false,
      parentKey: "targetKeyInit",
      category: "nodeIsGroup",
      isGroup: false,
      key: "InputWidget",
      seq: 1,
      group: "targetMainGroup",
    },
  ],
  linkDataArray: [
    {
      from: "sourceKeyInit",
      to: "GenerateFlow",
    },
    {
      from: "targetKeyInit",
      to: "InputWidget",
    },
    {
      from: "GenerateFlow",
      to: "InputWidget",
      category: "Mapping",
    },
  ],
};
//@ts-ignore
// let go = window.go;
const GojsConstant = {
  // GOJS_LICENSE_KEY : "73f143e7b20537c702d90776423d6bf919a17564ce841fa30a0415f6ef0d6a06329fed2857d28c93d1af4efe1c7c9789d8c06a21c248513db031d7db45e18eaeb73320e5410f4488a30b20d189fc7ff1fa7823a2c3a767a1db7884edeba9dc9c5b"
  //"73ff47e2b21c28c702d95d76423d6cbc5cf07f21de960ef70f001df4e5586d40219aef7801838ec5d1f01bf84e7b97d9d9c26f2a9f1b003fb46686db40e481f1b42563b44158418ef65327d189f92ba1f56772edc1b3";
  // GOJS_LICENSE_KEY : "73ff47e2b21c28c702d95d76423d6cbc5cf07f21de824da3595013a7ba5c6b162b98bf2a01d68ac9d7f84efb1c7d92cbcdc26179974c5668b56586dc44e282f1b03221bb110c1489a50776c4c9f829f2fa6a61f497e571a288288de0fbabc29c55f7f1cb4f"
  GOJS_LICENSE_KEY:
    "73f143e7b20537c702d90776423d6bf919a17564ce841fa30a0415f6ef0d6a06329fed2857d28c93d1af4efe1c7c9789d8c06a21c248513db031d7db45e18eaeb73320e5410f4488a30b20d189fc7ff1fa7823a2c3a767a1db7884edeba9dc9c5b",
  //from P'Chang
  //"73f143e7b20537c702d90776423d6bf919a17564ce841fa30a0415f6ef0d6a06329fed2857d28c93d1af4efe1c7c9789d8c06a21c248513db031d7db45e18eaeb73320e5410f4488a30b20d189fc7ff1fa7823a2c3a767a1db7884edeba9dc9c5b";
};

// export const MappingParam = (props: any) => {
export class MappingParam {
  public init(refElment: any) {
    this.initDiagram(refElment);
    // this.loadDataToDiagram(MyDiagramStateJson);
    // var x = this.getDataFromDiagram();
  }

  public setBoMapping(newBoNodes: any[]) {
    BoNode = newBoNodes;
  }
  public getBoMapping() {
    return BoNode;
    // let nodeArray = this.getDataFromDiagram().nodeDataArray;
    // nodeArray = nodeArray.filter((node: any) => node.typeDesc !== "Object" && node.group === "boGroup")
    // return nodeArray;
  }
  public isGenerateByBo() {
    return BoNode.length > 0 ? true : false;
  }
  public checkLink(fn: any, fp: any, tn: any, tp: any, link: any) {
    return (
      fn.containingGroup !== tn.containingGroup && fn.position.x < tn.position.x
    );
  }
  public countMappingLink() {
    var numMappingLink = 0;
    var diagramModel = MyDiagramState.model;
    var dataLinkArr = diagramModel.linkDataArray;
    for (var a = 0; a < dataLinkArr.length; a++) {
      if (!checkEmpty(dataLinkArr[a].points)) {
        numMappingLink++;
      }
    }

    return numMappingLink;
  }

  public initDiagram(refElment: any) {
    //@ts-ignore
    if (window.goSamples) goSamples(); // init for these samples -- you don't need to call this
    go.Diagram.licenseKey = GojsConstant.GOJS_LICENSE_KEY;

    const $$ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    let myDiagram = $$(go.Diagram, refElment, {
      "undoManager.isEnabled": true, // enable Ctrl-Z to undo and Ctrl-Y to redo
      "commandHandler.copiesTree": true,
      "commandHandler.deletesTree": true,
      "animationManager.isInitial": false,
      mouseWheelBehavior: go.ToolManager.WheelNone,

      // newly drawn links always map a node in one tree to a node in another tree
      "linkingTool.archetypeLinkData": {
        category: "Mapping",
      },
      "linkingTool.linkValidation": this.checkLink,
      "relinkingTool.linkValidation": this.checkLink,
      initialContentAlignment: new go.Spot(0.0, 0.0),
      initialPosition: new go.Point(0, 0),
      allowMove: false,
    });
    MyDiagramState = myDiagram;

    MyDiagramState.linkTemplate = $$(go.Link);
    MyDiagramState.linkTemplateMap.add(
      "Mapping",
      $$(
        go.Link,
        { isTreeLink: false, isLayoutPositioned: true },
        { fromSpot: go.Spot.Right, toSpot: go.Spot.Left },
        {
          routing: go.Link.Orthogonal,
          adjusting: go.Link.Stretch,
          toShortLength: 10,
          corner: 10,
          relinkableFrom: true,
          relinkableTo: true,
          //,reshapable: true
          resegmentable: true,
        },

        new go.Binding("points").makeTwoWay(),
        $$(go.Shape, { stroke: "#778CA2", strokeWidth: 2, width: 100 }),
        $$(go.Shape, { toArrow: "Circle", fill: "#778CA2", stroke: "#778CA2" }),
        $$(
          go.Panel,
          "Auto",
          { segmentIndex: 0, segmentOffset: new go.Point(100, 0) },
          $$(
            go.Shape,
            "RoundedRectangle",
            {
              height: 50,
              width: 50,
              fill: null,
              stroke: null,
            },
            new go.Binding("fill", "labelName", function (v: any) {
              return null != v && v.trim().length > 0 ? "#778CA2" : "";
            }),
            new go.Binding("stroke", "labelName", function (v: any) {
              return null != v && v.trim().length > 0 ? "#778CA2" : "";
            })
          ),
          $$(
            go.TextBlock,
            "...",
            {
              stroke: null,
            },
            new go.Binding("stroke", "labelName", function (v: any) {
              return null != v && v.trim().length > 0 ? "#778CA2" : "";
            })
          )
        )
      )
    );

    MyDiagramState.groupTemplate = $$(
      go.Group,
      "Auto",
      new go.Binding("position", "loc", go.Point.parse),
      //,new go.Binding("position", "loc", go.Point.parse).makeTwoWay()
      {
        layout: $$(go.TreeLayout, {
          alignment: go.TreeLayout.AlignmentStart,
          angle: 0,
          compaction: go.TreeLayout.CompactionNone,
          layerSpacing: 0,
          layerSpacingParentOverlap: 1,
          nodeIndent: 0,
          nodeIndentPastParent: 0.99,
          nodeSpacing: 0,
          setsPortSpot: false,
          setsChildPortSpot: false,
        }),
      },

      $$(go.Shape, {
        fill: "white",
        figure: "RoundedRectangle",
        stroke: "#EAEAEA",
        width: 350,
      }),
      $$(
        go.Panel,
        "Vertical",
        { defaultAlignment: go.Spot.Left, width: 350 },
        $$(go.Placeholder, { padding: 0 })
      )
    );

    MyDiagramState.addDiagramListener(
      "InitialLayoutCompleted",
      function (e: any) {
        // CommonEventParam.parameterEvent.resizeDiagramByDiagram();
      }
    );
    MyDiagramState.addDiagramListener(
      "LinkRelinked",
      function (e: any, obj: any) {
        console.log("LinkRelinked");
        let linkResults = MyDiagramState.findLinksByExample({
          to: MyDiagramState.toolManager.relinkingTool.originalToNode.data.key,
          from: MyDiagramState.toolManager.relinkingTool.originalFromNode.data
            .key,
        }).iterator;
        while (linkResults.next()) {
          MyDiagramState.model.removeLinkData(linkResults.value.data);
        }

        let from = e.subject.part.data.from;
        let to = e.subject.part.data.to;
      }
    );

    MyDiagramState.addDiagramListener("ObjectSingleClicked", function (e: any) {
      MyDiagramState.selection.each(function (node: any) {
        SelectionNodeState = node;

        // let cmd = MyDiagramState.commandHandler;
        // if(node.isTreeExpanded) {
        //   cmd.collapseTree(node);
        //   console.log("isTreeExpanded")
        // } else {
        //   cmd.expandTree(node);
        //   console.log("isTreeCollapse")
        // }

        if (node instanceof go.Link) {
          console.log("##node instanceof go.Link");
          // console.log("## ObjectSingleClicked Link => " + JSON.stringify(e.subject.part.data));
        } else {
          console.log(
            "## ObjectSingleClicked node => " +
            JSON.stringify(e.subject.part.data)
          );
          SelectedNodeJson = JSON.stringify(e.subject.part.data);
        }
      });
    });

    myDiagram.addDiagramListener("BackgroundSingleClicked", function (e) {
      // Blank space was clicked
      console.log("Blank space clicked");
      SelectedNodeJson = null;
    });

    // Event handler for NodeClick, specifically for expandable parent nodes
    // myDiagram.addDiagramListener("TreeCollapsed", (e) => {
    //   const node = e.subject;
    //   if (node instanceof go.Node && node.isGroup !== undefined) {
    //     node.isExpanded = !node.isExpanded; // Toggle expansion of child nodes
    //   }
    // });

    myDiagram.addDiagramListener("SelectionDeleting", function (e) {
      // Handle the deletion logic here
      const nodesToDelete = e.subject;
      if (nodesToDelete.count > 0) {
        // Perform any additional logic if needed
        console.log("Nodes to delete:", nodesToDelete.toArray());
      }
    });

    var lastedSeq = 0;
    MyDiagramState.addDiagramListener(
      "InitialLayoutCompleted",
      function (e: any) {
        console.log(">>>> InitialLayoutCompleted <<<<");
        try {
          //get max sequence
          let nodeDataArr = MyDiagramState.model.nodeDataArray;
          for (let a = 0; a < nodeDataArr.length; a++) {
            let nodeData = new ParameterElement().setjParameterElement(
              nodeDataArr[a]
            );
            if (nodeData.getSeq() > lastedSeq) {
              lastedSeq = nodeData.getSeq();
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    );
    let paramTemplate = new ParameterTemplate($$);
    let templateNode = new go.Map();

    let inputInitGroup = new ParamInitialGroup().createParameter(
      $$,
      paramTemplate
    );
    let outputInitGroup = new ParamInitialGroup().createParameter(
      $$,
      paramTemplate
    );
    let nodeIsGroup = new ParameterIsGroup().createParameter($$, paramTemplate);
    let nodeIsNotGroup = new ParamIsNotGroup().createParameter(
      $$,
      paramTemplate
    );
    templateNode.add(ParamConstant.SOURCE_INIT_GROUP, inputInitGroup);
    templateNode.add(ParamConstant.TARGET_INIT_GROUP, outputInitGroup);
    templateNode.add(ParamConstant.NODE_IS_GROUP, nodeIsGroup);
    templateNode.add(ParamConstant.NODE_IS_NOT_GROUP, nodeIsNotGroup);
    MyDiagramState.nodeTemplateMap = templateNode;

    var linkDrawnTo = "";
    var linkDrawnFrom = "";
    var intervalLink: any = 0;
    var linkDrawnObj = "";

    if (IsAlreadyAddLinkDrawnState == false) {
      console.log("register LinkDrawn");
      MyDiagramState.addDiagramListener(
        "LinkDrawn",
        function (e: any, obj: any) {
          //var objParameterElements = new ParameterElement();
          console.log("##### LinkDrawn ############ ");
          //Event object.
          var from = e.subject.part.data.from;
          var to = e.subject.part.data.to;

          linkDrawnTo = e.subject.part.data.to;
          linkDrawnFrom = e.subject.part.data.from;
          linkDrawnObj = e.subject.part.data;
        }
      );
      IsAlreadyAddLinkDrawnState = true;
    }

    let seft = this;
    MyDiagramState.model.addChangedListener(function (e: any) {
      console.log("e.toString() => " + e.toString());
      if (e.isTransactionFinished && !checkEmpty(e.toString())) {
        console.log("addChangedListener");
        var actNameArr = e.toString().split(":");
        //console.log(" actNameArr ===>"+actNameArr[1].trim());

        if (actNameArr[1].trim() === "Linking") {
          var dataLinkArr: any = MyDiagramState.model.linkDataArray;
          var numMappingLink = seft.countMappingLink();
          if (numMappingLink > 0)
            intervalLink = numMappingLink * ParamConstant.INTERVAL_LINK;
          for (var a = 0; a < dataLinkArr.length; a++) {
            if (
              !checkEmpty(dataLinkArr[a].points) &&
              dataLinkArr[a].from === linkDrawnFrom &&
              dataLinkArr[a].to === linkDrawnTo
            ) {
              var linkPosArr: any = [];
              for (var b = 0; b < dataLinkArr[a].points.size; b++) {
                if (b == 2 || b == 3) {
                  linkPosArr.push(
                    dataLinkArr[a].points.get(b).x + intervalLink
                  );
                } else {
                  linkPosArr.push(dataLinkArr[a].points.get(b).x);
                }

                linkPosArr.push(dataLinkArr[a].points.get(b).y);
              } // end for b
              intervalLink = intervalLink + intervalLink;

              MyDiagramState.startTransaction("Edit link position");
              //console.log("linkPosArr ==> "+linkPosArr);
              // diagramModel.setDataProperty(linkDrawnObj, "points", linkPosArr);
              MyDiagramState.commitTransaction("Edit link position");

              break;
            } // end if
          } // end for
        } // end if "Linking"
      }
    });

    // MyDiagramState.model = go.Model.fromJson(MyDiagramStateJson);
    MyDiagramState.requestUpdate();
  }

  public loadDataToDiagram(json: any) {
    MyDiagramStateJson = json;
    console.log(go.Model.fromJson(json));
    console.log(json);
    MyDiagramState.model = go.Model.fromJson(json);
    MyDiagramState.requestUpdate();
  }

  public getDataFromDiagram() {
    let datoDiagram: any = {};
    datoDiagram.nodeDataArray = [...MyDiagramState.model.nodeDataArray];
    datoDiagram.linkDataArray = [...MyDiagramState.model.linkDataArray];
    // console.log(
    //   "MyDiagramState.model.toJSON() ",
    //   MyDiagramState.model.toJSON()
    // );

    // console.log("datoDiagram ", datoDiagram);
    return datoDiagram;
  }
  public getStateDiagramJson() {
    return MyDiagramStateJson;
  }

  getNodeAndLinkData() {
    let nodeLink = {
      nodes: [...MyDiagramState.model.nodeDataArray],
      links: [...MyDiagramState.model.linkDataArray],
    };
    return nodeLink;
  }

  getNodeIsGroupData() {
    const data = MyDiagramState.model.nodeDataArray;
    const nodeIsGroupData = [];
    for (const datas of data) {
      if (datas.category === "nodeIsGroup") {
        nodeIsGroupData.push(datas);
      }
    }
    return nodeIsGroupData;
  }

  getNodeSelect() {
    console.log("SelectedNodeJson ", SelectedNodeJson);
    console.log("SelectedNodeState ", SelectionNodeState);
    return SelectedNodeJson ? JSON.parse(SelectedNodeJson) : null;
  }

  addDataModel(data: any) {
    const isObject = data.typeDesc === "Object";
    const newNodeData = {
      pathName: data.paramName,
      margin: data.margin,
      typeDesc: data.typeDesc,
      paramName: data.paramName,
      isList: isObject,
      parentKey: data.parentKey,
      category: data.category,
      isGroup: false,
      key: isObject ? data.paramName : data.key,
      seq: 0,
      group: data.group,
    };

    const maxSeqNode = this.findSeqNode(data.parentKey);
    newNodeData.seq = maxSeqNode.seq + 1;
    newNodeData.seq = newNodeData.seq === 0 ? 1 : newNodeData.seq;

    const newLinkData = {
      from: data.parentKey,
      to: isObject ? data.paramName : data.key,
    };

    MyDiagramStateJson.nodeDataArray.push(newNodeData);
    MyDiagramStateJson.linkDataArray.push(newLinkData);
    console.log("Data pushed:::", MyDiagramState.model.nodeDataArray);
    this.loadDataToDiagram(MyDiagramStateJson);
  }

  findSeqNode(parentKey: any) {
    return MyDiagramStateJson.nodeDataArray.reduce(
      (maxNode: any, node: any) => {
        if (node.parentKey === parentKey && node.seq > maxNode.seq) {
          return node;
        }
        return maxNode;
      },
      { seq: -1 }
    );
  }

  updateDataModel(data: any) {
    const keyToRemove = data.key;
    const newData = {
      paramName: data.paramName,
      typeDesc: data.typeDesc,
      isPrimaryKey: data.isPrimaryKey,
      foreignKey: data.foreignKey,
    };
    this.updateDataByKey(keyToRemove, newData, MyDiagramStateJson);
    this.loadDataToDiagram(MyDiagramStateJson);
  }

  updateDataByKey(key: any, newData: any, obj: any) {
    obj.nodeDataArray = obj.nodeDataArray.map((item: any) => {
      if (item.key === key) {
        return { ...item, ...newData };
      }
      return item;
    });

    return obj;
  }

  removeArrayByKey(key: any) {
    const targetData = MyDiagramStateJson.nodeDataArray.find(
      (item: any) => item.key === key
    );

    if (!targetData) {
      return MyDiagramStateJson;
    }

    if (targetData.typeDesc === "Object") {
      this.removeChildren(MyDiagramStateJson, key);
      MyDiagramStateJson.nodeDataArray =
        MyDiagramStateJson.nodeDataArray.filter(
          (item: any) => item.key !== key
        );
      MyDiagramStateJson.linkDataArray =
        MyDiagramStateJson.linkDataArray.filter(
          (item: any) => item.from !== key && item.to !== key
        );
    } else {
      MyDiagramStateJson.nodeDataArray =
        MyDiagramStateJson.nodeDataArray.filter(
          (item: any) => item.key !== key
        );
      MyDiagramStateJson.linkDataArray =
        MyDiagramStateJson.linkDataArray.filter(
          (item: any) => item.from !== key && item.to !== key
        );
    }

    this.loadDataToDiagram(MyDiagramStateJson);
    return MyDiagramStateJson;
  }

  removeTreeNodeBySelectionNode() {
    if (!SelectionNodeState.data) return;
    let selectedNodeKey = SelectionNodeState.data.key;

    //IF SELECT ON BO_DIAGRAM OR TABLE_DIAGRAM
    if (selectedNodeKey === BO_INIT_KEY || selectedNodeKey === TABLE_INIT_KEY) {
      this.removeAllNodeChildInGroupInit(selectedNodeKey);

      //IF SELECT ON TREENODE
    } else {
      this.removeNodeChildBySelectNodeKey(selectedNodeKey);
    }
  }

  removeAllNodeChildInGroupInit(groupKey: string) {
    let diagramData = this.getDataFromDiagram();
    let linkData = diagramData.linkDataArray;
    let nodeData = diagramData.nodeDataArray;

    let groupName = nodeData.find((node: any) => node.key === groupKey).group;
    let nodeForRemove = nodeData.filter(
      (node: any) => node.group === groupName && node.key !== groupKey
    );

    let linkForRemove = [];
    if (nodeForRemove.length > 0) {
      MyDiagramState.startTransaction("removeAllNodeChildInGroupInit");

      //REMOVE NODE
      for (const node of nodeForRemove) {
        let linkObj = linkData.find(
          (link: any) => link.from === node.key || link.to === node.key
        );
        if (node.group === "boGroup")
          BoNode = BoNode.filter((bo) => bo.key !== node.key);
        if (linkObj) linkForRemove.push(linkObj);
        MyDiagramState.model.removeNodeData(node);
      }

      //REMOVE LINK
      for (const link of linkForRemove) {
        MyDiagramState.model.removeLinkData(link);
      }
      MyDiagramState.commitTransaction("removeAllNodeChildInGroupInit");
    }
  }

  removeNodeChildBySelectNodeKey(nodeKey: string) {
    let diagramData = this.getDataFromDiagram();
    let linkData = diagramData.linkDataArray;
    let nodeData = diagramData.nodeDataArray;

    let nodeForRemove = nodeData.filter(
      (node: any) => node.parentKey === nodeKey || node.key === nodeKey
    );
    let linkForRemove = [];
    if (nodeForRemove.length > 0) {
      MyDiagramState.startTransaction("removeNodeChildByNodeSelectNodeKey");

      //REMOVE NODE
      for (const node of nodeForRemove) {
        let linkObj = linkData.find(
          (link: any) => link.from === node.key || link.to === node.key
        );
        if (node.group === "boGroup")
          BoNode = BoNode.filter((bo) => bo.key !== node.key);
        if (linkObj) linkForRemove.push(linkObj);
        MyDiagramState.model.removeNodeData(node);
      }

      //REMOVE LINK
      for (const link of linkForRemove) {
        MyDiagramState.model.removeLinkData(link);
      }
      MyDiagramState.commitTransaction("removeNodeChildByNodeSelectNodeKey");
    }
  }

  addNewNodeInParentSelection(nodeData: any) {
    let maxSeqInParent = this.findSeqNode(nodeData.parentKey);
    let seq = maxSeqInParent.seq + 1;
    MyDiagramState.model.addNodeData({ ...nodeData, seq });
    if (nodeData.group === "boGroup") BoNode.push({ ...nodeData, seq });
  }

  getSeqFromKey(key: string) {
    let diagramData = this.getDataFromDiagram();
    let nodeData = diagramData.nodeDataArray;

    let nodeDataObj = nodeData.find((node: any) => node.key === key);
    if (!nodeDataObj?.seq) return 0;
    return nodeDataObj.seq;
  }
  getIndexFromKey(key: string) {
    let diagramData = this.getDataFromDiagram();
    let nodeData: any[] = diagramData.nodeDataArray;
    let nodeIndex = nodeData.findIndex((node) => node.key === key);
    return nodeIndex;
  }
  getLastIndexOfParentKey(key: string) {
    let diagramData = this.getDataFromDiagram();
    let nodeData: any = diagramData.nodeDataArray;
    let lastIndex = nodeData.findLastIndex(
      (node: any) => node.parentKey === key
    );
    return lastIndex;
  }
  addNewNodeInParentSelectionWithList(parentKey: string, newNodeData: any[], newNodeLinkArray: any[]) {
    let diagramData = this.getStateDiagramJson();
    let nodeDataArray: any[] = diagramData.nodeDataArray;
    let linkDataArray: any[] = diagramData.linkDataArray;
    console.log("parentKey +> ", parentKey);
    let nodeIndex = this.getIndexFromKey(parentKey); //this.findSeqNode(parentKey);
    console.log("nodeIndex +> ", nodeIndex);
    let lastParrentKeyIndex = this.getLastIndexOfParentKey(parentKey);
    console.log("lastParrentKeyIndex +> ", lastParrentKeyIndex);
    let indexToInsert =
      lastParrentKeyIndex === -1 ? nodeIndex : lastParrentKeyIndex;

    if (nodeIndex <= 1) {
      //add last
      nodeDataArray = [...nodeDataArray, ...newNodeData];
    } else {
      //add after index
      nodeDataArray = this.insertAfter(
        nodeDataArray,
        indexToInsert,
        newNodeData
      );
    }
    linkDataArray = [...linkDataArray, ...newNodeLinkArray];
    diagramData = {
      ...diagramData,
      nodeDataArray,
      linkDataArray
    };
    // let layerOfParent = this.getLayerByParentField(parentKey);
    console.log("diagramData +> ", diagramData);
    this.loadDataToDiagram(diagramData);
  }

  autoGenBo(newNodeData: any[], newNodeLinkArray: any[]) {
    let diagramData = this.getStateDiagramJson();
    let nodeDataArray: any[] = diagramData.nodeDataArray;
    let linkDataArray: any[] = diagramData.linkDataArray;
    nodeDataArray = [...nodeDataArray, ...newNodeData];
    linkDataArray = [...linkDataArray, ...newNodeLinkArray];
    diagramData = {
      ...diagramData,
      nodeDataArray,
      linkDataArray
    };
    // let layerOfParent = this.getLayerByParentField(parentKey);
    console.log("diagramData +> ", diagramData);
    this.loadDataToDiagram(diagramData);
  }

  insertAfter(mainData: any, index: number, itemsToInsert: any) {
    if (index < 0 || index > mainData.length) return mainData;
    let leftData = mainData.slice(0, index + 1);
    let rightData = mainData.slice(index + 1);
    return leftData.concat(itemsToInsert, rightData);
  }

  updateNodeInParentSelection(nodeData: any) {
    console.log("updateNodeInParentSelection ==> ", nodeData);
    BoNode = BoNode.map((bo) => {
      if (bo.key === nodeData.key) {
        bo.paramName = nodeData.paramName;
        bo.typeDesc = nodeData.typeDesc;
        bo.isPrimaryKey = nodeData.isPrimaryKey;
        bo.foreignKey = nodeData.foreignKey;
        return bo;
      } else {
        return bo;
      }
    });
    this.updateDataModel(nodeData);
  }

  getNodeTableAndLinkByBo(nodeBo: any[]) {
    console.log("getNodeTableAndLinkByBo checked!!");
    return new Promise((resolve) => {
      let nodeTable: any[] = [];
      let links: any[] = [];
      let nodeBoList = [...nodeBo];
      for (const nodebo of nodeBoList) {
        let tableObj: any = {};
        let linkObj: any = {};

        if (nodebo.typeDesc === "Object") {
          tableObj = {
            ...nodebo,
            pathName: nodebo.paramName + "_",
            paramName: nodebo.paramName,
            isList: false,
            parentKey: "tableKeyInit",
            key: nodebo.key + "_",
            group: "tableGroup",
          };
        } else {
          tableObj = {
            ...nodebo,
            key: nodebo.key + "_",
            group: "tableGroup",
            parentKey: nodebo.parentKey + "_",
            paramName: nodebo.paramName,
          };
          linkObj = {
            from: nodebo.key,
            to: tableObj.key,
            category: "Mapping",
          };
          links.push(linkObj);
        }
        nodeTable.push(tableObj);
      }
      resolve({
        nodeTable,
        links,
      });
    });
  }

  async generateTableByBo() {
    const nodeMapping: any = await this.getNodeTableAndLinkByBo(
      structuredClone(BoNode)
    );
    const nodeBo = structuredClone(BoNode);
    console.log("nodeBo check: ", nodeBo);
    const nodeTable = nodeMapping.nodeTable;
    const nodeLink = nodeMapping.links;
    let newLinkDataArray = nodeMapping.links;
    let newNodeDataArray = [...NodeInit, ...nodeBo, ...nodeTable];

    const newBoMapping = {
      class: "go.GraphLinksModel",
      nodeDataArray: [...newNodeDataArray],
      linkDataArray: [...newLinkDataArray],
    };
    console.log("nodeBo check: ", nodeBo);
    console.log("Before loadDataToDiagram => ", newBoMapping);
    this.loadDataToDiagram(structuredClone(newBoMapping));
  }

  removeChildren(MyDiagramStateJson: any, parentKey: any) {
    const children = MyDiagramStateJson.linkDataArray
      .filter((link: any) => link.from === parentKey)
      .map((link: any) => link.to);
    children.forEach((childKey: any) => {
      MyDiagramStateJson.nodeDataArray =
        MyDiagramStateJson.nodeDataArray.filter(
          (item: any) => item.key !== childKey
        );
      MyDiagramStateJson.linkDataArray =
        MyDiagramStateJson.linkDataArray.filter(
          (link: any) => link.from !== childKey && link.to !== childKey
        );
      this.removeChildren(MyDiagramStateJson, childKey);
    });
  }

  getBoNameFromBoMapping() {
    let nodeData = MyDiagramStateJson.nodeDataArray;
    let nodeBoObj = nodeData.find(
      (nodeBo: any) =>
        nodeBo.typeDesc === "Object" && nodeBo.parentKey === BO_INIT_KEY
    );
    if (nodeBoObj) return nodeBoObj.paramName;
    return "";
  }

  checkKeyInNodeArray(key: string, nodeDataArray: any[]) {
    for (let a = 0; a < nodeDataArray.length; a++) {
      if (nodeDataArray[a].key == key) return true;
    }
    return false
  }

  getBoMappingWithoutTable() {
    let boMapping = this.getStateDiagramJson();
    let oldNodeDataArray = structuredClone(boMapping.nodeDataArray);
    let newNodeDataArray = oldNodeDataArray.filter(
      (node: any) => node.group !== "tableGroup" || node.key === TABLE_INIT_KEY
    );
    let linkDataArray = structuredClone(boMapping.linkDataArray);
    let newLinkDataArray = [];
    for (let a = 0; a < linkDataArray.length; a++) {
      if (this.checkKeyInNodeArray(linkDataArray[a].from, oldNodeDataArray) && this.checkKeyInNodeArray(linkDataArray[a].to, oldNodeDataArray)) {
        newLinkDataArray.push(linkDataArray[a])
      }
    }

    let newBoMapping = {
      ...boMapping,
      nodeDataArray: newNodeDataArray,
      linkDataArray: newLinkDataArray,
    };
    return newBoMapping;
  }
  updateBoMappingOnRemoveTable() {
    let boMappingWithoutTable = this.getBoMappingWithoutTable();
    this.loadDataToDiagram(boMappingWithoutTable);
  }

  getColumnTypeByBoParamType(paramType: string) {
    let pt = paramType.toLocaleLowerCase();
    if (pt === "string") return "varchar";
    if (pt === "boolean") return "boolean";
    if (pt === "date") return "date";
    if (pt === "datetime") return "datetime";
    if (pt === "file") return "file";
    if (pt === "text") return "text";
    if (pt === "number") return "bigint";
    if (pt === "object") return "object";
    return "varchar";
  }

  getTableColumnByBo(): TableType[] {
    let boMapping = this.getStateDiagramJson();
    let nodeDataArray = [...boMapping.nodeDataArray];
    let boNodes = nodeDataArray.filter(
      (node) => node.group === "boGroup" && node.typeDesc !== "BoInit"
    );

    //GET GROUP
    let boGroups = boNodes.filter((item) => item.typeDesc === "Object");

    //CREATE REQUEST BY GROUP
    let tableColArray: TableType[] = [];
    for (const boGroup of boGroups) {
      let tableColObj: any = {
        table_name: "",
        name_column: [],
      };
      tableColObj.table_name = boGroup.paramName;

      let itemGroups = boNodes.filter(
        (item) => item.parentKey === boGroup.key && item.typeDesc !== "Object"
      );
      for (const itemGroup of itemGroups) {
        console.log("itemGroup.typeDesc : ", itemGroup.typeDesc)
        let colObj = {
          column_name: itemGroup.paramName,
          column_type: this.getColumnTypeByBoParamType(itemGroup.typeDesc),
        };
        tableColObj.name_column.push(colObj);
      }
      tableColArray.push(tableColObj);
    }
    return tableColArray;
  }

  setTableByTableColumn(tableCols: TableType[]) {
    //make to list
    let resultTableNodeData: any = [];
    let resultTableLinkData: any = [];
    for (const tableCol of tableCols) {
      let tableName = tableCol.table_name;
      let nameCols = tableCol.name_column;
      let tableNodeData = [
        {
          paramName: tableName,
          pathName: tableName,
          margin: "0 0 0 30",
          typeDesc: "Table",
          isList: false,
          parentKey: "tableKeyInit",
          category: "nodeIsGroup",
          isGroup: false,
          key: tableName + "_tabl",
          group: "tableGroup",
        },
      ];
      resultTableLinkData.push({ from: TABLE_INIT_KEY, to: tableName + "_tabl" })
      for (const nameCol of nameCols) {
        let col_name = nameCol.column_name;
        let col_type = nameCol.column_type;

        if (col_type != "object") {
          let tableObj = {
            paramName: col_name,
            pathName: tableName + "." + col_name,
            margin: "0 0 0 50",
            typeDesc: col_type,
            isList: false,
            parentKey: tableName + "_tabl",
            category: "nodeIsNotGroup",
            isGroup: false,
            key: tableName+"_"+col_name + "_col",
            group: "tableGroup",
          };
          tableNodeData = [...tableNodeData, tableObj];

          resultTableLinkData.push({ from: tableName + "_tabl", to: tableName+"_"+col_name + "_col" })
        }
      }
      resultTableNodeData = [...resultTableNodeData, ...tableNodeData];
    }
    console.log("resultTableNodeData => ", resultTableNodeData);

    let boMappingWithoutTable = this.getBoMappingWithoutTable();
    boMappingWithoutTable = {
      ...boMappingWithoutTable,
      nodeDataArray: [
        ...boMappingWithoutTable.nodeDataArray,
        ...resultTableNodeData,
      ],
      linkDataArray: [
        ...boMappingWithoutTable.linkDataArray,
        ...resultTableLinkData,
      ]
    };
    console.log("boMappingWithoutTable => ", boMappingWithoutTable);
    this.loadDataToDiagram(boMappingWithoutTable);
  }

  getAllLayerInNodeDataArray() {
    let result: any = [];
    let count = 0;

    let boMapping = this.getStateDiagramJson();
    let nodeDataArray = [...boMapping.nodeDataArray];
    let boMappingList = nodeDataArray.filter(
      (item: any) => item.group === "boGroup"
    );
    let getKeyAndPar = boMappingList.map((item) => {
      return {
        key: item.key,
        parentKey: item.parentKey,
        paramName: item.paramName,
      };
    });

    const getLayerFromBoMapping = (getKeyAndPar: any[]) => {
      if (getKeyAndPar.length === 0) {
        return;
      }

      //GET MAIN OB BO
      if (count === 0) {
        let mainBo = BO_INIT_KEY;
        getKeyAndPar = getKeyAndPar.filter((item) => item.key !== BO_INIT_KEY);
        result = [...result, [mainBo]];

        //GET CHILD OF PARENT FROM PREVIOUS LOOP
      } else {
        let inSideRes: any[] = [];
        let parentOfBefore = result[count - 1];
        for (let pb of parentOfBefore) {
          let childOfParent = getKeyAndPar.filter(
            (item) => item.parentKey === pb
          );
          let keyChild = childOfParent.map((item) => item.key);
          if (keyChild.length > 0) inSideRes.push(...keyChild);
        }

        getKeyAndPar = getKeyAndPar.filter(
          (item) => !inSideRes.includes(item.key)
        );
        result = [...result, inSideRes];
      }
      count++;
      getLayerFromBoMapping(getKeyAndPar);
    };
    getLayerFromBoMapping(getKeyAndPar);

    return result;
  }

  getNameFromKeyByLayer(allLayer: any) {
    let boMapping = this.getStateDiagramJson();
    let nodeDataArray = [...boMapping.nodeDataArray];
    let result = [];
    for (const layer of allLayer) {
      let layerResult = [];
      for (const la of layer) {
        let nodeObj = nodeDataArray.find((node) => node.key === la);
        layerResult.push(nodeObj.paramName);
      }
      result.push(layerResult);
    }
    return result;
  }

  getRequestTableColListFromNodeDataArray(): TableType[] {
    let boMapping = this.getStateDiagramJson();
    let nodeDataArray = [...boMapping.nodeDataArray];
    let boMappingList = nodeDataArray.filter(
      (item: any) => item.group === "boGroup"
    );

    //GET ALL NODE_DATA_ARRAY
    let getKeyAndPar = boMappingList.map((item) => {
      return {
        key: item.key,
        parentKey: item.parentKey,
        paramName: item.paramName,
        dataType: item.typeDesc.toLowerCase(),
        isPrimaryKey: item.isPrimaryKey,
      };
    });

    //FILTER ON LY OBJECT
    let boObjectGroups = getKeyAndPar.filter(
      (item) => item.dataType === "object"
    );

    //CREATE REQUEST TABLE COLUMN FOR GENERATE_TABLE
    let reqTableColList = [];
    for (const boObj of boObjectGroups) {
      let parentChild: any = {
        table_name: boObj.paramName,
        name_column: [],
      };
      let childOfObjs = nodeDataArray.filter(
        (node) => node.parentKey === boObj.key
      );

      if (childOfObjs.length === 0) continue;
      console.log("childOfObjs : ", childOfObjs)
      let colObjs = childOfObjs.map((node) => {
        return {
          column_name: node.paramName,
          // column_type: this.getColumnTypeByBoParamType(node.typeDesc),
          column_type: node.typeDesc,
          primary_key: node.isPrimaryKey,
        };
      });
      parentChild.name_column = colObjs;
      reqTableColList.push(parentChild);
    }
    return reqTableColList;
  }

  getLayerByParentField(parentField: string): number {
    let allLayer = this.getAllLayerInNodeDataArray();
    for (const i in allLayer) {
      if (allLayer[i].includes(parentField)) return parseInt(i);
    }
    return -1;
  }

  getParamByKey(key: string) {
    let boMapping = this.getStateDiagramJson();
    // console.log("boMapping => ", boMapping);
    // console.log("key => ", key);
    let nodeDataArray = [...boMapping.nodeDataArray];
    let nodeObj = nodeDataArray.find((node: any) => node.key === key);
    return nodeObj;
  }

  getLinkByFromToKey(from: string, to: string) {
    let boMapping = this.getStateDiagramJson();
    let linkDataArray = [...boMapping.linkDataArray];
    let linkObj = linkDataArray.find((link: any) => link.from === from && link.to === to);
    return linkObj;
  }

  getForeignKeySelectsByParentKey(
    parentKey: string
  ): { label: string; value: string }[] {
    let boMapping = this.getStateDiagramJson();
    let nodeDataArray = [...boMapping.nodeDataArray];
    let boMappingList = nodeDataArray.filter(
      (item: any) => item.group === "boGroup"
    );
    // console.log("getForeignKeySelectsByParentkey boMappingList", boMappingList);

    let parentBo = boMappingList.find((item) => item?.key === parentKey);
    console.log("getForeignKeySelectsByParentkey parentBo", parentBo);
    if (parentBo?.key === BO_INIT_KEY) return [];

    let pkInParentBoGroups = boMappingList.filter(
      (item) =>
        item?.parentKey === parentBo?.parentKey &&
        item?.typeDesc !== "Object" 
    );
    console.log(
      "getForeignKeySelectsByParentkey pkInParentBoGroup",
      pkInParentBoGroups
    );
    if (pkInParentBoGroups.length == 0) return [];
    // let perentName = this.getParamNameByKey(parentBo.parentKey);

    //CREATE SELECT ITEMS
    let fkSelects = [];
    for (let a = 0; a < pkInParentBoGroups.length; a++) {
      let fkPath = pkInParentBoGroups[a].pathName;
      fkSelects.push({ value: fkPath, label: fkPath });
    }
    console.log("getForeignKeySelectsByParentkey fkSelects", fkSelects);

    return fkSelects;
  }

  getParamNameByKey(key: string) {
    let boMapping = this.getStateDiagramJson();
    // console.log("boMapping => ", boMapping);
    let nodeDataArray = [...boMapping.nodeDataArray];
    let nodeObj = nodeDataArray.find((node: any) => node.key === key);
    return nodeObj ? nodeObj.paramName : "";
  }

  addTargetNodesAndLinks() {
    const targetNode = [
      {
        loc: "550, 0",
        text: "Target",
        isGroup: true,
        key: "targetMainGroup",
      },
      {
        paramName: "Target",
        category: "targetInitGroup",
        isGroup: false,
        key: "targetKeyInit",
        group: "targetMainGroup",
      },
      {
        pathName: "User2",
        margin: "0 0 0 30",
        typeDesc: "Object",
        paramName: "User2",
        isList: false,
        parentKey: "targetKeyInit",
        category: "nodeIsGroup",
        isGroup: false,
        key: "User2",
        seq: 1,
        group: "targetMainGroup",
      },
      {
        pathName: "secret_level",
        margin: "0 0 0 50",
        typeDesc: "String",
        paramName: "secret_level",
        isList: false,
        parentKey: "User2",
        category: "nodeIsNotGroup",
        isGroup: false,
        key: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da9",
        seq: 1,
        group: "targetMainGroup",
      },
    ];

    const linkTarget = [
      {
        from: "targetKeyInit",
        to: "User2",
      },
      {
        from: "User2",
        to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da9",
      },
      {
        from: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da5",
        to: "B76279018-dfc79591-a1bc-4448-b296-da6a52f02da9",
        category: "Mapping",
      },
    ];

    MyDiagramStateJson.nodeDataArray.push(...targetNode);
    MyDiagramStateJson.linkDataArray.push(...linkTarget);

    this.loadDataToDiagram(MyDiagramStateJson);
    return MyDiagramStateJson;
  }
}
