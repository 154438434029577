import React, { useState } from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./StyleTool.module.scss";
import StoryGenPageText from "src/assets/svg/story-genpage-tool-text";
import { Select } from "antd";
import { HEAD, NORMAL } from "src/constants/PageConfig";

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onRemove: () => void;
  onEdit: () => void;
  onSelectLabelType: (type: string) => void;
  labelType?: string;
};

function TextTool({
  isShow,
  top,
  left,
  onRemove,
  onEdit,
  onSelectLabelType,
  labelType,
}: Props) {
  const labelTypes = [
    { label: "Normal", value: NORMAL },
    { label: "Head", value: HEAD },
  ];

  let [labelTypeState, setLabelTypeState] = useState<string>(
    labelType || NORMAL
  );

  const onClickRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemove();
  };

  const onClickEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onEdit();
  };

  const onChangeLabelType = (type: string) => {
    console.log("onChangeLabelType type => ", type);
    setLabelTypeState(type);
    onSelectLabelType(type);
  };

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: top + "px",
            left: left + "px",
          }}
          className={styles.cellToolContainer}
        >
          <div className={styles.actionWrapperBtn}>
            <Select
              variant={"borderless"}
              value={labelTypeState}
              style={{ width: 120 }}
              onChange={onChangeLabelType}
              options={labelTypes}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className={styles.lineDivide} />
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickEdit(e)}
          >
            <StoryGenPageText width={"16px"} height={"16px"} />
          </div>
          <div className={styles.lineDivide} />
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickRemove(e)}
          >
            <StoryEditEntDelete width={"16px"} height={"16px"} />
          </div>
        </div>
      )}
    </>
  );
}

export default TextTool;
