import React from "react";

const StoryGenPageAlignLeft = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.5C2 2.94771 2.44772 2.5 3 2.5C3.55228 2.5 4 2.94772 4 3.5V20.5C4 21.0523 3.55228 21.5 3 21.5C2.44772 21.5 2 21.0523 2 20.5V3.5Z"
        fill="#393939"
      />
      <path
        d="M6.5 17.5C5.67157 17.5 5 16.8284 5 16C5 15.1716 5.67157 14.5 6.5 14.5H13.5C14.3284 14.5 15 15.1716 15 16C15 16.8284 14.3284 17.5 13.5 17.5H6.5Z"
        fill="#393939"
      />
      <path
        d="M6.5 9.5C5.67157 9.5 5 8.82843 5 8C5 7.17157 5.67157 6.5 6.5 6.5H20.5C21.3284 6.5 22 7.17157 22 8C22 8.82843 21.3284 9.5 20.5 9.5H6.5Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageAlignLeft;
