import React, { useEffect, useRef, useState } from "react";
import styles from "../CreateEditPage.module.scss";
import { Button, Input, InputRef, Modal } from "antd";
import MessageNofify from "src/components/zero/zero-story/notification/MessageNotify";

type Props = {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  onSaveNewBcName: (bcName: string) => void;
  bcNameList: string[];
};

export default function AddBreadcrumpModal({
  isModalOpen,
  onOk,
  onCancel,
  onSaveNewBcName,
  bcNameList,
}: Props) {
  //PAGE STATE
  let [bcName, setBcName] = useState<string>("");
  let [isDisableSave, setIsDisableSave] = useState<boolean>(true);
  //   const inputRef = useRef<InputRef>(null);

  //MESSAGE NOTIFY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  //FIX INTEGRATION ISSUE
  useEffect(() => {
    if (isModalOpen) {
      clearInput();
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  //INPUT HANDLER
  const onChangeBcNameHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    bcName: string
  ) => {
    bcName ? setIsDisableSave(false) : setIsDisableSave(true);
    setBcName(bcName);
  };

  //ACTION HANDLER
  const actionClickSave = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    // console.log("getTabName => ", getTabName());
    let isPassValidate = isPassValidateOnSave(bcName);
    if (isPassValidate) {
      onSaveNewBcName(bcName);
      afterSaveNewTabSuccess();
    } else {
      showMessageNotifyError();
    }
  };

  const actionClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCancel();
  };

  //UTILITY FUNCTION
  const afterSaveNewTabSuccess = () => {
    clearInput();
    showMessageNotifySuccess();
  };
  const clearInput = () => {
    setBcName("");
    setIsDisableSave(true);
  };

  //MESSAGE NOTIFY
  const showMessageNotifySuccess = () => {
    setTriggerNotify(!triggerNotify);
    setIsSuccessNotify(true);
    setTitleNotify("Save new breadcrump success");
    setDescriptionNotify("New breadcrump has been created");
  };

  const showMessageNotifyError = () => {
    setTriggerNotify(!triggerNotify);
    setIsSuccessNotify(false);
    setTitleNotify("Save new breadcrump error");
    setDescriptionNotify("Please change breadcrump name");
  };

  const isPassValidateOnSave = (newBcName: string): boolean => {
    let isPass: boolean = true;

    let isDupItemInList = hasBreadcrumpNameInBreadcrumpNameList(newBcName); //CHECK DUPLICATE NAME IN LIST
    if (isDupItemInList) isPass = false;
    if (!newBcName) isPass = false;

    return isPass;
  };

  const hasBreadcrumpNameInBreadcrumpNameList = (
    newBcName: string
  ): boolean => {
    let hasItem: boolean = false;

    if (bcNameList.includes(newBcName)) {
      hasItem = true;
    }

    return hasItem;
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.modalTitle}>
            {/* <StoryEditDbRedBin /> */}
            {/* {modalDetail.title} */}
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={(e) => actionClickCancel(e)}
            >
              Cancel
            </Button>
            <Button
              className={
                isDisableSave ? styles.confirmBtnDisabled : styles.confirmBtn
              }
              key="save"
              onClick={(e) => actionClickSave(e)}
              type="primary"
              disabled={isDisableSave}
            >
              Save
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={(e) => actionClickSave(e)}
        onCancel={(e) => actionClickCancel(e)}
        centered={true}
        width={400}
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.modalTitle}>Add Breadcrump</div>
          <div className={styles.modalContent}>
            <div className={styles.inputFieldContainer}>
              <div className={styles.textTopic}>
                <div className={styles.text}>Step name</div>
                <div className={styles.textImportant}>*</div>
              </div>
              <div className={styles.inputTabName}>
                <Input
                  placeholder={"Enter step name"}
                  //   suffix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
                  value={bcName}
                  className={styles.searchInput}
                  onChange={(e) =>
                    onChangeBcNameHandler(e, e.currentTarget.value)
                  }
                  onClick={(e) => e.stopPropagation()}
                  //   ref={inputRef}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );
}
