import React, { useContext, useEffect, useState } from "react";
import styles from "./EditStoryEntityGenPage.module.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { getStoryEditIDPathFromGenPagePath } from "src/utils/routes-util/RoutesUtility";
import { Button, Steps } from "antd";
import ChooseTemplateStep1 from "src/components/zero/zero-story/story-entity-genpage/step1-choose-template/ChooseTemplateStep1";
import SearchFilterConfigStep2 from "src/components/zero/zero-story/story-entity-genpage/step2-search-filter-config/SearchFilterConfigStep2";
import SearchResultConfigStep3 from "src/components/zero/zero-story/story-entity-genpage/step3-search-result-config/SearchResultConfigStep3";
import CreateEditPageConfigStep4 from "src/components/zero/zero-story/story-entity-genpage/step4-create-edit-page-config/CreateEditPageConfigStep4";
import { useSearchParams } from "react-router-dom";
import SaveVersionModal from "src/components/zero/zero-story/modal/modal-entity-genpage/SaveVersionModal";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import GenerateMFLoading from "src/components/zero/zero-story/loading/GenerateMFLoading";
import {
  GenerateMFRequest,
  GeneratePageActionRequest,
  GeneratePageOnewebRequest,
  GeneratePageRequest,
  GetTableAndColumnRequest,
} from "src/types/request.type";
import useFetch from "src/hooks/useFetch";
import {
  GenPageActionResponse,
  GenSchemaReq,
  GenerateMFResponse,
  GeneratePageOnewebResponse,
  GeneratePageResponse,
  GetAllDBConnectByStoryIDResponse,
  GetEntityBoItem,
  GetEntityBoItemByStoryIDResponse,
  GetPageNodesResponse,
  GetTableAndColumnResponse,
  StoryEnyityBOResponse,
  TableType,
  GenerateVersionResponse,
  GenerateVersion
} from "src/types/response.type";
import { STATUS_OK } from "src/constants/ResponseStatus";
import {
  CALL_GENERATE_PAGE_ENDPOINT,
  CREATE_PAGE_GENERATE_ENDPOINT,
  CREATE_PAGE_OUTPUT_SCHEMA,
  CREATE_PAGE_STATE,
  GENERATE_MF_ENDPOINT,
  GEN_PAGE_ACTION_ENDPOINT,
  GET_BO_DATA_REFER_BY_STORY_ID_ENDPOINT,
  GET_BO_MFD,
  GET_DB_CONNECT_ENDPOINT,
  GET_ENTITY_BO_BY_ID_ENDPOINT,
  GET_PAGE,
  GET_PAGE_NODES_BY_PAGEID_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
  UPLOAD_FILE_LIBS_ENDPOINT,
  GET_PAGE_GENERATE_BY_ID_ENDPOINT
} from "src/utils/endpoint/createbo.endpoint";
import {
  callApiMethodMFDGet,
  callApiMethodPageGet,
  callApiMethodPagePost,
  callApiMethodZeroPost,
  checkEmpty,
  getAspId,
} from "src/services/util.service";
import { getGenerateMFRequest } from "src/utils/story-genmf/StoryGenerateMFUtility";
import {
  getBoFieldByGenerateBoConfig,
  getGeneratePageConfigScreenRequest,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import { generatePageFile } from "src/services/page-service";
import { UPLOAD_LIBS } from "src/utils/story-genpage/config/libs.config";
import { v4 as uuidv4 } from "uuid";
import {
  createMappinMFDOnNode,
  genHtmlWithEvent,
  getPageNodeIDByClassname,
  getPageNodeIDByHtmlID,
  prepareDataGenPage,
  prepareGenSchemaReq,
  prepareGenStateReq,
} from "src/services/gen.page.service";

type Props = {};

type ComponentConfig = {
  boIdRef: string;
  colId: string;
};

type ControlComponent = {
  configType?: string;
  rowId: string;
  componentConfig: ComponentConfig[];
  colId: string;
  detail: any;
  parentSelected: string;
};

type ScreenItem = {
  rowId: string;
  cols?: Array<{ childIdRef: string; colId: string; childRef: string;  controlComponent: void; isControlItem: boolean}>;
  isControlItem: boolean;
};

type ComponentReq = {
  enyity_bo_item_id: string;
  field_key: string;
  field_type: "TAB" | "BREADCRUMP" | "BUTTON" | "ICON" | "IMAGE" | "BO_ITEM" | "TABLE";
  row_number: number;
  column_number: number;
  childs: ComponentReq[];
  json_config_subcomponent: string;
};


function EditStoryEntityGenPage({ }: Props) {
  const GET_DB_CONNECT_FK = "getDbConnectFK";
  const PREPARE_GEN_MF_FK = "prepareGenMfFK";
  const GEN_MF_FK = "genMfFK";
  const GEN_PAGE_FK = "genPageFK";
  const GEN_PAGE_UP_LIBS_FK = "genPageUpLibsFK";
  const GET_PAGE_NODES_FK = "getPageNodesFK";
  const GEN_PAGE_ACTION_FK = "genPageActionFK";
  const GET_GEN_PAGE_VERSION_FK = "getGenerateVersionFK";

  const steps = [
    {
      title: "Choose Template",
      content: <ChooseTemplateStep1 />,
    },
    {
      title: "Search Filter Config",
      content: <SearchFilterConfigStep2 />,
    },
    {
      title: "Search Result Config",
      content: <SearchResultConfigStep3 />,
    },
    {
      title: "Create and Edit Page Config",
      content: <CreateEditPageConfigStep4 />,
    },
  ];
  let genPageContext = useContext(StoryGeneratePageContext);
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const { loading, error, responseData, fetchData, fetchKey, fetchDataAsync } =
    useFetch<
      | StoryEnyityBOResponse
      | GetAllDBConnectByStoryIDResponse
      | GetTableAndColumnResponse
      | GenerateMFResponse
      | GeneratePageResponse
      | GetEntityBoItemByStoryIDResponse
      | GeneratePageOnewebResponse
      | GetPageNodesResponse
      | GenPageActionResponse
      | GenerateVersionResponse
    >();
  let [searchParams, setSearchParams] = useSearchParams();
  let [storyId, setStoryId] = useState<string | null>();
  const [currentStep, setCurrentStep] = useState(0);
  let [isOpenSaveVersionModal, setIsOpenSaveVersionModal] =
    useState<boolean>(false);

  //GENERATE MF STATE
  let [isLoadingGenMF, setIsLoadingGenMF] = useState<boolean>(false);
  let [dbConnect, setDbConnect] = useState<GetTableAndColumnRequest>();
  let [versionName, setVersionName] = useState<string>("");
  let [pageGenerateId, setPageGenerateId] = useState<string>("");

  //GENERATE PAGE STATE
  let [isGenPageSuccess, setIsGenPageSuccess] = useState<boolean>(false);
  let [boConfigItems, setBoConfigItems] = useState<GetEntityBoItem[]>([]);
  let [callGenerateMFReq, setCallGenerateMFReq] = useState<GenerateMFRequest>();
  let [boMapping, setBoMapping] = useState<any>();
  let [functionNameToGen, setFunctionNameToGen] = useState<string>("");
  let [pageIdToGen, setPageIdToGen] = useState<string>("");
  const [nodeContentId, setnodeContentId] = useState<string>("");
  const [dateTimeGenMain, setDateTimeGenMain] = useState<string>("");
  const [toGenMapping, setToGenMapping] = useState<boolean>(false);
  const [jsonGenHtml, setJsonGenHtml] = useState<any>();
  const [pageGen, setPageGen] = useState<any>({});

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  //ONLOAD PAGE
  useEffect(() => {
    let sId = params.id;
    console.log("This storyId on page : ", sId);
    console.log("searchParams : ", searchParams.get("bo_id"));
    setStoryId(sId);

    //CHECK VERSION ID FOR LOAD DATA
    console.log("versionId : ", getVersionId());
    if(getVersionId()){
      getGenenrateVersionByID()
    }
  }, []);


  //CHECK ON CHANGE STEP IN USECONTEXT
  useEffect(() => {
    let step = genPageContext.currentStep;
    console.log("Main GenPage => ", step);

    setCurrentStep(step);
  }, [genPageContext.currentStep]);

  //** CALL API STEP CHECK **/
  //ACTION => CLICK BUTTON {Save Version}
  // 1. generatePageController

  // ACTION => CLICK BUTTON {Generate UI & Flow}
  // 1. getDBConnectController
  // 2. prepareDataForGenMFController
  // 3. generateMFController
  // 4. callGeneratePageOnewebAndUploadLibsController
  // 5. getPageNodesController
  // 6. callActionGenPageController

  //--CHECK RESPONSE BY STATE USEFETCH FROM CALL API SUCCESS
  useEffect(() => {
    if (!loading) {
      if (error || !responseData) {
        setIsLoadingGenMF(false);
        return;
      }

      let boId = getBoId() as string;

      switch (fetchKey) {
        case GET_DB_CONNECT_FK:
          let respDBConnect: any = responseData;
          // console.log("respDBConnect => ", respDBConnect);

          if (respDBConnect.status !== STATUS_OK) return;
          let getTableColReq =
            respDBConnect?.data as unknown as GetTableAndColumnRequest[];
          if (getTableColReq.length === 0) return;
          setDbConnect(getTableColReq[0]);

          getTableColReq = getTableColReq.map((item) => {
            return {
              ...item,
              is_active: Boolean(item.is_active),
            };
          });

          prepareDataForGenMFController(boId as string, getTableColReq[0]);
          break;

        // case GET_PAGE_GENERATE_BY_ID_ENDPOINT:   
        case GET_GEN_PAGE_VERSION_FK:   
          let resPonseGetGenpageVersion: any = responseData;

          if(resPonseGetGenpageVersion.status !== STATUS_OK) return;

          genPageContext.setIsVersionGenerated(true)
          let dataGenerate = resPonseGetGenpageVersion?.data as GenerateVersion
          console.log("dataGenerate => ", dataGenerate)
          onSetChooseTemplate(dataGenerate)
          onSetBoConfig(dataGenerate.generate_bo)
          onSetCreateEditPageConfig(dataGenerate.generate_screen)
          onSetComponentItemsConfigs(dataGenerate.generate_screen)
          console.log("genPageContext => ", genPageContext)
          break;

        case PREPARE_GEN_MF_FK:
          let respPrepareForGenMF: any = responseData;
          console.log("respPrepareForGenMF => ", respPrepareForGenMF);
          let isError = respPrepareForGenMF.some(
            (resp: any) => resp.status !== STATUS_OK
          );
          if (isError) return;

          let jsonBoMapping = respPrepareForGenMF[0]?.data;
          let tableColResp: TableType[] = respPrepareForGenMF[1]?.data.table;
          let boConfigItemsResp: GetEntityBoItem[] =
            respPrepareForGenMF[2]?.data;
          console.log("boConfigItemsResp => ", boConfigItemsResp);
          setBoConfigItems(boConfigItemsResp);

          let aspId = getAspId() as string;
          let storyId = getStoryId() as string;
          let searchConfigData = genPageContext.boFieldForData;
          let searchConfig = genPageContext.generateBoConfig;

          let callGenMFReq = getGenerateMFRequest(
            jsonBoMapping,
            tableColResp,
            boId,
            aspId,
            storyId,
            dbConnect as GetTableAndColumnRequest,
            versionName,
            searchConfigData,
            searchConfig
          );
          console.log("callGenMFReq => ", callGenMFReq);
          setCallGenerateMFReq(callGenMFReq);
          setBoMapping(JSON.parse(jsonBoMapping?.json_mapping_bo));

          //CALL GENEREATE PAGE TO ONEWEB FOR TEST
          // actionGenerateScreenConfig(boConfigItemsResp);
          //CALL GENEREATE PAGE TO ONEWEB FOR TEST

          //FIX FOR CHECK UPLOAD FILE REQUEST****
          generateMFController(callGenMFReq);
          // endProgressLoadingHandler();
          break;

        case GEN_MF_FK:
          let respGenMF: any = responseData;
          console.log("respGenMF ==> ", respGenMF);
          let genMfFlow = respGenMF?.data;

          //CALL GENEREATE PAGE TO ONEWEB
          actionGenerateScreenConfig(boConfigItems, genMfFlow);

          break;

        case GEN_PAGE_FK:
          let respGenPage = responseData as unknown as GeneratePageResponse;
          let genPageData = respGenPage.data;
          console.log("genPageData => ", genPageData);
          setPageGenerateId(genPageData?.page_generate_id);
          actionAfterSaveGenPageVersionSuccess();

          break;

        case GEN_PAGE_UP_LIBS_FK:
          let respGenPageUpLibs: any = responseData;
          console.log("respGenPageUpLibs => ", respGenPageUpLibs);
          let genPageResp: GeneratePageOnewebResponse = respGenPageUpLibs[0];
          let pageId = genPageResp?.data.content?.componentId;
          console.log("respGenPageUpLibs pageId => ", pageId);
          setPageIdToGen(pageId);
          getPageNodesController(pageId);

          break;

        case GET_PAGE_NODES_FK:
          let respGetPageNodes: GetPageNodesResponse =
            responseData as GetPageNodesResponse;
          console.log("respGetPageNodes => ", respGetPageNodes);
          let nodeId = respGetPageNodes?.data?.nodeId;
          setnodeContentId(nodeId);
          let genPageActionReq = getActionGeneratePageRequest(nodeId);
          console.log("genPageActionReq => ", genPageActionReq);

          callActionGenPageController(genPageActionReq);

          break;

        case GEN_PAGE_ACTION_FK:
          let respGenPageAction: GenPageActionResponse =
            responseData as GenPageActionResponse;
          console.log("respGenPageAction => ", respGenPageAction);

          setToGenMapping(true);
          break;

        default:
          break;
      }
    }
  }, [loading, responseData]);

  //ACTION BUTTON
  const onCancelGenPageHandler = () => {
    console.log("onClickSaveVersionGenPageHandler");
    let path = location.pathname;
    let backPath = getStoryEditIDPathFromGenPagePath(path);
    navigate(backPath);
  };
  const onClickBackGenPageHandler = () => {
    let currentStep = genPageContext.currentStep;
    let prevStep = currentStep - 1;
    if (currentStep === 0) return;
    genPageContext.setCurrentStep(prevStep);
    if(currentStep === 3) {
      genPageContext.setIsIconItemSeted(false);
      genPageContext.setIsImageItemSeted(false);
      genPageContext.setIsTextItemSeted(false)
    }
  };
  const onClickNextGenPageHandler = () => {
    saveDataOnClickNext();
  };
  const onClickSaveVersionGenPageHandler = () => {
    console.log("onClickSaveVersionGenPageHandler");
    setIsOpenSaveVersionModal(true);
  };
  const onClickPreviewGenPageHandler = () => {
    // let allGenPageData = storyGenPageHandler.getAllStoryGenPage();
    // console.log(
    //   "onClickPreviewGenPageHandler allGenPageData :: ",
    //   allGenPageData
    // );
  };
  const onClickGenUIAndFlowHandler = () => {
    actionGenerateMicroflow();
  };

  //SET CONTEXT WHEN GET VERSION SAVED
  const onSetChooseTemplate = (data:any) => {
    if(data){
      let ListTemplateId = data?.list_template_id
      let CreateTemplateId = data?.create_template_id
      let chooseTemplate = {
        list_template_id: ListTemplateId,
        create_template_id: CreateTemplateId,
      };
      genPageContext.setChooseTemplate(chooseTemplate)
    }
  }

  //SET BO CONFIG STEP 1,2
  const onSetBoConfig = (data:any) => {
    if(data){
      let searchFilterConfig = data.filter((item: any) => item.bo_config_type === "SEARCH_FILTER")
      let newsearchFilterConfigConfig = searchFilterConfig.map((item:any) => {
        return {
          enyity_bo_item_id: item.enyity_bo_item_id,
          bo_config_type: "SEARCH_FILTER",
          default_search: item.default_search,
        };
      })

      let searchResultConfig = data.filter((item: any) => item.bo_config_type === "SEARCH_RESULT")
      let newSearchResultConfig = searchResultConfig.map((item:any) => {
        return {
          enyity_bo_item_id: item.enyity_bo_item_id,
          bo_config_type: "SEARCH_RESULT",
          sort_by: item.sort_by,
        };
      })
      genPageContext.setGenerateBoConfig([...newsearchFilterConfigConfig, ...newSearchResultConfig])
    } 
    
  }

  //SET PANELS STEP 4
  const onSetCreateEditPageConfig = (screen:any) => {
    console.log('generate screen ==> ', screen)
    let mainScreen = screen.filter((item: any) => item.field_key === 'field_key')
    let minRow = 9
    let minColumn = 2
    let { maxRowNumber, maxColumnNumber } = mainScreen.reduce((acc: any, item: any) => {
      return {
          maxRowNumber: Math.max(acc.maxRowNumber, item.row_number),
          maxColumnNumber: Math.max(acc.maxColumnNumber, item.column_number)
      };
    }, { maxRowNumber: 0, maxColumnNumber: 0 });
  
    maxRowNumber = maxRowNumber < minRow ? minRow : maxRowNumber
    maxColumnNumber = maxColumnNumber < minColumn ? minColumn : maxColumnNumber
    console.log('Max Row Number:', maxRowNumber);
    console.log('Max Column Number:', maxColumnNumber);
    let ScreenPanels = getDefualtPanelScreen(maxRowNumber, maxColumnNumber)
    console.log("ScreenPanels => ", ScreenPanels)

    screen.forEach((item: any) => {
      let rowIndex = item.row_number - 1;
      let colIndex = item.column_number - 1;

      if (
          rowIndex < ScreenPanels.length &&
          colIndex < ScreenPanels[rowIndex].cols.length
      ) {
          if(item.field_type !== 'BO_ITEM'){
            // controlComponent
            if(item.field_type === 'BUTTON' || item.field_type === 'ICON' 
            || item.field_type === 'IMAGE' || item.field_type === 'LABLE'){  
            
              ScreenPanels[rowIndex].cols[colIndex]["controlComponent"] = getComponentId(item.field_type)
              ScreenPanels[rowIndex].isControlItem = false;
              ScreenPanels[rowIndex].cols[colIndex].isControlItem = true;

            }else{
              if(ScreenPanels[rowIndex].cols.length === maxColumnNumber){
                ScreenPanels[rowIndex].cols[0]["controlComponent"] = getComponentId(item.field_type)
                ScreenPanels[rowIndex].cols = ScreenPanels[rowIndex].cols.slice(0, ScreenPanels[rowIndex].cols.length-1)
                // console.log("ScreenPanels[rowIndex].cols ", ScreenPanels[rowIndex].cols)
                ScreenPanels[rowIndex].isControlItem = true;
                ScreenPanels[rowIndex].cols[0].childIdRef = item.enyity_bo_item_id;
                ScreenPanels[rowIndex].cols[0].isControlItem = true;
              }
            }
          }else{
            ScreenPanels[rowIndex].isControlItem = false;
            ScreenPanels[rowIndex].cols[colIndex].childIdRef = item.enyity_bo_item_id;
            ScreenPanels[rowIndex].cols[colIndex].isControlItem = false;
          }
        }
    });

    console.log("ScreenPanels mapping => ", ScreenPanels);
    genPageContext.setGenerateVersionStorage(ScreenPanels)
  }

  //SET CONTEXT CONTROL COMPONENT
  const onSetComponentItemsConfigs = (data:any) => {
    let Component = data.filter((item:any) => item.field_type !== 'BO_ITEM')

    let newComponent = Component.map((item:any) => {
      if(item.field_type === 'BUTTON' || item.field_type === 'ICON' || 
        item.field_type === 'IMAGE' || item.field_type === 'LABLE'){  
          return {
            colId: `main_cell-r${item.row_number}-c${item.column_number}`,
            detail: JSON.parse(item.json_config_subcomponent).json
          }
      }
      return {
        rowId: `r${item.row_number}`,
        configType: item.field_type,
        componentConfig: JSON.parse(item.json_config_subcomponent).json,
        parentSelected: JSON.parse(item.json_config_subcomponent).parentSelected ? JSON.parse(item.json_config_subcomponent).parentSelected : ''
      }
    })
    // newComponent = updateControlComponent(newComponent);
    console.log("newComponent => ", newComponent)
    genPageContext.setVersionSubComponent(newComponent)
    genPageContext.setUpdateComponents(newComponent)
    genPageContext.setConTrolconfigs(newComponent)
  }

  //SET DEFUALT PANELS
  const getDefualtPanelScreen = (row: number, col: number) => {
    let panelsScreen = []
    for(let r = 1; r <= row; r++){
      let rowPanel:any = {
        "rowId": `r${r}`,
        "isControlItem": false,
        "cols": []
      }

      for(let c = 1; c <= col; c++){
        let colObj:any = {
          "colId": `main_cell-r${r}-c${c}`,
          "childIdRef": "",
          "isControlItem": false,
          "controlComponent": null
        }
        rowPanel.cols.push(colObj)
      }
      panelsScreen.push(rowPanel)

    }
    return panelsScreen
  }

  const getComponentId = (componentText: string) => {
    let componentId = ''
    switch (componentText){
      case 'TAB':
        componentId = 'ct1'
        break;
      case 'BREADCRUMP':
        componentId = 'ct2'
        break;
      case 'BUTTON':
        componentId = 'ct3'
        break;
      case 'ICON':
        componentId = 'ct4'
        break;
      case 'IMAGE':
        componentId = 'ct5'
        break;
      case 'LABLE':
        componentId = 'ct6'
        break;
      case 'TABLE':
        componentId = 'ct7'
        break;
      default: 
        componentId = ''
        break;
    }
    return componentId
  }

  //GENERATE PAGE CONFIG ACTION
  const actionGenerateScreenConfig = async (
    boConfigItems: GetEntityBoItem[],
    genMfResponse?: GenerateMFResponse
  ) => {
    let chooseTemplate = genPageContext.chooseTemplate;
    let generateBoConfig = genPageContext.generateBoConfig;
    // console.log(
    //   "actionGenerateScreenConfig generateBoConfig => ",
    //   generateBoConfig
    // );
    let boFieldsSearchFilterSelected =
      getBoFieldByGenerateBoConfig(generateBoConfig);
    // console.log(
    //   "actionGenerateScreenConfig boFieldsSearchFilterSelected => ",
    //   boFieldsSearchFilterSelected
    // );

    let boFieldsConfig = genPageContext.boFieldForConfig;
    let boFieldsData = genPageContext.boFieldForData;
    let pageScreenConfig = genPageContext.panelItems;
    let allBoFieldsInSelects = genPageContext.boFieldForSelect;
    let jsonBoMapping = boMapping?.nodeDataArray;
    let updateComponentsContext = genPageContext.updateComponents;
    let mappingParentChildComponent =
      genPageContext?.mappingParentChildComponentTable;

    let callConfigScreenReq = getGeneratePageConfigScreenRequest(
      genMfResponse as GenerateMFResponse,
      // fixGenMFResp,
      chooseTemplate,
      // boFields,
      boFieldsSearchFilterSelected,
      boFieldsConfig,
      pageScreenConfig,
      boConfigItems,
      jsonBoMapping,
      allBoFieldsInSelects,
      updateComponentsContext,
      mappingParentChildComponent,
      boFieldsData
    );
    console.log(
      "checkDataInContext callConfigScreenReq => ",
      callConfigScreenReq
    );

    //ADD SOME FIELD FROM GENERATE_MF
    // console.log("GenerateMF callGenerateMFReq request => ", callGenerateMFReq)

    let newCallGeneratePageFileRequest = {
      ...callConfigScreenReq,
      bo: callGenerateMFReq?.bo,
      mapping: callGenerateMFReq?.mapping,
      table: callGenerateMFReq?.table,
    };
    console.log(
      "GenerateMF newCallGeneratePageFileRequest => ",
      newCallGeneratePageFileRequest
    );

    let datetime = new Date()
      .toISOString()
      .replace(/[-:.TZ]/g, "")
      .slice(0, 14);
    /**
     * begin generate edit page, add page also
     */
    let page = {
      editPage: {
        pageName: getPageName("editPage"),
        componentId: null,
        initFn: "",
      },
      addPage: {
        pageName: getPageName("addPage"),
        componentId: null,
        initFn: "",
      },
      mainPage: {
        pageName: getPageName("mainPage"),
        componentId: null,
        initFn: "",
      },
    };
    setPageGen(page);
    /**
     * end generate edit page, add page before
     */

    //CALL TO GET HTML, CSS, JS FROM GENERATE_PAGE REQUEST FUNCTION
    let {
      div2Content: html,
      jsText: js,
      cssText: css,
      functionName,
    } = await generatePageFile(
      newCallGeneratePageFileRequest,
      "mainPage",
      datetime,
      page
    );
    setFunctionNameToGen(functionName);
    setDateTimeGenMain(datetime);
    console.log("generatePageFile html => ", html);
    console.log("generatePageFile js => ", js);
    console.log("generatePageFile css => ", css);

    // @ts-ignore
    let convertHtml: any = window?.CONVERT_HTML_TO_OBJECT(
      html,
      getPageName("mainPage")
    );
    let htmlToGen = JSON.stringify(convertHtml?.page?.pageNodes);
    console.log("mainPage", convertHtml?.page?.pageNodes);
    //CALL GENERATE_PAGE AND UPLOAD LIBS
    let genPageReq = getGeneratePageOnewebRequest(htmlToGen, css, js);
    // console.log("actionGenerateScreenConfig genPageReq => ", genPageReq);
    let filesToUpload = await getFormDataWithFilesForGenPageUpload();
    // console.log("actionGenerateScreenConfig filesToUpload => ", filesToUpload);
    callGeneratePageOnewebAndUploadLibsController(genPageReq, filesToUpload);
    setJsonGenHtml(newCallGeneratePageFileRequest);
  };

  useEffect(() => {
    if (toGenMapping) continueGenPageConfig();
  }, [toGenMapping]);

  const continueGenPageConfig = async () => {
    try {
      // console.log("pageIdToGen => ", pageIdToGen);
      // console.log("jsonGenHtml => ", jsonGenHtml);
      // let jsonGenHtmlTemp = jsonGenHtml;

      // loop only one object in screens , not support many screen\
      //have to set  lenght of jsonGenHtml.screens == 1 only
      for (let a = 0; a < jsonGenHtml.screens.length; a++) {
        let key = jsonGenHtml.screens[a].key;
        //get input of mfd
        let inputMfdRes: any = await callApiMethodMFDGet(
          `${GET_BO_MFD}/${jsonGenHtml?.flow.flowId}/inputMapping`
        );
        //get output of mfd
        let outputMfdRes: any = await callApiMethodMFDGet(
          `${GET_BO_MFD}/${jsonGenHtml?.flow.flowId}/outputMapping`
        );

        let mainScreen = jsonGenHtml.screens[a];
        let mainConfigType = mainScreen.configType;
        if (mainConfigType === "LIST_TABLE") {
           /**
           * generate main page
           * */
          //get all page nodes
          let pNameMain = "mainPage";
          let getPageNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${pageIdToGen}`
          );

          //create state for page designer
          let { genStateReq, boJsonState, pgkeyDummy } = prepareGenStateReq(
            jsonGenHtml,
            pageIdToGen
          );
          let genStateRes: any = await callApiMethodPagePost(
            `${CREATE_PAGE_STATE}`,
            genStateReq
          );

          //create output shchem of mfd for page designer
          let genSchemaReq: GenSchemaReq = prepareGenSchemaReq(
            jsonGenHtml,
            pageIdToGen,
            outputMfdRes,
            nodeContentId
          );
          let genSchemaRes: any = await callApiMethodPagePost(
            `${CREATE_PAGE_OUTPUT_SCHEMA}`,
            genSchemaReq
          );

          //create mapping and call mfd by event on node (page_content on load)
          await createMappinMFDOnNode(
            jsonGenHtml,
            pageIdToGen,
            nodeContentId,
            inputMfdRes,
            boJsonState,
            pgkeyDummy,
            dateTimeGenMain,
            pNameMain,
            "load",
            "beforeLoadMFD",
            "afterLoadMFD"
          );

          //create mapping and call mfd by event on node (search btn on click)
          let pageNodeSearchBtnId: string = getPageNodeIDByHtmlID(
            getPageNodeRes,
            `searchButton-${mainConfigType}-${key}`
          );
          await createMappinMFDOnNode(
            jsonGenHtml,
            pageIdToGen,
            pageNodeSearchBtnId,
            inputMfdRes,
            boJsonState,
            pgkeyDummy,
            dateTimeGenMain,
            pNameMain,
            "click",
            "beforeSearchMFD",
            "afterLoadMFD"
          );

          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `action-edit-button-${key}-{{$index}}`,
            "clickEditItemFn",
            "click",
            pNameMain
          );

          //Set delete in row_item
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `action-delete-button-${key}-{{$index}}`,
            "handleClickRemoveItem",
            "click",
            pNameMain
          );

          // await genHtmlWithEvent(
          //   getPageNodeRes,
          //   jsonGenHtml,
          //   pageIdToGen,
          //   dateTimeGenMain,
          //   `button-remove-${mainConfigType}_{{$index}}`,
          //   "clickDeleteItemFn",
          //   "click",
          //   pNameMain
          // );

          //Set delete by select_list
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `deleteBtnIcon-${key}`,
            "handleClickRemoveItems",
            "click",
            pNameMain
          );

          //Set checkbox function
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `checkbox-child-${key}-{{$index}}`,
            "onChangeCheckBox",
            "change",
            pNameMain
          );

          //Set checkbox all items function 
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `checkbox-all-childs-${pNameMain}-${key}`,
            "onChangeSelectAllCheckBox",
            "change",
            pNameMain
          );

          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `content-add-button-${key}`,
            "handleGotoAddPage",
            "click",
            pNameMain
          );

           /**
           * generate edit page
           **/
          //get all page nodes
          //create state for page designer
          let pNameEdit = "editPage";
          let editPage = await generatePageFile(
            jsonGenHtml,
            pNameEdit,
            dateTimeGenMain,
            pageGen
          );
          let genEditPageReq = prepareDataGenPage(
            editPage,
            getPageName(pNameEdit),
            versionName,
            getTemplateId(),
            getBoId() as string,
            getStoryId() as string,
            pageGenerateId as string
          );
          let genEditPageRes: any = await callApiMethodZeroPost(
            `${CALL_GENERATE_PAGE_ENDPOINT}`,
            genEditPageReq
          );

          let editPageId = genEditPageRes.data?.data?.content?.componentId;
          let getPageEditNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${editPageId}`
          );
          let nodeContentIdEdit = getPageNodeIDByClassname(
            getPageEditNodeRes,
            "page__content"
          );

          //create state for page designer (edit)
          let prepareStateEdit: any = prepareGenStateReq(
            jsonGenHtml,
            editPageId
          );
          let genStateReqEdit = prepareStateEdit.genStateReq,
            boJsonStateEdit = prepareStateEdit.boJsonState,
            pgkeyDummyEdit = prepareStateEdit.pgkeyDummy;
          let genStateResEdit: any = await callApiMethodPagePost(
            `${CREATE_PAGE_STATE}`,
            genStateReqEdit
          );

          //create output shchem of mfd for page designer (edit)
          let genSchemaReqEdit: GenSchemaReq = prepareGenSchemaReq(
            jsonGenHtml,
            editPageId,
            outputMfdRes,
            nodeContentIdEdit
          );
          let genSchemaResEdit: any = await callApiMethodPagePost(
            `${CREATE_PAGE_OUTPUT_SCHEMA}`,
            genSchemaReqEdit
          );

          // await createMappinMFDOnNode(jsonGenHtml, editPageId, nodeContentIdEdit, inputMfdRes, boJsonStateEdit, pgkeyDummyEdit, dateTimeGenMain, pNameEdit, "load", "beforeLoadMain", "");
          if (jsonGenHtml.refTemplate === "OneToMany") {
            await createMappinMFDOnNode(
              jsonGenHtml,
              editPageId,
              nodeContentIdEdit,
              inputMfdRes,
              boJsonStateEdit,
              pgkeyDummyEdit,
              dateTimeGenMain,
              pNameEdit,
              "load",
              "beforeLoadChilds",
              ""
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `search-input-childs-btn`,
              "clickSearchChildFn",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `row-child-${key}-{{$index}}`,
              "handleClickRowEdit",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `checkbox-child-${key}-{{$index}}`,
              "handleClickCheckBoxRow",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `checkbox-all-childs`,
              "handleChangeCheckboxAll",
              "change",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `clear-cell-${pNameEdit}-${key}-data-button`,
              "handleClearBtn",
              "click",
              pNameEdit
            );
          }

          // let getPageEditNodeRes: any = await callApiMethodPageGet(`${GET_PAGE}/${editPageId}`);
          await genHtmlWithEvent(
            getPageEditNodeRes,
            jsonGenHtml,
            editPageId,
            dateTimeGenMain,
            `btn-${key}-${pNameEdit}`,
            "clickSaveItemFn",
            "click",
            pNameEdit
          );
          await genHtmlWithEvent(
            getPageEditNodeRes,
            jsonGenHtml,
            editPageId,
            dateTimeGenMain,
            `page__content_${pNameEdit}_${mainConfigType}_${key}_${dateTimeGenMain}`,
            "initLoad",
            "load",
            pNameEdit
          );

          /**
           * generate add page
           * */
          //get all page nodes
          let pNameAdd = "addPage";
          let addPage = await generatePageFile(
            jsonGenHtml,
            pNameAdd,
            dateTimeGenMain,
            pageGen
          );
          let genAddPageReq = prepareDataGenPage(
            addPage,
            getPageName(pNameAdd),
            versionName,
            getTemplateId(),
            getBoId() as string,
            getStoryId() as string,
            pageGenerateId as string
          );
          let genAddPageRes: any = await callApiMethodZeroPost(
            `${CALL_GENERATE_PAGE_ENDPOINT}`,
            genAddPageReq
          );

          let addPageId = genAddPageRes.data?.data?.content?.componentId;
          let getPageAddNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${addPageId}`
          );
          await genHtmlWithEvent(
            getPageAddNodeRes,
            jsonGenHtml,
            addPageId,
            dateTimeGenMain,
            `btn-${key}-${pNameAdd}`,
            "clickSaveNewItemFn",
            "click",
            pNameAdd
          );
          await genHtmlWithEvent(
            getPageAddNodeRes,
            jsonGenHtml,
            addPageId,
            dateTimeGenMain,
            `page__content_${pNameAdd}_${mainConfigType}_${key}_${dateTimeGenMain}`,
            "initLoad",
            "load",
            pNameAdd
          );

        } else if (
          mainConfigType === "LIST_TILE" ||
          mainConfigType === "LIST" ||
          mainConfigType === "LIST_CARD"
        ) {
          // generate action event, mapping, page state, config screen with response mfr,

          /**
           * generate main page
           * */
          //get all page nodes
          let pNameMain = "mainPage";
          let getPageNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${pageIdToGen}`
          );

          //create state for page designer
          let { genStateReq, boJsonState, pgkeyDummy } = prepareGenStateReq(
            jsonGenHtml,
            pageIdToGen
          );
          let genStateRes: any = await callApiMethodPagePost(
            `${CREATE_PAGE_STATE}`,
            genStateReq
          );

          //create output shchem of mfd for page designer
          let genSchemaReq: GenSchemaReq = prepareGenSchemaReq(
            jsonGenHtml,
            pageIdToGen,
            outputMfdRes,
            nodeContentId
          );
          let genSchemaRes: any = await callApiMethodPagePost(
            `${CREATE_PAGE_OUTPUT_SCHEMA}`,
            genSchemaReq
          );

          //create mapping and call mfd by event on node (page_content on load)
          await createMappinMFDOnNode(
            jsonGenHtml,
            pageIdToGen,
            nodeContentId,
            inputMfdRes,
            boJsonState,
            pgkeyDummy,
            dateTimeGenMain,
            pNameMain,
            "load",
            "beforeLoadMFD",
            "afterLoadMFD"
          );

          //create mapping and call mfd by event on node (search btn on click)
          let pageNodeSearchBtnId: string = getPageNodeIDByHtmlID(
            getPageNodeRes,
            `searchButton-${mainConfigType}-${key}`
          );
          await createMappinMFDOnNode(
            jsonGenHtml,
            pageIdToGen,
            pageNodeSearchBtnId,
            inputMfdRes,
            boJsonState,
            pgkeyDummy,
            dateTimeGenMain,
            pNameMain,
            "click",
            "beforeSearchMFD",
            "afterLoadMFD"
          );

          //create click edit,remove btn event to call clickEditItemFn
          //await genHtmlWithEvent(getPageNodeRes, jsonGenHtml, pageIdToGen, dateTimeGenMain, "button-edit-list-tile_{{$index}}", "clickEditItemFn", "click", pNameMain);
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `card-content-${mainConfigType}-${key}-{{$index}}`,
            "clickEditItemFn",
            "click",
            pNameMain
          );
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `button-remove-${mainConfigType}_{{$index}}`,
            "clickDeleteItemFn",
            "click",
            pNameMain
          );
          await genHtmlWithEvent(
            getPageNodeRes,
            jsonGenHtml,
            pageIdToGen,
            dateTimeGenMain,
            `content-add-button-${key}`,
            "handleGotoAddPage",
            "click",
            pNameMain
          );

          /**
           * generate edit page
           **/
          //get all page nodes
          //create state for page designer
          let pNameEdit = "editPage";
          let editPage = await generatePageFile(
            jsonGenHtml,
            pNameEdit,
            dateTimeGenMain,
            pageGen
          );
          let genEditPageReq = prepareDataGenPage(
            editPage,
            getPageName(pNameEdit),
            versionName,
            getTemplateId(),
            getBoId() as string,
            getStoryId() as string,
            pageGenerateId as string
          );
          let genEditPageRes: any = await callApiMethodZeroPost(
            `${CALL_GENERATE_PAGE_ENDPOINT}`,
            genEditPageReq
          );

          let editPageId = genEditPageRes.data?.data?.content?.componentId;
          let getPageEditNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${editPageId}`
          );
          let nodeContentIdEdit = getPageNodeIDByClassname(
            getPageEditNodeRes,
            "page__content"
          );

          //create state for page designer (edit)
          let prepareStateEdit: any = prepareGenStateReq(
            jsonGenHtml,
            editPageId
          );
          let genStateReqEdit = prepareStateEdit.genStateReq,
            boJsonStateEdit = prepareStateEdit.boJsonState,
            pgkeyDummyEdit = prepareStateEdit.pgkeyDummy;
          let genStateResEdit: any = await callApiMethodPagePost(
            `${CREATE_PAGE_STATE}`,
            genStateReqEdit
          );

          //create output shchem of mfd for page designer (edit)
          let genSchemaReqEdit: GenSchemaReq = prepareGenSchemaReq(
            jsonGenHtml,
            editPageId,
            outputMfdRes,
            nodeContentIdEdit
          );
          let genSchemaResEdit: any = await callApiMethodPagePost(
            `${CREATE_PAGE_OUTPUT_SCHEMA}`,
            genSchemaReqEdit
          );

          // await createMappinMFDOnNode(jsonGenHtml, editPageId, nodeContentIdEdit, inputMfdRes, boJsonStateEdit, pgkeyDummyEdit, dateTimeGenMain, pNameEdit, "load", "beforeLoadMain", "");
          if (jsonGenHtml.refTemplate === "OneToMany") {
            await createMappinMFDOnNode(
              jsonGenHtml,
              editPageId,
              nodeContentIdEdit,
              inputMfdRes,
              boJsonStateEdit,
              pgkeyDummyEdit,
              dateTimeGenMain,
              pNameEdit,
              "load",
              "beforeLoadChilds",
              ""
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `search-input-childs-btn`,
              "clickSearchChildFn",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `row-child-${key}-{{$index}}`,
              "handleClickRowEdit",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `checkbox-child-${key}-{{$index}}`,
              "handleClickCheckBoxRow",
              "click",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `checkbox-all-childs`,
              "handleChangeCheckboxAll",
              "change",
              pNameEdit
            );
            await genHtmlWithEvent(
              getPageEditNodeRes,
              jsonGenHtml,
              editPageId,
              dateTimeGenMain,
              `clear-cell-${pNameEdit}-${key}-data-button`,
              "handleClearBtn",
              "click",
              pNameEdit
            );
          }

          // let getPageEditNodeRes: any = await callApiMethodPageGet(`${GET_PAGE}/${editPageId}`);
          await genHtmlWithEvent(
            getPageEditNodeRes,
            jsonGenHtml,
            editPageId,
            dateTimeGenMain,
            `btn-${key}-${pNameEdit}`,
            "clickSaveItemFn",
            "click",
            pNameEdit
          );
          await genHtmlWithEvent(
            getPageEditNodeRes,
            jsonGenHtml,
            editPageId,
            dateTimeGenMain,
            `page__content_${pNameEdit}_${mainConfigType}_${key}_${dateTimeGenMain}`,
            "initLoad",
            "load",
            pNameEdit
          );

          /**
           * generate add page
           * */
          //get all page nodes
          let pNameAdd = "addPage";
          let addPage = await generatePageFile(
            jsonGenHtml,
            pNameAdd,
            dateTimeGenMain,
            pageGen
          );
          let genAddPageReq = prepareDataGenPage(
            addPage,
            getPageName(pNameAdd),
            versionName,
            getTemplateId(),
            getBoId() as string,
            getStoryId() as string,
            pageGenerateId as string
          );
          let genAddPageRes: any = await callApiMethodZeroPost(
            `${CALL_GENERATE_PAGE_ENDPOINT}`,
            genAddPageReq
          );

          let addPageId = genAddPageRes.data?.data?.content?.componentId;
          let getPageAddNodeRes: any = await callApiMethodPageGet(
            `${GET_PAGE}/${addPageId}`
          );
          await genHtmlWithEvent(
            getPageAddNodeRes,
            jsonGenHtml,
            addPageId,
            dateTimeGenMain,
            `btn-${key}-${pNameAdd}`,
            "clickSaveNewItemFn",
            "click",
            pNameAdd
          );
          await genHtmlWithEvent(
            getPageAddNodeRes,
            jsonGenHtml,
            addPageId,
            dateTimeGenMain,
            `page__content_${pNameAdd}_${mainConfigType}_${key}_${dateTimeGenMain}`,
            "initLoad",
            "load",
            pNameAdd
          );

          /***
           * if one to many create more here
           ***/
        }
      }

      endProgressLoadingHandler();
      afterGenerateSuccess();
    } catch (error) {
      console.error(error);
      endProgressLoadingHandler();
    }
  };

  //GENERATE MICROFLOW ACTION
  const actionGenerateMicroflow = () => {
    getDBConnectController();
    setIsLoadingGenMF(true);
  };

  const endProgressLoadingHandler = () => {
    setIsLoadingGenMF(false);
  };
  const afterGenerateSuccess = () => {
    let currentPath = location.pathname;

    let newPathArr = currentPath.split("/");
    newPathArr.pop();

    if (newPathArr?.length === 0) return;
    let pathAfterGenMF = newPathArr.join("/");
    navigate(pathAfterGenMF, {
      replace: true,
      state: {
        isFromGenMF: true,
      },
    });
  };

  //UTILITY FUNCTION
  enum Step {
    CHOOSE_TEMPLATE,
    SEARCH_FILTER_CONFIG,
    SEARCH_RESULT_CONFIG,
    CREATE_AND_EDIT_PAGE,
  }
  const saveDataOnClickNext = () => {
    let currentStep = genPageContext.currentStep;
    switch (currentStep) {
      case Step.CHOOSE_TEMPLATE: {
        genPageContext.setIsSaveChooseTemplate(true);
        break;
      }
      case Step.SEARCH_FILTER_CONFIG: {
        genPageContext.setIsSaveSearchFilter(true);
        break;
      }
      case Step.SEARCH_RESULT_CONFIG: {
        setIsGenPageSuccess(false);
        genPageContext.setIsSaveSearchResult(true);
        break;
      }
      case Step.CREATE_AND_EDIT_PAGE: {
        break;
      }
      default: {
        console.log("error step => ", currentStep);
      }
    }
  };

  const isNotFirstStep = () => {
    return currentStep > 0;
  };

  const isNotLastStep = () => {
    return currentStep < steps.length - 1;
  };

  const isLastStep = () => {
    return currentStep === steps.length - 1;
  };

  const getStoryId = () => {
    return params.id;
  };

  const getBoId = () => {
    return searchParams.get("bo_id");
  };

  const getVersionId = () => {
    return searchParams.get("verionId");
  };

  const getTemplateId = () => {
    return genPageContext?.chooseTemplate?.list_template_id;
  };

  const getPageName = (pageName: string) => {
    let storyName = genPageContext.storyName;
    if (checkEmpty(pageName)) {
      return "page_" + storyName + "_" + versionName;
    } else {
      return "page_" + storyName + "_" + pageName + "_" + versionName;
    }
  };

  //GENERATE PAGE HANDLER
  const getActionGeneratePageRequest = (
    nodeId: string
  ): GeneratePageActionRequest => {
    const id = uuidv4();
    let result: GeneratePageActionRequest = {
      eventType: "load",
      action: {
        pageId: pageIdToGen,
        appId: getAspId() as string,
        actionType: "JavaScript",
        functionName: functionNameToGen,
        id,
      },
      nodeId,
    };
    return result;
  };

  const getGeneratePageRequest = (
    versionName: string,
    description: string
  ): GeneratePageRequest => {
    let enyityBoId = getBoId() as string;
    let chooseTemplateData = genPageContext.chooseTemplate;
    let generateBo = genPageContext.generateBoConfig;
    let panelItems = genPageContext.panelItems;
    console.log("panelItems => ", panelItems);

    let result: GeneratePageRequest = {
      version_name: versionName,
      version_detail: description,
      enyity_bo_id: enyityBoId,
      list_template_id: chooseTemplateData.list_template_id,
      create_template_id: chooseTemplateData.create_template_id,
      generate_bo: generateBo,
      generate_screen: getScreenGenerate(panelItems),
    };

    // console.log("getGeneratePageRequest result => ", result);
    return result;
  };

  const getGeneratePageOnewebRequest = (
    html: string,
    css: string,
    js: string
  ): GeneratePageOnewebRequest => {
    let appId = getAspId() as string;
    let pageName = getPageName("mainPage");
    let pageTitle = versionName;
    let templateId = getTemplateId();
    let enyity_bo_id = getBoId() as string;
    let story_id = getStoryId() as string;
    let page_generate_id = pageGenerateId;

    return {
      appId,
      pageName,
      pageTitle,
      templateId,
      enyity_bo_id,
      story_id,
      page_generate_id,
      js,
      css,
      html,
    };
  };

  const getFormDataWithFilesForGenPageUpload = async (): Promise<FormData> => {
    let allFileNameAndPath = UPLOAD_LIBS;

    let formData = new FormData();
    for (const fileDetail of allFileNameAndPath) {
      const response = await fetch(fileDetail.filePath);
      const fileBlob = await response.blob();
      const file = new File([fileBlob], fileDetail.fileName, {
        type: fileBlob.type,
      });
      formData.append("file", file);
    }

    return formData;
  };

  //SAVE VERSION MODAL
  const onOkVersionModalHandler = () => {
    setIsOpenSaveVersionModal(false);
  };
  const onCancelVersionModalHandler = () => {
    setIsOpenSaveVersionModal(false);
  };
  const onSaveVersionHistoryHandler = (
    versionName: string,
    description: string
  ) => {
    // console.log(
    //   `onSaveVersionHistoryHandler versionName: ${versionName}, description: ${description}`
    // );
    setVersionName(versionName);
    let genPageRequest = getGeneratePageRequest(versionName, description);
    console.log(">==== genPageRequest =====> - - - ", genPageRequest)
    generatePageController(genPageRequest);
  };
  const actionAfterSaveGenPageVersionSuccess = () => {
    setIsOpenSaveVersionModal(false);
    setIsGenPageSuccess(true);
  };

  const getColumnNumber = (colId: string): number => {
    const col_number = colId.split('-c')[1];
    return parseInt(col_number);
  };

  const getComponentReq = (rowId: string, itemType: string) => {
    console.log("itemType => ", itemType)
    console.log("cell or row ID => ", rowId)
    console.log("genPageContext => ", genPageContext)
    console.log("genPageContext.conTrolconfigs => ", genPageContext.conTrolconfigs)
    console.log("genPageContext.parentSelectedComponentTable => ", genPageContext.parentSelectedComponentTable)

    let conTrolsComponent: ControlComponent[] = genPageContext.conTrolconfigs
    let parentSelected = genPageContext.parentSelectedComponentTable
    let component = conTrolsComponent.filter(item => itemType === 'row' ? item.rowId === rowId : item.colId === rowId);
    const parentSelectedName = parentSelected.filter((item:any) => item.rowId && item.rowId === rowId );
    console.log("component => ", component);
    console.log("parentSelectedName => ", parentSelectedName);

    const row = itemType === 'cell' ? parseInt(rowId.split("-r")[1].split('-c')[0]) : parseInt(rowId.split("r")[1]);
    const col = component[0].colId ? parseInt(component[0].colId.split("-c")[1]) : 1;
    const childs: any[] = [];
    let configtype = component[0].configType != undefined ? component[0].configType : component[0].detail.configType
    let sub_component: any = {
      json: component[0].componentConfig ? component[0].componentConfig : component[0].detail
    }
    if(configtype === "TABLE"){
      sub_component = {
        parentSelected : parentSelectedName[0]?.parentSelected,
        json: component[0].componentConfig ? component[0].componentConfig : component[0].detail
      }
    }
    
    console.log("sub_component : ", sub_component)
    const ComponentReq: ComponentReq = {
        enyity_bo_item_id: '',
        field_key: "field_key",
        field_type: configtype as "TAB" | "BREADCRUMP" | "BUTTON" | "ICON" | "IMAGE" | "BO_ITEM" | "TABLE",
        row_number: row,
        column_number: col,
        childs: [],
        json_config_subcomponent: JSON.stringify(sub_component)
    };
    console.log("ComponentReq => ", ComponentReq);
    
    return ComponentReq;
  
  };

  const getScreenGenerate = (screen: ScreenItem[]): ComponentReq[] => {
    const genScreenReq: ComponentReq[] = [];
    let screen_row = screen.length
    let screen_column = 9

    for (const scn of screen) {
      const row = parseInt(scn.rowId.split('r')[1]);

      if (scn.cols && scn.cols.length > 0 && scn.isControlItem === false) {
        screen_column = scn.cols.length
          scn.cols.forEach((item) => {
            if (item.childIdRef !== '') {
              const obj: ComponentReq = {
                enyity_bo_item_id: item.childIdRef,
                field_key: !scn.isControlItem ? "field_key" : "field_key",
                field_type: "BO_ITEM", // or other valid type
                row_number: row,
                column_number: getColumnNumber(item.colId) ?? 1,
                childs: [],
                json_config_subcomponent: ''
              };
              genScreenReq.push(obj);
            }

            if (item.isControlItem) {
              const obj1_2 = getComponentReq(item.colId,'cell');
              genScreenReq.push(obj1_2);
            }
          });
      } else if (scn.isControlItem) {
          const obj2 = getComponentReq(scn.rowId, 'row');
          genScreenReq.push(obj2);
      }
    }

    console.log("genScreenReq => ", genScreenReq);
    return genScreenReq;
  };

  //API CONTROLLER FUNCTION
  const callGeneratePageOnewebAndUploadLibsController = (
    genPageReq: GeneratePageOnewebRequest,
    fileToUpload: FormData
  ) => {
    let callGeneratePageReq = {
      endpoint: CALL_GENERATE_PAGE_ENDPOINT,
      method: "POST",
      reqBody: genPageReq,
    };

    let callUploadLibsReq = {
      endpoint: UPLOAD_FILE_LIBS_ENDPOINT,
      method: "POST",
      params: {
        app_id: getAspId(),
        force: "Y",
      },
      reqBody: fileToUpload,
      isUploadFile: true,
    };
    let request: any = [callGeneratePageReq, callUploadLibsReq];
    fetchDataAsync(request, GEN_PAGE_UP_LIBS_FK);
  };
  const getDBConnectController = () => {
    fetchData(
      {
        endpoint: GET_DB_CONNECT_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_DB_CONNECT_FK
    );
  };

  const getGenenrateVersionByID = () => {
    fetchData(
      {
        endpoint: GET_PAGE_GENERATE_BY_ID_ENDPOINT,
        method: "GET",
        params: {
          generate_version_id: getVersionId(),
        },
      },
      GET_GEN_PAGE_VERSION_FK
    );
  };

  const prepareDataForGenMFController = (
    boId: string,
    dbConnect: GetTableAndColumnRequest
  ) => {
    let getBoById = {
      endpoint: GET_ENTITY_BO_BY_ID_ENDPOINT,
      method: "GET",
      params: {
        id: boId,
      },
    };
    let getTableColReq = {
      endpoint: GET_TABLE_COL_ENDPOINT,
      method: "POST",
      reqBody: dbConnect,
    };
    let getBoByStoryIdReq = {
      endpoint: GET_BO_DATA_REFER_BY_STORY_ID_ENDPOINT,
      method: "GET",
      params: {
        story_id: getStoryId(),
      },
    };
    let request: any = [getBoById, getTableColReq, getBoByStoryIdReq];

    fetchDataAsync(request, PREPARE_GEN_MF_FK);
  };

  const generateMFController = (req: GenerateMFRequest) => {
    fetchData(
      {
        endpoint: GENERATE_MF_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GEN_MF_FK
    );
  };

  const generatePageController = (req: GeneratePageRequest) => {
    fetchData(
      {
        endpoint: CREATE_PAGE_GENERATE_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GEN_PAGE_FK
    );
  };

  const getPageNodesController = (pageId: string) => {
    fetchData(
      {
        endpoint: GET_PAGE_NODES_BY_PAGEID_ENDPOINT, //waiting
        method: "GET",
        params: {
          page_id: pageId,
        },
      },
      GET_PAGE_NODES_FK
    );
  };

  const callActionGenPageController = (req: GeneratePageActionRequest) => {
    fetchData(
      {
        endpoint: GEN_PAGE_ACTION_ENDPOINT, //waiting
        method: "PUT",
        reqBody: req,
      },
      GEN_PAGE_ACTION_FK
    );
  };

  return (
    <>
      <Steps size="small" current={currentStep} items={items} />
      <div className={styles.genPageContainer}>
        <div className={styles.genPageTitle}>{steps[currentStep].title}</div>

        {steps[currentStep].content}
      </div>
      <div className={styles.footerButton}>
        <Button
          className={styles.cancelBtn}
          key="cancel"
          onClick={onCancelGenPageHandler}
        >
          Cancel
        </Button>
        {isNotFirstStep() && (
          <Button
            className={styles.cancelBtn}
            key="back"
            onClick={onClickBackGenPageHandler}
          >
            Back
          </Button>
        )}

        {isNotLastStep() && (
          <Button
            className={styles.confirmBtn}
            key="next"
            onClick={onClickNextGenPageHandler}
            type="primary"
            loading={genPageContext.isLoadingBtnGeneratePage}
          >
            Next
          </Button>
        )}

        {isLastStep() && (
          <Button
            className={styles.cancelBtn}
            key="save_version"
            onClick={onClickSaveVersionGenPageHandler}
          >
            Save Version
          </Button>
        )}
        {isLastStep() && (
          <Button
            className={styles.cancelBtn}
            key="preview"
            onClick={onClickPreviewGenPageHandler}
            // type="primary"
          >
            Preview
          </Button>
        )}
        {isLastStep() && (
          <Button
            className={styles.cancelBtn}
            key="gen_ui_flow"
            onClick={onClickGenUIAndFlowHandler}
            // type="primary"
            disabled={isGenPageSuccess ? false : true}
          >
            Generate UI & Flow
          </Button>
        )}
        {/* SAVE VERSION MODAL */}
        <SaveVersionModal
          isModalOpen={isOpenSaveVersionModal}
          onOk={onOkVersionModalHandler}
          onCancel={onCancelVersionModalHandler}
          onSaveVersionHistory={onSaveVersionHistoryHandler}
          panelItems={genPageContext.panelItems}
        />

        {/* GENERATE MF LOADING */}
        <GenerateMFLoading isLoading={isLoadingGenMF} />
      </div>
    </>
  );
}

export default EditStoryEntityGenPage;
