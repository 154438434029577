import React, { useMemo, useEffect, useState } from "react";
import { Table } from "antd";
import "./StoryList.css";
import styles from "./StoryList.module.scss";
import DeleteIcon from "src/assets/svg/delete";
import EditIcon from "src/assets/svg/editIcon";
import ModalDelete from "src/page/component/database-connection/modal/ModalDelete";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";
import { callApiMethodDelete } from "src/services/util.service";
import { useNavigate } from "react-router";
import { pathRoutes } from "src/page/props/util.props";
import { DELETE_STORY_COMPONENT } from "src/utils/endpoint/createbo.endpoint";

type ListItemProps = {
  storyItemList: any;
  RefaceApi: any;
};

function StoryList({ storyItemList, RefaceApi }: ListItemProps) {
  let navigate = useNavigate();
  function handlerStoryCardItemClick(id: string) {
    navigate(`/${pathRoutes.MainDashboard}/story/` + id);
  }

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const APIDelete = async (id: string) => {
    try {
      const response = await callApiMethodDelete(DELETE_STORY_COMPONENT, {
        story_id: id,
      });
      if (response.status == 200) {
        await RefaceApi();
      }
    } catch (error) {
      console.log("error delete story component : ", error);
    }
  };

  function handleDeleteConfirmation(id: string) {
    setSelectedId(id);
    setOpenModalDelete(true);
  }

  const handleOk = () => {
    APIDelete(selectedId);
    setOpenModalDelete(false);
  };

  const columns = [
    {
      title: "My Story Name",
      key: "story_name",
      dataIndex: "story_name",
      render: (text: any) => <>{text}</>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (text: any) => <>{formatISODateToCustomFormat(text)}</>,
    },
    {
      title: "Owner",
      dataIndex: "created_by",
      key: "owner",
      render: (text: any) => <>{text}</>,
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <div className={styles.iconContainerStyle}>
          <div onClick={() => handlerStoryCardItemClick(record.id)}>
            <EditIcon />
          </div>
          <div
            className={styles.deleteIconStyle}
            onClick={() => handleDeleteConfirmation(record.id)}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        className={styles.tableBody}
        columns={columns}
        dataSource={storyItemList}
      />

      <ModalDelete
        title="Delete Story ?"
        description="Are you sure want to delete this story?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => {
          setOpenModalDelete(false);
        }}
        onOk={() => handleOk()}
        open={openModalDelete}
      />
    </>
  );
}

export default StoryList;
