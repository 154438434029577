import React from "react";

const DeleteIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width|"24"} 
        height={height|"24"} 
        style={style}
        className={className}
        viewBox="0 0 24 24"
         fill="none" 
         xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#E9E9E9" />
            <g opacity="0.5">
                <path d="M16.668 8.66797C16.4912 8.66797 16.3216 8.73821 16.1966 8.86323C16.0715 8.98826 16.0013 9.15782 16.0013 9.33464V16.7953C15.9822 17.1324 15.8306 17.4483 15.5796 17.6742C15.3286 17.9001 14.9986 18.0177 14.6613 18.0013H9.3413C9.00403 18.0177 8.67396 17.9001 8.42297 17.6742C8.17199 17.4483 8.02043 17.1324 8.0013 16.7953V9.33464C8.0013 9.15782 7.93106 8.98826 7.80604 8.86323C7.68102 8.73821 7.51145 8.66797 7.33464 8.66797C7.15782 8.66797 6.98826 8.73821 6.86323 8.86323C6.73821 8.98826 6.66797 9.15782 6.66797 9.33464V16.7953C6.687 17.4861 6.97902 18.1412 7.48009 18.6172C7.98115 19.0931 8.65042 19.3511 9.3413 19.3346H14.6613C15.3522 19.3511 16.0215 19.0931 16.5225 18.6172C17.0236 18.1412 17.3156 17.4861 17.3346 16.7953V9.33464C17.3346 9.15782 17.2644 8.98826 17.1394 8.86323C17.0143 8.73821 16.8448 8.66797 16.668 8.66797Z" fill="#393939" />
                <path d="M17.3333 6.66797H14.6667V5.33464C14.6667 5.15782 14.5964 4.98826 14.4714 4.86323C14.3464 4.73821 14.1768 4.66797 14 4.66797H10C9.82319 4.66797 9.65362 4.73821 9.5286 4.86323C9.40357 4.98826 9.33333 5.15782 9.33333 5.33464V6.66797H6.66667C6.48986 6.66797 6.32029 6.73821 6.19526 6.86323C6.07024 6.98826 6 7.15782 6 7.33464C6 7.51145 6.07024 7.68102 6.19526 7.80604C6.32029 7.93106 6.48986 8.0013 6.66667 8.0013H17.3333C17.5101 8.0013 17.6797 7.93106 17.8047 7.80604C17.9298 7.68102 18 7.51145 18 7.33464C18 7.15782 17.9298 6.98826 17.8047 6.86323C17.6797 6.73821 17.5101 6.66797 17.3333 6.66797ZM10.6667 6.66797V6.0013H13.3333V6.66797H10.6667Z" fill="#393939" />
                <path d="M11.3333 15.3333V10.6667C11.3333 10.4899 11.2631 10.3203 11.1381 10.1953C11.013 10.0702 10.8435 10 10.6667 10C10.4899 10 10.3203 10.0702 10.1953 10.1953C10.0702 10.3203 10 10.4899 10 10.6667V15.3333C10 15.5101 10.0702 15.6797 10.1953 15.8047C10.3203 15.9298 10.4899 16 10.6667 16C10.8435 16 11.013 15.9298 11.1381 15.8047C11.2631 15.6797 11.3333 15.5101 11.3333 15.3333Z" fill="#393939" />
                <path d="M14.0013 15.3333V10.6667C14.0013 10.4899 13.9311 10.3203 13.806 10.1953C13.681 10.0702 13.5114 10 13.3346 10C13.1578 10 12.9883 10.0702 12.8632 10.1953C12.7382 10.3203 12.668 10.4899 12.668 10.6667V15.3333C12.668 15.5101 12.7382 15.6797 12.8632 15.8047C12.9883 15.9298 13.1578 16 13.3346 16C13.5114 16 13.681 15.9298 13.806 15.8047C13.9311 15.6797 14.0013 15.5101 14.0013 15.3333Z" fill="#393939" />
            </g>
        </svg>
    );
}
export default DeleteIcon;