import { useState } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getToken } from "src/services/util.service";

type CallApiProps = {
  endpoint: string;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  reqBody?: any;
  params?: any;
  isUploadFile?: boolean;
};
type UseFetchResponse<T> = {
  loading: boolean;
  error: string | null;
  responseData: T | null;
  fetchData: (req: CallApiProps, fetchKey: string) => void;
  fetchDataAsync: (req: CallApiProps[], fetchKey: string) => void;
  isUpdateItems: boolean;
  fetchKey: string;
};

const useFetch = <T = any>(): UseFetchResponse<T> => {
  const [fetchKey, setFetchKey] = useState<string>("");
  const [isUpdateItems, setIsUpdateItems] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const token = getToken();

  const fetchData = async (req: CallApiProps, fetchKey: string) => {
    setError(null)
    setFetchKey(fetchKey);
    req.method !== "GET" ? setIsUpdateItems(true) : setIsUpdateItems(false);
    setLoading(true);
    
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": (req.isUploadFile) ? "multipart/form-data": "application/json"
      }
      const response: AxiosResponse<T> = await axios({
        url: req.endpoint,
        method: req.method || "GET",
        headers,//token ? { Authorization: `Bearer ${token}`,} : {},
        data: req.reqBody || {},
        params: req.params,
      });
      setResponseData(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        setError(axiosError.message);
        if (axiosError.response) {
          console.error("Data:", axiosError.response.data);
          console.error("Status:", axiosError.response.status);
        }
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDataAsync = async (reqs: CallApiProps[], fetchKey: string) => {
    setError(null)
    setFetchKey(fetchKey);
    setLoading(true);

    try {
      const responses = await Promise.all(
        reqs.map((req) =>
          {
            let headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": (req.isUploadFile) ? "multipart/form-data": "application/json"
            }
            return axios({
              url: req.endpoint,
              method: req.method || "GET",
              headers,
              data: req.reqBody || {},
              params: req.params,
            })
          }
          
        )
      );

      let fetchedData = responses.map((response) => response.data);
      setResponseData(fetchedData as T);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        setError(axiosError.message);
        console.error("Data:", axiosError.response?.data);
        console.error("Status:", axiosError.response?.status);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    responseData,
    fetchData,
    fetchDataAsync,
    isUpdateItems,
    fetchKey,
  };
};

export default useFetch;
