import React, { Component } from "react";

export const AppContext = React.createContext({});

export default class ProviderContext extends Component {
  state = {
    username: null,
    setUsername: (value) => {
      this.setState({ username: value });
    },
    password: null,
    setPassword: (value) => {
      this.setState({ password: value });
    },
    activeKey: "1",
    setActiveKey: (value) => {
      this.setState({ activeKey: value });
    },
    searchApp: "",
    setSearchApp: (value) => {
      this.setState({ searchApp: value });
    },
    viewMode: "",
    setViewMode: (value) => {
      this.setState({ viewMode: value });
    },
    selectedSort: "DESC",
    setSelectedSort: (value) => {
      this.setState({ selectedSort: value });
    },
    selectedFilter: "All",
    setSelectedFilter: (value) => {
      this.setState({ selectedFilter: value });
    },
    countConnector: 0,
    setCountConnector: (value) => {
      this.setState({ countConnector: value });
    },
    countStory: 0,
    setCountStory: (value) => {
      this.setState({ countStory: value });
    },
    tokenExpire: false,
    setTokenExpire: (value) => {
      this.setState({ tokenExpire: value });
    },
  };
  componentDidMount() {
    try {
    } catch (e) {}
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

ProviderContext.Consumer = AppContext.Consumer;
