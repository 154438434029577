import React from "react";
import EditStoryEntityGenPage from "./EditStoryEntityGenPage";
import { StoryGeneratePageProvider } from "src/page/context/StoryGeneratePage.context";

type Props = {};

function EditStoryEntityGenPageContainer({}: Props) {
  return (
    <StoryGeneratePageProvider>
      <EditStoryEntityGenPage />
    </StoryGeneratePageProvider>
  );
}

export default EditStoryEntityGenPageContainer;
