import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import styles from './ModalWarring.module.scss';
// import CheckCircleIcon from "src/assets/svg/check-circle";
import CheckCircleIcon from "src/assets/svg/warning-icon";

type ModalWarringProps = {
    open: boolean;
    onCon: () => void,
    onCan: () => void,
    name: any[]
    title:string
    description:string
    condition: string
};


function ModalWarring({ open, onCon, onCan, name, title, description, condition }: ModalWarringProps) {
    useEffect(() => {
        if(open) {
          window.parent.postMessage({event: "zero_open_modal", key: ""}, "*");
        } else {
          window.parent.postMessage({event: "zero_close_modal", key: ""}, "*");
        }
    
    }, [open, condition])

    return (
        <Modal
            open={open}
            width={400}
            footer={
                <div className={styles.footerModalWarning}>
                    {condition !== 'invalid' && (
                        <Button key="cancel" style={{ width: '50%' }} type="primary" onClick={onCan}>
                            Ok
                        </Button>
                    )}
                    {condition === 'invalid' && (
                        <div className={styles.footerModalWarning}>
                            <Button key="cancel" style={{ width: '50%' }} onClick={onCan}>
                                Cancel
                            </Button>
                            <Button key="ok" style={{ width: '50%' }} type="primary" onClick={onCon}>
                                Continue
                            </Button>
                        </div>
                    )}

                </div>
            }
            onCancel={onCan}
        >
            <div className={styles.modalContainer}>
                 <CheckCircleIcon />
                <div className={styles.titleText}>
                    {name.length > 0 && name.map((item) => {
                        return (<p className={styles.fileName}>{item.file_name}</p>)
                    })}
                    <p>{title}</p>
                </div>
                <div className={styles.descriptionText}>
                    {description}
                </div>
            </div>
        </Modal>
    );
}
export default ModalWarring;