import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type ICreditCard = {
  cardNumber: string;
  cardCCV: string;
  cardExpiryDate: string;
  cardName: string;
};

type CreditcardState = {
  config: ICreditCard;
  loading: boolean;
};

const initialValues: CreditcardState = {
  config: {
    cardNumber: "",
    cardCCV: "",
    cardExpiryDate: "",
    cardName: "",
  },
  loading: false,
};

const creditcardSlice = createSlice({
  name: "creditcard",
  initialState: initialValues,
  reducers: {
    setCardNumber: (state: CreditcardState, data: PayloadAction<string>) => {
      state.config.cardNumber = data.payload;
    },
    setCardCCV: (state: CreditcardState, data: PayloadAction<string>) => {
      state.config.cardCCV = data.payload;
    },
    setCardExpiryDate: (
      state: CreditcardState,
      data: PayloadAction<string>
    ) => {
      state.config.cardExpiryDate = data.payload;
    },
    setCardName: (state: CreditcardState, data: PayloadAction<string>) => {
      state.config.cardName = data.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCardNumber, setCardCCV, setCardExpiryDate, setCardName } =
  creditcardSlice.actions;
export const creditcardSelector = (store: RootState) => store.creditcardReducer;
export default creditcardSlice.reducer;
