import React from "react";

const StoryEditEntGenPageImage = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 14"
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4779_6092)">
        <path
          d="M11.0833 0H2.91667C2.1434 0.00092625 1.40208 0.308514 0.855295 0.855295C0.308514 1.40208 0.00092625 2.1434 0 2.91667L0 11.0833C0.00092625 11.8566 0.308514 12.5979 0.855295 13.1447C1.40208 13.6915 2.1434 13.9991 2.91667 14H11.0833C11.8566 13.9991 12.5979 13.6915 13.1447 13.1447C13.6915 12.5979 13.9991 11.8566 14 11.0833V2.91667C13.9991 2.1434 13.6915 1.40208 13.1447 0.855295C12.5979 0.308514 11.8566 0.00092625 11.0833 0V0ZM2.91667 1.16667H11.0833C11.5475 1.16667 11.9926 1.35104 12.3208 1.67923C12.649 2.00742 12.8333 2.45254 12.8333 2.91667V11.0833C12.8323 11.3432 12.7725 11.5995 12.6583 11.8329L7.31325 6.48783C7.0424 6.21691 6.72084 6.00201 6.36692 5.85538C6.013 5.70876 5.63367 5.63329 5.25058 5.63329C4.8675 5.63329 4.48816 5.70876 4.13425 5.85538C3.78033 6.00201 3.45876 6.21691 3.18792 6.48783L1.16667 8.5085V2.91667C1.16667 2.45254 1.35104 2.00742 1.67923 1.67923C2.00742 1.35104 2.45254 1.16667 2.91667 1.16667ZM2.91667 12.8333C2.45254 12.8333 2.00742 12.649 1.67923 12.3208C1.35104 11.9926 1.16667 11.5475 1.16667 11.0833V10.1582L4.01217 7.31267C4.17468 7.15005 4.36765 7.02104 4.58004 6.93302C4.79244 6.84501 5.02009 6.79971 5.25 6.79971C5.47991 6.79971 5.70756 6.84501 5.91996 6.93302C6.13235 7.02104 6.32532 7.15005 6.48783 7.31267L11.8329 12.6583C11.5995 12.7725 11.3432 12.8323 11.0833 12.8333H2.91667Z"
          fill="#374957"
        />
        <path
          d="M9.33073 6.1224C9.73453 6.1224 10.1293 6.00266 10.465 5.77831C10.8008 5.55397 11.0625 5.23511 11.217 4.86204C11.3715 4.48898 11.4119 4.07847 11.3332 3.68242C11.2544 3.28638 11.0599 2.92259 10.7744 2.63705C10.4889 2.35152 10.1251 2.15707 9.72904 2.07829C9.33299 1.99952 8.92248 2.03995 8.54942 2.19448C8.17635 2.349 7.85749 2.61069 7.63315 2.94644C7.4088 3.28219 7.28906 3.67693 7.28906 4.08073C7.28906 4.62221 7.50417 5.14152 7.88705 5.52441C8.26994 5.90729 8.78925 6.1224 9.33073 6.1224ZM9.33073 3.20573C9.50379 3.20573 9.67296 3.25705 9.81685 3.35319C9.96075 3.44934 10.0729 3.586 10.1391 3.74588C10.2054 3.90577 10.2227 4.0817 10.1889 4.25143C10.1552 4.42117 10.0718 4.57708 9.94945 4.69945C9.82708 4.82182 9.67117 4.90516 9.50143 4.93892C9.3317 4.97268 9.15577 4.95535 8.99588 4.88912C8.836 4.8229 8.69934 4.71075 8.60319 4.56685C8.50705 4.42296 8.45573 4.25379 8.45573 4.08073C8.45573 3.84867 8.54792 3.62611 8.71201 3.46201C8.87611 3.29792 9.09866 3.20573 9.33073 3.20573Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_4779_6092">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default StoryEditEntGenPageImage;
