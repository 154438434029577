import { Checkbox, CheckboxProps, Input } from "antd";
import React, { useState } from "react";
import styles from "./DataListConfig.module.scss";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import StoryEditEntEdit from "src/assets/svg/story-edit-ent-edit";
import { DataListType } from "src/types/MoreSettingType";

type UpdateRowDataProps = {
  id: string;
  column_name: string;
  column_value: string;
  default_check: string;
};
type Props = {
  dataListItem: DataListType;
  onRemoveItem: (id: string) => void;
  onUpdate: ({
    id,
    column_name,
    column_value,
    default_check,
  }: UpdateRowDataProps) => void;
};

function DataListConfigItem({ dataListItem, onRemoveItem, onUpdate }: Props) {
  let [isDefaultCheck, setIsDefaultCheck] = useState<boolean>(
    dataListItem.isDefaultCheck
  );
  let [inputValue, setInputValue] = useState<string>(dataListItem.showValue);
  let [showName, setShowName] = useState<string>(dataListItem.showName);
  let [isEdit, setIsEdit] = useState<boolean>(false);

  const onChangeInputValueHandler = (input: string) => {
    setInputValue(input);
  };
  const onChangeShowNameHandler = (input: string) => {
    setShowName(input);
  };
  const onClickIsEditHandler = () => {
    if (isEdit) {
      onUpdate({
        id: dataListItem.id as string,
        column_name: showName,
        column_value: inputValue,
        default_check: isDefaultCheck.toString(),
      });
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };
  const onChangeDefaultCheckHandler: CheckboxProps["onChange"] = (e) => {
    let isCheck = e.target.checked;
    setIsDefaultCheck(isCheck);
  };
  const onClickDeleteDataListHandler = (id: string) => {
    onRemoveItem(id);
  };
  return (
    <tr key={dataListItem.id}>
      <td>
        {isEdit ? (
          <Input
            value={inputValue}
            className={styles.searchInput}
            onChange={(e) => onChangeInputValueHandler(e.currentTarget.value)}
          />
        ) : (
          inputValue
        )}
      </td>
      <td>
        {isEdit ? (
          <Input
            value={showName}
            className={styles.searchInput}
            onChange={(e) => onChangeShowNameHandler(e.currentTarget.value)}
          />
        ) : (
          showName
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <Checkbox
          checked={isDefaultCheck}
          onChange={onChangeDefaultCheckHandler}
        />
      </td>
      <td
        className={styles.tdSetCenter}
        style={{ display: "flex", gap: "5px" }}
      >
        <div
          className={styles.actionIconWrapper}
          onClick={() => onClickIsEditHandler()}
        >
          <StoryEditEntEdit className={styles.actionIcon} />
        </div>
        <div
          className={styles.actionIconWrapper}
          onClick={() =>
            onClickDeleteDataListHandler(dataListItem.id as string)
          }
        >
          <StoryEditEntDelete className={styles.actionIcon} />
        </div>
      </td>
    </tr>
  );
}

export default DataListConfigItem;
