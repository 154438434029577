import React, { Component, useEffect, useState, useContext } from "react";
import styles from "./EditBoModal.module.scss";
import { TitleProps } from "antd/es/typography/Title";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import StoryEditEntEdit from "src/assets/svg/story-edit-ent-edit";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import { MappingParam } from "src/shared/MappingParam/MappingParam";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { Button, Modal } from "antd";
import CreateBoModal from "../modal-create-bo/CreateBoModal";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import EditBoMapping from "./components/EditBoMapping";
import {
  EntityBoMapping,
  GetEntityBoByIdResponse,
} from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import { UPDATE_ENTITY_BO_BY_ID_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";
import { MARGIN_LIST } from "src/utils/create-bo/createBo.util";
import { CreateBoType } from "src/types/BoMappingType";
import { checkEmpty } from "src/services/util.service";

const mappingParam = new MappingParam();

type Props = {
  boId: string;
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const UPDATE_ENTITY_BO_FK = "updateEntBoFK";

function EditBoModal({ boId, isModalOpen, onOk, onCancel }: Props) {
  const { loading, error, responseData, fetchData, fetchKey } =
    useFetch<GetEntityBoByIdResponse>();
  //   let [isOpenEditBoModal, setIsOpenEditBOModal] = useState<boolean>(false);
  let [isOpenCreateBoModal, setIsOpenCreateBoModal] = useState<boolean>(false);
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  let [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  let [nodeSelected, setNodeSelected] = useState<any>(null);
  let [isRerender, setIsRerender] = useState<boolean>(false);
  let [updateBoReq, setUpdateBoReq] = useState<EntityBoMapping | undefined>(
    undefined
  );


  useEffect(() => {
    console.log("isModalOpen => ", isModalOpen)
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
    setIsRerender(!isRerender);
  }, [isModalOpen]);

  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) return;

      console.log("fetchKey => ", fetchKey);
      switch (fetchKey) {
        case UPDATE_ENTITY_BO_FK: {
          let respUpdateEntBo = responseData?.data;
          console.log("UPDATE_ENTITY_BO_FK resp => ", respUpdateEntBo);
          onCancel();
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //TOP PART MODAL
  const TitleContent = ({ title }: TitleProps) => {
    const onClickAddBoHandler = () => {
      let nodeSelected = mappingParam.getNodeSelect();
      if (nodeSelected) {
        setNodeSelected(nodeSelected);
        setIsCreateMode(true);
        setIsOpenCreateBoModal(true);
      }
    };
    const onClickEditBoHandler = () => {
      let nodeSelected = mappingParam.getNodeSelect();
      if (nodeSelected) {
        setNodeSelected(nodeSelected);
        setIsCreateMode(false);
        setIsOpenCreateBoModal(true);
      }
    };
    const onClickDeleteBoHandler = () => {
      const nodeToDelete = mappingParam.getNodeSelect();
      if (nodeToDelete && !nodeToDelete.isGroup) {
        mappingParam.removeTreeNodeBySelectionNode();
      }
    };

    return (
      <div className={styles.createTitleModal}>
        <div className={styles.title}>
          <span>{title}&nbsp;&nbsp;</span>
        </div>
        <div className={styles.actionTool}>
          <div
            className={styles.actionIconWrapper}
            onClick={onClickAddBoHandler}
          >
            <StoryEditEntAdd className={styles.actionIcon} />
          </div>
          <div
            className={styles.actionIconWrapper}
            onClick={onClickEditBoHandler}
          >
            <StoryEditEntEdit className={styles.actionIcon} />
          </div>
          <div
            className={styles.actionIconWrapper}
            onClick={onClickDeleteBoHandler}
          >
            <StoryEditEntDelete className={styles.actionIcon} />
          </div>
        </div>
      </div>
    );
  };

  //ACTION HANDLER
  const onCancelEditBOModal = () => {
    onCancel();
  };

  //BOTTOM ACTION HANDLER
  const onClickSaveBoHandler = () => {
    let boMapping = mappingParam.getStateDiagramJson();
    let jsonBoMapping = JSON.stringify(boMapping);
    let id = updateBoReq?.id;
    // console.log("onClickSaveBoHandler boMapping => ", boMapping);
    // console.log("onClickSaveBoHandler updateBoReq => ", updateBoReq);
    let updateReq: EntityBoMapping = {
      ...(updateBoReq as EntityBoMapping),
      json_mapping_bo: jsonBoMapping,
    };

    getDBConnectByStoryIdController(id as string, updateReq);
  };

  //BO MAPPING HANDLER
  const updateBoMapping = (req: EntityBoMapping) => {
    setUpdateBoReq(req);
  };
  const isDataTypeObject = (dataType?: string): boolean => {
    return dataType === "Table" || dataType === "Object" ? true : false;
  };

  const onOkCreateBo = (
    parentKey?: string,
    createBoFields?: CreateBoType[]
  ) => {
    let layerOfParent = mappingParam.getLayerByParentField(parentKey as string);
    let margin = MARGIN_LIST[layerOfParent as number];
    let parent = mappingParam.getParamByKey(parentKey as string);


    let newNodeDataArray = [];
    let newLinkDataArray = [];
    for (const createBoField of createBoFields as CreateBoType[]) {
      let dataType = createBoField.dataType;
      let fieldName = createBoField.fieldName;
      let group = createBoField.group;
      let pathName = fieldName;
      if (!checkEmpty(parent.pathName)) {
        pathName = parent.pathName + "." + fieldName;
      }


      let newNodeData = {
        paramName: fieldName,
        pathName: pathName,
        margin,
        typeDesc: dataType,
        isList: false,
        parentKey: parentKey,
        category: isDataTypeObject(dataType) ? "nodeIsGroup" : "nodeIsNotGroup",
        isGroup: false,
        key: createBoField.itemId,
        group: group,
        isPrimaryKey: createBoField.isPrimaryKey,
        foreignKey: createBoField.foreignKey
      };
      let link = mappingParam.getLinkByFromToKey(parentKey as string, createBoField.itemId as string)
      if (checkEmpty(link)) {
        newLinkDataArray.push({ from: parentKey, to: createBoField.itemId })
      }
      newNodeDataArray.push(newNodeData);
    }
    mappingParam.addNewNodeInParentSelectionWithList(
      parentKey as string,
      newNodeDataArray,
      newLinkDataArray
    );
    setIsOpenCreateBoModal(false);
  };

  const onOkEditBo = (createBoFields: CreateBoType[]) => {
    for (const cb of createBoFields) {
      let nodeEditSelected = {
        ...nodeSelected,
        paramName: cb.fieldName,
        typeDesc: cb.dataType,
        isPrimaryKey: cb.isPrimaryKey,
        foreignKey: cb.foreignKey
      };
      mappingParam.updateNodeInParentSelection(nodeEditSelected);
    }

    setIsOpenCreateBoModal(false);
  };

  const onCancelCreateAndEditBo = () => {
    setIsOpenCreateBoModal(false);
  };

  //CONFIRM DELETE MODAL
  const onConfirmDeleteHandler = () => { };
  const onCancelDeleteHandler = () => { };

  //API CONTROLLER FUNCTION
  const getDBConnectByStoryIdController = (
    id: string,
    req: EntityBoMapping
  ) => {
    fetchData(
      {
        endpoint: UPDATE_ENTITY_BO_BY_ID_ENDPOINT,
        method: "PATCH",
        params: {
          id,
        },
        reqBody: req,
      },
      UPDATE_ENTITY_BO_FK
    );
  };

  return (
    <>
      <Modal
        title={<TitleContent title={"Update Business Object"} />}
        footer={
          <div className={styles.createFooterModal}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="save"
              onClick={onClickSaveBoHandler}
              type="primary"
            >
              Save
            </Button>
          </div>
          // )
        }
        open={isModalOpen}
        // onOk={onOkModal}
        onCancel={onCancelEditBOModal}
        centered={true}
        width={"80%"}
      >
        <span className={styles.createContentModal}>
          <EditBoMapping
            boId={boId}
            mappingParam={mappingParam}
            isRerender={isRerender}
            updateBoMapping={updateBoMapping}
            isEditMode={true}
          />
        </span>
      </Modal>
      <CreateBoModal
        modalDetail={{ title: "Create Business Object" }}
        isCreateMode={isCreateMode}
        isModalOpen={isOpenCreateBoModal}
        nodeSelected={nodeSelected}
        onOkCreate={onOkCreateBo}
        onOkEdit={onOkEditBo}
        onCancelCreateAndEditBo={onCancelCreateAndEditBo}
        mappingParam={mappingParam}
      />

      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Field?",
          description: `Are you sure you want to delete this field? This action cannot be
    undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onConfirmDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />
    </>
  );
}

export default EditBoModal;
