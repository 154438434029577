export let jsTextListsMain = `

{_JSONCONFIG_SCREEN_}

let ACTION_TYPE = {
  ACTION_SELECT_ALL_MAIN: "select_all_main",
  ACTION_SELECT_MAIN: "select_main",
  ACTION_INSERT_MAIN: "insert_main",
  ACTION_UPDATE_MAIN: "update_main",
  ACTION_DELETE_MAIN: "delete_main",
}

let FILE_HUB_URL_IMAGE = JSON_CONFIG_SCREEN.config.filehubUrlImage ? JSON_CONFIG_SCREEN.config.filehubUrlImage : '';
const SCREENS_EDIT_WITH_POSITION = JSON_CONFIG_SCREEN.screensEdit.filter(screen => screen.rownumber && screen.columnnumber);

const LIMIT = 10;
let TOTAL_PAGE = 0;
let CURRENT_PAGE = 1;

async function renderListContainer(screen) {
  const mainContainer = document.getElementById('mainContainer');
  const screenElement = document.getElementById('screenElement-' + screen.configType + '-' + screen.key);

  const listContainer = document.getElementById('list-container-' + screen.configType + '-' + screen.key);
  const listContent = document.getElementById('list-content-' + screen.configType + '-' + screen.key);
 
}

function MyComponent(JSON_CONFIG_SCREEN) {
  const screensWithPosition = JSON_CONFIG_SCREEN.screens.filter(screen => screen.rownumber && screen.columnnumber);

  screensWithPosition.forEach(screen => {
    renderListContainer(screen)
  });
}



function transformBoData(bo, input) {
  const output = {};

  console.log("bo", bo);
  const filteredBo = JSON_CONFIG_SCREEN.bo.filter(item => item.boName === bo);

  console.log("filteredBo", filteredBo);

  filteredBo.forEach(item => {
    const boName = item.boName;
    const fields = item.fields;
    const boObject = {};

    fields.forEach(field => {
      const fieldName = field.fieldName;
      if (input.hasOwnProperty(fieldName)) {
        boObject[fieldName] = input[fieldName];
      }else {
        if (field.type == "Object") {
          boObject[fieldName] = null;
        }else {
          boObject[fieldName] = "";
        }
      }
    });

    output[boName] = boObject;
  });
  console.log("output:: ", output)
  return output;
}


function getPrimaryKeyColumns(jsonConfig) {
  const primaryKeyColumns = [];

   // let mainBoName = jsonConfig.bo[0].boName;

  let primaryKeyColumnBo = [];
  let pathColumn;
  let pathFrom;
  jsonConfig.table.forEach(table => {
    table.columns.forEach(column => {
      if (column.primaryKey) {
        pathColumn = table.tableName + "." + column.column

        jsonConfig.mapping.forEach(mapping => {
          if (mapping.pathTo == pathColumn && mapping.pathFrom.split(".").length == 2) {
            pathFrom = mapping.pathFrom
          }
        })

      }
    });
  });

  

  let pathFroms = pathFrom.split(".");
  if (pathFroms.length > 0)
    primaryKeyColumnBo.push(pathFroms[pathFroms.length - 1])

  return primaryKeyColumnBo;
}



async function fetchDataFromServer(action, inputObject) {
  return new Promise((resolve, reject) => {
    try {
      let flow = JSON_CONFIG_SCREEN.flow;
      let config = JSON_CONFIG_SCREEN.config;
      let flowNameV = flow.flowName;
      let appName = flow.appName;
      let uniqId = flow.flowId;
      let snapVersion = flow.snapVersion;
      let MFR_URL = config.microFlowUrl;
      let inputname = flowNameV + "_flow_input";
      let object = {};
      object[inputname] = inputObject;
      object[inputname].action = action;
      let body = {
        flowName: flowNameV,
        appName: appName,
        snapVersion: snapVersion,
        uniqueId: uniqId,
        object: object,
      };

      $.ajax({
        url: MFR_URL,
        type: "POST",
        data: JSON.stringify(body),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data) {
          let map = data["responseObjectsMap"];
          let out = flowNameV + "_flow_output";
          let responseObj = map[out];

          if (responseObj === null) {
            reject(new Error('responseObj is null'));
          } else {
            resolve(responseObj);
          }
        },
        catch: function (e) {
          reject(e);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

async function getSelectMain(data, screenKey) {
  let key = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  let input = { [key]: data[key] }
  const inputObject = { parameter: transformBoData(screenKey, input) };
  try {
    const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_MAIN, inputObject);
    return response[screenKey];
  } catch (error) {
    console.error(error);
  }
}

async function handleClickRemove($page,event) {
  
  let index = $page.$index
  let key = "{{SCREEN_KEY}}";
  let data = $page.item
  
  let dataValue = await getSelectMain(data, key);
  const inputObject = { parameter: transformBoData(key, data) };
  try {
    await fetchDataFromServer(ACTION_TYPE.ACTION_DELETE_MAIN, inputObject);
    let screen = null;
    for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
      if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
        screen = JSON_CONFIG_SCREEN.screens[a]
      }
    }

    const card = document.getElementById('card-' + screen.configType + "-" + screen.key + "-" + index);
    console.log("valuePath>>>>>>>>>> card", card);

    if (card) {
      card.remove();
    }

  } catch (error) {
    console.error(error);
  }
}

function initLoad($page,event) {
    myScope["SCOPE_URL_IMAGE"] = JSON_CONFIG_SCREEN.config.filehubUrlImage;
    myScope.update();
    MyComponent(JSON_CONFIG_SCREEN);  
}


async function afterLoadMFD($page,event) {
 
  let screen = null;
  for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
    if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
      screen = JSON_CONFIG_SCREEN.screens[a]
    }
  }
  TOTAL_PAGE = $page.Store.{{FLOW_NAME}}.{{FLOW_NAME}}_flow_output.total;
  await paginationContainer(screen,$page);

  addFlexTextClassIfLong();
}

function clickEditItemFn($page,event) {
  console.log("===clickEditItemFn===")
  console.log("$page",$page)
  console.log("event",event)
  // go to edit
  // {{CLICK_EDIT}}
  let key = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  let refId = $page.item[key]
  // CURRENT_PAGE = 1;
  myScope.pageGo("{{PAGE_NAME_EDIT}}",{refId:refId})
  
}

function clickDeleteItemFn($page,event) {
  console.log("===clickDeleteItemFn===")
  console.log("$page",$page)
  console.log("event",event)
  handleClickRemove($page,event)
}



async function paginationContainer(mainScreen,$page) {
  const numberPages = Math.ceil(TOTAL_PAGE / LIMIT);
  const paginationButtonContent = document.getElementById('pagination-button-' + mainScreen.configType + '-' + mainScreen.key);
  const nextButton = document.getElementById('next-button-' + mainScreen.configType + '-' + mainScreen.key);
  const previousButton = document.getElementById('previous-button-' + mainScreen.configType + '-' + mainScreen.key);

  const ellipsSpanStart = document.getElementById('pagination-span-start');
  const ellipsSpanEnd = document.getElementById('pagination-span-end');
  if (ellipsSpanStart) ellipsSpanStart.remove();
  if (ellipsSpanEnd) ellipsSpanEnd.remove();

  // ลบปุ่มหน้าที่มีอยู่ (ยกเว้นปุ่ม "Previous" และ "Next")
  const existingPageButtons = paginationButtonContent.querySelectorAll('button[id^="page-button-"]');
  existingPageButtons.forEach(button => button.remove());


  for (let i = 1; i <= numberPages; i++) {
    // เงื่อนไขในการแสดงปุ่ม
    if (
      numberPages <= 5 || // จำนวนหน้าทั้งหมด <= 5
      i === 1 || // หน้าแรก
      i === numberPages || // หน้าสุดท้าย
      (i >= CURRENT_PAGE - 1 && i <= CURRENT_PAGE + 1) // หน้าปัจจุบัน หน้าก่อนหน้า และหน้าถัดไป
    ) {
      // สร้างปุ่มเพจิเนชั่น
      const pageButton = document.createElement('button');
      pageButton.id = 'page-button-' + mainScreen.configType + '-' + mainScreen.key + '-' + i;
      pageButton.className = 'pagination-button';
      pageButton.textContent = i;

      // เพิ่มคลาสสำหรับหน้าปัจจุบัน
      if (i === CURRENT_PAGE) {
        pageButton.classList.add('current-page');
      }

      // เพิ่ม event listener สำหรับคลิกปุ่ม
      pageButton.addEventListener('click', async () => {
        CURRENT_PAGE = i; // อัพเดทหน้าปัจจุบัน
        paginationContainer(mainScreen,$page); // อัพเดทคอนเทนต์ของหน้า
        console.log("Clicked pageButton: ", i); // ล็อกการคลิกปุ่ม

        $("#{{PAGE_CONTENT_ID}}").trigger("load");

      }, false);

      // แทรกปุ่มเข้าไปในคอนเทนต์ของปุ่มหน้าก่อนปุ่มถัดไป
      paginationButtonContent.insertBefore(pageButton, nextButton);
    }
    // เงื่อนไขในการแสดงจุดไข่ปลา
    else if (
      (i === CURRENT_PAGE - 2 && CURRENT_PAGE >= 4) || // จุดไข่ปลาเริ่มต้นถ้าหน้าปัจจุบันเกิน 4
      (i === CURRENT_PAGE + 2 && CURRENT_PAGE < numberPages - 3) // จุดไข่ปลาสิ้นสุดถ้าหน้าปัจจุบันน้อยกว่าหน้าสุดท้าย - 3
    ) {
      // สร้างจุดไข่ปลา
      const ellipsisSpan = document.createElement('span');
      ellipsisSpan.id = i < CURRENT_PAGE ? 'pagination-span-start' : 'pagination-span-end';
      ellipsisSpan.textContent = '...';
      ellipsisSpan.disabled = true; // ทำให้ไม่สามารถคลิกได้

      // แทรกจุดไข่ปลาเข้าไปในคอนเทนต์ของปุ่มหน้าก่อนปุ่มถัดไป
      paginationButtonContent.insertBefore(ellipsisSpan, nextButton);
    }
  }
  console.log("currentPage: ", CURRENT_PAGE);

  previousButton.hidden = CURRENT_PAGE === 1;
  nextButton.hidden = CURRENT_PAGE === numberPages;

  // ลบ event listeners ที่มีอยู่เพื่อหลีกเลี่ยงการเพิ่ม listeners หลายตัว
  const newNextButton = nextButton.cloneNode(true);
  nextButton.parentNode.replaceChild(newNextButton, nextButton);

  const newPreviousButton = previousButton.cloneNode(true);
  previousButton.parentNode.replaceChild(newPreviousButton, previousButton);

  // เพิ่ม event listener ให้กับปุ่ม Next
  newNextButton.addEventListener('click', async () => {
    if (CURRENT_PAGE < numberPages) {
      CURRENT_PAGE++; // เพิ่มหน้าปัจจุบัน
      paginationContainer(mainScreen,$page); // รีเฟรชการแบ่งหน้า
      console.log("click nextButton: ", CURRENT_PAGE);
      $("#{{PAGE_CONTENT_ID}}").trigger("load");

    }
  }, false);

  // เพิ่ม event listener ให้กับปุ่ม Previous
  newPreviousButton.addEventListener('click', async () => {
    if (CURRENT_PAGE > 1) {
      CURRENT_PAGE--; // ลดหน้าปัจจุบัน
      paginationContainer(mainScreen,$page); // รีเฟรชการแบ่งหน้า
      console.log("click previousButton: ", CURRENT_PAGE);

      $("#{{PAGE_CONTENT_ID}}").trigger("load");
    }
  }, false);
}


function handleGotoAddPage($page,event){
  // CURRENT_PAGE = 1;
  // {{CLICK_ADD}}
}

function beforeLoadMFD($page,event){

  let mainScreen = null;
  for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
    if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
      mainScreen = JSON_CONFIG_SCREEN.screens[a]
    }
  }

  let offset = LIMIT * (CURRENT_PAGE - 1);
  $page.State.offset = offset;
  $page.State.action = "select_all_main";
  $page.update();
}

function beforeSearchMFD($page,event){
  CURRENT_PAGE = 1;
  let offset = LIMIT * (CURRENT_PAGE - 1);
  $page.State.offset = offset;
  $page.State.action = "select_all_main";
  $page.update();
}

function addFlexTextClassIfLong() {
const textContainers = document.querySelectorAll('.list-text .text-container');
  textContainers.forEach(container => {
    const span = container.querySelector('span');
    if (span && span.textContent.length > 50) {
      container.classList.add('flex-text');
    }
  });
}



`