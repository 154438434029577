import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import React, { useEffect } from 'react';
import styles from "./PaymentsPage.module.scss";
import Button from '@mui/material/Button';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Stack from '@mui/material/Stack';
import { useAppDispatch } from 'src/store/store';
import { useSelector } from 'react-redux';
import { paymentSelector } from 'src/store/slices/paymentSlice';
import { creditcardSelector } from 'src/store/slices/creditcardSlice';
import SummaryForm from './components/SummaryForm';
import { reBackStepper } from 'src/store/slices/paymentStepperSlice';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './components/CheckoutForm';

//@ts-ignore
let stripeKey = window['env']['REACT_APP_STRIPE_KEY'] 
console.log("stripeKey",stripeKey)
export const stripePromise = loadStripe(stripeKey);

export default function PaymentsPage() {

    const dispath = useAppDispatch();
    const paymentReducer = useSelector(paymentSelector);
    const creditcardReducer = useSelector(creditcardSelector);

    useEffect(() => {

    }, [paymentReducer, creditcardReducer])

    return (
        <Grid sx={{
            flexGrow: 1,
            paddingX: '50px',
            paddingTop: '50px',
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
        }} container>
            {/* <Grid item xs={12} > */}
            <Grid>
                <Grid container spacing={8} sx={{ justifyContent: 'center' }}>
                    <Grid >
                        <Paper
                            sx={(theme) => ({
                                width: 800,
                                boxShadow: 'none',
                            })}
                        >
                            <Elements stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        </Paper>
                    </Grid>
                    <Grid >
                        <Paper
                            sx={(theme) => ({
                                width: 350,
                                boxShadow: 'none',
                            })}
                        >
                            <p className={styles.textHeader}>Summary</p>
                            <SummaryForm />
                        </Paper>
                    </Grid>
                </Grid>
                <Stack spacing={2} direction="row" sx={{
                    flexGrow: 1, paddingX: '100px',
                    paddingTop: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowLeftIcon />}
                        sx={{ width: '250px' }}
                        onClick={() => {
                            dispath(reBackStepper());
                        }}
                    >
                        BACK
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<ArrowRightIcon />}
                        sx={{ width: '250px' }}
                        disabled={
                            paymentReducer.paymentMthod === '' ||
                            creditcardReducer.config.cardNumber === '' ||
                            creditcardReducer.config.cardCCV === '' ||
                            creditcardReducer.config.cardExpiryDate === '' ||
                            creditcardReducer.config.cardName === ''
                        }
                        onClick={() => {
                            console.log(":::", paymentReducer.paymentMthod);
                            console.log(":::", creditcardReducer.config);
                        }}
                    >
                        CONTINUE
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
