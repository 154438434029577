import React, { useState, useEffect } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, message, Upload } from "antd";
import type { GetProp, UploadProps } from "antd";
import styles from "./ModalUpload.module.scss";
import { callApiMethodPost, callApiMethodGet } from "src/services/util.service";
import {
  UPLOAD_FILE_APP_COMPONENT_ENDPOINT,
  GET_FILE_BY_ID_APP_COMPONENT_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const base64toFile = (base64String: string, fileName: string) => {
  // Split the base64 string to get the content type and the data
  const [, content] = base64String.split(";base64,");

  // Convert the base64 content to binary format
  const byteCharacters = atob(content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob object from the binary data
  const blob = new Blob([byteArray], { type: "image/jpeg" }); // Change the type according to your image format

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: "image/jpeg" }); // Change the type according to your image format

  return file;
};

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

interface Props {
  isOpenModalUploadLogo: boolean;
  onOk: (uploadID: string) => void;
  onCancel: () => void;
  detail: { titleName: string; fileID: string; type: string };
}

function ModalUpload({ isOpenModalUploadLogo, onOk, onCancel, detail }: Props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [loadingButtonContinue, setLoadingButtonContinue] =
    useState<boolean>(false);

  useEffect(() => {
    if (isOpenModalUploadLogo) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
    APIGetFileByID();
    setImageUrl("");
    setLoadingButtonContinue(false);
  }, [isOpenModalUploadLogo]);

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const APICreateFile = async () => {
    try {
      const formData = new FormData();
      formData.append("appid", detail.fileID);
      formData.append("type", detail.type);
      formData.append(
        "file",
        base64toFile(imageUrl, detail.titleName + ".jpg")
      );

      const response = await callApiMethodPost(
        UPLOAD_FILE_APP_COMPONENT_ENDPOINT,
        formData
      );

      if (response.status == 200) {
        onOk(response.data.id);
        setLoadingButtonContinue(false);
      }
    } catch (error) {
      console.log("error upload file : ", error);
      onCancel();
    }
  };

  const APIGetFileByID = async () => {
    try {
      const response = await callApiMethodGet(
        GET_FILE_BY_ID_APP_COMPONENT_ENDPOINT,
        { file_id: detail.fileID }
      );

      if (response.status == 200) {
        setImageUrl(`data:image/jpeg;base64,${response.data.path}`);
      }
    } catch (error) {
      console.log("error upload file : ", error);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const onClickConfirm = () => {
    APICreateFile();
    setLoadingButtonContinue(true);
  };

  const Footer = () => {
    return (
      <div className={styles.footerContainer}>
        <button className={styles.btnCancel} onClick={onCancel}>
          Cancel
        </button>
        <button className={styles.btnCreate} onClick={onClickConfirm}>
          Continue
        </button>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={<div>{detail.titleName}</div>}
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancel}
              type="text"
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="ok"
              onClick={onClickConfirm}
              type="primary"
              loading={loadingButtonContinue}
            >
              Continue
            </Button>
          </div>
        }
        open={isOpenModalUploadLogo}
        onCancel={onCancel}
        centered
        width={400}
      >
        <div className={styles.modalContent}>
          <Upload
            name="avatar"
            listType="picture-card"
            className={styles.uploadContent}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "50%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </Modal>
    </div>
  );
}

export default ModalUpload;
