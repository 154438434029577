
import React from "react";

const WarningIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
    <svg 
    width={width||"20"}
    height={height || "20"} 
    viewBox="0 0 229 228" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <circle cx="114.5" cy="114" r="114" fill="#FFF8EC" />
        <circle cx="114.5" cy="114" r="82" fill="#FFDFA0" />
        <circle cx="114.5" cy="114" r="41" fill="#FFBE41" />
    </svg>);
}

export default WarningIcon;
