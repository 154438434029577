import { Button, Checkbox, CheckboxProps, Input } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./DataListConfig.module.scss";
import "./DataListConfig.scss";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import { v4 as uuidv4 } from "uuid";
import ConfirmDeleteModal from "../../../../ConfirmDeleteModal";
import DataListConfigItem from "./DataListConfigItem";
import { DataListType } from "src/types/MoreSettingType";

type Props = {
  onUpdate: (newItems: DataListType[]) => void;
  reRender: boolean;
  data: DataListType[];
};

type UpdateDataListProps = {
  id: string;
  column_name: string;
  column_value: string;
  default_check: string;
}

function DataListConfig({ onUpdate, reRender, data }: Props) {
  let [dataListItems, setDataListItems] = useState<DataListType[]>([]);
  let [inputValue, setInputValue] = useState<string>("");
  let [showName, setShowName] = useState<string>("");
  let [isDefaultCheck, setIsDefaultCheck] = useState<boolean>(false);
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  let [itemToRemove, setItemToRemove] = useState<string | null>(null);

  useEffect(() => {
    if (data.length > 0) {
      setDataListItems(data);
      onUpdate(data);
    } else {
      setDataListItems([]);
    }
  }, [reRender]);

  //DEFAULT ROW
  const onChangeInputValue = (value: string) => {
    setInputValue(value);
  };
  const onChangeShowName = (value: string) => {
    setShowName(value);
  };
  const onChangeIsDefaultCheck: CheckboxProps["onChange"] = (e: any) => {
    let isDefault = e.target.checked;
    setIsDefaultCheck(isDefault);
  };
  const onAddNewItemHandler = () => {
    if (!isPassValidateAddItem()) return;
    addDropdownDataList({ showValue: inputValue, showName, isDefaultCheck });
  };
  const clearDefaultRow = () => {
    setInputValue("");
    setShowName("");
    setIsDefaultCheck(false);
  };

  //DELETE MODAL
  const onDdDataListItemRemove = (id: string) => {
    setIsOpenDeleteModal(true);
    setItemToRemove(id);
  };
  const onOkConfirmDeleteHandler = () => {
    setIsOpenDeleteModal(false);

    if (!itemToRemove) return;
    removeDropdownDataList();
  };
  const onCancelConfirmDeleteHandler = () => {
    setIsOpenDeleteModal(false);
    setItemToRemove(null);
  };

  //ACTION LISTENER
  const onUpdateDataListItem = ({
    id,
    column_name,
    column_value,
    default_check,
  }: UpdateDataListProps) => {
    console.log(`id: ${id}, column_name: ${column_name}, column_value: ${column_value}, default_check: ${default_check}`)
    let newDataListItems = dataListItems.map((item) => {
      if(item.id === id) {
        return {
          ...item,
          showName: column_name,
          showValue: column_value,
          isDefaultCheck: Boolean(default_check)
        }
      }else {
        return item
      }
    })
    setDataListItems(newDataListItems);
    onUpdate(newDataListItems);
  };

  //UTILITY FUNCTION
  const removeDropdownDataList = () => {
    let newDataListItems = [...dataListItems].filter(
      (item) => item.id !== itemToRemove
    );
    setDataListItems(newDataListItems);
    onUpdate(newDataListItems);
    setItemToRemove(null);
  };
  const addDropdownDataList = ({
    showValue,
    showName,
    isDefaultCheck,
  }: DataListType) => {
    const id = uuidv4();
    let newDataListItem: DataListType = {
      id,
      showValue,
      showName,
      isDefaultCheck,
    };
    let newDataListItems = [...dataListItems, newDataListItem];
    setDataListItems(newDataListItems);
    onUpdate(newDataListItems);
    clearDefaultRow();
  };
  const isPassValidateAddItem = () => {
    let textError = "";
    if (!inputValue) textError += "Please input Value! ";
    if (!showName) textError += "Please input ShowName! ";

    let hasThisItem = dataListItems.some((item) => item.showValue === inputValue);
    if (hasThisItem) textError += "This item already added!";

    return textError ? false : true;
  };

  return (
    <>
      {/* CONFIRM DELETE MODAL */}
      <table id="DropdownDataListTb">
        <thead>
          <tr>
            <th>Value</th>
            <th style={{ textAlign: "center" }}>Show Name</th>
            <th style={{ textAlign: "center" }}>Default Check</th>
            <th></th>
            {/* <th>Search Criteria</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Input
                value={inputValue}
                className={styles.searchInput}
                onChange={(e) => onChangeInputValue(e.currentTarget.value)}
              />
            </td>
            <td>
              <Input
                value={showName}
                className={styles.searchInput}
                onChange={(e) => onChangeShowName(e.currentTarget.value)}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Checkbox
                checked={isDefaultCheck}
                onChange={onChangeIsDefaultCheck}
              />
            </td>
            <td className={styles.tdSetCenter}>
              <Button
                className={styles.addBtn}
                key="add"
                onClick={onAddNewItemHandler}
              >
                <StoryEditEntAdd
                  style={{ marginRight: "6px", fill: "#1c87f2" }}
                />
                Add
              </Button>
            </td>
          </tr>

          {dataListItems.map((item, index) => {
            return (
              <DataListConfigItem
                key={item.id}
                dataListItem={item}
                onRemoveItem={onDdDataListItemRemove}
                onUpdate={onUpdateDataListItem}
              />
            );
          })}
        </tbody>
      </table>
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Item?",
          description: `Are you sure you want to delete this item? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onOkConfirmDeleteHandler}
        onCancel={onCancelConfirmDeleteHandler}
      />
    </>
  );
}

export default DataListConfig;
