import React, { useEffect, useState } from "react";
import styles from "./EditStoryComponent.module.scss";
import { Button, Input, Select } from "antd";
import SearchIcon from "src/assets/svg/searchIcon";
import { NEWEST, OLDEST } from "src/constants/SortBy";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import StoryEntityDrawer from "src/components/zero/zero-story/drawer/StoryEntityDrawer";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import StoryEditEntEdit from "src/assets/svg/story-edit-ent-edit";
import StoryEditComponentPlay from "src/assets/svg/story-edit-component-play";
import StoryEditComponentComputer from "src/assets/svg/story-edit-component-computer";
import StoryComponentPreview from "src/components/zero/zero-story/modal/modal-story-component/StoryComponentPreview";
import StoryEditComponentMF from "src/assets/svg/story-edit-component-mf";
import { StoryEnyityBORequest } from "src/types/request.type";
import useFetch from "src/hooks/useFetch";
import {
  GetComponentByStoryIDComponent,
  GetComponentByStoryIDResponse,
  Response,
} from "src/types/response.type";
import { useParams } from "react-router";
import {
  GET_COMPONENT_BY_STORY_ID_ENDPOINT,
  REMOVE_COMPONENT_BY_STORY_ID_ENDPOINT,
  GROUP_BO_NAME_ENYITY_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { MICROFLOW, PAGE } from "src/constants/StoryComponent";
import { STATUS_OK } from "src/constants/ResponseStatus";
import { callApiMethodGet } from "src/services/util.service";

type Props = {
  isOpen: boolean;
};

type FilterModel = {
  value: string;
  label: string;
};

function EditStoryComponent({ isOpen }: Props) {
  let params = useParams();

  const sortItems = [
    { value: NEWEST, label: "Newest" },
    { value: OLDEST, label: "Oldest" },
  ];
  // const filterByItems = [{ value: "all", label: "All" }];
  let [openCreateComponentDrawer, setOpenCreateComponentDrawer] =
    useState<boolean>(false);
  let [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    useState<boolean>(false);
  let [itemToDelete, setItemToDelete] = useState<string>("");
  let [componentNameCreated, setComponentNameCreated] = useState<string>("");
  let [componentItems, setComponentItems] = useState<
    GetComponentByStoryIDComponent[]
  >([]);
  let [componentItemsSearch, setComponentItemsSearch] = useState<
    GetComponentByStoryIDComponent[]
  >([]);
  let [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false);
  let [filterByItems, setFilterByItems] = useState<FilterModel[]>([
    { value: "all", label: "All" },
  ]);

  let [filterByEnyityBoID, setFilterByEnyityBoID] = useState<string>("all");
  let [sortOrder, setSortOrder] = useState<string>("DESC");

  //INTEGRATED API
  const { loading, error, responseData, fetchData, fetchKey, fetchDataAsync } =
    useFetch<GetComponentByStoryIDResponse>();

  const GET_STORY_COMPONENT_FK = "getStCompFK";
  const REMOVE_STORY_COMPONENT_FK = "removeStCompFK";

  useEffect(() => {
    APIGroupBoNameByStoryID();
    if (!loading) {
      if (error || !responseData) return;

      switch (fetchKey) {
        case GET_STORY_COMPONENT_FK: {
          let respGetStoryComp =
            responseData as unknown as GetComponentByStoryIDResponse;
          let componentItems: GetComponentByStoryIDComponent[] =
            respGetStoryComp?.data.Component;
          // console.log(
          //   "EditStoryComponent respGetStoryComp : ",
          //   respGetStoryComp
          // );
          setNewComponentItems(componentItems);

          break;
        }
        case REMOVE_STORY_COMPONENT_FK: {
          let respRemoveStoryComp = responseData as unknown as Response;
          // console.log(
          //   "EditStoryComponent respRemoveStoryComp : ",
          //   respRemoveStoryComp
          // );
          if (respRemoveStoryComp.status === STATUS_OK)
            getStoryComponentController(filterByEnyityBoID, sortOrder);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //ONLOAD OPEN COMPONENT
  useEffect(() => {
    if (isOpen) {
      getStoryComponentController(filterByEnyityBoID, sortOrder);
    }
  }, [isOpen]);

  const onSearchComponentHandler = (search: string) => {
    let upperWord = search.toUpperCase();
    if (search) {
      let newComponentItems: GetComponentByStoryIDComponent[] =
        componentItems.filter((item) =>
          item.component_name.toLocaleUpperCase().includes(upperWord)
        );
      setComponentItems(newComponentItems);
    } else {
      setComponentItems([...componentItemsSearch]);
    }
  };
  const onChangeComponentSortHandler = (sort: string) => {
    // let newComponentItems = [...componentItems].sort(sortFn(sort));
    // setComponentItems(newComponentItems);
    setSortOrder(sort);
    getStoryComponentController(filterByEnyityBoID, sort);
  };
  const onChangeComponentFilterHandler = (filter: string) => {
    // console.log("onChangeComponentFilterHandler filter ", filter);
    setFilterByEnyityBoID(filter);
    getStoryComponentController(filter, sortOrder);
  };
  const onClickCreateStoryComponent = () => {
    setOpenCreateComponentDrawer(true);
  };

  //DRAWER
  const onCloseCreateComponentDrawer = () => {
    setOpenCreateComponentDrawer(false);
  };
  const onCreateComponentEntity = (req: StoryEnyityBORequest) => {
    console.log("onCreateComponentEntity componentName => ", req.bo_name);
    setComponentNameCreated(req.bo_name);
  };

  //CONFIRM DELETE MODAL
  const onOkConfirmDeleteHandler = () => {
    // console.log("onOkConfirmDeleteHandler itemToDelete ", itemToDelete);
    if (!itemToDelete) return;
    removeComponentItem();
    setOpenConfirmDeleteModal(false);
  };
  const onCancelConfirmDeleteHandler = () => {
    setOpenConfirmDeleteModal(false);
  };

  //ACTION BUTTON
  const onClickComponentPlayHandler = (id: string) => {
    console.log("onClickComponentPlayHandler id : ", id);
    showPreviewModal();
  };
  const onClickComponentEditHandler = ({
    id,
    component_type,
    component_ref_id,
  }: {
    id: string;
    component_type: string;
    component_ref_id: string;
  }) => {
    console.log(
      `onClickComponentEditHandler id:${id}, component_type:${component_type}`
    );
    const domain = window.location.origin;
    if (component_type === PAGE) {
      console.log("Component edit page id: ", id);

      let url = `${domain}/PageDesigner/#/editor/${component_ref_id}`;
      console.log("Component edit page url: ", url);
      window.open(url, "_self", "noopener,noreferrer");
    } else {
      console.log("Component edit mf id: ", id);

      let url = `${domain}/MicroFlow/workFlow/createWorkFlowDiagramASP.html?ppId=${component_ref_id}`;
      console.log("Component edit mf url: ", url);
      window.open(url, "_self", "noopener,noreferrer");
    }
  };
  const onClickComponentDeleteHandler = (id: string) => {
    console.log("onClickComponentDeleteHandler id : ", id);
    setItemToDelete(id);
    setOpenConfirmDeleteModal(true);
  };

  //PREVIEW MODAL
  const showPreviewModal = () => {
    setIsOpenPreviewModal(true);
  };

  const onOkPreviewModalHandler = () => {
    setIsOpenPreviewModal(false);
  };

  const onCancelPreviewModalHandler = () => {
    setIsOpenPreviewModal(false);
  };

  //UTILITY FUNCTION
  const setNewComponentItems = (
    newComponentItems: GetComponentByStoryIDComponent[]
  ) => {
    setComponentItems(newComponentItems);
    setComponentItemsSearch(newComponentItems);
  };
  const sortFn = (sortType: string = NEWEST) => {
    return (
      a: GetComponentByStoryIDComponent,
      b: GetComponentByStoryIDComponent
    ) => {
      if (sortType === NEWEST) {
        return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
      } else {
        return b.id.toLowerCase().localeCompare(a.id.toLowerCase());
      }
    };
  };
  const removeComponentItem = () => {
    removeStoryComponentController(itemToDelete);
  };
  const getStoryId = () => {
    return params.id;
  };

  //API CONTROLLER FUNCTION
  const getStoryComponentController = (filter: string, sort: string) => {
    let storyId = getStoryId();
    fetchData(
      {
        endpoint: GET_COMPONENT_BY_STORY_ID_ENDPOINT,
        method: "GET",
        params: {
          story_id: storyId,
          filter: filter,
          sortOrder: sort,
        },
      },
      GET_STORY_COMPONENT_FK
    );
  };

  const removeStoryComponentController = (id: string) => {
    fetchData(
      {
        endpoint: REMOVE_COMPONENT_BY_STORY_ID_ENDPOINT,
        method: "DELETE",
        params: {
          id,
        },
      },
      REMOVE_STORY_COMPONENT_FK
    );
  };

  const APIGroupBoNameByStoryID = async () => {
    let storyId = getStoryId();
    try {
      const response = await callApiMethodGet(GROUP_BO_NAME_ENYITY_ENDPOINT, {
        story_id: storyId,
      });

      if (response.status == 200) {
        const newData = response.data.map((item: any) => ({
          value: item.id,
          label: item.bo_name,
        }));
        setFilterByItems([{ value: "all", label: "All" }, ...newData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.editStCompContainer}>
      <div className={styles.compConfig}>
        <div className={styles.compConfigTitle}>Component</div>
        <div className={styles.compConfigInput}>
          <Input
            placeholder={"Search Component"}
            suffix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
            className={styles.searchInput}
            onChange={(e) => onSearchComponentHandler(e.currentTarget.value)}
          />
          <div className={styles.compInputFilter}>
            <div className={styles.filterTitle}>Filter&nbsp;by:</div>
            <Select
              // value={dbSelectValue}
              variant={"borderless"}
              defaultValue={filterByItems[0].value}
              options={filterByItems}
              style={{ width: "100%" }}
              placeholder={
                <div style={{ fontWeight: "bold" }}>
                  &nbsp;&nbsp;&nbsp;Search
                </div>
              }
              onChange={onChangeComponentFilterHandler}
              optionFilterProp="children"
              className={styles.compInputSelect}
            />
          </div>
          <div className={styles.compInputSort}>
            <div className={styles.sortTitle}>Sort&nbsp;by:</div>
            <Select
              // value={dbSelectValue}
              variant={"borderless"}
              defaultValue={sortItems[0].value}
              options={sortItems}
              style={{ width: "100%" }}
              placeholder={
                <div style={{ fontWeight: "bold" }}>
                  &nbsp;&nbsp;&nbsp;Search
                </div>
              }
              onChange={onChangeComponentSortHandler}
              optionFilterProp="children"
              className={styles.compInputSelect}
            />
          </div>
          <Button
            type="primary"
            className={styles.createCompBtn}
            onClick={onClickCreateStoryComponent}
          >
            <StoryEditEntAdd width={"18px"} height={"20px"} />
          </Button>
        </div>
      </div>

      {/* BO LIST SECTION */}
      {componentItems.length > 0 && (
        <div className={styles.compSelected}>
          <div className={styles.compSelectedTitle}>
            Component ({componentItems.length})
          </div>
          <div className={styles.compSelectedList}>
            {componentItems.map((compItem: GetComponentByStoryIDComponent) => {
              return (
                <div key={compItem.id} className={styles.compSelectedItem}>
                  <div className={styles.componentNameWrapper}>
                    {compItem.component_type === PAGE ? (
                      <StoryEditComponentComputer />
                    ) : (
                      <StoryEditComponentMF />
                    )}
                    <p>{compItem.component_name}</p>
                  </div>
                  <div className={styles.compAction}>
                    {/* ISPAGE */}
                    {compItem.component_type === PAGE && (
                      <div
                        className={styles.actionIconWrapper}
                        onClick={() =>
                          onClickComponentPlayHandler(compItem.id.toString())
                        }
                      >
                        <StoryEditComponentPlay className={styles.actionIcon} />
                      </div>
                    )}
                    <div
                      className={styles.actionIconWrapper}
                      onClick={() =>
                        onClickComponentEditHandler({
                          id: compItem.id.toString(),
                          component_type: compItem.component_type,
                          component_ref_id: compItem.component_ref_id,
                        })
                      }
                    >
                      <StoryEditEntEdit className={styles.actionIcon} />
                    </div>
                    <div
                      className={styles.actionIconWrapper}
                      onClick={() =>
                        onClickComponentDeleteHandler(compItem.id.toString())
                      }
                    >
                      <StoryEditEntDelete className={styles.actionIcon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* CREATE STORY DRAWER */}
      <StoryEntityDrawer
        isOpen={openCreateComponentDrawer}
        onClose={onCloseCreateComponentDrawer}
        onCreate={onCreateComponentEntity}
      />
      {/* CREATE ENTITY NOTIFICATION */}
      <CreateEntitySuccessNotify isCreated={componentNameCreated} />
      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Component?",
          description: `Are you sure you want to delete this component? This action cannot be
      undone.`,
        }}
        isModalOpen={openConfirmDeleteModal}
        onOk={onOkConfirmDeleteHandler}
        onCancel={onCancelConfirmDeleteHandler}
      />

      {/* PREVIEW MODAL */}
      <StoryComponentPreview
        isModalOpen={isOpenPreviewModal}
        onOk={onOkPreviewModalHandler}
        onCancel={onCancelPreviewModalHandler}
      />
    </div>
  );
}

export default EditStoryComponent;
