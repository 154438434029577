import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

type Props = {
  codeString: string;
};

export default function SyntaxLighter({ codeString }: Props) {
  return (
    <SyntaxHighlighter
      language="latte"
      style={materialDark}
      showLineNumbers={false}
      customStyle={{borderRadius: "12px"}}
    >
      {codeString}
    </SyntaxHighlighter>
  );
}
