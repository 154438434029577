export let jsTextListTile = `

{_JSONCONFIG_SCREEN_}

let ACTION_TYPE = {
  ACTION_SELECT_ALL_MAIN: "select_all_main",
  ACTION_SELECT_MAIN: "select_main",
  ACTION_INSERT_MAIN: "insert_main",
  ACTION_UPDATE_MAIN: "update_main",
  ACTION_DELETE_MAIN: "delete_main",
}

let FILE_HUB_URL_IMAGE = JSON_CONFIG_SCREEN.config.filehubUrlImage ? JSON_CONFIG_SCREEN.config.filehubUrlImage : '';
const SCREENS_EDIT_WITH_POSITION = JSON_CONFIG_SCREEN.screensEdit.filter(screen => screen.rownumber && screen.columnnumber);

async function fetchDataFromServer(action, inputObject) {
  return new Promise((resolve, reject) => {
    try {
      let flow = JSON_CONFIG_SCREEN.flow;
      let config = JSON_CONFIG_SCREEN.config;
      // let OBJECT_NAME = JSON_CONFIG_SCREEN.bo.boName;

      let flowNameV = flow.flowName;
      let appName = flow.appName;
      let uniqId = flow.flowId;
      let snapVersion = flow.snapVersion;
      let MFR_URL = config.microFlowUrl;

      let inputname = flowNameV + "_flow_input";

      let object = {};
      object[inputname] = inputObject;
      object[inputname].action = action;
      let body = {
        flowName: flowNameV,
        appName: appName,
        snapVersion: snapVersion,
        uniqueId: uniqId,
        object: object,
      };

      $.ajax({
        url: MFR_URL,
        type: "POST",
        data: JSON.stringify(body),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data) {
          console.log("data:<> ", data);
          let map = data["responseObjectsMap"];
          // let name = OBJECT_NAME + "_list";
          let out = flowNameV + "_flow_output";
          let responseObj = map[out];

          if (responseObj === null) {
            reject(new Error('responseObj is null'));
          } else {
            resolve(responseObj);
          }
        },
        catch: function (e) {
          reject(e);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function transformBoData(bo, input) {
  const output = {};

  console.log("bo", bo);
  const filteredBo = JSON_CONFIG_SCREEN.bo.filter(item => item.boName === bo);

  console.log("filteredBo", filteredBo);

  filteredBo.forEach(item => {
    const boName = item.boName;
    const fields = item.fields;
    const boObject = {};

    fields.forEach(field => {
      const fieldName = field.fieldName;
      if (input.hasOwnProperty(fieldName)) {
        boObject[fieldName] = input[fieldName];
      } else {
        boObject[fieldName] = null;
      }
    });

    output[boName] = boObject;
  });
  console.log("output:: ", output)
  return output;
}

function getPrimaryKeyColumns(jsonConfig) {
  const primaryKeyColumns = [];

  let primaryKeyColumnBo = [];
  let pathColumn;
  jsonConfig.table.forEach(table => {
    table.columns.forEach(column => {
      if (column.primaryKey) {
        pathColumn = table.tableName + "." + column.column
      }
    });
  });

  let pathFrom
  jsonConfig.mapping.forEach(mapping => {
    if (mapping.pathTo == pathColumn) {
      pathFrom = mapping.pathFrom
    }
  })
  let pathFroms = pathFrom.split(".");
  if (pathFroms.length > 0)
    primaryKeyColumnBo.push(pathFroms[pathFroms.length - 1])

  return primaryKeyColumnBo;
}

function extractFieldNames(apiResponse) {
  const result = {};
  apiResponse.bo.forEach((bo) => {
    result[bo.boName] = bo.fields.map(field => field.fieldName);

  });
  return result;
};

async function fetchData(valuePath, valueSearch) {
  console.log("valuePath>::", valuePath);
  const inputObject = {
    orderBy: null,
    search: valueSearch,
    limit: 30,
    offset: 0,
    parameter: transformBoData(valuePath, "")
  };

  console.log("inputObject>::", inputObject);

  try {
    const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_ALL_MAIN, inputObject);
    console.log("response :", response);
    console.log("response :", response[valuePath + "_list"]);
    return response[valuePath + "_list"];
  } catch (error) {
    console.error(error);
  }
}

async function getSelectMain(data, screenKey) {
  let key = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  console.log("inputObject key:: ", data[key]);

  let input = { [key]: data[key] }
  console.log("input:: ", input);
  const inputObject = { parameter: transformBoData(screenKey, input) };
  console.log("inputObject:: ", inputObject);

  try {
    const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_MAIN, inputObject);
    console.log("response :", screenKey);
    console.log("response :", response[screenKey]);
    return response[screenKey];

  } catch (error) {
    console.error(error);
  }
}

// ฟังก์ชันเพื่อ render หน้าจอ BO
function renderBOScreens(screen, data) {
  console.log("6666 >>>", data);
  let toggleButton = document.querySelector('#toggleButton-id-' + screen.key);
  let input = document.getElementById('input-id-' + screen.key);
  let isPasswordVisible = false;

  if (toggleButton) {
    toggleButton.addEventListener('click', () => {
      isPasswordVisible = !isPasswordVisible;
      input.type = isPasswordVisible ? 'text' : 'password';
      toggleButton.innerHTML = isPasswordVisible ? '<i class="fas fa-eye"></i>' : '<i class="fas fa-eye-slash"></i>';
    },false);
  }

  let uploadButton = document.getElementById('image-' + screen.key);
  if (uploadButton) {
    // อัปเดตรายการไฟล์เมื่อมีการเลือกไฟล์ใหม่
    const onChange = ({ FILE_LIST: newFileList }) => {
      setFileList(newFileList);
    };

    // แสดงตัวอย่างของไฟล์ภาพเมื่อคลิกที่ปุ่มดูตัวอย่าง
    const onPreview = async (files) => {
      const file = files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = function (event) {
        const imgElement = document.createElement('img');
        imgElement.src = event.target.result;
        imgElement.style.maxWidth = '100%';
        imgElement.style.maxHeight = '100%';

        const upload = document.getElementById('image-preview-' + screen.key);
        // ลบภาพเก่าออกจาก DOM
        const imgContainer = upload.querySelector('img');
        if (imgContainer) {
          imgContainer.remove();
        }

        // แทรกภาพลงใน DOM
        upload.appendChild(imgElement);
      };
      reader.readAsDataURL(file);
    };


    uploadButton.querySelector('input').addEventListener('change', (event) => {
      onChange({ FILE_LIST: event.target.files });
      onPreview(event.target.files);
      const uploadLabel = document.getElementById('image-preview-label-' + screen.key);
      uploadLabel.hidden = true;
    },false);
  };

}

let FILE_LIST = [];
function setFileList(newFileList) {
  FILE_LIST = newFileList;
  console.log('Updated FILE_LIST:', FILE_LIST);
};

function renderButtonScreens(screen, data, label, mainScreen) {
  console.log("99999 updatedData", data);
  const buttonId = mainScreen.key + "-" + screen.label + "-" + screen.buttonType + "-" + label;
  const button = document.getElementById(buttonId);
  console.log("99999 buttonId", buttonId);
  console.log("99999 button", button);

  if (button) {
    if (button._handleClick) {
      button.removeEventListener('click', button._handleClick);
    }

    button._handleClick = async function () {

  

      console.log("data", data);
      const inputObject = { parameter: transformBoData(mainScreen.key, data) };
      console.log("inputObject>::", inputObject);

      try {

        let response;
        if (label === 'edit') {
          response = await fetchDataFromServer(ACTION_TYPE.ACTION_UPDATE_MAIN, inputObject);
        } else if (label === 'add') {
          response = await fetchDataFromServer(ACTION_TYPE.ACTION_INSERT_MAIN, inputObject);
        }
        handlebackButtonClick(label, mainScreen);
        console.log("response>::", response);


        $("#{{PAGE_CONTENT_ID}}").trigger("load");

       
      } catch (error) {
        console.error(error);
      }
    };

    button.addEventListener('click', button._handleClick,false);
  }
}

// ฟังก์ชันเพื่อ render หน้าจอ Breadcrumb
function renderBreadcrumbScreens(screen) {
  if (Array.isArray(screen.steps)) {
    screen.steps.sort((a, b) => a.seq - b.seq).forEach((steps) => {
      console.log("steps.tabName>> ", steps.tabName);

      let ClickStep = document.getElementById('steps-item-' + steps.tabName);
      if (ClickStep) {
        ClickStep.addEventListener('click', () => handleBreadcrumbStepClick(steps, screen),false);
      }
    })
  }

}

// ฟังก์ชันเพื่อ render หน้าจอ Tab
function renderTabsScreenContainer(screen) {
  console.log("renderTabsScreenContainer>> ", screen);
  const tabComponent = document.getElementById('tab-component-' + screen.key);
  console.log("tabComponent>> ", tabComponent);
  if (Array.isArray(screen.tabs)) {
    console.log("screen.tabs>> ", screen.tabs);
    screen.tabs.sort((a, b) => a.seq - b.seq).forEach((tab) => {
      const tabElement = document.getElementById('tab-id-' + tab.tabName);
      console.log("tabElement>> ", tabElement);

      if (tabElement) {
        tabElement.addEventListener('click', () => {
          const activeTab = tabComponent.querySelector('.ant-tabs-tab-active');
          if (activeTab) {
            activeTab.classList.remove('ant-tabs-tab-active');
          }
          tabElement.classList.add('ant-tabs-tab-active');
          handleTabsClick(tab, screen);
        },false);
      }
    });
  }
}

// ฟังก์ชันเมื่อคลิกบน breadcrumb step
function handleBreadcrumbStepClick(step, screen) {
  console.log("step screen>> ", step);
  clearTabContent(screen);
  const stepContent = document.getElementById('tab-' + step.tabName);
  if (stepContent) {
    stepContent.style.display = 'grid';
  }

  renderTabScreens(step);
}

// ฟังก์ชันเพื่อล้างเนื้อหาของ tab
function clearTabContent(screen) {
  const container = document.getElementById('container');
  const stepContents = container.querySelectorAll('.tab-content-' + screen.key);
  stepContents.forEach(stepContent => {
    stepContent.style.display = 'none';
  });
}

// ฟังก์ชันเมื่อคลิกบน tab
function handleTabsClick(tab, screen) {
  console.log("tab screen>> ", tab);
  clearTabContent(screen);
  const stepContent = document.getElementById('tab-' + tab.tabName);
  if (stepContent) {
    stepContent.style.display = 'grid';
  }
  renderTabScreens(tab);
}

// ฟังก์ชัน render หน้าจอ Tab
function renderTabScreens(data) {
  if (!data.tabScreens) return [];

  console.log("tabScreens", data.tabScreens);
  data.tabScreens.forEach(tabScreen => {
    // console.log("tabScreens1",tabScreen);
    if (tabScreen.configType === 'BREADCRUMB') {
      renderBreadcrumbScreens(tabScreen);

    } else if (tabScreen.configType === 'TAB') {
      console.log("tabScreens2", tabScreen);
      renderTabsScreenContainer(tabScreen);
    }
  });
}

async function renderListTileContainer(screen) {
  const mainContainer = document.getElementById('mainContainer');
  const screenElement = document.getElementById('screenElement-' + screen.configType + '-' + screen.key);

  const listTileContainer = document.getElementById('list-tile-container-' + screen.configType + '-' + screen.key);
  const listTileContent = document.getElementById('list-tile-content-' + screen.configType + '-' + screen.key);
 
  const buttonAdd = document.getElementById('content-add-button-' + screen.key);
  if (buttonAdd) {
    buttonAdd.addEventListener('click', async function () {
      handleInsertListTileClick('add', screen.key);

      let dataValue = extractFieldNames(JSON_CONFIG_SCREEN);
      console.log("dataValue", dataValue[screen.key]);

      let createData = {};
      dataValue[screen.key].forEach((key, index) => {

        const inputElement = document.getElementById('input-id-add-' + key);
        if (inputElement) {
          console.log('key>',);

          console.log('key', key);
          createData[key] = inputElement.value;

          // เคลียร์ค่าใน input element
          inputElement.value = '';
        }
        inputElement.addEventListener('input', function () {
          createData[key] = inputElement.value;
        },false);
      });
      console.log("createData", createData);
      await createListTileContainer(createData, screen);

    },false)
  }

  const backButton = document.getElementById('backButton-container-add-Wrapper-' + screen.key);
  backButton.addEventListener('click', function () { handlebackButtonClick('add', screen); },false);

  const backButton2 = document.getElementById('backButton-containerWrapper-' + screen.key);
  backButton2.addEventListener('click', function () { handlebackButtonClick('edit', screen); },false);

}

async function fetchAndRenderUpdatedData(screen, listTileContent, valuePath) {
  //get old datas
}


function handleInsertListTileClick(label, key) {
  clearScreenContent(key, label);
  console.log("handleInsertListTileClick");
  const addListTileContent = document.getElementById('container-add-list-tile-' + key);
  console.log("stepContent:: :", addListTileContent);
  if (addListTileContent) {
    addListTileContent.style.display = 'block';
  }
}

async function createListTileContainer(createData, mainScreen) {
  console.log("createData >>!!!!!!!", createData);
  SCREENS_EDIT_WITH_POSITION.forEach(screen => {
    if (screen.configType === 'BO') {
      renderBOScreens(screen);
    } else if (screen.configType === 'BUTTON') {
      renderButtonScreens(screen, createData, 'add', mainScreen);
    } else if (screen.configType === 'BREADCRUMB') {
      renderBreadcrumbScreens(screen);
    } else if (screen.configType === 'TAB') {
      renderTabsScreenContainer(screen);
    }
  });
}


function updateListTileScreens(data, updatedData, mainScreen) {
  console.log("updateListTileScreens >>!!!!!!!", data);

  SCREENS_EDIT_WITH_POSITION.forEach(screen => {
    if (screen.configType === 'BO') {
      renderBOScreens(screen, data);
    } else if (screen.configType === 'BUTTON') {
      renderButtonScreens(screen, updatedData, 'edit', mainScreen);
    } else if (screen.configType === 'BREADCRUMB') {
      renderBreadcrumbScreens(screen);
    } else if (screen.configType === 'TAB') {
      renderTabsScreenContainer(screen);
    }
  });
}

async function handlebackButtonClick(label, mainScreen) {
  const mainContainer = document.getElementById('mainContainer');
  const listTileContainer = mainContainer.querySelectorAll('[id^="container-' + label + '-list-tile-' + mainScreen.key + '"]');

  listTileContainer.forEach(content => {
    content.style.display = 'none';
  });

  const container = document.getElementById('container');
  container.style.display = 'block';
}

function handleUpdateListTileClick(data, key, label) {

  clearScreenContent(key, label);

  console.log("handleUpdateListTileClick :<<< ", data);
  const updateListTileContent = document.getElementById('container-edit-list-tile-' + key);
  console.log("stepContent:: :", updateListTileContent);
  if (updateListTileContent) {
    updateListTileContent.style.display = 'block';
  }
}

function clearScreenContent(key, label) {
  const mainContainer = document.getElementById('mainContainer');
  const container = document.getElementById('container');
  container.style.display = 'none';

  const updateListTileContent = mainContainer.querySelectorAll('[id^="container-' + label + '-list-tile-' + key + '"]');
  console.log("updateListTileContent:: :", updateListTileContent);
  updateListTileContent.forEach(content => {
    content.style.display = 'none';
  });
};

function MyComponent(JSON_CONFIG_SCREEN) {
  const screensWithPosition = JSON_CONFIG_SCREEN.screens.filter(screen => screen.rownumber && screen.columnnumber);

  screensWithPosition.forEach(screen => {
    // if (screen.configType === 'BO') {
    renderBOScreens(screen);

    // } else if (screen.configType === 'BREADCRUMB') {
    renderBreadcrumbScreens(screen);

    // } else if (screen.configType === 'TAB') {
    renderTabsScreenContainer(screen);
    // }
    renderListTileContainer(screen)
  });
}



let FN_HANDLE_CLICK_EDIT = async function handleClickEdit(e) {
  console.log(e)
  const getButtonID = (element) => {
    if (element.nodeName == "BUTTON") {
      return element.id;
    } else {
      return getButtonID(element.parentNode)
    }
  }
  let id = getButtonID(e.srcElement)
  console.log("id", id)
  let ids = id.split("_")
  let index = parseInt(ids[ids.length - 1]);
  let screenKey = "{{SCREEN_KEY}}";
  let arrayRes = myScope.Store["{{FLOW_NAME}}"].{{FLOW_NAME}}_flow_output.{{SCREEN_KEY}}_list;
  let data = arrayRes[index];

  let screen = null;
  for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
    if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
      screen = JSON_CONFIG_SCREEN.screens[a]
    }
  }

  handleUpdateListTileClick(data, screenKey, 'edit');

  let dataValue = await getSelectMain(data, screenKey);
  console.log("dataValue :<<< ", dataValue);
  let updatedData = {};

  if (dataValue) {
    let keys = Object.keys(dataValue);
    keys.forEach((key) => {
      console.log("inputElement key:>> ", key);
      const inputElement = document.getElementById('input-id-edit-' + key);
      console.log("inputElement:>> ", inputElement);
      if (inputElement) {
        inputElement.value = dataValue[key];
        updatedData[key] = dataValue[key];
      }
      inputElement.addEventListener('input', function () {
        updatedData[key] = inputElement.value;
      },false);
    });
  }

  updateListTileScreens(data, updatedData, screen);

}


let FN_HANDLE_CLICK_REMOVE = async function handleClickRemove(e) {
  console.log(e)
  const getButtonID = (element) => {
    if (element.nodeName == "BUTTON") {
      return element.id;
    } else {
      return getButtonID(element.parentNode)
    }
  }
  let id = getButtonID(e.srcElement)
  console.log("id", id)
  let ids = id.split("_")
  let index = parseInt(ids[ids.length - 1]);
  let key = "{{SCREEN_KEY}}";
  let arrayRes = myScope.Store["{{FLOW_NAME}}"].{{FLOW_NAME}}_flow_output.{{SCREEN_KEY}}_list;
  let data = arrayRes[index];

  let dataValue = await getSelectMain(data, key);
  console.log("dataValue :<<< ", dataValue);
  console.log("buttonRemove data", data);
  const inputObject = { parameter: transformBoData(key, data) };
  console.log("inputObject>::", inputObject);

  try {
    await fetchDataFromServer(ACTION_TYPE.ACTION_DELETE_MAIN, inputObject);
    let screen = null;
    for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
      if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
        screen = JSON_CONFIG_SCREEN.screens[a]
      }
    }

    const card = document.getElementById('card-' + screen.configType + "-" + screen.key + "-" + index);
    console.log("valuePath>>>>>>>>>> card", card);

    if (card) {
      card.remove();
    }




  } catch (error) {
    console.error(error);
  }
}

let INIT_ALREADY = false;
function initLoad($page,event) {
  if(!INIT_ALREADY){
    myScope["SCOPE_URL_IMAGE"] = JSON_CONFIG_SCREEN.config.filehubUrlImage;
    myScope.update();
    MyComponent(JSON_CONFIG_SCREEN);
    let screen = null;
    for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
      if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
        screen = JSON_CONFIG_SCREEN.screens[a]
      }
    }
    let id ="searchButton-"+screen.configType+"-"+screen.key;  
    let idSeach ="search-input-"+screen.configType+"-"+screen.key;  
    const searchBtn = document.getElementById(id);
    searchBtn.addEventListener('click', async function (e) {
      $page.State.search = $("#"+idSeach).val();
      $page.update();
      $("#{{PAGE_CONTENT_ID}}").trigger("load");
    },false)
    INIT_ALREADY = true;
  }
}


function afterLoadMFD($page,event) {
 
  let screenKey = "{{SCREEN_KEY}}";
  let arrayRes = $page.Store["{{FLOW_NAME}}"].{{FLOW_NAME}}_flow_output.{{SCREEN_KEY}}_list;
  let primaryKey = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  
  for(let a=0;a<arrayRes.length;a++){

    let index = a;
    let data = arrayRes[index];

    const buttonEdit = document.getElementById('button-edit-list-tile_' + index);
    const buttonRemove = document.getElementById('button-remove-list-tile_' + index);

    buttonEdit.addEventListener('click', FN_HANDLE_CLICK_EDIT,false);

    buttonRemove.addEventListener('click', FN_HANDLE_CLICK_REMOVE,false)
  }

}

function clickEditItemFn($page,event) {
  console.log("===clickEditItemFn===")
  console.log("$page",$page)
  console.log("event",event)
}

`;