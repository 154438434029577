import React, {
  ReactComponentElement,
  useContext,
  useEffect,
  useState,
} from "react";
import ImageCover from "../../assets/svg/zero-background";
import styles from "../component/DashboardZeroContent.module.scss";
import { Button, Divider, Dropdown, Input, Space, Tabs, theme } from "antd";
import DataConnectionContainer from "./database-connection/DataConnectionContainer";
import SearchIcon from "src/assets/svg/searchIcon";
import AngleDownIcon from "src/assets/svg/angle-down";
import LineListIcon from "src/assets/svg/line-list";
import AppsIcon from "src/assets/svg/apps";
import { AppContext } from "../context/App.context";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import Story from "../story/Story";
import { pathRoutes, sortType } from "../props/util.props";
import useSearchApp from "src/hooks/useSearchApp";
import useViewMode from "src/hooks/useViewMode";
import { CARD_MODE, LIST_MODE } from "src/constants/ViewMode";
import { handleClickTabs } from "../utils/NavigationUtils";
import { SortOrder } from "src/types/databaseConnectors.type";
import {
  GROUP_DB_TYPE_AND_OWNER,
  STORY_GROUP_DB_TYPE_AND_OWNER,
} from "src/utils/endpoint/createbo.endpoint";
import { callApiMethodGet, getAspId } from "src/services/util.service";

const { useToken } = theme;

const { TabPane } = Tabs;
const text_greeting = "Hi!";
// const username = "Satoru";
const text = "Welcome to  Zero Project";
const db_connection = "Database Connection";
const story = "Story";
const app_component = "App Component";
const component_list = "Component List";
const db_reference = "Data Reference";
const text_preview_app = "Preview App";
const text_preview = "Preview";
const placeholder_search = "Search Application";
const text_oldest = "Oldest";
const text_newest = "Newest";

type DashboardZeroContentProps = {
  content?: any;
  onSearch?: (value: any) => void;
};

type FilterMode = {
  key: String;
  label: String;
};

function DashboardZeroContent({
  content,
  onSearch,
}: DashboardZeroContentProps) {
  const appContext: any = useContext(AppContext);
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sortOrder: SortOrder =
    (searchParams.get("sortOrder") as SortOrder) || "DESC";
  const [searchApp, setSearchApp] = useSearchApp();
  const [viewMode, setViewMode] = useViewMode(CARD_MODE);
  const [createdBy, setCreatedBy] = useState<String>("");

  const navigate = useNavigate();
  const { token } = useToken();

  const [filterOptions, setFilterOptions] = useState([
    { key: "All", label: "All" },
  ]);

  useEffect(() => {
    appContext.selectedFilter = "All";
    appContext.searchApp = "";
    if (location.pathname.includes("story")) {
      APIGetStoryGroupByDBTypeAndOwner();
    } else if (location.pathname.includes("data-reference")) {
      const option = [
        { key: "All", label: "All" },
        { key: "DATA_LIST", label: "DATA_LIST" },
        { key: "FROM_TABLE", label: "FROM_TABLE" },
      ];
      setFilterOptions(option);
    } else if (location.pathname.includes("database-connection")) {
      APIGetGroupByDBTypeAndOwner();
    }
    APIGetAppSpace();
  }, []);

  const APIGetAppSpace = async () => {
    try {
      const response = await callApiMethodGet(
        "https://test.oneweb.tech/AppSpace/v1/appspace/w-find/" + getAspId(),
        {}
      );
      setCreatedBy(response.content.createBy);
    } catch (error) {
      console.log("APIGetAppSpace Error : ", error);
      setCreatedBy("Order Management");
    }
  };

  const APIGetGroupByDBTypeAndOwner = async () => {
    try {
      const response = await callApiMethodGet(GROUP_DB_TYPE_AND_OWNER, {
        asp_id: getAspId(),
      });

      const option = [{ key: "All", label: "All" }];
      if (response.status == 200) {
        const dbType = response.data.db_type.map((item: string) => ({
          key: "DBType_" + item,
          label: "DBType : " + item,
        }));

        const owner = response.data.owner.map((item: string) => ({
          key: "Owner_" + item,
          label: "Owner : " + item,
        }));
        setFilterOptions([...option, ...dbType, ...owner]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const APIGetStoryGroupByDBTypeAndOwner = async () => {
    try {
      const response = await callApiMethodGet(STORY_GROUP_DB_TYPE_AND_OWNER, {
        asp_id: getAspId(),
      });

      const option = [{ key: "All", label: "All" }];
      if (response.status == 200) {
        const dbType = response.data.db_type.map((item: string) => ({
          key: "DBType_" + item,
          label: "DBType : " + item,
        }));

        const owner = response.data.owner.map((item: string) => ({
          key: "Owner_" + item,
          label: "Owner : " + item,
        }));
        setFilterOptions([...option, ...dbType, ...owner]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortOption = [
    { key: sortType.DESC, label: text_newest },
    { key: sortType.ASC, label: text_oldest },
  ];

  const selectedSortLabel =
    sortOption.find((item) => item.key === sortOrder)?.label || text_newest;
  const selectedFilterLabel =
    filterOptions.find((item) => item.key === appContext.selectedFilter)
      ?.label || "All";

  const datas = [
    { key: "database-connection", title: db_connection },
    { key: "story", title: story },
    { key: "app-component", title: app_component },
    { key: "data-reference", title: db_reference },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const handleFilterMenuClick = ({ key }: { key: string }) => {
    appContext.setSelectedFilter(key);
  };

  const handleSortMenuClick = ({ key }: { key: string }) => {
    searchParams.set("sortOrder", key);
    navigate({
      search: searchParams.toString(),
    });
  };

  const handleTabClickWrapper = (key: any) => {
    handleClickTabs(key, navigate);
  };

  const customTabBar = (props: any, DefaultTabBar: any) => {
    return (
      <DefaultTabBar {...props} className={styles.customTabBar}>
        {(node: any) => {
          const isActive = current === node.key;
          return (
            <div
              role="tab"
              aria-selected={isActive}
              className={`ant-tabs-tab ${
                isActive ? "ant-tabs-tab-active" : ""
              }`}
              onClick={() => handleTabClickWrapper(node.key)}
            >
              <div className={isActive ? styles.tabActive : styles.tab}>
                {node}
              </div>
            </div>
          );
        }}
      </DefaultTabBar>
    );
  };

  const renderFilterDropdown = (
    <Dropdown
      menu={{
        items: filterOptions,
        onClick: handleFilterMenuClick,
      }}
      dropdownRender={(menu: any) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      <Space>
        <div className={styles.dropdownItemContainer}>
          <div className={styles.dropdownText}>Filter by: </div>
          <div className={styles.labelText}>{selectedFilterLabel}</div>
          <AngleDownIcon />
        </div>
      </Space>
    </Dropdown>
  );

  const renderSortDropdown = (
    <Dropdown
      menu={{
        items: sortOption,
        onClick: handleSortMenuClick,
      }}
      dropdownRender={(menu: any) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      <Space>
        <div className={styles.dropdownItemContainer}>
          <div className={styles.dropdownText}>{selectedSortLabel}</div>
          <AngleDownIcon />
        </div>
      </Space>
    </Dropdown>
  );

  const tabs = datas.map((item) => (
    <TabPane tab={item.title} key={item.key}>
      <div style={{ height: "100%" }}>{content}</div>
    </TabPane>
  ));

  const onChangeSearch = (input: string) => {
    setSearchApp(input);
  };

  const onChangeViewMode = (mode: string) => {
    setViewMode(mode);
  };

  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "dasboard"
      : location.pathname.split("/")[1]
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        let currentLocate = location.pathname.split("/");
        if (currentLocate[2]) {
          setCurrent(currentLocate[2]);
        } else {
          setCurrent("database-connection");
        }
      }
    }
  }, [location, current]);

  return (
    <>
      <div
        className={styles.container}
        style={
          {
            // paddingTop: "40px"
          }
        }
      >
        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <div className={styles.textTitle}>
              {text_greeting} {createdBy}
            </div>
            <div className={styles.textSubTitle}>{text}</div>
          </div>

          <ImageCover width={"100%"} height={"100%"} />
        </div>

        <div className={styles.infoSection}>
          <div className={styles.title}>{text_preview_app}</div>
          <Button
            disabled
            className={styles.previewButton}
            type="primary"
            size="middle"
          >
            {text_preview}
          </Button>
        </div>
      </div>

      <div className={styles.customScrollbar}>
        <Tabs
          // defaultActiveKey={current}
          activeKey={current}
          onChange={handleTabClickWrapper}
          renderTabBar={customTabBar}
          className={styles.tabBarStyle}
          tabBarExtraContent={
            <div className={styles.tabBarContainer}>
              <Input
                placeholder={placeholder_search}
                prefix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
                className={styles.searchInput}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setSearchApp(value);
                  onSearch?.(value);
                }}
              />

              <div className={styles.dropdownContainer}>
                <div className={styles.dropdownContainerStyle}>
                  {renderFilterDropdown}
                  {renderSortDropdown}
                </div>

                <div className={styles.iconContainer}>
                  <div className={styles.iconGroupContainer}>
                    <div
                      className={styles.iconWrapper}
                      onClick={() => onChangeViewMode(LIST_MODE)}
                    >
                      <LineListIcon
                        fill={viewMode === LIST_MODE && "#1C87F2"}
                      />
                    </div>
                    <div
                      className={styles.iconWrapper}
                      onClick={() => onChangeViewMode(CARD_MODE)}
                    >
                      <AppsIcon
                        width={"16px"}
                        height={"16px"}
                        fill={viewMode === CARD_MODE && "#1C87F2"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          {tabs}
        </Tabs>
      </div>
    </>
  );
}
export default DashboardZeroContent;
