import React from "react";

function PremiumIcon() {
  return (
    <div style={{
      width: '100px',
      height: '100px',
    }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width='100px'
        height='100px'
        fill="none"
        viewBox="0 0 483 483"
      >
        <path
          fill="#2684FF"
          d="M407.292 197.753c0-92.543-74.238-167.565-165.816-167.565-81.621-.116-151.178 59.829-163.95 141.294-12.773 81.466 35.042 160.203 112.7 185.588v83.959c0 6.508 5.221 11.784 11.661 11.784h78.711c6.44 0 11.66-5.276 11.66-11.784v-83.9c68.494-22.288 114.957-86.66 115.034-159.376m-165.816 56.621c-30.961 0-56.06-25.363-56.06-56.65s25.099-56.651 56.06-56.651c30.96 0 56.059 25.364 56.059 56.651s-25.099 56.65-56.059 56.65"
        ></path>
        <path
          fill="url(#paint0_linear_25_607)"
          fillRule="evenodd"
          d="M241.475 30.188v110.944c30.961 0 56.059 25.363 56.059 56.65 0 31.288-25.098 56.651-56.059 56.651 13.592 0 26.627 5.456 36.239 15.169 9.611 9.712 15.01 22.885 15.01 36.621v50.964c77.688-25.39 125.523-104.152 112.751-185.646-12.773-81.495-82.351-141.465-164-141.353"
          clipRule="evenodd"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_25_607"
            x1="324.383"
            x2="324.383"
            y1="326.844"
            y2="148.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#0052CC"></stop>
            <stop offset="1" stopColor="#2684FF"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div >
  );
}

export default PremiumIcon;
