import React from "react";
import StoryEmptyCreateIcon from "src/assets/svg/story-create-icon";
import styles from "./DataReferenceCard.module.scss"

type Props = {
    onClickCreate: () => void;
};

function DataReferenceCardCreate({onClickCreate}: Props) {
  return (
    <div className={styles.dataReferenceCardCreateContainer} onClick={onClickCreate}>
      <StoryEmptyCreateIcon />
    </div>
  );
}

export default DataReferenceCardCreate;
