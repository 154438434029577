import { SHOW_AS_LABEL, TEXT, VARCHAR } from "src/constants/InputType";
import { v4 as uuidv4 } from "uuid";

const LAYER_LIMIT = 2;
const MARGIN_LIST = ["0 0 0 30", "0 0 0 50", "0 0 0 70"];
const getDefaultBoConfigItem = (
  bo_name: string = "",
  data_type: string = VARCHAR,
  ref_id: string = ""
) => {
  const id = uuidv4();
  return {
    id,
    isChecked: false,
    bo_name,
    data_type,
    input_type: TEXT,
    display_view: SHOW_AS_LABEL,
    display_edit: "text",
    show_status: false,
    ref_id,
  };
};
const getUpdateBoConfigItemsByID = (
  {
    id,
    // ischecked,
    bo_name,
    data_type,
    input_type,
    display_view,
    display_edit,
    show_status,
    ref_id,
  }: {
    id: string;
    // ischecked: boolean;
    bo_name?: string;
    data_type?: string;
    input_type?: string;
    display_view?: string;
    display_edit?: string;
    show_status?: boolean;
    ref_id?: string;
  },
  boConfigItems: any[]
) => {
  let newCreateBoConfigItems = boConfigItems.map((item) => {
    if (item.id === id) {
      // if (ischecked) item.ischecked = ischecked;
      if (bo_name) item.bo_name = bo_name;
      if (data_type) item.data_type = data_type;
      if (input_type) item.input_type = input_type;
      if (display_view) item.displayView = display_view;
      if (display_edit) item.displayEdit = display_edit;
      if (show_status !== undefined) item.show_status = show_status;
      if (ref_id) item.ref_id = ref_id;
      return item;
    } else {
      return item;
    }
  });
  return newCreateBoConfigItems;
};
const getUpdateRemoveBoConfig = (rowSelected: string, boConfigItems: any[]) => {
  let newCreateBoConfigItems = [...boConfigItems].filter(
    (item) => item.id !== rowSelected
  );
  return newCreateBoConfigItems;
};

export {
  getDefaultBoConfigItem,
  getUpdateBoConfigItemsByID,
  getUpdateRemoveBoConfig,
  LAYER_LIMIT,
  MARGIN_LIST,
};
