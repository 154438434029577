import React from "react";

const StoryGenPageText = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      className={className}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V4.16667C19.9987 3.062 19.5593 2.00296 18.7782 1.22185C17.997 0.440735 16.938 0.00132321 15.8333 0V0ZM18.3333 15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H15.8333C16.4964 1.66667 17.1323 1.93006 17.6011 2.3989C18.0699 2.86774 18.3333 3.50363 18.3333 4.16667V15.8333ZM15 7.5C15 7.72101 14.9122 7.93297 14.7559 8.08926C14.5996 8.24554 14.3877 8.33333 14.1667 8.33333C13.9457 8.33333 13.7337 8.24554 13.5774 8.08926C13.4211 7.93297 13.3333 7.72101 13.3333 7.5C13.3333 7.27899 13.2455 7.06702 13.0893 6.91074C12.933 6.75446 12.721 6.66667 12.5 6.66667H10.8333V13.3333H11.6667C11.8877 13.3333 12.0996 13.4211 12.2559 13.5774C12.4122 13.7337 12.5 13.9457 12.5 14.1667C12.5 14.3877 12.4122 14.5996 12.2559 14.7559C12.0996 14.9122 11.8877 15 11.6667 15H8.33333C8.11232 15 7.90036 14.9122 7.74408 14.7559C7.5878 14.5996 7.5 14.3877 7.5 14.1667C7.5 13.9457 7.5878 13.7337 7.74408 13.5774C7.90036 13.4211 8.11232 13.3333 8.33333 13.3333H9.16667V6.66667H7.5C7.27899 6.66667 7.06702 6.75446 6.91074 6.91074C6.75446 7.06702 6.66667 7.27899 6.66667 7.5C6.66667 7.72101 6.57887 7.93297 6.42259 8.08926C6.26631 8.24554 6.05435 8.33333 5.83333 8.33333C5.61232 8.33333 5.40036 8.24554 5.24408 8.08926C5.0878 7.93297 5 7.72101 5 7.5C5 6.83696 5.26339 6.20107 5.73223 5.73223C6.20107 5.26339 6.83696 5 7.5 5H12.5C13.163 5 13.7989 5.26339 14.2678 5.73223C14.7366 6.20107 15 6.83696 15 7.5Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageText;
