import React from "react";

function BasicIcon() {
    return (
        <div style={{
            width: '100px',
            height: '100px',
        }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width='100px'
                height='100px'
                fill="none"
                viewBox="0 0 483 483"
            >
                <path
                    fill="url(#paint0_linear_25_584)"
                    fillRule="evenodd"
                    d="M353.062 160.226 132.311 365.128c3.149 3.185 6.175 6.245 9.818 9.18l87.127 74.317a17.51 17.51 0 0 0 22.724 0c15.684-13.489 46.373-39.719 82.866-70.819 70.085-59.891 76.26-147.76 18.216-217.58"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#2684FF"
                    fillRule="evenodd"
                    d="M130.587 160.244c-54.797 60.876-52.8 149.161 2.746 204.793l109.655-100.649c67.091-61.625 72.271-171.764 6.054-234.2 0 0-63.659 69.18-118.455 130.056"
                    clipRule="evenodd"
                ></path>
                <defs>
                    <linearGradient
                        id="paint0_linear_25_584"
                        x1="159.913"
                        x2="322.962"
                        y1="276.572"
                        y2="305.029"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0052CC"></stop>
                        <stop offset="1" stopColor="#2684FF"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default BasicIcon;
