
import React from "react";

const AddButtonIcon = (props) => {
    const { className, style, width, height, fill } = props;
    return (
        <svg 
        width={width||"30"} 
        height={height||"30"}
        className={className}
        style={style}
         viewBox="0 0 30 30" 
         fill="none" 
         xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="29" height="29" rx="6.5" fill="white" />
            <rect x="0.5" y="0.5" width="29" height="29" rx="6.5" stroke="#ABABAB" />
            <path d="M21.25 13.75H16.25V8.75C16.25 8.41848 16.1183 8.10054 15.8839 7.86612C15.6495 7.6317 15.3315 7.5 15 7.5C14.6685 7.5 14.3505 7.6317 14.1161 7.86612C13.8817 8.10054 13.75 8.41848 13.75 8.75V13.75H8.75C8.41848 13.75 8.10054 13.8817 7.86612 14.1161C7.6317 14.3505 7.5 14.6685 7.5 15C7.5 15.3315 7.6317 15.6495 7.86612 15.8839C8.10054 16.1183 8.41848 16.25 8.75 16.25H13.75V21.25C13.75 21.5815 13.8817 21.8995 14.1161 22.1339C14.3505 22.3683 14.6685 22.5 15 22.5C15.3315 22.5 15.6495 22.3683 15.8839 22.1339C16.1183 21.8995 16.25 21.5815 16.25 21.25V16.25H21.25C21.5815 16.25 21.8995 16.1183 22.1339 15.8839C22.3683 15.6495 22.5 15.3315 22.5 15C22.5 14.6685 22.3683 14.3505 22.1339 14.1161C21.8995 13.8817 21.5815 13.75 21.25 13.75Z" fill={fill||"#ABABAB"} />
        </svg>


    );
};


export default AddButtonIcon;