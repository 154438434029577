import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as Auth from "../services/auth.service";
import { pathRoutes } from "../page/props/util.props";
import LeftMenu from "src/page/generate/LeftMenu";
import { checkEmpty } from "src/services/util.service";
import TokenExpireModal from "src/shared/TokenExpireModal/TokenExpireModal";
import { AppContext } from "src/page/context/App.context";
import { apiPath, callApiGet } from "src/utils/service.util";

const PrivateRoute = () => {
  const [auth, setAuth] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [typeUser, setTypeUser] = useState<string | null>(null);
  const [openTokenModal, setOpenTokenModal] = useState<boolean>(false);
  const [canRefreshToken, setCanRefreshToken] = useState<boolean>(false);
  const appContext: any = useContext(AppContext);

  const isTokenExpired = (exp: number) => {
    const currentTime = Math.floor(Date.now() / 1000); // Convert to Unix timestamp
    return exp < currentTime;
  };

  useEffect(() => {
    console.log(" 1 appContext.tokenExpire", appContext.tokenExpire);
    console.log("useEffect checkAuth");
    const checkAuth = async () => {
      console.log("checkAuth");
      const auth: any = localStorage.getItem(Auth.AuthKeys.openID);
      let userType: any = localStorage.getItem(Auth.AuthKeys._key_user);
      if (auth) {
        if (checkEmpty(userType)) {
          userType = "user";
          localStorage.setItem(Auth.AuthKeys._key_user, userType);
        }
        setTypeUser(userType);
        const parsedAuth = JSON.parse(auth);
        const { access_token, exp } = parsedAuth;

        // ตรวจสอบว่า token หมดอายุหรือยัง
        if (!isTokenExpired(exp)) {
          setAuth(parsedAuth); // Token ยังไม่หมดอายุ
        } else {
          console.log("Token has expired");
          localStorage.removeItem(Auth.AuthKeys.openID); // ลบ token ที่หมดอายุออก
          localStorage.removeItem(Auth.AuthKeys.user);
          setOpenTokenModal(true);
        }
      }
      setLoading(false); // Stop loading when auth is checked
    };

    checkAuth();
  }, []);

  useEffect(() => {
    console.log("appContext.tokenExpire", appContext.tokenExpire);
    if (appContext.tokenExpire) {
      setOpenTokenModal(true);
    }
  }, [appContext.tokenExpire]);

  const handleLogout = () => {};
  const handleCloseTokenModal = () => {
    setOpenTokenModal(false);
  };
  const handleRenewToken = () => {};

  

  if (auth?.access_token && typeUser === "user") {
    return (
      <LeftMenu>
        <Outlet />
        <TokenExpireModal
          open={openTokenModal}
          onClose={handleCloseTokenModal}
          onRenewToken={handleRenewToken}
          onLogout={handleLogout}
          canRefreshToken={canRefreshToken}
        />
      </LeftMenu>
    );
  }

  if (auth?.access_token && typeUser === "guest") {
    return <Outlet />;
  }
  if (!loading && (!auth?.access_token || !typeUser)) {
    return <Outlet />;
  }

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && auth?.access_token && typeUser === "user" && (
        <LeftMenu>
          <Outlet />
        </LeftMenu>
      )}
      {!loading && auth?.access_token && typeUser === "guest" && <Outlet />}
      {!loading && (!auth?.access_token || typeUser !== "user") && (
        <Navigate to={pathRoutes.Generate} />
      )}
      {/* {!loading && (!auth?.access_token || !typeUser) && <Outlet />} */}
    </>
  );
};

export default PrivateRoute;
