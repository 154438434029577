import { base64EmptyV1, base64EmptyV2 } from "./base64";
import renderBOScreens from "./renderBoScreens";
import renderButtonScreens from "./renderButtonScreens";
import renderLabelScreens from "./renderLabelScreens";
import addListTableOneToManyScreens from "./listTable/oneToMany/addListTableOneToManyScreens";
import updateListTableOneToManyScreens from "./listTable/oneToMany/updateListTableOneToManyScreens";
import { BO, BUTTON, ICON, IMAGE, LABEL } from "src/constants/PageConfig";
import renderImageScreens from "./renderImageScreens";
import renderIconScreens from "./renderIconScreens";
import { LIST_TABLE } from "src/constants/InputType";

export default function renderListTableContainer(
  mainContainer,
  screenElement,
  screen,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page,
  datetime
) {
  let fnNameFormat = getFormatFunctionByConfigScreenAndDatetime(
    JsonCONFIGSCREEN,
    datetime
  );
  if (pageName == "mainPage") {
    renderListTableContainerMain(
      mainContainer,
      screenElement,
      screen,
      maxColumn,
      filehubUrlImage,
      screensEditWithPosition,
      JsonCONFIGSCREEN,
      pageName,
      page,
      fnNameFormat
    );
  } else if (pageName == "addPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      addListTableOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      addListTableScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName
      );
    }
  } else if (pageName == "editPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      updateListTableOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      updateListTableScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        pageName
      );
    }
  }
}

function renderListTableContainerMain(
  mainContainer,
  screenElement,
  screen,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page,
  fnNameFormat
) {
  let addPageName = page.addPage.pageName;

  let gridColumn = `1 / span ${maxColumn}`;
  screenElement.style.zIndex = 9;
  screenElement.style.gridColumn = gridColumn;

  // สร้าง Search Form
  const searchForm = createSearchForm(screen);
  screenElement.appendChild(searchForm);

  // สร้าง button
  const contentButton = document.createElement("div");
  contentButton.id = "box-content-button-" + screen.key;
  contentButton.className = "content-button";

  const deleteButtonId = `deleteBtnIcon-${screen.key}`; //content-delete-button-${screen.key}

  let deleteBtnText = `<div id="${deleteButtonId}">
                          <a id="delete-btn-icon-${screen.key}">
                                  <span class="ow ion-android-delete span-0bccf55774-responsive btn-icon" style="font-size: 30px; cursor: pointer;"></span>
                          </a>
                       </div>`;

  let addBtnText = `<div id="content-add-button-${screen.key}">
     <a id="add-btn-icon-${screen.key}" pg-ref="${addPageName}">
         <span class="ow ion-android-add-circle span-0bccf55774-responsive btn-icon"  style="font-size: 30px;cursor: pointer;"></span>
     </a></div>`;

  contentButton.insertAdjacentHTML("beforeend", deleteBtnText);
  contentButton.insertAdjacentHTML("beforeend", addBtnText);

  screenElement.appendChild(contentButton);

  createListTable(
    screenElement,
    screen,
    pageName,
    JsonCONFIGSCREEN,
    fnNameFormat
  );
}

//Generate format ของฟังก์ชั่น ตัวอย่างแบบนี้ "mainPage_Products_20240902084714"
function getFormatFunctionByConfigScreenAndDatetime(
  JsonCONFIGSCREEN,
  datetime
) {
  let listTable = JsonCONFIGSCREEN.screens.find(
    (item) => item.configType === LIST_TABLE
  );
  let boName = listTable.valuePath || "";
  return `mainPage_${boName}_${datetime}`;
}

// สร้าง form search
function createSearchForm(screen) {
  const searchForm = document.createElement("form");
  searchForm.id = "search-form-" + screen.configType + "-" + screen.key;
  searchForm.className = "search-form";

  const searchIcon = document.createElement("i");
  searchIcon.className = "anticon anticon-search";
  searchIcon.style.position = "absolute";
  searchIcon.style.marginLeft = "5px";
  searchIcon.style.color = "rgba(171, 171, 171, 1)";

  const clearIcon = document.createElement("i");
  clearIcon.className = "fas fa-times clear-icon";
  clearIcon.style.display = "none";

  const searchButton = document.createElement("button");
  searchButton.id = "searchButton-" + screen.configType + "-" + screen.key;
  searchButton.setAttribute("type", "button");
  searchButton.textContent = "Search";
  searchButton.className = "ant-btn ant-btn-primary search-button";

  let searchInput = `
        <input 
            placeholder="Search..." 
            type="text" 
            id="search-input-${screen.configType}-${screen.key}"
            class="search-input dt-search" 
            pg-state="State.search"
        />
    `;

  searchForm.insertAdjacentHTML("beforeend", searchInput);
  searchForm.appendChild(searchIcon);
  searchForm.appendChild(clearIcon);
  searchForm.appendChild(searchButton);

  return searchForm;
}

function createListTable(
  screenElement,
  mainScreen,
  pageName,
  JsonCONFIGSCREEN,
  fnNameFormat
) {
  // console.log("createListTable fnNameFormat", fnNameFormat);
  // สร้างตาราง
  const tableElement = document.createElement("table");
  tableElement.id = `dataTable-${pageName}-${mainScreen.key}`;
  tableElement.className = "table table-bordered fixed-table";
  tableElement.style.marginTop = "25px";
  tableElement.style.width = "100%";

  const tableHead = document.createElement("thead");
  const tableHeadRow = document.createElement("tr");

  // เพิ่มคอลัมน์ Checkbox All
  const checkboxAllTh = document.createElement("th");
  const checkboxAll = document.createElement("input");
  checkboxAll.type = "checkbox";
  checkboxAll.id = `checkbox-all-childs-${pageName}-${mainScreen.key}`;
  checkboxAllTh.appendChild(checkboxAll);

  // let checkboxAllInnerText = `<input type="checkbox" 
  //                                id="checkbox-all-childs-${pageName}-${mainScreen.key}" 
  //                                onchange="onChangeSelectAllCheckBox_${fnNameFormat}(this)"
  //                              >`
  // checkboxAllTh.innerHTML = checkboxAllInnerText;
  tableHeadRow.appendChild(checkboxAllTh);

  // Sort the columns based on the seq property
  mainScreen.configColums.sort((a, b) => a.seq - b.seq);

  console.log("columns==> ", mainScreen.configColums);
  // สร้างคอลัมน์ตามข้อมูลที่กำหนดใน table.columns
  mainScreen.configColums.forEach((column) => {
    console.log("columns==>:::  ", column);
    const columnTh = document.createElement("th");
    columnTh.textContent = column.columnName;
    tableHeadRow.appendChild(columnTh);
  });

  // Add the action column header
  const actionColumnTh = document.createElement("th");
  actionColumnTh.textContent = "action";
  tableHeadRow.appendChild(actionColumnTh);

  tableHead.appendChild(tableHeadRow);
  tableElement.appendChild(tableHead);

  let base64Empty = base64EmptyV1;

  // สร้างส่วนเนื้อหาของตาราง (tbody)
  const tableBody = document.createElement("tbody");
  tableBody.id = `tableBody-childs-${pageName}-${mainScreen.key}`;

  let imageKey = "";
  let tdTemps = "";
  mainScreen.configColums.forEach((column, index) => {
    if (column.configType === "image_input") {
      column.columnName = column.key;
      imageKey = column.key;
    }

    console.log("configType:: ", column.configType === "image_input");

    let tdTemp = `
        <td id="column-child-${mainScreen.key}-${column.columnName}-{{$index}}"
             ${
               column.configType !== "image_input"
                 ? `pg-store="${column.columnName}"  
            pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${mainScreen.key}_list"`
                 : ""
             }
        > ${
          column.configType === "image_input"
            ? `
                <img  class="image-card-list-table"
                    style=" 
                        background: url(${base64Empty}) center center / cover no-repeat; 
                        background: url(&quot;{{SCOPE_URL_IMAGE}}{{Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${mainScreen.key}_list[$index].${imageKey}}}&quot;) center center / cover no-repeat, url(${base64Empty}) center center / cover no-repeat; 
                    ">
                `
            : column.columnName
        }</td>`;

    tdTemps = tdTemps + tdTemp;
  });

  // Add the checkbox column
  let tdCheckbox = `<td><input type="checkbox" id="checkbox-child-${mainScreen.key}-{{$index}}" class="row-checkbox-mainPage"></td>`;

  // Add the action column
  let tdAction = `<td style="display: flex;gap: 20px;border-color: white;">
                    <div id="action-edit-button-${mainScreen.key}-{{$index}}">
                    <a id="edit-btn-icon-${mainScreen.key}">
                            <span class="ow ion-edit span-0bccf55774-responsive btn-icon" style="font-size: 25px; cursor: pointer;"></span>
                    </a>
                    </div>
                    <div id="action-delete-button-${mainScreen.key}-{{$index}}"  style="display: flex;align-items: center;">
                      <span style="font-size: 28px;" class="ow ion-android-delete btn-icon" id="action-delete-span-${mainScreen.key}-{{$index}}"></span>
                    </div>
                  </td>`;

  let trRemp = `<tr id='row-child-${mainScreen.key}-{{$index}}' pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;]" pg-trackby="$index"
        pg-store="${JsonCONFIGSCREEN.flow.flowName}_flow_output.${mainScreen.key}_list" pg-is-list="true">${tdCheckbox}${tdTemps}${tdAction}</tr>`;

  tableBody.insertAdjacentHTML("beforeend", trRemp);
  tableElement.appendChild(tableBody);

  // เพิ่มตารางลงใน screenElement
  screenElement.appendChild(tableElement);

  var paginationContainer = ` <div id="pagination-container-${mainScreen.configType}-${mainScreen.key}" class="pagination-container">
    <span
        id="item-count-text-${mainScreen.configType}-${mainScreen.key}" class="item-count-text">
        </span>
        <div id="pagination-button-${mainScreen.configType}-${mainScreen.key}" class="pagination-button-content">
          <button id="previous-button-${mainScreen.configType}-${mainScreen.key}" class="pagination-button previous-button">Previous</button>
          <button id="next-button-${mainScreen.configType}-${mainScreen.key}" class="pagination-button next-button">Next</button>
        </div>
      </div>`;

  tableElement.insertAdjacentHTML("afterend", paginationContainer);

  document.body.appendChild(screenElement);
}

function addListTableScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-add-list-table-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-container-add-Wrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "add");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "add", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
}

function updateListTableScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-edit-list-table-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-containerWrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "edit");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "edit", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
}
