import React from "react";

function EnterpriseIcon() {
  return (
    <div style={{
      width: '100px',
      height: '100px',
    }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width='100px'
        height='100px'
        fill="none"
        viewBox="0 0 483 483"
      >
        <path
          fill="#2684FF"
          d="M447.752 229.674 259.726 47.816 241.5 30.187 99.961 167.084l-64.713 62.591c-6.747 6.534-6.747 17.118 0 23.652l129.311 125.07 76.941 74.416 141.539-136.895 2.192-2.12 62.521-60.471c6.747-6.534 6.747-17.118 0-23.652M241.5 303.979 176.902 241.5l64.598-62.479 64.598 62.479z"
        ></path>
        <path
          fill="url(#paint0_linear_25_573)"
          fillRule="evenodd"
          d="M241.501 179.021c-42.294-40.911-42.5-107.175-.462-148.331L99.674 167.362l76.94 74.417z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint1_linear_25_573)"
          fillRule="evenodd"
          d="M306.271 241.332 241.5 303.978c20.414 19.733 31.883 46.503 31.883 74.417s-11.469 54.685-31.883 74.417l141.712-137.063z"
          clipRule="evenodd"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_25_573"
            x1="229.965"
            x2="122.208"
            y1="115.817"
            y2="162.866"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#0052CC"></stop>
            <stop offset="1" stopColor="#2684FF"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_25_573"
            x1="253.843"
            x2="361.407"
            y1="366.457"
            y2="319.742"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#0052CC"></stop>
            <stop offset="1" stopColor="#2684FF"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div >
  );
}

export default EnterpriseIcon;
