import React, { useState, useEffect, useRef, useCallback }from 'react'
import styles from "./UploadExcelCreateBO.module.scss";
import { Button, Input, Modal } from "antd";
// import type { UploadProps } from 'antd';
import { message, Upload, Progress } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import IconUpload from "../../../../../assets/svg/upload-file-icon"
import { FileExcelOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from "uuid";
import { DISPLAY_VIEW_SELECT } from "src/utils/create-bo/DisplayViewSelect";
import { DISPLAY_EDIT_SELECT } from "src/utils/create-bo/DisplayEditSelect";
import debounce from 'lodash/debounce';
import MessageNofify from "../../notification/MessageNotify";
import ModalWarring from "./ModalWarning";
import { BOConfigItems } from "src/types/BoConfigType";

const { Dragger } = Upload;

interface ExcelRow {
    'Field Name': string; 
    [key: string]: any;   
}
interface RcFile extends File {
    lastModifiedDate?: Date;
    uid: string | number;
}
interface jsonExcelfile {
    file_id : string;
    file_name : string;
}


type Props = {
    modalDetail: { title: string };
    isModalOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    handleStateUpdate: (state: any) => void;
};


function UploadExcelCreateBO({
    modalDetail,
    isModalOpen,
    onOk,
    onCancel,
    handleStateUpdate,}: Props){

    let [isOpenUploadExcelCreateBOModal, setUploadExcelCreateBOModal] = useState<boolean>(false);
    let [ArrBOConfigItems, setArrBOConfigItems] =  useState<BOConfigItems[]>([]);

     //MESSAGE NOTIFY STATE
    let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
    let [titleNotify, setTitleNotify] = useState<string>("");
    let [descriptionNotify, setDescriptionNotify] = useState<string>("");
    let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

    //WARNING MODAL STATE
    let [openWarning, setOpenWarning] = useState<boolean>(false);
    let [isInvalidFormat, setisInvalidFormat] = useState<boolean>(false);
    let [fileNameInvalid, setFileNameInvalid] = useState<jsonExcelfile[]>([]);
    let [titleWaring, setTitleWaring] = useState<string>("");
    let [descriptionWarning, setDescriptionWarning] = useState<string>("");
    let [conditionWarning, setConditionWarning] = useState<string>("");

    useEffect(() => {
        if (isModalOpen) {
            window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
        } else {
            window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
        }
    }, [isModalOpen]);

    const onContinueModalWarning = () => {
        console.log("===== onCloseModalWarning");
        window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
        setOpenWarning(false);
        handleUpload(ArrBOConfigItems)
        // setUploadExcelCreateBOModal(false);
        onOk()
    };

    const onCloseModalWarning = () => {
        console.log("===== onOpenModalWarning");
        setOpenWarning(false);
        window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    };

    // const createRcFile = (file: File): RcFile => {
    //     const rcFile: RcFile = file as RcFile;
    //     rcFile.uid = Date.now();
    //     return rcFile;
    //   };
    const expectedProperties = [
        "Field Name",
        "Data Type",
        "Input Type",
        "Display (View)",
        "Display (Edit)",
        "Hidden",
        "Parent"
    ];
      
    function isValidObjectKeys(obj: Record<string, unknown>): boolean {
        const objKeys = Object.keys(obj);
      
        for (const key of objKeys) {
            if (!expectedProperties.some(expectedKey => expectedKey === key)) {
                // console.log("key ; ", key)
              return false;
            }
        
            // if (typeof obj[key] !== 'string') {
            //   return false;
            // }
          }
        return true;
    }

    const convertInputType = (input: string): string => {
        const typeMap: [string, string][] = [
            ['area', 'textarea'],
            ['text', 'text'],
            ['pass', 'password'],
            ['email', 'email'],
            ['date', 'date'],
            ['radio', 'radio'],
            ['num', 'number'],
            ['dropdown', 'dropdown'],
            ['file', 'file_input'],
            ['image', 'image_input'],
            ['img', 'image_input'],
            ['checkbox', 'checkbox']
        ];
    
        const lowerInput = input.toLocaleLowerCase();
        for (const [key, value] of typeMap) {
            if (lowerInput.includes(key)) {
                return value;
            }
        }
    
        return 'text';
    };

    // const onCancelUploadExcel = () => {
    //     setUploadExcelCreateBOModal(false);
    // }; 

    const checkFormatFileExcel = (json:any[], fileName:string, file_id:string) => {
        json.forEach((row) => {
            if(!isValidObjectKeys(row)){
                setisInvalidFormat(true)
                setFileNameInvalid(prevItems => {
                    if (prevItems.some(item => item.file_id === file_id)) {
                        return prevItems;
                    }
                    return [...prevItems, { 
                        file_name: fileName,
                        file_id: file_id
                     }];
                });
            }
        })
    }
        
    const onContinueUploadExcel = () => {
        if(ArrBOConfigItems.length > 0){
            if(isInvalidFormat){
                setTitleWaring("is invalid format!")
                setDescriptionWarning("Do you want to continue ?")
                setConditionWarning("invalid")
                setOpenWarning(true)
            }else{
                handleUpload(ArrBOConfigItems)
                // setUploadExcelCreateBOModal(false);
                onOk()
            }
        }else{
            setTitleWaring("File is not uploaded")
            setDescriptionWarning("Please upload file")
            setConditionWarning("empty")
            setOpenWarning(true)
            
        }
    };     

    const handleUpload = (newState: any) => {
        newState = ArrBOConfigItems
        console.log("newState => ", newState)
        handleStateUpdate(newState);
    };

    const handleFileRead = (file: RcFile, file_id: string, file_name: string) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json: ExcelRow[] = XLSX.utils.sheet_to_json(worksheet);
            console.log("json => ", json);

            let parentID = ''
            let parent_name = ''
            let ParentObj: any[] = []

            checkFormatFileExcel(json, file_name, file_id)

            // if(openWarning){
                // LOOP SET PARENT OBJ ID & NAME
                json.forEach((row) => {
                    if(row['Data Type'] && row['Data Type'].toLowerCase() == 'object'){
                        parent_name = row['Field Name'] != undefined ? row['Field Name'] : ''
                        parentID = uuidv4()
    
                        const objParent = {
                            name: parent_name,
                            id: parentID
                        }
                        ParentObj.push(objParent)
                        // setParentObjData(prevItems => [...prevItems, objParent])
                    }
                });
    
    
                json.forEach((row) => {
                    let refID = null
                    let Hidden = false
                    if(row['Parent']){
                        refID = ParentObj.length > 0 ? ParentObj.map((item) => {
                            if (item.name.toLowerCase() === row['Parent'].toLowerCase()) {
                                return item.id;
                            }
                            return null; 
                        }).filter(id => id !== null)[0] : ''
    
                    }
                    // console.log("refID : ", refID)
    
                    if(row['Data Type'] && row['Data Type'].toLowerCase() == 'object'){
                        parent_name = row['Field Name'] != undefined ? row['Field Name'] : ''
    
                        parentID = ParentObj.length > 0 ? ParentObj.map((item) => {
                            if (item.name.toLowerCase() === parent_name.toLowerCase()) {
                                return item.id;
                            }
                            return null; 
                        }).filter(id => id !== null)[0] : ''
                        
                        let objParentFromExcel = {
                            "id": parentID,
                            "isChecked": false,
                            "bo_name": parent_name,
                            "data_type": row['Data Type'] != undefined ? row['Data Type'] : '',
                            "input_type": "text",
                            "display_view": "Show as Label",
                            "display_edit": "text",
                            "show_status": false,
                            "ref_id": refID,
                            "displayView": "text-show_as_label",
                            "displayEdit": "text",
                            "file_id": file_id
                        }
                        setArrBOConfigItems(prevItems => [...prevItems, objParentFromExcel]);
    
                    // }else if(obj_parent_name && obj_parent_name.toLowerCase() == row['Parent'].toLowerCase()){
                    }else{
                        if(row['Hidden'] && !row['Hidden'].toLowerCase().includes('n')){
                            Hidden = true
                        }
                        let display_view_value = 'Show as Label'
                        let inputType = convertInputType(row['Input Type'] != undefined ? row['Input Type'] : '')
                        let displayViewMapping 
                        let disPlayEditeMapping
                        let valueEditeMapping
                        if(row['Display (View)']){
                            displayViewMapping = DISPLAY_VIEW_SELECT[inputType].find(option => option.label === row['Display (View)']);
                        }
                        if (displayViewMapping) {
                            display_view_value = displayViewMapping.value;
                        }
                        
                        if(row['Input Type']){
                            let showText = DISPLAY_EDIT_SELECT[inputType].showText
                            let valueText = DISPLAY_EDIT_SELECT[inputType].showText
                            console.log("inputType => ", inputType)
                            if(inputType === 'dropdown' || inputType === 'radio' || inputType === 'file_input'){
                                showText =  DISPLAY_EDIT_SELECT[inputType]?.selects?.[0]?.label || '';
                                valueText = DISPLAY_EDIT_SELECT[inputType]?.selects?.[0]?.value || '';
                            }else if(inputType == 'text'){
                                showText = DISPLAY_EDIT_SELECT[inputType]?.selects?.find(item => item.value === row['Display (Edit)'])?.label || "Text"
                                valueText = DISPLAY_EDIT_SELECT[inputType]?.selects?.find(item => item.value === row['Display (Edit)'])?.value || "text"
                            }

                            console.log("showText => ", showText)
                            console.log("valueText => ", valueText)
                            disPlayEditeMapping = showText
                            valueEditeMapping = valueText
                        }

        
                        let objChildFromExcel = {
                            "id": uuidv4(),
                            "isChecked": false,
                            "bo_name": row['Field Name'] != undefined ? row['Field Name'] : '',
                            "data_type": row['Data Type'] != undefined ? row['Data Type'] : '',
                            "input_type": inputType,
                            "display_view": row['Display (View)'] != undefined ? row['Display (View)'] : '',
                            "display_edit": valueEditeMapping || '',
                            "show_status": Hidden,
                            "ref_id": refID,
                            "displayView": display_view_value,
                            "displayEdit": disPlayEditeMapping || '',
                            "file_id": file_id
                        }
        
                        setArrBOConfigItems(prevItems => [...prevItems, objChildFromExcel]);
                    }
                    // console.log("ArrBOConfigItems => ", ArrBOConfigItems)
                });
                handleStateUpdate(ArrBOConfigItems)
            // }
        };
        reader.readAsArrayBuffer(file);
    };

    const scrollableDivRef = useRef<HTMLDivElement>(null);


    const debouncedHandleFileRead = useCallback(
        debounce((fileList: UploadFile<any>[]) => {
          const uploadedFiles = fileList.filter(f => !f.status || f.status === 'done');
          setArrBOConfigItems([]);
          if (uploadedFiles.length > 0) {
            Array.from(uploadedFiles).forEach(file => {
                const uploadedFile = file;
                const file_id = uploadedFile.originFileObj?.uid;
                const file_name = uploadedFile.originFileObj?.name;
                if (uploadedFile.originFileObj && file_id) {
                  handleFileRead(uploadedFile.originFileObj as any, file_id, file_name as string);
                }
            });
          }
        }, 50),
        []
    );
    

    const UploadProps = {
        name: 'file',
        multiple: true,
        accept: ".xlsx, .xls",
        onChange(info: UploadChangeParam<UploadFile<any>>) {
            const { file, fileList } = info;
            const { status } = file;
            
            if (status !== 'removed' && fileList.length ) {
                debouncedHandleFileRead(fileList);
                setTriggerNotify(!triggerNotify);
                setTitleNotify("Upload Excel successfully");
                setDescriptionNotify("Continue to create BO");
                setIsSuccessNotify(true);
            }else if(status === 'removed'){
                const removedFileid= file.uid;
                const removedFileName = file.name;
                console.log("removedFileName => ", removedFileName)
                ArrBOConfigItems = ArrBOConfigItems.filter(item => item.file_id != removedFileid);
                let fileNameInvalidFromState:jsonExcelfile[] = fileNameInvalid

                setFileNameInvalid([])
                let fileInvalidList:jsonExcelfile[] = []
                fileNameInvalidFromState.forEach((item: jsonExcelfile) => {
                    if(item.file_id !== removedFileid){
                        fileInvalidList.push(item)
                        setFileNameInvalid(fileInvalidList)
                    }
                })

                if(fileInvalidList.length === 0 ){
                    setisInvalidFormat(false)
                }
   
                if(ArrBOConfigItems.length > 0 ){
                    setArrBOConfigItems(ArrBOConfigItems)
                }else{
                    setArrBOConfigItems([]);
                }

                setTriggerNotify(!triggerNotify);
                setTitleNotify("File removed successfully");
                setDescriptionNotify("Upload new file");
                setIsSuccessNotify(true);
            }

            // if (status !== 'uploading') {
            //     // console.log(info.file, info.fileList);
            // }
            //   if (status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully.`);
            //   } else if (status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            //   }
        },
        onDrop(e: React.DragEvent<HTMLDivElement>) {
            console.log('Dropped files', e.dataTransfer.files);
            e.preventDefault(); 
            e.stopPropagation();
        },
        beforeUpload: () => false,
        iconRender(file: UploadFile<any>) {
            if (file.status === 'uploading') {
              return <LoadingOutlined />;
            }
            return <FileExcelOutlined />;
        },
    };

    return (
        <>
        <Modal
            title={<div className={styles.modalTitle}>{modalDetail.title}</div>}
            footer={
            <div className={styles.modalFooter}>
                <Button
                    className={styles.cancelBtn}
                    onClick={onCancel}
                    key="cancel"
                >
                Cancel
                </Button>
                <Button
                    className={styles.conBtn}
                    key="continue"
                    onClick={onContinueUploadExcel}
                >
                Continue
                </Button>
                
            </div>
            }
            // onCancel={onCancelCreateAndEditBo}
            centered={true}
            width={ 800 }
            style={{ minWidth: '750px'}}
            open={isModalOpen}
            onCancel={onCancel}
        >
            <div className={styles.modalContent}>
                <div className={styles.divInputUploadfile}>
                    <Dragger {...UploadProps} className={styles.Dragger}>
                        <div className={styles.divContainerEl}>
                            <IconUpload />
                            <p className="ant-upload-text" style={{color: "#393939" ,fontWeight: "600"} }  >Drag and drop files here</p>
                            <p style={{color: "gray",fontSize: "12px"} } className="ant-upload-text">*support file .xls, .xlsx</p>
                            <p>or</p>
                            <Button 
                                className={styles.conBtn}
                                key="browse"
                            >
                                Browse
                            </Button>
                        </div>
                    </Dragger>
                    {/* <div className={styles.inputFieldContainer} ref={scrollableDivRef}>
                        {ArrBOConfigItems.length > 0 ? (
                            ArrBOConfigItems.map((item, index) => (
                                <p key={index}>{index+1} {item.bo_name} - {item.displayView} - {item.display_view} = {item.input_type}</p>
                            ))
                            ) : (
                            <p>No items available</p>
                        )}
                    </div> */}
                </div>
            </div>
        </Modal>

        <MessageNofify
            trigger={triggerNotify}
            title={titleNotify}
            description={descriptionNotify}
            isSuccess={isSuccessNotify}
        />

        <ModalWarring
            open={openWarning}
            onCon={onContinueModalWarning}
            onCan={onCloseModalWarning}
            name={ fileNameInvalid }
            title={titleWaring}
            description={ descriptionWarning }
            condition={conditionWarning}
        />

        
        </>
    )
}

export default UploadExcelCreateBO