import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Tabs, Input, Select, Upload, message} from "antd";
import styles from "./ModalTreeLink.module.scss";
import { callApiMethodGet, getAspId, callApiMethodPost } from "src/services/util.service";
import ModalUpload from "./ModalUpload";
import type { GetProp, UploadProps } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  GET_STORY_BY_ASPID_ENDPOINT,
  GET_COMPONENT_BY_STORY_ID_ENDPOINT,
  UPLOAD_FILE_APP_COMPONENT_ENDPOINT,
  GET_FILE_BY_ID_APP_COMPONENT_ENDPOINT
} from "src/utils/endpoint/createbo.endpoint";
import EditImg from "../../../assets/svg/story-edit";

const { TabPane } = Tabs;

type StoryListByAspID = {
  value: string;
  label: string;
};

type ComponentByStoryID = {
  value: string;
  label: string;
};

type Props = {
  isOpenModalTreeLink: boolean;
  onOk: (
    name: string,
    configUrl: string,
    configStoryID: string,
    configComponentID: string,
    img_path: string,
    file_id: string
  ) => void;
  onCancel: () => void;
  menuDetail: {
    titleName: string;
    configUrl: string;
    configStoryID: string;
    configComponentID: string;
    configComponentName: string;
    img_path: string
    file_id: string
  };
  componentType: string;
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function ModalTreeLink({
  isOpenModalTreeLink,
  onOk,
  onCancel,
  menuDetail,
  componentType
}: Props) {
  let [storyList, setStoryList] = useState<StoryListByAspID[]>([]);
  let [componentByStoryID, setComponentByStoryID] = useState<
    ComponentByStoryID[]
  >([]);
  const [editDataReferName, setEditDataReferName] = useState<boolean>(false);
  let [inputName, setInputName] = useState<string>(menuDetail.titleName);
  let [inputConfigUrl, setInputConfigUrl] = useState<string>("");
  let [inputConfigStoryID, setInputConfigStoryID] = useState<string>("");
  let [inputConfigComponentID, setInputConfigComponentID] =
    useState<string>("");
  let [inputConfigComponentName, setInputConfigComponentName] =
    useState<string>("");

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageId, setImageId] = useState<string>("");

  const APIGetStoryByAspID = async () => {
    try {
      const response = await callApiMethodGet(GET_STORY_BY_ASPID_ENDPOINT, {
        asp_id: getAspId(),
      });

      if (response.status == 200) {
        // State : Set Story List
        const newDataStoryList = response.data.storyComponent.map(
          (item: any) => ({
            value: item.id,
            label: item.story_name,
          })
        );
        setStoryList(newDataStoryList);
      }
    } catch (error) {
      setStoryList([]);
    }
  };

  const APIGetComponentByStoryID = async (storyID: string) => {
    try {
      const response = await callApiMethodGet(
        GET_COMPONENT_BY_STORY_ID_ENDPOINT,
        {
          story_id: storyID,
        }
      );

      if (response.status == 200) {
        // State : Set Story List
        const newData = response.data.Component.map((item: any) => ({
          value: item.id,
          label: item.component_name,
        }));
        setComponentByStoryID(newData);
      }
    } catch (error) {
      setInputConfigComponentID("");
      setInputConfigComponentName("");
      setComponentByStoryID([]);
    }
  };

  useEffect(() => {
    console.log("menuDetail ", menuDetail)
    setEditDataReferName(false);
    setInputName(menuDetail.titleName);
    setInputConfigUrl(menuDetail.configUrl);
    setInputConfigStoryID(menuDetail.configStoryID);
    setInputConfigComponentID(menuDetail.configComponentID);
    setInputConfigComponentName(menuDetail.configComponentName);
    console.log("component type : ", componentType)
    console.log("menuDetail.file_id : ", menuDetail.file_id)
    if (isOpenModalTreeLink) {
      setImageId(menuDetail.file_id);
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
      if(componentType.toLocaleLowerCase().includes('menu')) APIGetFileByID(menuDetail.file_id);
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
      setImageId('');
      setImageUrl('');
    }
    APIGetStoryByAspID();
  }, [isOpenModalTreeLink]);

  const onChangeStory = (storyID: string) => {
    setInputConfigStoryID(storyID);
    APIGetComponentByStoryID(storyID);
  };

  const onChangePageName = (data: any) => {
    console.log("component name : ", data.value);

    setInputConfigComponentID(data.value);
    setInputConfigComponentName(data.label);
  };

  const onClickConfirm = () => {
    if(componentType.toLocaleLowerCase() === 'menu'){
      APICreateFile()
    }else{

    }
    onOk(
      inputName,
      inputConfigUrl,
      inputConfigStoryID,
      inputConfigComponentID,
      imageUrl,
      imageId
    )
  }

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      console.log("info.file.originFileObj ", info.file.originFileObj)
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      return;
    }
    // if (info.file.status === 'done') {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj as FileType, (url) => {
    //     setLoading(false);
    //     setImageUrl(url);
    //   });
    // }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload icon</div>
    </button>
  );

  const base64toFile = (base64String: string, fileName: string) => {
    // Split the base64 string to get the content type and the data
    const [, content] = base64String.split(";base64,");
  
    // Convert the base64 content to binary format
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a Blob object from the binary data
    const blob = new Blob([byteArray], { type: "image/jpeg" }); // Change the type according to your image format
  
    // Create a File object from the Blob
    const file = new File([blob], fileName, { type: "image/jpeg" }); // Change the type according to your image format
  
    return file;
  };

  const APICreateFile = async () => {
    try {
      const formData = new FormData();
      formData.append("appid", '');
      formData.append("type", "menu");
      formData.append(
        "file",
        base64toFile(imageUrl, componentType + ".jpg")
      );

      const response = await callApiMethodPost(
        UPLOAD_FILE_APP_COMPONENT_ENDPOINT,
        formData
      );

      if (response.status == 200) {
        setImageId(response.data.id)
        // onOk(response.data.id);
        onOk(
          inputName,
          inputConfigUrl,
          inputConfigStoryID,
          inputConfigComponentID,
          imageUrl,
          response.data.id
        )
        // setLoadingButtonContinue(false);
      }
    } catch (error) {
      console.log("error upload file : ", error);
    }
  };

  const APIGetFileByID = async (fileId: string) => {
    try {
      const response = await callApiMethodGet(
        GET_FILE_BY_ID_APP_COMPONENT_ENDPOINT,
        { file_id: fileId }
      );

      if (response.status == 200) {
        setImageUrl(`data:image/jpeg;base64,${response.data.path}`);
      }
    } catch (error) {
      console.log("error upload file : ", error);
    }
  };

  return (
    <div>
      <Modal
        title={
          <div>
            <div className={styles.inputNameContainer}>
              {editDataReferName == true ? (
                <>
                  <Input
                    type="text"
                    value={inputName}
                    style={{ width: 200 }}
                    onChange={(e) => {
                      setInputName(e.target.value);
                    }}
                  />
                </>
              ) : (
                <>{menuDetail.titleName}</>
              )}
              <div
                onClick={() => setEditDataReferName(true)}
                style={{ marginLeft: "10px" }}
              >
                <EditImg width={"20px"} height={"20px"} />
              </div>
            </div>
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button className={styles.cancelBtn} onClick={onCancel} type="text">
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              onClick={ onClickConfirm
                // onOk(
                //   inputName,
                //   inputConfigUrl,
                //   inputConfigStoryID,
                //   inputConfigComponentID
                // )
              }
              type="primary"
            >
              Confirm
            </Button>
          </div>
        }
        open={isOpenModalTreeLink}
        // onOk={() => onOk}
        onCancel={onCancel}
        // centered
        width={500}
      >
        <Tabs defaultActiveKey="1" tabPosition="top">
          <TabPane tab="URL" key="1">
            <p>URL</p>
            <Input
              value={inputConfigUrl}
              onChange={(e) => setInputConfigUrl(e.target.value)}
            />
          </TabPane>
          <TabPane tab="Story" key="2">
            <p>Story</p>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Select story"
              optionFilterProp="children"
              value={inputConfigStoryID}
              onChange={(value) => onChangeStory(value)}
              options={storyList}
            />
            <p>Page Name</p>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Select story"
              optionFilterProp="children"
              value={inputConfigComponentName}
              onChange={(value) => onChangePageName(value)}
              options={componentByStoryID.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              labelInValue
            />
          </TabPane>
        </Tabs>
        {
          componentType.toLocaleLowerCase().includes('menu') &&
          <div className={styles.uploadIconContainer}>
            {/* <label> Upload icon </label> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </div>
        }
      </Modal>
    </div>
  );
}

export default ModalTreeLink;
