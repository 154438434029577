import React from "react";

const expand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="-51.2 -51.2 614.40 614.40" fill="#8c8c8c">
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <title>ionicons-v5-c</title>
      <polyline 
        points="304 96 416 96 416 208" 
        style={{ fill: 'none', stroke: '#8c8c8c', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: 36 }}
      />
      <line 
        x1="405.77" 
        y1="106.2" 
        x2="111.98" 
        y2="400.02" 
        style={{ fill: 'none', stroke: '#8c8c8c', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: 36 }}
      />
      <polyline 
        points="208 416 96 416 96 304" 
        style={{ fill: 'none', stroke: '#8c8c8c', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: 36 }}
      />
    </g>
  </svg>
  );
};

export default expand;
