import React from "react";

const StoryEditEntGenPageBreadcrumpLine = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 101 3"
      width={width || "101"}
      height={height || "3"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5H100"
        stroke="#1C87F2"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default StoryEditEntGenPageBreadcrumpLine;
