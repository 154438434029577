import React from "react";
import {Col, Layout, Row, Spin} from "antd";
import styles from "./UIFirstPage.module.scss";
import {useTranslation} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons";

const { Header, Footer, Sider, Content } = Layout;

function UILoading(props: any) {
  const { t, i18n } = useTranslation();
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  return (
    <Layout>
      <Content>
        <Row
          style={{
            height: "100vh",
            fontSize: "20px",
          }}
        >
          <Col className={styles.boxIconWhite} style={{ width: "100%" }}>
            <div className={styles.boxIconCenterWhite}>
              {/* <LogoLoading {...props} /> */}
              {/* {t("Loading")}... */}
              <Spin 
              // tip={t("Loading") + "..."}
               indicator={antIcon} />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default UILoading;
