import React, { ChangeEvent, EventHandler, KeyboardEventHandler, MouseEventHandler, useState } from "react";
import styles from "./CommentMessageBox.module.css";
import { Button, Input, Popover } from "antd";
import { FaPlusCircle, FaTelegramPlane, FaUpload } from "react-icons/fa";
import { ME } from "../constants/chatbubble.constant";

type Props = {
  isLoading: boolean;
  addNewMessage: (message: any) => void;
};

export default function CommentMessageBox({ isLoading, addNewMessage }: Props) {
  let [comment, setComment] = useState<string>("");
  let [isOpenPopOver, setIsOpenPopOver] = useState(false);

  const handleChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.currentTarget.value;
    setComment(input);
  };

  const handleOpenPopover = () => {
    setIsOpenPopOver((prev) => !prev);
  };

  // const handleSendMessage = (
  //   e:
  //     | MouseEventHandler<HTMLButtonElement>
  //     | KeyboardEventHandler<HTMLInputElement>
  // ) => {
  //   // Handle "Enter" key press
  //   if ("key" in e && e.key !== "Enter") {
  //     return; // Exit if the key pressed is not "Enter"
  //   }

  //   let newComment = {
  //     agent: ME,
  //     text: comment,
  //     chatColor: "#F8F9FA",
  //   };
  //   addNewMessage(newComment);

  //   setComment("");
  // };

  const handleSendMessageByButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    let newComment = {
      agent: ME,
      text: comment,
      chatColor: "#F8F9FA",
    };
  
    addNewMessage(newComment);
    setComment(""); // Reset comment field
  };
  
  const handleSendMessageByKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
    // Only submit if "Enter" key is pressed
    if (e.key === 'Enter') {
      e.preventDefault();
  
      let newComment = {
        agent: ME,
        text: comment,
        chatColor: "#F8F9FA",
      };
  
      addNewMessage(newComment);
      setComment(""); // Reset comment field
    }
  };

  return (
    <div className={styles.commentMessageBoxContainer}>
      <div className={styles.commentMessageBoxWrapper}>
        <div className={styles.textToolOptionContainer}>
          <Popover
            content={<PopoverContent onClose={handleOpenPopover} />}
            trigger="click"
            open={isOpenPopOver}
            onOpenChange={handleOpenPopover}
          >
            <Button className={styles.leftToolBtn} disabled={isLoading}>
              <FaPlusCircle size={"22px"} color="#A0A1A9" />
            </Button>
          </Popover>
        </div>
        <div className={styles.textInputContainer}>
          <Input
            value={comment}
            onChange={handleChangeComment}
            placeholder={"Message PromptX"}
            className={styles.textInput}
            onKeyDown={handleSendMessageByKeyPress}
            disabled={isLoading}
          />
        </div>
        <div className={styles.rightToolContainer}>
          <Button
            onClick={handleSendMessageByButtonClick}
            // onKeyDown={handleSendMessageByKeyPress}
            className={styles.rightToolBtn}
            disabled={isLoading}
          >
            <FaTelegramPlane size={"22px"} color="#A0A1A9" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function PopoverContent({ onClose }: { onClose: () => void }) {
  return (
    <div className={styles.popOverContainer}>
      <a onClick={onClose}>
        <FaUpload />
        &nbsp; Attachment
      </a>
    </div>
  );
}
