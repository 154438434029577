import React, { useContext, useEffect, useState } from "react";
import { CHECK_BOX, DROPDOWN, RADIO } from "src/constants/InputType";
import { MoreSettingType } from "src/types/MoreSettingType";
import MoreSettingItem from "./MoreSettingItem";
import CreateBusinessObjectHandler from "src/utils/create-bo/CreateBusinessObjectHandler";
import MoreSettingConfigModal from "../../modal/modal-create-entity/modal-more-setting-config/MoreSettingConfigModal";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { DataReferDataType, DataReferResponse } from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import { DataReferRequest, SetDataReferRequest } from "src/types/request.type";
import {
  GET_DATA_REFER_ENDPOINT,
  SAVE_SET_DATA_REFER_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { getAspId } from "src/services/util.service";

type Props = {
  createBoHandler: CreateBusinessObjectHandler;
  isEditMode: boolean;
  isRerender: boolean
};

type OnSaveProps = {
  input_type: string;
  data_refer_name: string;
  data_refer_id: string;
};

const GET_DATA_REFER_FK = "getDataRefFK";
const SAVE_SET_DATA_REFER_FK = "saveSetDataRefFK";

function MoreSettingStep3({ createBoHandler, isEditMode, isRerender }: Props) {
  const { loading, error, responseData, fetchData, fetchKey } =
    useFetch<DataReferResponse>();
  let [moreSettingItems, setMoreSettingItems] = useState<MoreSettingType[]>([]);
  let [isOpenConfigModal, setIsOpenConfigModal] = useState<boolean>(false);
  let [dataListSelects, setDataListSelects] = useState<
    { label: string; value: string }[]
  >([]);
  let [dataListTableSelects, setDataListTableSelects] = useState<
    { label: string; value: string }[]
  >([]);
  let storyEntityContext = useContext(StoryEntityContext);

  let[isRerenderState, setIsRerenderState] = useState<boolean>(false);

  let aspId = getAspId();

  //GET SELECT DATA REFER ON LOAD
  useEffect(() => {
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*"); //FIX INTEGRATTION
    getDataReferSelects();
    console.log('isRerender ', isRerender)
  }, []);

  //--CHECK RESPONSE BY STATE USEFETCH FROM CALL API SUCCESS
  useEffect(() => {
    if (!loading) {
      switch (fetchKey) {
        case GET_DATA_REFER_FK: {
          if (!error) {
            let dataReferSelects: DataReferDataType[] = [];
            dataReferSelects =
              responseData?.data as unknown as DataReferDataType[];

            for (const dataRef of dataReferSelects) {
              if (dataRef.input_type === DROPDOWN) {
                setDataListTableSelects((prev) => [
                  ...prev,
                  {
                    label: dataRef.data_refer_name,
                    value: dataRef.data_refer_id,
                  },
                ]);
              } else {
                setDataListSelects((prev) => [
                  ...prev,
                  {
                    label: dataRef.data_refer_name,
                    value: dataRef.data_refer_id,
                  },
                ]);
              }
            }
          }

          //FILTER GET ONLY DROPDOWN, RADIO, CHECKBOX FROM BO_CONFIG_ITEMS
          let boConfigItems = storyEntityContext.boConfigItems;
          let boConfigForMoreSetting = boConfigItems.filter((item) =>
            [DROPDOWN, RADIO, CHECK_BOX].includes(item.input_type)
          );

          if(isEditMode){
            let moreSettingItemsContext = storyEntityContext.moreSettingItems;
            let DataReferMapping_forEdit: MoreSettingType[] = boConfigItems.map( (item) => {
              return {
                id: item.id,
                bo_name: item.bo_name,
                input_type: item.input_type,
                data_refer_id: item.data_refer_id
              }
            })
            console.log("DataReferMapping_forEdit => ", DataReferMapping_forEdit)
            storyEntityContext.setDataReferMapping(DataReferMapping_forEdit)

            console.log("moreSettingItemsContext => ", moreSettingItemsContext)
          }

          let dataReferMapping = storyEntityContext.dataReferMapping;
          let moreSettingByBoConfig: MoreSettingType[] =
            boConfigForMoreSetting.map((boConfigItem) => {
              return {
                id: boConfigItem.id,
                bo_name: boConfigItem.bo_name,
                input_type: boConfigItem.input_type,
              };
            });

          //RE MAPPING DATA REFER WHEN BACK TO THIS PAGE
          let newMoreSettingItems: MoreSettingType[] = [];
          if (dataReferMapping.length > 0) {
            newMoreSettingItems = structuredClone(moreSettingByBoConfig);
            for (const dataRefer of dataReferMapping) {
              let index = newMoreSettingItems.findIndex(
                (item) => item.id === dataRefer.id
              );
              if (index === -1) continue;
              newMoreSettingItems[index].data_refer_id =
                dataRefer.data_refer_id;
            }
          } else {
            newMoreSettingItems = moreSettingByBoConfig;
          }
          console.log("newMoreSettingItems => ", newMoreSettingItems)
          updateMoreSettingItems(newMoreSettingItems);

          break;
        }

        case SAVE_SET_DATA_REFER_FK: {
          if (error?.search("404") == undefined) {
            if (error) return;
          }

          let nextStep = storyEntityContext.currentStep + 1;
          let moreSettingItemsContext = storyEntityContext.moreSettingItems;

          let dataReferMappingList = [...moreSettingItemsContext].map(
            (item) => {
              return {
                id: item.id,
                bo_name: item.bo_name,
                input_type: item.input_type,
                data_refer_id: item.data_refer_id,
              };
            }
          );
          storyEntityContext.setDataReferMapping(dataReferMappingList);

          setMoreSettingItems(moreSettingItemsContext);
          storyEntityContext.setIsMapDataReferSave(false);
          storyEntityContext.setCurrentStep(nextStep);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //--CHECK STATE TRIGGER {isMapDataReferSave} FROM PARENT COMPONENT
  useEffect(() => {
    if (storyEntityContext.isMapDataReferSave) {
      let moreSettingItems = storyEntityContext.moreSettingItems;
      let requestSetDataRefer: SetDataReferRequest[] = [];
      for (const item of moreSettingItems) {
        if (item.data_refer_id) {
          requestSetDataRefer.push({
            enyity_bo_item_id: item.id,
            data_refer_id: item.data_refer_id,
          });
        }
      }
      saveSetDataReferController(requestSetDataRefer);
    }
  }, [storyEntityContext.isMapDataReferSave]);

  //ACTION BUTTON HANDLER
  const openModalConfigHandler = () => {
    setIsOpenConfigModal(true);
  };
  const onSaveConfigModalHandler = ({
    input_type,
    data_refer_name,
    data_refer_id,
  }: OnSaveProps) => {
    if (input_type === DROPDOWN) {
      setDataListTableSelects((prev) => [
        ...prev,
        { value: data_refer_id, label: data_refer_name },
      ]);
    } else {
      setDataListSelects((prev) => [
        ...prev,
        { value: data_refer_id, label: data_refer_name },
      ]);
    }
    setIsOpenConfigModal(false);
  };
  const onCancelConfigModalHandler = () => {
    setIsOpenConfigModal(false);
  };

  //API CONTROLLER FUNCTION
  const getDataReferByAspIdController = (req: DataReferRequest) => {
    fetchData(
      {
        endpoint: GET_DATA_REFER_ENDPOINT,
        method: "GET",
        // reqBody: req,
        params: {
          asp_id: req.asp_id,
        },
      },
      GET_DATA_REFER_FK
    );
  };
  const saveSetDataReferController = (req: SetDataReferRequest[]) => {
    fetchData(
      {
        endpoint: SAVE_SET_DATA_REFER_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      SAVE_SET_DATA_REFER_FK
    );
  };

  //UTILITY FUNCTION
  const isGetDataReferSelect = () => {
    let isSaveSetDataRefer = storyEntityContext.isMapDataReferSave;
    return responseData && !loading && !isSaveSetDataRefer;
  };

  const isSaveSetDataRefer = () => {
    let isSaveSetDataRefer = storyEntityContext.isMapDataReferSave;
    return responseData && !loading && isSaveSetDataRefer;
  };
  const getDataReferSelects = () => {
    let reqGetDataRefer: DataReferRequest = {
      asp_id: aspId as string,
      data_refer_name: "",
      order_by: "desc",
    };
    getDataReferByAspIdController(reqGetDataRefer);
  };
  const updateMoreSettingItems = (newMoreSettingItems: MoreSettingType[]) => {
    setMoreSettingItems(newMoreSettingItems);
    storyEntityContext.setMoreSettingItems(newMoreSettingItems);
    console.log("moreSettingItemsContext => ", storyEntityContext.moreSettingItems)
  };
  return (
    <div>
      <table id="MoreSettingTb">
        <thead>
          <tr>
            <th>Business Object</th>
            <th>Input Type</th>
            <th>Datareference</th>
          </tr>
        </thead>
        <tbody>
          {moreSettingItems.map((item, index) => {
            return (
              <MoreSettingItem
                key={index}
                moreSettingItem={item}
                openModalConfig={openModalConfigHandler}
                dataReferSelects={
                  item.input_type === DROPDOWN
                    ? dataListTableSelects
                    : dataListSelects
                }
                isEditMode={isEditMode}
              />
            );
          })}
        </tbody>
      </table>

      <MoreSettingConfigModal
        isModalOpen={isOpenConfigModal}
        onSave={onSaveConfigModalHandler}
        onCancel={onCancelConfigModalHandler}
        createBoHandler={createBoHandler}
      />
    </div>
  );
}

export default MoreSettingStep3;
