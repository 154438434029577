export let jsTextListsAdd = `

{_JSONCONFIG_SCREEN_}

let ACTION_TYPE = {
  ACTION_SELECT_ALL_MAIN: "select_all_main",
  ACTION_SELECT_MAIN: "select_main",
  ACTION_INSERT_MAIN: "insert_main",
  ACTION_UPDATE_MAIN: "update_main",
  ACTION_DELETE_MAIN: "delete_main",
  ACTION_INSERT_CHILDS:"insert_childs"
}
let FILE_HUB_URL_IMAGE = JSON_CONFIG_SCREEN.config.filehubUrlImage ? JSON_CONFIG_SCREEN.config.filehubUrlImage : '';
const SCREENS_EDIT_WITH_POSITION = JSON_CONFIG_SCREEN.screensEdit.filter(screen => screen.rownumber && screen.columnnumber);

let INPUT_CHILD_OBJECT;

function clickSaveItemFn($page,event) {
  console.log("===clickSaveItemFn===")
  console.log("$page",$page)
  console.log("event",event)

}

function getPrimaryKeyColumns(jsonConfig) {
  const primaryKeyColumns = [];

  // let mainBoName = jsonConfig.bo[0].boName;

  let primaryKeyColumnBo = [];
  let pathColumn;
  let pathFrom;
  jsonConfig.table.forEach(table => {
    table.columns.forEach(column => {
      if (column.primaryKey) {
        pathColumn = table.tableName + "." + column.column

        jsonConfig.mapping.forEach(mapping => {
          if (mapping.pathTo == pathColumn && mapping.pathFrom.split(".").length == 2) {
            pathFrom = mapping.pathFrom
          }
        })

      }
    });
  });

  

  let pathFroms = pathFrom.split(".");
  if (pathFroms.length > 0)
    primaryKeyColumnBo.push(pathFroms[pathFroms.length - 1])

  return primaryKeyColumnBo;
}


function transformBoData(bo, input) {
  const output = {};
  const filteredBo = JSON_CONFIG_SCREEN.bo.filter(item => item.boName === bo);
  filteredBo.forEach(item => {
    const boName = item.boName;
    const fields = item.fields;
    const boObject = {};

    fields.forEach(field => {
      const fieldName = field.fieldName;
      if (input.hasOwnProperty(fieldName)) {
        boObject[fieldName] = input[fieldName];
      } else {
        if (field.type == "Object") {
          boObject[fieldName] = null;
        }else {
          boObject[fieldName] = "";
        }
      }
    });

    output[boName] = boObject;
  });
  return output;
}


async function fetchDataFromServer(action, inputObject) {
  return new Promise((resolve, reject) => {
    try {
      let flow = JSON_CONFIG_SCREEN.flow;
      let config = JSON_CONFIG_SCREEN.config;

      let flowNameV = flow.flowName;
      let appName = flow.appName;
      let uniqId = flow.flowId;
      let snapVersion = flow.snapVersion;
      let MFR_URL = config.microFlowUrl;

      let inputname = flowNameV + "_flow_input";

      let object = {};
      object[inputname] = inputObject;
      object[inputname].action = action;
      let body = {
        flowName: flowNameV,
        appName: appName,
        snapVersion: snapVersion,
        uniqueId: uniqId,
        object: object,
      };

      $.ajax({
        url: MFR_URL,
        type: "POST",
        data: JSON.stringify(body),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data) {
          console.log("data:<> ", data);
          let map = data["responseObjectsMap"];
          let out = flowNameV + "_flow_output";
          let responseObj = map[out];

          if (responseObj === null) {
            reject(new Error('responseObj is null'));
          } else {
            resolve(responseObj);
          }
        },
        catch: function (e) {
          reject(e);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

async function getSelectMain(refId, screenKey) {
  let key = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  console.log("inputObject key:: ", refId);

  let input = { [key]: refId }
  console.log("input:: ", input);
  const inputObject = { parameter: transformBoData(screenKey, input) };
  console.log("inputObject:: ", inputObject);

  try {
    const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_MAIN, inputObject);
    return response[screenKey];

  } catch (error) {
    console.error(error);
  }
}

async function createListContainer(createData, mainScreen) {
  console.log("createData >>!!!!!!!", createData);
  SCREENS_EDIT_WITH_POSITION.forEach(screen => {
    if (screen.configType === 'BO') {
      renderBOScreens(screen);
    } else if (screen.configType === 'BUTTON') {
      renderButtonScreens(screen, createData, 'add', mainScreen);
    } else if (screen.configType === 'BREADCRUMB') {
      //BREADCRUMB
    } else if (screen.configType === 'TAB') {
      //TAB
    }else if (screen.configType === 'TABLE') {
      if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
        renderTableOneToMany(mainScreen, screen, createData, 'add');
      }
    }
  });
}

// ฟังก์ชันเพื่อ render หน้าจอ BO
function renderBOScreens(screen, data) {
  let toggleButton = document.querySelector('#toggleButton-id-' + screen.key);
  let input = document.getElementById('input-id-' + screen.key);
  let isPasswordVisible = false;

  if (toggleButton) {
    toggleButton.addEventListener('click', () => {
      isPasswordVisible = !isPasswordVisible;
      input.type = isPasswordVisible ? 'text' : 'password';
      toggleButton.innerHTML = isPasswordVisible ? '<i class="fas fa-eye"></i>' : '<i class="fas fa-eye-slash"></i>';
    },false);
  }

}

function renderButtonScreens(screen, data, label, mainScreen) {
  const buttonId = "btn-"+mainScreen.key+"-{{PAGE_NAME}}";
  const button = document.getElementById(buttonId);
  if (button) {
    button._handleClick = async function () {
      const inputObject = { parameter: transformBoData(mainScreen.key, data) };
      try {
        let response;
        response = await fetchDataFromServer(ACTION_TYPE.ACTION_INSERT_MAIN, inputObject);
        if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
            const responseChilds = await fetchDataFromServer(ACTION_TYPE.ACTION_INSERT_CHILDS, INPUT_CHILD_OBJECT);
          }

        const editScreen = SCREENS_EDIT_WITH_POSITION.find(editScreen => {
          return editScreen.configType === 'TABLE';
        });
    
        clearTableData(mainScreen, editScreen, label)

        //have to click back
        {{CLICK_BACK}}

      } catch (error) {
        console.error(error);
      }
    };
    button.addEventListener('click', button._handleClick,false);
  }
}

function extractFieldNames(apiResponse) {
  const result = {};
  apiResponse.bo.forEach((bo) => {
    result[bo.boName] = bo.fields.map(field => field.fieldName);

  });
  return result;
};

async function handleClickAdd(e) {
  const screensWithPosition = JSON_CONFIG_SCREEN.screens.filter(screen => screen.rownumber && screen.columnnumber);

  screensWithPosition.forEach(async (screen) => {
    
    let dataValue = extractFieldNames(JSON_CONFIG_SCREEN);

    let createData = {};
    dataValue["{{SCREEN_KEY}}"].forEach((key, index) => {

      const inputImageElement = document.getElementById('image-file-add-' + key);
      const inputElement = document.getElementById('input-id-add-' + key);
      if (inputElement) {
        createData[key] = inputElement.value;

        // เคลียร์ค่าใน input element
        inputElement.value = '';
        inputElement.addEventListener('input', function () {
          createData[key] = inputElement.value;
        },false);
      }
      if (inputImageElement) {
        // อัปเดตรายการไฟล์เมื่อมีการเลือกไฟล์ใหม่
        const onChange = ({ FILE_LIST: newFileList }) => {
          console.log("6666 newFileList>>>", newFileList);
          setFileList(newFileList);
        };

        // แสดงตัวอย่างของไฟล์ภาพเมื่อคลิกที่ปุ่มดูตัวอย่าง
        const onPreview = async (files) => {
          console.log("6666 files[0]>>>", files[0]);
          const file = files[0];
          if (!file) return;

          const reader = new FileReader();
          reader.onload = function (event) {
            const imgElement = document.createElement('img');
            imgElement.src = event.target.result;
            imgElement.style.maxWidth = '100%';
            imgElement.style.maxHeight = '100%';

            const upload = document.getElementById('image-preview-add-' + key);
            if (upload) {
              // ลบภาพเก่าออกจาก DOM
              const imgContainer = upload.querySelector('img');
              if (imgContainer) {
                imgContainer.remove();
              }

              // แทรกภาพลงใน DOM
              upload.appendChild(imgElement);
            }
          };
          reader.readAsDataURL(file);

          // เช็คว่ามี Token ใน Session ไหม
          let existingToken = sessionStorage.getItem('$$_center_token_$$');
          if (!existingToken) {
            let centerUser = JSON_CONFIG_SCREEN.config.centerUser;
            let centerPassword = JSON_CONFIG_SCREEN.config.centerPassword;
            if (centerUser && centerPassword) {
              let user = {
                "username": centerUser,
                "password": centerPassword
              };
              await fetchToken(user);
              existingToken = sessionStorage.getItem('$$_center_token_$$');
            }
          }

          const formData = new FormData();
          formData.append('fullName', file.name);
          const lastDotIndex = file.name.lastIndexOf('.');
          formData.append('fileName', file.name.substring(0, lastDotIndex));
          formData.append('file', file);

          let imageID = await uploadFileWithToken(formData, existingToken);
          createData[key] = imageID;
          console.log("imageID", imageID);
        };

        inputImageElement.addEventListener('change', (event) => {
          onChange({ FILE_LIST: event.target.files });
          onPreview(event.target.files);
          const uploadLabel = document.getElementById('image-preview-label-add-' + key);
          if (uploadLabel) {
            console.log("6666 uploadLabel>>>", uploadLabel);
            uploadLabel.hidden = true;
          }
        }, false);

        inputImageElement.value = ''; // เคลียค่าของอินพุต
        const previewContainer = document.getElementById('image-preview-add-' + key);
        if (previewContainer) {
          const imgContainer = previewContainer.querySelector('img');
          if (imgContainer) {
            imgContainer.remove(); // ลบภาพที่แสดงตัวอย่างออกจาก DOM
          }
          const uploadLabel = document.getElementById('image-preview-label-add-' + key);
          uploadLabel.hidden = false;
        }
      }

      
    });
    await createListContainer(createData, screen);

    const backButton = document.getElementById("backButton-container-add-Wrapper-{{SCREEN_KEY}}");
    backButton.addEventListener('click', function () { 
      //have to click back
      {{CLICK_BACK}}

    },false);
    });


}

let FILE_LIST = [];
function setFileList(newFileList) {
  FILE_LIST = newFileList;
  console.log('Updated FILE_LIST:', FILE_LIST);
};

function initLoad($page,event) {
  handleClickAdd();
}

function clickSaveNewItemFn($page,event) {
  console.log("===clickSaveItemFn===")
  console.log("$page",$page)
  console.log("event",event)
}

async function fetchToken(credentials) {
  try {
    const response = await fetch(JSON_CONFIG_SCREEN.config.centerDomain +'/center/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    });
    const data = await response.json();
    const token = data.token;
    sessionStorage.setItem('$$_center_token_$$', token); // เก็บ Token ใน Session

    console.log("data>>> ", token);
    return token;
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
}

async function uploadFileWithToken(formData, token) {
  try {
    const response = await fetch(JSON_CONFIG_SCREEN.config.centerDomain +'/FileService/uploadFile', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error('Failed to upload file.');
    }
    const responseData = await response.json();

    console.log('File uploaded successfully!', responseData);
    let imageId = responseData.data.id;
    return imageId;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}
  async function renderTableOneToMany(mainScreen, screen, data, label) {
  console.log("renderTableOneToMany", data);
  const dataTable = document.getElementById('dataTable-' + label + '-' + screen.configType + '-' + screen.key + '-' + mainScreen.key);
  console.log("dataTable", dataTable);
  const tbody = dataTable.querySelector('tbody');
  console.log("tbody", tbody);

  const buttonAddTable = $("#add-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button")[0];
  const newButtonAddTable = buttonAddTable.cloneNode(true);
  buttonAddTable.parentNode.replaceChild(newButtonAddTable, buttonAddTable);

  const arrayDataTable = [];
  newButtonAddTable.addEventListener('click', function () {
    if (arrayDataTable.length === 0) {
        clickCount = 0;
    }
    clickCount++;

    const dataTable = collectTableData(mainScreen, screen, label);
    arrayDataTable.push(dataTable);
    console.log("dataTable", dataTable);
    console.log("arrayDataTable", arrayDataTable);

    const row = document.createElement('tr');
    row.id = 'new-row-id-index-' + clickCount;
    row.dataset.index = clickCount - 1; // เก็บค่าของข้อมูลใน dataset

    const deleteNewRowCell = document.createElement('td');
    const deleteNewRowDiv = document.createElement('div');
    deleteNewRowDiv.innerHTML = '<button id="button-index-' + clickCount + '"type="button" class="delete-middle ant-table-row-expand-icon ant-table-row-expand-icon-expanded"></button>';
    const deleteButton = deleteNewRowDiv.firstChild;

      deleteNewRowCell.appendChild(deleteButton);
      row.appendChild(deleteNewRowCell);

    Object.values(dataTable).forEach(value => {
      const cell = document.createElement('td');
      cell.textContent = value;
      row.appendChild(cell);
    });
    tbody.appendChild(row);

    deleteButton.addEventListener('click', function (event) {
      const target = event.target;
      const parentRow = target.closest('tr');
      console.log("Parent row ID:", parentRow.id);

      // ดึงค่าจาก dataset
      const dataIndex = parentRow.dataset.index;
      console.log("Data index:", dataIndex);

      // ลบ row จาก arrayDataTable
      if (dataIndex > -1) {
        arrayDataTable.splice(dataIndex, 1);
      }
      // ลบแถวจาก DOM
      parentRow.remove();

      // อัปเดต dataset ของแถวที่เหลือ
      const rows = tbody.querySelectorAll('tr[id^="row-child-{{SCREEN_KEY}}-"]');
      rows.forEach((row, index) => {
        row.dataset.index = index;
      });


      console.log("Updated arrayDataTable:", arrayDataTable);
    }, false);


    INPUT_CHILD_OBJECT = {
      parameter: {
        [mainScreen.key]: {
          [screen.key + '_list']: arrayDataTable
        }
      }
    };

  });

  

  const clearButton = document.getElementById('clear-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button');
  clearButton.addEventListener('click', function () {
    const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
    console.log("newRowsContainer ", newRowsContainer);
    const inputElements = newRowsContainer.querySelectorAll('input');
    inputElements.forEach(input => {
      input.value = '';
    });
  }, false);

}

function collectTableData(mainScreen, screen, label) {
  const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
  console.log("newRowsContainer ", newRowsContainer);

  const inputs = newRowsContainer.querySelectorAll('input');
  const rowData = {};

  inputs.forEach(input => {
    console.log("row", input);
    rowData[input.placeholder] = input.value;
  });

  console.log('Collected Data:', rowData);
  return rowData;
}

function clearTableData(mainScreen, screen, label) {
  const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
  if(newRowsContainer==null) return;
  console.log("newRowsContainer ", newRowsContainer);
  const inputsNewRows = newRowsContainer.querySelectorAll('input');

  inputsNewRows.forEach(function (input) {
    input.value = '';
  });

  var tbody = document.querySelectorAll('#dataTable-' + label + '-' + screen.configType + '-' + screen.key + '-' + mainScreen.key + ' tbody');

  while (tbody[0].rows.length > 1) {
    tbody[0].deleteRow(1);
  }

  const inputs = tbody[0].querySelectorAll('tr td input');
  console.log("tbody:: inputs", inputs);
  inputs.forEach(function (input) {
    input.value = '';
  });
}


`