import { Modal, Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import StoryEditDbRedBin from "src/assets/svg/story-edit-db-red-bin";
import styles from "./SaveVersionModal.module.scss";
import SearchIcon from "src/assets/svg/searchIcon";
import TextArea from "antd/es/input/TextArea";
import MessageNofify from "../../notification/MessageNotify";
import { BUTTON } from "src/constants/PageConfig";

type Props = {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  onSaveVersionHistory: (versionName: string, description: string) => void;
  panelItems?: any;
};

function SaveVersionModal({
  isModalOpen,
  onOk,
  onCancel,
  onSaveVersionHistory,
  panelItems,
}: Props) {
  //MESSAGE NOTIFY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);
  let [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
    setLoadingButtonSave(false);
  }, [isModalOpen]);

  let [versionName, setVersionName] = useState<string>("");
  let [description, setDescription] = useState<string>("");

  //INPUT HANDLER
  const onChangeVersionNameHandler = (input: string) => {
    // console.log("onChangeVersionNameHandler input : ", input);
    setVersionName(input);
  };
  const onChangeDescriptionHandler = (input: string) => {
    // console.log("onChangeDescriptionHandler input : ", input);
    setDescription(input);
  };

  //ACTION BUTTON HANDLER
  const onClickSave = () => {
    if (isPassValidateOnSave()) {
      let vName = versionName.trim();

      //SPECIAL CHARACTER VALIDATION
      if (containsSpecialCharacter(vName)) {
        errorOnSpecialCharVersionName();
        return;
      }

      //HAS SUBMIT BUTTON VALIDATION
      if (!hasAddedSubmitButton()) {
        errorOnNotFoundButton();
        return;
      }

      setLoadingButtonSave(true);
      onSaveVersionHistory(vName, description);
      clearInputOnSave();
    } else {
      errorOnEmptyInput();
    }
  };

  //UTILITY FUNCTION
  const clearInputOnSave = () => {
    setVersionName("");
    setDescription("");
  };

  const containsSpecialCharacter = (str: string): boolean => {
    const specialCharacterRegex = /[^a-zA-Z0-9]/;
    return specialCharacterRegex.test(str);
  };

  const isPassValidateOnSave = () => {
    return versionName && description ? true : false;
  };

  const hasAddedSubmitButton = () => {
    for (const row of panelItems) {
      let cols = row?.cols;
      for (const col of cols) {
        if (col?.isControlItem && col?.controlComponent) {
          let props = col?.controlComponent?.props;
          if (props?.configType === BUTTON) return true;
        }
      }
    }
    return false;
  };

  const errorOnEmptyInput = () => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify("Save Version!");
    setDescriptionNotify("Please add Version name and Description.");
    setIsSuccessNotify(false);
  };

  const errorOnSpecialCharVersionName = () => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify("Save Version!");
    setDescriptionNotify("Please remove special character in Version name.");
    setIsSuccessNotify(false);
  };

  const errorOnNotFoundButton = () => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify("Save Version!");
    setDescriptionNotify("Please add button at least 1.");
    setIsSuccessNotify(false);
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.modalTitle}>
            {/* <StoryEditDbRedBin /> */}
            {/* {modalDetail.title} */}
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="save"
              onClick={onClickSave}
              type="primary"
              loading={loadingButtonSave}
            >
              Save
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        centered={true}
        width={400}
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.modalTitle}>Add to version history</div>
          <div className={styles.modalContent}>
            <div className={styles.versionContainer}>
              <div className={styles.textTopic}>
                <div className={styles.text}>Version name</div>
                <div className={styles.textImportant}>*</div>
              </div>
              <div className={styles.inputVersionName}>
                <Input
                  placeholder={"Enter your version name"}
                  //   suffix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
                  value={versionName}
                  className={styles.searchInput}
                  onChange={(e) =>
                    onChangeVersionNameHandler(e.currentTarget.value)
                  }
                />
              </div>
            </div>
            <div className={styles.descContainer}>
              <div className={styles.textTopic}>
                <div className={styles.text}>Description</div>
                <div className={styles.textImportant}>*</div>
              </div>
              <div className={styles.inputDescription}>
                <TextArea
                  value={description}
                  onChange={(e) => onChangeDescriptionHandler(e.target.value)}
                  placeholder="Describe what change"
                  autoSize={{ minRows: 5, maxRows: 7 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );
}

export default SaveVersionModal;
