export class ParameterElement{
	
	private jParameterElement:any = {key:null,group:null,parentKey:null,paramName:null,typeDesc:null,isList:false,
		category:null,margin:null,pathName:null,willBeMapping:false,refParamId:null,
		seq:0,mustRef:false,xpath:null};
	private modelDiagram:any;
	private node:any ;
	
	public setModelDiagram(modelD:string){
		this.modelDiagram = modelD;
		return this;
	};
	
	public setNode(nodeM:string){
		this.node = nodeM;
		return this;
	};

	public setKey(key:string){
		this.jParameterElement.key = key;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "key", key);
		}
		return this;
	};
	
	public getKey(){
		return this.jParameterElement.key; 
	};
	
	public setGroup(group:string){
		this.jParameterElement.group = group;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "group", group);
		}
		return this;
	};
	
	public getXPath(){
		return this.jParameterElement.xpath;
	};

	public setXPath(xpath:string){
		this.jParameterElement.xpath = xpath;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "group", group);
		}
		return this;
	};
	
	public getGroup(){
		return this.jParameterElement.group;
	};
	
	public setParentKey(parentKey:string){
		this.jParameterElement.parentKey = parentKey;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "parentKey", parentKey);
		}
		return this;
	};
	
	public getParentKey(){
		return this.jParameterElement.parentKey;
	};
	
	public setParamName(paramName:string){
		this.jParameterElement.paramName = paramName;
		if(this.modelDiagram != null && this.node != null){
			//console.log("==================== this.modelDiagram is not null && this.jParameterElement is not nul =========");
			//this.modelDiagram.setDataProperty(this.node, "paramName", paramName);
		}
		return this;
	};
	
	public getParamName(){
		return this.jParameterElement.paramName;
	};
		
	public setTypeDesc(typeDesc:string){
		this.jParameterElement.typeDesc = typeDesc;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "typeDesc", typeDesc);
		}
		return this;
	};
	
	public getTypeDesc(){
		return this.jParameterElement.typeDesc; 
	};
	
	
	public setIsList(isList:boolean){
		this.jParameterElement.isList = isList;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "isList", isList);
		}
		return this;
	};
	
	public getIsList(){
		return this.jParameterElement.isList; 
	};
	
	public setCategory(category:string){
		this.jParameterElement.category = category;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "category", category);
		}
		return this;
	};
	
	public getCategory(){
		return this.jParameterElement.category; 
	};
	
	// public setFrom(from:string){
	// 	this.jParameterElement.from = from;
	// 	if(this.modelDiagram != null && this.node != null){
	// 		//this.modelDiagram.setDataProperty(this.node, "from", from);
	// 	}
	// 	return this;
	// };
	
	// public getFrom(){
	// 	return this.jParameterElement.from; 
	// };
	
	// public setTo(to:string){
	// 	this.jParameterElement.to = to;		
	// 	if(this.modelDiagram != null && this.node != null){
	// 		//this.modelDiagram.setDataProperty(this.node, "to", to);
	// 	}
	// 	return this;
	// };
	
	// public getTo(){
	// 	return this.jParameterElement.to; 
	// };
	
	public setMargin(margin:string){
		this.jParameterElement.margin = margin;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "margin", margin);
		}
		return this;
	};
	
	public getMargin(){
		return this.jParameterElement.margin; 
	};
	
	public setPathName(pathName:string){
		this.jParameterElement.pathName = pathName;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "pathName", pathName);
		}
		return this;
	}
	
	public getPathName(){
		return this.jParameterElement.pathName; 
	}

	public setWillBeMapping(willBeMapping:boolean){
		this.jParameterElement.willBeMapping = willBeMapping;
		if(this.modelDiagram != null && this.node != null){
			//this.modelDiagram.setDataProperty(this.node, "willBeMapping", willBeMapping);
		}
	}

	public getWillBeMapping(){
		return this.jParameterElement.willBeMapping; 
	}
	
	//refParamId
	public setRefParamId(refParamId:string){
		this.jParameterElement.refParamId = refParamId;
		return this;
	}
	
	public getRefParamId(){
		return this.jParameterElement.refParamId;
	}
	
	public setSeq(seq:number){
		this.jParameterElement.seq = seq;
		return this;
	}
	
	public getSeq(){
		return this.jParameterElement.seq;
	}
	
	public setjParameterElement(jParameterObj:any){
		this.jParameterElement = jParameterObj; 
		return this;
	};
	
	public getJParameterElement(){
		return this.jParameterElement;
	};

	public setMustRef(mustRef:boolean){
		this.jParameterElement.mustRef = mustRef;
		if(this.modelDiagram != null && this.node != null){
			//modelDiagram.setDataProperty(node, "group", group);
		}
		return this;
	};
	public isMustRef(){
		return this.jParameterElement.mustRef;
	};
	
}