const SELECT_DATA_TYPE = [
  { value: "string", label: "String" },
  { value: "boolean", label: "Boolean" },
  { value: "date", label: "Date" },
  { value: "datetime", label: "DateTime" },
  { value: "time", label: "Time" },
  { value: "file", label: "File" },
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "object", label: "Object" },
];
const SELECT_DATA_TYPE_OBJECT = [
  { value: "object", label: "Object" },
];

export { SELECT_DATA_TYPE, SELECT_DATA_TYPE_OBJECT };
