import React, { useEffect, useRef, useState } from "react";
import styles from "./GojsTest.module.scss";
import { JsonInitBoGojs, JsonTestGojs, MappingParam ,JsonInitMappingGojs, MyDiagramState} from "src/shared/MappingParam/MappingParam";

let mappingParam = new MappingParam()
function GojsTest(props: any) {
  const testDiagram = useRef(null);

  const getJsonGojs = ()=>{
    let node =  mappingParam.getDataFromDiagram();
    console.log("node",node)
    let data =  mappingParam.getDataFromDiagram();
    console.log("data",MyDiagramState)
  }

  useEffect(() => {
    mappingParam.init(testDiagram.current)
    // mappingParam.loadDataToDiagram(JsonInitMappingGojs);
    mappingParam.loadDataToDiagram(JsonTestGojs);

    // mappingParam.addDataModel(data);
    // mappingParam.updateDataModel(data);
    // mappingParam.removeArrayByKey(key);
  }, [])
  return (
    <>
      <button onClick={getJsonGojs}>get json</button>
      <div style={{ height: "100%" }} >
        <div className={styles.diagram} ref={testDiagram} ></div>
      </div>
    </>
  );
}

export default GojsTest;
