import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./ConfirmDeleteModal.module.scss";
import StoryEditDbRedBin from "src/assets/svg/story-edit-db-red-bin";

type Props = {
  modalDetail: { title: string; description: string };
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
};

function ConfirmDeleteModal({
  modalDetail,
  isModalOpen,
  onOk,
  onCancel,
}: Props) {
  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  //ACTION HANDLER
  const onClickOk = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOk();
  };

  const onClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.modalTitle}>
            <StoryEditDbRedBin />
            {modalDetail.title}
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={(e) => onClickCancel(e)}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="ok"
              onClick={(e) => onClickOk(e)}
              type="primary"
              danger
            >
              Confirm
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        centered={true}
        width={400}
      >
        <p className={styles.modalContent}>{modalDetail.description}</p>
      </Modal>
    </>
  );
}

export default ConfirmDeleteModal;
