import React from "react";

const StoryEditEntGenPageButton = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 12"
      width={width || "14"}
      height={height || "12"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0833 0.75H2.91667C2.14312 0.75 1.40125 1.05729 0.854272 1.60427C0.307291 2.15125 0 2.89312 0 3.66667L0 8.33333C0 9.10688 0.307291 9.84875 0.854272 10.3957C1.40125 10.9427 2.14312 11.25 2.91667 11.25H11.0833C11.4664 11.25 11.8456 11.1746 12.1995 11.028C12.5534 10.8814 12.8749 10.6666 13.1457 10.3957C13.4166 10.1249 13.6314 9.80336 13.778 9.44949C13.9246 9.09563 14 8.71636 14 8.33333V3.66667C14 3.28364 13.9246 2.90437 13.778 2.55051C13.6314 2.19664 13.4166 1.87511 13.1457 1.60427C12.8749 1.33343 12.5534 1.11859 12.1995 0.972018C11.8456 0.825442 11.4664 0.75 11.0833 0.75ZM12.8333 8.33333C12.8333 8.79746 12.649 9.24258 12.3208 9.57077C11.9926 9.89896 11.5475 10.0833 11.0833 10.0833H2.91667C2.45254 10.0833 2.00742 9.89896 1.67923 9.57077C1.35104 9.24258 1.16667 8.79746 1.16667 8.33333V3.66667C1.16667 3.20254 1.35104 2.75742 1.67923 2.42923C2.00742 2.10104 2.45254 1.91667 2.91667 1.91667H11.0833C11.5475 1.91667 11.9926 2.10104 12.3208 2.42923C12.649 2.75742 12.8333 3.20254 12.8333 3.66667V8.33333Z"
        fill="#374957"
      />
    </svg>
  );
};
export default StoryEditEntGenPageButton;
