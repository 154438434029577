import React, { useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PackagePlans from './PackagePlans';
import { useAppDispatch } from '../../../store/store';
import { plansSelector, setBillingCycle } from '../../../store/slices/plansSlice';
import { useSelector } from 'react-redux';
import styles from "./ChoicePlans.module.scss";

function ChoicePlans() {
    const dispath = useAppDispatch();
    const plansReducer = useSelector(plansSelector);

    useEffect(() => {
    }, [plansReducer])

    const handleAlignment = (
        _: React.MouseEvent<HTMLElement>,
        newBillingCycle: string | null,
    ) => {
        if (newBillingCycle !== null) {
            dispath(setBillingCycle(newBillingCycle));
        }
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <h1 style={{ fontWeight: '500' }}>Choose a package plan</h1>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '20px',
            }}>
                <ToggleButtonGroup
                    value={plansReducer.billing_cycle}
                    exclusive
                    color="primary"
                    onChange={handleAlignment}
                    aria-label="billing_cycle"
                    size='small'
                    className={styles.toggleButtonGroup}
                >
                    <ToggleButton
                        fullWidth
                        value="one_time"
                        style={plansReducer.billing_cycle === "one_time" ? {
                            backgroundColor: 'blue',
                            fontWeight: '700',
                            color: 'white'
                        } : undefined}
                    >
                        One Time
                    </ToggleButton>
                    <ToggleButton
                        fullWidth
                        value="monthly"
                        style={plansReducer.billing_cycle === "monthly" ? {
                            backgroundColor: 'blue',
                            fontWeight: '700',
                            color: 'white'
                        } : undefined}
                    >
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div>
                <PackagePlans />
            </div>
        </div >
    );
}

export default ChoicePlans;