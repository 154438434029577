import React, { Component } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topic from "../page/example/Topic";
import Home from "../page/example/Login";



const TopicRoutes = () => {
  const { pathname } = useLocation();

  return (
  <Routes>
    <Route path={`${pathname}/:topicId`} element={<Topic />} />
    <Route path={pathname} element={<Home />} />
  </Routes>
  )
};
export default TopicRoutes;
