import React from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./StyleTool.module.scss";
import StoryGenPageAddRight from "src/assets/svg/story-genpage-tool-add-right";

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onRemoveComponent: () => void;
  onAddCol: () => void;
  
};

function CellComponentTableTool({
  isShow,
  top,
  left,
  onRemoveComponent,
  onAddCol,
}: Props) {

  const onClickAddCol = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onAddCol();
  };

  const onClickRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemoveComponent();
  };

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: top + "px",
            left: left + "px",
          }}
          className={styles.cellToolContainer}
        >
          <div
            className={styles.actionWrapper}
            onClick={(e) => onClickAddCol(e)}
          >
            <StoryGenPageAddRight width={"20px"} height={"20px"} />
          </div>
          <div
            className={styles.actionWrapper}
            onClick={(e) => onClickRemove(e)}
          >
            <StoryEditEntDelete width={"16px"} height={"16px"} />
          </div>
        </div>
      )}
    </>
  );
}

export default CellComponentTableTool;
