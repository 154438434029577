import React from "react";

const EditIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg
            width={width | "24"}
            height={height | "24"}
            style={style}
            className={className}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#E9E9E9" />
            <g clip-path="url(#clip0_4209_31070)">
                <path d="M17.8787 6.12108C17.5105 5.75339 17.0114 5.54688 16.491 5.54688C15.9706 5.54688 15.4715 5.75339 15.1032 6.12108L6.29355 14.9307C6.04127 15.1816 5.84124 15.48 5.70503 15.8087C5.56882 16.1373 5.49913 16.4898 5.50001 16.8455V17.9576C5.50001 18.1012 5.55708 18.239 5.65866 18.3406C5.76024 18.4422 5.89802 18.4992 6.04167 18.4992H7.15372C7.50946 18.5002 7.86187 18.4307 8.19055 18.2946C8.51923 18.1584 8.81764 17.9585 9.06851 17.7062L17.8787 8.89603C18.2462 8.52781 18.4526 8.02881 18.4526 7.50856C18.4526 6.9883 18.2462 6.4893 17.8787 6.12108ZM8.30259 16.9403C7.99709 17.2438 7.58432 17.4147 7.15372 17.4159H6.58334V16.8455C6.58279 16.6321 6.62459 16.4206 6.70632 16.2234C6.78805 16.0262 6.90808 15.8472 7.05947 15.6967L13.7453 9.01087L14.9911 10.2567L8.30259 16.9403ZM17.1123 8.13012L15.7548 9.48808L14.509 8.24495L15.867 6.88699C15.9488 6.80537 16.0458 6.74065 16.1527 6.69655C16.2595 6.65244 16.3739 6.6298 16.4895 6.62993C16.605 6.63006 16.7195 6.65294 16.8262 6.69728C16.9329 6.74162 17.0298 6.80654 17.1114 6.88835C17.1931 6.97015 17.2578 7.06723 17.3019 7.17404C17.346 7.28085 17.3686 7.39531 17.3685 7.51087C17.3684 7.62643 17.3455 7.74083 17.3012 7.84755C17.2568 7.95426 17.1919 8.0512 17.1101 8.13283L17.1123 8.13012Z" fill="#ABABAB" />
            </g>
            <defs>
                <clipPath id="clip0_4209_31070">
                    <rect width="13" height="13" fill="white" transform="translate(5.5 5.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default EditIcon;