import React, { useEffect, useState } from "react";
import styles from "./DataReferenceCard.module.scss";
import InputTypeDropdownImg from "../../../../assets/png/svgviewer-png-input-type-dropdown.png";
import InputTypeRadioImg from "../../../../assets/png/svgviewer-png-input-type-radio.png";
import InputTypeCheckboxImg from "../../../../assets/png/svgviewer-png-input-type-checkbox.png";
import StoryCardIcon from "src/assets/svg/database-connection";

type Props = {
  id: string;
  dataReferName: string;
  userProfile: string;
  owner: string;
  date: string;
  inputType: string;
  openEditModel: (dataReferID:string) => void;
};

function DataReferenceItemCardItem({
  id,
  dataReferName,
  userProfile,
  owner,
  date,
  inputType,
  openEditModel,
}: Props) {

  const inputTypeImg = () => {
    if (inputType == "dropdown") {
      return InputTypeDropdownImg;
    } else if (inputType == "radio") {
      return InputTypeRadioImg;
    } else {
      return InputTypeCheckboxImg;
    }
  };

  return (
    <div
      className={styles.dataReferItemContainer}
      onClick={() => openEditModel(id)}
    >
      <div className={styles.imgWrapper}>
        <img
          src={inputTypeImg()}
          alt="story-card-img"
          className={styles.img}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={styles.detailWrapper}>
        <h4 className={styles.title}>{dataReferName}</h4>
        <div className={styles.detail}>
          <div className={styles.detailLeft}>
            <div className={styles.imgWrapper}>
              <img
                className={styles.profileImg}
                src={userProfile}
                alt="profile"
              />
            </div>
            <div className={styles.textWrapper}>
              <h5 className={styles.textEmail}>{owner}</h5>
              <p className={styles.textUploadDate}>{date}</p>
            </div>
          </div>
          <div className={styles.detailRight}>
            <div className={styles.expandWrapper}>
              <StoryCardIcon width={"20px"} height={"20px"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataReferenceItemCardItem;
