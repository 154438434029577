import React, { useEffect, useState } from "react";
import styles from "./EditStoryEntity.module.scss";
import { Button, Input, Select } from "antd";
import SearchIcon from "src/assets/svg/searchIcon";
import StoryEntityDrawer from "src/components/zero/zero-story/drawer/StoryEntityDrawer";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import { NEWEST, OLDEST } from "src/constants/SortBy";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";
import UpdateEntitySuccessNotify from "src/components/zero/zero-story/notification/UpdateEntitySuccessNotify"
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import StoryEntityGenPageModal from "src/components/zero/zero-story/modal/modal-entity-genpage/StoryEntityGenPageModal";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getPathToGenPageWithBoId,
  getPathToGenPageWithStoryIDAndEntName,
} from "src/utils/routes-util/RoutesUtility";
import GenerateMFLoading from "src/components/zero/zero-story/loading/GenerateMFLoading";
import useFetch from "src/hooks/useFetch";

import HistoryVersionDrawer from "../entity/modal/HistoryVersionDrawer";

import {
  ADD_STORY_ENYITY_ENDPOINT,
  GENERATE_MF_ENDPOINT,
  GET_DB_CONNECT_ENDPOINT,
  GET_ENTITY_BO_BY_ID_ENDPOINT,
  GET_STORY_ENYITY_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
  REMOVE_STORY_ENYITY_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import {
  GenerateMFResponse,
  GetAllDBConnectByStoryIDResponse,
  GetTableAndColumnResponse,
  StoryEnyityBOResponse,
  TableType,
} from "src/types/response.type";
import { STATUS_OK } from "src/constants/ResponseStatus";
import {
  GenerateMFRequest,
  GetTableAndColumnRequest,
  StoryEnyityBORequest,
} from "src/types/request.type";
import StoryEditEntEdit from "src/assets/svg/story-edit-ent-edit";
import StoryEditEntHistory from "src/assets/svg/story-edit-ent-history";
import EditBoModal from "src/components/zero/zero-story/modal/modal-edit-bo/EditBoModal";
import CreateStoryEntityModal from "../../../../components/zero/zero-story/modal/modal-create-entity/CreateStoryEntityModal"

import { StoryEntityProvider } from "src/page/context/StoryEntity.context";
// /modal/modal-create-entity/CreateStoryEntityModal";;

import { getAspId } from "src/services/util.service";
import { getDBFromDBConnection } from "src/utils/story-genmf/StoryGenerateMFUtility";
import { BO_INIT_KEY, TABLE_INIT_KEY } from "src/constants/DiagramInitKey";

type Props = {
  afterGenMF: () => void;
  isOpen: boolean;
};

function EditStoryEntity({ afterGenMF, isOpen }: Props) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();

  const sortItems = [
    { value: NEWEST, label: "Newest" },
    { value: OLDEST, label: "Oldest" },
  ];

  let [openCreateEntityDrawer, setOpenCreateEntityDrawer] =
    useState<boolean>(false);
  let [entityItems, setEntityItems] = useState<StoryEnyityBORequest[]>([]);
  let [entityItemsSearch, setEntityItemsSearch] = useState<
    StoryEnyityBORequest[]
  >([]);

  let [entityNameCreated, setEntityNameCreated] = useState<string>("");
  let [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    useState<boolean>(false);
  let [titleDelete, setTitleDelete] = useState<string>("");
  let [itemToDelete, setItemToDelete] = useState<string>("");
  let [isOpenGenPageModal, setIsOpenGenPageModal] = useState<boolean>(false);
  let [isLoadingGenMF, setIsLoadingGenMF] = useState<boolean>(false);
  let [isOpenEditBoModal, setIsOpenEditBoModal] = useState<boolean>(false);
  let [isOpenCreateBOModal, setisOpenCreateBOModal] = useState<boolean>(false);
  let [isEditSuccess, setIsEditSuccess] = useState<string>("");
  let [entityType, setentityType] = useState<string>("");
  let [editBoSelectedId, setEditBoSelectedId] = useState<string>("");
  let [dbConnect, setDbConnect] = useState<GetTableAndColumnRequest>();
  let [boIdToGenMF, setBoIdToGenMF] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<String>("");
  let [isOpenHistoryVersionDrawer, setIsOpenHistoryVersionDrawer] =
    useState<boolean>(false);
  let [enyityBoID, setEnyityBoId] = useState<string>("");
  const [arrBOConfigItems, setArrBOConfigItems] = useState<any[]>([]); 

  const { loading, error, responseData, fetchData, fetchKey, fetchDataAsync } =
    useFetch<
      | StoryEnyityBOResponse
      | GetAllDBConnectByStoryIDResponse
      | GetTableAndColumnResponse
      | GenerateMFResponse
    >();

  const GET_STORY_ENT_FK = "getStEntFK";
  const ADD_STORY_ENT_FK = "addStEntFK";
  const REMOVE_STORY_ENT_FK = "removeStEntFK";
  const GET_DB_CONNECT_FK = "getDbConnectFK";
  const PREPARE_GEN_MF_FK = "prepareGenMfFK";
  const GEN_MF_FK = "genMfFK";

  //ONLOAD OPEN ENTITY
  useEffect(() => {
    if (isOpen) getStoryEntityController();
  }, [isOpen]);

  //--CHECK RESPONSE BY STATE USEFETCH FROM CALL API SUCCESS
  useEffect(() => {
    if (!loading) {
      if (error || !responseData) {
        setIsLoadingGenMF(false);
        return;
      }

      if (fetchKey === ADD_STORY_ENT_FK || fetchKey === REMOVE_STORY_ENT_FK) {
        getStoryEntityController();
      } else if (fetchKey === GET_STORY_ENT_FK) {
        let respGetStory: any = responseData;
        let newEntityItems: StoryEnyityBORequest[] =
          respGetStory.data as unknown as StoryEnyityBORequest[];
        updateNewStoryEntityItems(newEntityItems);
      } else if (fetchKey === GET_DB_CONNECT_FK) {
        let respDBConnect: any = responseData;
        // console.log("respDBConnect => ", respDBConnect);

        if (respDBConnect.status !== STATUS_OK) return;
        let getTableColReq =
          respDBConnect?.data as unknown as GetTableAndColumnRequest[];
        if (getTableColReq.length === 0) return;
        setDbConnect(getTableColReq[0]);

        getTableColReq = getTableColReq.map((item) => {
          return {
            ...item,
            is_active: Boolean(item.is_active),
          };
        });

        prepareDataForGenMFController(boIdToGenMF, getTableColReq[0]);
      } else if (fetchKey === PREPARE_GEN_MF_FK) {
        let respPrepareForGenMF: any = responseData;
        // console.log("respPrepareForGenMF => ", respPrepareForGenMF);
        let isError = respPrepareForGenMF.some(
          (resp: any) => resp.status !== STATUS_OK
        );
        if (isError) return;

        let jsonBoMapping = respPrepareForGenMF[0]?.data;
        let tableColResp: TableType[] = respPrepareForGenMF[1]?.data.table;
        let callGenMFReq = getGenerateMFRequest(jsonBoMapping, tableColResp);
        generateMFController(callGenMFReq);
      } else if (fetchKey === GEN_MF_FK) {
        let respGenMF: any = responseData;
        console.log("respGenMF ==> ", respGenMF);
        endProgressLoadingHandler();
      }
    }
  }, [loading, responseData]);

  const onChangeEntitySortHandler = (sort: string) => {
    setSortOrder(sort);
    // let newEntityItems = [...entityItems].sort(sortFn(sort));
    // setEntityItems(newEntityItems);
    getStoryEntityController();
  };
  const sortFn = (sortType: string = NEWEST) => {
    return (a: StoryEnyityBORequest, b: StoryEnyityBORequest) => {
      if (sortType === NEWEST) {
        return a.story_id.toLowerCase().localeCompare(b.story_id.toLowerCase());
      } else {
        return b.story_id.toLowerCase().localeCompare(a.story_id.toLowerCase());
      }
    };
  };
  const onChangeEntitySearchHandler = (search: string) => {
    if (search) {
      let newEntityItems = entityItemsSearch.filter((item) =>
        item.bo_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
      setEntityItems(newEntityItems);
    } else {
      setEntityItems(entityItemsSearch);
    }
  };
  const onClickCreateStoryEntity = () => {
    setOpenCreateEntityDrawer(true);
  };
  const onCloseCreateEntityDrawer = () => {
    setOpenCreateEntityDrawer(false);
  };

  const onCreateStoryEntity = (req: StoryEnyityBORequest) => {
    addStoryEntityController(req);
    setOpenCreateEntityDrawer(false);
    onCreateEntitySuccessNotify(req.bo_name);
  };
  const updateNewStoryEntityItems = (
    newEntityItems: StoryEnyityBORequest[]
  ) => {
    setEntityItems(newEntityItems);
    setEntityItemsSearch(newEntityItems);
  };
  const onCreateEntitySuccessNotify = (entName: string) => {
    setEntityNameCreated(entName);
  };
  const onClickDeleteBoHandler = (id: string, boName: string) => {
    setOpenConfirmDeleteModal(true);
    setTitleDelete(boName);
    setItemToDelete(id);
  };

  const onClickEditBoHandler = (id: string) => {
    console.log("onClickEditBoHandler on id => ", id);
    // setIsOpenEditBoModal(true);
    setisOpenCreateBOModal(true);
    setEditBoSelectedId(id);
  };
  const onClickHistoryBoHandler = (id: string) => {
    console.log("onClickHistoryBoHandler on id => ", id);
    setEnyityBoId(id);
    setIsOpenHistoryVersionDrawer(true);
  };

  const onCloseModalHistoryVersion = () => {
    setIsOpenHistoryVersionDrawer(false);
  };

  const onOkConfirmDeleteHandler = () => {
    if (!itemToDelete) return;
    removeEntity();
    setOpenConfirmDeleteModal(false);
  };
  const removeEntity = () => {
    removeStoryEntityController();
    setItemToDelete("");
  };
  const onCancelConfirmDeleteHandler = () => {
    setOpenConfirmDeleteModal(false);
  };

  //GENERATE PAGE
  const onClickGeneratePageHandler = (boId: string) => {
    let currentPath = location.pathname;
    let genPage = getPathToGenPageWithBoId(currentPath, boId);
    navigate(genPage);
  };
  const onOKGenPageModal = () => {
    setIsOpenGenPageModal(false);
  };
  const onCancelGenPageModal = () => {
    setIsOpenGenPageModal(false);
  };
  const onCreateGenPageModal = (data: any) => {
    console.log("onCreateGenPageModal => ", data);
  };

  const onOKCreateBOModal = () => {
    setisOpenCreateBOModal(false);
  };
  const onCancelCreateBOModal = () => {
    setisOpenCreateBOModal(false);
    setArrBOConfigItems([])
  };

  const onCreateBO = () => {
    let newStoryEntity: StoryEnyityBORequest = {
      story_id: "" as string,
      bo_name: "" || "BO_NAME",
      json_mapping_bo: JSON.stringify(''),
    };

  }

  const onUpdateBO = () => {
    console.log("======== updated ========")
    setIsEditSuccess("success")
    setTimeout(() => {
      setIsEditSuccess("")
    }, 3000);
    // onCreateEntitySuccessNotify('as')
  }

  //GENERATE MICROFLOW
  const onClickGenerateMFHandler = (id: string, boName: string) => {
    console.log(`onClickGenerateMFHandler id : ${id}, boName: ${boName} `);
    setBoIdToGenMF(id);
    getDBConnectController();

    setIsLoadingGenMF(true);
  };

  //LOADING GENERATE MICROFLOW
  const endProgressLoadingHandler = () => {
    setIsLoadingGenMF(false);
    afterGenMF();
  };

  //UTILITY FUNCTION
  const getStoryId = () => {
    return params.id;
  };
  const getBoFromJsonBoMapping = (
    boMapping: any,
    tableColResp: TableType[]
  ) => {
    let result = [];
    let boList = boMapping?.nodeDataArray;

    //GET ONLY BO SIDE FROM BO_MAPPING
    let onlyBo = boList.filter(
      (bo: any) => bo.group === "boGroup" && bo.key !== BO_INIT_KEY
    );

    //COLLECT ONLY TYPE OBJECT
    let collectTypeObjects = onlyBo.filter(
      (bo: any) => bo.typeDesc.toLowerCase() === "object"
    );

    //CREATE BO_LIST BY OBJECT TYPE
    for (const objType of collectTypeObjects) {
      // let cols: any = tableColResp.find(
      //   (item: any) => item.table_name === objType.paramName
      // );
      // let fields = cols.name_column.map((item: any) => {
      //   return {
      //     fieldName: item.column_name,
      //     type: item.column_type,
      //   };
      // });
      let cols: any = onlyBo.filter(
        (item: any) => objType.key === item.parentKey
      );
      let fields = cols.map((item: any) => {
        return {
          fieldName: item.paramName,
          type: item.typeDesc,
        };
      });
      let boGroup = {
        boName: objType.paramName || "",
        fields,
      };
      result.push(boGroup);
    }
    return result;
  };
  const getTableFromJsonBoMapping = (
    boMapping: any,
    tableColResp: TableType[]
  ) => {
    let result = [];
    let boList = boMapping?.nodeDataArray;

    //GET ONLY BO SIDE FROM BO_MAPPING
    let onlyTable = boList.filter(
      (bo: any) => bo.group === "tableGroup" && bo.key !== TABLE_INIT_KEY
    );

    //COLLECT ONLY TYPE OBJECT
    let collectTypeTables = onlyTable.filter(
      (table: any) => table.typeDesc.toLowerCase() === "table"
    );

    //CREATE BO_LIST BY OBJECT TYPE
    for (const tablType of collectTypeTables) {
      let cols: any = tableColResp.find(
        (item: any) => item.table_name === tablType.paramName
      );
      let columns = cols.name_column.map((item: any) => {
        return {
          fieldName: item.column_name,
          type: item.column_type,
          primaryKey: item.primary_key,
          whereKey: item.primary_key,
        };
      });
      let tablGroup = {
        tableName: tablType.paramName || "",
        customWhere: "",
        defaultOrderby: "",
        columns,
      };
      result.push(tablGroup);
    }
    console.log("getTableFromJsonBoMapping => ",result)
    return result;
  };

  const getMappingFromJsonBoMapping = (
    boMapping: any,
    tableColResp: TableType[]
  ) => {
    let result: any = [];
    let boList = boMapping?.nodeDataArray;
    let linkDataArray = boMapping?.linkDataArray;

    //MAPPING DATA FROM TABLE_COL AND BO_MAPPING
    
    let mappingList = linkDataArray.filter((link: any) => link.category === "Mapping")
    let mappingWithDetail: any = [];
    for (const mp of mappingList) {
      //MAPPING DATA FOR PATH_FROM
      let pathFrom = boList.find((bo: any) => bo.key === mp.from);
      pathFrom = {
        ...pathFrom,
        parentDetail: boList.find((bo: any) => bo.key === pathFrom.parentKey),
      };

      //MAPPING DATA FOR PATH_TO
      let pathTo = boList.find((bo: any) => bo.key === mp.to);
      pathTo = {
        ...pathTo,
        parentDetail: boList.find((bo: any) => bo.key === pathTo.parentKey),
      };
      mappingWithDetail.push({ pathFrom, pathTo });
    }

    for (const mpd of mappingWithDetail) {
      let pathFrom = "";
      let pathTo = "";

      let pathFromType = mpd.pathFrom.typeDesc.toLowerCase();
      let pathToType = mpd.pathTo.typeDesc.toLowerCase();
      if (pathFromType === "object") {
        pathFrom = mpd.pathFrom.paramName;
      } else {
        pathFrom =
          mpd.pathFrom.parentDetail.paramName + "." + mpd.pathFrom.paramName;
      }

      if (pathToType === "table") {
        pathTo = mpd.pathTo.paramName;
      } else {
        pathTo = mpd.pathTo.parentDetail.paramName + "." + mpd.pathTo.paramName;
      }

      result.push({ pathFrom, pathTo });
    }

    return result;
  };
  const getGenerateMFRequest = (
    jsonBoMapping: any,
    tableColResp: TableType[]
  ): GenerateMFRequest => {
    let boMapping = JSON.parse(jsonBoMapping?.json_mapping_bo);
    let aspId = getAspId();
    let story_id = getStoryId();
    let bo = getBoFromJsonBoMapping(boMapping, tableColResp);
    let mapping = getMappingFromJsonBoMapping(boMapping, tableColResp);
    let table = getTableFromJsonBoMapping(boMapping, tableColResp);
    let result: GenerateMFRequest = {
      appId: aspId as string,
      flowName: jsonBoMapping.bo_name + "_flow",
      type: "select_all,insert,update,delete,select_one,select_where",
      includeOne: "Y",
      enyity_bo_id: boIdToGenMF,
      story_id: story_id as string,
      bo,
      mapping,
      table,
      // db: getDBFromDBConnection(dbConnect),
    };
    // console.log("getGenerateMFRequest result => ", result);
    return result;
  };

  //API CONTROLLER FUNCTION
  const getStoryEntityController = () => {
    let storyId = getStoryId();
    fetchData(
      {
        endpoint: GET_STORY_ENYITY_ENDPOINT,
        method: "GET",
        params: {
          story_id: storyId,
          sortOrder: sortOrder,
        },
      },
      GET_STORY_ENT_FK
    );
  };
  const addStoryEntityController = (req: StoryEnyityBORequest) => {
    fetchData(
      {
        endpoint: ADD_STORY_ENYITY_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      ADD_STORY_ENT_FK
    );
  };
  const removeStoryEntityController = () => {
    fetchData(
      {
        endpoint: REMOVE_STORY_ENYITY_ENDPOINT,
        method: "DELETE",
        params: {
          id: itemToDelete,
        },
      },
      REMOVE_STORY_ENT_FK
    );
  };
  const getDBConnectController = () => {
    fetchData(
      {
        endpoint: GET_DB_CONNECT_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_DB_CONNECT_FK
    );
  };
  const prepareDataForGenMFController = (
    boId: string,
    dbConnect: GetTableAndColumnRequest
  ) => {
    let getBoById = {
      endpoint: GET_ENTITY_BO_BY_ID_ENDPOINT,
      method: "GET",
      params: {
        id: boId,
      },
    };
    let getTableColReq = {
      endpoint: GET_TABLE_COL_ENDPOINT,
      method: "POST",
      reqBody: dbConnect,
    };
    let request: any = [getBoById, getTableColReq];

    fetchDataAsync(request, PREPARE_GEN_MF_FK);
  };

  const generateMFController = (req: GenerateMFRequest) => {
    fetchData(
      {
        endpoint: GENERATE_MF_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GEN_MF_FK
    );
  };

  //EDIT BO MODAL HANDLER
  const onOkEditBoHandler = () => {
    setIsOpenEditBoModal(false);
  };
  const onCancelEditBoHandler = () => {
    setIsOpenEditBoModal(false);
  };

  return (
    <div className={styles.editStEntContainer}>
      <div className={styles.entConfig}>
        <div className={styles.entConfigTitle}>Entity</div>
        <div className={styles.entConfigInput}>
          <Input
            placeholder={"Search Business Object"}
            suffix={<SearchIcon style={{ marginInlineEnd: "10px" }} />}
            className={styles.searchInput}
            onChange={(e) => onChangeEntitySearchHandler(e.currentTarget.value)}
          />
          <div className={styles.entInputSort}>
            <div className={styles.sortTitle}>Sort&nbsp;by:</div>
            <Select
              // value={dbSelectValue}
              variant={"borderless"}
              defaultValue={sortItems[0].value}
              options={sortItems}
              style={{ width: "100%" }}
              placeholder={
                <div style={{ fontWeight: "bold" }}>
                  &nbsp;&nbsp;&nbsp;Search
                </div>
              }
              onChange={onChangeEntitySortHandler}
              optionFilterProp="children"
              className={styles.entInputSelect}
            />
          </div>
          <Button
            type="primary"
            className={styles.createBoBtn}
            onClick={onClickCreateStoryEntity}
          >
            Create B/O
          </Button>
        </div>
      </div>

      {/* BO LIST SECTION */}
      {entityItems.length > 0 && (
        <div className={styles.entSelected}>
          <div className={styles.entSelectedTitle}>
            BO ({entityItems.length})
          </div>
          <div className={styles.entSelectedList}>
            {entityItems.map((ent_item) => {
              return (
                <div key={ent_item.id} className={styles.entSelectedItem}>
                  <p>{ent_item.bo_name}</p>
                  <div className={styles.entAction}>
                    <Button
                      className={styles.genPageBtn}
                      key="genpage"
                      onClick={() =>
                        onClickGeneratePageHandler(ent_item.id as string)
                      }
                    >
                      Generate Component
                    </Button>
                    {/* <Button
                      className={styles.genMFBtn}
                      key="genmf"
                      onClick={() =>
                        onClickGenerateMFHandler(
                          ent_item.id as string,
                          ent_item.bo_name
                        )
                      }
                    >
                      Generate Microflow
                    </Button> */}

                    {/* <div className={styles.actionIconWrapper}>
                      <StoryEditEntEdit className={styles.actionIcon} />
                    </div> */}
                    <div
                      className={styles.actionIconWrapper}
                      onClick={() =>
                        onClickEditBoHandler(ent_item.id as string)
                      }
                    >
                      <StoryEditEntEdit className={styles.actionIcon} />
                    </div>
                    <div
                      className={styles.actionIconWrapper}
                      onClick={() =>
                        onClickDeleteBoHandler(
                          ent_item.id as string,
                          ent_item.bo_name as string
                        )
                      }
                    >
                      <StoryEditEntDelete className={styles.actionIcon} />
                    </div>
                    <div
                      className={styles.actionIconWrapper}
                      onClick={() =>
                        onClickHistoryBoHandler(ent_item.id as string)
                      }
                    >
                      <StoryEditEntHistory className={styles.actionIcon} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* CREATE STORY DRAWER */}
      <StoryEntityDrawer
        isOpen={openCreateEntityDrawer}
        onClose={onCloseCreateEntityDrawer}
        onCreate={onCreateStoryEntity}
      />
      {/* CREATE ENTITY NOTIFICATION */}
      <CreateEntitySuccessNotify isCreated={entityNameCreated} />
      { isEditSuccess && isEditSuccess !== '' && (
        <UpdateEntitySuccessNotify isUpdated={isEditSuccess} />
      )}
      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: `Delete ${titleDelete} ?`,
          description: `Are you sure you want to delete this database? This action cannot be
        undone.`,
        }}
        isModalOpen={openConfirmDeleteModal}
        onOk={onOkConfirmDeleteHandler}
        onCancel={onCancelConfirmDeleteHandler}
      />

      {/* GENERATE PAGE MODAL */}
      <StoryEntityGenPageModal
        isModalOpen={isOpenGenPageModal}
        onOk={onOKGenPageModal}
        onCancel={onCancelGenPageModal}
        onCreate={onCreateGenPageModal}
      />

      {/* GENERATE MF LOADING */}
      <GenerateMFLoading isLoading={isLoadingGenMF} />

      <EditBoModal
        boId={editBoSelectedId}
        isModalOpen={isOpenEditBoModal}
        onOk={onOkEditBoHandler}
        onCancel={onCancelEditBoHandler}
      />
      {/* FOR EDIT BO */}
      {isOpenCreateBOModal && (
        <StoryEntityProvider> 
          <CreateStoryEntityModal
            isModalOpen={isOpenCreateBOModal}
            entityType={entityType}
            onOk={onOKCreateBOModal}
            onCancel={onCancelCreateBOModal}
            onCreate={onCreateBO}
            onUpdate={onUpdateBO}
            arrBOConfigItems = { arrBOConfigItems }
            boId={editBoSelectedId} 
            isCreateMode = { false }
          /> 
        </StoryEntityProvider>
       )} 
      <HistoryVersionDrawer
        isOpen={isOpenHistoryVersionDrawer}
        enyityBoID={enyityBoID}
        onCreate={onClickCreateStoryEntity}
        onClose={onCloseModalHistoryVersion}
      />
    </div>
  );
}

export default EditStoryEntity;
