import React, { createContext, useState, ReactNode } from "react";
import { BoConfigType } from "src/types/BoConfigType";
import { MsSelectToConfigType } from "src/types/MoreSettingType";

type BoConfigProps = {
  boConfigItems: BoConfigType[];
  setBoConfigItems: (boConfigType: BoConfigType[]) => void;
};

export const BoConfigContext = createContext<BoConfigProps>({
  boConfigItems: [],
  setBoConfigItems: () => {},
});

export const BoConfigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  let [boConfigItems, setBoConfigItems] = useState<BoConfigType[]>([]);

  const boConfigItemsValue: BoConfigProps = {
    boConfigItems,
    setBoConfigItems,
  };

  return (
    <BoConfigContext.Provider value={boConfigItemsValue}>
      {children}
    </BoConfigContext.Provider>
  );
};


type MsConfigProps = {
  dataMsConfig: MsSelectToConfigType | undefined;
  setDataMsConfig: (input: MsSelectToConfigType) => void;
};

export const MsConfigContext = createContext<MsConfigProps>({
  dataMsConfig: undefined,
  setDataMsConfig: () => {},
});

export const MsConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  let [dataMsConfig, setDataMsConfig] = useState<MsSelectToConfigType>();
  const msConfigValue: MsConfigProps = {
    dataMsConfig,
    setDataMsConfig,
  };
  return (
    <MsConfigContext.Provider value={msConfigValue}>
      {children}
    </MsConfigContext.Provider>
  );
};
