import { FlowBo, FlowScreensEdit, GenerateMFRequest, GeneratePageConfigScreenRequest, GeneratePageOnewebRequest } from "src/types/request.type";
import { GenActionReq, GenFlowReq, GenFlowRes, GenSchemaReq, GenStateReq, GetPageNodeRes, InputOutputMfdRes } from "src/types/response.type";
import { v4 as uuidv4 } from "uuid";
import { callApiMethodPagePost, callApiMethodPagePut, checkEmpty, getAspId, getToken, parseJwt } from "./util.service";
import { CREATE_PAGE_ACTION, CREATE_PAGE_FLOW_MAPPING } from "src/utils/endpoint/createbo.endpoint";

export const prepareGenStateReq = (jsonGenHtmlTemp: any, pageId: string): any => {

  const getChild = (parentid: string, parentpath: string, parentmargin: number, flowBo: any) => {
    let boJson: any = []
    for (let a = 0; a < flowBo.fields.length; a++) {
      let objectName = flowBo.fields[a].fieldName;
      let id = uuidv4();
      let path = `${parentpath}.${objectName}`
      let margin = parentmargin + 20
      let boObject = {
        "key": id,
        "group": "PGMainGroup",
        "parentKey": parentid,
        "paramName": `${objectName}`,
        "typeDesc": flowBo.fields[a].type,
        "isList": false,
        "propName": "",
        "margin": `0 0 0 ${margin}`,
        "category": "PGIsGroup",
        "pathName": path
      };

      if (flowBo.fields[a].type == "Object") {
        boJson.push(boObject)
        let childs = getChild(id, path, margin, flowBo.fields[a])
        boJson = boJson.concat(childs)
      } else {
        boObject.category = "PGIsNotGroup"
        boJson.push(boObject)
      }
    }
    return boJson;
  }

  let boJson = [];
  // let keyInitId = "PGInit";//uuidv4()
  let keyDummy = uuidv4();
  let action = {
    "key": uuidv4(),
    "group": "PGMainGroup",
    "parentKey": keyDummy,
    "paramName": "action",
    "typeDesc": "String",
    "isList": false,
    "propName": "select_all_main",
    "margin": "0 0 0 50",
    "category": "PGIsNotGroup",
    "pathName": `${keyDummy}.action`
  }
  let search = {
    "key": uuidv4(),
    "group": "PGMainGroup",
    "parentKey": keyDummy,
    "paramName": "search",
    "typeDesc": "String",
    "isList": false,
    "propName": "",
    "margin": "0 0 0 50",
    "category": "PGIsNotGroup",
    "pathName": `${keyDummy}.search`
  }
  let limit = {
    "key": uuidv4(),
    "group": "PGMainGroup",
    "parentKey": keyDummy,
    "paramName": "limit",
    "typeDesc": "Integer",
    "isList": false,
    "propName": 10,
    "margin": "0 0 0 50",
    "category": "PGIsNotGroup",
    "pathName": `${keyDummy}.limit`
  }
  let offset = {
    "key": uuidv4(),
    "group": "PGMainGroup",
    "parentKey": keyDummy,
    "paramName": "offset",
    "typeDesc": "Integer",
    "isList": false,
    "propName": 0,
    "margin": "0 0 0 50",
    "category": "PGIsNotGroup",
    "pathName": `${keyDummy}.offset`
  }
  boJson.push(action)
  boJson.push(search)
  boJson.push(limit)
  boJson.push(offset)
  let flowBos = jsonGenHtmlTemp.bo as FlowBo[];
  let objectName;
  for (let a = 0; a < flowBos.length; a++) {
    objectName = flowBos[a].boName
    let id = uuidv4();
    let path = `${keyDummy}.${objectName}`
    let pathDummy = `${keyDummy}.${objectName}`
    let margin = 50
    let boObject = {
      "key": id,
      "group": "PGMainGroup",
      "parentKey": keyDummy,
      "paramName": `${objectName}`,
      "typeDesc": "Object",
      "isList": false,
      "propName": "",
      "margin": `0 0 0 ${margin}`,
      "category": "PGIsGroup",
      "pathName": pathDummy
    };
    boJson.push(boObject)
    let childs: any = getChild(id, path, margin, flowBos[a])
    boJson = boJson.concat(childs)
  }

  //ADD BO LIST
  for (let a = 0; a < flowBos.length; a++) {
    objectName = flowBos[a].boName
    let id = uuidv4();
    let path = `${keyDummy}.${objectName}_list`
    let pathDummy = `${keyDummy}.${objectName}_list`
    let margin = 50
    let boObject = {
      "key": id,
      "group": "PGMainGroup",
      "parentKey": keyDummy,
      "paramName": `${objectName}_list`,
      "typeDesc": "Object",
      "isList": true,
      "propName": "",
      "margin": `0 0 0 ${margin}`,
      "category": "PGIsGroup",
      "pathName": pathDummy
    };
    boJson.push(boObject)
    let childs: any = getChild(id, path, margin, flowBos[a])
    boJson = boJson.concat(childs)
  }


  let genStateReq: GenStateReq = {
    id: pageId,
    pageId: pageId,
    boJson: JSON.stringify(boJson)
  }
  return { genStateReq: genStateReq, boJsonState: boJson, pgkeyDummy: keyDummy };

}



export const prepareGenSchemaReq = (jsonGenHtmlTemp: any, pageId: string, outputMfdRes: InputOutputMfdRes, nodeId: string): GenSchemaReq => {

  const getChildOutput = (param: any) => {
    let boJson: any = []
    if (checkEmpty(param)) return boJson;
    for (let a = 0; a < param.length; a++) {
      let obj: any = param[a];
      let objTemp = Object.assign({}, obj)
      objTemp.param = null
      boJson.push(objTemp)
      let childs: any = getChildOutput(obj.param)
      boJson = boJson.concat(childs)
    }
    return boJson;
  }


  let boJson: any = []
  //@ts-ignore
  for (let a = 0; a < outputMfdRes.data?.root.length; a++) {
    let obj: any = outputMfdRes.data?.root[a];
    let objTemp = Object.assign({}, obj)
    objTemp.param = null
    boJson.push(objTemp)

    let childs: any = getChildOutput(obj.param)
    boJson = boJson.concat(childs)
  }

  let id = `${jsonGenHtmlTemp?.flow?.flowId}@${pageId}`;
  let genSchemaReq: GenSchemaReq = {
    id: id,
    flowId: jsonGenHtmlTemp?.flow?.flowId,
    flowProject: jsonGenHtmlTemp?.flow?.appName,
    name: "Input Business Object",
    flowName: jsonGenHtmlTemp?.flow?.flowName,
    nodeId: nodeId,
    pageId: pageId,
    boJson: JSON.stringify(boJson)
  }
  return genSchemaReq

}



export const prepareGenFlowReq = (jsonGenHtml: any, pageIdToGen: string, nodeContentId: string, inputMfdRes: InputOutputMfdRes, boJsonState: any, pgkeyDummy: string): any => {

  const getChildInput = (param: any, parentKey: string) => {
    let nodes: any = [];
    let links: any = [];
    if (checkEmpty(param)) return { nodeChilds: nodes, linkChilds: links };;
    for (let a = 0; a < param.length; a++) {
      let obj: any = param[a];
      let objTemp = Object.assign({}, obj)
      objTemp.param = null
      objTemp.parentKey = parentKey;
      nodes.push(objTemp)
      let linkTemp = {
        "from": parentKey,
        "to": obj.key
      }
      links.push(linkTemp)

      let { nodeChilds, linkChilds }: any = getChildInput(obj.param, obj.key)
      nodes = nodes.concat(nodeChilds)
      links = links.concat(linkChilds)
    }
    return { nodeChilds: nodes, linkChilds: links };
  }

  const getKeyByPath = (path: string, nodeDataArray: any[]) => {
    for (let a = 0; a < nodeDataArray.length; a++) {
      if (nodeDataArray[a].pathName == path) return nodeDataArray[a].key
    }
    console.error("not found key by path:", path, nodeDataArray)
  }

  let nodeDataArray: any = [
    {
      "loc": "450, 0",
      "text": "Input",
      "isGroup": true,
      "key": "inputMainGroup"
    },
    {
      "paramName": "Input Process Parameter",
      "category": "inputInitGroup",
      "isGroup": false,
      "key": "inputKeyInit",
      "group": "inputMainGroup"
    }
  ]
  let linkDataArray: any = []

  let nodesInputMain: any = [];
  let linksInputMain: any = [];
  let parentKey = "inputKeyInit";
  //@ts-ignore
  for (let a = 0; a < inputMfdRes.data?.root.length; a++) {
    let obj: any = inputMfdRes.data?.root[a];
    let objTemp = Object.assign({}, obj)
    objTemp.param = null
    objTemp.parentKey = parentKey;
    nodesInputMain.push(objTemp)
    let linkTemp = {
      "from": parentKey,
      "to": obj.key
    }
    linksInputMain.push(linkTemp)

    let { nodeChilds, linkChilds }: any = getChildInput(obj.param, obj.key)
    nodesInputMain = nodesInputMain.concat(nodeChilds)
    linksInputMain = linksInputMain.concat(linkChilds)
  }

  // let dummyKey = uuidv4()
  let pgNodes: any = [
    {
      "loc": "0, 0",
      "isGroup": true,
      "key": "PGMainGroup"
    },
    {
      "paramName": "Page Designer parameter",
      "category": "PGInitGroup",
      "isGroup": false,
      "key": "PGInit",
      "group": "PGMainGroup"
    },
    {
      "pathName": pgkeyDummy,
      "margin": "0 0 0 30",
      "typeDesc": "Object",
      "paramName": pgkeyDummy,
      "isList": false,
      "isExpand": false,
      "mustRef": false,
      "parentKey": "PGInit",
      "category": "PGDummyGroup",
      "isGroup": false,
      "key": pgkeyDummy,
      "seq": 0,
      "group": "PGMainGroup"
    }
  ]
  let pgLinks: any = [{
    "from": "PGInit",
    "to": pgkeyDummy
  }];



  let paths: any = [
    { pathF: `${pgkeyDummy}.action`, pathT: `${jsonGenHtml?.flow?.flowName}_flow_input.action` },
    { pathF: `${pgkeyDummy}.search`, pathT: `${jsonGenHtml?.flow?.flowName}_flow_input.search` },
    { pathF: `${pgkeyDummy}.limit`, pathT: `${jsonGenHtml?.flow?.flowName}_flow_input.limit` },
    { pathF: `${pgkeyDummy}.offset`, pathT: `${jsonGenHtml?.flow?.flowName}_flow_input.offset` },
  ]
  for (let a = 0; a < boJsonState.length; a++) {
    let nodeTemp = Object.assign({}, boJsonState[a])
    nodeTemp.group = "PGMainGroup";
    let linkTemp = {
      "from": nodeTemp.parentKey,
      "to": nodeTemp.key
    }
    pgLinks.push(linkTemp)
    pgNodes.push(nodeTemp)

    if (nodeTemp.typeDesc != "Object") {
      let parentName = "";
      for (let b = 0; b < boJsonState.length; b++) {
        if (boJsonState[b].key == nodeTemp.parentKey) {
          parentName = boJsonState[b].paramName
        }
      }
      let pathName = nodeTemp.pathName;
      pathName = pathName.replace(pgkeyDummy,"")
      let pathF = `${pgkeyDummy}${pathName}`;
      // let pathF = `${nodeTemp.parentKey}.${parentName}.${nodeTemp.paramName}`;
      if (pgkeyDummy != nodeTemp.parentKey)
        paths.push({ pathF: pathF, pathT: `${jsonGenHtml?.flow?.flowName}_flow_input.parameter${pathName}` })
    }

  }
  console.log("paths", paths)
  nodeDataArray = nodeDataArray.concat(nodesInputMain)
  nodeDataArray = nodeDataArray.concat(pgNodes)



  let linkMappings: any = [];
  for (let a = 0; a < paths.length; a++) {
    let from = getKeyByPath(paths[a].pathF, nodeDataArray)
    let to = getKeyByPath(paths[a].pathT, nodeDataArray)
    if (!checkEmpty(from) && !checkEmpty(to)) {
      let link = {
        "category": "Mapping",
        "from": from,
        "to": to,
      }
      linkMappings.push(link)
    }
  }
  linkDataArray = linkDataArray.concat(linksInputMain)
  linkDataArray = linkDataArray.concat(pgLinks)
  linkDataArray = linkDataArray.concat(linkMappings)

  let boJson = { nodeDataArray, linkDataArray };
  let genFlowReq: GenFlowReq = {
    id: nodeContentId,
    flowId: jsonGenHtml?.flow?.flowId,
    flowProject: jsonGenHtml?.flow?.appName,
    name: "mapping for page",
    flowName: jsonGenHtml?.flow?.flowName,
    nodeId: nodeContentId,
    pageId: pageIdToGen,
    boJson: JSON.stringify(boJson)
  }
  return genFlowReq
}

export const prepareGenActionReq = (jsonGenHtml: any, pageIdToGen: string, nodeContentId: string, genFlowRes: GenFlowRes, dateTimeGenMain: string, pNameMain: string, eventType: string, beforeLoad: string, afterLoad: string) => {

  let userObj = parseJwt(getToken())
  let fullname = userObj.firstname;
  if (!checkEmpty(userObj.lastname)) {
    fullname = fullname + "." + userObj.lastname
  }
  let flowBos = jsonGenHtml.bo as FlowBo[];
  let objectName;
  for (let a = 0; a < flowBos.length; a++) {
    objectName = flowBos[a].boName
  }
  let date = new Date();
  let mappingId: any = genFlowRes.data?.id;
  if (!checkEmpty(afterLoad)) {
    afterLoad = `${afterLoad}_${pNameMain}_${objectName}_${dateTimeGenMain}`
  }
  if (!checkEmpty(beforeLoad)) {
    beforeLoad = `${beforeLoad}_${pNameMain}_${objectName}_${dateTimeGenMain}`
  }

  let genActionReq: GenActionReq = {
    id: uuidv4(),
    createdDate: date,
    updatedDate: date,
    createdBy: fullname,
    updatedBy: fullname,
    isDeleted: 0,
    nodeId: nodeContentId,
    eventType: eventType,
    action: {
      id: uuidv4(),
      createdDate: date,
      updatedDate: date,
      createdBy: fullname,
      updatedBy: fullname,
      isDeleted: 0,
      appId: jsonGenHtml?.flow?.appId,
      pageId: pageIdToGen,
      actionType: "MicroFlow",
      beforeFunctionName: beforeLoad,
      afterFunctionName: afterLoad,
      functionName: "",
      microflowProjectId: jsonGenHtml?.flow?.appId,
      microflowProcessId: jsonGenHtml?.flow?.flowId,
      microflowProcessName: jsonGenHtml?.flow?.flowName,
      infiniteScroll: 0,
      bufferSize: 10,
      loadingAnimation: 0,
      loadingType: "",
      customReqHeader: "[]",
      microflowProjectName: jsonGenHtml?.flow?.appName,
      snapVersion: jsonGenHtml?.flow?.snapVersion,
      microflowMappingId: mappingId
    }
  }
  return genActionReq
}

export const getPageNodeIDByHtmlID = (getPageNodeRes: GetPageNodeRes, id: string): string => {

  let pageNodes: any = getPageNodeRes.data?.pageNodes;
  for (let a = 0; a < pageNodes.length; a++) {
    for (let b = 0; b < pageNodes[a].props.length; b++) {
      let prop = pageNodes[a].props[b];
      if (prop.value == id && prop.name == "id")
        return pageNodes[a].id;
    }
  }
  return "";
}

export const getPageNodeIDByClassname = (getPageNodeRes: GetPageNodeRes, className: string): string => {

  let pageNodes: any = getPageNodeRes.data?.pageNodes;
  for (let a = 0; a < pageNodes.length; a++) {
    for (let b = 0; b < pageNodes[a].props.length; b++) {
      let prop = pageNodes[a].props[b];
      if (prop.value == className && prop.name == "className")
        return pageNodes[a].id;
    }
  }
  return "";
}
export const prepareGenActionClickReq = (jsonGenHtml: any, pageIdToGen: string, nodeId: string, functionName: string, event: string) => {

  let userObj = parseJwt(getToken())
  let fullname = userObj.firstname;
  if (!checkEmpty(userObj.lastname)) {
    fullname = fullname + "." + userObj.lastname
  }
  let flowBos = jsonGenHtml.bo as FlowBo[];
  let objectName;
  for (let a = 0; a < flowBos.length; a++) {
    objectName = flowBos[a].boName
  }
  let date = new Date();
  let genActionReq: GenActionReq = {
    id: uuidv4(),
    createdDate: date,
    updatedDate: date,
    createdBy: fullname,
    updatedBy: fullname,
    isDeleted: 0,
    nodeId: nodeId,
    eventType: event,
    action: {
      id: uuidv4(),
      createdDate: date,
      updatedDate: date,
      createdBy: fullname,
      updatedBy: fullname,
      isDeleted: 0,
      appId: getAspId() as string,
      pageId: pageIdToGen,
      actionType: "JavaScript",
      beforeFunctionName: ``,
      afterFunctionName: ``,
      functionName: functionName,
      microflowProjectId: "",
      microflowProcessId: "",
      microflowProcessName: "",
      infiniteScroll: 0,
      bufferSize: 10,
      loadingAnimation: 0,
      loadingType: "",
      customReqHeader: "[]",
      microflowProjectName: "",
      snapVersion: "",
      microflowMappingId: ""
    }
  }
  return genActionReq
}

export const getMainObjName = (jsonGenHtml: any) => {
  let flowBos = jsonGenHtml.bo as FlowBo[];
  let objectName;
  for (let a = 0; a < flowBos.length; a++) {
    objectName = flowBos[a].boName
  }
  return objectName
}

export const prepareDataGenPage = (editPage: any, pageName: string, versionName: string, templateId: string, boId: string, storyId: string, pageGenerateId: string): GeneratePageOnewebRequest => {


  // div2Content: html,
  // jsText: js,
  // cssText: css,

  let js = editPage.jsText;
  let css = editPage.cssText;
  let html = editPage.div2Content;

  console.log(pageName, html)
  // @ts-ignore
  let convertHtml: any = window?.CONVERT_HTML_TO_OBJECT(html, pageName);
  console.log(pageName, convertHtml?.page?.pageNodes)
  let htmlToGen = JSON.stringify(convertHtml?.page?.pageNodes);

  let appId = getAspId() as string;
  let pageTitle = versionName;
  let enyity_bo_id = boId;
  let story_id = storyId;
  html = htmlToGen;
  let page_generate_id = pageGenerateId;

  return {
    appId,
    pageName,
    pageTitle,
    templateId,
    enyity_bo_id,
    story_id,
    page_generate_id,
    js,
    css,
    html,
  };

}


export const genHtmlWithEvent = async (getPageNodeRes: GetPageNodeRes, jsonGenHtml: any, pageIdToGen: string, dateTime: string, htmlId: string, fnName: string, event: string, pageName: string) => {

  let btnEditHtmlId = htmlId;
  let pageNodeEditId: string = getPageNodeIDByHtmlID(getPageNodeRes, btnEditHtmlId);
  let functionName = `${fnName}_${pageName}_${getMainObjName(jsonGenHtml)}_${dateTime}`
  let genActionClickEditReq: GenActionReq = prepareGenActionClickReq(jsonGenHtml, pageIdToGen, pageNodeEditId, functionName, event);
  await callApiMethodPagePut(`${CREATE_PAGE_ACTION}`, genActionClickEditReq)


}

export const createMappinMFDOnNode = async (jsonGenHtml: any, pageIdToGen: string, nodeContentId: string, inputMfdRes: InputOutputMfdRes, boJsonState: any, pgkeyDummy: string, dateTimeGenMain: string, pNameMain: string, eventType: string, beforeLoad: string, afterLoad: string) => {

  //create mapping mfd with page state for page designer (page to call mfd)
  let genFlowReq: GenFlowReq = prepareGenFlowReq(
    jsonGenHtml,
    pageIdToGen,
    nodeContentId,
    inputMfdRes,
    boJsonState,
    pgkeyDummy
  );
  let genFlowRes: any = await callApiMethodPagePost(
    `${CREATE_PAGE_FLOW_MAPPING}`,
    genFlowReq
  );

  //create some action with page to call mfd and set afterload mfd also
  let genActionReq: GenActionReq = prepareGenActionReq(
    jsonGenHtml,
    pageIdToGen,
    nodeContentId,
    genFlowRes,
    dateTimeGenMain,
    pNameMain,
    eventType,
    beforeLoad,
    afterLoad
  );

  let genActionRes: any = await callApiMethodPagePut(
    `${CREATE_PAGE_ACTION}`,
    genActionReq
  );

}