import React, { useEffect, useState, useContext } from "react";
import DataReferenceDrawer from "src/components/zero/zero-story/modal/modal-data-refer/DataReferenceDrawer";
import StoryEmptyItem from "src/components/zero/zero-story/empty-page/StoryEmptyItem";
import styles from "src/page/data-reference/DataReference.module.scss";
import StoryCardProfile from "../../assets/png/story-card-profile.png";
import DataReferenceCardCreate from "src/components/zero/zero-story/data-reference/DataReferenceCardCreate";
import DataReferenceItemCardItem from "src/components/zero/zero-story/data-reference/DataReferenceItem";
import useViewMode from "src/hooks/useViewMode";
import { CARD_MODE, LIST_MODE } from "src/constants/ViewMode";
import DataReferenceList from "src/components/zero/zero-story/data-reference/DataReferenceList";
import DashboardZeroContent from "../component/DashboardZeroContent";
import { callApiMethodGet, callApiMethodPost } from "src/services/util.service";
import PlusSmallColorIcon from "src/assets/svg/plus-small-color";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";
import { useLocation } from "react-router-dom";
import { GET_DATA_REFER_BY_ASPID_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";
import EditDataReferModal from "src/components/zero/zero-story/modal/modal-data-refer/EditDataReferModal";
import { AppContext } from "../context/App.context";

type Props = {};
type DataReferenceItemProp = {
  DataReferID: string;
  DataReferName: string;
  InputType: string;
  DataReferType: string;
  CreatedBy: string;
  CreatedAt: string;
};

function DataReference({}: Props) {
  const aspID = localStorage.getItem("asp_id");
  const location = useLocation();
  const appContext: any = useContext(AppContext);

  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState("");
  let [isOpenEditDataReferModal, setIsOpenEditDataReferModal] =
    useState<boolean>(false);
  const [dataReferID, setDataReferID] = useState("");
  const [searchApp, setSearchApp] = useState(appContext.searchApp);

  useEffect(() => {
    // เช็คการเปลี่ยนแปลงของ location เทียบกับ previousLocation
    if (location.search !== sortOrder) {
      setSortOrder(location.search);
      APIGetDataByAspID();
    }

    if (appContext.selectedFilter !== setFilter) {
      setFilter(appContext.selectedFilter);
      APIGetDataByAspID();
    }

    if (appContext.searchApp !== searchApp) {
      setSearchApp(appContext.searchApp);
      APIGetDataByAspID();
    }

    APIGetDataByAspID();
  }, [location, sortOrder, filter, appContext, searchApp]);

  // let mockupItems: DataReferenceItemProp[] = [];
  let [openDrawerCreate, setOpenDrawerCreate] = useState<boolean>(false);
  let [isSearch, setIsSearch] = useState<boolean>(false);

  // let newDataReferenceItems: DataReferenceItemProp[] = [];
  let [dataReferenceItems, setDataReferenceItems] = useState<
    DataReferenceItemProp[]
  >([]);

  let [viewMode, setViewMode] = useViewMode();

  const APIGetDataByAspID = async () => {
    try {
      const response = await callApiMethodGet(
        GET_DATA_REFER_BY_ASPID_ENDPOINT + location.search,
        {
          asp_id: aspID,
          data_type: filter,
          data_refer_name: searchApp,
        }
      );
      const newData = response.data.map((item: any) => ({
        DataReferID: item.data_refer_id,
        DataReferName: item.data_refer_name,
        InputType: item.input_type,
        DataReferType: item.data_refer_type,
        CreatedBy: item.created_by,
        CreatedAt: item.created_at,
      }));
      setDataReferenceItems(Object.assign([], newData));
    } catch (error) {
      setDataReferenceItems([]);
    }
  };

  //ON CHANGE VIEW MODE
  useEffect(() => {
    console.log("Story viewmode : ", viewMode);
  }, [viewMode]);

  const onClickCreateStory = () => {
    setOpenDrawerCreate(true);
  };

  const onCloseDrawerCreate = () => {
    setOpenDrawerCreate(false);
  };

  const onClickCreate = () => {
    console.log("onClickCreate");
    setOpenDrawerCreate(true);
  };

  const onOpenEditDataReferModel = (dataReferID: string) => {
    setDataReferID(dataReferID);
    setIsOpenEditDataReferModal(true);
  };

  const onCloseEditDataReferModel = () => {
    setIsOpenEditDataReferModal(false);
  };

  const content = (
    <div className={styles.dataReferenceContainer}>
      <div className={styles.headerTitle}>
        <div className={styles.title}>
          Data Reference {`(${dataReferenceItems?.length || 0})`}
        </div>
        {viewMode === LIST_MODE && dataReferenceItems?.length > 0 && (
          <div style={{ cursor: "pointer" }} onClick={() => onClickCreate()}>
            <PlusSmallColorIcon width={30} height={30} />
          </div>
        )}
      </div>
      {dataReferenceItems.length > 0 ? (
        viewMode === CARD_MODE ? (
          <div className={styles.dataReferenceWrapper}>
            <DataReferenceCardCreate onClickCreate={onClickCreateStory} />
            {dataReferenceItems.map((item) => {
              return (
                <DataReferenceItemCardItem
                  id={item.DataReferID}
                  dataReferName={item.DataReferName}
                  userProfile={StoryCardProfile}
                  owner={item.CreatedBy}
                  date={formatISODateToCustomFormat(item.CreatedAt)}
                  inputType={item.InputType}
                  openEditModel={onOpenEditDataReferModel}
                />
              );
            })}
          </div>
        ) : (
          // CARD VIEW
          <DataReferenceList
            dataReferenceItemsList={dataReferenceItems}
            CallAPIGetDataByAspID={APIGetDataByAspID}
            openEditDataReferModal={onOpenEditDataReferModel}
          />
        ) // STORY ITEMS EMPTY
      ) : (
        !isSearch && (
          <StoryEmptyItem
            onClickCreate={onClickCreateStory}
            txtName="Create Data Reference"
          />
        )
      )}
      <DataReferenceDrawer
        isOpen={openDrawerCreate}
        onClose={onCloseDrawerCreate}
        RefaceApi={APIGetDataByAspID}
      />
      <EditDataReferModal
        dataReferID={dataReferID}
        isOpenModal={isOpenEditDataReferModal}
        onClose={onCloseEditDataReferModel}
        RefaceApi={APIGetDataByAspID}
      />
    </div>
  );

  return (
    <>
      <div style={{ height: "100%" }}>
        <DashboardZeroContent content={content} />
      </div>
    </>
  );
}

export default DataReference;
