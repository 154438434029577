import { BoConfigType } from "src/types/BoConfigType";
import getBoConfigItemDefault from "./BoConfigItemTemplate";
import { v4 as uuidv4 } from "uuid";
import { BoMappingInit } from "../bo-initial-data/BoMappingInit";

class CreateBusinessObjectHandler {
  public createBoItems: any[] = [];
  public createBoNodes: any[] = [];
  public createBoConfigItems: any[] = [];
  public moreSettingItems: any[] = [];
  public createBoConfigRowSeleted: string = "";

  getCreateBoItems() {
    return this.createBoItems;
  }
  setCreateBoItems(newCreateBoItems: any[]) {
    this.createBoItems = newCreateBoItems;
  }
  getCreateBoNodesWithoutObject() {
    return this.createBoNodes;
  }
  setCreateBoNodesWithoutObject(newCreateBoNodes: any[]) {
    let createBoNodesWithoutObj = newCreateBoNodes.filter(
      (bo) => bo.typeDesc !== "Object"
    );
    this.createBoNodes = createBoNodesWithoutObj;
  }
  getMoreSettingItems() {
    return this.moreSettingItems;
  }
  getMoreSettingItemById(id: string) {
    return this.moreSettingItems.find((item) => item.id === id);

  }
  setMoreSettingItems(newMoreSettingItems: any[]) {
    this.moreSettingItems = newMoreSettingItems;
  }
  getCreateBoConfigItems() {
    return this.createBoConfigItems;
  }
  setCreateBoConfigItems(newCreateBoConfigItems: any[]) {
    this.createBoConfigItems = newCreateBoConfigItems;
  }
  updateCreateBoConfigItemByID({
    id,
    boName,
    inputType,
    displayView,
    displayEdit,
    isHidden,
  }: {
    id: string;
    boName?: string;
    inputType?: string;
    displayView?: string;
    displayEdit?: string;
    isHidden?: boolean;
  }) {
    let newCreateBoConfigItems = this.createBoConfigItems.map((item) => {
      if (item.id === id) {
        if (boName) item.boName = boName;
        if (inputType) item.inputType = inputType;
        if (displayView) item.displayView = displayView;
        if (displayEdit) item.displayEdit = displayEdit;
        if (isHidden !== undefined) item.isHidden = isHidden;
        return item;
      } else {
        return item;
      }
    });
    this.createBoConfigItems = newCreateBoConfigItems;
  }

  isBoNodeNotChanged(): boolean {
    let boNodes = this.getCreateBoConfigItems();
    let boNodesFromCreateBo = this.getCreateBoNodesWithoutObject();
    if (boNodes.length !== boNodesFromCreateBo.length) return false;
    for (const index in boNodesFromCreateBo) {
      if (boNodesFromCreateBo[index].paramName !== boNodes[index].boName)
        return false;
      if (boNodesFromCreateBo[index].typeDesc !== boNodes[index].dataType)
        return false;
    }
    return true;
  }

  // getBoConfigItemsByBoNodes() {
  //   let boConfigItemsByNodes = [];
  //   let boConfigItems = this.getCreateBoConfigItems();
  //   let boNodes = this.getCreateBoNodesWithoutObject();

  //   //BO_CONFIG_ITEMS NOT NULL. CHECK ITEMS UPDATING.
  //   if (boConfigItems.length > 0) {
  //     //BONODE FROM BO_MAPPING HAD CHANGED. UPDATE NEW BO_CONFIG_ITEMS
  //     if (!this.isBoNodeNotChanged()) {
  //       let newBoConfigItems: BoConfigType[] = [];
  //       for (const node of boNodes) {
  //         let newBoConfigItem: BoConfigType;
  //         let oldBoConfigItem = boConfigItems.find((bo) => bo.id === node.key);
  //         if (oldBoConfigItem) {
  //           newBoConfigItem = {
  //             ...oldBoConfigItem,
  //             boName: node.paramName,
  //             dataType: node.typeDesc,
  //           };
  //         } else {
  //           newBoConfigItem = getBoConfigItemDefault(
  //             node.key,
  //             node.paramName,
  //             node.typeDesc
  //           );
  //         }
  //         newBoConfigItems.push(newBoConfigItem);
  //       }
  //       boConfigItemsByNodes = newBoConfigItems;
  //       this.setCreateBoConfigItems(newBoConfigItems);

  //       //ELSE SETSTATE BY OLD BO_CONFIG_ITEMS
  //     } else {
  //       boConfigItemsByNodes = boConfigItems;
  //     }

  //     //ELSE BO_CONFIG_ITEMS IS NULL. ADD ITEMS TO BO_CONFIG_ITEMS BY BO_NODE FROM BO_MAPPING.
  //   } else {
  //     let newBoConfigItem: BoConfigType[] = boNodes.map((boMap) => {
  //       return getBoConfigItemDefault(
  //         boMap.key,
  //         boMap.paramName,
  //         boMap.typeDesc
  //       );
  //     });
  //     boConfigItemsByNodes = newBoConfigItem;
  //     this.setCreateBoConfigItems(newBoConfigItem);
  //   }

  //   return boConfigItemsByNodes;
  // }

  getBoMappingFromBoConfig(boConfigItem: any) {
    return {
      paramName: boConfigItem.boName,
      pathName: boConfigItem.boName,
      margin: "0 0 0 50",
      typeDesc: boConfigItem.dataType,
      isList: false,
      parentKey: "customer_bo",
      category: "nodeIsGroup",
      isGroup: false,
      key: boConfigItem.id,
      group: "boGroup",
    };
  }
  getTableMappingFromBoConfig(boConfigItem: any) {
    return {
      paramName: boConfigItem.boName,
      pathName: boConfigItem.boName,
      margin: "0 0 0 50",
      typeDesc: boConfigItem.dataType,
      isList: false,
      parentKey: "customer_table",
      category: "nodeIsGroup",
      isGroup: false,
      key: boConfigItem.id + "_",
      group: "tableGroup",
    };
  }
  getLinkMapping(fromKey: string, toKey: string) {
    return {
      from: fromKey,
      to: toKey,
      category: "Mapping",
    };
  }
  getBoMappingByCreateBoConfigItems() {
    let boConfigItems = this.getCreateBoConfigItems();
    console.log(
      "getBoMappingByCreateBoConfigItems boConfigItems => ",
      boConfigItems
    );
    let boTableInit = BoMappingInit;
    let newBoTableMapping: any = [];
    let linkDataArray: any = [];
    let resultBoNodeMapping: any = [];
    let resultBoMapping = {
      ...boTableInit,
      nodeDataArray: [
        ...boTableInit.nodeDataArray,
        {
          paramName: "customerBo",
          pathName: "customerBo",
          margin: "0 0 0 30",
          typeDesc: "Object",
          isList: false,
          parentKey: "boKeyInit",
          category: "nodeIsGroup",
          isGroup: false,
          key: "customer_bo",
          group: "boGroup",
        },
        {
          paramName: "customerTable",
          pathName: "customerTable",
          margin: "0 0 0 30",
          typeDesc: "Object",
          isList: false,
          parentKey: "tableKeyInit",
          category: "nodeIsGroup",
          isGroup: false,
          key: "customer_table",
          group: "tableGroup",
        },
      ],
    };

    for (const item of boConfigItems) {
      let boMapping = this.getBoMappingFromBoConfig(item);
      let tableMapping = this.getTableMappingFromBoConfig(item);
      let link = this.getLinkMapping(boMapping.key, tableMapping.key);
      newBoTableMapping = [...newBoTableMapping, boMapping, tableMapping];
      linkDataArray = [...linkDataArray, link];
    }

    resultBoMapping = {
      ...resultBoMapping,
      linkDataArray,
      nodeDataArray: [...resultBoMapping.nodeDataArray, ...newBoTableMapping],
    };

    //GET NODE IN BOGROUP FOR MAPPING_PARAMS
    let nodeBoForGenTable = resultBoMapping.nodeDataArray.filter(
      (item) => item.group === "boGroup" && item.category !== "sourceInitGroup"
    );
    // console.log("nodeInBoGroup => ", nodeInBoGroup);
    // console.log("resultBoMapping => ", resultBoMapping);
    return { resultBoMapping, nodeBoForGenTable };
  }

  addCreateBoConfigItem() {
    const id = uuidv4();
    let newBoConfigItem = getBoConfigItemDefault(id);
    this.setCreateBoConfigItems([newBoConfigItem, ...this.createBoConfigItems]);
  }

  getCreateBoConfigRowSeleted() {
    return this.createBoConfigRowSeleted;
  }
  setCreateBoConfigRowSeleted(rowSeleted: string) {
    this.createBoConfigRowSeleted = rowSeleted;
  }
  removeRowByCreateBoConfigRowSeleted() {
    if (!this.createBoConfigRowSeleted) return;
    let newCreateBoConfigItems = [...this.createBoConfigItems].filter(
      (item) => item.id !== this.createBoConfigRowSeleted
    );
    this.setCreateBoConfigItems(newCreateBoConfigItems);
    this.setCreateBoConfigRowSeleted("");
  }
}
export default CreateBusinessObjectHandler;
