import React, { useEffect } from "react";
import styles from '../../database-connection/card/CardDataSource.module.scss';
import { Avatar, Card } from "antd";

type CardDataSourceProps = {
    key: number
    title: string
    logo: any
    isActive: boolean
    onClick: () => void
};

function CardDataSource({ key, title, logo,isActive,onClick   }: CardDataSourceProps) {

    useEffect(() => {
        const cardBody = document.querySelectorAll('.ant-card .ant-card-body') as NodeListOf<HTMLElement>;

        if (cardBody) {
            cardBody.forEach((cardBody) => {
                cardBody.style.padding = '16px';
            });
        }

    }, []);

    return (
        <Card key={key} className={`${styles.card} ${isActive ? styles.activeCard : ''}`} onClick={onClick}>
        <div className={styles.cardContent}>
          <Avatar src={logo} />
          <div className={styles.titleContainer}>
            <div>{title}</div>
          </div>
        </div>
      </Card>
    );
}
export default CardDataSource;