import { cssText } from "src/template/menu/cssText"
import { jsText } from "src/template/menu/jsText"

let requestMenu = {
    "refTemplate": "Menu",
    "configType": "Vertical",
    "withIcon": "Y",
    "menu": [
        {
            "seq": 1,
            "label": "home",
            "link": {
                "type": "url",
                "url": "https://oneweb.tech/home",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": 0,
                    "pageName": ""
                }
            },
            "childs": [
                {
                    "seq": 1,
                    "label": "a",
                    "link": {
                        "type": "url",
                        "url": "",
                        "story": {
                            "storyId": "",
                            "storyName": "",
                            "boName": "",
                            "lastVersion": 0,
                            "pageName": ""
                        }
                    }
                }
            ]
        },
        {
            "seq": 2,
            "label": "profile",
            "link": {
                "type": "url",
                "url": "https://oneweb.tech/features",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": 0,
                    "pageName": ""
                }
            },
            "childs": []
        },
        {
            "seq": 3,
            "label": "contacts",
            "link": {
                "type": "url",
                "url": "https://oneweb.tech/communityforum/NewSignIN1",
                "story": {
                    "storyId": "",
                    "storyName": "",
                    "boName": "",
                    "lastVersion": 0,
                    "pageName": ""
                }
            },
            "childs": []
        }
    ]
}

interface LINK {
    type: string,
    url: string,
    story: {
        storyId: string,
        storyName: string,
        boName: string,
        lastVersion: string,
        pageName: string
    }
}

interface MENU {
    seq: number,
    label: string,
    file_page_id: string,
    link: LINK
    childs: CHILD[];
}

interface CHILD {
    seq: number,
    label: string,
    file_page_id: string,
    link: LINK
}

interface JSON_MENU {
    refTemplate: string,
    configType: string,
    withIcon: string,
    dropdown: string, //Y/N
    menu: MENU[]
}

interface JSON_MENU_RES {
    htmlText: string,
    jsText: string,
    cssText: string,
    functionName: string
}

const getLiContents = (columnObj:MENU, classSubMenu:string, classMenuToggle:string): string => {
    console.log("childs:> ", columnObj);
    //@ts-ignore
    const linkPageDesigner = `${window["env"]["REACT_APP_PAGE_URL"]}img/`;

    let liContents = "";
    let pghref = "";
    let href = "";
    let subLi = "";
    let icon = columnObj.file_page_id === '' ?  `<div class="circle-icon"></div>` : columnObj.file_page_id
    let iconSub = `<div class="circle-sub-icon"></div>`
    let tooltip = ``

    columnObj.childs.sort((a, b) => a.seq - b.seq);
    if (columnObj.childs.length > 0) {
        if (columnObj.link.type === "url") {
            pghref = `href="${columnObj.link.url}" target="_blank"`;
        } else if (columnObj.link.type === "story") {
            pghref = `href="./${columnObj.link.story?.pageName}"`;
        }

        for (let a = 0; a < columnObj.childs.length; a++) {
            let child = columnObj.childs[a];
            console.log("childs:> ", child);
            if (child.link.type === "url") {
                href = `href="${child.link.url}" target="_blank"`;
            } else if (child.link.type === "story") {
                href = `href="./${child.link.story?.pageName}"`;
            }

            icon = columnObj.file_page_id === '' ? icon : `<div class="div-icon"><img alt="icon-menu" src="${linkPageDesigner}${columnObj.file_page_id}" class="icon-menu"></div>`
            iconSub = child.file_page_id === '' ? iconSub : `<img alt="icon-sub-menu" src="${linkPageDesigner}${child.file_page_id}" class="icon-sub-menu">`

            subLi += `<li> ${ iconSub } <span><a ${href}  >${child.label} </a></span></li>`
        }

        let arrowToggle = `<svg class="toggle-sub-menu" width="20px" height="20px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" d="M14.4 5.424L9 10.522l-5.395-5.1a1.59 1.59 0 0 0-2.158 0 1.386 1.386 0 0 0-.08 1.958q.038.042.08.08l6.473 6.12a1.59 1.59 0 0 0 2.16 0l6.473-6.117a1.386 1.386 0 0 0 .08-1.96q-.033-.04-.08-.078a1.59 1.59 0 0 0-2.153 0z"></path> </g></svg>`
        liContents = `
                        <li style="position: relative;" class="${classMenuToggle}">
                            <div class="d-flex align-item-center">
                                ${icon}
                                <span class="span-toggle-submenu" ><a ${pghref} class="dropdown-toggle">${columnObj.label}</a>${arrowToggle}</span>
                            </div>
                            <div class="${classSubMenu}">
                                <ul class="dropdown-menu-ul">
                                ${subLi}
                                </ul>
                            </div>
                        </li>
                    `;
    } else {
        icon = columnObj.file_page_id === '' ? icon : `<div class="div-icon"><img alt="icon-menu" src="${linkPageDesigner}${columnObj.file_page_id}" class="icon-menu"> </div>`
        tooltip = `
            <div class="tooltip-menu">
                <div class="tooltip-arrow"></div>
                <div class="tooltip-content" style="position: relative;">
                    <div class="tooltip-inner" role="tooltip"> ${columnObj.label} </div>
                </div>
            </div>
        `
        if (columnObj.link.type === "url") {
            pghref = `href="${columnObj.link.url}" target="_blank"`;
        } else if (columnObj.link.type === "story") {
            pghref = `href="./${columnObj.link.story?.pageName}"`;
        }
        liContents = `<li> ${icon} <span><a ${pghref}>${columnObj.label} </a></span> ${tooltip} </li>`;
    }
    return liContents;
}

const getMenuContainer = (jsonMenu: JSON_MENU): string => {

    let logoContainer = "";
    let classNav = "";
    let menuContainer = "";
    let classUl = ""
    let classLogo = ""
    let classSubMenu = ""
    let classMenuToggle = ""
    let li = "";
    let toggleCollap = ""
    console.log("jsonMenu", jsonMenu)

    switch (jsonMenu.configType.toLowerCase()) {
        case 'horizontal' || 'none':
            classNav = 'horizontal';
            classUl = 'horizontal-ul';
            classLogo = 'horizontal-logo';
            classSubMenu = 'dropdown-menu-horizon';
            classMenuToggle = "dropdown-horizon"
            break;
        case 'vertical':
            classNav = 'vertical';
            classUl = 'vertical-ul';
            classLogo = 'vertical-logo';
            classSubMenu = 'dropdown-menu-list';
            classMenuToggle = "dropdown-menu-toggle"
            toggleCollap = `<div class="layout-sider-trigger" >
                <span role="img" aria-label="right" class="anticon anticon-right">
                    <svg viewBox="64 64 896 896"
                        focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor"
                        aria-hidden="true">
                        <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                        </path>
                    </svg>
                </span>
            </div>`
            break;
    }

    if (jsonMenu.menu.length < 0) {
        console.error("configType with jsonMenu.menu not math")
        return "";
    }

    let menuSort = jsonMenu.menu.sort((a, b) => a.seq - b.seq);
    for (let a = 0; a < menuSort.length; a++) {
        let columnObj = menuSort[a];

        let liContents = getLiContents(columnObj, classSubMenu, classMenuToggle);
        li += liContents;
    }
    let uiContent = `<ul class="ul-menu ${classUl}">${li}</ul>`;
    menuContainer = `<nav role="navigation" class="primary-navigation nav-menu">${uiContent}</nav>`

    // let iconContainer = getIconContainer(jsonMenu);

    let navContent = `
         <div class="navbar-menu-container ${classNav}">
            ${logoContainer}
            ${toggleCollap}
            ${menuContainer}
         </div>`;

    return navContent;
}

function transformText(jsTextSource: string, datetime: string, pageName: string) {


    let jsText = jsTextSource.replace(/function (\w+)\(/g, `function $1_${pageName}_${datetime}(`);

    let jsTextTemp = jsText


    let fns = ["init"]
    let propsStatic: any = []


    let propsReplaceMore: any = [];

    for (let a = 0; a < fns.length; a++) {
        const resultArr = jsTextTemp.split(`${fns[a]}(`);
        jsTextTemp = (resultArr.join(`${fns[a]}_${pageName}_${datetime}(`));
    }

    for (let a = 0; a < propsStatic.length; a++) {
        const resultArr = jsTextTemp.split(`${propsStatic[a]}`);
        jsTextTemp = (resultArr.join(`${propsStatic[a]}_${pageName}_${datetime}`));
    }

    for (let a = 0; a < propsReplaceMore.length; a++) {
        const resultArr = jsTextTemp.split(`${propsReplaceMore[a].from}`);
        jsTextTemp = (resultArr.join(`${propsReplaceMore[a].to}`));
    }

    jsText = jsTextTemp;
    const functionName = `init_${pageName}_${datetime}`;

    return { functionName: functionName, jsTextReflector: jsText};
}

export function generatePageFileMenu(jsonMenu: JSON_MENU, pageName: string, datetime: string): any{
    let { jsTextReflector, functionName} = transformText(jsText, datetime, pageName);
    
    let textContent = "";
    if (pageName === "menu") {
        textContent = getMenuContainer(jsonMenu);
        textContent = `
        <div class="menu">
          ${textContent}
        </div>`;
    }
    let root = `<div>${textContent}</div>`;

    return {
        htmlText: textContent,//root,
        cssText: cssText,
        jsText: jsTextReflector,
        functionName: functionName
    }
}