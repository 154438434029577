import { Checkbox, CheckboxProps, Select } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./SearchResultConfigStep3.module.scss";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import { SEARCH_RESULT_SORT_BY_SELECTS } from "src/utils/story-genpage/StoryGenPageMockUp";

type Props = {
  searchResultItem: SearchResultConfigItemType;
  onRemoveItem: (id: string) => void;
};
type SearchResultConfigItemType = {
  id: string;
  boFieldName: string;
  // isSearchResult: boolean;
  sortBy: string;
};

function SearchResultConfigItem({ searchResultItem, onRemoveItem }: Props) {
  const sortBySelects = SEARCH_RESULT_SORT_BY_SELECTS;
  let [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  let [searchResultItemID, setSearchResultItemID] = useState<string>("");
  useEffect(() => {
    let sResultItemID = searchResultItem.id;
    setSearchResultItemID(sResultItemID);
  }, []);
  const onChangeIsSearchResult: CheckboxProps["onChange"] = (e) => {
    let isChecked = e.target.checked;
    console.log(`checked = ${isChecked} on itemID = ${searchResultItemID}`);
    setIsSearchResult(isChecked);
  };
  const onChangeSortByHandler = (value: string) => {
    console.log("onChangeSortByHandler value ", value);
  };
  const onClickDeleteSearchResultHandler = (id: string) => {
    console.log("onClickDeleteSearchResultHandler id :: ", id);
    onRemoveItem(id);
  };
  return (
    <tr key={searchResultItem.id}>
      <td>{searchResultItem.boFieldName}</td>
      <td>
        <Select
          value={searchResultItem.sortBy}
          defaultValue={sortBySelects[0].value}
          options={sortBySelects}
          style={{ width: "100%" }}
        //   placeholder={<div>&nbsp;&nbsp;&nbsp;Select BO Field</div>}
          onChange={onChangeSortByHandler}
          optionFilterProp="children"
          className={styles.genTableSelect}
        />
      </td>
      <td className={styles.tdSetCenter}>
        <div
          className={styles.actionIconWrapper}
          onClick={() => onClickDeleteSearchResultHandler(searchResultItem.id)}
        >
          <StoryEditEntDelete className={styles.actionIcon} />
        </div>
      </td>
    </tr>
  );
}

export default SearchResultConfigItem;
