import React, { useContext, useEffect, useState } from "react";
import StoryDrawer from "src/components/zero/zero-story/drawer/StoryDrawer";
import StoryEmptyItem from "src/components/zero/zero-story/empty-page/StoryEmptyItem";
import styles from "src/page/story/Story.module.scss";
import StoryCardProfile from "../../assets/png/story-card-profile.png";
import StoryCardCreate from "src/components/zero/zero-story/not-empty-page/StoryCardCreate";
import StoryCardItem from "src/components/zero/zero-story/not-empty-page/StoryCardItem";
import PaginationZero from "src/components/zero/zero-story/pagination/PaginationZero";
// import useSearchApp from "src/hooks/useSearchApp";
import useViewMode from "src/hooks/useViewMode";
import { CARD_MODE, LIST_MODE } from "src/constants/ViewMode";
import StoryList from "src/components/zero/zero-story/not-empty-page/StoryList";
import DashboardZeroContent from "../component/DashboardZeroContent";
import { callApiMethodGet, callApiMethodPost } from "src/services/util.service";
import PlusSmallColorIcon from "src/assets/svg/plus-small-color";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";
import { useLocation } from "react-router-dom";
import {
  GET_STORY_BY_ASPID_ENDPOINT,
  CREATE_STORY_COMPONENT_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { AppContext } from "src/page/context/App.context";

type Props = {};
type StoryItemProp = {
  id: string;
  story_name: string;
  userProfile: string;
  created_by: string;
  created_at: string;
};

function Story({}: Props) {
  const appContext: any = useContext(AppContext);
  const location = useLocation();
  const [sortOrder, setSortOrder] = useState(location.search);
  let [selectedFilter, setSelectedFilter] = useState(appContext.selectedFilter);
  let [searchApp, setSearchApp] = useState(appContext.searchApp);

  useEffect(() => {
    APIGetStoryByAspID();
  }, []);

  useEffect(() => {
    if (appContext.selectedFilter !== selectedFilter) {
      setSelectedFilter(appContext.selectedFilter);
      APIGetStoryByAspID();
    }

    if (appContext.searchApp !== searchApp) {
      setSearchApp(appContext.searchApp);
      APIGetStoryByAspID();
    }

    if (location.search !== sortOrder) {
      setSortOrder(location.search);
      APIGetStoryByAspID();
    }
  }, [appContext, selectedFilter, searchApp, location, sortOrder]);

  const asp_id = localStorage.getItem("asp_id");
  let [openDrawerCreate, setOpenDrawerCreate] = useState<boolean>(false);
  let [isSearch, setIsSearch] = useState<boolean>(false);

  let newDataStoryItems: StoryItemProp[] = [];
  let [storyItems, setStoryItems] = useState<StoryItemProp[]>(
    newDataStoryItems.length > 0 ? newDataStoryItems : []
  );
  let [viewMode, setViewMode] = useViewMode();

  const APIGetStoryByAspID = async () => {
    let whereDBType = "";
    let whereOwner = "";
    if (appContext.selectedFilter?.search("DBType_") == 0) {
      whereDBType = appContext.selectedFilter?.split("_")[1];
    } else if (appContext.selectedFilter?.search("Owner_") == 0) {
      whereOwner = appContext.selectedFilter?.split("_")[1];
    }

    try {
      const response = await callApiMethodGet(
        GET_STORY_BY_ASPID_ENDPOINT + sortOrder,
        {
          asp_id: asp_id,
          db_type: whereDBType,
          owner: whereOwner,
          search: searchApp,
        }
      );

      appContext.setCountStory(response.data.storyComponent.length);
      setStoryItems(Object.assign([], response.data.storyComponent));
    } catch (error) {
      appContext.setCountStory(0);
      setStoryItems([]);
    }
  };

  const APIPost = async (storyName: string) => {
    try {
      const response = await callApiMethodPost(
        CREATE_STORY_COMPONENT_ENDPOINT,
        {
          asp_id: asp_id,
          story_name: storyName,
        }
      );

      if (response.status == 200) {
        await APIGetStoryByAspID();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //ON CHANGE VIEW MODE
  useEffect(() => {
    console.log("Story viewmode : ", viewMode);
  }, [viewMode]);

  const onClickCreateStory = () => {
    setOpenDrawerCreate(true);
  };
  const onCloseDrawerCreate = () => {
    setOpenDrawerCreate(false);
  };

  const onCreateStory = (newStoryName: string) => {
    APIPost(newStoryName);
    setOpenDrawerCreate(false);
  };

  const onClickCreate = () => {
    console.log("onClickCreate");
    setOpenDrawerCreate(true);
  };

  const content = (
    <div className={styles.storyContainer}>
      <div className={styles.headerTitle}>
        <div className={styles.title}>
          My Story {`(${storyItems?.length || 0})`}
        </div>
        {viewMode === LIST_MODE && storyItems?.length > 0 && (
          <div style={{ cursor: "pointer" }} onClick={() => onClickCreate()}>
            <PlusSmallColorIcon width={30} height={30} />
          </div>
        )}
      </div>
      {storyItems.length > 0 ? (
        // CARD VIEW
        viewMode === CARD_MODE ? (
          <div className={styles.storyWrapper}>
            {/* {!isSearch && currentPage === 1 && ( */}
            <StoryCardCreate onClickCreate={onClickCreateStory} />
            {/* )} */}
            {storyItems
              // .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((item) => {
                return (
                  <StoryCardItem
                    id={item.id}
                    key={item.id}
                    storyName={item.story_name}
                    userProfile={StoryCardProfile}
                    owner={item.created_by}
                    date={formatISODateToCustomFormat(item.created_at)}
                  />
                );
              })}
          </div>
        ) : (
          // CARD VIEW
          // <StoryList storyItemList={storyItems} searchText={searchApp} />
          <StoryList
            storyItemList={storyItems}
            RefaceApi={APIGetStoryByAspID}
          />
        )
      ) : (
        // STORY ITEMS EMPTY
        !isSearch && (
          <div className={styles.storyEmptyWrapper}>
            <StoryEmptyItem
              onClickCreate={onClickCreateStory}
              txtName="Design your story here"
            />
          </div>
        )
      )}
      {/* PAGINATION */}
      {/* {storyItems.length > 0 && viewMode !== LIST_MODE && (
        // <PaginationZero
        //   total={storyItems.length}
        //   onPageChange={handlePageChange}
        // />
        <></>
      )} */}
      {/* CREATE STORY DRAWER */}
      <StoryDrawer
        isOpen={openDrawerCreate}
        onClose={onCloseDrawerCreate}
        onCreate={onCreateStory}
      />
    </div>
  );
  return (
    <>
      <div style={{ height: "100%" }}>
        <DashboardZeroContent content={content} />
      </div>
    </>
  );
}

export default Story;
