import React from "react";

const MessageIcon = (props) => {
    const { className, style, width, height, fill } = props;
    return (<svg
        width={width || "16"}
        height={height || "16"}
        viewBox="0 0 16 16"
        style={{color: "#f9f9f9"}}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8604_15639)">
            <path d="M16.0008 7.49838C15.9043 5.93839 15.3535 4.44072 14.4162 3.18999C13.4789 1.93927 12.1961 0.990171 10.7259 0.459706C9.25571 -0.0707594 7.66242 -0.159402 6.14246 0.204706C4.6225 0.568814 3.24231 1.36976 2.17205 2.50879C1.10179 3.64782 0.388246 5.07515 0.11938 6.61481C-0.149485 8.15448 0.0380834 9.73917 0.658963 11.1735C1.27984 12.6079 2.30689 13.8292 3.61349 14.6869C4.92009 15.5446 6.44912 16.0012 8.01209 16.0004H12.6674C13.5512 15.9995 14.3985 15.648 15.0235 15.0231C15.6484 14.3982 15.9999 13.5508 16.0008 12.667V7.49838ZM14.6674 12.667C14.6674 13.1975 14.4567 13.7062 14.0816 14.0813C13.7066 14.4563 13.1979 14.667 12.6674 14.667H8.01209C7.07137 14.6666 6.14131 14.4679 5.28255 14.0839C4.42379 13.6999 3.6556 13.1392 3.02809 12.4384C2.39754 11.7379 1.92404 10.9107 1.63935 10.0122C1.35466 9.11378 1.26535 8.16484 1.37742 7.22904C1.55438 5.753 2.21755 4.37783 3.26238 3.32033C4.30722 2.26282 5.67429 1.58312 7.14809 1.38838C7.43558 1.35234 7.72501 1.33409 8.01475 1.33371C9.5684 1.32947 11.074 1.87231 12.2674 2.86704C12.9644 3.44634 13.5366 4.16114 13.9491 4.96813C14.3617 5.77511 14.606 6.65747 14.6674 7.56171V12.667Z" fill={fill||"#60605F"} />
            <path d="M5.33464 6.00032H8.0013C8.17811 6.00032 8.34768 5.93009 8.47271 5.80506C8.59773 5.68004 8.66797 5.51047 8.66797 5.33366C8.66797 5.15685 8.59773 4.98728 8.47271 4.86225C8.34768 4.73723 8.17811 4.66699 8.0013 4.66699H5.33464C5.15782 4.66699 4.98826 4.73723 4.86323 4.86225C4.73821 4.98728 4.66797 5.15685 4.66797 5.33366C4.66797 5.51047 4.73821 5.68004 4.86323 5.80506C4.98826 5.93009 5.15782 6.00032 5.33464 6.00032Z" fill={fill||"#60605F"} />
            <path d="M10.668 7.33301H5.33464C5.15782 7.33301 4.98826 7.40325 4.86323 7.52827C4.73821 7.6533 4.66797 7.82287 4.66797 7.99968C4.66797 8.17649 4.73821 8.34606 4.86323 8.47109C4.98826 8.59611 5.15782 8.66635 5.33464 8.66635H10.668C10.8448 8.66635 11.0143 8.59611 11.1394 8.47109C11.2644 8.34606 11.3346 8.17649 11.3346 7.99968C11.3346 7.82287 11.2644 7.6533 11.1394 7.52827C11.0143 7.40325 10.8448 7.33301 10.668 7.33301Z" fill={fill||"#60605F"} />
            <path d="M10.668 10H5.33464C5.15782 10 4.98826 10.0702 4.86323 10.1953C4.73821 10.3203 4.66797 10.4899 4.66797 10.6667C4.66797 10.8435 4.73821 11.0131 4.86323 11.1381C4.98826 11.2631 5.15782 11.3333 5.33464 11.3333H10.668C10.8448 11.3333 11.0143 11.2631 11.1394 11.1381C11.2644 11.0131 11.3346 10.8435 11.3346 10.6667C11.3346 10.4899 11.2644 10.3203 11.1394 10.1953C11.0143 10.0702 10.8448 10 10.668 10Z" fill={fill||"#60605F"} />
        </g>
        <defs>
            <clipPath id="clip0_8604_15639">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>);
};


export default MessageIcon;