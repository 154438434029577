import React from "react";

const StoryGenPageAlignRight = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3.5C20 2.94771 20.4477 2.5 21 2.5C21.5523 2.5 22 2.94772 22 3.5V20.5C22 21.0523 21.5523 21.5 21 21.5C20.4477 21.5 20 21.0523 20 20.5V3.5Z"
        fill="#393939"
      />
      <path
        d="M10.5 17.5C9.67157 17.5 9 16.8284 9 16C9 15.1716 9.67157 14.5 10.5 14.5H17.5C18.3284 14.5 19 15.1716 19 16C19 16.8284 18.3284 17.5 17.5 17.5H10.5Z"
        fill="#393939"
      />
      <path
        d="M3.5 9.5C2.67157 9.5 2 8.82843 2 8C2 7.17157 2.67157 6.5 3.5 6.5H17.5C18.3284 6.5 19 7.17157 19 8C19 8.82843 18.3284 9.5 17.5 9.5H3.5Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageAlignRight;
