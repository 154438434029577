import React, { useEffect, useState } from "react";
import styles from "./StoryCard.module.scss";
import StoryCardImg from "../../../../assets/png/story-card-img.png";
import StoryCardIcon from "src/assets/svg/story-card-icon";
import { useNavigate } from "react-router";
import { pathRoutes } from "src/page/props/util.props";

type Props = {
  id: string;
  storyName: string;
  userProfile: string;
  owner: string;
  date: string;
};

function StoryCardItem({
  id,
  storyName,
  userProfile,
  owner,
  date,
}: Props) {
  let navigate = useNavigate();
  const handlerStoryCardItemClick = () => {
    navigate(`/${pathRoutes.MainDashboard}/story/` + id, {
      state: { id, storyName, userProfile, owner, date },
    });
  };

  return (
    <div
      className={styles.storyCardItemContainer}
      onClick={handlerStoryCardItemClick}
    >
      <div className={styles.imgWrapper}>
        <img
          src={StoryCardImg}
          alt="story-card-img"
          className={styles.img}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={styles.detailWrapper}>
        <h4 className={styles.title}>{storyName}</h4>
        <div className={styles.detail}>
          <div className={styles.detailLeft}>
            <div className={styles.imgWrapper}>
              <img
                className={styles.profileImg}
                src={userProfile}
                alt="profile"
              />
            </div>
            <div className={styles.textWrapper}>
              <h5 className={styles.textEmail}>{owner}</h5>
              <p className={styles.textUploadDate}>{date}</p>
            </div>
          </div>
          <div className={styles.detailRight}>
            <div className={styles.expandWrapper}>
              <StoryCardIcon width={"20px"} height={"20px"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoryCardItem;
