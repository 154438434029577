export default function renderTablescreenElement(screenElement, screen, mainScreen, maxColumn, label, JsonCONFIGSCREEN, pageName) {

    console.log("dataTable screen", screen);
    let gridColumn = `1 / span ${maxColumn}`;
    screenElement.style.zIndex = 9;
    screenElement.style.gridColumn = gridColumn;
    // สร้าง Search Form
    const searchForm = createSearchForm();
    screenElement.appendChild(searchForm);

    //สร้าง content button
    const contentButton = document.createElement('div');
    contentButton.className = 'content-button';

    //สร้าง clear button
    let clearBtnText = `<button id="clear-cell-${pageName}-${mainScreen.key}-data-button" class="btn ant-btn">Clear</button>`;
    contentButton.insertAdjacentHTML('beforeend', clearBtnText);

    //สร้าง delete button
    let deleteBtnText = `<button id="delete-cell-${pageName}-${mainScreen.key}-data-button" class="btn ant-btn ant-btn-primary ant-btn-dangerous" disabled>Delete</button>`;
    //สร้าง save button
    let saveBtnText = `<button id="save-cell-${pageName}-${mainScreen.key}-data-button" class="btn ant-btn ant-btn-primary" style="display:none">Save</button>`;
    if (label === 'edit') {
        contentButton.insertAdjacentHTML('beforeend', deleteBtnText);
        contentButton.insertAdjacentHTML('beforeend', saveBtnText);
    }

    // สร้าง Add button
    let addBtnText = `<button id="add-cell-${pageName}-${mainScreen.key}-data-button" class="ant-btn ant-btn-primary">
                           <span>Add</span>   
                   </button>`
    contentButton.insertAdjacentHTML('beforeend', addBtnText);

    screenElement.appendChild(contentButton);

    //สร้าง fields เพิ่มข้อมูล
    createFields(screenElement, screen, mainScreen, label, pageName);

    // สร้างตาราง
    createTable(screenElement, screen, mainScreen, label, JsonCONFIGSCREEN);

    // สร้าง Save button
    // const contentSaveButton = document.createElement('div');
    // contentSaveButton.id = 'box-content-table-save-button-' + screen.key;
    // contentSaveButton.className = 'content-save-button';

    // let saveBtnText = `<button id="save-button-${label}-page-${screen.configType}-${screen.key}" class="ant-btn ant-btn-primary">
    //                         <span>save</span>
    //                     </button>`;
    // contentSaveButton.insertAdjacentHTML('beforeend', saveBtnText);
    // screenElement.appendChild(contentSaveButton);
};


// สร้าง form search
function createSearchForm() {
    const searchForm = document.createElement('form');
    searchForm.className = 'search-form';

    const searchInput = document.createElement('input');
    searchInput.type = 'text';
    searchInput.placeholder = 'Search...';
    searchInput.className = 'search-input dt-search';
    searchInput.id = "search-input-childs"
    searchInput.setAttribute("pg-state", "State.search");

    const searchIcon = document.createElement('i');
    searchIcon.className = 'anticon anticon-search';
    searchIcon.style.position = 'absolute';
    searchIcon.style.marginLeft = '5px';
    searchIcon.style.color = 'rgba(171, 171, 171, 1)';

    const clearIcon = document.createElement('i');
    clearIcon.className = 'fas fa-times clear-icon';
    clearIcon.style.display = 'none';

    const searchButton = document.createElement('button');
    searchButton.setAttribute('type', 'button');
    searchButton.textContent = 'Search';
    searchButton.className = 'ant-btn ant-btn-primary search-button';
    searchButton.id = "search-input-childs-btn"

    searchForm.appendChild(searchInput);
    searchForm.appendChild(searchIcon);
    searchForm.appendChild(clearIcon);
    searchForm.appendChild(searchButton);

    return searchForm;
};


function createTable(screenElement, screen, mainScreen, label, JsonCONFIGSCREEN) {
    // สร้างตาราง
    const tableElement = document.createElement('table');
    tableElement.id = `dataTable-${label}-${screen.configType}-${screen.key}-${mainScreen.key}`;
    tableElement.className = 'table table-bordered';
    tableElement.style.marginTop = '25px';
    tableElement.style.width = '100%';

    const tableHead = document.createElement('thead');
    const tableHeadRow = document.createElement('tr');

    // เพิ่มคอลัมน์ Checkbox All
    const checkboxAllTh = document.createElement('th');
    const checkboxAll = document.createElement('input');
    checkboxAll.type = 'checkbox';
    checkboxAll.id = 'checkbox-all-childs';

    checkboxAllTh.appendChild(checkboxAll);
    tableHeadRow.appendChild(checkboxAllTh);

    // Sort the columns based on the seq property
    screen.configColums.sort((a, b) => a.seq - b.seq);

    console.log("columns==> ", screen.configColums);
    // สร้างคอลัมน์ตามข้อมูลที่กำหนดใน table.columns
    screen.configColums.forEach(column => {
        console.log("columns==>:::  ", column);
        const columnTh = document.createElement('th');
        columnTh.textContent = column.columnName; // ใช้ชื่อคอลัมน์เป็นหัวข้อ
        tableHeadRow.appendChild(columnTh);
    });

    // Add one more column (e.g., "additional_column")
    // const additionalColumnTh = document.createElement('th');
    // additionalColumnTh.textContent = "";
    // tableHeadRow.appendChild(additionalColumnTh);

    tableHead.appendChild(tableHeadRow);
    tableElement.appendChild(tableHead);

    // สร้างส่วนเนื้อหาของตาราง (tbody)
    const tableBody = document.createElement('tbody');
    tableBody.id = `tableBody-childs-${mainScreen.key}`;
    // const tableBodyRow = document.createElement('tr');

    // const newRow = tableBody.insertRow();
    // screen.configColums.forEach(column => {
    //     const newCell = newRow.insertCell();
    //     const inputElement = document.createElement('input');
    //     inputElement.type = 'text';
    //     inputElement.placeholder = column.columnName;
    //     newCell.appendChild(inputElement);
    // });

    // Add a cell for the Clear button
    // const actionCell = newRow.insertCell();

    // const clearButton = document.createElement('button');
    // clearButton.id = 'clear-cell-' + label + '-data-button';
    // clearButton.textContent = 'Clear';
    // clearButton.className = 'btn ant-btn';

    // Append the Clear button to the action cell
    // actionCell.appendChild(clearButton);

    // tableBody.appendChild(tableBodyRow);

    let tdTemps = ""
    screen.configColums.forEach(column => {
        let tdTemp = `
        <td id="column-child-${column.columnName}"
            pg-store="${column.columnName}"  
            pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${mainScreen.key}.${screen.key}_list"
            >${column.columnName}</td>`;

        tdTemps = tdTemps + tdTemp;
    });
    let tdCheckbox = `<td><input type="checkbox" id="checkbox-child-${mainScreen.key}-{{$index}}" class="row-checkbox"></td>`
    let trRemp = `<tr id='row-child-${mainScreen.key}-{{$index}}' pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;]" pg-trackby="$index"
        pg-store="${JsonCONFIGSCREEN.flow.flowName}_flow_output.${mainScreen.key}.${screen.key}_list" pg-is-list="true">${ label === 'edit' ? tdCheckbox: ''}${tdTemps}</tr>`;

    tableBody.insertAdjacentHTML('beforeend', trRemp);
    tableElement.appendChild(tableBody);

    // เพิ่มตารางลงใน screenElement
    screenElement.appendChild(tableElement);
    document.body.appendChild(screenElement);
};

function createFields(screenElement, screen, mainScreen, label, pageName) {
    const newRowContainer = document.createElement('div');
    newRowContainer.id = `new-row-container-${pageName}-${mainScreen.key}`;
    newRowContainer.className = 'new-row';
    const columnNames = screen.configColums.sort((a, b) => a.seq - b.seq);

    columnNames.forEach(columnName => {
        console.log("columnName <>", columnName);
        const inputElement = document.createElement('input');
        inputElement.id = columnName.columnName + '-childs-' + mainScreen.key;
        inputElement.type = 'text';
        inputElement.placeholder = columnName.columnName;
        newRowContainer.appendChild(inputElement);
    });
    screenElement.appendChild(newRowContainer);
}