import React, { useEffect, useState } from "react";
import { notification } from "antd";
import StoryEditEntCreated from "src/assets/svg/story-edit-ent-created";
import styles from "./CreateEntitySuccessNotify.module.scss";

type Props = {
  isUpdated: string;
};

function UpdateEntitySuccessNotify({ isUpdated }: Props) {
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (isUpdated !== "") {
        api.open({
            message: (
            <div className={styles.messageNotify}>Update Successfully!</div>
            ),
            description: (
            <div className={styles.descripNotify}>
                Business Object has been updated successfully.
            </div>
            ),
            icon: (
            <div className={styles.iconNotify}>
                <StoryEditEntCreated className={styles.iconSuccessNotify} />
            </div>
            ),
            duration: 2,
        });
        }
    });

  return <>{contextHolder}</>;
}

export default UpdateEntitySuccessNotify;
