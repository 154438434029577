import React, { useContext, useEffect, useState } from "react";
import styles from "./ControlTable.module.scss";
import {
  BoFieldForDataProps,
  StoryGeneratePageContext,
} from "src/page/context/StoryGeneratePage.context";
import { getPositionToolInMainPanel } from "src/utils/story-genpage/StoryGeneratePageUtility";
import {
  getComponentContainerIdByMainRowId,
  getSubCellIdByMainRowIdAndColId,
  getSubFieldIdByMainRowIdAndColId,
} from "../../../utils/controls.util";
import SetupTableForComponentTableModal from "src/components/zero/zero-story/modal/modal-entity-genpage/setup-table-for-component-table/SetupTableForComponentTableModal";
import { v4 as uuidv4 } from "uuid";
import BoFieldTool from "../../BoFieldTool";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import CellTool from "../../CellTool";
import { BO } from "src/constants/ComponentType";

type ColumnItemType = {
  id: string;
  colId: string;
  boIdRef?: string;
};

type Props = {
  colNum: number;
  boFieldItems: any;
  mainRowId?: string;
  onUpdateItems: (tableItems: any, rowId: string) => void;
};

export default function ControlTable({
  colNum,
  boFieldItems,
  mainRowId,
  onUpdateItems,
}: Props) {
  const DEFAULT_COLUMN_NUMBER: number = 2;

  let genPageContext = useContext(StoryGeneratePageContext);
  let [tableColItems, setTableColItems] = useState<ColumnItemType[]>([]);

  //COLUMN_NUMBER
  let [columnNumber, setColumnNumber] = useState<number>(2);

  //BO COMPONENT STATE
  let [isOpenDeleteBoComp, setIsOpenDeleteBoComp] = useState<boolean>(false);

  //CELL COMPONENT STATE
  let [isOpenDeleteCellComp, setIsOpenDeleteCellComp] =
    useState<boolean>(false);

  //CONFIRM DELETE MODAL STATE
  let [itemIdToDelete, setItemIdToDelete] = useState<string>("");

  //DRAG STATE
  let [dragOverItem, setDragOverItem] = useState<string>("");

  //TOOL STATE
  let [isCellToolOpen, setIsCellToolOpen] = useState<boolean>(false);
  let [isBoFieldToolOpen, setIsBoFieldToolOpen] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();

  //SETUP TABLE STATE
  let [isOpenSelectTableModal, setIsOpenSelectTableModal] =
    useState<boolean>(false);
  let [selectTableList, setSelectTableList] = useState<
    { value: string; label: string }[]
  >([]);
  let [mappingParentChild, setMappingParenetChild] = useState<any[]>([]);
  let [parentSelected, setParentSelected] = useState<string>("");
  let [isFirstload, setIsFirstload] = useState<boolean>(false);
  
  let [clickedRowId, setClickedRowId] = useState<string>("");

  useEffect(() => {
    let selects = genPageContext.selectComponentTable;
    let mapping = genPageContext.mappingParentChildComponentTable;
    // console.log("selects => ", selects);
    // console.log("mapping => ", mapping);
    setSelectTableList(selects);
    setMappingParenetChild(mapping);
  }, []);

  useEffect(() => {
    let subComponent = genPageContext.versionSubComponent
    // console.log("load subComponent => ", subComponent)
    subComponent.forEach((item: any) => {
      let confType = item.configType ? item.configType : item.detail.configType      
      if (confType === "TABLE") {
        // console.log("item.parentSelected =>+> ",item.parentSelected )
        if(item.parentSelected && item.parentSelected !== ''){
          // console.log("item.parentSelected =>+> ",item.parentSelected )
          setParentSelected(item.parentSelected)
        }
      }
    });
    setIsFirstload(true)
  },[genPageContext.isVersionGenerated])

  //CHECK CONTEXT PARENT
  useEffect(() => {
    console.log("CONTROL CONTEXT HAS CHANGE => ", genPageContext.conTrolconfigs)
  },[genPageContext.conTrolconfigs])

  //CHECK FOCUS ON PARENT
  useEffect(() => {
    let itemFocus = genPageContext.itemFocusOnConfigPage;
    console.log("itemFocusOnConfigPage => ", itemFocus);

    if (!itemFocus || itemFocus.includes("main")) {
      closeAllTool();
      return;
    }

    let { rowId: focusRowId, cellId: focusCellId } = getCellIdFromFocusItem();
    //DIV FOR GET_POSITION NOT NULL AND CHECK ROW SELECTED
    if (mainRowId !== focusRowId) {
      closeAllTool();
      return;
    }

    let cellFocusEle = document.getElementById(focusCellId); //GET CELL POSITION BY ELEMENT_ID
    let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID

    if (cellFocusEle && outerDivEle) {
      let { top, left } = getPositionToolInMainPanel(cellFocusEle, outerDivEle);
      console.log(`top: ${top}, left: ${left}`);

      if (itemFocus.includes("field")) {
        //{sub-r3_field-r1-c1}
        setIsBoFieldToolOpen(true);
        setIsCellToolOpen(false);
      } else if (itemFocus.includes("cell")) {
        //{sub-r3_cell-r1-c1}
        setIsBoFieldToolOpen(false);
        setIsCellToolOpen(true);
      }

      setPositionTool({ left, top });
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //CHECK ON NUMBER OF ROW AND COLUMN CHANGE
  useEffect(() => {
    console.log(`col: ${colNum}`);
    if (!colNum || colNum === DEFAULT_COLUMN_NUMBER) return;

    setColumnNumber((prev) => prev + 1);
  }, [colNum]);

  useEffect(() => {
    if (columnNumber) {
      let newTableColItems = getTableItemsEmptyByColNum(columnNumber);
      let mergedTableColItems =
        getMergeTableColItemsWithNewTableColItems(newTableColItems);
      setTableColItems(mergedTableColItems);
    }

    closeAllTool();
  }, [columnNumber]);

  //CHECK ONCHANGE TABLE
  useEffect(() => {
    if (parentSelected && !isFirstload) {
      let newTableColItems = [...tableColItems];
      newTableColItems = newTableColItems.map((item: any) => {
        return {
          ...item,
          boIdRef: "",
        };
      });
      setTableColItems(newTableColItems);
    }else if(isFirstload && parentSelected){  //SET TABLE FROM VERSION SAVED
      let subComponent = genPageContext.versionSubComponent
      subComponent.forEach((item: any) => {
        let confType = item.configType ? item.configType : item.detail.configType      
        if (confType === "TABLE") {
          // sub-r1_cell-r1-c1
          item.componentConfig.forEach((col:any) => {
            // console.log("parentSelected => ", parentSelected)
            let celId = `sub-${item.rowId}_cell-${col.colId}`
            onAddChildInCellByBofieldItem(celId, col.boIdRef);
          });
        }
      });
      setIsFirstload(false)
    }
  }, [parentSelected]);

  //DRAG DROP HANDLER
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let cellId = e.currentTarget.id;
    console.log("handleDrop in cellId : ", cellId);
    setDragOverItem("");

    let dropItemDetailStr = e.dataTransfer.getData("text/plain");
    console.log("Item dropped dropItemDetailStr ", dropItemDetailStr);
    let dropItemDetailObj: any =
      getDropItemObjFromDataTransfer(dropItemDetailStr);
    console.log("Item dropped dropItemDetailObj ", dropItemDetailObj);
    let { componentId, componentType } = dropItemDetailObj;

    if (componentType === BO) {
      onAddChildInCellByBofieldItem(cellId, componentId);
    }
  };

  const getColIdFromCellId = (cellId: string) => {
    let cellPart = cellId.split("_");
    let cellPosition = cellPart[1].split("-");
    return cellPosition[1] + "-" + cellPosition[2];
  };

  const getDropItemObjFromDataTransfer = (dropItemDetailStr: string) => {
    let dropItemDetailObj = JSON.parse(dropItemDetailStr);
    return dropItemDetailObj;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragOver id : ", id);
    setDragOverItem(id);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragLeave id : ", id);
    setDragOverItem("");
  };

  const getTableContainerId = () => {
    return getComponentContainerIdByMainRowId(mainRowId as string);
  };

  const onClickConfigTable = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const parentElement = e.currentTarget.parentElement?.parentElement;
    let clickRowId = parentElement?.id
    let RowId = clickRowId?.split("-")[1]
    console.log("Grandparent element: ", RowId);
    setClickedRowId(RowId as string)
    openSetupTable();
  };

  //SETUP TABLE MODAL HANDLER
  const openSetupTable = () => {
    setIsOpenSelectTableModal(true);
  };

  const onOkSetupTable = () => {
    setIsOpenSelectTableModal(false);
  };

  const onCancelSetupTable = () => {
    setIsOpenSelectTableModal(false);
  };

  const onSelectTable = (parentName: string) => {
    setParentSelected(parentName);
    setIsOpenSelectTableModal(false);
    
    // SET CONTEXT FOR SAVE VERSION
    let controlConfigs = genPageContext.conTrolconfigs.filter((item:any) => item.configType === 'TABLE')
    controlConfigs = controlConfigs.map((item:any) => {
      return {
        ...item,
        parentSelected: parentName
      }
    })
    let controlConfigsContext = genPageContext.conTrolconfigs.filter((item:any) => item.configType !== 'TABLE')
    let newControlConfigs = controlConfigsContext.concat(controlConfigs)
    genPageContext.setConTrolconfigs(newControlConfigs)

    let selectParentObj = {
      rowId: clickedRowId,
      parentSelected: parentName
    }

    let selectedContext = genPageContext.parentSelectedComponentTable.filter((item:any) => item.rowId !== clickedRowId)
    genPageContext.setParentSelectedComponentTable([...selectedContext, selectParentObj])

  };

  //UTILITY
  const getSubCellIdByColId = (colId: string) => {
    return getSubCellIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getSubFieldIdByColId = (colId: string) => {
    return getSubFieldIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getCellIdFromFocusItem = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let rowDetail = focusItem.split("_")[0];
    let rowId = rowDetail.split("-")[1];
    if (focusItem.includes("field")) {
      return { rowId, cellId: focusItem.replace("field", "cell") };
    } else {
      return { rowId, cellId: focusItem };
    }
  };

  const getColIdFromFocusItemId = (focusItem: string) => {
    let cellPosition = focusItem.split("_")[1];
    let cellPositionSplit = cellPosition.split("-");
    let idForRemove = cellPositionSplit[1] + "-" + cellPositionSplit[2]; //r1-c1;
    return idForRemove;
  };

  const sortColIdFromNewTableColItems = (newTableColItems: any) => {
    let newSortTableColItems = newTableColItems.map(
      (item: any, index: number) => {
        return {
          ...item,
          colId: "r1-c" + (index + 1),
        };
      }
    );
    return newSortTableColItems;
  };

  const closeAllTool = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
  };

  //CELL DATA MANAGEMENT
  const getMergeTableColItemsWithNewTableColItems = (newTableColItems: any) => {
    let oldTableItems = structuredClone(tableColItems);
    let newUpdateColItems = structuredClone(newTableColItems);

    let oldTableItemsMap = new Map();
    for (const item of oldTableItems) {
      oldTableItemsMap.set(item.colId, item);
    }

    for (const newItem of newUpdateColItems) {
      let oldColObj = oldTableItemsMap.get(newItem.colId);
      if (oldColObj) {
        Object.assign(newItem, { ...newItem, boIdRef: oldColObj.boIdRef });
      }
    }

    return newUpdateColItems;
  };

  const getTableItemsEmptyByColNum = (colNum: number) => {
    let result: ColumnItemType[] = [];
    for (let index = 0; index < colNum; index++) {
      const id = uuidv4();
      let colId = `r1-c${index + 1}`;
      let tableItem: ColumnItemType = {
        id,
        colId,
        boIdRef: "",
      };
      result.push(tableItem);
    }
    return result;
  };

  const onRemoveFieldInCell = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let idForRemove = getColIdFromFocusItemId(focusItem);

    //GET NEW ROW AFTER REMOVE CHILD
    let newTableColItems = removeFieldInCell(idForRemove);
    console.log(
      "onOkConfirmDeleteHandler newTableColItems => ",
      newTableColItems
    );
    setTableColItems(newTableColItems);

    genPageContext.setItemFocusOnConfigPage("");
    setIsBoFieldToolOpen(false);
  };

  const onRemoveCell = () => {
    //CHECK NOT LESS THAN DEFAULT_COL_NUM
    if (columnNumber === DEFAULT_COLUMN_NUMBER) return;

    let focusItem = genPageContext.itemFocusOnConfigPage;
    let idForRemove = getColIdFromFocusItemId(focusItem);

    let newTableColItems = tableColItems.filter(
      (item: any) => item?.colId !== idForRemove
    );

    let newSort = sortColIdFromNewTableColItems(newTableColItems);
    setTableColItems(newSort);
    setColumnNumber((prev) => prev - 1);

    genPageContext.setItemFocusOnConfigPage("");
    setIsCellToolOpen(false);
  };

  const removeFieldInCell = (colId: string) => {
    let newTableItems = tableColItems.map((item: any) => {
      if (item?.colId === colId) {
        return {
          ...item,
          boIdRef: "",
        };
      } else {
        return item;
      }
    });

    return newTableItems;
  };

  const updateChildInCellWithBoFieldItem = (
    cellId: string,
    boIdRef: string
  ) => {
    let colId = getColIdFromCellId(cellId);
    // console.log("updateChildInCellWithBoFieldItem colId =>> ", colId)
    // console.log("parentSelected -------", parentSelected)
    if (!parentSelected) return;
    let isExistInBoSelected = isParentOfBoParentSelected(boIdRef);
    if (!isExistInBoSelected) return;

    let newTableColItems = [...tableColItems];
    let col = newTableColItems.find((item: any) => item.colId === colId);
    if (!col) return newTableColItems;
    Object.assign(col, { ...col, boIdRef });
    return newTableColItems;
  };

  const onAddChildInCellByBofieldItem = (
    cellId: string,
    componentId: string
  ) => {
    let newTableColItems = updateChildInCellWithBoFieldItem(
      cellId,
      componentId
    );
    console.log(
      "onAddChildInCellByBofieldItem newTableColItems => ",
      newTableColItems
    );
    if (!newTableColItems) return;
    onUpdateItems(newTableColItems, mainRowId as string);
    setTableColItems(newTableColItems as ColumnItemType[]);
  };

  const isParentOfBoParentSelected = (boIdRef: string) => {
    // CHECK BO_FIELD FROM BO_ID_REF
    let boFieldDataContext = genPageContext.boFieldForData;
    let { rootPath } = boFieldDataContext.find(
      (item: any) => item?.key === boIdRef
    ) as BoFieldForDataProps;

    if (!rootPath) return false;

    // CHECK PARENT OF THIS BO_FIELD
    let parentBoItems = mappingParentChild.find(
      (item: any) => item?.paramName === parentSelected
    );
    // console.log(
    //   "updateChildInCellWithBoFieldItem parentBoItems => ",
    //   parentBoItems
    // );
    if (!parentBoItems) return false;

    // RETURN IF NOT SELECT IN TABLE SELECT
    let isParentOfParentSelected = parentBoItems?.childs.some(
      (item: any) => item.pathName === rootPath
    );
    // console.log(
    //   "updateChildInCellWithBoFieldItem isParentOfParentSelected => ",
    //   isParentOfParentSelected
    // );

    return isParentOfParentSelected;
  };

  const onClickCell = (e: React.MouseEvent<HTMLDivElement>, colId: string) => {
    e.stopPropagation();
    console.log("onClickCell in component colID => ", colId);
    genPageContext.setItemFocusOnConfigPage(colId);
  };

  const getBoFieldFromBoIdRef = (boIdRef: string) => {
    if (!boIdRef || boFieldItems?.length === 0) return;
    let componentItem = boFieldItems.find((item: any) => item.id === boIdRef);
    return componentItem?.boField;
  };

  //MODAL DELETE BO FIELD CONFIRMATION
  const onClickRemoveChildHandler = () => {
    setIsOpenDeleteBoComp(true);
  };

  const onOkDeleteBoComponentHandler = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    console.log("onOkConfirmDeleteHandler focusBoItem => ", focusBoItem);

    if (focusBoItem.includes("field")) {
      onRemoveFieldInCell();
    }

    setIsOpenDeleteBoComp(false);
  };

  const onCancelDeleteBoComponentHandler = () => {
    setIsOpenDeleteBoComp(false);
  };

  //MODAL DELETE CELL CONFIRMATION
  const onClickDeleteCellHandler = () => {
    setIsOpenDeleteCellComp(true);
  };

  const onOkDeleteCellComponentHandler = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    console.log("onOkDeleteCellComponentHandler focusBoItem => ", focusBoItem);

    if (focusBoItem.includes("cell")) {
      onRemoveCell();
    }

    setIsOpenDeleteCellComp(false);
  };

  const onCancelDeleteCellComponentHandler = () => {
    setIsOpenDeleteCellComp(false);
  };

  return (
    <>
      <div id={getTableContainerId()} className={styles.controlTableContainer}>
        <div className={styles.controlTableHeader}>
          <h5 style={{ textAlign: "center", margin: 0, fontSize: "14px" }}>
            {parentSelected
              ? "Table : " + parentSelected
              : "Please select table"}
          </h5>
          <button
            className={styles.controlTableButton}
            onClick={onClickConfigTable}
          >
            Table
          </button>
        </div>
        <div className={styles.controlTableBody}>
          <div className={styles.controlTableRow}>
            {tableColItems.map((col: any) => {
              return (
                <div
                  key={col.id}
                  id={getSubCellIdByColId(col.colId)}
                  className={[
                    dragOverItem === getSubCellIdByColId(col.colId)
                      ? styles.controlTableColDragOver
                      : styles.controlTableCol,
                    genPageContext.itemFocusOnConfigPage ===
                    getSubCellIdByColId(col.colId)
                      ? styles.controlTableColFocus
                      : "",
                  ].join(" ")}
                  onClick={(e) =>
                    onClickCell(e, getSubCellIdByColId(col.colId))
                  }
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={(e) => handleDragLeave(e)}
                >
                  {col?.boIdRef && (
                    <div
                      className={
                        genPageContext.itemFocusOnConfigPage ===
                        col.colId + "_field"
                          ? styles.insideTableColFocus
                          : styles.insideTableCol
                      }
                      onClick={(e) =>
                        onClickCell(e, getSubFieldIdByColId(col.colId))
                      }
                    >
                      {getBoFieldFromBoIdRef(col?.boIdRef as string)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* TOOL */}
      <BoFieldTool
        isShow={isBoFieldToolOpen}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onClick={onClickRemoveChildHandler}
      />

      <CellTool
        isShow={isCellToolOpen}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onClickDelete={onClickDeleteCellHandler}
      />

      {/* ADD TAB  MODAL*/}
      <SetupTableForComponentTableModal
        isModalOpen={isOpenSelectTableModal}
        onOk={onOkSetupTable}
        onCancel={onCancelSetupTable}
        onSelectParent={onSelectTable}
        parentList={selectTableList}
      />

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete BO Field?",
          description: `Are you sure you want to delete this BO Field? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteBoComp}
        onOk={onOkDeleteBoComponentHandler}
        onCancel={onCancelDeleteBoComponentHandler}
      />

      {/* CONFIRM DELETE CELL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Cell?",
          description: `Are you sure you want to delete this cell? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteCellComp}
        onOk={onOkDeleteCellComponentHandler}
        onCancel={onCancelDeleteCellComponentHandler}
      />
    </>
  );
}
