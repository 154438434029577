import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./StoryEntityGenPageModal.module.scss";
import ChooseTemplateStep1 from "../../story-entity-genpage/step1-choose-template/ChooseTemplateStep1";
import SearchFilterConfigStep2 from "../../story-entity-genpage/step2-search-filter-config/SearchFilterConfigStep2";
import SearchResultConfigStep3 from "../../story-entity-genpage/step3-search-result-config/SearchResultConfigStep3";
import CreateEditPageConfigStep4 from "../../story-entity-genpage/step4-create-edit-page-config/CreateEditPageConfigStep4";

type Props = {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  onCreate: (boName: string) => void;
};
type TitleProps = { title: string; stepNum: number; total: number };

function StoryEntityGenPageModal({
  isModalOpen,
  onOk,
  onCancel,
  onCreate,
}: Props) {
  
  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  let [current, setCurrent] = useState(0);
  const steps = [
    {
      title: "Choose Template",
      content: <></>, //<ChooseTemplateStep1/>,
    },
    {
      title: "Search Filter Config",
      content: <></>, //<SearchFilterConfigStep2/>,
    },
    {
      title: "Search Result Config",
      content: <></>, //<SearchResultConfigStep3/>,
    },
    {
      title: "Create and Edit Page Config",
      content: <></>, //<CreateEditPageConfigStep4/>,
    },
  ];
  const TitleContent = ({ title, stepNum, total }: TitleProps) => {
    return (
      <div className={styles.createTitleModal}>
        <div className={styles.title}>
          <span>{title}&nbsp;&nbsp;</span>
          <span style={{ color: "#1c87f2" }}>{stepNum}</span>
          <span style={{ color: "#ababab" }}>{"/" + total}</span>
        </div>
      </div>
    );
  };
  const prev = () => {
    if (current === 0) return;
    setCurrent((prev) => prev - 1);
  };
  const onClickBackHandler = () => {
    console.log("onClickBackHandler");
    prev();
  };
  const next = () => {
    console.log("On next step number => ", current);
    if (current === steps.length - 1) return;
    setCurrent((prev) => prev + 1);
  };
  const onClickNextHandler = () => {
    console.log("onClickNextHandler");
    next();
  };
  const onClickContinueHandler = () => {
    console.log("onClickContinueHandler");
  };
  return (
    <>
      <Modal
        title={
          <TitleContent
            title={steps[current].title}
            stepNum={current + 1}
            total={steps.length}
          />
        }
        footer={
          <div className={styles.createFooterModal}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {current > 0 && (
              <Button
                className={styles.cancelBtn}
                key="back"
                onClick={onClickBackHandler}
              >
                Back
              </Button>
            )}

            {current < steps.length - 1 && (
              <Button
                className={styles.confirmBtn}
                key="next"
                onClick={onClickNextHandler}
                type="primary"
              >
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <Button
                className={styles.confirmBtn}
                key="continue"
                onClick={onClickContinueHandler}
                type="primary"
              >
                Continue
              </Button>
            )}
          </div>
        }
        open={isModalOpen}
        // onOk={onOkModal}
        onCancel={onCancel}
        centered={true}
        width={"50%"}
      >
        <span className={styles.createContentModal}>
          {steps[current].content}
        </span>
      </Modal>
    </>
  );
}

export default StoryEntityGenPageModal;
