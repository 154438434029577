import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { PlanResponse, Product } from "src/types/PlanProduct.type";
import { getAuthorizationPmtx } from "src/services/util.service";

// สร้าง async action สำหรับดึงข้อมูลจาก API
//@ts-ignore
let api = window["env"]["REACT_APP_ZERO_URL"];
export const fetchPlanProducts = createAsyncThunk<PlanResponse>(
  "products/fetchProducts",
  async () => {
    const response = await axios.get(api + "/pmtx-api/v1/products", {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }); // ใส่ endpoint ของ API ที่ต้องการดึงข้อมูล
    return response.data;
  }
);

type PlansState = {
  plans?: PlanResponse;
  currPlan?: Product;
  billing_cycle: string;
  loading: boolean;
  error?: string;
};

const initialValues: PlansState = {
  billing_cycle: "one_time",
  loading: false,
};

const plansSlice = createSlice({
  name: "plans",
  initialState: initialValues,
  reducers: {
    setCurrentPlan: (state, action) => {
      state.currPlan = action.payload;
    },
    setBillingCycle: (state, action) => {
      state.billing_cycle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlanProducts.fulfilled, (state, action) => {
        // กรณรี products เรียงจาก Premium, Advance, Basic
        let newProducts = action.payload;
        newProducts.response = action.payload?.response?.reverse();
        state.plans = newProducts;

        // // กรณรี products เรียงจาก Basic, Advance, Premium
        // state.plans = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlanProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to load plans";
      });
  },
});

export const { setCurrentPlan, setBillingCycle } = plansSlice.actions;
export const plansSelector = (store: RootState) => store.plansReducer;
export default plansSlice.reducer;
