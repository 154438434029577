//@ts-ignore
export const BASE_URL_AI = `${window["env"]["REACT_APP_GENERATE_URL"]}`;
//@ts-ignore
export const BASE_URL_GENERATE = `${window['env']['REACT_APP_ZERO_URL']}/pmtx-api/v1`;

export const getUpdateResultByStepTypeEndpoint = (stepEnum: number, id: string) => {
  return `${BASE_URL_AI}/result/${stepEnum}/${id}`;
};

export const GET_USER_PRODUCT_PLAN_ENDPOINT = `${BASE_URL_GENERATE}/user-product-plan`;
export const USER_GENERATE_APP_ENDPOINT = `${BASE_URL_GENERATE}/user-generate-app`;
export const GET_USER_GENERATE_APP_BY_APP_ID_ENDPOINT = `${BASE_URL_GENERATE}/user-generate-app/find`;
