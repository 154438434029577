import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import styles from '../modal/ModalFail.module.scss';
import DatabaseErrorIcon from "src/assets/svg/database-error-circle";

type ModalFailProps = {
    open: boolean;
    onClose: () => void,
    name:string
    title:string
    description:string
    isType?:string
};


function ModalFail({ open,onClose,name,title,description,isType }: ModalFailProps) {
    useEffect(() => {
        if(open) {
          window.parent.postMessage({event: "zero_open_modal", key: ""}, "*");
        } else {
          window.parent.postMessage({event: "zero_close_modal", key: ""}, "*");
        }
      }, [open])

    return (
        <Modal
            open={open}
            width={400}
            footer={[
                <Button key="cancel" onClick={onClose} style={{ width: 'calc(50% - 5px)', marginRight: '5px' }}>
                  Cancel
                </Button>,
                <Button key="ok" type="primary" danger onClick={onClose} style={{ width: 'calc(50% - 5px)', marginLeft: '5px' }}>
                  {isType? 'Confirm': 'OK'}
                </Button>
              ]}
              onCancel={onClose}
        >
            <div className={styles.modalContainer}>
            <DatabaseErrorIcon/>
                <div className={styles.titleText}>
                   {name} {title} 
                </div>
                <div className={styles.descriptionText}>
                    {description}
                </div>
            </div>
        </Modal>
    );
}
export default ModalFail;