import React from "react";

const StoryEditEntGenPageBreadcrumpDot = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 18 19"
      width={width || "18"}
      height={height || "19"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9.5" r="9" fill="#1C87F2" />
    </svg>
  );
};
export default StoryEditEntGenPageBreadcrumpDot;
