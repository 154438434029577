import {
  ColumnItems,
  Flow,
  GeneratePageConfigScreenRequest,
  Screen,
} from "src/types/request.type";
import {
  CENTER_DOMAIN,
  CENTER_PASSWORD,
  CENTER_USER,
  FILE_HUB_URL_IMAGE,
  MF_URL,
} from "./config/screen.config";
import { GetEntityBoItem } from "src/types/response.type";
import { DATA_LIST, FROM_TABLE } from "src/constants/DataReferType";
import {
  IMAGE_INPUT,
  LIST,
  LIST_CARD,
  LIST_TABLE,
  LIST_TILE,
  TEXT,
} from "src/constants/InputType";
import { UPLOAD_LIBS } from "./config/libs.config";
import {
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  NORMAL,
  ONE_TO_MANY,
  ONE_TO_ONE,
  TAB,
  TABLE,
} from "src/constants/PageConfig";
import { BoFieldForDataProps } from "src/page/context/StoryGeneratePage.context";

export const getToolPosition = (
  focuEelement: HTMLElement,
  outerDivEle: HTMLElement
) => {
  let innerRect = focuEelement.getBoundingClientRect();
  let outerRect = outerDivEle.getBoundingClientRect();
  let relativeTop = innerRect.top - outerRect.top;
  let relativeLeft = innerRect.left - outerRect.left;

  let aboveOfCell = relativeTop - 10;
  let centerOfCell = relativeLeft + innerRect.width / 2;

  return {
    top: Math.round(aboveOfCell),
    left: Math.round(centerOfCell),
  };
};

export const getPositionToolInMainPanel = (
  elementToGetPosition: HTMLElement,
  elementWrapper: HTMLElement
) => {
  let mainElement = document.getElementById("gridPanel");
  let innerRect = elementToGetPosition.getBoundingClientRect();
  let outerRect = elementWrapper.getBoundingClientRect();
  let mainRect = (mainElement as HTMLElement).getBoundingClientRect();

  let addPositionByMain = outerRect.top - mainRect.top;
  let relativeTop = innerRect.top - outerRect.top + addPositionByMain;
  let relativeLeft = innerRect.left - outerRect.left;

  let aboveOfCell = relativeTop - 10;
  let alignCenterOfCell = relativeLeft + innerRect.width / 2;

  return {
    top: Math.round(aboveOfCell),
    left: Math.round(alignCenterOfCell),
  };
};

//GENERATE PAGE SCREEN CONFIG REQUREST
type Connection = {
  host_name: string;
  host_port: string;
  db_name: string;
  db_username: string;
  db_password: string;
  db_type: string;
};
export const getDatabaseConnection = (connection: Connection) => {
  let {
    host_name: hostName,
    host_port: port,
    db_name: dbName,
    db_username: username,
    db_password: password,
    db_type: dbType,
  } = connection;
  if (!hostName || !port || !dbName || !username || !password || !dbType)
    return "";

  let pathConnection = "";
  switch (dbType) {
    case "postgresql":
      pathConnection = `postgresql://${username}:${password}@${hostName}:${port}/${dbName}`;
      break;
    case "mysql":
      pathConnection = `mysql://${username}:${password}@${hostName}:${port}/${dbName}`;
      break;
    case "oracle":
      pathConnection = `oracle:thin:@${username}:${password}@${hostName}:${port}:${dbName}`;
      break;
    case "db2":
      pathConnection = `db2://${username}:${password}@${hostName}:${port}/${dbName}`;
      break;
    case "tibero":
      pathConnection = `tibero:thin:@${username}:${password}@${hostName}:${port}:${dbName}`;
      break;
    default:
      pathConnection = `postgresql://${username}:${password}@${hostName}:${port}/${dbName}`;
      break;
  }
  return pathConnection;
};
export const getGeneratePageConfigScreenRequest = (
  genMfResponse: Flow,
  chooseTemplate: any,
  boFieldsSearchFilterSelected: any,
  boFieldsConfig: any,
  pageScreenConfig: any,
  boConfigItems: GetEntityBoItem[],
  jsonBoMapping: any,
  allBoFieldsInSelects: any,
  updateComponents: any,
  mappingParentChildComponent?: any,
  bofieldsData?: BoFieldForDataProps[]
): GeneratePageConfigScreenRequest => {
  console.log(
    "getGeneratePageConfigScreenRequest chooseTemplate => ",
    chooseTemplate
  );

  let filehubUrlImage = getFileHubUrlImage();
  let microFlowUrl = getMicroflowUrl();
  let configType = chooseTemplate?.list_template_id;
  let screens: Screen[] = getScreenFromBoFieldData(
    configType,
    boFieldsSearchFilterSelected,
    jsonBoMapping,
    boConfigItems,
    allBoFieldsInSelects
  );
  console.log("getGeneratePageConfigScreenRequest screens => ", screens);

  let screensEdit = getScreensEditFromPageConfig(
    pageScreenConfig,
    boFieldsConfig,
    boConfigItems,
    updateComponents,
    mappingParentChildComponent,
    bofieldsData
  );
  console.log(
    "getGeneratePageConfigScreenRequest screensEdit => ",
    screensEdit
  );

  //REMOVE NULL ITEMS
  screensEdit = screensEdit.filter((item: any) => item);

  //GET REF_TEMPLATE BY SCREEN_EDIT
  let refTemplate = getRefTemplateByScreenEdit(screensEdit);
  //GET DATA FROM PANEL ITEMS ON SCREEN CONFIG
  // console.log(
  //   "getGeneratePageConfigScreenRequest screensEdit => ",
  //   screensEdit
  // );
  let centerUser = getCenterUser();
  let centerPassword = getCenterPassword();
  let centerDomain = getCenterDomain();
  let result: GeneratePageConfigScreenRequest = {
    refTemplate,
    flow: genMfResponse,
    config: {
      filehubUrlImage,
      microFlowUrl,
      centerUser,
      centerPassword,
      centerDomain,
    },
    screens,
    screensEdit,
  };

  return result;
};

const getScreensEditFromPageConfig = (
  pageScreenConfig: any,
  boFieldsConfig: any,
  boConfigItems: GetEntityBoItem[],
  updateComponents: any,
  mappingParentChildComponent?: any,
  bofieldsData?: BoFieldForDataProps[]
) => {
  // console.log(
  //   "getScreensEditFromPageConfig pageScreenConfig => ",
  //   updateComponents
  // );
  let screenEdits: any = [];
  let cellWithFieldItems = getOnlyCellWithFieldItem(pageScreenConfig);
  console.log(
    "getScreensEditFromPageConfig cellWithFieldItems => ",
    cellWithFieldItems
  );

  let rowWithComponentItem = getOnlyRowWithComponentItem(pageScreenConfig);
  // console.log(
  //   "getScreensEditFromPageConfig rowWithComponentItem => ",
  //   rowWithComponentItem
  // );

  console.log(
    "getScreensEditFromPageConfig updateComponents => ",
    updateComponents
  );

  //FIELD COMPONENT
  for (const fieldItem of cellWithFieldItems) {
    let screenEditObj = getScreenEditItem(
      fieldItem,
      boFieldsConfig,
      boConfigItems,
      updateComponents
    );
    screenEdits.push(screenEditObj);
  }

  //ROW COMPONENT
  for (const rowItem of rowWithComponentItem) {
    let screenEditObj = getScreenEditComponentRowItem(
      rowItem,
      boFieldsConfig,
      boConfigItems,
      updateComponents,
      mappingParentChildComponent,
      bofieldsData
    );
    screenEdits.push(screenEditObj);
  }

  return screenEdits;
};

const getScreenEditComponentRowItem = (
  rowItem: any,
  boItemsConfig: any,
  boConfigItems: GetEntityBoItem[],
  updateComponents: any,
  mappingParentChildComponent?: any,
  bofieldsData?: BoFieldForDataProps[]
) => {
  let screenEditObj: any;
  let rownumber = parseInt(rowItem?.rowId.split("")[1]);
  let componentRowConfig = updateComponents?.find(
    (item: any) => item?.rowId === rowItem?.rowId
  );
  let componentName = String(componentRowConfig?.configType).toLowerCase();
  // console.log("getScreenEditComponentRowItem steps => ", steps);
  console.log(
    "getScreenEditComponentRowItem componentRowConfig => ",
    componentRowConfig
  );

  screenEditObj = {
    key: componentName,
    label: componentName,
    configType: componentRowConfig?.configType, //BREADCRUMP, TABLE, TAB
    rownumber,
    columnnumber: 1,
    valuePath: "",
  };

  //CHECK CONFIG_TYPE FOR CREATE GENERATE_PAGE REQUEST
  if (componentRowConfig?.configType === BREADCRUMP) {
    let steps = getStepFromComponentRowItem(componentRowConfig, boItemsConfig);
    screenEditObj = {
      ...screenEditObj,
      steps,
    };
  } else if (componentRowConfig?.configType === TAB) {
    let tabs = getStepFromComponentRowItem(componentRowConfig, boItemsConfig);
    screenEditObj = {
      ...screenEditObj,
      tabs,
    };
  } else if (componentRowConfig?.configType === TABLE) {
    let { parentName, pathName } = getParentNameFromMappingParentChild(
      componentRowConfig,
      bofieldsData,
      mappingParentChildComponent
    );
    // console.log("getScreenEditComponentRowItem parentName => ", parentName);
    let configColums = getColumnsFromComponentRowItem(
      componentRowConfig,
      bofieldsData,
      mappingParentChildComponent
    );
    // console.log("getScreenEditComponentRowItem configColums => ", configColums);
    screenEditObj = {
      ...screenEditObj,
      key: parentName,
      valuePath: pathName,
      label: parentName.toLowerCase(),
      configColums,
    };
  }

  return screenEditObj;
};

const getColumnsFromComponentRowItem = (
  componentRowConfig: any,
  bofieldsData: any,
  mappingParentChildComponent?: any
) => {
  let result: any = [];
  let colConfigs = componentRowConfig?.componentConfig;
  //CREATE REQUEST COLUMNS FROM COMPONENT_ROW_CONFIGS

  for (const indexCol in colConfigs) {
    let pathName = getPathNameFromBoId(
      colConfigs[indexCol]?.boIdRef,
      bofieldsData
    );
    let { columnName, valuePath } = getColumnNameFromMappingParentChild(
      pathName,
      mappingParentChildComponent
    );
    let colObj = {
      seq: parseInt(indexCol) + 1,
      columnName,
      valuePath,
    };
    result.push(colObj);
  }

  return result;
};

const getStepFromComponentRowItem = (
  componentRowConfig: any,
  boItemsConfig: any
) => {
  // console.log(
  //   "getStepFromComponentRowItem componentRowConfig => ",
  //   componentRowConfig
  // );
  // console.log("getStepFromComponentRowItem boItemsConfig => ", boItemsConfig);

  let result: any = [];
  let rowConfigs = componentRowConfig?.componentConfig;

  //CREATE REQUEST TABS FROM COMPONENT_ROW_CONFIGS
  for (const indexRowCon in rowConfigs) {
    let tabRows = rowConfigs[indexRowCon]?.componentRowItems;
    console.log("getStepFromComponentRowItem tabRows => ", tabRows);
    let tabScreens: any = [];

    for (const tabRow of tabRows) {
      let tabScreen = getTabScreenFromTabRowConfig(
        tabRow,
        boItemsConfig,
        componentRowConfig
      );
      // console.log("getStepFromComponentRowItem tabScreen => ", tabScreen);
      tabScreens.push(...tabScreen);
    }

    let stepObj = {
      seq: parseInt(indexRowCon) + 1,
      tabName: rowConfigs[indexRowCon]?.name,
      tabScreens,
    };
    result.push(stepObj);
  }
  return result;
};

const getTabScreenFromTabRowConfig = (
  tabRowConfig: any,
  boItemsConfig: any,
  componentRowConfig: any
) => {
  let tabScreens: any = [];
  let cols = tabRowConfig?.cols;

  // console.log("getTabScreenFromTabRowConfig cols => ", cols);
  // console.log(
  //   "getTabScreenFromTabRowConfig componentRowConfig => ",
  //   componentRowConfig
  // );

  for (const col of cols) {
    let tabScreen: any = {};

    let { rownumber, columnnumber } = getRowNumAndColNumFromColIdInTabConfig(
      col?.colId
    );

    //BO
    if (col?.childRef) {
      let { fieldName, valuePath } = getBoFieldDetailByBoId(
        col?.childRef,
        boItemsConfig
      );

      let configType = "BO";
      tabScreen = {
        key: fieldName,
        label: fieldName,
        configType,
        rownumber,
        columnnumber,
        valuePath,
      };
      tabScreens.push(tabScreen);

      //FIELD COMPONENT BUTTON, ICON
    } else if (col?.isControlItem) {
      //CHECK TYPE OF COMPONENT FIELD
      let configs = col?.configs;
      let tabScreen = getTabScreenByConfigs(configs, rownumber, columnnumber);
      tabScreens.push(tabScreen);
    }
  }

  return tabScreens;
};

const getTabScreenByConfigs = (
  configs: any,
  rownumber: number,
  columnnumber: number
) => {
  let configType = configs?.configType;
  let tabScreen: any = {
    configType,
    rownumber,
    columnnumber,
    valuePath: "",
  };
  switch (configType) {
    case BUTTON:
      let { title } = configs;
      tabScreen = {
        ...tabScreen,
        key: title,
        label: title,
      };
      break;
    case LABEL:
      let { text } = configs;
      tabScreen = {
        ...tabScreen,
        key: "mock key text",
        label: text,
      };
      break;
    case IMAGE:
      let { imagePath } = configs;
      tabScreen = {
        ...tabScreen,
        key: "mock key image",
        imagePath,
      };
      break;
    case ICON:
      let { iconPath } = configs;
      tabScreen = {
        ...tabScreen,
        key: "mock key icon",
        iconPath,
      };
      break;
    default:
      tabScreen = {};
      break;
  }
  return tabScreen;
};

const getParentNameFromMappingParentChild = (
  componentRowConfig: any,
  bofieldsData: any,
  mappingParentChildComponent?: any
): { pathName: string; parentName: string } => {
  let colConfigs = componentRowConfig?.componentConfig;
  if (colConfigs?.length === 0) return { pathName: "", parentName: "" };

  let boId = colConfigs[0]?.boIdRef;
  let pathName = getPathNameFromBoId(boId, bofieldsData);

  for (const mapping of mappingParentChildComponent) {
    let childs = mapping?.childs;
    let isParentOfChild = childs.find(
      (item: any) => item?.pathName === pathName
    );
    if (isParentOfChild)
      return { pathName: mapping?.pathName, parentName: mapping?.paramName };
  }

  return { pathName: "", parentName: "" };
};

const getPathNameFromBoId = (boId: string, boItemsConfig: any) => {
  let boItem = boItemsConfig.find((item: any) => item?.key === boId);
  return boItem?.rootPath;
};

const getColumnNameFromMappingParentChild = (
  pathName: string,
  mappingParentChildComp: any
) => {
  for (const mapping of mappingParentChildComp) {
    let childs = mapping?.childs;
    for (const child of childs) {
      if (child?.pathName === pathName) {
        return { columnName: child?.paramName, valuePath: child?.pathName };
      }
    }
  }

  return {
    columnName: "",
    valuePath: "",
  };
};

const getRowNumAndColNumFromColIdInTabConfig = (colId: string) => {
  let posStr = colId.split("-");
  let rowStr = posStr[0].split("")[1];
  let colStr = posStr[1].split("")[1];

  return {
    rownumber: parseInt(rowStr),
    columnnumber: parseInt(colStr),
  };
};

const getScreenEditItem = (
  fieldItem: any,
  boItemsConfig: any,
  boConfigItems: GetEntityBoItem[],
  updateComponents: any
) => {
  // console.log("getScreenEditItem fieldItem => ", fieldItem);
  // console.log("getScreenEditItem boItemsConfig => ", boItemsConfig);
  // console.log("getScreenEditItem boConfigItems => ", boConfigItems);
  // console.log("getScreenEditItem updateComponents => ", updateComponents);

  let screenEditObj: any;
  //IS BO_FILED
  if (fieldItem?.childIdRef) {
    let { fieldName, valuePath } = getBoFieldDetailByBoId(
      fieldItem?.childIdRef,
      boItemsConfig
    );
    let { columnNumber, rowNumber } = getRowAndColNumberByCellId(
      fieldItem?.colId
    );

    //add detail from boConfigItems
    let boConfigObj = boConfigItems.find((item) => item?.bo_name === fieldName);
    // console.log("getScreenEditItem boConfigObj => ", boConfigObj)
    let boConfig = {};
    if (boConfigObj) {
      boConfig = {
        inputType: boConfigObj?.input_type,
        displayView: boConfigObj?.display_view,
        displayEdit: boConfigObj?.display_edit,
        hidden: String(boConfigObj?.show_status) === "true" ? "Y" : "N",
        dataRefer: getDataRefer(boConfigObj?.data_refer),
      };
    }

    //CREATE JSON FROM PAGE_SCREEN_CONFIG AND BO_CONFIG
    screenEditObj = {
      key: fieldName,
      label: fieldName,
      configType: "BO",
      rownumber: rowNumber,
      columnnumber: columnNumber,
      valuePath,
      ...boConfig,
    };

    //IS CONTROL_ITEM
  } else if (!fieldItem?.childIdRef && fieldItem?.isControlItem) {
    //FIX CODE BUTTON
    let { columnNumber, rowNumber } = getRowAndColNumberByCellId(
      fieldItem?.colId
    );
    let configType = getConfigTypeByColId(updateComponents, fieldItem?.colId);

    switch (configType) {
      case BUTTON:
        let title = getButtonTitleByColId(updateComponents, fieldItem?.colId);
        let buttonType = getButtonTypeByColId(
          updateComponents,
          fieldItem?.colId
        );
        console.log(
          `getScreenEditItem title: ${title}, buttonType: ${buttonType}`
        );

        screenEditObj = {
          key: title,
          label: title,
          configType: BUTTON,
          rownumber: rowNumber,
          columnnumber: columnNumber,
          buttonType,
        };
        break;

      case LABEL:
        let labelText = getLabelTextByColId(updateComponents, fieldItem?.colId);
        let labelType = getLabelTypeByColId(updateComponents, fieldItem?.colId);
        console.log(
          `getScreenEditItem labelText: ${labelText}, labelType: ${labelType}`
        );

        screenEditObj = {
          key: labelText,
          label: labelText,
          configType: LABEL,
          rownumber: rowNumber,
          columnnumber: columnNumber,
          labelType,
        };
        break;

      case ICON:
        let iconPath = getIconPathByColId(updateComponents, fieldItem?.colId);
        console.log(`getScreenEditItem iconPath: ${iconPath}`);

        screenEditObj = {
          configType: ICON,
          rownumber: rowNumber,
          columnnumber: columnNumber,
          iconPath,
          key: "icon",
        };
        break;

      case IMAGE:
        let imagePath = getImagePathByColId(updateComponents, fieldItem?.colId);
        console.log(`getScreenEditItem imagePath: ${imagePath}`);

        screenEditObj = {
          configType: IMAGE,
          rownumber: rowNumber,
          columnnumber: columnNumber,
          imagePath,
          key: "image",
        };
        break;
    }
  }
  return screenEditObj;
};

export const getFormDataWithFilesForGenPageUpload =
  async (): Promise<FormData> => {
    let allFileNameAndPath = UPLOAD_LIBS;

    let formData = new FormData();
    for (const fileDetail of allFileNameAndPath) {
      const response = await fetch(fileDetail.filePath);
      const fileBlob = await response.blob();
      const file = new File([fileBlob], fileDetail.fileName, {
        type: fileBlob.type,
      });
      formData.append("file", file);
    }

    return formData;
  };

const getConfigTypeByColId = (updateComponents: any, colId: string): string => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (!updateObj) return "";
  return updateObj?.detail?.configType;
};

const getButtonTitleByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (updateObj) {
    return updateObj?.detail?.title;
  } else {
    return "Button";
  }
};

const getButtonTypeByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (updateObj) {
    return updateObj?.detail?.buttonType;
  } else {
    return "primary";
  }
};

const getLabelTextByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (updateObj) {
    return updateObj?.detail?.text;
  } else {
    return "Label";
  }
};

const getLabelTypeByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (updateObj) {
    return updateObj?.detail?.labelType;
  } else {
    return NORMAL;
  }
};

const getIconPathByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (!updateObj) return "";
  return updateObj?.detail?.iconPath;
};

const getImagePathByColId = (updateComponents: any, colId: string) => {
  let updateObj = updateComponents.find((item: any) => item?.colId === colId);
  if (!updateObj) return "";
  return updateObj?.detail?.imagePath;
};

const getDataRefer = (dataRefer: any) => {
  if (!dataRefer) return null;

  if (dataRefer?.type === FROM_TABLE) {
    let dataConnect = dataRefer?.dataconnect;
    return {
      // dataReferId: dataRefer?.data_refer_id,
      // dataReferName: dataRefer?.data_refer_name,
      type: "table",
      datas: null,
      dataconnect: {
        connection: getDatabaseConnection(dataConnect?.connection),
        tableName: dataConnect?.table_name,
        columnShow: dataConnect?.column_show,
        columnValue: dataConnect?.column_value,
        condition: dataConnect?.condition,
        advanceSearch: dataConnect?.advance_search,
      },
    };
  } else if (dataRefer?.type === DATA_LIST) {
    let datas = dataRefer?.datas;
    datas = datas.map((item: any) => {
      return {
        key: item.key,
        value: item.value,
        isDefault: item.is_default === "true" ? "Y" : "N",
      };
    });
    return {
      // dataReferId: dataRefer?.data_refer_id,
      // dataReferName: dataRefer?.data_refer_name,
      type: "list",
      datas,
      dataconnect: null,
    };
  }
};

const getBoFieldDetailByBoId = (
  boId: string,
  boItemsConfig: any
): { fieldName: string; valuePath: string } => {
  let boItem = boItemsConfig.find((item: any) => item.id === boId);

  let boField = boItem?.boField;
  let fieldName = boField?.split(".")[1];
  let result = {
    fieldName,
    valuePath: boField,
  };
  return result;
};
const getRowAndColNumberByCellId = (
  cellId: string
): { columnNumber: number; rowNumber: number } => {
  //main_cell-r3-c1
  let cellPosition = cellId.split("_")[1];
  let cellPosSplit = cellPosition.split("-");
  let rowStr = cellPosSplit[1].split("")[1];
  let colStr = cellPosSplit[2].split("")[1];
  return {
    columnNumber: parseInt(colStr),
    rowNumber: parseInt(rowStr),
  };
};

const getOnlyRowWithComponentItem = (pageScreenConfig: any) => {
  let result = [];
  for (const row of pageScreenConfig) {
    if (row?.isControlItem) {
      result.push(row);
    }
  }
  return result;
};

const getOnlyCellWithFieldItem = (pageScreenConfig: any) => {
  let result = [];
  for (const row of pageScreenConfig) {
    let cols = row?.cols;
    for (const col of cols) {
      if (col?.childIdRef || col?.isControlItem) {
        result.push(col);
      }
    }
  }
  return result;
};

const getScreenFromBoFieldData = (
  configType: string,
  boFieldsSearchFilterSelected: any,
  jsonBoMapping: any,
  boConfigItems: GetEntityBoItem[],
  allBoFieldsInSelects: any
): Screen[] => {
  // console.log("getScreenFromBoFieldData boConfigItems => ", boConfigItems);
  let bo = allBoFieldsInSelects[0]?.value; //mainBo.id
  if (!bo) return [];

  let nameOfMainBo = bo.split(".")[0]; //mainBo
  // console.log("getScreenFromBoFieldData boFieldsSearchFilterSelected => ", boFieldsSearchFilterSelected);

  let result: Screen = {
    key: nameOfMainBo,
    label: nameOfMainBo,
    configType, //LIST_TILE,LIST_CARD,LIST_TABLE,LIST
    rownumber: 1,
    columnnumber: 1,
    valuePath: nameOfMainBo,
  };

  //GET COLUMN_ITEMS
  let columnItems = getColumnItemsByConfigType(
    configType,
    boFieldsSearchFilterSelected,
    boConfigItems,
    allBoFieldsInSelects
  );
  console.log("getScreenFromBoFieldData columnItems => ", columnItems);

  switch (configType) {
    case LIST_CARD || LIST || LIST_TILE:
      result = {
        ...result,
        card: columnItems,
      };
      break;
    case LIST_TABLE:
      result = {
        ...result,
        configColums: columnItems,
      };
      break;
    default:
      result = {
        ...result,
        card: columnItems,
      };
      break;
  }

  return [result];
};

const getInputTypeFromBoConfigItemsByBoName = (
  boConfigItems: GetEntityBoItem[],
  boName: string
) => {
  let boObj = boConfigItems.find((item: any) => item.bo_name === boName);
  if (!boObj) return TEXT;

  return boObj?.input_type;
};

export const getBoFieldByGenerateBoConfig = (generateBoConfig: any) => {
  let boFieldSelected = generateBoConfig.filter(
    (item: any) => item.bo_config_type === "SEARCH_RESULT"
  );
  return boFieldSelected.map((item: any) => {
    return {
      value: item?.enyity_bo_item_id,
      label: item?.enyity_bo_item_id,
    };
  });
};

const getMappingBoNameAndInputType = (
  boFieldsSearchFilterSelected: any,
  boConfigItems: GetEntityBoItem[]
) => {
  let mappingBoNameAndInputType = [];
  for (const field of boFieldsSearchFilterSelected) {
    let valuePath = field.value;
    let boName = valuePath.split(".")[1];
    let boObj = boConfigItems.find((item: any) => item.bo_name === boName);

    let boNameAndInputTypeObj;
    if (boObj) {
      boNameAndInputTypeObj = {
        boName,
        inputType: boObj?.input_type,
      };
    } else {
      boNameAndInputTypeObj = {
        boName,
        inputType: TEXT,
      };
    }

    mappingBoNameAndInputType.push(boNameAndInputTypeObj);
  }
  return mappingBoNameAndInputType;
};

const getColumnItemsByConfigType = (
  configType: string,
  boFieldsSearchFilterSelected: any,
  boConfigItems: GetEntityBoItem[],
  allBoFieldsInSelects: any
) => {
  let result: ColumnItems[] = [];

  let mappingBoNameAndInputType = getMappingBoNameAndInputType(
    allBoFieldsInSelects,
    boConfigItems
  );
  console.log(
    "getCardsFromBoFields mappingBoNameAndInputType => ",
    mappingBoNameAndInputType
  );
  let inputTypeImage = mappingBoNameAndInputType.find(
    (item: any) => item.inputType === IMAGE_INPUT
  );

  //ADD DEFAULT FIRST COLUMN IN COLUMN_ITEMS
  let firstSeqObj: ColumnItems;
  if (inputTypeImage) {
    let boNameFirstSeq = inputTypeImage?.boName;
    let boFieldFirstSeq = allBoFieldsInSelects.find((item: any) =>
      item.value.includes(boNameFirstSeq)
    );
    firstSeqObj = {
      seq: "1",
      valuePath: boFieldFirstSeq?.value,
      key: boNameFirstSeq,
      configType: IMAGE_INPUT,
      value: "{filehubUrlImage}{valuePath}",
    };

    boFieldsSearchFilterSelected = boFieldsSearchFilterSelected.filter(
      (item: any) => !item.value.includes(boNameFirstSeq)
    );
  } else {
    firstSeqObj = {
      seq: "1",
      valuePath: "",
      key: "",
      configType: IMAGE_INPUT,
      value: "{filehubUrlImage}{valuePath}",
    };
  }

  //ADD COLUMN_NAME FOR LIST_TABLE
  if (configType === LIST_TABLE) {
    firstSeqObj = {
      ...firstSeqObj,
      columnName: "image",
    };
  }
  result.push(firstSeqObj);

  //ADD COLUMN FROM BO_SEARCH_FILTER
  for (let index = 0; index < boFieldsSearchFilterSelected.length; index++) {
    let valuePath = boFieldsSearchFilterSelected[index].value;
    let boName = valuePath.split(".")[1];
    let inputType = getInputTypeFromBoConfigItemsByBoName(
      boConfigItems,
      boName
    );

    let columnItem = getColumnItem(
      configType,
      index,
      valuePath,
      boName,
      inputType
    );

    result.push(columnItem);
  }
  return result;
};

const getColumnItem = (
  configType: string,
  index: number,
  valuePath: string,
  boName: string,
  inputType: string
): ColumnItems => {
  let columnItem: ColumnItems = {
    seq: String(index + 2),
    valuePath,
  };

  switch (configType) {
    case LIST_CARD || LIST || LIST_TILE:
      columnItem = {
        ...columnItem,
        key: boName,
        configType: inputType,
        value: valuePath,
      };
      break;

    case LIST_TABLE:
      columnItem = {
        ...columnItem,
        columnName: boName,
      };
      break;

    default:
      columnItem = {
        ...columnItem,
        key: boName,
        configType: inputType,
        value: valuePath,
      };
      break;
  }

  return columnItem;
};

export const getDefaultComponentInCellConfigByConfigType = (
  configType: string
) => {
  let configs: any = {};
  switch (configType) {
    case BUTTON:
      configs = {
        title: "Button",
        buttonType: "none",
        configType: BUTTON,
      };
      break;

    case LABEL:
      configs = {
        text: "Show text",
        labelType: NORMAL,
        configType: LABEL,
      };
      break;

    case ICON:
      configs = {
        iconPath: "",
        configType: ICON,
      };
      break;

    case IMAGE:
      configs = {
        imagePath: "",
        configType: IMAGE,
      };
      break;
  }
  return configs;
};

export const getUpdatedTabActiveItemsWithConfigs = (tabActiveItems: any) => {
  for (const row of tabActiveItems) {
    let cols = row?.cols;
    for (const col of cols) {
      if (col?.configs) {
        let newProps = getComponentPropsByConfigs(col?.configs);
        let itemProps = {
          ...col?.controlComponent?.props,
          ...newProps,
        };
        Object.assign(col?.controlComponent?.props, itemProps);
      }
    }
  }

  // console.log("getUpdatedTabActiveItemsWithConfigs newItemProps => ", tabActiveItems)
  return tabActiveItems;
};

export const getComponentPropsByConfigs = (configs: any) => {
  let props: any = {};
  let configType = configs?.configType;
  switch (configType) {
    case BUTTON:
      props = {
        title: configs?.title || "Button",
        buttonType: configs?.buttonType || "none",
        configType: BUTTON,
      };
      break;

    case LABEL:
      props = {
        text: configs?.text || "Show text",
        labelType: configs?.labelType || NORMAL,
        configType: LABEL,
      };
      break;

    case ICON:
      props = {
        iconPath: "",
        configType: ICON,
      };
      break;

    case IMAGE:
      props = {
        imagePath: "",
        configType: IMAGE,
      };
      break;
  }

  return props;
};

const getSubComponentPositionIdByCellId = (cellId: string) => {
  let position = cellId.split("_");
  let positionRowStr = position[0].split("-");
  let positionCellStr = position[1].split("-");
  let tabNumId = position[2];
  return {
    rowId: positionRowStr[1],
    colId: positionCellStr[1] + "-" + positionCellStr[2],
    tabNumId: parseInt(tabNumId),
  };
};

export const getNewUpdateColInTabBySubComponent = (
  updateComponents: any,
  cellId: string,
  updateDetail: any
) => {
  //PARAMS
  let { rowId, colId, tabNumId } = getSubComponentPositionIdByCellId(cellId);

  //GET MAIN_COMPONENT(BREADCRUMP, TAB) FOR UPDATE
  let mainComponentObj = updateComponents?.find(
    (item: any) => item?.rowId === rowId
  );
  if (!mainComponentObj) return;

  //GET COMPONENT_CONFIG IN MAIN_COMPONENT
  let componentConfig = mainComponentObj?.componentConfig;
  if (!componentConfig) return;

  //GET TAB_CONFIG IN COMPONENT_CONFIG BY TAB_NUM_ID
  let tabConfig = componentConfig?.find(
    (item: any) => item?.tabNumId === tabNumId
  );
  if (!tabConfig) return;

  //GET COL TO UPDATE SUB_COMPONENT(BUTTON, ICON, IMAGE) BY COL_ID
  let rowInTabId = colId.split("-")[0];
  let rowsInTab = tabConfig?.componentRowItems.find(
    (item: any) => item?.rowId === rowInTabId
  );
  let colsInRowTab = rowsInTab?.cols;

  let col = colsInRowTab?.find(
    (item: any) => item?.colId === colId + "_" + tabNumId
  );

  //ASSIGN NEW OBJECT TO COL
  Object.assign(col, { ...updateDetail, configs: updateDetail });

  return updateComponents;
};

const getRefTemplateByScreenEdit = (
  screenEdit: any
): "OneToOne" | "OneToMany" => {
  if (screenEdit?.length === 0) return ONE_TO_ONE;

  let oneToManyTypeList = [BREADCRUMP, TAB, TABLE];
  for (const se of screenEdit) {
    if (oneToManyTypeList.includes(se?.configType)) return ONE_TO_MANY;
  }

  return ONE_TO_ONE;
};

const getFileHubUrlImage = () => {
  return `${FILE_HUB_URL_IMAGE}`;
};

const getMicroflowUrl = () => {
  return `${MF_URL}`;
};

const getCenterUser = () => {
  return `${CENTER_USER}`;
};

const getCenterPassword = () => {
  return `${CENTER_PASSWORD}`;
};

const getCenterDomain = () => {
  return `${CENTER_DOMAIN}`;
};
