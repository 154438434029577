import React from "react";

const StoryGenPageUploadImage = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      className={className}
      style={style}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1640 4843 c-192 -14 -445 -70 -587 -128 -438 -181 -672 -514 -759
-1080 -18 -111 -19 -195 -19 -1075 0 -1053 -1 -1038 65 -1303 123 -492 425
-793 920 -917 258 -65 258 -65 1305 -65 1039 0 1035 0 1284 61 602 148 911
538 986 1243 22 201 22 1439 0 1481 -32 62 -115 99 -183 81 -46 -13 -99 -65
-112 -112 -6 -18 -10 -143 -10 -277 l0 -243 -67 54 c-148 118 -236 150 -413
151 -106 1 -136 -3 -195 -22 -136 -46 -118 -32 -675 -512 -306 -264 -462 -390
-498 -407 -41 -19 -69 -25 -126 -25 -95 1 -139 19 -239 103 -45 38 -110 82
-144 99 -172 85 -376 91 -546 16 -31 -14 -263 -163 -513 -331 -251 -169 -460
-303 -464 -298 -13 14 -38 162 -50 293 -8 87 -10 395 -7 1015 4 950 4 947 56
1147 95 366 313 584 679 679 189 49 234 52 877 58 592 6 611 7 645 27 58 34
82 79 78 145 -5 65 -32 109 -82 133 -30 14 -96 16 -598 14 -309 -1 -583 -3
-608 -5z m2531 -2470 c25 -11 116 -81 203 -154 l157 -134 -5 -245 c-5 -249
-17 -363 -55 -512 -94 -366 -313 -585 -679 -679 -210 -54 -215 -54 -1222 -54
-995 0 -1027 1 -1222 50 -196 48 -344 125 -457 237 -60 60 -119 135 -110 142
41 34 936 628 971 645 103 49 234 35 320 -34 182 -147 268 -190 409 -203 145
-14 268 9 384 73 35 20 259 204 538 444 339 290 490 414 520 425 23 8 49 17
57 20 31 11 147 -2 191 -21z"
        />
        <path
          d="M3765 4833 c-17 -10 -135 -124 -263 -254 -195 -198 -235 -243 -242
-275 -23 -100 50 -194 149 -194 63 0 89 15 183 107 l88 87 0 -463 c0 -460 0
-464 22 -503 63 -112 222 -109 277 6 20 41 21 58 21 501 l0 459 88 -87 c95
-93 120 -107 185 -107 72 0 130 49 147 125 17 77 8 91 -242 344 -128 130 -246
244 -263 254 -16 9 -50 17 -75 17 -25 0 -59 -8 -75 -17z"
        />
        <path
          d="M1773 3980 c-85 -22 -117 -38 -201 -97 -144 -102 -235 -280 -236
-463 -1 -164 54 -300 169 -416 230 -234 597 -234 830 0 115 115 170 251 169
416 -2 377 -366 656 -731 560z m263 -328 c115 -59 174 -188 140 -305 -21 -71
-65 -129 -123 -164 -42 -25 -58 -28 -133 -28 -75 0 -91 3 -133 28 -58 35 -102
93 -123 164 -39 135 42 277 186 324 41 14 142 3 186 -19z"
        />
      </g>
    </svg>
  );
};
export default StoryGenPageUploadImage;
