import React, { useEffect, useReducer, useRef, useState } from "react";
import { Drawer, Input, Radio, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./DataReferenceDrawer.module.scss";
import CreateDataReferModal from "src/components/zero/zero-story/modal/modal-data-refer/CreateDataReferModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  RefaceApi: any;
};

const InputTypeValues = {
  checkbox: "checkbox",
  radio: "radio",
  dropdown: "dropdown",
};

function DataReferenceDrawer({ isOpen, RefaceApi, onClose }: Props) {
  useEffect(() => {
    if (isOpen) {
      setInputDataReferName("");
      setInputType("");
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
   
  }, [isOpen]);

  let [inputDataRefName, setInputDataReferName] = useState<string>("");
  let [inputType, setInputType] = useState<string>("");
  let [modalOpen, setModalOpen] = useState<boolean>(false);

  const onCreateHandler = () => {
    setModalOpen(true);
    onClose();
  };

  const onCloseCreateDataReferModal = () => {    
    setModalOpen(false);
    onClose();
  };

  const FooterDrawer = () => {
    const isDisabled = !inputDataRefName || !inputType;

    return (
      <div className={styles.footerContainer}>
        <button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </button>
        <button
          className={styles.btnCreate}
          onClick={onCreateHandler}
          disabled={isDisabled}
        >
          Create
        </button>
      </div>
    );
  };

  return (
    <>
      <Drawer
        title="Create Data Reference"
        closeIcon={<LeftOutlined />}
        open={isOpen}
        onClose={onClose}
        footer={<FooterDrawer />}
      >
        <div className={styles.textInputContainer}>
          <p className={styles.textHeader}>Data Reference Name</p>
          <span className={styles.textImportant}>*</span>
        </div>
        <Input
          className={styles.inputStory}
          placeholder="Data Reference Name"
          value={inputDataRefName}
          onChange={(e) => setInputDataReferName(e.currentTarget.value)}
        />

        <div className={styles.textInputContainer2}>
          <p className={styles.textHeader}>Create Data Reference</p>
          <span className={styles.textImportant}>*</span>
        </div>

        <Radio.Group
          className={styles.radioGroup}
          onChange={(e) => setInputType(e.target.value)}
          value={inputType}
        >
          <Radio
            className={styles.radioContainer}
            value={InputTypeValues.checkbox}
          >
            Checkbox
          </Radio>

          <Radio
            className={styles.radioContainer}
            value={InputTypeValues.radio}
          >
            Radio Button
          </Radio>

          <Radio
            className={styles.radioContainer}
            value={InputTypeValues.dropdown}
          >
            Dropdown
          </Radio>
        </Radio.Group>
      </Drawer>
      <CreateDataReferModal
        isOpenModal={modalOpen}
        data={{
          dataReferName: inputDataRefName,
          inputType: inputType,
        }}
        RefaceAPIGetDataByAspID={RefaceApi}
        onClose={onCloseCreateDataReferModal}
      />
    </>
  );
}

export default DataReferenceDrawer;
