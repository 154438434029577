import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import styles from '../modal/ModalWarning.module.scss';
import CheckCircleIcon from "src/assets/svg/check-circle";
import WarningIcon from "src/assets/svg/warning-icon";

type ModalWarningProps = {
    open: boolean;
    onClose: () => void,
    onLogOut: () => void,
    // name:string
    // title:string
    // description:string
};


function ModalWarning({ open, onClose, onLogOut }: ModalWarningProps) {
    // useEffect(() => {
    //     if (open) {
    //         window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    //     } else {
    //         window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    //     }
    // }, [open])

    return (
        <Modal
            open={open}
            width={400}
            footer={[
                <div className={styles.buttonGroup}>
                    <Button className={styles.buttonModal} key="cancel" type="default" onClick={onClose}>Cancel</Button>
                    <Button className={styles.buttonModal}key="logout" type="primary" onClick={onLogOut}>Log Out</Button>
                </div>,
            ]}
            onCancel={onClose}
        >
            <div className={styles.modalContainer}>
                <WarningIcon />
                <div className={styles.titleText}>
                    Are you sure you want to log out?
                </div>
                <div className={styles.descriptionText}>
                    You will need to log in again to continue using the app. Make sure your work is saved.
                </div>
            </div>
        </Modal>
    );
}
export default ModalWarning;