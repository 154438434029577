import { useContext } from "react";
import { AppContext } from "src/page/context/App.context";
const useSearchApp = () => {
  let appContext: any = useContext(AppContext);
  const searchApp: string = appContext.searchApp;
  const setSearchApp: (input: string) => void = (input: string) => {
    appContext.setSearchApp(input);
  };
  return [searchApp, setSearchApp] as const;
};
export default useSearchApp;
