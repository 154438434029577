import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../../modal-create-entity/CreateStoryEntityModal.module.scss";
import { MappingParam } from "src/shared/MappingParam/MappingParam";
import { Button, Select } from "antd";
import { useParams } from "react-router";
import useFetch from "src/hooks/useFetch";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { checkEmpty } from "src/services/util.service";
import {
  GetAllDBConnectByStoryIDResponse,
  GetTableAndColumnResponse,
  GenerateTableNodeBOResponse,
  TableType,
  EntityBoMapping,
  ConnectorTableType,
} from "src/types/response.type";
import {
  GenerateTableNodeBORequest,
  GetTableAndColumnRequest,
} from "src/types/request.type";
import { BoMappingInit } from "src/utils/bo-initial-data/BoMappingInit";
import {
  GET_DB_CONNECT_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
  GENERATE_TABLE_NODE_ENDPOINT,
  GET_ENTITY_BO_BY_ID_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import ConfirmDeleteModal from "../../ConfirmDeleteModal";
import { BO_INIT_KEY } from "src/constants/DiagramInitKey";

type Props = {
  boId: string;
  mappingParam: MappingParam;
  isRerender?: boolean;
  updateBoMapping: (req: EntityBoMapping) => void;
  isEditMode: boolean;
};

type SelectProps = { value: string; label: string };

function EditBoMapping({
  boId,
  mappingParam,
  isRerender,
  updateBoMapping,
  isEditMode
}: Props) {
  let params = useParams();
  const { loading, error, responseData, fetchData, fetchKey } = useFetch<
    | GetAllDBConnectByStoryIDResponse
    | GetTableAndColumnResponse
    | GenerateTableNodeBOResponse
  >();
  let [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  let [selectedTable, setSelectedTable] = useState<string[]>([]);
  let [selectTableItems, setSelectTableItems] = useState<SelectProps[]>([]);
  let [tableColumnItems, setTableColumnItems] = useState<TableType[]>([]);
  let [isOpenRemoveModal, setIsOpenRemoveModal] = useState<boolean>(false);
  let [entityBoForEdit, setEntityBoForEdit] = useState<EntityBoMapping>();
  let [dbConnectorReq, setDbConnectorReq] =
    useState<GetTableAndColumnRequest>();

  let storyEntityContext = useContext(StoryEntityContext);

  const boMappingDiagram = useRef(null);

  const GET_DB_CONNECT_FK = "getDbConnectFK";
  const GET_TABLE_COL_FK = "getTablColFK";
  const GET_GEN_TABLE_FK = "getGenTablFK";
  const GET_ENT_BO_ID_FK = "getEntBoIdFk";

  //GET DB_CONNNECTION AND BO_INIT ONLOAD
  useEffect(() => {
    if (isFirstTime) {
      mappingParam.init(boMappingDiagram.current);
      setIsFirstTime(false);
    }

    if(boId){
      //RESET BO MAPPING
      mappingParam.loadDataToDiagram(structuredClone(BoMappingInit));
      mappingParam.setBoMapping([]);
      setSelectedTable([]);

      getDBConnectByStoryIdController();
      console.log('bo config items => ', storyEntityContext.boConfigItems)
      console.log('data refer config items => ', storyEntityContext.moreSettingItems)

    } 
  }, [boId])

  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) return;

      switch (fetchKey) {
        case GET_DB_CONNECT_FK: {
          let respGetAllDBConnect = responseData?.data as unknown[];
          if (respGetAllDBConnect?.length === 0) return;
          respGetAllDBConnect = respGetAllDBConnect.map((item: any) => {
            return {
              ...item,
              is_active: Boolean(item.is_active),
            };
          });
          let reqGetTableCol: GetTableAndColumnRequest =
            respGetAllDBConnect[0] as GetTableAndColumnRequest;
          setDbConnectorReq(reqGetTableCol);
          getTableAndColumnController(reqGetTableCol);
          break;
        }
        case GET_TABLE_COL_FK: {
          let respGetTableCol: any = responseData?.data;
          let allTable = respGetTableCol?.table || [];
          let tableSelects: SelectProps[] = [...(allTable as unknown[])].map(
            (item: any) => {
              return {
                label: item.table_name,
                value: item.table_name,
              };
            }
          );
          setTableColumnItems(allTable as unknown as TableType[]);
          setSelectTableItems(tableSelects);

          if (boId) {
            getGetEntityBoByIdController();
          }
          break;
        }
        case GET_GEN_TABLE_FK: {
          let respGenTables =
            responseData?.data as unknown as ConnectorTableType;
          let respTableCols = respGenTables?.name_tabl;
          setTableColumnItems((prev) => [...prev, ...respTableCols]);

          let newSelectTableItems = respTableCols.map((item) => {
            return {
              value: item.table_name,
              label: item.table_name,
            };
          });
          setSelectTableItems((prev) => [...prev, ...newSelectTableItems]);

          let newSelectTables = respTableCols.map((item) => item.table_name);
          setSelectedTable(newSelectTables);
          break;
        }
        case GET_ENT_BO_ID_FK: {
          let respEntBoId = responseData?.data as unknown as EntityBoMapping;
          updateBoMapping(respEntBoId);
          setEntityBoForEdit(respEntBoId);

          let boMapping = JSON.parse(respEntBoId?.json_mapping_bo);
          if(isEditMode){
            boMapping = mappingParam.getStateDiagramJson()
          }
          let nodeDataArray = boMapping?.nodeDataArray;
          let linkDataArray = boMapping?.linkDataArray;
          let tableNames = nodeDataArray.filter(
            (item: any) =>
              item.group === "tableGroup" && item.typeDesc === "Table"
          );
          let newSelectTables = tableNames.map((item: any) => item.paramName);
          setSelectedTable(newSelectTables);
          console.log("boMapping b4 => ", boMapping)
          console.log("bo items  => ", storyEntityContext.boConfigItems)
          // initJsonBoMapping(nodeDataArray, linkDataArray)
          for (const createBoField of storyEntityContext.boConfigItems) {
            if(!createBoField.enyity_bo_item_id){

              if (createBoField.data_type == "Object") {
                let parentKey = checkEmpty(createBoField.ref_id === '' ? null : createBoField.ref_id) 
                  ? BO_INIT_KEY
                  : createBoField.ref_id;
                let pathName = getPathName(
                  storyEntityContext.boConfigItems,
                  createBoField.ref_id,
                  createBoField.bo_name
                );
                let margin = 30;
                let pathNames = pathName.split(".");
                margin = margin + (pathNames.length - 1) * 20;
                let newNodeData = {
                  paramName: createBoField.bo_name || "",
                  pathName: pathName,
                  margin: `0 0 0 ${margin}`,
                  typeDesc: createBoField.data_type,
                  isList: false,
                  category: "nodeIsGroup",
                  isGroup: false,
                  key: createBoField.id,
                  parentKey: parentKey,
                  group: "boGroup",
                };
                nodeDataArray.push(newNodeData);
                linkDataArray.push({ from: parentKey, to: createBoField.id });
              } else {
                let pathName = getPathName(
                  storyEntityContext.boConfigItems,
                  createBoField.ref_id,
                  createBoField.bo_name
                );
                let margin = 30;
                let pathNames = pathName.split(".");
                margin = margin + (pathNames.length - 1) * 20;
                let newNodeData = {
                  paramName: createBoField.bo_name || "",
                  pathName: pathName,
                  margin: `0 0 0 ${margin}`,
                  typeDesc: convertDataType(createBoField.data_type),
                  isList: false,
                  category: "nodeIsNotGroup",
                  isGroup: false,
                  key: createBoField.id,
                  parentKey: createBoField.ref_id,
                  group: "boGroup",
                };
                nodeDataArray.push(newNodeData);
                linkDataArray.push({
                  from: createBoField.ref_id,
                  to: createBoField.id,
                });
              }
            }
          }
          boMapping.nodeDataArray = nodeDataArray
          boMapping.linkDataArray = linkDataArray
          console.log("boMapping after =>",boMapping)
          storyEntityContext.setIsLoadingToUpdateBo(false)
          // console.log("mappingParam => ", jsonBOMapping)
          mappingParam.loadDataToDiagram(boMapping);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  const onAddSelectTableHandler = (selectedTable: string[]) => {
    if (selectedTable.length > 0) {
      if (!tableColumnItems) return;
      let tableColObjs: TableType[] = tableColumnItems.filter((item) =>
        selectedTable.includes(item.table_name)
      );
      mappingParam.setTableByTableColumn(tableColObjs);
    }
  };
  const onDeleteSelectTableHandler = () => {
    mappingParam.updateBoMappingOnRemoveTable();
  };

  //ACTION HANDLER
  const generateTableByBoHandler = () => {
    let tableColumnReq = mappingParam.getRequestTableColListFromNodeDataArray();
    if (tableColumnReq.length === 0) return;
    let reqGenerateTableNodeBo: GenerateTableNodeBORequest;
    reqGenerateTableNodeBo = {
      connector: dbConnectorReq as GetTableAndColumnRequest,
      name_tabl: tableColumnReq as TableType[],
    };
    getGenerateTableByBoController(reqGenerateTableNodeBo);
  };
  const removeTableHandler = () => {
    setIsOpenRemoveModal(true);
  };
  const onChangeTableHandler = (value: string[]) => {
    setSelectedTable(value);

    value.length > 0
      ? onAddSelectTableHandler(value)
      : onDeleteSelectTableHandler();
  };

  //API CONTROLLER FUNCTION
  const getDBConnectByStoryIdController = () => {
    fetchData(
      {
        endpoint: GET_DB_CONNECT_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_DB_CONNECT_FK
    );
  };

  const getTableAndColumnController = (req: GetTableAndColumnRequest) => {
    fetchData(
      {
        endpoint: GET_TABLE_COL_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GET_TABLE_COL_FK
    );
  };

  const getGenerateTableByBoController = (req: GenerateTableNodeBORequest) => {
    fetchData(
      {
        endpoint: GENERATE_TABLE_NODE_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GET_GEN_TABLE_FK
    );
  };
  const getGetEntityBoByIdController = () => {
    fetchData(
      {
        endpoint: GET_ENTITY_BO_BY_ID_ENDPOINT,
        method: "GET",
        params: {
          id: boId,
        },
      },
      GET_ENT_BO_ID_FK
    );
  };

  //UTILITY FUNCTION
  const getStoryId = () => {
    return params.id;
  };

  //REMOVE MODAL HANDLER
  const onOkRemoveModalHandler = () => {
    setIsOpenRemoveModal(false);
    setSelectedTable([]);
    onDeleteSelectTableHandler();
  };
  const onCancelRemoveModalHandler = () => {
    setIsOpenRemoveModal(false);
  };

  const getPathName = (
    boConfigItems: any[],
    parent_id: string,
    currentName: string
  ): string => {
    if (checkEmpty(parent_id)) return `${currentName}`;
    for (let a = 0; a < boConfigItems.length; a++) {
      let item = boConfigItems[a];
      if (item.id === parent_id) {
        let parentPath = getPathName(
          boConfigItems,
          item.parent_id as string,
          item.bo_name as string
        );
        if (checkEmpty(parentPath)) return `${currentName}`;
        else return `${parentPath}.${currentName}`;
      }
    }
    return "";
  };

  const convertDataType = (dataType: string): string => {
    switch (dataType) {
      case "Varchar":
        return "String";
      case "Datetime":
        return "Datetime";
      case "Integer":
        return "Integer";
      case "Decimal":
        return "Decimal";
      case "Boolean":
        return "Boolean";
      default:
        return "Object";
    }
  };

  

  return (
    <div className={styles.createBusinessObjContainer}>
      <div className={styles.boTableMappingContainer}>
        <div style={{ height: "100%", width: "100%" }}>
          <div
            key={"cboMappingKey"}
            style={{ height: "100%", width: "100%" }}
            ref={boMappingDiagram}
          ></div>
        </div>
      </div>
      <div className={styles.genTableContainer}>
        <Button
          className={storyEntityContext.isLoadingToUpdateBo === false ? styles.genTableBtn : styles.genTableBtnDisabled}
          key="gen_table"
          onClick={() => generateTableByBoHandler()}
          disabled={storyEntityContext.isLoadingToUpdateBo}
        >
          Generate Table From BO
        </Button>
        <div className={styles.selectWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.textHeader}>Please select table</span>
            <span className={styles.textImportant}>*</span>
          </div>
          <div className={styles.actionWrapper}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              mode="multiple"
              value={selectedTable}
              options={selectTableItems}
              style={{ width: "100%", height: "auto" }}
              placeholder={<div>&nbsp;&nbsp;&nbsp;Table</div>}
              onChange={onChangeTableHandler}
              optionFilterProp="children"
              className={styles.genTableSelect}
              disabled={selectTableItems.length > 0 ? false : true}
            />
            <div
              className={styles.actionIconWrapper}
              onClick={() => removeTableHandler()}
            >
              <StoryEditEntDelete className={styles.actionIcon} />
            </div>
          </div>
        </div>
      </div>

      {/* MESSAGE NOTIFY */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Remove table select?",
          description: `Are you sure you want to remove table select? This action cannot be
  undone.`,
        }}
        isModalOpen={isOpenRemoveModal}
        onOk={onOkRemoveModalHandler}
        onCancel={onCancelRemoveModalHandler}
      />
    </div>
  );
}

export default EditBoMapping;
