import React, { useEffect, useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Drawer, Radio } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./StoryDrawer.module.scss";
import CreateStoryEntityModal from "../modal/modal-create-entity/CreateStoryEntityModal";
import UploadExcelCreateBO from "../modal/modal-upload-excel/UploadExcelCreateBO";
import { StoryEnyityBORequest } from "src/types/request.type";
import { StoryEntityProvider } from "src/page/context/StoryEntity.context";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (newBo: StoryEnyityBORequest) => void;
};

const entityItems: { value: string; label: string }[] = [
  { value: "create_manual", label: "Create Manual" },
  { value: "upload_excel", label: "Upload From Excel" },
  { value: "gen_ai", label: "Generate From AI" },
];

function StoryEntityDrawer({ isOpen, onClose, onCreate }: Props) {
  let [createEntityType, setCreateEntityType] = useState<string>(
    entityItems[0].value
  );
  let [isOpenCreateBOModal, setIsOpenCreateBOModal] = useState<boolean>(false);
  let [isOpenUploadExcelCreateBOModal, setUploadExcelCreateBOModal] = useState<boolean>(false);

  const [arrBOConfigItems, setArrBOConfigItems] = useState<any[]>([]); 
  let [isEditSuccess, setIsEditSuccess] = useState<string>("");

  const handleStateUpdate = (newState: any[]) => {
    setArrBOConfigItems(newState);
  };

  useEffect(() => {
    if (isOpen) {
      console.log("StoryEntityDrawer load");
      setCreateEntityType(entityItems[0].value);

      window.parent.postMessage({event: "zero_open_modal", key: ""}, "*");
    } else {
      window.parent.postMessage({event: "zero_close_modal", key: ""}, "*");
    }
  }, [isOpen]);

  const onChangeEntityTypeHandler = (e: RadioChangeEvent) => {
    let entityTypeVal = e.target.value;
    setCreateEntityType(entityTypeVal);
  };

  const onClickCreateEntityHandler = () => {
    // if (inputStoryName) {
    //   onCreate(inputStoryName);
    //   setInputStoryName("");
    //   return;
    // }
    // onClose();
    console.log('entityItems', entityItems)
    if(createEntityType == 'create_manual'){
      setIsOpenCreateBOModal(true);
    }else if(createEntityType == 'upload_excel'){
      setUploadExcelCreateBOModal(true)
    }
  };
  const onOKCreateBOModal = () => {
    setIsOpenCreateBOModal(false);
  };
  const onCancelCreateBOModal = () => {
    setIsOpenCreateBOModal(false);
    setArrBOConfigItems([])
  };

  const onContinueUploadExcelModal = () => {
    setUploadExcelCreateBOModal(false);
    setIsOpenCreateBOModal(true);
  };
  const onCancelUploadExcelModal = () => {
    setUploadExcelCreateBOModal(false);
  };

  const onUpdateBO = () => {
    setIsEditSuccess("success")
  }

  const FooterDrawer = () => {
    return (
      <div className={styles.footerContainer}>
        <button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </button>
        <button
          className={styles.btnCreate}
          onClick={onClickCreateEntityHandler}
        >
          Create
        </button>
      </div>
    );
  };
  return (
    <>
      <Drawer
        title="Create Entity"
        closeIcon={<LeftOutlined />}
        onClose={onClose}
        open={isOpen}
        footer={<FooterDrawer />}
      >
        <Radio.Group
          onChange={onChangeEntityTypeHandler}
          value={createEntityType}
          className={styles.radioContainer}
        >
          {entityItems.map((ent_item) => {
            return (
              <div key={ent_item.value} className={styles.radioWrapper}>
                <Radio value={ent_item.value}>{ent_item.label}</Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Drawer>
      {isOpenCreateBOModal && (
        <StoryEntityProvider>
          <CreateStoryEntityModal
            isModalOpen={isOpenCreateBOModal}
            entityType={createEntityType}
            onOk={onOKCreateBOModal}
            onCancel={onCancelCreateBOModal}
            onCreate={onCreate}
            onUpdate={onUpdateBO}
            arrBOConfigItems = { arrBOConfigItems }
            boId={""}
            isCreateMode = { true }
          />
        </StoryEntityProvider>
      )}

      {isOpenUploadExcelCreateBOModal && (
          <UploadExcelCreateBO
            isModalOpen={isOpenUploadExcelCreateBOModal}
            modalDetail={{ title: "Upload From Excel" }}
            onOk={ onContinueUploadExcelModal }
            onCancel={ onCancelUploadExcelModal }
            handleStateUpdate = { handleStateUpdate } 
          />
      )}
    </>
  );
}

export default StoryEntityDrawer;
