import renderBOScreens from "./renderBoScreens";
import renderButtonScreens from "./renderButtonScreens";
import renderLabelScreens from "./renderLabelScreens";
import renderBreadcrumbScreens from "./renderBreadcrumbScreens";
import renderTabsScreenContainer from "./renderTabsScreensContainer";
import renderTableContainer from "./renderTableContainer";
import renderListCardContainer from "./renderListCardContainer";
import renderListContainer from "./renderListContainer";
import renderListTileContainer from "./renderListTileContainer";
import {
  BO,
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  TAB,
  TABLE,
} from "src/constants/PageConfig";
import renderImageScreens from "./renderImageScreens";
import {
  LIST,
  LIST_CARD,
  LIST_TABLE,
  LIST_TILE,
} from "src/constants/InputType";
import renderIconScreens from "./renderIconScreens";

function renderTabScreens(data, container, screen, maxColumn) {
  console.log("step:", data);
  console.log("step:", screen);
  console.log("step.columnnumber:", screen.columnnumber);

  const stepElement = document.createElement("div");
  stepElement.id = `tab-${data.tabName}`;
  stepElement.style.display = data.seq !== 1 ? "none" : "grid";
  stepElement.style.gridRow = screen.rownumber;
  stepElement.style.gridColumn = `1 / span ${maxColumn}`;
  stepElement.style.paddingTop = "60px";
  // stepElement.hidden = data.seq !== 1

  stepElement.className = `tab-content-${screen.key}`;

  stepElement.style.gridAutoRows = "auto";
  stepElement.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;
  // repeat(auto-fill, minmax(300px, 1fr))

  if (!data.tabScreens) return [];

  data?.tabScreens.forEach((tabScreen) => {
    const { rownumber, columnnumber } = tabScreen;
    console.log("rownumber:", rownumber);
    console.log("columnnumber:", columnnumber);

    const tabScreenElement = document.createElement("div");
    tabScreenElement.style.gridRow = rownumber;
    tabScreenElement.style.gridColumn = columnnumber;
    tabScreenElement.style.padding = "10px";

    // Create elements based on tabScreen configType
    if (tabScreen.configType === BO) {
      renderBOScreens(tabScreenElement, tabScreen);
    } else if ([TABLE, LIST_TABLE].includes(tabScreen.configType)) {
      console.log("tabScreen: ", tabScreen);
      renderTableContainer(tabScreenElement, tabScreen, maxColumn);
    } else if (tabScreen.configType === BUTTON) {
      console.log("tabScreen: ", tabScreen);
      renderButtonScreens(tabScreenElement, tabScreen);
    } else if (tabScreen.configType === LABEL) {
      console.log("tabScreen: ", tabScreen);
      renderLabelScreens(tabScreenElement, tabScreen);
    } else if (tabScreen.configType === TAB) {
      renderTabsScreenContainer(
        tabScreenElement,
        stepElement,
        tabScreen,
        maxColumn
      );
    } else if (tabScreen.configType === BREADCRUMP) {
      console.log("tabScreen: ", tabScreen);
      renderBreadcrumbScreens(
        tabScreenElement,
        stepElement,
        tabScreen,
        maxColumn
      );
    } else if (screen.configType === LIST_CARD) {
      renderListCardContainer(tabScreenElement, screen, maxColumn);
    } else if (screen.configType === LIST) {
      renderListContainer(tabScreenElement, screen, maxColumn);
    } else if (screen.configType === LIST_TILE) {
      renderListTileContainer(tabScreenElement, screen, maxColumn);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(tabScreenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(tabScreenElement, screen);
    }
    stepElement.appendChild(tabScreenElement);
  });

  container.appendChild(stepElement);
}

function clearTabContent(container, screen) {
  const stepContents = container.querySelectorAll(`.tab-content-${screen.key}`);
  console.log("stepContents2:: :", stepContents);
  stepContents.forEach((stepContent) => {
    stepContent.style.display = "none";
    // stepContent.remove();
  });
}

export { renderTabScreens, clearTabContent };
