import $ from "jquery";
import renderBOScreens from "src/template/renderBoScreens";
import renderBreadcrumbScreens from "src/template/renderBreadcrumbScreens";
import renderButtonScreens from "src/template/renderButtonScreens";
import renderLabelScreens from "src/template/renderLabelScreens";
import renderTabsScreenContainer from "src/template/renderTabsScreensContainer";
import renderListTableContainer from "src/template/renderListTableContainer";
import renderListCardContainer from "src/template/renderListCardContainer";
import renderListContainer from "src/template/renderListContainer";
import renderListTileContainer from "src/template/renderListTileContainer";
import { jsTextListTile } from "src/template/listTile/js_backup";
import { cssText } from "src/template/cssText";
import { jsText } from "src/template/jsText";
import { checkEmpty } from "./util.service";
import { jsTextListTileMain } from "src/template/listTile/js_MainPage";
import { jsTextListTileAdd } from "src/template/listTile/js_AddPage";
import { jsTextListTileEdit } from "src/template/listTile/js_EditPage";

import { jsTextListCardEdit } from "src/template/listCard/js_EditPage";
import { jsTextListCardAdd } from "src/template/listCard/js_AddPage";
import { jsTextListCardMain } from "src/template/listCard/js_MainPage";

import { jsTextListsMain } from "src/template/list/js_MainPage";
import { jsTextListsAdd } from "src/template/list/js_AddPage";
import { jsTextListsEdit } from "src/template/list/js_EditPage";

import { jsTextListTableMain } from "src/template/listTable/js_MainPage";
import { jsTextListTableAdd } from "src/template/listTable/js_AddPage";
import { jsTextListTableEdit } from "src/template/listTable/js_EditPage";

import { testHeaderFn } from "./page-service-header";
import { BO, BREADCRUMP, BUTTON, LABEL, TAB } from "src/constants/PageConfig";
import { LIST, LIST_TILE } from "src/constants/InputType";

export let CONFIG_SCREEN = {
  refTemplate: "OneToOne", //OneToMany
  flow: {
    appId: "c734fcc0-8067-4ac8-b6d7-af46781a9682",
    appName: "TestZero2",
    flowId: "C2474273-4ff8202c-6669-4b0c-84c1-36a34ba23018",
    flowName: "flow_Employee_3",
    snapVersion: "v.1.0.0",
    runtimeName:
      "C2474273-4ff8202c-6669-4b0c-84c1-36a34ba23018.TestZero2.flow_Employee_3.v.1.0.0.jar",
  },
  //flowName + _flow_output
  //flowName + _flow_input

  bo: [
    //ตอนส่ง api
    {
      boName: "Employee",
      fields: [
        {
          fieldName: "emp_id",
          type: "String",
        },
        {
          fieldName: "emp_name",
          type: "String",
        },
        {
          fieldName: "emp_lname",
          type: "String",
        },
      ],
    },
  ],
  mapping: [
    {
      pathFrom: "Employee.emp_id",
      pathTo: "test_employee.emp_id",
    },
    {
      pathFrom: "Employee.emp_name",
      pathTo: "test_employee.emp_name",
    },
    {
      pathFrom: "Employee.emp_lname",
      pathTo: "test_employee.emp_lname",
    },
  ],
  table: [
    {
      tableName: "test_employee",
      search:
        "test_employee.emp_name || test_employee.emp_lname like '%search%'",
      customWhere: "",
      defaultOrderby:
        "order by test_employee.emp_name asc, test_employee.emp_lname asc",
      columns: [
        {
          column: "emp_id",
          type: "string",
          primaryKey: true,
          whereKey: true,
          foreignKey: "",
        },
        {
          column: "emp_name",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "emp_lname",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
      ],
    },
  ],
  config: {
    filehubUrlImage:
      "https://mocha.centerapp.io/FileService/downloadFile?fileId=", // https://https://mocha.centerapp.io/FileService/downloadFile?fileId=
    microFlowUrl: "https://test.oneweb.tech/MicroflowRest/v2/DoAction",
  },
  screens: [
    // {
    //   key: "username1",
    //   label: "Username",
    //   configType: "BO",
    //   rownumber: 1,
    //   columnnumber: 1,
    //   valuePath: "username",
    //   inputType: "image",
    //   value: "88888"
    // },
    // {
    //   key: "fname",
    //   label: "First name",
    //   configType: "BO",
    //   rownumber: 2,
    //   columnnumber: 1,
    //   valuePath: "Employee.emp_name",
    //   inputType: "image",//text,password,number,textarea,date,file,radio,select,checkbox,email, image
    //   displayView: "",//
    //   displayEdit: "",//
    //   hidden: "N",
    //   dataRefer: {
    //     type: "table",//table,list
    //     datas: [
    //       {
    //         columnShow: "เพศชาย",
    //         columnValue: "man"
    //       },
    //       {
    //         columnShow: "เพศหญิง",
    //         columnValue: "woman"
    //       }
    //     ],
    //     dataconnect: { // table map --> datas
    //       connection: {},
    //       tableName: "xxx",
    //       columnShow: "xxx",
    //       columnValue: "xxx",
    //       condition: "xxx",
    //       advanceSearch: "xxx"
    //     }
    //   }
    // },
    //  {
    //   key: "lname",
    //   label: "Last name",
    //   configType: "BO",
    //   rownumber: 2,
    //   columnnumber: 2,
    //   valuePath: "getuser.UserObj.lname"
    // },
    // {
    //   key: "breadcrumb",
    //   label: "breadcrumb",
    //   configType: "BREADCRUMB",
    //   rownumber: 12,
    //   columnnumber: 1,
    //   valuePath: "",
    //   steps: [
    //     {
    //       seq: 1,
    //       tabName: "step1",
    //       tabScreens: [
    //         {

    //           key: "username",
    //           label: "Unamename",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         },
    //         {

    //           key: "date",
    //           label: "Date",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 2,
    //           valuePath: "getuser.UserObj.date"
    //         },
    //         {

    //           key: "step1_BC_table1",
    //           label: "table1",
    //           configType: "TABLE",
    //           rownumber: 2,
    //           columnnumber: 1,
    //           configColums: [
    //             {
    //               seq: 1,
    //               columnName: "Task Name",
    //               valuePath: "getTask.Tasks.name"
    //             },
    //             {
    //               seq: 2,
    //               columnName: "Step Name",
    //               valuePath: "getTask.Tasks.stepName"
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       seq: 2,
    //       tabName: "step2",
    //       tabScreens: [
    //         {
    //           key: "tab-breadcrumb",
    //           label: "",
    //           configType: "TAB",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "",
    //           tabs: [
    //             {
    //               seq: 1,
    //               tabName: "tab1 breadcrumb",
    //               tabScreens: [
    //                 {

    //                   key: "usernamebreadcrumb",
    //                   label: "Unamename breadcrumb",
    //                   configType: "BO",
    //                   rownumber: 1,
    //                   columnnumber: 1,
    //                   valuePath: "getuser.UserObj.username"
    //                 }
    //               ]
    //             },
    //             {
    //               seq: 2,
    //               tabName: "tab2 breadcrumb",
    //               tabScreens: [
    //                 {

    //                   key: "breadcrumb-table1",
    //                   label: "table1",
    //                   configType: "TABLE",
    //                   rownumber: 1,
    //                   columnnumber: 1,
    //                   configColums: [
    //                     {
    //                       seq: 1,
    //                       columnName: "Task Name",
    //                       valuePath: "getTask.Tasks.name"
    //                     },
    //                     {
    //                       seq: 2,
    //                       columnName: "Step Name",
    //                       valuePath: "getTask.Tasks.stepName"
    //                     }
    //                   ]
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       seq: 3,
    //       tabName: "step3",
    //       tabScreens: [
    //         {

    //           key: "username1s",
    //           label: "Unamename1",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         },
    //         {
    //           key: "BUTTON",
    //           label: "BUTTON Text",
    //           configType: "BUTTON",
    //           buttonType: "primary", //primary .....
    //           rownumber: 2,
    //           columnnumber: 1,
    //           valuePath: "",
    //           action: ["savecreateUser", "getUsers"]
    //         }
    //       ]
    //     }

    //   ]
    // },
    // {
    //   key: "tab",
    //   label: "",
    //   configType: "TAB",
    //   rownumber: 4,
    //   columnnumber: 1,
    //   valuePath: "",
    //   tabs: [
    //     {
    //       seq: 1,
    //       tabName: "tab1",
    //       tabScreens: [
    //         {

    //           key: "username",
    //           label: "Unamenametab1",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         }
    //       ]
    //     },
    //     {
    //       seq: 2,
    //       tabName: "tab2",
    //       tabScreens: [
    //         {

    //           key: "tab2_table1",
    //           label: "table1",
    //           configType: "TABLE",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           configColums: [
    //             {
    //               seq: 1,
    //               columnName: "Task Name",
    //               valuePath: "getTask.Tasks.name"
    //             },
    //             {
    //               seq: 2,
    //               columnName: "Step Name",
    //               valuePath: "getTask.Tasks.stepName"
    //             }
    //           ]
    //         },
    //         {
    //           key: "breadcrumb",
    //           label: "breadcrumb",
    //           configType: "BREADCRUMB",
    //           rownumber: 3,
    //           columnnumber: 1,
    //           valuePath: "",
    //           steps: [
    //             {
    //               seq: 1,
    //               tabName: "BREADCRUMB1",
    //               tabScreens: [
    //                 {

    //                   key: "username",
    //                   label: "Unamename",
    //                   configType: "BO",
    //                   rownumber: 1,
    //                   columnnumber: 1,
    //                   valuePath: "getuser.UserObj.username"
    //                 },
    //                 {

    //                   key: "date",
    //                   label: "Date",
    //                   configType: "BO",
    //                   rownumber: 1,
    //                   columnnumber: 2,
    //                   valuePath: "getuser.UserObj.date"
    //                 },
    //                 {

    //                   key: "steps_table1",
    //                   label: "table1",
    //                   configType: "TABLE",
    //                   rownumber: 2,
    //                   columnnumber: 1,
    //                   configColums: [
    //                     {
    //                       seq: 1,
    //                       columnName: "Task Name",
    //                       valuePath: "getTask.Tasks.name"
    //                     },
    //                     {
    //                       seq: 2,
    //                       columnName: "Step Name",
    //                       valuePath: "getTask.Tasks.stepName"
    //                     }
    //                   ]
    //                 },
    //               ]
    //             },
    //             {
    //               seq: 2,
    //               tabName: "BREADCRUMB2",
    //               tabScreens: [
    //                 {
    //                   key: "BREADCRUMB2-tab-breadcrumb",
    //                   label: "",

    //                   configType: "TAB",
    //                   rownumber: 1,
    //                   columnnumber: 1,
    //                   valuePath: "",
    //                   tabs: [
    //                     {
    //                       seq: 1,
    //                       tabName: "tab1 breadcrumb",
    //                       tabScreens: [
    //                         {

    //                           key: "usernamebreadcrumb",
    //                           label: "Unamename breadcrumb",
    //                           configType: "BO",
    //                           rownumber: 1,
    //                           columnnumber: 1,
    //                           valuePath: "getuser.UserObj.username"
    //                         }
    //                       ]
    //                     },
    //                     {
    //                       seq: 2,
    //                       tabName: "tab2 breadcrumb",
    //                       tabScreens: [
    //                         {

    //                           key: "tab2-breadcrumb-table1",
    //                           label: "table1",
    //                           configType: "TABLE",
    //                           rownumber: 1,
    //                           columnnumber: 1,
    //                           configColums: [
    //                             {
    //                               seq: 1,
    //                               columnName: "Task Name",
    //                               valuePath: "getTask.Tasks.name"
    //                             },
    //                             {
    //                               seq: 2,
    //                               columnName: "Step Name",
    //                               valuePath: "getTask.Tasks.stepName"
    //                             }
    //                           ]
    //                         }
    //                       ]
    //                     }
    //                   ]
    //                 },
    //               ]
    //             },

    //           ]
    //         },
    //       ]
    //     }
    //   ]
    // },
    // {
    //   key: "LABLE",
    //   label: "LABLE Text",
    //   configType: "LABLE",
    //   labelType: "HEAD", //HEAD 18 bold or NORMAL 15
    //   rownumber: 5,
    //   columnnumber: 1,
    //   valuePath: "getuser.UserObj.username"
    // },
    // {
    //   key: "BUTTON",
    //   label: "BUTTON Text",
    //   configType: "BUTTON",
    //   buttonType: "primary", //primary .....
    //   rownumber: 6,
    //   columnnumber: 1,
    //   valuePath: "",
    //   action: ["savecreateUser", "getUsers"]
    // },
    // {

    //   key: "table1",
    //   label: "table1",
    //   configType: "TABLE",
    //   rownumber: 7,
    //   columnnumber: 1,
    //   configColums: [
    //     {
    //       seq: 1,
    //       columnName: "Task Name",
    //       valuePath: "getTask.Tasks.name"
    //     },
    //     {
    //       seq: 2,
    //       columnName: "Step Name",
    //       valuePath: "getTask.Tasks.stepName"
    //     }
    //   ]
    // },
    {
      key: "Employee",
      label: "user infor",
      configType: "LIST_TILE",
      rownumber: 8,
      columnnumber: 1,
      valuePath: "Employee", // Employee
      card: [
        {
          key: "emp_lname", //imageId
          seq: "1",
          valuePath: "Employee.emp_lname", /// da931a80-59a9-44f5-80c2-876b76f6da71
          configType: "image_input",
          value: "{filehubUrlImage}{valuePath}", // https://mocha.centerapp.io/FileService/downloadFile?fileId=da931a80-59a9-44f5-80c2-876b76f6da71
        },
        {
          key: "emp_name",
          seq: "2",
          valuePath: "Employee.emp_name",
          configType: "text",
          value: "xxx",
        },
        {
          key: "emp_lname",
          seq: "3",
          valuePath: "Employee.emp_lname",
          configType: "text",
          value: "xxx",
        },
      ],
    },
    // , {
    //   key: "user",
    //   label: "user information",
    //   configType: "LIST_CARD",
    //   rownumber: 9,
    //   columnnumber: 1,
    //   valuePath: "UserObjs", // Employee
    //   card: [
    //     {
    //       seq: "1",
    //       valuePath: "imageId", //UserObjs.imageId
    //       configType: "image",
    //       value: "{filehubUrlImage}{valuePath}"
    //     },
    //     {
    //       seq: "2",
    //       valuePath: "firstname", //UserObjs.firstname //Employee.emp_name
    //       configType: "text",
    //       value: "xxx"
    //     },
    //     {
    //       seq: "3",
    //       valuePath: "department", //UserObjs.department
    //       configType: "text",
    //       value: "xxx"
    //     }
    //   ]
    // }
    // , {
    //   key: "user_LIST_TABLE",
    //   label: "user information",
    //   configType: "LIST_TABLE",
    //   rownumber: 10,
    //   columnnumber: 1,
    //   valuePath: "UserObjs",
    //   configColums: [
    //     {
    //       seq: 1,
    //       columnName: "First Name",
    //       valuePath: "UserObjs.fName"
    //     },
    //     {
    //       seq: 2,
    //       columnName: "Last Name",
    //       valuePath: "UserObjs.lName"
    //     }
    //   ]
    // },
    // {
    //   key: "user",
    //   label: "user information",
    //   configType: "LIST",
    //   rownumber: 11,
    //   columnnumber: 1,
    //   valuePath: "UserObjs",
    //   card: [
    //     {
    //       key:"imageId",
    //       seq: "1",
    //       valuePath: "imageId",
    //       configType: "image",
    //       value: "{filehubUrlImage}{valuePath}"
    //     },
    //     {
    //       key:"firstname",
    //       seq: "2",
    //       valuePath: "firstname",
    //       configType: "text",
    //       value: "xxx"
    //     },
    //     {
    //       key:"department",
    //       seq: "3",
    //       valuePath: "department",
    //       configType: "text",
    //       value: "xxx"
    //     }
    //   ]
    // }
  ],
  screensEdit: [
    {
      key: "emp_id",
      label: "Id",
      configType: "BO",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "Employee.emp_id",
      // displayView: "Read Only",
      //-Show as Label  => เทียบเท่าไม่มีค่า => แสดง label บน input
      //-Input Text Read Only =>  แสดง label บน input แต่เป็น read only
    },
    {
      key: "emp_name",
      label: "First name",
      configType: "BO",
      rownumber: 1,
      columnnumber: 2,
      valuePath: "Employee.emp_name",
    },
    {
      key: "emp_lname",
      label: "Last name",
      configType: "BO",
      rownumber: 2,
      columnnumber: 1,
      valuePath: "Employee.emp_lname",
      inputType: "image_input",
    },
    // {
    //   key: "breadcrumb",
    //   label: "breadcrumb",
    //   configType: "BREADCRUMB",
    //   rownumber: 3,
    //   columnnumber: 1,
    //   valuePath: "",
    //   steps: [
    //     {
    //       seq: 1,
    //       tabName: "step1",
    //       tabScreens: [
    //         {

    //           key: "username",
    //           label: "Unamename",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         }
    //       ]
    //     },
    //     {
    //       seq: 2,
    //       tabName: "step2",
    //       tabScreens: [
    //         {

    //           key: "username",
    //           label: "Unamename",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         }
    //       ]
    //     },
    //   ]
    // },
    //  {
    //   key: "tab",
    //   label: "",
    //   configType: "TAB",
    //   rownumber: 3,
    //   columnnumber: 1,
    //   valuePath: "",
    //   tabs: [
    //     {
    //       seq: 1,
    //       tabName: "tab1",
    //       tabScreens: [
    //         {

    //           key: "username",
    //           label: "Unamename",
    //           configType: "BO",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           valuePath: "getuser.UserObj.username"
    //         }
    //       ]
    //     },
    //     {
    //       seq: 2,
    //       tabName: "tab2",
    //       tabScreens: [
    //         {

    //           key: "table1",
    //           label: "table1",
    //           configType: "TABLE",
    //           rownumber: 1,
    //           columnnumber: 1,
    //           configColums: [
    //             {
    //               seq: 1,
    //               columnName: "Task Name",
    //               valuePath: "getTask.Tasks.name"
    //             },
    //             {
    //               seq: 2,
    //               columnName: "Step Name",
    //               valuePath: "getTask.Tasks.stepName"
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   key: "LABLE",
    //   label: "LABLE Text",
    //   configType: "LABLE",
    //   labelType: "HEAD", //HEAD 18 bold or NORMAL 15
    //   rownumber: 4,
    //   columnnumber: 1,
    //   valuePath: "UserObj.username"
    // },
    {
      key: "button",
      label: "save",
      configType: "BUTTON",
      buttonType: "primary", //primary .....
      rownumber: 5,
      columnnumber: 2,
      valuePath: "",
      action: ["savecreateUser", "getUsers"],
    },
  ],
};

export async function generatePageFile(
  JsonCONFIGSCREEN,
  pageName,
  datetime,
  page
) {
  /*


  js สำหรับ render/generate html,js เอาไปใส่ zero fromt end เลย

  app.js จะเป็น js ที่ใช้ สำหรับหน้าจอที่ต้อง generate ขึ้นมา

  1
  write css
  write js 
  write html => import css js
  to page-generate-file or some path

  document.getElementById("app").textContent;


  2 return {htmltext,csstext,jstext}

  */

  // console.log("JsonCONFIGSCREEN", JsonCONFIGSCREEN)
  let { htmltext, container, jstext, cssText } = await MyComponent(
    JsonCONFIGSCREEN,
    pageName,
    page,
    datetime
  );

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  const rootElement = document.getElementById("app");
  removeAllChildNodes(rootElement);
  rootElement.appendChild(container);

  await new Promise((resolve) => setTimeout(resolve, 1000));
  let screen = "";
  for (let a = 0; a < JsonCONFIGSCREEN.screens.length; a++) {
    if (JsonCONFIGSCREEN.screens[a].configType.indexOf("LIST") != -1) {
      screen = JsonCONFIGSCREEN.screens[a];
    }
  }

  let pageContentId = `page__content_${pageName}_${screen.configType}_${screen.key}_${datetime}`;

  let pageContent = document.getElementsByClassName("page__content")[0];
  pageContent.id = pageContentId;
  const div2Content = pageContent.outerHTML;
  // console.log("div2Content", div2Content);

  let { functionName, jsText } = transformText(
    JsonCONFIGSCREEN,
    jstext,
    datetime,
    pageContentId,
    pageName,
    page
  );
  // console.log("jstext", jsText);
  // console.log("functionName", functionName);

  let datetimeGen = datetime;
  return { div2Content, functionName, jsText, cssText, datetimeGen };
}

function transformText(
  JsonCONFIGSCREEN,
  input,
  datetime,
  pageContentId,
  pageName,
  page
) {
  let editPageName = page.editPage.pageName;
  let addPageName = page.addPage.pageName;
  let mainPageName = page.mainPage.pageName;

  let OBJECT_NAME = JsonCONFIGSCREEN.bo.map((item) => item.boName);
  const functionName = `initLoad_${pageName}_${OBJECT_NAME}_${datetime}`;
  let jsText = input.replace(
    /function (\w+)\(/g,
    `function $1_${pageName}_${OBJECT_NAME[0]}_${datetime}(`
  );

  let jsTextTemp = jsText;

  let jsonconfifStr = JSON.stringify(JsonCONFIGSCREEN);
  let text = `
    let JSON_CONFIG_SCREEN = ${jsonconfifStr};
  `;
  const resultArr = jsTextTemp.split(`{_JSONCONFIG_SCREEN_}`);
  jsTextTemp = resultArr.join(text);

  let fns = [
    "initLoad",
    "MyComponent",
    "clearScreenContent",
    "handleUpdateListTileClick",
    "handlebackButtonClick",
    "updateListTileScreens",
    "createListTileContainer",
    "handleInsertListTileClick",
    "fetchAndRenderUpdatedData",
    "renderListTileContainer",
    "renderTabScreens",
    "handleTabsClick",
    "clearTabContent",
    "handleBreadcrumbStepClick",
    "renderTabsScreenContainer",
    "renderBreadcrumbScreens",
    "renderButtonScreens",
    "renderBOScreens",
    "setFileList",
    "getSelectMain",
    "fetchData",
    "extractFieldNames",
    "getPrimaryKeyColumns",
    "getNewUUID",
    "getMainAndChildBoKeyFromJsonConfig",
    "getMainAndChildTableKeyFromJsonConfig",
    "isMainBoName",
    "setChildFkUuidFromMainInput",
    "transformBoData",
    "fetchDataFromServer",
    "handleClickEdit",
    "handleClickRemoveItems",
    "handleClickRemoveItem",
    "handleClickRemove",
    "afterLoadMFD",
    "clickEditItemFn",
    "fetchToken",
    "uploadFileWithToken",
    "clickDeleteItemFn",
    "clickSaveItemFn",
    "clickSaveNewItemFn",
    "handleClickAdd",
    "paginationContainer",
    "handleGotoAddPage",
    "beforeLoadMFD",
    "beforeSearchMFD",
    "onChangeCheckBox",
    "onChangeSelectAllCheckBox",
    "handleCheckBoxSelectOnPaginationChanged",
    "transformChildsData",
    "toCamelCase",
    "getColumnNamesWithForeignKey",
    "getBoFKNameMappingWithTableForeignKey",
    "getNewDataArrayWithFk",
    "renderTableOneToMany",
    "renderRowsTable",
    "clearTableData",
    "beforeLoadChilds",
    "clickSearchChildFn",
    "collectTableData",
    "handleClickRowEdit",
    "handleClickCheckBoxRow",
    "handleChangeCheckboxAll",
    "handleClearBtn",

    "renderListCardContainer",
    "createListCardContainer",
    "updateListCardScreens",

    "updateListScreens",
    "createListContainer",
    "renderListContainer",

    "renderListTableContainer",
    "createListTableContainer",
    "updateListTableScreens",
    "addFlexTextClassIfLong",
  ];
  let propsStatic = [
    "ACTION_TYPE",
    "FILE_HUB_URL_IMAGE",
    "SCREENS_EDIT_WITH_POSITION",
    "FILE_LIST",
    "JSON_CONFIG_SCREEN",
    "INIT_ALREADY",
    "FN_HANDLE_CLICK_EDIT",
    "FN_HANDLE_CLICK_REMOVE",
    "LIMIT",
    "TOTAL_PAGE",
    "CURRENT_PAGE",
    "INPUT_CHILD_OBJECT",
    "UPDATE_CHILD_OBJECT",
    "DELETE_CHILD_OBJECT",
    "ROW_DATA",
    "SELECTED_ROWS",
  ];

  let screenKey = "";
  let screenChildKey = "";
  for (let a = 0; a < JsonCONFIGSCREEN.screens.length; a++) {
    if (JsonCONFIGSCREEN.screens[a].configType.indexOf("LIST") != -1) {
      screenKey = JsonCONFIGSCREEN.screens[a].key;
    }
  }

  for (let a = 0; a < JsonCONFIGSCREEN.screensEdit.length; a++) {
    if (JsonCONFIGSCREEN.screensEdit[a].configType == "TABLE") {
      screenChildKey = JsonCONFIGSCREEN.screensEdit[a].key;
    }
  }

  let propsReplaceMore = [
    { from: `{{FLOW_NAME}}`, to: `${JsonCONFIGSCREEN.flow.flowName}` },
    { from: `{{SCREEN_KEY}}`, to: `${screenKey}` },
    { from: `{{PAGE_CONTENT_ID}}`, to: `${pageContentId}` },
    { from: `{{PAGE_NAME}}`, to: `${pageName}` },
    { from: `{{CLICK_BACK}}`, to: `myScope.pageGo('${mainPageName}');` },
    { from: `{{CLICK_ADD}}`, to: `myScope.pageGo('${addPageName}');` },
    { from: `{{CLICK_EDIT}}`, to: `myScope.pageGo('${editPageName}');` },
    { from: `{{PAGE_NAME_EDIT}}`, to: `${editPageName}` },
    { from: `{{SCREEN_CHILD_KEY}}`, to: `${screenChildKey}` },
  ];

  for (let a = 0; a < fns.length; a++) {
    const resultArr = jsTextTemp.split(`${fns[a]}(`);
    jsTextTemp = resultArr.join(
      `${fns[a]}_${pageName}_${OBJECT_NAME[0]}_${datetime}(`
    );
  }

  for (let a = 0; a < propsStatic.length; a++) {
    const resultArr = jsTextTemp.split(`${propsStatic[a]}`);
    jsTextTemp = resultArr.join(
      `${propsStatic[a]}_${pageName}_${OBJECT_NAME[0]}_${datetime}`
    );
  }

  for (let a = 0; a < propsReplaceMore.length; a++) {
    const resultArr = jsTextTemp.split(`${propsReplaceMore[a].from}`);
    jsTextTemp = resultArr.join(`${propsReplaceMore[a].to}`);
  }

  jsText = jsTextTemp;
  // console.log(jsText)
  return { functionName, jsText };
}

/*
 TAB มี child ได้ทุกอย่าง
 TABLE มี child ได้แค่ column ตัวเอง
 BO
 BREADCRUMB  มี child ได้ทุกอย่าง
 BUTTON
 LABLE

 ICON
 IAMGE

 getuser.UserObjs =[
    {
        imageId:"xxx",
        fistname:"xxx",
        department:"xxx"
    },
    {
        imageId:"xxx",
        fistname:"xxx",
        department:"xxx"
    },
    {
        imageId:"xxx",
        fistname:"xxx",
        department:"xxx"
    }
]


กด add เปิดหน้า edit screen ส่งค่า ?
กด edit เปิดหน้า edit screen ส่งค่า ?
กด delete เปิด confirm modal (check box => delete many)


"dataRefer": {
    "type": "table,list",
    "datas": [
      {
        "cloumnShow": "เพศชาย",
        "cloumnValue": "man"
      },
      {
        "cloumnShow": "เพศหญิง",
        "cloumnValue": "woman"
      }
    ],
    "dataconnect": {
      "connection": {},
      "tableName": "xxx",
      "columnShow": "xxx",
      "columnValue": "xxx",
      "condition": "xxx",
      "advanceSearch": "xxx"
    }
  }
  

*/

async function MyComponent(JsonCONFIGSCREEN, pageName, page, datetime) {
  // const rootElement = document.getElementById('app');
  const mainContainer = document.createElement("div");
  mainContainer.id = "mainContainer";
  const container = document.createElement("div");
  container.id = "container";

  let maxRow = 0;
  let maxColumn = 0;
  JsonCONFIGSCREEN.screens.forEach((screen) => {
    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);
  });

  container.style.display = "grid";
  container.style.gridAutoRows = "auto";
  container.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

  let filehubUrlImage = JsonCONFIGSCREEN.config.filehubUrlImage ?? "";

  // หา screens ที่มีการระบุ rownumber และ columnnumber
  const screensWithPosition = JsonCONFIGSCREEN.screens.filter(
    (screen) => screen.rownumber && screen.columnnumber
  );
  const screensEditWithPosition = JsonCONFIGSCREEN.screensEdit.filter(
    (screen) => screen.rownumber && screen.columnnumber
  );

  let mainConfigType = "";
  screensWithPosition.forEach((screen, index) => {
    // console.log("screen", screen)
    const { rownumber, columnnumber } = screen;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage);
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === BREADCRUMP) {
      renderBreadcrumbScreens(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TAB) {
      renderTabsScreenContainer(screenElement, container, screen, maxColumn);
    } else if (screen.configType === "LIST_TABLE") {
      // console.log("screen :: ", screen);
      renderListTableContainer(
        mainContainer,
        screenElement,
        screen,
        maxColumn,
        filehubUrlImage,
        screensEditWithPosition,
        JsonCONFIGSCREEN,
        pageName,
        page,
        datetime
      );
      mainConfigType = screen.configType;
    } else if (screen.configType === "LIST_CARD") {
      renderListCardContainer(
        mainContainer,
        screenElement,
        screen,
        maxColumn,
        filehubUrlImage,
        screensEditWithPosition,
        JsonCONFIGSCREEN,
        pageName,
        page
      );
      mainConfigType = screen.configType;
    } else if (screen.configType === "LIST") {
      renderListContainer(
        mainContainer,
        screenElement,
        screen,
        maxColumn,
        filehubUrlImage,
        screensEditWithPosition,
        JsonCONFIGSCREEN,
        pageName,
        page
      );
      mainConfigType = screen.configType;
    } else if (screen.configType === LIST_TILE) {
      renderListTileContainer(
        mainContainer,
        screenElement,
        screen,
        index,
        maxColumn,
        filehubUrlImage,
        screensEditWithPosition,
        JsonCONFIGSCREEN,
        pageName,
        page
      );
      mainConfigType = screen.configType;
    }

    container.appendChild(screenElement);
    mainContainer.appendChild(container);
  });

  let jsTextMain = jsText;
  if (mainConfigType === "LIST_TABLE") {
    if (pageName == "mainPage") {
      jsTextMain = jsTextListTableMain;
    } else if (pageName == "addPage") {
      jsTextMain = jsTextListTableAdd;
    } else if (pageName == "editPage") {
      jsTextMain = jsTextListTableEdit;
    }
  } else if (mainConfigType === "LIST_CARD") {
    if (pageName == "mainPage") {
      jsTextMain = jsTextListCardMain;
    } else if (pageName == "addPage") {
      jsTextMain = jsTextListCardAdd;
    } else if (pageName == "editPage") {
      jsTextMain = jsTextListCardEdit;
    }
  } else if (mainConfigType === LIST) {
    if (pageName == "mainPage") {
      jsTextMain = jsTextListsMain;
    } else if (pageName == "addPage") {
      jsTextMain = jsTextListsAdd;
    } else if (pageName == "editPage") {
      jsTextMain = jsTextListsEdit;
    }
  } else if (mainConfigType === LIST_TILE) {
    jsTextMain = jsTextListTile;
    if (pageName == "mainPage") {
      jsTextMain = jsTextListTileMain;
    } else if (pageName == "addPage") {
      jsTextMain = jsTextListTileAdd;
    } else if (pageName == "editPage") {
      jsTextMain = jsTextListTileEdit;
    }
  }
  return {
    htmltext: $(mainContainer).html(),
    container: mainContainer,
    jstext: jsTextMain,
    cssText: cssText,
  };
}

var con = {
  refTemplate: "OneToOne",
  flow: {
    appId: "c734fcc0-8067-4ac8-b6d7-af46781a9682",
    appName: "TestZero2",
    flowId: "C2474273-4ff8202c-6669-4b0c-84c1-36a34ba23018",
    flowName: "flow_Employee_3",
    snapVersion: "v.1.0.0",
    runtimeName:
      "C2474273-4ff8202c-6669-4b0c-84c1-36a34ba23018.TestZero2.flow_Employee_3.v.1.0.0.jar",
  },
  bo: [
    {
      boName: "Employee",
      fields: [
        {
          fieldName: "emp_id",
          type: "String",
        },
        {
          fieldName: "emp_name",
          type: "String",
        },
        {
          fieldName: "emp_lname",
          type: "String",
        },
      ],
    },
  ],
  mapping: [
    {
      pathFrom: "Employee.emp_id",
      pathTo: "test_employee.emp_id",
    },
    {
      pathFrom: "Employee.emp_name",
      pathTo: "test_employee.emp_name",
    },
    {
      pathFrom: "Employee.emp_lname",
      pathTo: "test_employee.emp_lname",
    },
  ],
  table: [
    {
      tableName: "test_employee",
      search:
        "test_employee.emp_name || test_employee.emp_lname like '%search%'",
      customWhere: "",
      defaultOrderby:
        "order by test_employee.emp_name asc, test_employee.emp_lname asc",
      columns: [
        {
          column: "emp_id",
          type: "string",
          primaryKey: true,
          whereKey: true,
          foreignKey: "",
        },
        {
          column: "emp_name",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "emp_lname",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
      ],
    },
  ],
  config: {
    filehubUrlImage:
      "https://mocha.centerapp.io/FileService/downloadFile?fileId=da931a80-59a9-44f5-80c2-876b76f6da71",
    microFlowUrl: "https://test.oneweb.tech/MicroflowRest/v2/DoAction",
  },
  screens: [
    {
      key: "TestCust",
      label: "TestCust",
      configType: "LIST_CARD",
      rownumber: 0,
      columnnumber: 0,
      valuePath: "TestCust",
      card: [
        {
          key: "cust_id",
          seq: "1",
          valuePath: "TestCust.cust_id",
          configType: "text",
          value: "TestCust.cust_id",
        },
        {
          key: "cust_name",
          seq: "2",
          valuePath: "TestCust.cust_name",
          configType: "text",
          value: "TestCust.cust_name",
        },
        {
          key: "cust_lastname",
          seq: "3",
          valuePath: "TestCust.cust_lastname",
          configType: "text",
          value: "TestCust.cust_lastname",
        },
      ],
    },
  ],
  screensEdit: [
    {
      key: "cust_id",
      label: "cust_id",
      configType: "BO",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "TestCust.cust_id",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "Lorem ipsum dolor sit",
      hidden: "N",
      dataRefer: {
        type: "list",
        datas: [
          {
            key: "test",
            value: "test",
            isDefault: "N",
          },
        ],
        dataconnect: null,
      },
    },
    {
      key: "cust_name",
      label: "cust_name",
      configType: "BO",
      rownumber: 1,
      columnnumber: 2,
      valuePath: "TestCust.cust_name",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "Lorem ipsum dolor sit",
      hidden: "N",
      dataRefer: {
        type: "table",
        datas: null,
        dataconnect: {
          connection: "postgresql://zero:zo1cdYwX@10.1.102.26:5437/onewebdb",
          tableName: "tsdfsdf",
          columnShow: "cdfgdfg",
          columnValue: "clumn_23234",
          condition: "condition_value",
          advanceSearch: "advance_search",
        },
      },
    },
    {
      key: "cust_lastname",
      label: "cust_lastname",
      configType: "BO",
      rownumber: 2,
      columnnumber: 1,
      valuePath: "TestCust.cust_lastname",
    },
  ],
};

var test = {
  refTemplate: "OneToOne",
  flow: {
    appId: "0447d645-215b-4bfe-b752-03ec9a7551fd",
    appName: "testlocalzero8",
    flowId: "B13315381-a3d82cf7-b107-4af5-acfe-9a550421131e",
    flowName: "flow_product_31",
    snapVersion: "v.1.0.0",
    runtimeName:
      "B13315381-a3d82cf7-b107-4af5-acfe-9a550421131e.testlocalzero8.flow_product_31.v.1.0.0.jar",
  },
  config: {
    filehubUrlImage:
      "https://mocha.centerapp.io/FileService/downloadFile?fileId=",
    microFlowUrl: "https://test.oneweb.tech/MicroflowRest/v2/DoAction",
    centerUser: "mocha_system@gmail.com",
    centerPassword: "P@55w0rD1234",
    centerDomain: "https://mocha.centerapp.io",
  },
  screens: [
    {
      key: "product",
      label: "product",
      configType: "LIST_TILE",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "product",
      card: [
        {
          seq: "1",
          valuePath: "product.productImageID",
          key: "productImageID",
          configType: "image_input",
          value: "{filehubUrlImage}{valuePath}",
        },
        {
          seq: "2",
          valuePath: "product.productName",
          key: "productName",
          configType: "text",
          value: "product.productName",
        },
        {
          seq: "3",
          valuePath: "product.productDetail",
          key: "productDetail",
          configType: "text",
          value: "product.productDetail",
        },
      ],
    },
  ],
  screensEdit: [
    {
      key: "id",
      label: "id",
      configType: "BO",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "product.id",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productName",
      label: "productName",
      configType: "BO",
      rownumber: 1,
      columnnumber: 2,
      valuePath: "product.productName",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productDetail",
      label: "productDetail",
      configType: "BO",
      rownumber: 2,
      columnnumber: 1,
      valuePath: "product.productDetail",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productImageID",
      label: "productImageID",
      configType: "BO",
      rownumber: 2,
      columnnumber: 2,
      valuePath: "product.productImageID",
      inputType: "image_input",
      displayView: "display_image",
      displayEdit: "Upload Image",
      hidden: "N",
    },
    {
      key: "Button",
      label: "Button",
      configType: "BUTTON",
      rownumber: 3,
      columnnumber: 2,
    },
  ],
  bo: [
    {
      boName: "product",
      fields: [
        {
          fieldName: "id",
          type: "String",
        },
        {
          fieldName: "productName",
          type: "String",
        },
        {
          fieldName: "productDetail",
          type: "String",
        },
        {
          fieldName: "productImageID",
          type: "String",
        },
      ],
    },
  ],
  mapping: [
    {
      pathFrom: "product.id",
      pathTo: "products.id",
    },
    {
      pathFrom: "product.productName",
      pathTo: "products.product_name",
    },
    {
      pathFrom: "product.productDetail",
      pathTo: "products.product_detail",
    },
    {
      pathFrom: "product.productImageID",
      pathTo: "products.product_image_id",
    },
  ],
  table: [
    {
      tableName: "products",
      search:
        "products.product_name || products.product_detail like '%search%'",
      customWhere: "",
      defaultOrderby:
        "order by products.product_name asc, products.product_detail asc",
      columns: [
        {
          column: "id",
          type: "string",
          primaryKey: true,
          whereKey: true,
          foreignKey: "",
        },
        {
          column: "product_name",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "product_detail",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "product_image_id",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
      ],
    },
  ],
};
var test2 = {
  refTemplate: "OneToMany",
  flow: {
    appId: "dd33f0d6-e156-4713-8063-f232262a78f3",
    appName: "testlocalzero14",
    flowId: "G9805243-7981fe4c-d147-4513-9ae3-b7f4227a9579",
    flowName: "flow_product_23",
    snapVersion: "v.1.0.0",
    runtimeName:
      "G9805243-7981fe4c-d147-4513-9ae3-b7f4227a9579.testlocalzero14.flow_product_23.v.1.0.0.jar",
  },
  config: {
    filehubUrlImage:
      "https://mocha.centerapp.io/FileService/downloadFile?fileId=",
    microFlowUrl: "https://test.oneweb.tech/MicroflowRest/v2/DoAction",
    centerUser: "mocha_system@gmail.com",
    centerPassword: "P@55w0rD1234",
    centerDomain: "https://mocha.centerapp.io",
  },
  screens: [
    {
      key: "product",
      label: "product",
      configType: "LIST_TABLE",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "product",
      configColums: [
        {
          seq: 1,
          columnName: "productImageID",
          valuePath: "product.productImageID",
        },
        {
          seq: 2,
          columnName: "productName",
          valuePath: "product.productName",
        },
        {
          seq: 3,
          columnName: "productDetail",
          valuePath: "product.productDetail",
        },
        {
          seq: 4,
          columnName: "productPrice",
          valuePath: "product.productPrice",
        },
      ],
    },
  ],
  screensEdit: [
    {
      key: "id",
      label: "id",
      configType: "BO",
      rownumber: 1,
      columnnumber: 1,
      valuePath: "product.id",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productName",
      label: "productName",
      configType: "BO",
      rownumber: 1,
      columnnumber: 2,
      valuePath: "product.productName",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productDetail",
      label: "productDetail",
      configType: "BO",
      rownumber: 2,
      columnnumber: 1,
      valuePath: "product.productDetail",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productPrice",
      label: "productPrice",
      configType: "BO",
      rownumber: 2,
      columnnumber: 2,
      valuePath: "product.productPrice",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productCnt",
      label: "productCnt",
      configType: "BO",
      rownumber: 3,
      columnnumber: 1,
      valuePath: "product.productCnt",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "isProd",
      label: "isProd",
      configType: "BO",
      rownumber: 3,
      columnnumber: 2,
      valuePath: "product.isProd",
      inputType: "text",
      displayView: "show_as_label",
      displayEdit: "text",
      hidden: "N",
    },
    {
      key: "productImageID",
      label: "productImageID",
      configType: "BO",
      rownumber: 4,
      columnnumber: 1,
      valuePath: "product.productImageID",
      inputType: "image_input",
      displayView: "display_image",
      displayEdit: "Upload Image",
      hidden: "N",
    },
    {
      key: "productItem",
      label: "productitem",
      configType: "TABLE",
      rownumber: 6,
      columnnumber: 1,
      valuePath: "product.productItem",
      configColums: [
        {
          seq: 1,
          columnName: "itemID",
          valuePath: "product.productItem.itemID",
        },
        {
          seq: 2,
          columnName: "productID",
          valuePath: "product.productItem.productID",
        },
        {
          seq: 3,
          columnName: "itemName",
          valuePath: "product.productItem.itemName",
        },
        {
          seq: 4,
          columnName: "itemPrice",
          valuePath: "product.productItem.itemPrice",
        },
      ],
    },
    {
      key: "Button",
      label: "Button",
      configType: "BUTTON",
      rownumber: 7,
      columnnumber: 1,
    },
  ],
  bo: [
    {
      boName: "product",
      fields: [
        {
          fieldName: "id",
          type: "String",
        },
        {
          fieldName: "productName",
          type: "String",
        },
        {
          fieldName: "productDetail",
          type: "String",
        },
        {
          fieldName: "productPrice",
          type: "Decimal",
        },
        {
          fieldName: "productCnt",
          type: "Integer",
        },
        {
          fieldName: "isProd",
          type: "Boolean",
        },
        {
          fieldName: "productImageID",
          type: "String",
        },
        {
          fieldName: "productItem",
          type: "Object",
          fields: [
            {
              fieldName: "itemID",
              type: "String",
            },
            {
              fieldName: "productID",
              type: "String",
            },
            {
              fieldName: "itemName",
              type: "String",
            },
            {
              fieldName: "itemPrice",
              type: "Decimal",
            },
          ],
        },
      ],
    },
  ],
  mapping: [
    {
      pathFrom: "product.id",
      pathTo: "products.id",
    },
    {
      pathFrom: "product.productName",
      pathTo: "products.product_name",
    },
    {
      pathFrom: "product.productDetail",
      pathTo: "products.product_detail",
    },
    {
      pathFrom: "product.productImageID",
      pathTo: "products.product_image_id",
    },
    {
      pathFrom: "product.productPrice",
      pathTo: "products.price",
    },
    {
      pathFrom: "product.productCnt",
      pathTo: "products.cnt_",
    },
    {
      pathFrom: "product.isProd",
      pathTo: "products.is_prod",
    },
    {
      pathFrom: "product.productItem.itemID",
      pathTo: "product_item.id",
    },
    {
      pathFrom: "product.productItem.productID",
      pathTo: "product_item.product_id",
    },
    {
      pathFrom: "product.productItem.itemName",
      pathTo: "product_item.item_name",
    },
    {
      pathFrom: "product.productItem.itemPrice",
      pathTo: "product_item.item_price",
    },
  ],
  table: [
    {
      tableName: "products",
      search:
        "products.product_name || products.product_detail like '%search%'",
      customWhere: "",
      defaultOrderby:
        "order by products.product_name asc, products.product_detail asc",
      columns: [
        {
          column: "id",
          type: "string",
          primaryKey: true,
          whereKey: true,
          foreignKey: "",
        },
        {
          column: "product_name",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "product_detail",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "product_image_id",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "price",
          type: "float64",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "cnt_",
          type: "int64",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "is_prod",
          type: "bool",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
      ],
    },
    {
      tableName: "product_item",
      search: "",
      customWhere: "",
      defaultOrderby: "",
      columns: [
        {
          column: "id",
          type: "string",
          primaryKey: true,
          whereKey: true,
          foreignKey: "",
        },
        {
          column: "product_id",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "products.id",
        },
        {
          column: "item_name",
          type: "string",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
        {
          column: "item_price",
          type: "float64",
          primaryKey: false,
          whereKey: false,
          foreignKey: "",
        },
      ],
    },
  ],
};

export const testFn = async () => {
  const getPageName = (pageName) => {
    let storyName = "st1";
    if (checkEmpty(pageName)) {
      return "page_" + storyName + "_" + "1";
    } else {
      return "page_" + storyName + "_" + pageName + "_" + "1";
    }
  };

  let datetime = new Date()
    .toISOString()
    .replace(/[-:.TZ]/g, "")
    .slice(0, 14);
  /**
   * begin generate edit page, add page before
   */
  let page = {
    editPage: {
      pageName: getPageName("editPage"),
      componentId: null,
      initFn: "",
    },
    addPage: {
      pageName: getPageName("addPage"),
      componentId: null,
      initFn: "",
    },
    mainPage: {
      pageName: getPageName("mainPage"),
      componentId: null,
      initFn: "",
    },
  };

  var x = {};
  let convertHtml, htmlToGen;
  x = await generatePageFile(test2, "editPage", datetime, page);
  console.log("editPage div2Content =>", x.div2Content);
  // console.log("editPage jsText =>", x.jsText)
  convertHtml = window?.CONVERT_HTML_TO_OBJECT(
    x.div2Content,
    getPageName("editPage")
  );
  // htmlToGen = JSON.stringify(convertHtml?.page?.pageNodes);
  // console.log("editPage htmlToGen =>", convertHtml?.page?.pageNodes)

  x = await generatePageFile(test2, "addPage", datetime, page);
  console.log("addPage div2Content =>", x.div2Content);
  convertHtml = window?.CONVERT_HTML_TO_OBJECT(
    x.div2Content,
    getPageName("addPage")
  );
  // htmlToGen = JSON.stringify(convertHtml?.page?.pageNodes);
  // console.log("addPage htmlToGen =>", convertHtml?.page?.pageNodes)

  x = await generatePageFile(test2, "mainPage", datetime, page);
  console.log("mainPage div2Content =>", x.div2Content);
  convertHtml = window?.CONVERT_HTML_TO_OBJECT(
    x.div2Content,
    getPageName("mainPage")
  );
  // htmlToGen = JSON.stringify(convertHtml?.page?.pageNodes);
  // console.log("mainPage htmlToGen =>", convertHtml?.page?.pageNodes)

  // let useKey = ""
  // for (let a = 0; a < test.screens.length; a++) {
  //   if (test.screens[a].configType.indexOf("LIST") != -1) {
  //     useKey = test.screens[a].key
  //   }
  // }
  // console.log("useKey", useKey)

  // let testHeader = testHeaderFn();
};
