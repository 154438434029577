import React from 'react'
import StoryEmptyCreateIcon from 'src/assets/svg/story-empty-create-icon'
import StoryEmptyCreateBG from "../../../../assets/png/story-empty-item.png"
import styles from './StoryEmptyItem.module.scss'

type Props = {
  onClickCreate: () => void;
  txtName: string;
}

function StoryEmptyItem({onClickCreate, txtName}: Props) {
  return (
    <div className={styles.storyEmptyContainer}>
        <div className={styles.bgImgWrapper}>
            <img className={styles.bgImg} src={StoryEmptyCreateBG} alt="story_bg"  />
            <p className={styles.bgTxt}>{txtName}</p>
        </div>
        <div className={styles.createBtn} onClick={onClickCreate} >
            <StoryEmptyCreateIcon />
        </div>
    </div>
  )
}

export default StoryEmptyItem