import React from "react";

const StoryEditEntGenPageTab = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 14"
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6381 1.80919L11.4281 1.38394C11.2694 0.907354 10.8232 0.585938 10.3215 0.585938H9.282C8.98975 0.585938 8.799 0.892771 8.92908 1.15469L9.22483 1.7526H8.02083L7.83825 1.38394C7.67958 0.907354 7.23392 0.585938 6.73167 0.585938H5.69625C5.404 0.585938 5.21325 0.892771 5.34333 1.15469L5.63908 1.7526H4.445L4.02267 0.908521C3.92408 0.710771 3.72167 0.585938 3.50058 0.585938H2.33392C1.0465 0.586521 0 1.63302 0 2.91985V10.5032C0 12.1114 1.30842 13.4199 2.91667 13.4199H11.0833C12.6916 13.4199 14 12.1114 14 10.5032V4.66985C14 3.25177 12.9815 2.06935 11.6381 1.80919ZM1.16667 2.91985C1.16667 2.27644 1.68992 1.75319 2.33333 1.75319H3.1395L3.56183 2.59727C3.66042 2.79502 3.86283 2.91985 4.08392 2.91985H11.0839C11.8434 2.91985 12.4851 3.40927 12.7266 4.08652H1.16667V2.91985ZM12.8333 10.5032C12.8333 11.468 12.0482 12.2532 11.0833 12.2532H2.91667C1.95183 12.2532 1.16667 11.468 1.16667 10.5032V5.25319H12.8333V10.5032Z"
        fill="#374957"
      />
    </svg>
  );
};
export default StoryEditEntGenPageTab;
