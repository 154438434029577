import React from "react";

const DatabaseConnectionIcon = (props) => {
    const { className, style, width, height,fill,opacity} = props;
    return (
        <svg 
            width={width || "13" }
            height={height || "12"} 
            className={className}
            viewBox="0 0 13 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g clipPath="url(#clip0_2817_18570)">
                <path d="M12.5004 5.75002C12.4992 5.41292 12.4219 5.08044 12.2742 4.77741C12.1265 4.47439 11.9122 4.20864 11.6474 4.00002C12.0138 3.71123 12.2809 3.31529 12.4114 2.86744C12.5419 2.41959 12.5294 1.94216 12.3756 1.50176C12.2218 1.06136 11.9344 0.679956 11.5534 0.410746C11.1724 0.141536 10.7169 -0.00204847 10.2504 2.20825e-05H2.75044C2.28396 -0.00204847 1.82847 0.141536 1.4475 0.410746C1.06654 0.679956 0.779101 1.06136 0.625291 1.50176C0.471482 1.94216 0.458971 2.41959 0.589503 2.86744C0.720036 3.31529 0.987102 3.71123 1.35344 4.00002C1.08772 4.20781 0.872798 4.47338 0.724988 4.77659C0.577179 5.07981 0.500361 5.4127 0.500361 5.75002C0.500361 6.08734 0.577179 6.42024 0.724988 6.72345C0.872798 7.02666 1.08772 7.29224 1.35344 7.50002C0.987102 7.78882 0.720036 8.18475 0.589503 8.6326C0.458971 9.08045 0.471482 9.55788 0.625291 9.99828C0.779101 10.4387 1.06654 10.8201 1.4475 11.0893C1.82847 11.3585 2.28396 11.5021 2.75044 
                11.5H10.2504C10.7169 11.5021 11.1724 11.3585 11.5534 11.0893C11.9344 10.8201 12.2218 10.4387 12.3756 9.99828C12.5294 9.55788 12.5419 9.08045 12.4114 8.6326C12.2809 8.18475 12.0138 7.78882 11.6474 7.50002C11.9122 7.2914 12.1265 7.02566 12.2742 6.72263C12.4219 6.41961 12.4992 6.08713 12.5004 5.75002ZM1.50044 2.25002C1.50044 1.9185 1.63214 1.60056 1.86656 1.36614C2.10098 1.13172 2.41892 1.00002 2.75044 1.00002H3.00044V1.50002C3.00044 1.63263 3.05312 1.75981 3.14689 1.85358C3.24066 1.94734 3.36784 2.00002 3.50044 2.00002C3.63305 2.00002 3.76023 1.94734 3.854 1.85358C3.94777 1.75981 4.00044 1.63263 4.00044 1.50002V1.00002H5.00044V1.50002C5.00044 1.63263 5.05312 1.75981 5.14689 1.85358C5.24066 1.94734 5.36784 2.00002 5.50044 2.00002C5.63305 2.00002 5.76023 1.94734 5.854 1.85358C5.94777 1.75981 6.00044 1.63263 6.00044 1.50002V1.00002H10.2504C10.582 1.00002 10.8999 1.13172 11.1343 1.36614C11.3687 1.60056 11.5004 1.9185 11.5004 2.25002C11.5004 2.58154 11.3687 2.89949 11.1343 3.13391C10.8999 3.36833 
                10.582 3.50002 10.2504 3.50002H2.75044C2.41892 3.50002 2.10098 3.36833 1.86656 3.13391C1.63214 2.89949 1.50044 2.58154 1.50044 2.25002ZM11.5004 9.25002C11.5004 9.58154 11.3687 9.89948 11.1343 10.1339C10.8999 10.3683 10.582 10.5 10.2504 10.5H2.75044C2.41892 10.5 2.10098 10.3683 1.86656 10.1339C1.63214 9.89948 1.50044 9.58154 1.50044 9.25002C1.50044 8.9185 1.63214 8.60056 1.86656 8.36614C2.10098 8.13172 2.41892 8.00002 2.75044 8.00002H3.00044V8.50002C3.00044 8.63263 3.05312 8.75981 3.14689 8.85358C3.24066 8.94734 3.36784 9.00002 3.50044 9.00002C3.63305 9.00002 3.76023 8.94734 3.854 8.85358C3.94777 8.75981 4.00044 8.63263 4.00044 8.50002V8.00002H5.00044V8.50002C5.00044 8.63263 5.05312 8.75981 5.14689 8.85358C5.24066 8.94734 5.36784 9.00002 5.50044 9.00002C5.63305 9.00002 5.76023 8.94734 5.854 8.85358C5.94777 8.75981 6.00044 8.63263 6.00044 
                8.50002V8.00002H10.2504C10.582 8.00002 10.8999 8.13172 11.1343 8.36614C11.3687 8.60056 11.5004 8.9185 11.5004 9.25002ZM2.75044 7.00002C2.41892 7.00002 2.10098 6.86833 1.86656 6.63391C1.63214 6.39949 1.50044 6.08154 1.50044 5.75002C1.50044 5.4185 1.63214 5.10056 1.86656 4.86614C2.10098 4.63172 2.41892 4.50002 2.75044 4.50002H3.00044V5.00002C3.00044 5.13263 3.05312 5.25981 3.14689 5.35358C3.24066 5.44734 3.36784 5.50002 3.50044 5.50002C3.63305 5.50002 3.76023 5.44734 3.854 5.35358C3.94777 5.25981 4.00044 5.13263 4.00044 5.00002V4.50002H5.00044V5.00002C5.00044 5.13263 5.05312 5.25981 5.14689 5.35358C5.24066 5.44734 5.36784 5.50002 5.50044 5.50002C5.63305 5.50002 5.76023 5.44734 5.854 5.35358C5.94777 5.25981 6.00044 5.13263 6.00044 5.00002V4.50002H10.2504C10.582 4.50002 10.8999 4.63172 11.1343 4.86614C11.3687 5.10056 11.5004 5.4185 11.5004 5.75002C11.5004 6.08154 11.3687 6.39949 11.1343 6.63391C10.8999 6.86833 10.582 7.00002 10.2504 7.00002H2.75044Z" fill={fill || "#1C87F2"} fillOpacity={opacity ? opacity : ""} />
            </g>
            <defs>
                <clipPath id="clip0_2817_18570">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DatabaseConnectionIcon;
