import React, { useContext, useEffect, useState } from "react";
import styles from "../ControlItem.module.scss";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import { Button, Input } from "antd";
import TextTool from "../../TextTool";
import {
  getPositionToolInMainPanel,
  getToolPosition,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import { getMainRowIdFromCellId } from "../../../utils/controls.util";
import { HEAD, NORMAL } from "src/constants/PageConfig";

type Props = {
  cellId?: string;
  onUpdate: (detail: any, cellId: string) => void;
  onRemove: (cellId: string) => void;
  configType?: string;
  text?: string;
  labelType?: string;
};

function ControlText({ cellId, onUpdate, onRemove, text, labelType }: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  let [isEdit, setIsEdit] = useState<boolean>(false);
  let [editText, setEditText] = useState<string>("");
  let [textState, setTextState] = useState<string>(text || "Show text");
  let [labelTypeState, setLabelTypeState] = useState<string>(
    labelType || NORMAL
  );

  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);

  //MODAL DELETE STATE
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  //TOOL STATE
  let [isOpenTool, setIsOpenTool] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();

  //CHECK PROPS
  useEffect(() => {
    console.log(`ControlText props cellId: ${cellId}`);
  }, []);

  useEffect(() => {
    if(genPageContext.isVersionGenerated && !genPageContext.isTextItemSeted){
      setIsFirstLoad(true)
    }
  },[genPageContext.isVersionGenerated])

  useEffect(() => {
    if(!genPageContext.isTextItemSeted && isFirstLoad){
      let component = genPageContext.versionSubComponent.filter((item:any) => item.detail && item.detail.configType === 'LABLE')
      console.log("component %%%%%%%% ", component)
      // text labelType configType
      if(component.length > 0 ){
        let colId = component[0].colId
        let textLabel = component[0].detail.text
        let textType = component[0].detail.labelType
        setTextState(textLabel);
        onUpdate({ text: editText, labelType: textType }, colId as string);
        genPageContext.setIsTextItemSeted(true)
        setIsFirstLoad(false)
      }
    }

  },[isFirstLoad])

  useEffect(() => {
    console.log(
      `genPageContext.itemFocusOnConfigPage: ${genPageContext.itemFocusOnConfigPage}`
    );
    if (
      !genPageContext.itemFocusOnConfigPage ||
      !genPageContext.itemFocusOnConfigPage.includes(cellId as string)
    ) {
      setIsOpenTool(false);
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //ACTION HANDLER
  const onClickTextComponent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (cellId?.includes("main")) {
      let cellFocusEle = document.getElementById(cellId);
      let outerDivEle = document.getElementById("gridPanel");
      let { top, left } = getToolPosition(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage(cellId + "_control");
    } else {
      console.log("onClickTextComponent");
      let cellFocusEle = document.getElementById(cellId as string); //GET CELL POSITION BY ELEMENT_ID
      let mainRowId = getMainRowIdFromCellId(cellId as string);
      let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID
      let { top, left } = getPositionToolInMainPanel(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage((cellId + "_control") as string);
    }
  };

  const onChangeEditHandler = (input: string) => {
    setEditText(input);
  };

  const onSubmitEditHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setTextState(editText);
    setIsEdit(false);
    setIsOpenTool(false);
    onUpdate({ text: editText, labelType: labelTypeState }, cellId as string);
  };

  const onCancelEditHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsEdit(false);
  };

  const onSelectLabelTypeFromButtonTool = (labelType: string) => {
    console.log("onSelectLabelTypeFromButtonTool labelType => ", labelType);
    setLabelTypeState(labelType);
    onUpdate({ text: textState, labelType }, cellId as string);
  };

  //MODAL DELETE HANDLER
  const onOkDeleteHandler = () => {
    console.log("onOkDeleteHandler");
    onRemove(cellId as string);
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  const onCancelDeleteHandler = () => {
    console.log("onCancelDeleteHandler input ");
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  //TOOL HANDLER
  const onClickRemoveText = () => {
    console.log("onClickRemoveText cellId => ", cellId);
    setIsOpenDeleteModal(true);
  };

  const onClickEditText = () => {
    setEditText(textState);
    setIsEdit(true);
    setIsOpenTool(false);
  };

  return (
    <>
      <div className={styles.textContainer} onClick={onClickTextComponent}>
        {isEdit ? (
          <div className={styles.controlTextEditWrapper}>
            <div className={styles.inputRow}>
              <Input
                className={styles.inputText}
                value={editText}
                onChange={(e) => onChangeEditHandler(e.currentTarget.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className={styles.inputRow}>
              <Button
                className={styles.btnCancel}
                onClick={onCancelEditHandler}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={styles.btnConfirm}
                onClick={onSubmitEditHandler}
              >
                Confirm
              </Button>
            </div>
          </div>
        ) : (
          <h5
            style={{
              textAlign: "center",
              fontWeight: labelTypeState === HEAD ? "bold" : "normal",
            }}
          >
            {textState}
          </h5>
        )}
      </div>

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Text?",
          description: `Are you sure you want to delete this Text? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onOkDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />

      {/* TOOL */}
      <TextTool
        isShow={isOpenTool}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onRemove={onClickRemoveText}
        onEdit={onClickEditText}
        onSelectLabelType={onSelectLabelTypeFromButtonTool}
        labelType={labelTypeState}
      />
    </>
  );
}

export default ControlText;
