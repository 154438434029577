import React from "react";
import styles from "./NavigationBar.module.scss";
import AppsIcon from "src/assets/svg/apps";
import { Avatar } from "antd";

type NavigationBarProps = {
    header: any
}


const NavigationBar = ({ header }: NavigationBarProps) => {

    return (
        <></>
        // <div className={styles.headerContainer}>
        //     <div className={styles.headerContent}>
        //         <div className={styles.header}>
        //             <div className={styles.headerLeft}>
        //                 <label>{header}</label>
        //             </div>
        //             <div className={styles.headerRight}>
        //                 <AppsIcon />
        //                 <div className={styles.avatarContainer}>
        //                     <Avatar size={32} src="https://www.ladyissue.com/wp-content/uploads/2020/03/HSTPTID7.jpg" alt="Avatar" />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default NavigationBar;