export let cssText = `
.menu {
    display: flex;
}

.circle-icon {
    width: 30px;
    height: 30px;
    background: #cccccc;
    border-radius: 50%;
}

.circle-sub-icon {
    width: 18px;
    height: 18px;
    background: #959191;
    border-radius: 50%;
}

.horizontal-ul .icon-menu{
    width: 20px;
    height: 20px;
    padding: unset;
}

.div-icon{
    min-width: 30px;
    max-width: 30px;
    width: 30px;
    height: 30px;
    /* padding: 5px 0; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.div-icon:hover{
    background: #fff;
}

.icon-menu {
    width: 30px;
    height: 30px;
}

.icon-sub-menu {
    width: 16px;
    height: 16px;
}

.circle-icon:empty:before {
    content: "";
}

.circle-sub-icon:empty:before {
    content: "";
}

.layout-sider-trigger{
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 20px;
}

.anticon{
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    transition: all 0.2s;
}

.anticon svg {
    display: inline-block;
}

.rotated{
    transform: rotate(180deg);
}

.vertical {
    flex: 0 0 56px;
    max-width: 56px;
    min-width: 56px;
    width: 56px;
    box-sizing: border-box;
    transition: all 0.3s;
}

.vertical.collap > .layout-sider-trigger .anticon{
    transform: rotate(180deg);
}

.vertical.collap .vertical-ul {
    /* align-items: flex-start; */
}

.vertical-ul {
    width: auto;
}
.vertical:not(.collap) .vertical-ul > li {
    width: 46px;
    height: 40px;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
}

.vertical-ul li {
    position: relative;
    display: block !important;
    text-overflow: ellipsis;
    margin: 5px !important;
    inset-inline-start: 0;
    /* padding-inline: calc(50% - 8px - 4px); */
    padding: 6.5px !important;
    height: auto;
    line-height: 40px;
    max-height: 40px;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
}

.vertical .vertical-ul li span {
    margin-left: 0px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 2em;
    display: none;
}

.vertical.collap .vertical-ul li span {
    margin-left: 10px;
    display:block;
}

.vertical.collap .vertical-ul li:hover:not(.dropdown-menu-toggle) {
    background: #fff;
}

.vertical:not(.collap) .vertical-ul li:hover {
    background: #fff;
}

.vertical.collap .vertical-ul li:hover image{
    filter: sepia(1) saturate(10000%) hue-rotate(180deg) brightness(0) saturate(0%) invert(0.92) sepia(1) saturate(10000%) hue-rotate(-180deg) brightness(1.1);
}

.vertical:not(.collap) .vertical-ul li:not(.dropdown-menu-toggle):hover .tooltip-menu{
    display: block;
}

.vertical-ul li .d-flex{
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 2em;
}

.vertical.collap .dropdown-menu-toggle{
    line-height: unset;
    max-height: unset;
}

.tooltip-menu {
    inset: 101px auto auto 55px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 1070;
    display: none;
    width: max-content;
    max-width: 250px;
    visibility: visible;
    transform-origin: var(--arrow-x, 50%) var(--arrow-y, 50%);
}

.tooltip-arrow {
    position: absolute;
    top: 17px;
    left: 0px;
    transform: translateY(-50%) translateX(-100%) rotate(-90deg);
    z-index: 1;
    display: block;
    pointer-events: none;
    width: 16px;
    height: 16px;
    overflow: hidden;
}

.tooltip-arrow:before {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    width: 16px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.88);
    clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z');
    content: "";
}

.tooltip-arrow::after {
    content: "";
    position: absolute;
    width: 8.970562748477143px;
    height: 8.970562748477143px;
    bottom: 0;
    inset-inline: 0;
    margin: auto;
    border-radius: 0 0 2px 0;
    transform: translateY(50%) rotate(-135deg);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
    z-index: 0;
    background: transparent;
}

.tooltip-inner{
    min-width: 32px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: start;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
}

.horizontal {
    width: 100%;
    height: fit-content !important;
    flex-direction: row !important;
    padding-top: 80px !important;
}

.horizontal-ul {
    flex-direction: row !important;
    justify-content: center!important;
    gap: 10px;
}

.horizontal-ul li:hover{
   background: #fff!important;
   border-radius: 5px;
   cursor: pointer;
}

.horizontal-ul li{
    gap: 10px;
    margin: 0 !important;
    padding-left: 5px!important;
}

.horizontal-logo {
    margin-right: 50px;
}

.vertical-logo {
    margin-bottom: 50px;
}

.navbar-menu-container {
    height: 100%;
    align-items: center;
    padding: 63px 0px 20px 0px;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    background: #F2F2F2;
    box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
    z-index: 1000;
}

.ul-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: unset;
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* gap: 20px; */
}

.ul-menu li a {
    /* color: #707070!important; */
    font-size: 18px !important;
  }

.ul-menu li:not(.dropdown-menu-toggle) {
    /* margin: 0 !important; */
    text-align: left !important;
    display: flex !important; 
    align-items: center;
}

.vertical:not(.collap) .dropdown-menu-toggle:hover .dropdown-menu-list {
    display: block !important;
    visibility: visible;
    opacity: 1;
    height: 100% !important;
    left: 50px;
    top: 0;
    overflow: unset!important;
    max-width: 250px;
}

.vertical:not(.collap) .dropdown-menu-toggle:hover .dropdown-menu-list span {
    display: block;
}

.vertical:not(.collap) .dropdown-menu-list .dropdown-menu-ul, .dropdown-menu-horizon .dropdown-menu-ul {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);;
    border-radius: 10px;
    padding: 8px;
    padding-top: 8px!important;
    min-width: 250px;
    /* backdrop-filter: blur(20px); */
    background: rgba(242, 242, 242, .97);
}

.toggle-sub-menu{
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-left: 10px;
    transform: translateY(-50%);
}

.span-toggle-submenu{
    position: relative;
    padding-right: 20px;
    width: 100%;
}

.dropdown-horizon:hover .dropdown-menu-horizon {
    top: 30px;
    display: block !important;
    visibility: visible;
    opacity: 1;
}

.dropdown-horizon .d-flex .div-icon{
    margin-right: 10px;
}


.logo-menu-container {
    flex: 0 0 auto;
}

.vertical .dropdown-menu-ul {
    visibility: visible !important;
    opacity: 1 !important;
    position: unset !important;
    background: #F2F2F2;
    left: 0;
    display: block !important;
}

.dropdown-menu-horizon .dropdown-menu-ul {
    visibility: visible !important;
    opacity: 1 !important;
    position: unset !important;
    /* backdrop-filter: blur(20px); */
    background: rgba(242, 242, 242, .97);
    left: 0;
    display: block !important;
    box-sizing: border-box;
}

.dropdown-menu-horizon .dropdown-menu-ul li{
    border-radius: 6px;
    box-sizing: border-box;
}

.dropdown-menu-horizon .dropdown-menu-ul li:hover{
    background-color: #fff;
}

.dropdown-menu-ul>li {
    overflow: hidden;
    overflow-wrap: break-word;
    height: 40px;
    line-height: 40px;
}

.dropdown-menu-ul>li>a {
    /* margin-left: 20px; */
}

.vertical.collap .dropdown-menu-ul {
    padding-top: unset !important;
    box-shadow: unset !important;
    min-width: unset !important;
    width: 95%;
    min-width: 250px;
    padding-top: unset !important;
    padding-left: unset;
    padding-right: unset;
}

.vertical.collap .dropdown-menu-ul {
    /* min-width: 250px;
    padding-top: unset !important; */
}

.vertical.collap .vertical-ul li > .dropdown-menu-list {
    display: block;
    transition: all .3s;
    position: unset;
    opacity: 1;
    width: 100%;
    z-index: 99;
    /* padding-left: 10px;  */
    margin-top: 10px;
    box-shadow: unset !important;
    left: 0;
}

.vertical:not(.collap) .vertical-ul li > .dropdown-menu-list {
    display: none;
    overflow: hidden;
    position: absolute;
}

.vertical:not(.collap) .vertical-ul li > .dropdown-menu-list li{
    margin: 0 !important;
}


.vertical.collap .vertical-ul li > .dropdown-menu-list .dropdown-menu-ul li {
    margin: 0 !important;
    max-height: 34px;
}

.dropdown-menu-list a{
    margin-left: 10px;
}


.dropdown-menu-horizon {
    display: none;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 99;
    min-width: 250px;
    box-sizing: border-box;
    border-radius: 13px;
}
`;