import React from "react";

function AdvancedIcon() {
  return (
    <div style={{
      width: '100px',
      height: '100px',
    }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width='100px'
        height='100px'
        fill="none"
        viewBox="0 0 483 483"
      >
        <path
          fill="url(#paint0_linear_25_565)"
          fillRule="evenodd"
          d="M167.821 212.581a10.3 10.3 0 0 0-9.299-3.99 10.3 10.3 0 0 0-8.226 5.895l-88.8 177.671a10.63 10.63 0 0 0 .464 10.333 10.61 10.61 0 0 0 9.028 5.038h123.65a10.23 10.23 0 0 0 9.492-5.872c26.673-55.155 10.508-139.019-36.309-189.075"
          clipRule="evenodd"
        ></path>
        <path
          fill="#2684FF"
          fillRule="evenodd"
          d="M232.951 50.981c-45.014 69.311-50.209 157.228-13.672 231.366l59.613 119.31a10.61 10.61 0 0 0 9.492 5.871h123.628c3.678 0 7.094-1.906 9.028-5.037a10.63 10.63 0 0 0 .465-10.333S255.183 59.241 251.003 50.914a10.024 10.024 0 0 0-18.052.067"
          clipRule="evenodd"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_25_565"
            x1="216.487"
            x2="91.933"
            y1="240"
            y2="373.704"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0052CC"></stop>
            <stop offset="0.923" stopColor="#2684FF"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
export default AdvancedIcon;
