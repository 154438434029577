import React, { useEffect, useState } from 'react';
import styles from './CheckoutForm.module.scss';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { plansSelector } from 'src/store/slices/plansSlice';
import { getAuthorizationPmtx } from 'src/services/util.service';


const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    currPlan,
    billing_cycle,
  } = useSelector(plansSelector);

  useEffect(() => {
  }, [currPlan, billing_cycle])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      alert(!stripe || !elements);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement); // เปลี่ยนเป็น CardNumberElement

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: {
        name: (event.target as any).name_on_card.value,
        email: (event.target as any).email.value,
      },
    });


    if (error) {
      setErrorMessage(error.message || 'เกิดข้อผิดพลาดในการชำระเงิน');
      alert(error.message);
      return;
    }

    let _priceId = currPlan?.one_time_prices.price_id;
    if (billing_cycle === 'monthly') {
      _priceId = currPlan?.monthly_prices.price_id;
    }

    //@ts-ignore
    let api = window['env']['REACT_APP_ZERO_URL'];
    const response = await fetch(api + '/pmtx-api/v1/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationPmtx(),
      },
      body: JSON.stringify({
        name_on_card: (event.target as any).name_on_card.value,
        email: (event.target as any).email.value,
        payment_method_id: paymentMethod?.id,
        price_id: _priceId,
        billing_cycle: billing_cycle,
      }),
    });

    const result = await response.json();

    if (result.error) {
      setErrorMessage(result.error);
      alert(result.error);

    } else {
      alert("successful subscription");
      // Handle successful subscription (e.g. redirect to success page)
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.paymentForm}>

      <div className={styles.formRow}>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" placeholder="john.doe@gmail.com" required />
      </div>

      <div className={styles.formRow}>
        <label htmlFor="name_on_card">Name on card</label>
        <input type="text" name="name_on_card" placeholder="John Doe" required />
      </div>

      {/* 
      <div className={styles.formRow}>
      <label htmlFor="name_on_card">Email</label>
      <CardElement
        id="card-element"
        options={{
          style: {
            base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
          hidePostalCode: true, // ปิดการกรอกที่อยู่หรือรหัสไปรษณีย์
        }}
      />
      </div> */}

      <div className={styles.cardNumber}>
        <label>Card Number</label>
        <CardNumberElement
          className={styles.element}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      <div className={styles.expiryCvc}>
        <div className={styles.expiry}>
          <label>Expiry Date</label>
          <CardExpiryElement
            className={styles.element}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
        <div className={styles.cvc}>
          <label>CVC</label>
          <CardCvcElement
            className={styles.element}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      </div>


      {errorMessage && <div className={styles.cardError} role="alert">{errorMessage}</div>}

      <button type="submit" className={styles.submitButton} disabled={!stripe}>Pay Now</button>
    </form>
  );
};

export default CheckoutForm;
