import React from "react";

const StoryEditDbDelete = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      className={className}
      style={style}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3507_2814)">
        <path
          d="M10.25 0C4.72305 0 0.25 4.47254 0.25 10C0.25 15.527 4.72254 20 10.25 20C15.777 20 20.25 15.5275 20.25 10C20.25 4.47305 15.7775 0 10.25 0ZM10.25 18.6667C5.47121 18.6667 1.58332 14.7788 1.58332 10C1.58332 5.22117 5.47121 1.33332 10.25 1.33332C15.0288 1.33332 18.9167 5.22121 18.9167 10C18.9167 14.7788 15.0288 18.6667 10.25 18.6667Z"
        />
        <path
          d="M11.1936 10.0009L14.3181 6.87636C14.5784 6.61605 14.5784 6.1939 14.3181 5.93354C14.0578 5.67323 13.6356 5.67323 13.3753 5.93354L10.2508 9.05804L7.12636 5.93354C6.86604 5.67319 6.44397 5.67319 6.18354 5.93354C5.92319 6.19386 5.92319 6.61601 6.18354 6.87636L9.308 10.0009L6.18354 13.1253C5.92319 13.3857 5.92319 13.8078 6.18354 14.0682C6.4439 14.3285 6.86601 14.3285 7.12636 14.0682L10.2508 10.9437L13.3753 14.0682C13.6355 14.3285 14.0576 14.3285 14.3181 14.0682C14.5784 13.8078 14.5784 13.3857 14.3181 13.1253L11.1936 10.0009Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3507_2814">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default StoryEditDbDelete;
