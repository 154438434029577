import React, { useEffect, useRef, useState } from "react";
import styles from "../functional-spec/FunctionalSpec.module.scss";

import ChatMessage from "../../chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../constants/genstep.constant";

type FunctionalSpecProps = {
    messages: any;
    setMessages: any;
};


function FunctionalSpec({ messages, setMessages }: FunctionalSpecProps) {

    // useEffect(() => {
    //     if (messages) {
            // console.log("FunctionalSpec resultStories>>> ::.", messages);
    //     }
    // }, [messages])

    return (
        <div className={styles.chatContainer} >

            {messages?.map((msg: any, index: number) => (
                <ChatMessage
                    key={index}
                    agent={msg?.agent}
                    message={msg?.text}
                    profileImage={msg?.profileImage}
                    chatColor={msg?.chatColor}
                    step={GENERATE_STEP.FUNCTIONAL}
                />
            ))}
        </div>
        
    );
}
export default FunctionalSpec;