import React, { useState, useEffect } from "react";
import { Button, Modal, Tree, Input, Space, notification } from "antd";
import styles from "../modal/ConfigurationModal.module.scss";
import {
  BellFilled,
  UserOutlined,
  CloudUploadOutlined,
  LinkOutlined,
  PlusCircleTwoTone,
  SaveOutlined,
  HarmonyOSOutlined,
} from "@ant-design/icons";
import DeleteIcon from "src/assets/svg/delete";
import AddIcon from "src/assets/svg/addIcon";
import ModalTreeLink from "../modal/ModalTreeLink";
import ModalUpload from "./ModalUpload";
import ModalDelete from "src/page/component/database-connection/modal/ModalDelete";
import { ConfigDetail, ListMenu } from "../models/AppComponentModel";
import error from "../../../assets/png/error.png";

const { TreeNode } = Tree;

const UploadFileType = {
  Notification: "Upload Notification",
  Logo: "Upload Logo",
  Profile: "Upload User Profile",
};

type Props = {
  isOpenModal: boolean;
  configDetail: { titleName: string; detail: ConfigDetail };
  onSave: (bodyConfig: any, titleName: string) => void;
  onClose: () => void;
};

function ConfigurationModal({
  isOpenModal,
  configDetail,
  onSave,
  onClose,
}: Props) {
  let [open, setOpen] = useState(isOpenModal);
  let [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  let [isOpenModalLink, setIsOpenModalLink] = useState<boolean>(false);

  let [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  let [isOpenModalUpload, setIsOpenModalUpload] = useState<boolean>(false);
  let [isNameModalUpload, setIsNameModalUpload] = useState<string>("");
  let [inputUploadID, setInputUploadID] = useState<string>("");

  let [inputListMenu, setInputListMenu] = useState<ListMenu[]>([]);
  let [openSubMenuInputAdd, setOpenSubMenuInputAdd] = useState<string>("");

  let [indexMenu, setIndexMenu] = useState<string>("");
  let [inputMenuName, setInputMenuName] = useState<string>("");

  let [indexSubMenu, setIndexSubMenu] = useState<string>("");
  let [inputSubMenuName, setInputSubMenu] = useState<string>("");
  let [keyName, setKeyName] = useState<string>("");

  let [configUrl, setConfigUrl] = useState<string>("");
  let [configStoryID, setConfigStoryID] = useState<string>("");
  let [configComponentID, setConfigComponentID] = useState<string>("");
  let [configComponentName, setConfigComponentName] = useState<string>("");
  let [imagePath, setImagePath] = useState<string>("");
  let [imageId, setImageId] = useState<string>("");

  let [removeIndexMenu, setRemoveIndexMenu] = useState<
    [string, string, string]
  >(["", "", ""]);

  useEffect(() => {
    setOpen(isOpenModal);
    setOpenModalAdd(false);
    setInputListMenu(configDetail.detail.list_menu);
    console.log("configDetail ", configDetail)

    if (isOpenModal) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isOpenModal]);

  const openNotification = (name: string) => {
    notification.open({
      message: <div className={styles.messageNotify}>{"Duplicate Name!"}</div>,
      description: (
        <div
          className={styles.descriptionNotify}
        >{`Field Name :  ${name} `}</div>
      ),
      icon: (
        <div className={styles.iconNotify}>
          <img src={error} alt="" width={"50px"} height={"50px"} />
        </div>
      ),
    });
  };

  const onChangeAddMenu = () => {
    const checkDuplicate = inputListMenu.find(
      (item) => item.config_name == inputMenuName
    );

    if (checkDuplicate != undefined) {
      openNotification(inputMenuName);
    } else {
      if (inputMenuName !== undefined && inputMenuName !== "") {
        setInputListMenu([
          ...inputListMenu,
          {
            index: String(inputListMenu.length + 1),
            config_name: inputMenuName,
            config_url: "",
            config_story_id: "",
            config_story_name: "",
            config_component_id: "",
            config_component_name: "",
            img_path: "",
            file_id: "",
            list_sub_menu: [],
          },
        ]);
        setInputMenuName("");
      }
    }

    setOpenModalAdd(false);
  };

  const onClickAddSubMenu = (menuName: string) => {
    if (inputSubMenuName !== undefined && inputSubMenuName !== "") {
      const index = inputListMenu.findIndex(
        (item) => item.config_name === menuName
      );
      if (index !== -1) {
        const checkDuplicate = inputListMenu[index].list_sub_menu.find(
          (item) => item.config_name == inputSubMenuName
        );

        if (checkDuplicate != undefined) {
          openNotification(inputSubMenuName);
        } else {
          inputListMenu[index].list_sub_menu.push({
            index: String(inputListMenu[index].list_sub_menu.length + 1),
            config_name: inputSubMenuName,
            config_url: "",
            config_story_id: "",
            config_story_name: "",
            config_component_id: "",
            config_component_name: "",
            img_path: "",
            file_id: ""
          });
          setInputListMenu([...inputListMenu]);
          setInputSubMenu("");
        }
      }
    }
    setOpenSubMenuInputAdd("");
  };

  const onCloseModalLink = () => {
    setIsOpenModalLink(false);
  };

  const onCloseModalUpload = () => {
    setIsOpenModalUpload(false);
  };

  const onClickOpenModalLink = (
    key: string,
    menuIndex: string,
    menuName: string,
    subMenuIndex: string,
    SubMenuName: string,
    configUrl: string,
    configStoryID: string,
    configComponentID: string,
    configComponentName: string,
    img_path: string,
    file_id: string
  ) => {
    setKeyName(key);
    setIndexMenu(menuIndex);
    setInputMenuName(menuName);
    setIndexSubMenu(subMenuIndex);
    setInputSubMenu(SubMenuName);
    setConfigUrl(configUrl);
    setConfigStoryID(configStoryID);
    setConfigComponentID(configComponentID);
    setConfigComponentName(configComponentName);
    setImagePath(img_path);
    setImageId(file_id);
    setIsOpenModalLink(true);
  };

  const onOkLink = (
    name: string,
    configUrl: string,
    configStoryID: string,
    configComponentID: string,
    imgPath: string,
    fileId: string
  ) => {
    if (keyName == "Menu") {
      const menuDetail = inputListMenu.find(
        (item) => item.index === String(indexMenu)
      );
      if (menuDetail) {
        menuDetail.config_name = name;
        menuDetail.config_url = configUrl;
        menuDetail.config_story_id = configStoryID;
        menuDetail.config_component_id = configComponentID;
        menuDetail.img_path = imgPath;
        menuDetail.file_id = fileId;
      }
    } else {
      const menuDetail = inputListMenu.find(
        (item) => item.index === String(indexMenu)
      );
      const subMenu = menuDetail?.list_sub_menu.find(
        (item) => item.index === String(indexSubMenu)
      );
      if (subMenu) {
        subMenu.config_name = name;
        subMenu.config_url = configUrl;
        subMenu.config_story_id = configStoryID;
        subMenu.config_component_id = configComponentID;
        subMenu.img_path = imgPath;
        subMenu.file_id = fileId;
      }
    }
    setIsOpenModalLink(false);
  };

  const onOkRemoveMenu = () => {
    if (removeIndexMenu[0] == "Menu") {
      const data = inputListMenu.filter(
        (item) => item.index !== removeIndexMenu[1]
      );

      setInputListMenu(data);
    } else {
      const index = inputListMenu.findIndex(
        (item) => item.index === removeIndexMenu[1]
      );
      const filteredSubMenu = inputListMenu[index].list_sub_menu.filter(
        (item) => item.index !== removeIndexMenu[2]
      );
      const updatedInputListMenu = [...inputListMenu];
      updatedInputListMenu[index].list_sub_menu = filteredSubMenu;
      setInputListMenu(updatedInputListMenu);
    }
    setOpenModalDelete(false);
  };

  const onClickRemoveMenu = (
    key: string,
    indexMenu: string,
    indexSubMenu: string
  ) => {
    removeIndexMenu[0] = key;
    removeIndexMenu[1] = indexMenu;
    removeIndexMenu[2] = indexSubMenu;
    setRemoveIndexMenu(removeIndexMenu);
  };

  const onClickOpenModalUpload = (name: string) => {
    console.log("configDetail.detail.noti_file_id : ", configDetail.detail.noti_file_id)
    if (name == UploadFileType.Notification) {
      setInputUploadID(configDetail.detail.noti_file_id);
    } else if (name == UploadFileType.Profile) {
      setInputUploadID(configDetail.detail.profile_file_id);
    } else if (name == UploadFileType.Logo) {
      setInputUploadID(configDetail.detail.logo_file_id);
    }
    setIsNameModalUpload(name);
    setIsOpenModalUpload(true);
  };

  const onOkModalUploadLogo = (uploadID: string) => {
    console.log("uploadID ", uploadID)
    if (isNameModalUpload == UploadFileType.Profile) {
      configDetail.detail.profile_file_id = uploadID;
    } else if (isNameModalUpload == UploadFileType.Logo) {
      configDetail.detail.logo_file_id = uploadID;
    } else if (isNameModalUpload == UploadFileType.Notification) {
      configDetail.detail.noti_file_id = uploadID;
    }
    setIsOpenModalUpload(false);
  };

  const onConfirmModelConfiguration = () => {
    configDetail.detail.list_menu = inputListMenu;
    onSave(configDetail.detail, configDetail.titleName);
  };

  const FooterDrawer = () => {
    return (
      <div className={styles.footerContainer}>
        <button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </button>
        <button
          className={styles.btnCreate}
          onClick={onConfirmModelConfiguration}
        >
          Continue
        </button>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={configDetail.titleName + " Configuration"}
        visible={open}
        onCancel={onClose}
        width={1000}
        style={{ height: 200 }}
        footer={<FooterDrawer />}
      >
        <div className={styles.iconHeaderContainer}>
          <div className={styles.contentHeaderContainer}>
            <Button
              type="text"
              size="large"
              icon={<AddIcon />}
              onClick={() => setOpenModalAdd(true)}
            />
            {openModalAdd && (
              <div>
                <Space.Compact>
                  <Input onChange={(e) => setInputMenuName(e.target.value)} />
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={onChangeAddMenu}
                  ></Button>
                </Space.Compact>
              </div>
            )}
            <Button
              type="text"
              size="large"
              icon={<DeleteIcon />}
              onClick={() => setOpenModalDelete(true)}
            />
          </div>
          { configDetail.titleName !== "Menu" &&
            <div className={styles.siderHeaderContainer}>
              <Button
                type="text"
                size="large"
                icon={<BellFilled />}
                onClick={() => onClickOpenModalUpload(UploadFileType.Notification)}
              />
              <Button
                type="text"
                size="large"
                icon={<UserOutlined />}
                onClick={() => onClickOpenModalUpload(UploadFileType.Profile)}
              />
              <Button
                type="text"
                size="large"
                icon={<HarmonyOSOutlined />}
                onClick={() => onClickOpenModalUpload(UploadFileType.Logo)}
              />
            </div>
          }
        </div>

        <div className={styles.configurationContainer}>
          {inputListMenu.map((itemMenu, index) => {
            return (
              <>
                <Tree
                  showLine
                  switcherIcon={false}
                  virtual={true}
                  defaultExpandAll={true}
                  // defaultExpandedKeys={["0-0"]}
                >
                  <TreeNode
                    title={
                      <div className={styles.boxMenu}>
                        <Button
                          type="text"
                          icon={<PlusCircleTwoTone />}
                          onClick={() =>
                            setOpenSubMenuInputAdd(itemMenu.config_name)
                          }
                        />
                        {openSubMenuInputAdd == itemMenu.config_name && (
                          <Space.Compact
                            style={{ width: 200, marginRight: 10 }}
                          >
                            <Input
                              onChange={(e) => setInputSubMenu(e.target.value)}
                            />
                            <Button
                              type="primary"
                              icon={<SaveOutlined />}
                              onClick={() =>
                                onClickAddSubMenu(itemMenu.config_name)
                              }
                            ></Button>
                          </Space.Compact>
                        )}
                        <span
                          style={{ marginRight: 16 }}
                          onClick={() =>
                            onClickRemoveMenu("Menu", itemMenu.index, "")
                          }
                        >
                          {itemMenu.config_name}
                        </span>
                        <Button
                          type="text"
                          icon={<LinkOutlined />}
                          onClick={() =>
                            onClickOpenModalLink(
                              "Menu",
                              itemMenu.index,
                              itemMenu.config_name,
                              "",
                              "",
                              itemMenu.config_url,
                              itemMenu.config_story_id,
                              itemMenu.config_component_id,
                              itemMenu.config_component_name,
                              itemMenu.img_path,
                              itemMenu.file_id
                            )
                          }
                        />
                      </div>
                    }
                  >
                    {itemMenu.list_sub_menu.map((item) => {
                      return (
                        <TreeNode
                          title={
                            <div className={styles.boxSubMenu}>
                              <span
                                style={{ marginRight: 16 }}
                                onClick={() =>
                                  onClickRemoveMenu(
                                    "SubMenu",
                                    itemMenu.index,
                                    item.index
                                  )
                                }
                              >
                                {item.config_name}
                              </span>
                              <Button
                                type="text"
                                icon={<LinkOutlined />}
                                onClick={() =>
                                  onClickOpenModalLink(
                                    "SubMenu",
                                    itemMenu.index,
                                    itemMenu.config_name,
                                    item.index,
                                    item.config_name,
                                    item.config_url,
                                    item.config_story_id,
                                    item.config_component_id,
                                    item.config_component_name,
                                    item.img_path,
                                    item.file_id
                                  )
                                }
                              />
                            </div>
                          }
                        />
                      );
                    })}
                  </TreeNode>
                </Tree>
              </>
            );
          })}
        </div>
      </Modal>
      <ModalTreeLink
        isOpenModalTreeLink={isOpenModalLink}
        onOk={onOkLink}
        onCancel={onCloseModalLink}
        menuDetail={{
          titleName: keyName == "Menu" ? inputMenuName : inputSubMenuName,
          configUrl: configUrl,
          configStoryID: configStoryID,
          configComponentID: configComponentID,
          configComponentName: configComponentName,
          img_path: imagePath,
          file_id: imageId
        }}
        componentType={ configDetail.titleName }
      ></ModalTreeLink>
      <ModalUpload
        isOpenModalUploadLogo={isOpenModalUpload}
        onOk={onOkModalUploadLogo}
        onCancel={onCloseModalUpload}
        // titleName={isNameModalUpload}
        detail={{
          titleName: isNameModalUpload,
          fileID: inputUploadID,
          type: configDetail.titleName,
        }}
      ></ModalUpload>
      <ModalDelete
        title="Delete data?"
        description="Are you sure want to delete this data?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => {
          setOpenModalDelete(false);
        }}
        onOk={onOkRemoveMenu}
        open={openModalDelete}
      />
    </div>
  );
}

export default ConfigurationModal;
