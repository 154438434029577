export const TAB = "TAB";
export const BREADCRUMP = "BREADCRUMP";
export const BUTTON = "BUTTON";
export const ICON = "ICON";
export const IMAGE = "IMAGE";
export const TEXT = "TEXT";
export const TABLE = "TABLE"
export const LABEL = "LABLE"
export const ONE_TO_ONE = "OneToOne"
export const ONE_TO_MANY = "OneToMany"
export const BO = "BO";

export const HEAD = "HEAD";
export const NORMAL = "NORMAL";


