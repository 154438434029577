import React, { createContext, useState } from "react";
import { GetTableAndColumnRequest } from "src/types/request.type";

type DataReferConfig = {
  id: string;
  title: string;
  input_type: string;
};
type DataReferMappingType = {
  id: string;
  bo_name: string;
  input_type: string;
  data_refer_id?: string;
};
const dbConnectorInit = {
  jndi_name: "",
  connection_url: "",
  db_type: "",
  db_type_new: "",
  host_port: "",
  host_name: "",
  db_name: "",
  db_username: "",
  db_password: "",
  type_create: "",
  asp_id: "",
  created_by: "",
  updated_by: "",
  is_active: false,
};
interface StoryEntityState {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  boConfigRowSelectedID: string;
  setBoConfigRowSelectedID: (value: string) => void;
  boConfigItems: any[];
  setBoConfigItems: (boConfigItems: any[]) => void;
  isBoConfigSave: boolean;
  setIsBoConfigSave: (value: boolean) => void;
  boConfigAction: "create" | "update";
  setBoConfigAction: (value: "create" | "update") => void;
  isBoConfigRemove: boolean;
  setIsBoConfigRemove: (value: boolean) => void;
  moreSettingItems: any[];
  setMoreSettingItems: (moreSettingItems: any[]) => void;
  dataReferConfig: DataReferConfig;
  setDataReferConfig: (value: DataReferConfig) => void;
  isMapDataReferSave: boolean;
  setIsMapDataReferSave: (value: boolean) => void;
  dataReferMapping: DataReferMappingType[];
  setDataReferMapping: (dataReferMapping: DataReferMappingType[]) => void;
  dbConnectorRequest: GetTableAndColumnRequest;
  setDbConnectorRequest: (dbConnectorReq: GetTableAndColumnRequest) => void;
  isLoadingToUpdateBo: boolean;
  setIsLoadingToUpdateBo: (value: boolean) => void;
}

const StoryEntityContext = createContext<StoryEntityState>({
  currentStep: 0,
  setCurrentStep: () => {},
  boConfigRowSelectedID: "",
  setBoConfigRowSelectedID: () => {},
  boConfigItems: [],
  setBoConfigItems: () => {},
  isBoConfigSave: false,
  setIsBoConfigSave: () => {},
  boConfigAction: "create",
  setBoConfigAction: () => {},
  isBoConfigRemove: false,
  setIsBoConfigRemove: () => {},
  moreSettingItems: [],
  setMoreSettingItems: () => {},
  dataReferConfig: { id: "", title: "", input_type: "" },
  setDataReferConfig: () => {},
  isMapDataReferSave: false,
  setIsMapDataReferSave: () => {},
  dataReferMapping: [],
  setDataReferMapping: () => {},
  dbConnectorRequest: dbConnectorInit,
  setDbConnectorRequest: () => {},
  isLoadingToUpdateBo: false,
  setIsLoadingToUpdateBo: () => {}
});

const StoryEntityProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<StoryEntityState>({
    currentStep: 0,
    setCurrentStep: (value: number) => {
      setState((prevState) => ({ ...prevState, currentStep: value }));
    },
    boConfigRowSelectedID: "",
    setBoConfigRowSelectedID: (value: string) => {
      setState((prevState) => ({ ...prevState, boConfigRowSelectedID: value }));
    },
    boConfigItems: [],
    setBoConfigItems: (boConfigItems: any[]) => {
      setState((prevState) => ({ ...prevState, boConfigItems }));
    },
    isBoConfigSave: false,
    setIsBoConfigSave: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isBoConfigSave: value }));
    },
    boConfigAction: "create",
    setBoConfigAction: (value: "create" | "update") => {
      setState((prevState) => ({ ...prevState, boConfigAction: value }));
    },
    isBoConfigRemove: false,
    setIsBoConfigRemove: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isBoConfigRemove: value }));
    },
    moreSettingItems: [],
    setMoreSettingItems: (moreSettingItems: any[]) => {
      setState((prevState) => ({ ...prevState, moreSettingItems }));
    },
    dataReferConfig: { id: "", title: "", input_type: "" },
    setDataReferConfig: (value: DataReferConfig) => {
      setState((prevState) => ({ ...prevState, dataReferConfig: value }));
    },
    isMapDataReferSave: false,
    setIsMapDataReferSave: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isMapDataReferSave: value }));
    },
    dataReferMapping: [],
    setDataReferMapping: (value: DataReferMappingType[]) => {
      setState((prevState) => ({ ...prevState, dataReferMapping: value }));
    },
    dbConnectorRequest: dbConnectorInit,
    setDbConnectorRequest: (value: GetTableAndColumnRequest) => {
      setState((prevState) => ({ ...prevState, dbConnectorRequest: value }));
    },
    isLoadingToUpdateBo: false,
    setIsLoadingToUpdateBo: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isLoadingToUpdateBo: value }));
    },
  });

  return (
    <StoryEntityContext.Provider value={state}>
      {children}
    </StoryEntityContext.Provider>
  );
};

export { StoryEntityContext, StoryEntityProvider };
