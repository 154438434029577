// GenerateContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface GenerateContextType {
    messageData: any;
    setMessageData: (messageData: any) => void;
    projectName: string;
    setProjectName: (projectName: string) => void;
    uuidApp: string;
    setUuidApp: (projectName: string) => void;
}

const GenerateContext = createContext<GenerateContextType>({
    messageData: {},
    setMessageData: () => { },
    projectName: '',
    setProjectName: () => { },
    uuidApp: '',
    setUuidApp: () => { },

});

const GenerateContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messageData, setMessageData] = useState<any>();
    const [projectName, setProjectName] = useState<string>('');
    const [uuidApp, setUuidApp] = useState<string>('');

    const contextValue: GenerateContextType = {
        messageData,
        setMessageData,
        projectName,
        setProjectName,
        uuidApp,
        setUuidApp,

    };

    return (
        <GenerateContext.Provider value={contextValue}>
            {children}
        </GenerateContext.Provider>
    );
};

// export const useData = (): GenerateContextType => {
//     const context = useContext(GenerateContext);
//     if (!context) {
//         throw new Error('useData must be used within a DataProvider');
//     }
//     return context;
// };

export { GenerateContextProvider, GenerateContext };
