import React, { useContext, useEffect, useState } from "react";
import styles from "../ControlItem.module.scss";
import { Button, Input } from "antd";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import {
  getPositionToolInMainPanel,
  getToolPosition,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import { getMainRowIdFromCellId } from "../../../utils/controls.util";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import ButtonTool from "../../ButtonTool";

type Props = {
  title?: string;
  cellId?: string;
  onUpdate: (detail: any, cellId: string) => void;
  onRemove: (cellId: string) => void;
  configType?: string;
  buttonType?: string;
};

function ControlButton({
  title,
  cellId,
  onUpdate,
  onRemove,
  buttonType,
}: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  let [btnName, setBtnName] = useState<string>(title || "");
  let [btnType, setBtnType] = useState<string>(buttonType || "none");
  let [isOpenTool, setIsOpenTool] = useState<boolean>(false);
  let [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();
  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
  
  //CHECK PROPS
  useEffect(() => {
    console.log(`ControlButton props cellId: ${cellId}, title: ${title}`);
  }, []);

  useEffect(() => {
    console.log(
      `genPageContext.itemFocusOnConfigPage: ${genPageContext.itemFocusOnConfigPage}`
    );
    if (
      !genPageContext.itemFocusOnConfigPage ||
      !genPageContext.itemFocusOnConfigPage.includes(cellId as string)
    ) {
      setIsOpenTool(false);
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  useEffect(() => {
    if(genPageContext.isVersionGenerated){
      setIsFirstLoad(true)
    }
  }, [genPageContext.isVersionGenerated])

  //SET BTN FROM VERSION SAVED
  useEffect(() => {
    if(isFirstLoad && genPageContext.isVersionGenerated){
      let component = genPageContext.versionSubComponent.filter((item:any) => 
        item.detail && item.detail.configType === 'BUTTON'
      )
      // console.log("component -+-+-+-+ ", component)
      component.forEach((item:any) => {
        setBtnName(item.detail.title)
        onSelectButtonTypeFromButtonTool(item.detail.buttonType)
      });
      setIsFirstLoad(false)
    }
  },[isFirstLoad])

  //ACTION HANDLER
  const onClickButtonComponent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (cellId?.includes("main")) {
      let cellFocusEle = document.getElementById(cellId);
      let outerDivEle = document.getElementById("gridPanel");
      let { top, left } = getToolPosition(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage(cellId + "_control");
    } else {
      console.log("onClickButtonComponent");
      let cellFocusEle = document.getElementById(cellId as string); //GET CELL POSITION BY ELEMENT_ID
      let mainRowId = getMainRowIdFromCellId(cellId as string);
      let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID
      let { top, left } = getPositionToolInMainPanel(
        cellFocusEle as HTMLElement,
        outerDivEle as HTMLElement
      );
      setPositionTool({ top, left });
      setIsOpenTool(true);
      genPageContext.setItemFocusOnConfigPage((cellId + "_control") as string);
    }
  };

  const onChangeTitleName = (title: string) => {
    setBtnName(title);
  };

  const onClickSaveEditButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsEditTitle(false);
    onUpdate({ title: btnName, buttonType: btnType }, cellId as string);
  };

  const onClickRemoveButton = () => {
    console.log("onClickRemoveButton cellId => ", cellId);
    setIsOpenDeleteModal(true);
  };

  const onClickEditButton = () => {
    setIsEditTitle(true);
    setIsOpenTool(false);
  };

  const onSelectButtonTypeFromButtonTool = (btnType: string) => {
    console.log("onSelectButtonTypeFromButtonTool btnType => ", btnType);
    setBtnType(btnType);
    onUpdate({ title: btnName, buttonType: btnType }, cellId as string);
  };

  //MODAL DELETE HANDLER
  const onOkDeleteHandler = () => {
    console.log("onOkDeleteHandler");
    onRemove(cellId as string);
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  const onCancelDeleteHandler = () => {
    console.log("onCancelDeleteHandler");
    setIsOpenDeleteModal(false);
    setIsOpenTool(false);
  };

  return (
    <>
      <div
        className={styles.buttonContainer}
        onClick={(e) => {
          onClickButtonComponent(e);
        }}
      >
        {/* <button className={styles.buttonItem}>{title}</button> */}
        {isEditTitle ? (
          <div className={styles.inputEditWrapper}>
            <Input
              value={btnName}
              onChange={(e) => onChangeTitleName(e.currentTarget.value)}
              className={styles.inputSearchApp}
              onClick={(e) => e.stopPropagation()}
            />
            <Button
              size="small"
              type="primary"
              onClick={(e) => onClickSaveEditButton(e)}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button type="primary">{btnName}</Button>
        )}
      </div>

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Button?",
          description: `Are you sure you want to delete this Button? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onOkDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />

      {/* TOOL */}
      <ButtonTool
        isShow={isOpenTool}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onRemove={onClickRemoveButton}
        onEdit={onClickEditButton}
        onSelectBtnType={onSelectButtonTypeFromButtonTool}
        btnType={btnType}
      />
    </>
  );
}

export default ControlButton;
