import React, { useRef, useState } from "react";

type Props = {
  item: any;
  itemStyles: string;
};

function BoFieldItem({ item, itemStyles }: Props) {
  let [isDragging, setIsDragging] = useState<boolean>(false);
  const dragRef = useRef<HTMLDivElement>(null);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, componentId: string) => {
    console.log("onDragStart e ", e);
    setIsDragging(true);
    e.dataTransfer.setData("text/plain", JSON.stringify({componentId, componentType: "bo"}));
  };
  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    console.log("Drag ended e ", e);
    setIsDragging(false);
  };
  return (
    <div
      key={item.id}
      className={itemStyles}
      draggable
      onDragStart={(e) => onDragStart(e, item.id)}
      onDragEnd={(e) => onDragEnd(e)}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    >
      {item.boField}
    </div>
  );
}

export default BoFieldItem;
