import renderBOScreens from "./renderBoScreens";
import renderLabelScreens from "./renderLabelScreens";
import renderButtonScreens from "./renderButtonScreens";
import renderBreadcrumbScreens from "./renderBreadcrumbScreens";
import renderTabsScreenContainer from "./renderTabsScreensContainer";
import addListTileOneToManyScreens from "./listTile/oneToMany/addListTileOnetoManyScreens";
import updateListTileOneToManyScreens from "./listTile/oneToMany/updateListTileOneToManyScreens";
import fetchDataFromServer from "./fetchDataFromServer";
import { transformBoData, getPrimaryKeyColumns } from "./fetchDataFromServer";
import { ACTION_TYPE } from "./utilsPageType";
import { CONFIG_SCREEN as JsonCONFIGSCREEN } from "src/services/page-service";
import { base64EmptyV1, base64EmptyV2 } from "./base64";
import renderImageScreens from "./renderImageScreens";
import {
  BO,
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  TAB,
} from "src/constants/PageConfig";
import renderIconScreens from "./renderIconScreens";

export default async function renderListTileContainer(
  mainContainer,
  screenElement,
  screen,
  index,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page
) {
  if (pageName == "mainPage") {
    renderListTileContainerMain(
      mainContainer,
      screenElement,
      screen,
      index,
      maxColumn,
      filehubUrlImage,
      screensEditWithPosition,
      JsonCONFIGSCREEN,
      pageName,
      page
    );
  } else if (pageName == "editPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      updateListTileOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        index,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      updateListTileScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        index,
        screen,
        pageName
      );
    }
  } else if (pageName == "addPage") {
    if (JsonCONFIGSCREEN.refTemplate === "OneToMany") {
      addListTileOneToManyScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        index,
        pageName,
        JsonCONFIGSCREEN
      );
    } else {
      addListTileScreens(
        mainContainer,
        screen,
        screensEditWithPosition,
        filehubUrlImage,
        index,
        screen,
        pageName
      );
    }
  }
}

// สร้าง form search
function createSearchForm(screen) {
  const searchForm = document.createElement("form");
  searchForm.id = "search-form-" + screen.configType + "-" + screen.key;
  searchForm.className = "search-form";

  // const searchInput = document.createElement('input');
  // searchInput.id = 'search-input-' + screen.configType + '-' + screen.key;
  // searchInput.type = 'text';
  // searchInput.placeholder = 'Search...';
  // searchInput.className = 'search-input dt-search';

  const searchIcon = document.createElement("i");
  searchIcon.className = "anticon anticon-search";
  searchIcon.style.position = "absolute";
  searchIcon.style.marginLeft = "5px";
  searchIcon.style.color = "rgba(171, 171, 171, 1)";

  const clearIcon = document.createElement("i");
  clearIcon.className = "fas fa-times clear-icon";
  clearIcon.style.display = "none";

  const searchButton = document.createElement("button");
  searchButton.id = "searchButton-" + screen.configType + "-" + screen.key;
  searchButton.setAttribute("type", "button");
  searchButton.textContent = "Search";
  searchButton.className = "ant-btn ant-btn-primary search-button";

  let searchInput = `
        <input 
            placeholder="Search..." 
            type="text" 
            id="search-input-${screen.configType}-${screen.key}"
            class="search-input dt-search" 
            pg-state="State.search"
        >
    `;

  // searchForm.appendChild(searchInput);
  searchForm.insertAdjacentHTML("beforeend", searchInput);
  searchForm.appendChild(searchIcon);
  searchForm.appendChild(clearIcon);
  searchForm.appendChild(searchButton);

  return searchForm;
}

function updateListTileScreens(
  mainContainer,
  screen,
  screensEditWithPosition,
  filehubUrlImage,
  index,
  mainScreen,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-edit-list-tile-" + screen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-containerWrapper-" + screen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  // backButton.addEventListener('click', function () { handlebackButtonClick(mainContainer, index); })

  // container.hidden = true;
  // container.style.display = 'none';

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "edit");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "edit", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === BREADCRUMP) {
      renderBreadcrumbScreens(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TAB) {
      renderTabsScreenContainer(screenElement, container, screen, maxColumn);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
  console.log("container>>> ", container);
}

function addListTileScreens(
  mainContainer,
  screen,
  screensEditWithPosition,
  filehubUrlImage,
  index,
  mainScreen,
  pageName
) {
  const container = document.createElement("div");
  container.id = "container-add-list-tile-" + screen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-container-add-Wrapper-" + screen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  // backButton.addEventListener('click', function () { handlebackButtonClick(mainContainer, index); })

  // container.hidden = true;
  // container.style.display = 'none';

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "add");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "add", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === BREADCRUMP) {
      renderBreadcrumbScreens(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TAB) {
      renderTabsScreenContainer(screenElement, container, screen, maxColumn);
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
  console.log("container>>> ", container);
}

async function renderListTileContainerMain(
  mainContainer,
  screenElement,
  screen,
  index,
  maxColumn,
  filehubUrlImage,
  screensEditWithPosition,
  JsonCONFIGSCREEN,
  pageName,
  page
) {
  let addPageName = page.addPage.pageName;

  let gridColumn = `1 / span ${maxColumn}`;
  screenElement.style.zIndex = 9;
  screenElement.style.gridColumn = gridColumn;

  // สร้าง Search Form
  const searchForm = createSearchForm(screen);
  screenElement.appendChild(searchForm);

  // สร้าง Add button
  const contentAddButton = document.createElement("div");
  contentAddButton.id = "box-content-add-button-" + screen.key;
  contentAddButton.className = "content-add-button";

  // const addButton = document.createElement('button');
  // addButton.className = 'ant-btn button';
  // // addButton.className = 'ant-btn button ant-btn-primary';
  // addButton.innerHTML = `<span class="ow ion-android-add-circle span-0bccf55774-responsive" style="font-size: 25px;"></span>`;
  // contentAddButton.appendChild(addButton);

  // let addBtnText = `<button  class="ant-btn button" style="height: 48px;">
  //         <span  class="ow ion-android-add-circle span-0bccf55774-responsive btn-icon" style="font-size: 25px;   "></span></button>`

  let addBtnText = `<div id="content-add-button-${screen.key}">
                        <a id="add-btn-icon-${screen.key}" pg-ref="${addPageName}">
                            <span class="ow ion-android-add-circle span-0bccf55774-responsive btn-icon"  style="font-size: 30px;cursor: pointer;"></span>
                        </a>
                    </div>`;

  contentAddButton.insertAdjacentHTML("beforeend", addBtnText);
  screenElement.appendChild(contentAddButton);

  // สร้าง List
  const listTileContainer = document.createElement("div");
  listTileContainer.id =
    "list-tile-container-" + screen.configType + "-" + screen.key;
  listTileContainer.className = "list-tile-container";

  // const listTileContent = document.createElement('div');
  // listTileContent.id = 'list-tile-content-' + screen.configType + '-' + screen.key;
  // listTileContent.className = 'list-tile';
  let imageKey = "";
  let contentCard = "";
  let i = 0;
  screen.card.forEach((itemp) => {
    if (itemp.configType == "image_input") {
      imageKey = itemp.key;
    } else {
      i = i + 1;
      let text = `${itemp.key} Text`;
      let weight = "bold";
      // let marginTop ="0px;"
      if (i == 1) {
        // text = "Head Text";
        // weight = "bold";
        // marginTop ="20px;"
      }
      contentCard =
        contentCard +
        `
            <div class="text-ellipsis" > 
                <span class="ng-binding" pg-store="${itemp.key}"
                style="font-weight: ${weight};" 
                pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list">${text}</span>
                <div class="text-ellipsis" id="div7"> 
                    <span style="
                    font-size: 14px;
                    color: #979797;
                    top: -5px;
                    position: relative;
                        " id="text5" class="ng-binding">${itemp.key}</span>
                </div>
            </div >
            
            `;
    }
  });

  let base64Empty = base64EmptyV1;
  // let base64Empty = base64EmptyV2
  var listTileContentText = `
    
    <div id="list-tile-content-${screen.configType}-${screen.key}" class="list-tile">
        <div class="card info-card-tile ng-scope"  style="cursor:pointer;" id="card-${screen.configType}-${screen.key}-{{$index}}"  pg-flow="Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;]" pg-trackby="$index"
            pg-store="${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list" pg-is-list="true" >
            <div class="card-content card-tile" id="card-content-${screen.configType}-${screen.key}-{{$index}}">
                <div class="imgContent-list-tile" ><img  class="image-list-tile"
                    style=" 
                        background: url(${base64Empty}) center center / cover no-repeat; 
                        background: url(&quot;{{SCOPE_URL_IMAGE}}{{Store[&quot;${JsonCONFIGSCREEN.flow.flowName}&quot;].${JsonCONFIGSCREEN.flow.flowName}_flow_output.${screen.key}_list[$index].${imageKey}}}&quot;) center center / cover no-repeat, url(${base64Empty}) center center / cover no-repeat; 
                    ">
                </div>
                <div class="card-text">
                    ${contentCard}
                </div>
                
            </div>
            <div id="button-remove-${screen.configType}_{{$index}}"  style="align-self: self-end;padding-right: 10px;"><span style="font-size: 25px;" class="ow ion-android-delete btn-icon" id="text9"></span></div>
        </div>
    </div>
   
        `;

  var paginationContainer = ` <div id="pagination-container-${screen.configType}-${screen.key}" class="pagination-container">
    <span
        id="item-count-text-${screen.configType}-${screen.key}" class="item-count-text">
        </span>
        <div id="pagination-button-${screen.configType}-${screen.key}" class="pagination-button-content">
          <button id="previous-button-${screen.configType}-${screen.key}" class="pagination-button previous-button">Previous</button>
          <button id="next-button-${screen.configType}-${screen.key}" class="pagination-button next-button">Next</button>
        </div>
      </div>`;

  // listTileContainer.appendChild(listTileContent);
  listTileContainer.insertAdjacentHTML("beforeend", listTileContentText);
  screenElement.appendChild(listTileContainer);

  screenElement.insertAdjacentHTML("beforeend", paginationContainer);

  // เพิ่ม pagination
  // const paginationContainer = document.createElement('div');
  // paginationContainer.id = 'pagination-container-' + screen.configType + '-' + screen.key;
  // paginationContainer.className = 'pagination-container';

  // // เพิ่ม pagination-button
  // const paginationButton = document.createElement('div');
  // paginationButton.id = 'pagination-button-' + screen.configType + '-' + screen.key;
  // paginationButton.className = 'pagination-button-content';

  // // เพิ่มตัวหนังสือแสดงจำนวนรายการ
  // const itemCountText = document.createElement('span');
  // itemCountText.id = 'item-count-text-' + screen.configType + '-' + screen.key;
  // itemCountText.className = 'item-count-text';
  // // itemCountText.textContent = `Showing 1-${valuePath.length} of ${valuePath.length}`;
  // paginationContainer.appendChild(itemCountText);

  // // สร้างปุ่ม Previous
  // const previousButton = document.createElement('button');
  // previousButton.id = 'previous-button-' + screen.configType + '-' + screen.key;
  // previousButton.className = 'pagination-button';
  // previousButton.textContent = 'Previous';
  // previousButton.addEventListener('click', () => {
  //     // โค้ดสำหรับกลับไปหน้าก่อนหน้า
  // });
  // paginationButton.appendChild(previousButton);
  // paginationContainer.appendChild(paginationButton);

  // // เพิ่มปุ่มหน้าต่าง ๆ
  // // const totalPages = Math.ceil(valuePath.length / itemsPerPage); // คำนวณหน้าทั้งหมด
  // // for (let i = 1; i <= totalPages; i++) {
  // const pageButton = document.createElement('button');
  // pageButton.id = 'page-button-' + screen.configType + '-' + screen.key;
  // pageButton.className = 'pagination-button';
  // pageButton.textContent = 1;
  // // pageButton.textContent = i;
  // pageButton.addEventListener('click', () => {
  //     // โค้ดสำหรับไปยังหน้าที่ i
  // });
  // paginationButton.appendChild(pageButton);
  // paginationContainer.appendChild(paginationButton);
  // // }

  // // สร้างปุ่ม Next
  // const nextButton = document.createElement('button');
  // nextButton.id = 'next-button-' + screen.configType + '-' + screen.key;
  // nextButton.className = 'pagination-button';
  // nextButton.textContent = 'Next';
  // nextButton.addEventListener('click', () => {
  //     // โค้ดสำหรับไปหน้าถัดไป
  // });
  // paginationButton.appendChild(nextButton);
  // paginationContainer.appendChild(paginationButton);

  // screenElement.appendChild(paginationContainer);
}
