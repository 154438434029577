import React from "react";
import StoryCreateIcon from "src/assets/svg/story-create-icon";
import styles from "./StoryCard.module.scss"

type Props = {
    onClickCreate: () => void;
};

function StoryCardCreate({onClickCreate}: Props) {
  return (
    <div className={styles.storyCardCreateContainer} onClick={onClickCreate}>
      <StoryCreateIcon />
    </div>
  );
}

export default StoryCardCreate;
