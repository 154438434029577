import { cssText } from "src/template/footer/cssText"
import { jsText } from "src/template/footer/jsText"
import { checkEmpty } from "./util.service"

let footerTest = {
  "refTemplate": "Menu",
  "configType": "3 Column", //4 Column, 3 Column,2 Column, None
  "alignItem": "Y",//N
  "instagram": "Y",//N
  "facebook": "Y",//N
  "youtube": "Y",//N
  "search": "Y",//N
  "footer": [
    {
      "seq": 1,
      "label": "main1",
      "link": {
        "type": "url", //url,story
        "url": "",//เปิดแท๊บใหม่ ด้วย url
        "story": {
          "storyId": "",
          "storyName": "",
          "boName": "",
          "lastVersion": "",
          "pageName": "" //myScope.pageGo('pageName');`
        }
      },
      "childs": [
        {
          "seq": 1,
          "label": "child1",
          "link": {
            "type": "url", //url,story
            "url": "",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": "pageName"
            }
          }
        },
        {
          "seq": 2,
          "label": "child2",
          "link": {
            "type": "url", //url,story
            "url": "https://oneweb.tech/",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": ""
            }
          }
        },

      ]
    }, {
      "seq": 2,
      "label": "main2",
      "link": {
        "type": "url", //url,story
        "url": "",//เปิดแท๊บใหม่ ด้วย url
        "story": {
          "storyId": "",
          "storyName": "",
          "boName": "",
          "lastVersion": "",
          "pageName": "" //myScope.pageGo('pageName');`
        }
      },
      "childs": [
        {
          "seq": 1,
          "label": "222222222_1",
          "link": {
            "type": "story", //url,story
            "url": "",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": "pageName"
            }
          }
        },
        {
          "seq": 2,
          "label": "222222222_2",
          "link": {
            "type": "url", //url,story
            "url": "https://oneweb.tech/",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": ""
            }
          }
        }
      ]
    },
    {
      "seq": 3,
      "label": "main3",
      "link": {
        "type": "url", //url,story
        "url": "",//เปิดแท๊บใหม่ ด้วย url
        "story": {
          "storyId": "",
          "storyName": "",
          "boName": "",
          "lastVersion": "",
          "pageName": "" //myScope.pageGo('pageName');`
        }
      },
      "childs": [
        {
          "seq": 1,
          "label": "222222222_1",
          "link": {
            "type": "story", //url,story
            "url": "",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": "pageName"
            }
          }
        },
        {
          "seq": 2,
          "label": "222222222_2",
          "link": {
            "type": "url", //url,story
            "url": "https://oneweb.tech/",
            "story": {
              "storyId": "",
              "storyName": "",
              "boName": "",
              "lastVersion": "",
              "pageName": ""
            }
          }
        }
      ]
    }
  ]
}


interface JSON_FOOTER {
  refTemplate: string,
  configType: string, //4 Column, 3 Column,2 Column, None
  alignItem: string,//N
  instagram: string,//N
  facebook: string,//N
  youtube: string,//N
  search: string,//N
  footer: FOOTER[]
}
interface LINK {
  type: string, //url,story
  url: string,//เปิดแท๊บใหม่ ด้วย url
  story: {
    storyId: string,
    storyName: string,
    boName: string,
    pageName: string //myScope.pageGo('pageName');`
  }
}
interface FOOTER {
  seq: number,
  label: string,
  link: LINK
  childs: [
    {
      seq: number,
      label: string,
      link: LINK
    }]
}

interface JSON_FOOTER_RES {
  htmlText: string,
  jsText: string,
  cssText: string,
  functionName: string
}


const getDivGrid = (jsonFooter: JSON_FOOTER): string => {

  console.log("jsonFooter", jsonFooter)
  let column = 0;
  //4 Column, 3 Column,2 Column, None
  let classGrid = ""
  if (jsonFooter.configType == "4 Column") {
    column = 4;
    classGrid = "footer-col-xl-4"
  }
  else if (jsonFooter.configType == "3 Column") {
    column = 3;
    classGrid = "footer-col-xl-3"
  }
  else if (jsonFooter.configType == "2 Column") {
    column = 2;
    classGrid = "footer-col-xl-2"
  }
  else if (jsonFooter.configType == "None") {
    column = 0;
  }
  if (jsonFooter.footer.length != column) {
    console.error("configType with jsonFooter.footer not math")
    return "";
  }

  let divGridContent = "";
  // let columnContent = ""

  jsonFooter.footer.sort((a, b) => a.seq - b.seq);
  for (let a = 0; a < jsonFooter.footer.length; a++) {
    let columnObj = jsonFooter.footer[a];
    let columnHeader = `<div class="h1-footer"><label class="footer-text-head" >${columnObj.label}</label></div>`
    let liContents = getLiContents(columnObj)
    let href = "";
    if (columnObj.link.type == "url" && !checkEmpty(columnObj.link.url)) {
      href = `pg-href="${columnObj.link.url}" target="_blank"`
    } else if (columnObj.link.type == "story" && !checkEmpty(columnObj.link.story.pageName)) {
      href = `pg-href="./${columnObj.link.story.pageName}" `
    }
    let uiConten = `<ul  class="ul-footer" ${href} >${liContents}</ul>`
    divGridContent += ` <div  class="${classGrid}">${columnHeader}${uiConten}</div>`
  }
  let rowContent = `<div class="footerRow">${divGridContent}</div>`
  // console.log("rowContent", rowContent)

  return rowContent
}
const getLiContents = (columnObj: FOOTER): string => {
  let liContents = "";
  columnObj.childs.sort((a, b) => a.seq - b.seq);
  for (let a = 0; a < columnObj.childs.length; a++) {
    let child = columnObj.childs[a];
    let href = "";
    if (child.link.type == "url" && !checkEmpty(child.link.url)) {
      href = `href="${child.link.url}" target="_blank"`
    } else if (child.link.type == "story" && !checkEmpty(child.link.story.pageName)) {
      href = `href="./${child.link.story.pageName}" `
    }
    liContents += ` <li><a class="a-footer" ${href} ><span class="footer-li" >${child.label}</span></a></li>`
  }
  // console.log("liContents", liContents)
  return liContents;
}


/** ref html
 * 
 <div class="padding-footer row-footer-general">
  <div class="footerRow">
      <div>
        <div  class="footer-col-xl-3">
          <div class="row h1-footer"><label >HOME</label></div>
          <ul class="ul-footer">
            <li ><a class="a-footer" style="" pg-ref="FlexibleandFriendly" ><span>UX/UI</span></a></li>
            <li ><a class="a-footer"  pg-ref="https://oneweb.tech/integration" target="_blank"><span>Microservice</span></a></li>
          </ul>
        </div>
        <div  class="footer-col-xl-3">
          <div class="row h1-footer"><label >HOME</label></div>
          <ul class="ul-footer">
            <li ><a class="a-footer" style="" pg-ref="FlexibleandFriendly" ><span>UX/UI</a></li>
            <li ><a class="a-footer"  pg-ref="https://oneweb.tech/integration" target="_blank"><span>Microservice</span></a></li>
          </ul>
        </div>
        <div  class="footer-col-xl-3">
          <div class="row h1-footer"><label >HOME</label></div>
          <ul class="ul-footer">
            <li ><a class="a-footer" style="" pg-ref="FlexibleandFriendly" >UX/UI</a></li>
            <li ><a class="a-footer"  pg-ref="https://oneweb.tech/integration" target="_blank"><span>Microservice</span></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div  class="row row-social">
        <img pg-href="https://www.facebook.com/MeetAvalant/"
          src="https://oneweb.tech/img/850e104c-e0de-3312-848e-3aaa494fab2b.png">
        <img src="https://oneweb.tech/img/b2c19e8c-1946-3ea4-a8eb-6e9381d02efc.png"
          pg-href="https://www.youtube.com/channel/UCGc9WG8eXH6QGEEywIeb5jA">
        <img src="https://oneweb.tech/img/dd1e3c75-b933-38ff-820f-c432e8bb8e23.png"
          pg-href="https://www.instagram.com/avalant.oneweb/">
    </div>
  </div>
 */


const getSocialContain = (jsonFooter: JSON_FOOTER): string => {
  let content = "";
  if (jsonFooter.facebook == "Y") {
    content += ` <img class="cursor-pointer" pg-href="https://www.facebook.com/MeetAvalant/"
    src="https://oneweb.tech/img/850e104c-e0de-3312-848e-3aaa494fab2b.png">`;
  }
  if (jsonFooter.instagram == "Y") {
    content += `  <img class="cursor-pointer" src="https://oneweb.tech/img/dd1e3c75-b933-38ff-820f-c432e8bb8e23.png"
    pg-href="https://www.instagram.com/avalant.oneweb/">`;
  }
  if (jsonFooter.youtube == "Y") {
    content += `   <img class="cursor-pointer" src="https://oneweb.tech/img/b2c19e8c-1946-3ea4-a8eb-6e9381d02efc.png"
    pg-href="https://www.youtube.com/channel/UCGc9WG8eXH6QGEEywIeb5jA"> `;
  }
  let socialContain = `<div  class="row-social">${content}</div>`;
  return socialContain;

}
export function generatePageFileFooter(jsonFooter: JSON_FOOTER, pageName: string, datetime: string): JSON_FOOTER_RES {
  let textContent = "";
  if (pageName == "footer") {
    textContent = getDivGrid(jsonFooter)
    let socialContain = getSocialContain(jsonFooter);
    textContent = `
        <div class="padding-footer row-footer-general">
          ${textContent}
          ${socialContain}
        </div>
    `;
  }
  let root = `<footer>${textContent}</footer>`;

  let { jsTextReflector, functionName } = transformText(jsText, datetime, pageName);


  return {
    htmlText: root,
    cssText: cssText,
    jsText: jsTextReflector,
    functionName: functionName
  }
}


function transformText(jsTextSource: string, datetime: string, pageName: string) {


  let jsText = jsTextSource.replace(/function (\w+)\(/g, `function $1_${pageName}_${datetime}(`);

  let jsTextTemp = jsText


  let fns = ["init"]
  let propsStatic: any = []


  let propsReplaceMore: any = [];

  for (let a = 0; a < fns.length; a++) {
    const resultArr = jsTextTemp.split(`${fns[a]}(`);
    jsTextTemp = (resultArr.join(`${fns[a]}_${pageName}_${datetime}(`));
  }

  for (let a = 0; a < propsStatic.length; a++) {
    const resultArr = jsTextTemp.split(`${propsStatic[a]}`);
    jsTextTemp = (resultArr.join(`${propsStatic[a]}_${pageName}_${datetime}`));
  }

  for (let a = 0; a < propsReplaceMore.length; a++) {
    const resultArr = jsTextTemp.split(`${propsReplaceMore[a].from}`);
    jsTextTemp = (resultArr.join(`${propsReplaceMore[a].to}`));
  }

  jsText = jsTextTemp;
  const functionName = `init_${pageName}_${datetime}`;
  return { functionName: functionName, jsTextReflector: jsText };
}


export const testFooterFn = async () => {




  let datetime = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
  /**
   * begin generate edit page, add page before
   */



  let convertHtml, htmlToGen;
  let x: JSON_FOOTER_RES = generatePageFileFooter(footerTest as any, "footer", datetime)
  // console.log("header div2Content =>", x.div2Content)
  console.log("header div2Content =>", x.htmlText)
  console.log("header jsText =>", x.jsText)
  //@ts-ignore
  convertHtml = window?.CONVERT_HTML_TO_OBJECT_HEADER_FOOTER(x.htmlText, "footer");
  console.log("header convertHtml =>", convertHtml)



}
