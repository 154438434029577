import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createDBConnectors,
  deleteDBConnectorsById,
  updateDBConnectorsById,
} from "src/services/databaseConnectors.service";
import { GetAllDBConnectorsByAspIDProps } from "src/types/databaseConnectors.type";

export function useCreateDBConnector(props: GetAllDBConnectorsByAspIDProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDBConnectors,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["databaseConnectorsByAspID", { ...props, search: "" }],
      });
    },
  });
}

export function useUpdateDBConnector() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateDBConnectorsById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["databaseConnectorsByAspID"],
      });
    },
  });
}

export function useDeleteDBConnector(props: GetAllDBConnectorsByAspIDProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDBConnectorsById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["databaseConnectorsByAspID", { ...props, search: "" }],
      });
    },
  });
}
