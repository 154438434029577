import { Button, Modal, notification, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styles from "./CreateStoryEntityModal.module.scss";
import StoryEditEntEdit from "src/assets/svg/story-edit-ent-edit";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import StoryEditEntEmpty from "src/assets/svg/story-edit-ent-empty";
import ExpandIcon from "src/assets/svg/expand";
import CompressIcon from "src/assets/svg/compress";
import "./CreateStoryEntityModal.css";
import { MappingParam } from "src/shared/MappingParam/MappingParam";
import CreateBoModal from "../modal-create-bo/CreateBoModal";
import CreateBusinessObjectStep1 from "../../story-entity/step1-CreateBo/CreateBusinessObjectStep1";
import BusinessObjectConfigStep2 from "../../story-entity/step2-BoConfig/BusinessObjectConfigStep2";
import MoreSettingStep3 from "../../story-entity/step3-MoreSetting/MoreSettingStep3";
import CreateBusinessObjectHandler from "src/utils/create-bo/CreateBusinessObjectHandler";
import { StoryBoConfigRequest } from "src/types/request.type";
import { StoryBoConfigResponse } from "src/types/response.type";
import { SetDataReferRequest } from "src/types/request.type";
import { BoMappingInit } from "src/utils/bo-initial-data/BoMappingInit";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { MsConfigProvider } from "src/page/context/CreateEntityManual.context";
import errorImg from "../../../../../assets/png/error.png";

import { STATUS_OK } from "src/constants/ResponseStatus";

import { json, useParams } from "react-router";
import { StoryEnyityBORequest } from "src/types/request.type";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import {
  MARGIN_LIST,
  getDefaultBoConfigItem,
  getUpdateRemoveBoConfig,
} from "src/utils/create-bo/createBo.util";
import MessageNofify from "../../notification/MessageNotify";
import { CreateBoType } from "src/types/BoMappingType";
import { checkEmpty } from "src/services/util.service";

import useFetch from "src/hooks/useFetch";

import {
  GET_ENTITY_BO_BY_ID_TO_EDIT_ENDPOINT,
  UPDATE_ENTITY_BO_BY_ID_ENDPOINT,
  GET_ENTITY_BO_BY_ID_ENDPOINT,
  SAVE_SET_DATA_REFER_ENDPOINT,
  ADD_BO_CONFIG_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";

import {
  EntityBoMapping,
  GetEntityBoByIdResponse,
} from "src/types/response.type";

import EditBoMapping from "../modal-edit-bo/components/EditBoMapping";
import { BO_INIT_KEY } from "src/constants/DiagramInitKey";

type Props = {
  isModalOpen: boolean;
  entityType: string;
  onOk: () => void;
  onCancel: () => void;
  onCreate: (newBo: StoryEnyityBORequest) => void;
  onUpdate: () => void;
  // BO CONFIG ITEMS FROM UPLOAD EXCEL
  arrBOConfigItems: any[];
  boId: string;
  isCreateMode: boolean;
};

interface BOConfigItem {
  id: string;
  isChecked: string;
  bo_name: string;
  data_type: any;
  input_type: string;
  display_view: string;
  display_edit: string;
  show_status: string;
  ref_id: string;
  displayView: string;
  displayEdit: string;
  file_id: string;
}

type BOConfigItems = BOConfigItem[];

interface EntityBOItem {
  id: string;
  story_id: string;
  bo_name: string;
  json_mapping_bo: string;
  created_by: string;
  updated_by: string;
}
interface BOItem {
  bo_item_id: string;
}

type TitleProps = { title: string; stepNum: number; total: number };
const mappingParam = new MappingParam();
const createBoHandler = new CreateBusinessObjectHandler();

const BO_CONFIG_NUM = 1;
const MORE_SETTING_NUM = 2;
const CREATEBO_MAPPING_NUM = 3;

const CreateStoryEntityModal = ({
  isModalOpen,
  entityType,
  onOk,
  onCancel,
  onCreate,
  onUpdate,
  arrBOConfigItems,
  boId,
  isCreateMode,
}: Props) => {
  const {
    loading: loadingEntity,
    error: errorEntity,
    responseData: responseEntity,
    fetchData: fetchEntityData,
    fetchKey: fetchEntityKey,
  } = useFetch<GetEntityBoByIdResponse>();

  const {
    loading: loadingUpdated,
    error: errorUpdated,
    responseData: responseUpdated,
    fetchDataAsync: fectchDataUpdated,
    fetchKey: fetchUpdatedKey,
  } = useFetch<StoryBoConfigResponse[]>();

  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
    console.log("isCreateMode => ", isCreateMode);
    if (!isCreateMode && boId) {
      setLoadingButtonNext(true);
      getEntityBOItemsIDByBOId();
      // setisEditMode(true)
    }
  }, [isModalOpen]);

  let [ObjStep3State, setObjStep3State] = useState<any>({});

  useEffect(() => {
    let ObjStep3 = {
      title: "",
      content: <CreateBusinessObjectStep1 mappingParam={mappingParam} />,
    };
    if (!isCreateMode) {
      ObjStep3.title = "Update Business Object";
      ObjStep3.content = (
        <EditBoMapping
          boId={boId}
          mappingParam={mappingParam}
          isRerender={isRerender}
          updateBoMapping={updateBoMapping}
          isEditMode={isEditMode}
        />
      );
    } else {
      ObjStep3.title = "Create Business Object";
    }
    console.log("ObjStep3", ObjStep3);
    setObjStep3State(ObjStep3);
  }, [isCreateMode]);

  let params = useParams();
  let storyEntityContext = useContext(StoryEntityContext);
  let [openCreateBoModal, setOpenCreateBoModal] = useState<boolean>(false);
  let [nodeSelected, setNodeSelected] = useState<any>(null);
  let [isCreateModeState, setIsCreateModeState] = useState<boolean>(true);
  let [isStateReRender, setIsStateReRender] = useState<boolean>(false);

  let [isFirstOpenModal, setIsFirstOpenModal] = useState<boolean>(true);
  let [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  let [isFromUploadExcel, setisFromUploadExcel] = useState<boolean>(false);

  let [isEditMode, setisEditMode] = useState<boolean>(false);
  let [boConfigItemsState, setboConfigItemsState] = useState<any>([]);
  // let [responseEntityBO, setResponseEntityBO] = useState<EntityBoMapping | undefined>(undefined)

  let [jsonBoMappingState, setJsonBoMappingState] = useState<any>({});

  let [isRerender, setIsRerender] = useState<boolean>(false);
  let [entityNameUpdated, setEntityNameUpdated] = useState<boolean>(false);

  let [username, setUsername] = useState<string>("");

  //MESSAGE NOTIFY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  let [loadingButtonContinue, setLoadingButtonContinue] =
    useState<boolean>(false);
  let [loadingButtonNext, setLoadingButtonNext] = useState<boolean>(false);
  let [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const GET_ENT_BO_ID_FK = "getEntBoIdFk";
  const GET_ENT_BO_ITEM_FK = "getEntBoItemFk";
  const UPDATE_ENTITY_BO_FK = "updateEntBoFK";
  const SAVE_SET_DATA_REFER_FK = "saveSetDataRefFK";
  const ADD_BO_CONFIG_ITEM_FK = "addBoItemFK";

  useEffect(() => {
    console.log("onload CreateStoryEntityModal");
    setLoadingButtonContinue(false);
  }, []);

  const getEntityBOItemsIDByBOId = () => {
    fetchEntityData(
      {
        endpoint: GET_ENTITY_BO_BY_ID_ENDPOINT,
        method: "GET",
        params: {
          id: boId,
        },
      },
      GET_ENT_BO_ID_FK
    );
  };

  const getDataEntityBOItems = (req: string) => {
    fetchEntityData(
      {
        endpoint: GET_ENTITY_BO_BY_ID_TO_EDIT_ENDPOINT,
        method: "GET",
        params: {
          boItemID: req,
        },
      },
      GET_ENT_BO_ITEM_FK
    );
  };

  const updateBoMapping = (req: EntityBoMapping) => {
    setUpdateBoReq(req);
  };

  let [updateBoReq, setUpdateBoReq] = useState<EntityBoMapping | undefined>(
    undefined
  );

  useEffect(() => {
    if (!loadingEntity) {
      if (errorEntity) return;

      switch (fetchEntityKey) {
        case GET_ENT_BO_ID_FK: {
          let bo_item_id: BOItem[] = [];
          let respGeEntityBO = responseEntity?.data as EntityBOItem | undefined;
          let respEntBoId = responseEntity?.data as unknown as EntityBoMapping;
          updateBoMapping(respEntBoId);

          if (respGeEntityBO && respGeEntityBO.json_mapping_bo) {
            let jsonBoMapping = JSON.parse(respGeEntityBO.json_mapping_bo);
            console.log("jsonBoMapping => ", jsonBoMapping);
            setJsonBoMappingState(jsonBoMapping);
            let user = respGeEntityBO.updated_by;
            setUsername(user);
            let bo_group_items =
              getSelectBoFieldFromJsonBoMapping(jsonBoMapping);
            console.log("bo_group_item => ", bo_group_items);

            bo_group_items.forEach((item: any) => {
              bo_item_id.push(item.bo_item_id);
            });

            let boItems = bo_item_id.join(",");
            // console.log("req => ", boItems)
            getDataEntityBOItems(boItems);
            
          }
          break;
        }
        case GET_ENT_BO_ITEM_FK: {
          let respGeEntityBOItems = responseEntity?.data as
            | BOConfigItems
            | undefined;
          let BOconfItems: any = [];
          if (respGeEntityBOItems) {
            BOconfItems = respGeEntityBOItems.map((item: any) => {
              return {
                id: item.entity_bo_item_id,
                isChecked: item.show_status === "true" ? true : false,
                bo_name: item.bo_name,
                data_type: item.data_type,
                input_type: item.input_type,
                display_view: item.display_view,
                display_edit: item.display_edit,
                show_status: item.show_status === "true" ? true : false,
                ref_id: item.parent_id,
                displayView: item.display_view,
                displayEdit: item.display_edit,
                enyity_bo_item_id: item.entity_bo_item_id,
                data_refer_id: item.data_refer_id,
              };
            });
            storyEntityContext.setBoConfigItems(BOconfItems);
          }
          console.log("BOconfItems => ", BOconfItems);
          setboConfigItemsState(BOconfItems);
          setisEditMode(true);
          setLoadingButtonNext(false);
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchEntityKey}`);
        }
      }
    }
  }, [loadingEntity, responseEntity]);

  useEffect(() => {
    if (!loadingUpdated) {
      if (errorUpdated || !responseUpdated) return;

      switch (fetchUpdatedKey) {
        case ADD_BO_CONFIG_ITEM_FK: {
          let newResp = getCombineResponseFromFetchAsync(responseUpdated);
          if (newResp?.status === STATUS_OK) {
            storyEntityContext.setIsLoadingToUpdateBo(false);
            // onUpdateteEntitySuccessNotify(true)
          }
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchUpdatedKey}`);
        }
      }
    }
  }, [loadingUpdated, responseUpdated]);

  // setDisabledButtonNext(
  //   storyEntityContext.boConfigItems.filter((item) => item.bo_name === "")
  //     .length > 0
  //     ? false
  //     : true
  // );

  const setIsNotFirstOpenModal = () => {
    setIsFirstOpenModal(false);
  };

  const getCombineResponseFromFetchAsync = (resp: StoryBoConfigResponse[]) => {
    let respDatas: any = resp.map((item) => item.data);
    let combinedDataArray = [].concat(...respDatas);
    return {
      status: resp[0]?.status,
      message: resp[0]?.message,
      data: [...combinedDataArray],
    };
  };

  const getSelectBoFieldFromJsonBoMapping = (boMapping: any) => {
    let result = [];
    let boList = boMapping?.nodeDataArray;

    //GET ONLY BO SIDE FROM BO_MAPPING
    let onlyBo = boList.filter(
      (bo: any) => bo.group === "boGroup" && bo.key !== BO_INIT_KEY
    );
    console.log("onlyBo => ", onlyBo);

    result = onlyBo.map((item: any) => {
      return {
        bo_item_id: item.key,
      };
    });
    return result;
  };

  const getPathName = (
    boConfigItems: any[],
    parent_id: string,
    currentName: string
  ): string => {
    if (checkEmpty(parent_id)) return `${currentName}`;
    for (let a = 0; a < boConfigItems.length; a++) {
      let item = boConfigItems[a];
      if (item.id === parent_id) {
        let parentPath = getPathName(
          boConfigItems,
          item.parent_id as string,
          item.bo_name as string
        );
        if (checkEmpty(parentPath)) return `${currentName}`;
        else return `${parentPath}.${currentName}`;
      }
    }
    return "";
  };

  const convertDataType = (dataType: string): string => {
    switch (dataType) {
      case "Varchar":
        return "String";
      case "Datetime":
        return "Datetime";
      case "Integer":
        return "Integer";
      case "Decimal":
        return "Decimal";
      case "Boolean":
        return "Boolean";
      default:
        return "Object";
    }
  };

  const onSetNewBoMappingJson = () => {
    console.log(
      "storyEntityContext.boConfigItems => ",
      storyEntityContext.boConfigItems
    );
    mappingParam.loadDataToDiagram(structuredClone(BoMappingInit));
    mappingParam.setBoMapping([]);
    let newNodeDataArray = [];
    let newLinkDataArray = [];
    for (const createBoField of storyEntityContext.boConfigItems) {
      if (createBoField.data_type == "Object") {
        let parentKey = checkEmpty(
          createBoField.ref_id === "" ? null : createBoField.ref_id
        )
          ? BO_INIT_KEY
          : createBoField.ref_id;
        let pathName = getPathName(
          storyEntityContext.boConfigItems,
          createBoField.ref_id,
          createBoField.bo_name
        );
        let margin = 30;
        let pathNames = pathName.split(".");
        margin = margin + (pathNames.length - 1) * 20;
        let newNodeData = {
          paramName: createBoField.bo_name || "",
          pathName: pathName,
          margin: `0 0 0 ${margin}`,
          typeDesc: createBoField.data_type,
          isList: false,
          category: "nodeIsGroup",
          isGroup: false,
          key: createBoField.id,
          parentKey: parentKey,
          group: "boGroup",
        };
        newNodeDataArray.push(newNodeData);
        newLinkDataArray.push({ from: parentKey, to: createBoField.id });
      } else {
        let pathName = getPathName(
          storyEntityContext.boConfigItems,
          createBoField.ref_id,
          createBoField.bo_name
        );
        let margin = 30;
        let pathNames = pathName.split(".");
        margin = margin + (pathNames.length - 1) * 20;
        let newNodeData = {
          paramName: createBoField.bo_name || "",
          pathName: pathName,
          margin: `0 0 0 ${margin}`,
          typeDesc: convertDataType(createBoField.data_type),
          isList: false,
          category: "nodeIsNotGroup",
          isGroup: false,
          key: createBoField.id,
          parentKey: createBoField.ref_id,
          group: "boGroup",
        };
        newNodeDataArray.push(newNodeData);
        newLinkDataArray.push({
          from: createBoField.ref_id,
          to: createBoField.id,
        });
      }
    }
    mappingParam.autoGenBo(newNodeDataArray, newLinkDataArray);

    // setJsonBoMappingState(boMapping)
    // console.log("boMapping aft => ", boMapping)
    console.log("mappingParam => ", mappingParam.getStateDiagramJson());
  };

  const steps = [
    {
      title: "Business Object Config",
      content: (
        <BusinessObjectConfigStep2
          mappingParam={mappingParam}
          isRerender={isStateReRender}
          isFromUploadExcel={isFromUploadExcel}
          arrBOConfigItems={boConfigItemsState}
          isEditMode={isEditMode}
        />
      ),
    },
    {
      title: "More Setting",
      content: (
        <MsConfigProvider>
          <MoreSettingStep3
            createBoHandler={createBoHandler}
            isEditMode={isEditMode}
            isRerender={isStateReRender}
          />
        </MsConfigProvider>
      ),
    },
    ObjStep3State,
    // {
    //   title: "Create Business Object",
    //   content: <CreateBusinessObjectStep1 mappingParam={mappingParam} />,
    // },
  ];

  //----------------------------------TITLE PART MODAL----------------------------------------
  const TitleContent = ({ title, stepNum, total }: TitleProps) => {
    const onAddAndEditHandler = () => {
      const currentStep = storyEntityContext.currentStep;
      switch (currentStep + 1) {
        case BO_CONFIG_NUM:
          addBoConfigItem();
          break;
        case MORE_SETTING_NUM:
          break;
        case CREATEBO_MAPPING_NUM:
          let nodeSelected = mappingParam.getNodeSelect();
          if (nodeSelected) {
            setNodeSelected(nodeSelected);
            setOpenCreateBoModal(true);
          }
          break;
        default:
          console.warn("Unexpected step value in onClickEditBoHandler");
      }
    };
    const isEditBoTableInit = () => {
      let nodeSelected = mappingParam.getNodeSelect();
      return nodeSelected?.typeDesc === "BoInit" ||
        nodeSelected?.typeDesc === "TableInit"
        ? true
        : false;
    };

    const onClickAddBoHandler = () => {
      // check edit mode
      if (entityType !== "edit") {
        setIsCreateModeState(true);
      }
      onAddAndEditHandler();
    };
    const onClickEditBoHandler = () => {
      if (isEditBoTableInit()) return;
      setIsCreateModeState(false);
      const currentStep = storyEntityContext.currentStep;
      switch (currentStep + 1) {
        case BO_CONFIG_NUM:
          console.log(
            "onClickEditBoHandler BoConfigItems => ",
            storyEntityContext.boConfigItems
          );
          break;
        case MORE_SETTING_NUM:
          console.log(
            "onClickEditBoHandler BoConfigItems => ",
            storyEntityContext.boConfigItems
          );
          break;
        case CREATEBO_MAPPING_NUM:
          onAddAndEditHandler();
          break;
        default:
          console.warn("Unexpected step value in onClickEditBoHandler");
      }
    };
    const onClickDeleteBoHandler = () => {
      const currentStep = storyEntityContext.currentStep;

      switch (currentStep + 1) {
        case BO_CONFIG_NUM:
          console.log("onClickDeleteBoHandler");
          const rowSelected = storyEntityContext.boConfigRowSelectedID;
          if (rowSelected) setIsOpenDeleteModal(true);

          break;
        case MORE_SETTING_NUM:
          break;
        case CREATEBO_MAPPING_NUM:
          const nodeToDelete = mappingParam.getNodeSelect();
          if (nodeToDelete && !nodeToDelete.isGroup) {
            mappingParam.removeTreeNodeBySelectionNode();
          }
          break;
        default:
          console.warn("Unexpected step value in onClickDeleteBoHandler");
      }
    };

    return (
      <div className={styles.createTitleModal}>
        <div className={styles.title}>
          <span>{title}&nbsp;&nbsp;</span>
          <span style={{ color: "#1c87f2" }}>{stepNum}</span>
          <span style={{ color: "#ababab" }}>{"/" + total}</span>
        </div>
        {storyEntityContext.currentStep + 1 !== MORE_SETTING_NUM && (
          <div className={styles.actionTool}>
            <div
              className={styles.actionIconWrapper}
              onClick={onClickAddBoHandler}
            >
              <StoryEditEntAdd className={styles.actionIcon} />
            </div>
            <div
              className={styles.actionIconWrapper}
              onClick={onClickEditBoHandler}
            >
              <StoryEditEntEdit className={styles.actionIcon} />
            </div>
            <div
              className={styles.actionIconWrapper}
              onClick={onClickDeleteBoHandler}
            >
              <StoryEditEntDelete className={styles.actionIcon} />
            </div>
          </div>
        )}
      </div>
    );
  };
  //----------------------------------TITLE PART MODAL----------------------------------------

  const isDataTypeObject = (dataType?: string): boolean => {
    return dataType === "Table" || dataType === "Object" ? true : false;
  };

  const onOkCreateBo = (
    parentKey?: string,
    createBoFields?: CreateBoType[]
  ) => {
    let layerOfParent = mappingParam.getLayerByParentField(parentKey as string);
    let margin = MARGIN_LIST[layerOfParent as number];
    let parent = mappingParam.getParamByKey(parentKey as string);

    let newNodeDataArray = [];
    let newLinkDataArray = [];
    for (const createBoField of createBoFields as CreateBoType[]) {
      let dataType = createBoField.dataType || "";
      let fieldName = createBoField.fieldName || "";
      let group = createBoField.group || "";
      let pathName = fieldName;
      if (!checkEmpty(parent.pathName)) {
        pathName = parent.pathName + "." + fieldName;
      }

      let newNodeData = {
        paramName: fieldName,
        pathName: pathName,
        margin,
        typeDesc: dataType,
        isList: false,
        parentKey: parentKey,
        category: isDataTypeObject(dataType) ? "nodeIsGroup" : "nodeIsNotGroup",
        isGroup: false,
        key: createBoField.itemId,
        group: group,
        isPrimaryKey: createBoField.isPrimaryKey,
        foreignKey: createBoField.foreignKey,
      };
      newNodeDataArray.push(newNodeData);
      let link = mappingParam.getLinkByFromToKey(
        parentKey as string,
        createBoField.itemId as string
      );
      if (checkEmpty(link)) {
        newLinkDataArray.push({ from: parentKey, to: createBoField.itemId });
      }
    }
    console.log("onOkCreateBo newNodeDataArray => ", newNodeDataArray);

    //mappingParam.addNewNodeInParentSelection(newNodeDataArray);

    mappingParam.addNewNodeInParentSelectionWithList(
      parentKey as string,
      newNodeDataArray,
      newLinkDataArray
    );
    setOpenCreateBoModal(false);
  };

  const onOkEditBo = (createBoFields: CreateBoType[]) => {
    console.log(
      "onOkEditBo createBoFields: CreateBoType[] => ",
      createBoFields
    );
    for (const cb of createBoFields) {
      let nodeEditSelected = {
        ...nodeSelected,
        paramName: cb.fieldName,
        typeDesc: cb.dataType,
        isPrimaryKey: cb.isPrimaryKey,
        foreignKey: cb.foreignKey,
      };
      mappingParam.updateNodeInParentSelection(nodeEditSelected);
    }

    setOpenCreateBoModal(false);
  };
  const onCancelCreateAndEditBo = () => {
    setOpenCreateBoModal(false);
    arrBOConfigItems = [];
  };

  const onClickBackHandler = () => {
    if (storyEntityContext.currentStep === 0) return;
    storyEntityContext.setCurrentStep(storyEntityContext.currentStep - 1);
    setIsStateReRender(true);
    setLoadingButtonNext(false);
    storyEntityContext.setIsLoadingToUpdateBo(false);
  };

  const onClickNextHandler = async () => {
    setLoadingButtonNext(true);
    setIsStateReRender(false);
    const currentStep = storyEntityContext.currentStep;
    const checkFieldName = storyEntityContext.boConfigItems.filter(
      (item) => item.bo_name === ""
    ).length;

    if (!checkDuplicatesFieldName()) {
      if (checkFieldName > 0) {
        setTriggerNotify(!triggerNotify);
        setTitleNotify("Field Name");
        setDescriptionNotify("Please Add Field Name");
        setIsSuccessNotify(false);
        setLoadingButtonNext(false);
      } else {
        switch (currentStep + 1) {
          case BO_CONFIG_NUM:
            if (!isCreateMode) {
              // onSetNewBoMappingJson()
              let nextStep = storyEntityContext.currentStep + 1;
              storyEntityContext.setCurrentStep(nextStep);
              storyEntityContext.setIsLoadingToUpdateBo(true);
            } else {
              storyEntityContext.setIsBoConfigSave(true);
            }
            setLoadingButtonNext(false);
            break;
          case MORE_SETTING_NUM:
            if (isCreateMode) {
              storyEntityContext.setIsMapDataReferSave(true);
            } else {
              let nextStep = storyEntityContext.currentStep + 1;
              storyEntityContext.setCurrentStep(nextStep);
              storyEntityContext.setIsLoadingToUpdateBo(true);
              setLoadingButtonNext(true);
              // storyEntityContext.setDataReferMapping()
            }
            setIsStateReRender(false);
            break;
          case CREATEBO_MAPPING_NUM:
            break;
          default:
            console.warn("Unexpected step value in onClickDeleteBoHandler");
        }
      }
    }
  };

  const openNotificationError = (
    titleName: string,
    description: string,
    filedName: string
  ) => {
    notification.open({
      message: (
        <div className={styles.messageNotify}>{`Error ${titleName}!`}</div>
      ),
      description: (
        <div
          className={styles.descriptionNotify}
        >{`${description} : ${filedName}`}</div>
      ),
      icon: (
        <div className={styles.iconNotify}>
          <img src={errorImg} alt="" width={"50px"} height={"50px"} />
        </div>
      ),
    });
  };

  const checkDuplicatesFieldName = (): boolean => {
    const seenDataType = new Set();
    const seenRefID = new Set();
    let duplicateDataType = false;
    let duplicateRefID = false;
    let boName = "";

    storyEntityContext.boConfigItems.forEach((item) => {
      if (item.data_type === "Object") {
        const key = item.bo_name;
        if (seenDataType.has(key)) {
          boName = item.bo_name;
          duplicateDataType = true;
        }
        seenDataType.add(key);
      }

      if (item.ref_id !== "") {
        const key = `${item.bo_name}-${item.ref_id}`;
        if (seenRefID.has(`${item.bo_name}-${item.ref_id}`)) {
          boName = item.bo_name;
          duplicateRefID = true;
        }
        seenRefID.add(key);
      }
    });

    if (duplicateDataType || duplicateRefID) {
      openNotificationError("Duplicate", "Field Name", `${boName}`);
      setLoadingButtonNext(false);
      return true;
    }
    return false;
  };

  const onClickContinueHandler = () => {
    setLoadingButtonContinue(true);

    //CREATE ENTITY NAME
    let boMappingJson = mappingParam.getStateDiagramJson();
    let storyId = getStoryId();
    let boName = mappingParam.getBoNameFromBoMapping();

    if (!boName) {
      saveNoBoItemError();
      return;
    }

    let newStoryEntity: StoryEnyityBORequest = {
      story_id: storyId as string,
      bo_name: boName || "BO_NAME",
      json_mapping_bo: JSON.stringify(boMappingJson),
    };
    onCreate(newStoryEntity);
    onCancel();
  };

  //BOTTOM ACTION HANDLER
  const onClickSaveBoHandler = () => {
    storyEntityContext.setIsLoadingToUpdateBo(true);
    let boMapping = mappingParam.getStateDiagramJson();
    console.log("onClickSaveBoHandler boMapping => ", boMapping);
    let updateReq: EntityBoMapping = {
      ...(updateBoReq as EntityBoMapping),
      json_mapping_bo: JSON.stringify(boMapping),
    };

    // FOR SAVE BO CONFIG ITEM , DATA REFER, BO MAPPING
    saveAndUpdateDataReferForEdit();
    saveAndUpdateBOItemsConfigForEdit();
    saveAndUpdateEntityBO(boMapping);
    onUpdate();
    onCancel();
  };

  const saveAndUpdateEntityBO = (boMapping: any) => {
    let jsonBoMapping = JSON.stringify(boMapping);
    let id = updateBoReq?.id;
    console.log("id ", id);
    let updateReq: EntityBoMapping = {
      ...(updateBoReq as EntityBoMapping),
      json_mapping_bo: jsonBoMapping,
    };
    console.log("onClickSaveBoHandler updateBoReq => ", updateBoReq);
    console.log("onClickSaveBoHandler updateReq => ", updateReq);
    if (!boMapping) {
      saveNoBoItemError();
      return;
    }
    updateeEntityBOMapping(id as string, updateReq);
  };

  const saveAndUpdateBOItemsConfigForEdit = () => {
    let boConfigItems = storyEntityContext.boConfigItems;
    if (boConfigItems.length === 0) {
      storyEntityContext.setIsBoConfigSave(false);

      let nextStep = storyEntityContext.currentStep + 1;
      storyEntityContext.setCurrentStep(nextStep);
      return;
    }

    //GET DATA FROM BOCONFIG LIST SET TO REQUEST FORM
    console.log("save item boConfigItems => ", boConfigItems);
    let reqBodys = boConfigItems.map((item) => {
      return {
        ...item,
        story_id: params.id as string,
        show_status: item.show_status.toString(),
        display_edit: item.displayEdit,
        display_view: item.displayView,
      };
    });

    let reqInsert: StoryBoConfigRequest = {
      action: "create",
      data: [],
    };
    let reqUpdate: StoryBoConfigRequest = {
      action: "update",
      data: [],
    };

    //SEPERATE BY enyity_bo_item_id IN OBJECT
    for (const reqBody of reqBodys) {
      reqBody.enyity_bo_item_id
        ? reqUpdate.data.push(reqBody)
        : reqInsert.data.push(reqBody);
    }

    let resultReq: StoryBoConfigRequest[] = [];
    if (reqInsert.data.length > 0) resultReq.push(reqInsert);
    if (reqUpdate.data.length > 0) resultReq.push(reqUpdate);
    console.log("resultReq : ", resultReq);
    addBoConfigItemsAsyncController(resultReq);
  };

  const saveAndUpdateDataReferForEdit = () => {
    let moreSettingItems = storyEntityContext.moreSettingItems;
    let requestSetDataRefer: SetDataReferRequest[] = [];
    for (const item of moreSettingItems) {
      if (item.data_refer_id) {
        requestSetDataRefer.push({
          enyity_bo_item_id: item.id,
          data_refer_id: item.data_refer_id,
        });
      }
    }
    saveSetDataReferController(requestSetDataRefer);
  };

  const saveSetDataReferController = (req: SetDataReferRequest[]) => {
    fetchEntityData(
      {
        endpoint: SAVE_SET_DATA_REFER_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      SAVE_SET_DATA_REFER_FK
    );
  };

  // API CONTROLLER FUNCTION
  const addBoConfigItemsAsyncController = (
    inputReq: StoryBoConfigRequest[]
  ) => {
    let request: any = inputReq.map((req) => {
      return {
        endpoint: ADD_BO_CONFIG_ENDPOINT,
        method: "POST",
        reqBody: req,
      };
    });
    fectchDataUpdated(request, ADD_BO_CONFIG_ITEM_FK);
  };

  //SAVE BO MAPPING
  const updateeEntityBOMapping = (id: string, req: EntityBoMapping) => {
    fetchEntityData(
      {
        endpoint: UPDATE_ENTITY_BO_BY_ID_ENDPOINT,
        method: "PATCH",
        params: {
          id,
        },
        reqBody: req,
      },
      UPDATE_ENTITY_BO_FK
    );
  };

  useEffect(() => {
    console.log("Onload create bo modal ");
    console.log("arrBOConfigItems => ", arrBOConfigItems);
    if (arrBOConfigItems.length > 0) {
      setisFromUploadExcel(true);
      setboConfigItemsState(arrBOConfigItems);
    }
  }, []);

  //BUSINESS OBJECT CONFIG HANDLER
  const addBoConfigItem = () => {
    let newBoConfigItem = getDefaultBoConfigItem();
    // SET LATEST PARENT
    if (storyEntityContext.boConfigItems.length > 0) {
      storyEntityContext.boConfigItems.reduce((latest, current) => {
        newBoConfigItem.ref_id = current?.ref_id;
      });
    } else {
      newBoConfigItem.data_type = "Object";
    }
    console.log("newBoConfigItem => ", newBoConfigItem);
    console.log(
      "storyEntityContext.boConfigItems ",
      storyEntityContext.boConfigItems
    );
    storyEntityContext.setBoConfigItems([
      ...storyEntityContext.boConfigItems,
      newBoConfigItem,
    ]);
    setIsStateReRender(!isStateReRender);
  };
  const removeBoConfigItem = () => {
    storyEntityContext.setIsBoConfigRemove(true);
  };

  //CONFIRM DELETE MODAL HANDLER
  const onConfirmDeleteHandler = () => {
    removeBoConfigItem();
    if (!isCreateMode) {
      removeBoItemsInJsonMapping();
    }
    setIsOpenDeleteModal(false);
  };
  const onCancelDeleteHandler = () => {
    setIsOpenDeleteModal(false);
  };

  //UTILITY FUNCTION
  const getStoryId = () => {
    return params.id;
  };
  const saveNoBoItemError = () => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify("Save BO Failed!");
    setDescriptionNotify("Please add BusinessObject item");
    setIsSuccessNotify(false);
  };

  const removeBoItemsInJsonMapping = () => {
    let removeId = storyEntityContext.boConfigRowSelectedID;
    let boMapping = jsonBoMappingState;
    console.log("--- boMapping --- ", boMapping);
    console.log("--- removeId --- ", removeId);

    if (removeId) {
      let nodeDataArray = boMapping.nodeDataArray;
      let linkDataArray = boMapping.linkDataArray;
      if (linkDataArray && linkDataArray.length > 0) {
        linkDataArray.forEach(() => {
          let indexFrom = linkDataArray.findIndex(
            (item: any) => item.from === removeId
          );
          if (indexFrom !== -1) {
            console.log("indexFrom => ", indexFrom);
            boMapping.linkDataArray.splice(indexFrom, 1);
          }

          let indexTo = linkDataArray.findIndex(
            (item: any) => item.to === removeId
          );
          if (indexTo !== -1) {
            console.log("indexTo => ", indexTo);
            boMapping.linkDataArray.splice(indexTo, 1);
          }
        });
        console.log("link ", boMapping.linkDataArray);
      }

      if (nodeDataArray && nodeDataArray.length > 0) {
        nodeDataArray.forEach(() => {
          let indexFrom = nodeDataArray.findIndex(
            (item: any) => item.key === removeId
          );
          if (indexFrom !== -1) {
            console.log("indexFrom => ", indexFrom);
            boMapping.nodeDataArray.splice(indexFrom, 1);
          }

          let indexTo = nodeDataArray.findIndex(
            (item: any) => item.parentKey === removeId
          );
          if (indexTo !== -1) {
            console.log("indexTo => ", indexTo);
            boMapping.nodeDataArray.splice(indexTo, 1);
          }
        });
        console.log("node ", boMapping.nodeDataArray);
      }
      console.log("boMapping ", boMapping);

      saveAndUpdateEntityBO(boMapping);
      // console.log("JsonBoMappingState => ", jsonBoMappingState)
    }
  };

  const onUpdateteEntitySuccessNotify = (value: boolean) => {
    setEntityNameUpdated(value);
  };

  const modalBodyStyles = isExpanded
    ? ({
        flex: 1,
        overflowY: "auto",
        height: "80vh",
      } as React.CSSProperties)
    : {};

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    console.log(isExpanded);
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TitleContent
              title={steps[storyEntityContext.currentStep].title}
              stepNum={storyEntityContext.currentStep + 1}
              total={steps.length}
            />
            <div style={{ position: "absolute", top: "-8px", right: "10px" }}>
              <Button
                type="text"
                icon={isExpanded ? <CompressIcon /> : <ExpandIcon />}
                onClick={toggleExpand}
                style={{ marginRight: 8 }}
              />
            </div>
          </div>
        }
        footer={
          // current !== 0 && (
          <div className={styles.createFooterModal}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {storyEntityContext.currentStep > 0 && (
              <Button
                className={styles.cancelBtn}
                key="back"
                onClick={onClickBackHandler}
              >
                Back
              </Button>
            )}

            {storyEntityContext.currentStep < steps.length - 1 && (
              <Button
                className={styles.confirmBtn}
                key="next"
                onClick={onClickNextHandler}
                type="primary"
                loading={loadingButtonNext}
              >
                Next
              </Button>
            )}

            {storyEntityContext.currentStep === steps.length - 1 &&
              isCreateMode && (
                <Button
                  className={styles.confirmBtn}
                  key="continue"
                  onClick={onClickContinueHandler}
                  type="primary"
                  loading={loadingButtonContinue}
                >
                  Continue
                </Button>
              )}

            {storyEntityContext.currentStep === steps.length - 1 &&
              !isCreateMode && (
                <Button
                  className={styles.confirmBtn}
                  key="save"
                  onClick={onClickSaveBoHandler}
                  type="primary"
                  loading={storyEntityContext.isLoadingToUpdateBo}
                  disabled={storyEntityContext.isLoadingToUpdateBo}
                >
                  Save
                </Button>
              )}
          </div>
          // )
        }
        open={isModalOpen}
        // onOk={onOkModal}
        onCancel={onCancel}
        centered={true}
        width={isExpanded ? "100%" : "80%"}
      >
        <span
          className={
            isExpanded
              ? styles.createContentModalExpand
              : styles.createContentModal
          }
        >
          {steps[storyEntityContext.currentStep].content}
        </span>
      </Modal>
      <CreateBoModal
        modalDetail={{ title: "Create Business Object" }}
        isCreateMode={isCreateModeState}
        isModalOpen={openCreateBoModal}
        nodeSelected={nodeSelected}
        onOkCreate={onOkCreateBo}
        onOkEdit={onOkEditBo}
        onCancelCreateAndEditBo={onCancelCreateAndEditBo}
        mappingParam={mappingParam}
      />

      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Field?",
          description: `Are you sure you want to delete this field? This action cannot be
        undone.`,
        }}
        isModalOpen={isOpenDeleteModal}
        onOk={onConfirmDeleteHandler}
        onCancel={onCancelDeleteHandler}
      />

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );
};

export default CreateStoryEntityModal;
