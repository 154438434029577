import React, { useMemo, useEffect, useState } from "react";
import { Table } from "antd";
import "./DataReferenceList.css";
import styles from "./DataReferenceList.module.scss";
import DeleteIcon from "src/assets/svg/delete";
import EditIcon from "src/assets/svg/editIcon";
import ModalDelete from "src/page/component/database-connection/modal/ModalDelete";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";
import { callApiMethodDelete } from "src/services/util.service";
import { useNavigate } from "react-router";
import { DELETE_DATA_REFER_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";

type ListItemProps = {
  dataReferenceItemsList: any;
  CallAPIGetDataByAspID: any;
  openEditDataReferModal: (dataReferID: string) => void;
};

function DataReferenceList({
  dataReferenceItemsList,
  CallAPIGetDataByAspID,
  openEditDataReferModal,
}: ListItemProps) {
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const APIDeleteDataRefer = async (dataReferID: string) => {
    try {
      const response = await callApiMethodDelete(DELETE_DATA_REFER_ENDPOINT, {
        id: dataReferID,
      });
      if (response.status == 200) {
        await CallAPIGetDataByAspID();
      }
    } catch (error) {
      console.log("error delete Data Refer : ", error);
    }
  };

  function handleDeleteConfirmation(id: string) {
    setSelectedId(id);
    setOpenModalDelete(true);
  }

  const handleOk = () => {
    APIDeleteDataRefer(selectedId);
    setOpenModalDelete(false);
  };

  const columns = [
    {
      title: "Data Reference",
      key: "DataReferName",
      dataIndex: "DataReferName",
      render: (text: any) => <>{text}</>,
    },
    {
      title: "Date",
      dataIndex: "CreatedAt",
      key: "date",
      render: (text: any) => <>{formatISODateToCustomFormat(text)}</>,
    },
    {
      title: "Owner",
      dataIndex: "CreatedBy",
      key: "owner",
      render: (text: any) => <>{text}</>,
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <div className={styles.iconContainerStyle}>
          <div onClick={() => openEditDataReferModal(record.DataReferID)}>
            <EditIcon />
          </div>
          <div
            className={styles.deleteIconStyle}
            onClick={() => handleDeleteConfirmation(record.DataReferID)}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        className={styles.tableBody}
        columns={columns}
        dataSource={dataReferenceItemsList}
      />

      <ModalDelete
        title="Delete Data Reference ?"
        description="Are you sure want to delete this data reference?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => {
          setOpenModalDelete(false);
        }}
        onOk={() => handleOk()}
        open={openModalDelete}
      />
    </>
  );
}

export default DataReferenceList;
