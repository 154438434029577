import React, { useEffect } from "react";
import styles from "../../database-connection/card/CardItem.module.scss";
import { Avatar, Card } from "antd";
import DatabaseConnectionIcon from "src/assets/svg/database-connection";
import ImageMongoDB from "../../../../assets/png/mongoDB-img.png";
import ImagePostgres from "../../../../assets/png/postgres-img.png";
import ImageFirebase from "../../../../assets/png/firebase-img.png";
import ImageMariaDB from "../../../../assets/png/mariaDB-img.png";
import ImageMySQL from "../../../../assets/png/mysql.png";
import ImageSQL from "../../../../assets/png/sql-server-img2.png";
import ImageOracle from "../../../../assets/png/oracle-img.jpg";
import { formatISODateToCustomFormat } from "src/page/utils/FormatDate";

type CardItemProps = {
  id: number;
  connectionName: string;
  dbType: string;
  userProfile: string;
  username: string;
  uploadDate: string;
  onClick: () => void;
};

function CardItem({
  id,
  connectionName,
  dbType,
  userProfile,
  username,
  uploadDate,
  onClick,
}: CardItemProps) {
  const getImageByDbType = (dbType: string) => {
    switch (dbType) {
      case "mongodb":
        return ImageMongoDB;
      case "mysql":
        return ImageMySQL;
      case "postgresql":
        return ImagePostgres;
      case "firebase":
        return ImageFirebase;
      case "mariadb":
        return ImageMariaDB;
      case "sqlserver":
        return ImageSQL;
      case "oracle":
        return ImageOracle;
      default:
        return ImageMongoDB;
    }
  };

  return (
    <Card key={id} onClick={onClick} className={styles.cardContainer}>
      <div className={styles.cardContent}>
        <img
          alt="example"
          src={getImageByDbType(dbType)}
          className={styles.cardImage}
        />
        <div className={styles.cardTitle}>{connectionName}</div>
      </div>
      {/* "https://i.pinimg.com/236x/ab/24/0a/ab240a0632380ffdd977c6208724a280.jpg" */}
      <div className={styles.cardFooter}>
        <div className={styles.avatarContainer}>
          <Avatar src={userProfile} className={styles.avatarImage} />
          <div className={styles.userInfo}>
            <div className={styles.userEmail}>{username}</div>
            <div className={styles.uploadDate}>
              {formatISODateToCustomFormat(uploadDate)}
            </div>
          </div>
        </div>
        <div className={styles.iconContainer}>
          <DatabaseConnectionIcon width={18.5} height={18.5} />
        </div>
      </div>
    </Card>
  );
}
export default CardItem;
