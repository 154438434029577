import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../context/App.context";

function Profile(props) {
  console.log("props", props);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const context = useContext(AppContext);

  useEffect(()=>{
    setUsername(context.username)
  },[])
  return (
    <div>
      <div>Profile</div>
      <div>{props.location.state.name}</div>
      <div>username : {username}</div>
      <div>password : {context.password}</div>
    </div>
  );
}

export default Profile;
