export const getRowIdFromCellId = (cellId: string) => {
  //{sub-r3_cell-r1-c1}
  let cellPosition = cellId.split("_")[1];
  let rowId = cellPosition.split("-")[1];
  return rowId;
};

export const getMainRowIdFromCellId = (cellId: string) => {
  //{sub-r3_cell-r1-c1}
  let rowPosition = cellId.split("_")[0];
  let mainRowId = rowPosition.split("-")[1];
  return mainRowId;
};

export const getCellPositionFromCellId = (cellId: string) => {
  //{sub-r3_cell-r1-c1}
  let cellPosition = cellId.split("_")[1];
  let cellPos = cellPosition.split("-");
  return cellPos[1] + "-" + cellPos[2];
};

export const getSubCellIdByMainRowIdAndColId = (
  mainRowId: string,
  colId: string
) => {
  return `sub-${mainRowId}_cell-${colId}`;
};

export const getSubFieldIdByMainRowIdAndColId = (
  mainRowId: string,
  colId: string
) => {
  return `sub-${mainRowId}_field-${colId}`;
};

export const getComponentContainerIdByMainRowId = (mainRowId: string) => {
  return `component-${mainRowId}`;
};

export const getColIdOneColumnInRowByRowId = (rowId: string) => {
  return "main_cell-" + rowId + "-c1";
}

export const getColIdByRowIdAndColId = (rowId: number, colId: number) => {
  return "main_cell-r" + rowId + "-c" + colId;
}

export const getGridItemsByActiveId = (gridItems: any, activeId: string) => {
  let gridObj = gridItems.find((item: any) => item.id === activeId);
  if (!gridObj) return;

  let gridItem = gridObj?.componentRowItems;
  return gridItem;
};

//FROM MAIN PANEL FUNCTION
export const updateChildInCellWithBoFieldItem = (
  cellId: string,
  boItemId: string,
  gridPanelItems: any
) => {
  let rowId = cellId.split("-")[1]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
  let newRowItem: any = gridPanelItems.find(
    (item: any) => item.rowId === rowId
  );

  let gPanelItem = newRowItem.cols.map((item: any) => {
    if (item.colId === cellId) {
      return {
        ...item,
        childIdRef: boItemId,
        isControlItem: false,
        controlComponent: null,
      };
    } else {
      return item;
    }
  });
  // console.log("new gPanelItem => ", gPanelItem);
  newRowItem = {
    ...newRowItem,
    cols: gPanelItem,
  };
  console.log("newRowItem => ", newRowItem);
  return newRowItem;
};

export const deepClone = (obj: any) => {
  return JSON.parse((JSON.stringify(obj)))
}

export const replaceChildInsideCellInGridPanelItems = (
  cellId: string,
  newRowItem: any,
  gridPanelItems: any
) => {
  let rowId = cellId.split("-")[1];
  let itemChangedIndex = gridPanelItems.findIndex(
    (item: any) => item.rowId === rowId
  );
  // let gridPanelItems_ = deepClone([...gridPanelItems])
  //  console.log("gridPanelItems_ => ", gridPanelItems_);
  // console.log("itemChangedIndex => ", itemChangedIndex);
  console.log("%c itemChangedIndex => ","color: green;font-size: 16px;font-weight: bold;", itemChangedIndex );
  let leftGridItems = [...gridPanelItems].splice(0, itemChangedIndex);
  // console.log("leftGridItems => ", leftGridItems);
  console.log("%c leftGridItems => ","color: green;font-size: 16px;font-weight: bold;", leftGridItems );
  let rightGridItems = [...gridPanelItems].splice(
    itemChangedIndex + 1,
    gridPanelItems.length
  );
  // console.log("rightGridItems => ", rightGridItems);
  console.log("%c rightGridItems => ","color: green;font-size: 16px;font-weight: bold;", rightGridItems );
  let newGridPanelItems = leftGridItems.concat(newRowItem, rightGridItems);
  // console.log("newGridPanelItems => ", newGridPanelItems);
  return newGridPanelItems;
};

export const replaceRowWithNewRowItemByRowId = (
  rowId: string,
  newRowItem: any,
  gridPanelItems: any
) => {
  let itemChangedRowIndex = gridPanelItems.findIndex(
    (item: any) => item.rowId === rowId
  );
  // console.log("itemChangedIndex => ", itemChangedIndex);
  let aboveGridItems = [...gridPanelItems].splice(0, itemChangedRowIndex);
  // console.log("leftGridItems => ", leftGridItems);
  let belowGridItems = [...gridPanelItems].splice(
    itemChangedRowIndex + 1,
    gridPanelItems.length
  );
  // console.log("rightGridItems => ", rightGridItems);
  let newGridPanelItems = aboveGridItems.concat(newRowItem, belowGridItems);
  // console.log("newGridPanelItems => ", newGridPanelItems);
  return newGridPanelItems;
};

export const removeChildInCell = (cellId: string, gridPanelItems: any) => {
  let rowId = cellId.split("-")[1]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
  console.log("removeChildInCell cellId => ", cellId)
  console.log("removeChildInCell gridPanelItems => ", gridPanelItems)

  // let gridPanelItems_ = deepClone(gridPanelItems)
  let newRowItem: any = gridPanelItems.find(
    (item: any) => item.rowId === rowId
  );

  let gPanelItem = newRowItem.cols.map((item: any) => {
    if (item.colId === cellId) {
      return {
        ...item,
        childIdRef: "",
        isControlItem: false,
        controlComponent: null,
      };
    } else {
      return item;
    }
  });
  console.log("removeChildInCell gPanelItem => ", gPanelItem);
  newRowItem = {
    ...newRowItem,
    cols: gPanelItem,
  };
  return newRowItem;
};

export const removeComponentInCell = (
  rowId: string,
  colNum: number,
  gridPanelItems: any
) => {
  let rowItemFocus: any = gridPanelItems.find(
    (item: any) => item.rowId === rowId
  );
  // console.log("rowItemFocus => ", rowItemFocus);

  let cols = [];
  for (let index = 1; index <= colNum; index++) {
    let col = {
      colId: "main_cell-" + rowId + "-c" + index,
      childRef: "",
    };
    cols.push(col);
  }

  let newRowItem = {
    ...rowItemFocus,
    isControlItem: false,
    controlComponent: null,
    cols,
  };

  return newRowItem;
};

export const defineRowIdInControlComponent = (
  controlComponent: any,
  mainRowId: string
) => {
  let props = controlComponent?.props;

  let newControlComponent = {
    ...controlComponent,
    props: {
      ...props,
      mainRowId,
    },
  };
  return newControlComponent;
};
