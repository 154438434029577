import React, { useEffect, useState } from "react";
import styles from "../database-connection/EditStoryDBConnection.module.scss";
import { Select } from "antd";
import StoryEditDbDelete from "src/assets/svg/story-edit-db-delete";
import StoryEditDbMongoDb from "src/assets/svg/story-edit-db-mongodb";
import ModalConfirmDelete from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";
import ImageMySqlDB from "src/assets/png/LogoMySQL.png";
import ImageMongoDB from "src/assets/png/LogoMongoDB.png";
import RedisDB from "src/assets/png/LogoRedis.png";
import ImagePostgres from "src/assets/png/LogoPostgreSQL.png";
import ImageFirebase from "src/assets/png/LogoFirebase.png";
import ImageMariaDB from "src/assets/png/LogoMaria.png";
import ImageSqlServer from "src/assets/png/LogoSqlServer.png";
import ImageOracle from "src/assets/png/LogoOracle2.png";
import {
  GET_DATA_CONNECTOR_ENDPOINT,
  GET_DB_CONNECT_ENDPOINT,
  CREATE_STORY_DB_CONNECTOR_ENDPOINT,
  DELETE_STORY_DB_CONNECTOR_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";

import {
  callApiMethodGet,
  callApiMethodDelete,
  callApiMethodPost,
} from "src/services/util.service";

type StoryDBConnector = {
  StoryDBConnectorID: string;
  DBConnectorID: string;
  DBConnectionName: string;
  DBItemName: string;
  DBType: string;
  DBName: string;
};
type Props = {
  storyID: string;
  isOpen: boolean;
};
type DBSelectedType = {
  value: string;
  label: string;
};
type DBSelectedItemType = {
  dbDetail: StoryDBConnector;
  onClickDelete: () => void;
  onSetDeleteItem: (value: string) => void;
};
const DBSelectedItem = ({
  dbDetail,
  onClickDelete,
  onSetDeleteItem,
}: DBSelectedItemType) => {
  const handlerOnClickDelete = () => {
    onSetDeleteItem(dbDetail.StoryDBConnectorID);
    onClickDelete();
  };

  const getImageByDbType = (dbType: string) => {
    switch (dbType) {
      case "mongodb":
        return ImageMongoDB;
      case "mysql":
        return ImageMySqlDB;
      case "postgresql":
        return ImagePostgres;
      case "firebase":
        return ImageFirebase;
      case "mariadb":
        return ImageMariaDB;
      case "sqlserver":
        return ImageSqlServer;
      case "oracle":
        return ImageOracle;
      default:
        return ImageMongoDB;
    }
  };

  return (
    <div className={styles.DBSelectedItem}>
      <div className={styles.dbIcon}>
        <img
          src={getImageByDbType(dbDetail.DBType)}
          alt="story-card-img"
          className={styles.img}
          style={{ width: "100%", height: "100%" , maxHeight:"33px", maxWidth:"33px"}}
        />
      </div>
      <div className={styles.dbName}>
        {dbDetail.DBItemName != ""
          ? dbDetail.DBItemName
          : dbDetail.DBConnectionName}
      </div>
      <div
        className={styles.delIconWrapper}
        id={
          dbDetail.DBItemName != ""
            ? dbDetail.DBItemName
            : dbDetail.DBConnectionName
        }
        onClick={handlerOnClickDelete}
      >
        <StoryEditDbDelete
          id={dbDetail.StoryDBConnectorID}
          className={styles.delIcon}
        />
      </div>
    </div>
  );
};

function EditStoryDBConnection({ storyID, isOpen }: Props) {
  const [dbConnectItems, setDbConnectItems] = useState<DBSelectedType[]>([]);
  let [storyDBConnector, setStoryDBConnector] = useState<StoryDBConnector[]>(
    []
  );
  let [dbSelects, setDbSelects] = useState<DBSelectedType[]>([]);
  let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let [itemToDelete, setItemToDelete] = useState<string>("");
  let [isNotificationSuccess, setIsNotificationSuccess] =
    useState<boolean>(false);

  async function apiGetDatabaseConnection() {
    const aspID = localStorage.getItem("asp_id");
    try {
      const response = await callApiMethodGet(GET_DATA_CONNECTOR_ENDPOINT, {
        asp_id: aspID,
      });
      const newData = response.data.map((item: any) => ({
        value: item.id,
        label:
          item.item_name != "" ? item.item_name : item.connection_name,
      }));
      setDbConnectItems(newData);
    } catch (error) {
      console.error("Error occurred:", error);
      setDbConnectItems([]);
    }
  }

  async function apiGetStoryDatabaseConnection() {
    try {
      const response = await callApiMethodGet(GET_DB_CONNECT_ENDPOINT, {
        story_id: storyID,
      });
      const newData = response.data.map((item: any) => ({
        StoryDBConnectorID: item.story_db_connector_id,
        DBConnectorID: item.db_connector_id,
        DBConnectionName: item.connection_name,
        DBItemName: item.item_name,
        DBName: item.db_name,
        DBType: item.db_type,
      }));
      setStoryDBConnector(newData);
    } catch (error) {
      console.error("Error occurred:", error);
      setStoryDBConnector([]);
    }
  }

  async function apiCreateStoryDBConnector(dbConnectorID: string[]) {
    try {
      const response = await callApiMethodPost(
        CREATE_STORY_DB_CONNECTOR_ENDPOINT,
        {
          db_connector_id: dbConnectorID,
          story_id: storyID,
        }
      );
      setIsNotificationSuccess(true);
      apiGetStoryDatabaseConnection();
    } catch (error) { }
  }

  async function apiDeleteDatabaseConnection(storyDBConnectorID: string) {
    try {
      const response = await callApiMethodDelete(
        DELETE_STORY_DB_CONNECTOR_ENDPOINT,
        {
          id: storyDBConnectorID,
        }
      );
      if (response.status == 200) {
        apiGetDatabaseConnection();
        apiGetStoryDatabaseConnection();
      }
    } catch (error) {
      console.log("error Delete Database Connector : ", error);
    }
  }

  useEffect(() => {
    apiGetDatabaseConnection();
    apiGetStoryDatabaseConnection();
  }, []);

  const handleChangeDBConnection = (selectVals: string[]) => {
    apiCreateStoryDBConnector(selectVals);
  };

  const handleModalDeleteOk = () => {
    let newDbSelects = dbSelects.filter(
      (dbSelected) => dbSelected.value !== itemToDelete
    );
    setDbSelects(newDbSelects);
    clearSelectAfterDelete();
    apiDeleteDatabaseConnection(itemToDelete);
    setIsModalOpen(false);
  };

  const handleModalDeleteCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalOpenModal = () => {
    setIsModalOpen(true);
  };

  const setDeleteItem = (item: string) => {
    setItemToDelete(item);
  };

  const clearSelectAfterDelete = () => {
    setItemToDelete("");
  };

  return (
    <div className={styles.editStDbContainer}>
      <ModalConfirmDelete
        modalDetail={{
          title: "Delete Database?",
          description: `Are you sure you want to delete this database? This action cannot be
      undone.`,
        }}
        isModalOpen={isModalOpen}
        onOk={handleModalDeleteOk}
        onCancel={handleModalDeleteCancel}
      />
      <div className={styles.dbConfig}>
        <div className={styles.dbConfigTitle}>Database Connection</div>
        <div className={styles.dbConfigSelect}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Please select connection"
            onChange={handleChangeDBConnection}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={dbConnectItems}
          />
        </div>
      </div>
      {storyDBConnector.length > 0 && (
        <div className={styles.dbSelected}>
          <div className={styles.dbSelectedTitle}>
            Database selected ({storyDBConnector.length})
          </div>
          <div className={styles.dbSelectedList}>
            {storyDBConnector.map((db) => {
              return (
                <DBSelectedItem
                  key={db.StoryDBConnectorID}
                  dbDetail={db}
                  onClickDelete={handleModalOpenModal}
                  onSetDeleteItem={setDeleteItem}
                />
              );
            })}
          </div>
        </div>
      )}
      {isNotificationSuccess == true ? (
        <CreateEntitySuccessNotify isCreated={"componentNameCreated"} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default EditStoryDBConnection;
