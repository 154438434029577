import React from "react";

const PlusSmallColorIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width||"32"} 
        height={height||"32"} 
        viewBox="0 0 32 32" 
        fill="none" 
        className={className}
        style={style}
        xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="5" fill={fill ||"#1C87F2"} />
            <path d="M22.6667 14.6667H17.3333V9.33333C17.3333 8.97971 17.1929 8.64057 16.9428 8.39052C16.6928 8.14048 16.3536 8 16 8C15.6464 8 15.3072 8.14048 15.0572 8.39052C14.8071 8.64057 14.6667 8.97971 14.6667 9.33333V14.6667H9.33333C8.97971 14.6667 8.64057 14.8071 8.39052 15.0572C8.14048 15.3072 8 15.6464 8 16C8 16.3536 8.14048 16.6928 8.39052 16.9428C8.64057 17.1929 8.97971 17.3333 9.33333 17.3333H14.6667V22.6667C14.6667 23.0203 14.8071 23.3594 15.0572 23.6095C15.3072 23.8595 15.6464 24 16 24C16.3536 24 16.6928 23.8595 16.9428 23.6095C17.1929 23.3594 17.3333 23.0203 17.3333 22.6667V17.3333H22.6667C23.0203 17.3333 23.3594 17.1929 23.6095 16.9428C23.8595 16.6928 24 16.3536 24 16C24 15.6464 23.8595 15.3072 23.6095 15.0572C23.3594 14.8071 23.0203 14.6667 22.6667 14.6667Z" fill={fill||"white"} />
        </svg>


    );
};

export default PlusSmallColorIcon;
