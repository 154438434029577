import renderBOScreens from "src/template/renderBoScreens";
import renderLabelScreens from "src/template/renderLabelScreens";
import renderButtonScreens from "src/template/renderButtonScreens";
import renderBreadcrumbScreens from "src/template/renderBreadcrumbScreens";
import renderTabsScreenContainer from "src/template/renderTabsScreensContainer";
import renderTableContainer from "src/template/renderTableContainer";
import {
  BO,
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  TAB,
  TABLE,
} from "src/constants/PageConfig";
import renderImageScreens from "src/template/renderImageScreens";
import renderIconScreens from "src/template/renderIconScreens";

export default function updateListTileOneToManyScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  index,
  pageName,
  JsonCONFIGSCREEN
) {
  const container = document.createElement("div");
  container.id = "container-edit-list-tile-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-containerWrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "edit");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "edit", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === BREADCRUMP) {
      renderBreadcrumbScreens(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TAB) {
      renderTabsScreenContainer(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TABLE) {
      renderTableContainer(
        screenElement,
        screen,
        mainScreen,
        maxColumn,
        "edit",
        JsonCONFIGSCREEN,
        pageName
      );
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  // if (!JsonCONFIGSCREEN.table) return;
  // updateTableOneToMany(container, mainScreen, maxColumn, JsonCONFIGSCREEN.table, 'edit');

  mainContainer.appendChild(container);
  console.log("container>>> ", container);
}

// function updateTableOneToMany(container, mainScreen, maxColumn, tables, label) {
//     tables.forEach(table => {
//         console.log("table", table);
//         let gridColumn = `1 / span ${maxColumn}`;
//         container.style.zIndex = 9;
//         container.style.gridColumn = gridColumn;
//         // สร้าง Search Form
//         const searchForm = createSearchForm();
//         container.appendChild(searchForm);

//         // สร้าง Add button
//         const contentAddButton = document.createElement('div');
//         contentAddButton.id = 'box-content-' + label + '-table-add-button-' + mainScreen.key;
//         contentAddButton.className = 'content-add-button';

//         let addBtnText = `<button id="content-${label}-table-add-button-${mainScreen.key}" class="ant-btn ant-btn-primary">
//                             <span>add</span>
//                     </button>`
//         contentAddButton.insertAdjacentHTML('beforeend', addBtnText);
//         container.appendChild(contentAddButton);

//         // สร้างตาราง
//         createTable(container, mainScreen, table, label);

//         // สร้าง Save button
//         const contentSaveButton = document.createElement('div');
//         contentSaveButton.id = 'box-content-table-save-button-' + mainScreen.key;
//         contentSaveButton.className = 'content-save-button';

//         let saveBtnText = `<button id="save-button-${label}-page-${mainScreen.configType}-${mainScreen.key}" class="ant-btn ant-btn-primary">
//                             <span>save</span>
//                         </button>`;
//         contentSaveButton.insertAdjacentHTML('beforeend', saveBtnText);
//         container.appendChild(contentSaveButton);
//     });
// }

// // สร้าง form search
// function createSearchForm() {
//     const searchForm = document.createElement('form');
//     searchForm.className = 'search-form';

//     const searchInput = document.createElement('input');
//     searchInput.type = 'text';
//     searchInput.placeholder = 'Search...';
//     searchInput.className = 'search-input dt-search';

//     const searchIcon = document.createElement('i');
//     searchIcon.className = 'anticon anticon-search';
//     searchIcon.style.position = 'absolute';
//     searchIcon.style.marginLeft = '5px';
//     searchIcon.style.color = 'rgba(171, 171, 171, 1)';

//     const clearIcon = document.createElement('i');
//     clearIcon.className = 'fas fa-times clear-icon';
//     clearIcon.style.display = 'none';

//     const searchButton = document.createElement('button');
//     searchButton.setAttribute('type', 'button');
//     searchButton.textContent = 'Search';
//     searchButton.className = 'ant-btn ant-btn-primary search-button';

//     searchForm.appendChild(searchInput);
//     searchForm.appendChild(searchIcon);
//     searchForm.appendChild(clearIcon);
//     searchForm.appendChild(searchButton);

//     return searchForm;
// };

// function createTable(container, mainScreen, table, label) {
//     // สร้างตาราง
//     const tableElement = document.createElement('table');
//     tableElement.id = `dataTable-${label}-${mainScreen.configType}-${mainScreen.key}`;
//     tableElement.className = 'table table-bordered';
//     tableElement.style.marginTop = '25px';
//     tableElement.style.width = '100%';

//     const tableHead = document.createElement('thead');
//     const tableHeadRow = document.createElement('tr');

//     console.log("table==> ", table);
//     // สร้างคอลัมน์ตามข้อมูลที่กำหนดใน table.columns
//     table.columns.forEach(column => {
//         const columnTh = document.createElement('th');
//         columnTh.textContent = column.column; // ใช้ชื่อคอลัมน์เป็นหัวข้อ
//         tableHeadRow.appendChild(columnTh);
//     });

//     // Add one more column (e.g., "additional_column")
//     const additionalColumnTh = document.createElement('th');
//     additionalColumnTh.textContent = "";
//     tableHeadRow.appendChild(additionalColumnTh);

//     tableHead.appendChild(tableHeadRow);
//     tableElement.appendChild(tableHead);

//     // สร้างส่วนเนื้อหาของตาราง (tbody)
//     const tableBody = document.createElement('tbody');
//     // const tableBodyRow = document.createElement('tr');

//     const newRow = tableBody.insertRow();
//     table.columns.forEach(column => {
//         const newCell = newRow.insertCell();
//         const inputElement = document.createElement('input');
//         inputElement.type = 'text';
//         inputElement.placeholder = column.column;
//         newCell.appendChild(inputElement);
//     });

//     // Add a cell for the Clear button
//     const actionCell = newRow.insertCell();

//     const clearButton = document.createElement('button');
//     clearButton.id = 'clear-cell-' + label + 'data-button';
//     clearButton.textContent = 'Clear';
//     clearButton.className = 'btn ant-btn';

//     // Append the Clear button to the action cell
//     actionCell.appendChild(clearButton);

//     // tableBody.appendChild(tableBodyRow);
//     tableElement.appendChild(tableBody);

//     // เพิ่มตารางลงใน container
//     container.appendChild(tableElement);
//     document.body.appendChild(container);
// };
