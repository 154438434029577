import { pathRoutes } from "src/page/props/util.props";

const getPathToGenPageWithStoryIDAndEntName = (path: string, entName: string) => {
  return `${path}/genpage?boname=${entName}`;
};
const getPathToGenPageWithBoId = (path: string, boId: string) => {
  return `${path}/genpage?bo_id=${boId}`;
};

const getPathToGenPageWithBoIdAndVersionID = (path: string, boId: string, versionId: string) => {
  return `${path}/genpage?bo_id=${boId}&verionId=${versionId}`;
};
// const getPathToStoryByStoryId = (path: string, storyId: string) => {
//   return `${path}/genpage?bo_id=${boId}`;
// }
const getStoryIDFromGenPagePath = (path: string) => {
  let leftWord = "story/";
  let rightWord = "/genpage";
  const pattern = new RegExp(`${leftWord}(.*?)${rightWord}`, "g");
  const match = pattern.exec(path);
  return match ? match[1] : null;
};
const getStoryIDFromEditStoryPath = (path: string) => {
  let pathArray = path.split("/");
  let storyID = pathArray[pathArray.length - 1]
  return storyID || "";
};
const getStoryEditIDPathFromGenPagePath = (path: string) => {
  const storyEditPath = `/${pathRoutes.MainDashboard}/story`;
  let storyID = getStoryIDFromGenPagePath(path);
  return `${storyEditPath}/${storyID}`;
};

export {
  getPathToGenPageWithStoryIDAndEntName,
  getStoryIDFromGenPagePath,
  getPathToGenPageWithBoId,
  getPathToGenPageWithBoIdAndVersionID,
  getStoryIDFromEditStoryPath,
  getStoryEditIDPathFromGenPagePath,
};
