import React, { useContext, useEffect, useState } from "react";
import styles from "../ControlItem.module.scss";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import { Button } from "antd";
import AddTabModal from "src/components/zero/zero-story/modal/modal-entity-genpage/create-edit-page-config/controls/AddTabModal";
import { v4 as uuidv4 } from "uuid";
import StoryEditEntGenPageRemove from "src/assets/svg/story-edit-ent-genpage-remove";
import BoFieldTool from "../../BoFieldTool";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import {
  getComponentPropsByConfigs,
  getDefaultComponentInCellConfigByConfigType,
  getPositionToolInMainPanel,
  getUpdatedTabActiveItemsWithConfigs,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import {
  defineRowIdInControlComponent,
  getCellPositionFromCellId,
  getComponentContainerIdByMainRowId,
  getGridItemsByActiveId,
  getRowIdFromCellId,
  getSubCellIdByMainRowIdAndColId,
  getSubFieldIdByMainRowIdAndColId,
} from "../../../utils/controls.util";
import { BUTTON } from "src/constants/PageConfig";
import { configs } from "@typescript-eslint/eslint-plugin";
import { BO, CONTROL } from "src/constants/ComponentType";

type Props = {
  rowNum: number;
  colNum: number;
  boFieldItems: any;
  controlItems: any;
  getControlComponentByControlId: (controlId: string) => React.JSX.Element;
  mainRowId?: string;
  onUpdateItems: (tabItems: any, rowId: string) => void;
};

type TabItemType = {
  id: string;
  tabNumId: number;
  name: string;
  componentRowItems?: RowItemType[];
};

type RowItemType = {
  id: string;
  rowId: string;
  cols: ColumnItemType[];
};

type ColumnItemType = {
  id: string;
  colId: string;
  childRef?: string;
  isControlItem?: boolean;
  controlComponent?: React.JSX.Element;
};

type ControlItemType = {
  id: string;
  controlName: string;
  icon: React.JSX.Element;
  isRowReplace: boolean;
};

function ControlTab({
  rowNum,
  colNum,
  boFieldItems,
  controlItems,
  getControlComponentByControlId,
  mainRowId,
  onUpdateItems,
}: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  let [tabItems, setTabItems] = useState<TabItemType[]>([]);
  let [activeTab, setActiveTab] = useState<string>("");
  let [tabNumIdActive, setTabNumIdActive] = useState<number>();
  let [componentGridItems, setComponentGridItems] = useState<RowItemType[]>([]);
  // let [focusItem, setFocusItem] = useState<string>("");

  //TAB STATE
  let [isOpenDeleteTabModal, setIsOpenDeleteTabModal] =
    useState<boolean>(false);
  let [isOpenAddTabModal, setIsOpenAddTabModal] = useState<boolean>(false);

  //BO COMPONENT STATE
  let [isOpenDeleteBoComp, setIsOpenDeleteBoComp] = useState<boolean>(false);

  //CONFIRM DELETE MODAL STATE
  let [itemIdToDelete, setItemIdToDelete] = useState<string>("");

  //DRAG STATE
  let [dragOverItem, setDragOverItem] = useState<string>("");

  //TOOL STATE
  let [isTabBreadToolOpen, setIsTabBreadToolOpen] = useState<boolean>(false);
  let [isCellToolOpen, setIsCellToolOpen] = useState<boolean>(false);
  let [isBoFieldToolOpen, setIsBoFieldToolOpen] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();
  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  //SET TAB FROM VERSION SAVED
  useEffect(() => {
    if(genPageContext.isVersionGenerated && isFirstLoad) {
      let component = genPageContext.versionSubComponent
      component = component.filter((item:any) => item.configType && item.configType === 'TAB');
      console.log("TAB component -----", component)

      let allTabItems = component.flatMap((item: any) => item.componentConfig);
      let allComponentGridItems = allTabItems.flatMap((comp: any) => comp.componentRowItems);
      console.log("allTabItems ------", allTabItems)
      // console.log("allComponentGridItems ------", allComponentGridItems.slice(0, 2))
      
      allComponentGridItems.forEach((item:any) => {
        item.cols.forEach((col:any) => {
          if(col.isControlItem){
            console.log("col.controlComponent ", col.controlComponent)
            let cellId = col.controlComponent.props.cellId
            let controlComponentId = getComponentId(col.configType)
            col.controlComponent = getControlComponentTosetComponent(cellId, controlComponentId)
          }
        })
      });

      console.log("allComponentGridItems ------ ", allComponentGridItems)

      setTabItems(allTabItems);
      setComponentGridItems(allComponentGridItems.slice(0, 2));
      if(allTabItems.length > 0) setActiveTab(allTabItems[0].id)

      setIsFirstLoad(false)
    }
 
  }, [isFirstLoad])

  //GET COMPONENT IN TAB
  const getControlComponentTosetComponent = (cellId :string, controlId: string) => {
    let controlComponent = getControlComponentByControlId(controlId);
    let props = controlComponent?.props;
    console.log(
      "updateChildInCellWithControlItemNotRowReplace props : ",
      props
    );
    let { configType } = props;
    let configs = getDefaultComponentInCellConfigByConfigType(configType);
    let componentProps = getComponentPropsByConfigs(configs);

    controlComponent = {
      ...controlComponent,
      props: {
        ...props,
        ...componentProps,
        cellId,
        onRemove: onRemoveComponentFieldInCell,
      },
    };
    console.log(
      "ControlTab updateChildInCellWithControlItemNotRowReplace controlComponent => ",
      controlComponent
    );

    return controlComponent
  }

  const getComponentId = (componentText: string) => {
    let componentId = ''
    switch (componentText){
      case 'TAB':
        componentId = 'ct1'
        break;
      case 'BREADCRUMP':
        componentId = 'ct2'
        break;
      case 'BUTTON':
        componentId = 'ct3'
        break;
      case 'ICON':
        componentId = 'ct4'
        break;
      case 'IMAGE':
        componentId = 'ct5'
        break;
      case 'TEXT':
        componentId = 'ct6'
        break;
      case 'TABLE':
        componentId = 'ct7'
        break;
      default: 
        componentId = ''
        break;
    }
    return componentId
  }

  //CHECK [componentGridItems,tabItems] 
  useEffect(() => {
    console.log("componentGridItems ........", componentGridItems)
    console.log("tabItems ........", tabItems)
  },[componentGridItems,tabItems])

  //REMOVE STATE
  let [isRemoveFieldComponent, setIsRemoveFieldComponent] =
    useState<boolean>(false);

  //CHECK FOCUS ON PARENT
  useEffect(() => {
    let itemFocus = genPageContext.itemFocusOnConfigPage;
    // console.log("itemFocusOnConfigPage => ", itemFocus);

    if (!itemFocus || itemFocus.includes("main")) {
      clearOnClickBlankSpaceSubPage();
      return;
    }

    let { rowId: focusRowId, cellId: focusCellId } = getCellIdFromFocusItem();

    //DIV FOR GET_POSITION NOT NULL AND CHECK ROW SELECTED
    if (mainRowId !== focusRowId) {
      closeAllTool();
      return;
    }

    let cellFocusEle = document.getElementById(focusCellId); //GET CELL POSITION BY ELEMENT_ID
    let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID

    if (cellFocusEle && outerDivEle) {
      let { top, left } = getPositionToolInMainPanel(cellFocusEle, outerDivEle);
      console.log(`top: ${top}, left: ${left}`);

      if (itemFocus.includes("field")) {
        //{sub-r3_field-r1-c1}
        setIsBoFieldToolOpen(true);
        setIsCellToolOpen(false);
      } else if (itemFocus.includes("cell")) {
        //{sub-r3_cell-r1-c1}
        setIsBoFieldToolOpen(false);
        setIsCellToolOpen(true);
      }

      setPositionTool({ left, top });
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //CHECK ON NUMBER OF ROW AND COLUMN CHANGE
  useEffect(() => {
    console.log(`row: ${rowNum}, col: ${colNum}`);

    if (tabItems.length === 0) return;
    let newTabItems = getGridItemsAllTabFromNumberOfRowAndCol();
    setTabItems(newTabItems);

    let tabGridItems = getGridItemsByActiveId(newTabItems, activeTab);
    setComponentGridItems(tabGridItems as RowItemType[]);

    closeAllTool();
  }, [rowNum, colNum]);

  //CHECK AND SET ACTIVE TAB ON ITEMS === 1
  useEffect(() => {
    if (tabItems.length === 1) setActiveToFirstTab();
  }, [tabItems]);

  //CHECK ON ACTIVE TAB
  useEffect(() => {
    console.log("Active tab => ", activeTab);
    if (!activeTab) return;

    let tabActiveItems = getTabGridItemsByTabId(activeTab);
    console.log("ControlTab tabActiveItems => ", tabActiveItems)
    let updatedTabActiveItems =
      getUpdatedTabActiveItemsWithConfigs(tabActiveItems);
    setComponentGridItems(updatedTabActiveItems as RowItemType[]);

    //CHECK TAB NAME
    const tabObj = tabItems.find((item: any) => item?.id === activeTab);
    console.log("activeTab tabObj => ", tabObj);
    setTabNumIdActive(tabObj?.tabNumId || 0);

    removeFucusItem();
  }, [activeTab]);

  //ACTION HANDLER
  const actionAddNewTab = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpenAddTabModal(true);
  };

  const onClickRemoveTab = (
    e: React.MouseEvent<HTMLDivElement>,
    idToRemove: string
  ) => {
    e.stopPropagation();

    setItemIdToDelete(idToRemove);
    setIsOpenDeleteTabModal(true);
  };

  const onSaveNewTabName = (newTabName: string) => {
    console.log("onSaveNewTabName newTabName => ", newTabName);
    const id = uuidv4();

    //SET ITEM WITH TAB
    let tabNumId = getMaxTabNum() + 1;
    let rowColItems = getGridItemsFromNumberOfRowAndCol(tabNumId);
    // console.log("onSaveNewTabName rowColItems => ", rowColItems);

    let newTabItem: TabItemType = {
      id,
      tabNumId,
      name: newTabName,
      componentRowItems: rowColItems,
    };
    // console.log("onSaveNewTabName newTabItem => ", newTabItem);
    let newTabItems = [...tabItems, newTabItem];

    onAddTab(newTabItems);
    setIsOpenAddTabModal(false);
  };

  //ADD TAB HANDLER
  const onCancelAddTab = () => {
    setIsOpenAddTabModal(false);
  };

  const onOkAddTab = () => {
    setIsOpenAddTabModal(false);
  };

  const getMaxTabNum = () => {
    let tabs = [...tabItems];
    if (tabs.length === 0) return 0;
    let max = Math.max(...tabs.map((obj) => obj?.tabNumId));
    console.log(max);
    return max;
  };

  const onClickTab = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation();
    console.log("onClickTab id => ", id);
    setActiveTab(id);
  };

  //TAB MANAGEMENT
  const onAddTab = (newTabItems: TabItemType[]) => {
    setTabItems(newTabItems);
    onUpdateItems(newTabItems, mainRowId as string);
  };

  const onDeleteTab = (newTabItems: TabItemType[]) => {
    if (newTabItems.length === 0) {
      setComponentGridItems([]);
      onUpdateItems([], mainRowId as string);
    } else if (newTabItems.length > 0 && activeTab === itemIdToDelete) {
      setActiveToFirstTab();
      let newTabGridItems = getTabGridItemsByTabId(activeTab);
      setComponentGridItems(newTabGridItems as RowItemType[]);
      onUpdateItems(newTabItems, mainRowId as string);
    }

    setItemIdToDelete("");
    setTabItems(newTabItems);
  };

  //CELL DATA MANAGEMENT
  const onClickCell = (e: React.MouseEvent<HTMLDivElement>, colId: string) => {
    e.stopPropagation();
    console.log("onClickCell in component colID => ", colId);
    genPageContext.setItemFocusOnConfigPage(colId);
  };

  const getCombinateOldAndNewColsInTabItem = (
    oldCols: ColumnItemType[],
    newCols: ColumnItemType[]
  ): ColumnItemType[] => {
    let result: ColumnItemType[] = [];
    for (const newCol of newCols) {
      let newCombinateItem: ColumnItemType;
      let oldCol = oldCols.find((item) => item.colId === newCol.colId);
      if (oldCol) {
        newCombinateItem = oldCol;
      } else {
        newCombinateItem = newCol;
      }
      result.push(newCombinateItem);
    }
    return result;
  };

  const getCombinateOldAndNewGridItems = (
    oldGridItems: TabItemType[],
    newGridItems: TabItemType[]
  ) => {
    let result: TabItemType[] = [];

    for (let index = 0; index < oldGridItems.length; index++) {
      let newRowItems = newGridItems[index]?.componentRowItems;
      let oldRowItems = oldGridItems[index]?.componentRowItems;

      let newRowUpdateItems: RowItemType[] = [];
      for (const iRow in newRowItems as RowItemType[]) {
        let rowItem: RowItemType = (newRowItems as RowItemType[])[iRow];

        let newCombinateCols: ColumnItemType[] = [];
        if (!(oldRowItems as RowItemType[])[iRow]) {
          newCombinateCols = (newRowItems as RowItemType[])[iRow].cols;
        } else {
          let oldCols = (oldRowItems as RowItemType[])[iRow].cols;
          let newCols = (newRowItems as RowItemType[])[iRow].cols;

          newCombinateCols = getCombinateOldAndNewColsInTabItem(
            oldCols,
            newCols
          );
        }

        let newRowUpdateItem: RowItemType = {
          id: rowItem.id,
          rowId: rowItem.rowId,
          cols: newCombinateCols,
        };

        newRowUpdateItems.push(newRowUpdateItem);
      }

      let newBcItem: TabItemType = {
        id: oldGridItems[index].id,
        tabNumId: oldGridItems[index].tabNumId,
        name: oldGridItems[index].name,
        componentRowItems: newRowUpdateItems,
      };
      result.push(newBcItem);
    }

    return result;
  };

  const getGridItemsAllTabFromNumberOfRowAndCol = () => {
    let newTabItems: TabItemType[] = [];
    for (const item of tabItems) {
      let componentRowItems = getGridItemsFromNumberOfRowAndCol(item.tabNumId);
      let newItem: TabItemType = {
        id: item.id,
        tabNumId: item.tabNumId,
        name: item.name,
        componentRowItems,
      };
      newTabItems.push(newItem);
    }

    let newCombinateGridItems = getCombinateOldAndNewGridItems(
      tabItems,
      newTabItems
    );
    return newCombinateGridItems;
  };

  const getGridItemsFromNumberOfRowAndCol = (
    tabNumId: number
  ): RowItemType[] => {
    let result: RowItemType[] = [];

    for (let row = 1; row <= rowNum; row++) {
      let rId = uuidv4();
      let rowId = `r${row}`;

      let cols: ColumnItemType[] = [];
      for (let col = 1; col <= colNum; col++) {
        let cId = uuidv4();
        let colId = `r${row}-c${col}_${tabNumId}`;

        let colItem: ColumnItemType = {
          id: cId,
          colId,
        };
        cols.push(colItem);
      }

      let rowItem = {
        id: rId,
        rowId,
        cols,
      };

      result.push(rowItem);
    }

    return result;
  };

  const getTabGridItemsByTabId = (activeTab: string) => {
    let tabGridObj = tabItems.find((tab) => tab.id === activeTab);
    if (!tabGridObj) return;

    let tabGridItem = tabGridObj?.componentRowItems;
    return tabGridItem;
  };

  const onAddChildInCellByBofieldItem = (
    cellId: string,
    componentId: string
  ) => {
    let newRowItem = updateChildInCellWithBoFieldItem(cellId, componentId);
    // console.log("onAddChildInCellByBofieldItem newRowItem => ", newRowItem);

    let newComponentGridItems =
      replaceChildInsideCellInGridPanelItemsComponentTab(cellId, newRowItem);
    // console.log(
    //   "onAddChildInCellByBofieldItem newComponentGridItems => ",
    //   newComponentGridItems
    // );

    setNewTabItemsOnDropItem(newComponentGridItems);
  };

  const onAddChildInCellByControlItem = (
    cellId: string,
    componentId: string,
    isRowReplace?: boolean
  ) => {
    let newRowItem: any;
    if (isRowReplace) {
      newRowItem = updateChildInCellWithControlItemRowReplace(
        cellId,
        componentId
      );
    } else {
      newRowItem = updateChildInCellWithControlItemNotRowReplace(
        cellId,
        componentId
      );
    }

    let newGridPanelItems: any =
      replaceChildInsideCellInGridPanelItemsComponentTab(cellId, newRowItem);
    setNewTabItemsOnDropItem(newGridPanelItems);
  };

  const setNewTabItemsOnDropItem = (newComponentGridItems: any) => {
    if (newComponentGridItems.length === 0) return;

    let newTabItems = tabItems.map((item) => {
      if (item.id === activeTab) {
        return {
          ...item,
          componentRowItems: newComponentGridItems,
        };
      } else {
        return item;
      }
    });

    // console.log("setNewTabItemsByActiveTab newTabItems => ", newTabItems)
    setTabItems(newTabItems);
    setComponentGridItems(newComponentGridItems);
    onUpdateItems(newTabItems, mainRowId as string);
  };

  const updateChildInCellWithBoFieldItem = (
    cellId: string,
    childRef: string
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let cellPosition = getCellPositionFromCellId(cellId) + "_" + tabNumIdActive;
    let newRowItem: any = componentGridItems.find(
      (item: any) => item.rowId === rowId
    );

    let gPanelItem = newRowItem.cols.map((item: any) => {
      if (item.colId === cellPosition) {
        return {
          ...item,
          childRef,
        };
      } else {
        return item;
      }
    });
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
    };
    // console.log("newRowItem => ", newRowItem);
    return newRowItem;
  };

  const updateChildInCellWithControlItemNotRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);
    let props = controlComponent?.props;
    console.log(
      "updateChildInCellWithControlItemNotRowReplace props : ",
      props
    );
    let { configType } = props;
    let configs = getDefaultComponentInCellConfigByConfigType(configType);
    let componentProps = getComponentPropsByConfigs(configs);

    controlComponent = {
      ...controlComponent,
      props: {
        ...props,
        ...componentProps,
        cellId,
        onRemove: onRemoveComponentFieldInCell,
      },
    };
    console.log(
      "ControlTab updateChildInCellWithControlItemNotRowReplace controlComponent => ",
      controlComponent
    );

    let rowId = getRowIdFromCellId(cellId);
    let newRowItem: any = componentGridItems.find(
      (item: any) => item.rowId === rowId
    );

    let colId = getCellPositionFromCellId(cellId) + "_" + tabNumIdActive;
    let colsInRowItem = newRowItem.cols.map((item: any) => {
      if (item.colId === colId) {
        return {
          ...item,
          childIdRef: "",
          isControlItem: true,
          controlComponent: controlComponent,
          configs,
        };
      } else {
        return item;
      }
    });
    console.log("new colsInRowItem => ", colsInRowItem);
    newRowItem = {
      ...newRowItem,
      cols: colsInRowItem,
    };
    return newRowItem;
  };

  const updateChildInCellWithControlItemRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);

    let rowId = getRowIdFromCellId(cellId);
    let newRowItem: any = componentGridItems.find(
      (item: any) => item.rowId === rowId
    );

    let newControlComponent = defineRowIdInControlComponent(
      controlComponent,
      rowId
    );

    let gPanelItem = [
      {
        colId: "main_cell-" + rowId + "-c1",
        childRef: "",
      },
    ];
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
      isControlItem: true,
      controlComponent: newControlComponent,
    };
    // console.log("newRowItem => ", newRowItem);
    return newRowItem;
  };

  const replaceChildInsideCellInGridPanelItemsComponentTab = (
    cellId: string,
    newRowItem: any
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let itemChangedIndex = componentGridItems.findIndex(
      (item: any) => item.rowId === rowId
    );

    //SPLIT ITEMS AS 2 SIDE BY INDEX OF ITEM_CHANGED
    let leftGridItems = [...componentGridItems].splice(0, itemChangedIndex);
    let rightGridItems = [...componentGridItems].splice(
      itemChangedIndex + 1,
      componentGridItems.length
    );

    let newGridPanelItems = leftGridItems.concat(newRowItem, rightGridItems);

    return newGridPanelItems;
  };

  const getComponentFromChildIdRef = (childRef: string) => {
    if (!childRef || boFieldItems?.length === 0) return;
    let componentItem = boFieldItems.find((item: any) => item.id === childRef);
    return componentItem?.boField;
  };

  const getIdForRemoveComponentField = (focusItem: string) => {
    let cellId = getSubCellIdForRemoveByFocusItem(focusItem);
    let cellPosition = cellId.split("_")[1];
    let cellPositionSplit = cellPosition.split("-");
    let idForRemove = `${cellPositionSplit[1]}-${cellPositionSplit[2]}_${
      cellId.split("_")[2]
    }`;
    return idForRemove;
  };

  const getSubCellIdForRemoveByFocusItem = (focusItem: string) => {
    let focusItemSplit = focusItem.split("_");
    return `${focusItemSplit[0]}_${focusItemSplit[1]}_${focusItemSplit[2]}`;
  };

  const onRemoveFieldInCell = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let cellPosition = focusItem.split("_")[1];
    let cellPositionSplit = cellPosition.split("-");
    let idForRemove =
      cellPositionSplit[1] + "-" + cellPositionSplit[2] + "_" + tabNumIdActive; //r1-c1_1;

    //GET NEW ROW AFTER REMOVE CHILD
    let newRowItem = removeFieldInCell(idForRemove);
    // console.log("onOkConfirmDeleteHandler newRowItem => ", newRowItem);

    //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
    let newComponentGridItems =
      replaceChildInsideCellInGridPanelItemsComponentTab(focusItem, newRowItem);
    console.log(
      "onRemoveFieldInCell newComponentGridItems => ",
      newComponentGridItems
    );
    setNewTabItemsOnDropItem(newComponentGridItems);
    genPageContext.setItemFocusOnConfigPage("");

    setIsBoFieldToolOpen(false);
  };

  const removeFieldInCell = (cellId: string) => {
    let rowId = cellId.split("-")[0]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
    let newRowItem: any = componentGridItems.find(
      (item: any) => item.rowId === rowId
    );

    let gPanelItem = newRowItem.cols.map((item: any) => {
      if (item.colId === cellId) {
        return {
          ...item,
          childRef: "",
          isControlItem: false,
          controlComponent: null,
          configs: null
        };
      } else {
        return item;
      }
    });
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
    };
    return newRowItem;
  };

  //-----------------------REMOVE COMPONENT FIELD IN CONTROL_TAB-----------------------//
  const onRemoveComponentFieldInCell = (cellId: string) => {
    console.log("onRemoveComponentFieldInCell cellId => ", cellId);
    setIsRemoveFieldComponent(true);
  };

  useEffect(() => {
    if (isRemoveFieldComponent) {
      let focusItem = genPageContext.itemFocusOnConfigPage;
      let cellId = getSubCellIdForRemoveByFocusItem(focusItem);
      let idForRemove = getIdForRemoveComponentField(focusItem);
      // console.log("onRemoveFieldInCell idForRemove => ", idForRemove);

      //GET NEW ROW AFTER REMOVE CHILD
      let newRowItem = removeFieldInCell(idForRemove);
      // console.log("onRemoveFieldInCell newRowItem => ", newRowItem);

      //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
      let newComponentGridItems =
        replaceChildInsideCellInGridPanelItemsComponentTab(cellId, newRowItem);
      // console.log(
      //   "onRemoveFieldInCell newComponentGridItems => ",
      //   newComponentGridItems
      // );
      setNewTabItemsOnDropItem(newComponentGridItems);

      genPageContext.setItemFocusOnConfigPage("");
      setIsRemoveFieldComponent(false);
    }
  }, [isRemoveFieldComponent]);
  //-----------------------^REMOVE COMPONENT FIELD IN CONTROL_TAB^-----------------------//

  //DRAG DROP HANDLER
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let cellId = e.currentTarget.id;
    console.log("handleDrop in cellId : ", cellId);
    setDragOverItem("");

    let dropItemDetailStr = e.dataTransfer.getData("text/plain");
    let dropItemDetailObj: any =
      getDropItemObjFromDataTransfer(dropItemDetailStr);
    console.log("Item dropped droppedItemId ", dropItemDetailObj);
    let { componentId, componentType, isRowReplace } = dropItemDetailObj;

    if (componentType === BO) {
      // console.log("BO item drop => ", componentId);
      onAddChildInCellByBofieldItem(cellId, componentId);
    } else if (componentType === CONTROL) {
      // console.log("CONTROL item drop => ", componentId);
      onAddChildInCellByControlItem(cellId, componentId, isRowReplace);
    }
    // onAddChildInCellByBofieldItem(cellId, componentId);
  };

  const getDropItemObjFromDataTransfer = (dropItemDetailStr: string) => {
    let result = {};

    let dropItemDetailObj = JSON.parse(dropItemDetailStr);
    let { componentId, componentType } = dropItemDetailObj;

    if (componentType === CONTROL) {
      let itemObjFromControlItem = controlItems.find(
        (item: any) => item.id === componentId
      );

      //IF ITEM FROM CONTROL_ITEM IS NOT NULL THEN RESULT = ITEM
      if (!itemObjFromControlItem) result = dropItemDetailObj;
      result = {
        ...dropItemDetailObj,
        isRowReplace: (itemObjFromControlItem as ControlItemType).isRowReplace,
      };
    } else {
      result = dropItemDetailObj;
    }

    return result;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragOver id : ", id);
    setDragOverItem(id);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragLeave id : ", id);
    setDragOverItem("");
  };

  //MODAL DELETE TAB CONFIRMATION
  const onOkDeleteTabHandler = () => {
    if (itemIdToDelete) {
      let newTabItems = tabItems.filter((item) => item.id !== itemIdToDelete);
      onDeleteTab(newTabItems);
    }

    setIsOpenDeleteTabModal(false);
  };

  const onCancelDeleteTabHandler = () => {
    setIsOpenDeleteTabModal(false);
  };

  //MODAL DELETE BO FIELD CONFIRMATION
  const onClickRemoveChildHandler = () => {
    setIsOpenDeleteBoComp(true);
  };

  const onOkDeleteBoComponentHandler = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    console.log("onOkConfirmDeleteHandler focusBoItem => ", focusBoItem);

    if (focusBoItem.includes("field")) {
      onRemoveFieldInCell();
    }

    setIsOpenDeleteBoComp(false);
  };

  const onCancelDeleteBoComponentHandler = () => {
    setIsOpenDeleteBoComp(false);
  };

  //UTILITY FUNCTION
  const removeFucusItem = () => {
    genPageContext.setItemFocusOnConfigPage("");
  };

  const clearOnClickBlankSpaceSubPage = () => {
    console.log("clearOnClickBlankSpaceSubPage");
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsTabBreadToolOpen(false);
  };

  const closeAllTool = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    setIsTabBreadToolOpen(false);
  };

  const getCellIdFromFocusItem = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let rowDetail = focusItem.split("_")[0];
    let rowId = rowDetail.split("-")[1];
    if (focusItem.includes("field")) {
      return { rowId, cellId: focusItem.replace("field", "cell") };
    } else {
      return { rowId, cellId: focusItem };
    }
  };

  const setActiveToFirstTab = () => {
    if (tabItems.length === 0) return;

    let tabId = tabItems[0].id;
    setActiveTab(tabId);
  };

  const getSubCellIdByColId = (colId: string) => {
    return getSubCellIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getSubFieldIdByColId = (colId: string) => {
    return getSubFieldIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getTabContainerId = () => {
    return getComponentContainerIdByMainRowId(mainRowId as string);
  };

  //COMPONENT
  const AddTab = () => {
    return (
      <Button
        className={styles.tabHeaderAddBtn}
        type="dashed"
        size={"middle"}
        onClick={(e) => actionAddNewTab(e)}
      >
        Add Tab
      </Button>
    );
  };

  const TabHeader = () => {
    return (
      <div className={styles.tabHeader}>
        {tabItems.map((item) => {
          return (
            <div
              key={item.id}
              className={
                item.id === activeTab
                  ? styles.tabHeaderActiveBtn
                  : styles.tabHeaderBtn
              }
              onClick={(e) => onClickTab(e, item.id)}
            >
              <span>{item.name}</span>
              <div onClick={(e) => onClickRemoveTab(e, item.id)}>
                <StoryEditEntGenPageRemove
                  style={
                    item.id === activeTab
                      ? { fill: "#fff" }
                      : { fill: "#1c87f2" }
                  }
                />
              </div>
            </div>
          );
        })}
        <AddTab />
      </div>
    );
  };

  return (
    <>
      <div id={getTabContainerId()} className={styles.controlTabContainer}>
        {/* <h3 style={{ textAlign: "center", margin: 0 }}>
          {"TabComponent focus on => " + genPageContext.itemFocusOnConfigPage}
        </h3> */}
        <TabHeader />
        <table className={styles.table}>
          <tbody>
            {componentGridItems?.map((row) => {
              return (
                <tr key={row.id} id={row.rowId} className={styles.tableTr}>
                  {row.cols?.map((col) => {
                    return (
                      <td
                        key={col.id}
                        id={getSubCellIdByColId(col.colId)}
                        className={[
                          dragOverItem === getSubCellIdByColId(col.colId)
                            ? styles.tableTdDragOver
                            : styles.tableTd,
                          genPageContext.itemFocusOnConfigPage ===
                          getSubCellIdByColId(col.colId)
                            ? styles.tableTdFocus
                            : "",
                        ].join(" ")}
                        onClick={(e) =>
                          onClickCell(e, getSubCellIdByColId(col.colId))
                        }
                        onDrop={(e) => handleDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                      >
                        {col.isControlItem ? (
                          <div
                            className={
                              genPageContext.itemFocusOnConfigPage ===
                              col.colId + "_field"
                                ? styles.controlComponentCellFocus
                                : styles.controlComponentCell
                            }
                            onClick={(e) =>
                              onClickCell(e, getSubFieldIdByColId(col.colId))
                            }
                          >
                            {col.isControlItem
                              ? col.controlComponent
                              : getComponentFromChildIdRef(
                                  col.childRef as string
                                )}
                          </div>
                        ) : col.childRef ? (
                          <div
                            className={
                              genPageContext.itemFocusOnConfigPage ===
                              col.colId + "_field"
                                ? styles.insideTableTdFocus
                                : styles.insideTableTd
                            }
                            onClick={(e) =>
                              onClickCell(e, getSubFieldIdByColId(col.colId))
                            }
                          >
                            {col.isControlItem
                              ? col.controlComponent
                              : getComponentFromChildIdRef(
                                  col.childRef as string
                                )}
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* TOOL */}
      <BoFieldTool
        isShow={isBoFieldToolOpen}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onClick={onClickRemoveChildHandler}
      />

      {/* CONFIRM DELETE TAB */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete BO Tab?",
          description: `Are you sure you want to delete this Tab? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteTabModal}
        onOk={onOkDeleteTabHandler}
        onCancel={onCancelDeleteTabHandler}
      />

      {/* ADD TAB  MODAL*/}
      <AddTabModal
        isModalOpen={isOpenAddTabModal}
        onOk={onOkAddTab}
        onCancel={onCancelAddTab}
        onSaveNewTabName={onSaveNewTabName}
        tabNameList={tabItems.map((item) => item.name)}
      />

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete BO Field?",
          description: `Are you sure you want to delete this BO Field? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteBoComp}
        onOk={onOkDeleteBoComponentHandler}
        onCancel={onCancelDeleteBoComponentHandler}
      />
    </>
  );
}

export default ControlTab;
