export default function renderButtonScreens(screenElement, screen, label, mainScreen, pageName) {
    const button = document.createElement('button');
    // ตรวจสอบว่ามี label หรือไม่
    const buttonId = label ? `btn-${mainScreen.key}-${pageName}` : `${mainScreen.key}`;
    button.id = buttonId;

    button.textContent = screen.label;
    button.className = `btn-general ant-btn ant-btn-${screen.buttonType}`;
    // Add click event listener
    button.addEventListener('click', function () {
        // console.log(`Button "${screen.label}" clicked.`);
    });

    screenElement.appendChild(button);
}