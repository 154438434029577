import React from "react";

const StoryEditEntGenPageBreadCrump = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 6"
      width={width || "14"}
      height={height || "6"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1591 0.15625C9.73197 0.15625 8.54741 1.21415 8.34802 2.58695H7.58466C7.39979 1.8263 6.7133 1.25965 5.89657 1.25965C5.07984 1.25965 4.39337 1.8263 4.2085 2.58695H3.42552C3.24064 1.8263 2.55415 1.25965 1.73742 1.25965C0.779406 1.25965 0 2.03909 0 2.9971C0 3.95512 0.779406 4.73455 1.73745 4.73455C2.55418 4.73455 3.24067 4.16793 3.42554 3.40726H4.20853C4.3934 4.16791 5.07989 4.73455 5.89662 4.73455C6.71336 4.73455 7.39985 4.16793 7.58472 3.40726H8.34805C8.54744 4.78002 9.732 5.83795 11.1592 5.83795C12.7256 5.83795 14 4.56354 14 2.9971C14 1.43066 12.7256 0.15625 11.1591 0.15625ZM1.73745 3.91424C1.23173 3.91424 0.820312 3.5028 0.820312 2.9971C0.820312 2.49141 1.23173 2.07996 1.73745 2.07996C2.24317 2.07996 2.65456 2.49141 2.65456 2.9971C2.65456 3.5028 2.24314 3.91424 1.73745 3.91424ZM5.8966 3.91424C5.3909 3.91424 4.97946 3.50282 4.97946 2.9971C4.97946 2.49138 5.39088 2.07996 5.8966 2.07996C6.40232 2.07996 6.81373 2.49138 6.81373 2.9971C6.81373 3.50282 6.40229 3.91424 5.8966 3.91424ZM11.1591 5.01764C10.045 5.01764 9.13861 4.11125 9.13861 2.9971C9.13861 1.88295 10.045 0.976563 11.1591 0.976563C12.2733 0.976563 13.1797 1.88298 13.1797 2.9971C13.1797 4.11122 12.2733 5.01764 11.1591 5.01764Z"
        fill="#374957"
      />
      <path
        d="M10.8693 3.00364L10.3585 2.53352L9.80295 3.1371L10.9134 4.15921L12.3873 2.57825L11.7873 2.01888L10.8693 3.00364Z"
        fill="#374957"
      />
    </svg>
  );
};
export default StoryEditEntGenPageBreadCrump;
