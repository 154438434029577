import React from "react";

const StoryEditEntGenPageCard = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 16 16"
      width={width || "16"}
      height={height || "16"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <g clipPath="url(#clip0_2408_123440)">
          <path
            d="M14 8C14 4.6914 11.3086 2 8 2C4.6914 2 2 4.6914 2 8C2 11.3086 4.69187 14 8 14C11.3081 14 14 11.3086 14 8ZM2.92951 8C2.92951 5.20449 5.20402 2.92951 8 2.92951C10.796 2.92951 13.0705 5.20449 13.0705 8C13.0705 10.796 10.796 13.0705 8 13.0705C5.20402 13.0705 2.92951 10.7955 2.92951 8Z"
            fill="#393939"
          />
          <path
            d="M7.36129 10.6569L9.68507 8.33317C9.86539 8.1519 9.86539 7.85912 9.68507 7.67785L7.36129 5.35407C7.18747 5.20489 6.93046 5.20489 6.75664 5.35407C6.5619 5.52092 6.53914 5.81464 6.706 6.00938L8.69978 8.00319L6.70597 10.0016C6.52564 10.1829 6.52564 10.4757 6.70597 10.6569C6.88724 10.8373 7.18004 10.8373 7.36129 10.6569Z"
            fill="#393939"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2408_123440">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="matrix(0 1 -1 0 14 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default StoryEditEntGenPageCard;
