import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import styles from '../modal/ModalSuccess.module.scss';
import CheckCircleIcon from "src/assets/svg/check-circle";

type ModalSuccessProps = {
    open: boolean;
    onClose: () => void,
    name:string
    title:string
    description:string
};


function ModalSuccess({ open, onClose,name, title,description }: ModalSuccessProps) {
    useEffect(() => {
        if(open) {
          window.parent.postMessage({event: "zero_open_modal", key: ""}, "*");
        } else {
          window.parent.postMessage({event: "zero_close_modal", key: ""}, "*");
        }
      }, [open])

    return (
        <Modal
            open={open}
            width={400}
            footer={[
                <Button key="ok" style={{width:'100%'}} type="primary" onClick={onClose}>OK</Button>,
            ]}
            onCancel={onClose}
        >
            <div className={styles.modalContainer}>
                 <CheckCircleIcon />
                <div className={styles.titleText}>
                    {name} {title}
                </div>
                <div className={styles.descriptionText}>
                    {description}
                </div>
            </div>
        </Modal>
    );
}
export default ModalSuccess;