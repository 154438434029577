//@ts-ignore
// let go = window.go;
import * as go from "gojs";


/*
 * "BoTreeExpanderButton" is only used in Business object Diagram
 * */

go.GraphObject.defineBuilder("BoTreeExpanderButton", function(args:any) {
	var button =
		go.GraphObject.make("Button",
		  { // set these values for the isTreeExpanded binding conversion
			"_treeExpandedFigure": window.location.origin+"/zero/assets/images/del_btn.svg",
			"_treeCollapsedFigure": window.location.origin+"/zero/assets/images/plus_btn.svg"  
		  },
		  go.GraphObject.make(go.Picture,  // the icon
			{
			  name: "ButtonIcon",
			  source: window.location.origin+"/zero/assets/images/plus_btn.svg",  // default value for isTreeExpanded is true
			  desiredSize: new go.Size(16, 16)
			},
			// bind the Shape.figure to the Node.isTreeExpanded value using this converter:
			new go.Binding("source", "isTreeExpanded",
						   function(exp:any, shape:any) {
							 var button = shape.panel;
							 return exp ? button["_treeExpandedFigure"] : button["_treeCollapsedFigure"];
						   })
				.ofObject()),
		  // assume initially not visible because there are no links coming out
		  { visible: true }
		  // bind the button visibility to whether it's not a leaf node
		  /*,new go.Binding("visible", "isTreeLeaf",
						 function(leaf) { return !leaf; })
			  .ofObject()*/);

	// tree expand/collapse behavior
	button.click = function(e:any, button:any) {
	  var node = button.part;
	  if (node instanceof go.Adornment) node = node.adornedPart;
	  if (!(node instanceof go.Node)) return;
	  var diagram = node.diagram;
	  if (diagram === null) return;
	  var cmd = diagram.commandHandler;
	  if (node.isTreeExpanded) {
		if (!cmd.canCollapseTree(node)) return;
	  } else {
		if (!cmd.canExpandTree(node)) return;
	  }
	  e.handled = true;
	  if (node.isTreeExpanded) {
		cmd.collapseTree(node);
	  } else {
		cmd.expandTree(node);
	  }
	};

	return button;
  });


  
export class ParameterTemplate{
	public $$:any;
	constructor($$:any){
		this.$$ = $$;
	}

	public treeSetting(marginObj:any){
		
		return   this.$$("BoTreeExpanderButton", {
			width : 16,
			height : 16,
			"ButtonBorder.fill": null,
			"ButtonBorder.stroke": null,
			"_buttonStrokeNormal": null,									
			"_buttonStrokeOver": null,
			"_buttonFillOver": null,
			margin:  marginObj
		});
	};
	
	public buttonParamEvent(buttonImg:string, tooltipText:any, funcEvent:any){
		
		return this.$$("ButtonCircle", 
				{  visible : false,"_buttonFillOver": "white"
					,"_buttonFillNormal":"white", "ButtonBorder.fill" :"white"
					,click : function(e:any,obj:any){funcEvent()}
					,toolTip:  // define a tooltip for each node that displays the color as text
				          this.$$(go.Adornment, "Auto",
				                  this.$$(go.Shape, { fill: "#FFFFCC" }),
				                  this.$$(go.TextBlock, tooltipText ,{ margin: 4 })
				                )  // end of Adornment
				}
				,new go.Binding("visible", "visible"),
				this.$$(go.Picture, {width: 24, height: 24,  source: "../images/"+buttonImg })
			);
	}
	
}