import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { fetchPlanProducts, plansSelector, setCurrentPlan } from '../../../store/slices/plansSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styles from "./PackagePlans.module.scss";
import { setStepper } from '../../../store/slices/paymentStepperSlice';
import BasicIcon from 'src/assets/svg/basic-icon';
import PremiumIcon from 'src/assets/svg/premium-icon';
import AdvancedIcon from 'src/assets/svg/advanced-icon';
import { Product } from 'src/types/PlanProduct.type';
import { Button } from 'antd';
import EnterpriseIcon from 'src/assets/svg/enterprise-icon';

type PlanItem = {
  key: string;
  value: string;
}

const ListPlanItems: PlanItem[] = [
  { key: "number_of_nodes", value: "Number of Nodes" },
  { key: "number_of_runs_per_month", value: "Number of Runs (per month)" },
  { key: "token_limit_per_month", value: "Token Limit (per month)" },
  { key: "number_of_apps", value: "Number of Apps" },
  { key: "historical_data", value: "Historical Data" },
  { key: "export_to_pdf", value: "Export to PDF" },
  { key: "priority_support", value: "Priority Support" },
  { key: "ai_agent_workflows", value: "AI Agent Workflows" },
  { key: "business_process_workflows", value: "Business Process Workflows" },
  { key: "custom_workflows_apis", value: "Custom Workflows (APIs)" },
]

const textStyle = {
  fontSize: 13,
  fontWeight: 'regular',
  lineHeight: 0,
  // mb: '2px',
  style: {
    listStyleType: 'none',
    padding: 0,
    margin: '0px',
  }
};

export const CurrencyFormat = (price: number) => Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
  price,
);

function percentage(partialValue: number, totalValue: number) {
  var percent = 100 - Math.round(partialValue * 100) / totalValue;
  return '(-' + roundOf(percent, 2) + '%)';
}

function roundOf(n: number, p: number) {
  const n1 = n * Math.pow(10, p + 1);
  const n2 = Math.floor(n1 / 10);
  if (n1 >= (n2 * 10 + 5)) {
    return (n2 + 1) / Math.pow(10, p);
  }
  return n2 / Math.pow(10, p);
}

const PackagePlans: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    plans,
    currPlan,
    billing_cycle,
    loading,
    error, // added error state handling
  } = useSelector(plansSelector);

  useEffect(() => {
    dispatch(fetchPlanProducts());
  }, [dispatch]);

  const handleSelectPlan = (plan: Product) => {
    dispatch(setCurrentPlan(plan));
  };

  function handleGetStartedPlan() {
    dispatch(setStepper());
  }
  const packageIcon = (planName: string) => {
    if (planName === 'basic') {
      return <BasicIcon />
    } else if (planName === 'advanced') {
      return <AdvancedIcon />
    } else if (planName === 'premium') {
      return <PremiumIcon />
    } else {
      return <EnterpriseIcon />
    }
  };

  const checkYesOrNoOrString = (str: string) => (str === 'No' ? <div className={styles.failMark}>✘</div> : (str === 'Yes' ? <div className={styles.okMark}>✔</div> : str));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '60px',
        padding: '20px'
      }}
    >
      {loading ? (
        <>Loading...</>
      ) : error ?
        <div style={{ color: 'red' }}>Error: {error}</div>
        : plans?.response?.map((plan) => {

          return <div
            key={plan.name}
            style={{
              border: currPlan?.name === plan.name ? '2px solid #8686FF' : '1px solid #E6E6E6',
              borderRadius: '8px',
              padding: '20px',
              width: '350px',
              textAlign: 'center',
              boxShadow: currPlan?.name === plan.name ? '0 4px 8px #424242' : '0 4px 8px #A0A0A0',
              backgroundColor: plan.isPopular ? '#f0f8ff' : '#fff',
            }}
            onClick={() => handleSelectPlan(plan)}
          >
            {plan.isPopular ? <div style={{
              backgroundColor: '#c7084e',
              color: 'white',
              width: '100px',
              fontSize: '9px',
            }}>Most Popular</div> : <div style={{
              backgroundColor: 'transparent',
              width: '100px',
              height: '16px',
            }} />}

            <div style={{ paddingTop: '20px', paddingBottom: 0 }}>{packageIcon(plan.name.toLocaleLowerCase())}</div>
            <h3 style={{ textAlign: 'start', color: '#323232' }}>{plan.name.toUpperCase()}</h3>
            <div style={{ textAlign: 'start', marginTop: '-20px' }}>
              {plan.description}
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: '20',
              marginTop: '-10px'
            }}>
              <h1 style={{ textAlign: 'start', color: '#323232', fontSize: '40px' }}>
                {
                  CurrencyFormat(billing_cycle === 'monthly' ?
                    plan.monthly_prices.unit_amount : plan.one_time_prices.unit_amount)
                }
              </h1>
              <div style={{ width: '15px' }} />
              <div style={{ width: '50px', textAlign: 'start' }}>
                per month
              </div>
              <div style={{ margin: '10px', color: '#A12222' }}>
                {billing_cycle === 'monthly' ? percentage(plan.monthly_prices.unit_amount, plan.one_time_prices.unit_amount) : ''}
              </div>
            </div>
            <Button
              disabled={!currPlan}
              className={styles.selectButton}
              onClick={currPlan?.name === plan.name ? () => handleGetStartedPlan() : undefined}
              style={{
                backgroundColor: currPlan?.name === plan.name ? 'blue' : 'gray',
              }}
            >
              {currPlan?.name === plan.name ? 'Selected' : 'Select'}
            </Button>
            <ul style={{
              listStyleType: 'none',
              padding: 0,
              marginTop: '40px',
            }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper'
                }}
                subheader={<ListItemText
                  primary="This includes features"
                  primaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    mb: '2px',
                    textAlign: 'start',
                  }}
                  sx={{ my: 0 }}
                />}
              >
                {ListPlanItems.map((item) => {
                  return <ListItem>
                    <ListItemText
                      id={item.key}
                      primary={item.value}
                      primaryTypographyProps={textStyle} />

                    <p className={styles.feature}>{checkYesOrNoOrString(plan.metadata[item.key])}</p>
                  </ListItem>
                })}
              </List>
            </ul>
          </div>;
        })}
    </div>
  );
};

export default PackagePlans;
