import React, { useEffect, useState } from "react";
import styles from "./SelectFromTable.module.scss";
import { Input, Select, SelectProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SelectFromTableType } from "src/types/MoreSettingType";
import useFetch from "src/hooks/useFetch";
import {
  GetAllDBConnectByStoryIDResponse,
  GetTableAndColumnResponse,
  TableType,
} from "src/types/response.type";
import { GetTableAndColumnRequest } from "src/types/request.type";
import {
  GET_DB_CONNECT_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { useParams } from "react-router";
import MessageNofify from "src/components/zero/zero-story/notification/MessageNotify";

type SelectType = {
  label: string;
  value: string;
};

type ColumnType = {
  column_name: string;
  column_type: string;
  primary_key: boolean;
};

type Props = {
  onUpdate: (newItems: SelectFromTableType) => void;
  reRender: boolean;
  data: SelectFromTableType;
};

const GET_DB_CONNECT_FK = "getDbConnectFK";
const GET_TABLE_COL_FK = "getTablColFK";

function SelectFromTable({ onUpdate, reRender, data }: Props) {
  const { loading, error, responseData, fetchData, fetchKey } = useFetch<
    GetAllDBConnectByStoryIDResponse | GetTableAndColumnResponse
  >();
  let params = useParams();

  let [tableName, setTableName] = useState<string>("");
  let [columnShow, setColumnShow] = useState<string>("");
  let [columnValue, setColumnValue] = useState<string>("");
  let [conditionValue, setConditionValue] = useState<string>("");
  let [advanceSearch, setAdvanceSearch] = useState<string>("");
  let [connectionId, setConnectionId] = useState<string>("");
  let [isLoading, setIsLoading] = useState<boolean>(false);

  //SELECT STATE
  let [tableSelects, setTableSelects] = useState<SelectType[]>([]);
  let [colSelects, setColSelects] = useState<SelectType[]>([]);

  //DATA FROM FETCHER STATE
  let [allTableAndCol, setAllTableAndCol] = useState<TableType[]>([]);
  // let [allColInTable, setAllColInTable] = useState<ColumnType[]>([]);

  //NOTITY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  useEffect(() => {
    //CALL DB_CONNECTION AND TABLE_COLUMN
    getDBConnectByStoryIdController();
    actionPrepareDataAndState();
  }, [reRender]);

  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) {
        setIsLoading(false);

        //ERROR NOTIFY
        let title = "Call API Error!";
        let description = error;
        openNotifyError(title, description);
        return;
      }

      console.log("fetchKey => ", fetchKey);
      switch (fetchKey) {
        case GET_DB_CONNECT_FK: {
          let respGetAllDBConnect = responseData?.data as unknown[];
          if (respGetAllDBConnect?.length === 0) return;
          respGetAllDBConnect = respGetAllDBConnect.map((item: any) => {
            return {
              ...item,
              is_active: Boolean(item.is_active),
            };
          });
          let reqGetTableCol: GetTableAndColumnRequest =
            respGetAllDBConnect[0] as GetTableAndColumnRequest;
          // console.log("reqGetTableCol => ", reqGetTableCol);

          setConnectionId(reqGetTableCol.db_connector_id as string);
          getTableAndColumnController(reqGetTableCol);
          break;
        }
        case GET_TABLE_COL_FK: {
          let respGetTableCol: any = responseData?.data;
          let allTable = respGetTableCol?.table as TableType[];
          let tableSelects: SelectType[] = [...(allTable as unknown[])].map(
            (item: any) => {
              return {
                label: item.table_name as string,
                value: item.table_name as string,
              };
            }
          );
          // console.log("allTable => ", allTable);
          // console.log("tableSelects => ", tableSelects);

          //SET DATA LOAD
          setTableSelects(tableSelects);
          setAllTableAndCol(allTable);

          //CHECK DATA AND PREPARE
          // actionPrepareDataAndState();

          //LOADING FALSE
          setIsLoading(false);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  useEffect(() => {
    let updateSelectTableItem: SelectFromTableType = {
      tableName,
      columnShow,
      columnValue,
      conditionValue,
      advanceSearch,
      connectionId,
    };
    onUpdate(updateSelectTableItem);
  }, [tableName, columnShow, columnValue, conditionValue, advanceSearch]);

  //ACTION HANDLER
  const onChangeTableNameHandler = (value: string) => {
    console.log("onChangeTableNameHandler value => ", value);
    setTableName(value);

    let colSelects = getColSelectsFromTableSelected(value);
    console.log("onChangeTableNameHandler colSelects => ", colSelects);
    setColSelects(colSelects);
  };
  const onChangeColumnShowHandler = (value: string) => {
    console.log("onChangeColumnShowHandler => ", value);
    setColumnShow(value);
  };
  const onChangeColumnValueHandler = (value: string) => {
    setColumnValue(value);
  };
  const onChangeConditionValueHandler = (value: string) => {
    setConditionValue(value);
  };
  const onChangeAdvanceSearchHandler = (value: string) => {
    setAdvanceSearch(value);
  };

  //UTILITY FUNCTION
  const actionPrepareDataAndState = () => {
    if (data) {
      setAllState();
      onUpdate(data);
    } else {
      clearAllState();
    }
  };
  const getColSelectsFromTableSelected = (
    tableSelected: string
  ): SelectType[] => {
    let tableAndCol = allTableAndCol.find(
      (item) => item.table_name === tableSelected
    );
    let cols = tableAndCol?.name_column;
    if (!cols) return [];

    let colSelects: SelectType[] = cols?.map((item) => {
      return {
        label: item.column_name,
        value: item.column_name,
      };
    });

    return colSelects;
  };
  const setAllState = () => {
    setTableName(data.tableName);
    setColumnShow(data.columnShow);
    setColumnValue(data.columnValue);
    setConditionValue(data.conditionValue);
    setAdvanceSearch(data.advanceSearch);
  };
  const clearAllState = () => {
    setTableName("");
    setColumnShow("");
    setColumnValue("");
    setConditionValue("");
    setAdvanceSearch("");
  };

  const getStoryId = () => {
    return params.id;
  };

  //API CONTROLLER FUNCTION
  const getDBConnectByStoryIdController = () => {
    setIsLoading(true);
    fetchData(
      {
        endpoint: GET_DB_CONNECT_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_DB_CONNECT_FK
    );
  };

  const getTableAndColumnController = (req: GetTableAndColumnRequest) => {
    fetchData(
      {
        endpoint: GET_TABLE_COL_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GET_TABLE_COL_FK
    );
  };

  //NOTIFY HANDLER
  const openNotifyError = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(false);
  };

  const openNotifySuccess = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(true);
  };

  return (
    <div className={styles.selectFTableContainer}>
      <div className={styles.rowWrapper}>
        <div className={styles.text}>Table&nbsp;Name</div>
        <div className={styles.input}>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={tableName}
            options={tableSelects}
            style={{ width: "100%" }}
            onChange={onChangeTableNameHandler}
            optionFilterProp="children"
            className={styles.genTableSelect}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.text}>Column&nbsp;Show</div>
        <div className={styles.input}>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={columnShow}
            options={colSelects}
            style={{ width: "100%" }}
            onChange={onChangeColumnShowHandler}
            optionFilterProp="children"
            className={styles.genTableSelect}
            disabled={isLoading}
          />
          {/* <Input
            value={columnShow}
            className={styles.searchInput}
            onChange={(e) => onChangeColumnShowHandler(e.currentTarget.value)}
          /> */}
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.text}>Column&nbsp;Value</div>
        <div className={styles.input}>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={columnValue}
            options={colSelects}
            style={{ width: "100%" }}
            onChange={onChangeColumnValueHandler}
            optionFilterProp="children"
            className={styles.genTableSelect}
            disabled={isLoading}
          />
          {/* <Input
            value={columnValue}
            className={styles.searchInput}
            onChange={(e) => onChangeColumnValueHandler(e.currentTarget.value)}
          /> */}
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.text}>Condition&nbsp;Value</div>
        <div className={styles.input}>
          <Input
            value={conditionValue}
            className={styles.searchInput}
            onChange={(e) =>
              onChangeConditionValueHandler(e.currentTarget.value)
            }
          />
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.text}>Advance&nbsp;Search</div>
        <div className={styles.textArea}>
          <TextArea
            value={advanceSearch}
            onChange={(e: any) => onChangeAdvanceSearchHandler(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 7 }}
          />
        </div>
      </div>

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />

    </div>
  );
}

export default SelectFromTable;
