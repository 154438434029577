import React from "react";
import styles from "../card/CardCreate.module.scss"
import { Card } from "antd";
import PlusIcon from "src/assets/svg/plus";

type Props = {
    onClickCreate: () => void;
};

function CardCreate({ onClickCreate }: Props) {

    return (
        <Card className={styles.cardCreateContainer} onClick={onClickCreate}>
            <PlusIcon />
        </Card>
    );
}

export default CardCreate;
