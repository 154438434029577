import React from "react";

const StoryEditEntDelete = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      viewBox="0 0 17 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.918 4.66797C12.7412 4.66797 12.5716 4.73821 12.4466 4.86323C12.3215 4.98826 12.2513 5.15782 12.2513 5.33464V12.7953C12.2322 13.1324 12.0806 13.4483 11.8296 13.6742C11.5786 13.9001 11.2486 14.0177 10.9113 14.0013H5.5913C5.25403 14.0177 4.92396 13.9001 4.67297 13.6742C4.42199 13.4483 4.27043 13.1324 4.2513 12.7953V5.33464C4.2513 5.15782 4.18106 4.98826 4.05604 4.86323C3.93102 4.73821 3.76145 4.66797 3.58464 4.66797C3.40782 4.66797 3.23826 4.73821 3.11323 4.86323C2.98821 4.98826 2.91797 5.15782 2.91797 5.33464V12.7953C2.937 13.4861 3.22902 14.1412 3.73009 14.6172C4.23115 15.0931 4.90042 15.3511 5.5913 15.3346H10.9113C11.6022 15.3511 12.2715 15.0931 12.7725 14.6172C13.2736 14.1412 13.5656 13.4861 13.5846 12.7953V5.33464C13.5846 5.15782 13.5144 4.98826 13.3894 4.86323C13.2643 4.73821 13.0948 4.66797 12.918 4.66797Z"
        // fill="#393939"
      />
      <path
        d="M13.5833 2.66797H10.9167V1.33464C10.9167 1.15782 10.8464 0.988255 10.7214 0.863231C10.5964 0.738207 10.4268 0.667969 10.25 0.667969H6.25C6.07319 0.667969 5.90362 0.738207 5.7786 0.863231C5.65357 0.988255 5.58333 1.15782 5.58333 1.33464V2.66797H2.91667C2.73986 2.66797 2.57029 2.73821 2.44526 2.86323C2.32024 2.98826 2.25 3.15782 2.25 3.33464C2.25 3.51145 2.32024 3.68102 2.44526 3.80604C2.57029 3.93106 2.73986 4.0013 2.91667 4.0013H13.5833C13.7601 4.0013 13.9297 3.93106 14.0547 3.80604C14.1798 3.68102 14.25 3.51145 14.25 3.33464C14.25 3.15782 14.1798 2.98826 14.0547 2.86323C13.9297 2.73821 13.7601 2.66797 13.5833 2.66797ZM6.91667 2.66797V2.0013H9.58333V2.66797H6.91667Z"
        // fill="#393939"
      />
      <path
        d="M7.58333 11.3333V6.66667C7.58333 6.48986 7.5131 6.32029 7.38807 6.19526C7.26305 6.07024 7.09348 6 6.91667 6C6.73986 6 6.57029 6.07024 6.44526 6.19526C6.32024 6.32029 6.25 6.48986 6.25 6.66667V11.3333C6.25 11.5101 6.32024 11.6797 6.44526 11.8047C6.57029 11.9298 6.73986 12 6.91667 12C7.09348 12 7.26305 11.9298 7.38807 11.8047C7.5131 11.6797 7.58333 11.5101 7.58333 11.3333Z"
        // fill="#393939"
      />
      <path
        d="M10.2513 11.3333V6.66667C10.2513 6.48986 10.1811 6.32029 10.056 6.19526C9.93102 6.07024 9.76145 6 9.58464 6C9.40782 6 9.23826 6.07024 9.11323 6.19526C8.98821 6.32029 8.91797 6.48986 8.91797 6.66667V11.3333C8.91797 11.5101 8.98821 11.6797 9.11323 11.8047C9.23826 11.9298 9.40782 12 9.58464 12C9.76145 12 9.93102 11.9298 10.056 11.8047C10.1811 11.6797 10.2513 11.5101 10.2513 11.3333Z"
        // fill="#393939"
      />
    </svg>
  );
};
export default StoryEditEntDelete;
