import { Button, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import styles from "./SearchResultConfigStep3.module.scss";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import SearchResultConfigItem from "./SearchResultConfigItem";
import "./SearchResultConfigStep3.scss";
import { SEARCH_RESULT_SORT_BY_SELECTS } from "src/utils/story-genpage/StoryGenPageMockUp";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";

type Props = {};
type SearchResultConfigItemType = {
  id: string;
  boFieldName: string;
  // isSearchResult: boolean;
  sortBy: string;
};
function SearchResultConfigStep3({}: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);
  let [boFieldSelects, setBoFieldSelects] = useState<
    { value: string; label: string }[]
  >([]);
  let [allBoFieldSelects, setAllBoFieldSelects] = useState<
    { value: string; label: string }[]
  >([]);

  let [sortBySelects, setSortBySelects] = useState<
    { value: string; label: string }[]
  >(SEARCH_RESULT_SORT_BY_SELECTS);

  let [searchResultConfigItems, setSearchResultConfigItems] = useState<
    SearchResultConfigItemType[]
  >([]);
  let [boFieldCreate, setBoFieldCreate] = useState<string | null>();
  let [sortBy, setSortBy] = useState<string>("asc");

  let [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  let [itemToRemove, setItemToRemove] = useState<string | null>();
  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  //SETUP ITEMS ON LOAD
  useEffect(() => {
    console.log("onload SearchResultConfigStep3 ", genPageContext);

    //GET SELECTS AND SET FROM SEARCH_FILTER_CONFIG ON PREVIOUS STEP.
    let getBoFieldSelects = getBoFieldSelectContext();
    console.log("getBoFieldSelects => ", getBoFieldSelects)
    setBoFieldOnLoadSuccess(getBoFieldSelects);

    setOldSearchResultItems(getBoFieldSelects); //set search_result items from context saved
  }, []);

  //SAVE DATA TRIGGER FROM PARENT
  useEffect(() => {
    let isSave = genPageContext.isSaveSearchResult;
    let currentStep = genPageContext.currentStep;
    let nextStep = currentStep + 1;

    if (isSave) {
      let dataForSave = getSearchResultConfigDataForSave();
      console.log(
        "SearchResultConfigStep3 onsave dataForSave => ",
        dataForSave
      );

      //SAVE ALL DATA TO CONTEXT ON CLICK NEXT.
      let gerateBoConfigData: any[] = genPageContext.generateBoConfig;
      let newGenerateBoConfig = gerateBoConfigData.filter(
        (item) => item.bo_config_type !== "SEARCH_RESULT"
      );

      genPageContext.setGenerateBoConfig([
        ...newGenerateBoConfig,
        ...dataForSave,
      ]);
      genPageContext.setIsSaveSearchResult(false);
      genPageContext.setCurrentStep(nextStep);
    }
  }, [genPageContext.isSaveSearchResult]);

  //FILTER SELECTS BY SEARCH_RESULT_ITEMS
  useEffect(() => {
    if(isFirstLoad) {
      setIsFirstLoad(false)
      return;
    }

    if (searchResultConfigItems.length > 0) {
      let allSearchResultId = searchResultConfigItems.map((item) => item.id);
      let newBoFieldSelects = allBoFieldSelects.filter(
        (item) => !allSearchResultId.includes(item.value)
      );
      setBoFieldSelects(newBoFieldSelects);
    } else {
      setBoFieldSelects([...allBoFieldSelects]);
    }
  }, [searchResultConfigItems]);

  //DEFAULT ROW
  const onChangeSelectBoField = (value: string) => {
    console.log("onChangeSelectBoField value ", value);
    setBoFieldCreate(value);
  };

  useEffect(() => {
    if (boFieldCreate !== null) {
      onAddBoFieldHandler();
    }
  }, [boFieldCreate]);
  
  const onChangeSortByHandler = (value: string) => {
    console.log("onChangeSortByHandler value ", value);
    setSortBy(value);
  };
  const onAddBoFieldHandler = () => {
    console.log(
      `create new row step3 on add with ==> boFieldCreate=${boFieldCreate}, isDefaultSearchCreate=${sortBy}`
    );
    // console.log(
    //   `create new row step3 on add with ==> boFieldCreate=${boFieldCreate}, isDefaultSearchCreate=${sortBy}`
    // );
    let boSelect = boFieldSelects.find((item) => item.value === boFieldCreate);
    if (!isPassValidateAddItem()) return; //validation on AddItem.
    addNewSearchResultItem({
      boItemId: boSelect?.value as string,
      boFieldName: boSelect?.label as string,
      sortBy,
    });
  };
  const clearDefaultRow = () => {
    setBoFieldCreate(null);
    setSortBy("asc");
  };

  //DELETE MODAL
  const onSearchResultItemRemove = (id: string) => {
    setIsOpenModal(true);
    console.log("onSearchResultItemRemove id :: ", id);
    setItemToRemove(id);
  };
  const onOkConfirmDeleteHandler = () => {
    setIsOpenModal(false);

    if (!itemToRemove) return;
    removeSearchResultItem();
  };
  const onCancelConfirmDeleteHandler = () => {
    setIsOpenModal(false);
    setItemToRemove(null);
  };

  //UTILITY FUNCTION
  const addNewSearchResultItem = ({
    boItemId,
    boFieldName,
    sortBy,
  }: {
    boItemId: string;
    boFieldName: string;
    sortBy: string;
  }) => {
    let newSearchResultItem: SearchResultConfigItemType = {
      id: boItemId,
      boFieldName,
      sortBy,
    };
    let newSearchResultItems: SearchResultConfigItemType[] = [
      ...searchResultConfigItems,
      newSearchResultItem,
    ];
    updateNewSearchResultItems(newSearchResultItems);
    clearDefaultRow();
  };
  const removeSearchResultItem = () => {
    let newSearchResultItems = [...searchResultConfigItems].filter(
      (item) => item.id !== itemToRemove
    );
    // setSearchResultConfigItems(newSearchResultItems);
    updateNewSearchResultItems(newSearchResultItems);
    setItemToRemove(null);
  };
  const updateNewSearchResultItems = (
    newSearchResultItems: SearchResultConfigItemType[]
  ) => {
    setSearchResultConfigItems(newSearchResultItems);
  };
  const isPassValidateAddItem = () => {
    let isPass = true;
    let textError = "";
    if (!boFieldCreate || !sortBy) {
      isPass = false;
      textError += "Please input bo or sortby!";
    }
    let hasThisBo = searchResultConfigItems.some(
      (item) => item.boFieldName === boFieldCreate
    );
    if (hasThisBo) {
      isPass = false;
      textError += "This bo already added!";
    }
    return isPass;
  };
  const getSearchResultConfigDataForSave = () => {
    let dataToSave = searchResultConfigItems.map((item) => {
      return {
        enyity_bo_item_id: item.id,
        // enyity_bo_item_name: item.boFieldName,
        bo_config_type: "SEARCH_RESULT",
        sort_by: item.sortBy,
      };
    });
    return dataToSave;
  };
  const getSearchResultBySearchFilter = () => {
    // console.log("getSearchResultBySearchFilter ", genPageContext);
    let generateBoConfigSaved = genPageContext?.generateBoConfig;
    let searchResultItemsSaved = generateBoConfigSaved.filter(
      (item) => item.bo_config_type === "SEARCH_RESULT"
    );
    let searchFilterItemsSaved = generateBoConfigSaved.filter(
      (item) => item.bo_config_type === "SEARCH_FILTER"
    );

    let searchResultItems: any = [];
    let searchResultOldItems = [];
    let searchResultNewItems = [];
    if (searchResultItemsSaved.length > 0) {
      let searchResultItemsID = searchFilterItemsSaved.map(
        (item) => item.enyity_bo_item_id
      );
      for (const sf of searchFilterItemsSaved) {
        if (searchResultItemsID.includes(sf.enyity_bo_item_id)) {
          let searchResultItem = searchResultItemsSaved.find(
            (item) => item.enyity_bo_item_id === sf.enyity_bo_item_id
          );
          searchResultOldItems.push(searchResultItem);
        } else {
          searchResultNewItems.push(sf);
        }
      }
      searchResultItems = [...searchResultOldItems, ...searchResultNewItems];
    } else {
      searchResultItems = [...searchFilterItemsSaved];
    }
    console.log("getSearchResultBySearchFilter result => ", searchResultItems);
    return searchResultItems;
  };

  const getBoFieldBySearchFilter = () => {
    let newBoFieldSelects: any[] = [];
    let generateBoConfigSaved = genPageContext?.generateBoConfig;
    let searchFilterItemsSaved = generateBoConfigSaved.filter(
      (item) => item.bo_config_type === "SEARCH_FILTER"
    );
    if (searchFilterItemsSaved.length > 0) {
      newBoFieldSelects = searchFilterItemsSaved.map((item) => {
        return {
          value: item.enyity_bo_item_id,
          label: item.enyity_bo_item_name,
        };
      });
    }
    return newBoFieldSelects;
  };

  const getBoFieldSelectContext = () => {
    return genPageContext.boFieldForSelect;
  }

  const setOldSearchResultItems = (
    boFieldSelects: { label: string; value: string }[]
  ) => {
    // console.log("getSearchResultBySearchFilter ", genPageContext);
    let generateBoConfigSaved = genPageContext?.generateBoConfig;
    let searchResultItemsSaved = generateBoConfigSaved.filter(
      (item) => item.bo_config_type === "SEARCH_RESULT"
    );
    if (searchResultItemsSaved.length === 0) return;
    let newSearchResultItems: SearchResultConfigItemType[] =
      searchResultItemsSaved.map((item) => {
        return {
          id: item.enyity_bo_item_id,
          boFieldName: item.enyity_bo_item_id,
          sortBy: item.sort_by as string,
        };
      });

    //FILTER BY BO_FIELD SELECTS
    let boFieldValues = boFieldSelects.map((item) => item.value);
    let newSearchResultByBoField = newSearchResultItems.filter((item) =>
      boFieldValues.includes(item.id)
    );

    setSearchResultConfigItems(newSearchResultByBoField);
  };

  const setBoFieldBySearchFilterItems = (searchFilterItems: any) => {
    let newBoFieldSelects: any = searchFilterItems?.map((item: any) => {
      return {
        value: item?.enyity_bo_item_id,
        label: item?.enyity_bo_item_name,
      };
    });
    setBoFieldSelects(newBoFieldSelects);
  };

  const setBoFieldOnLoadSuccess = (
    boFieldItems: { label: string; value: string }[]
  ) => {
    console.log("setBoFieldOnLoadSuccess => ", boFieldItems)
    setBoFieldSelects(boFieldItems);
    setAllBoFieldSelects(boFieldItems);
  };

  return (
    <>
      {/* CONFIRM DELETE MODAL */}
      <table id="SearchResultTb">
        <thead>
          <tr>
            <th>BO Field</th>
            {/* <th style={{ textAlign: "center" }}>Search Result</th> */}
            <th>SortBy</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Select
                value={boFieldCreate}
                // defaultValue={boFieldSelects[0].value}
                options={boFieldSelects}
                style={{ width: "60%" }}
                placeholder={<div>&nbsp;&nbsp;&nbsp;Select BO Field</div>}
                onChange={onChangeSelectBoField}
                optionFilterProp="children"
                className={styles.genTableSelect}
              />
            </td>
            {/* <td style={{ textAlign: "center" }}>
              <Checkbox onChange={onChangeIsSearchResult} />
            </td> */}
            <td style={{ textAlign: "start" }}>
              <Select
                value={sortBy}
                // defaultValue={sortBySelects[0].value}
                options={sortBySelects}
                style={{ width: "100%" }}
                // placeholder={<div>&nbsp;&nbsp;&nbsp;Select BO Field</div>}
                onChange={onChangeSortByHandler}
                optionFilterProp="children"
                className={styles.genTableSelect}
              />
            </td>
            <td className={styles.tdSetCenter}>
              <Button
                className={styles.addBtn}
                key="add"
                onClick={onAddBoFieldHandler}
              >
                <StoryEditEntAdd
                  style={{ marginRight: "6px", fill: "#1c87f2" }}
                />
                Add
              </Button>
            </td>
          </tr>

          {searchResultConfigItems.map((item, index) => {
            return (
              <SearchResultConfigItem
                key={index}
                searchResultItem={item}
                onRemoveItem={onSearchResultItemRemove}
              />
            );
          })}
        </tbody>
      </table>
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete BO Field?",
          description: `Are you sure you want to delete this BO Field? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenModal}
        onOk={onOkConfirmDeleteHandler}
        onCancel={onCancelConfirmDeleteHandler}
      />
    </>
  );
}

export default SearchResultConfigStep3;
