import React, { useEffect, useState } from "react";
import { notification } from "antd";
import StoryEditEntCreated from "src/assets/svg/story-edit-ent-created";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./MessageNotify.module.scss";
import error from "../../../../assets/png/error.png";
import success from "../../../../assets/png/success.png";

type Props = {
  trigger: boolean;
  title: string;
  description: string;
  isSuccess: boolean;
};

function MessageNofify({
  trigger,
  title,
  description,
  isSuccess = true,
}: Props) {
  const [api, contextHolder] = notification.useNotification();
  let [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  useEffect(() => {
    if (isFirstTime) setIsFirstTime(false);
  }, []);

  useEffect(() => {
    if (!isFirstTime) {
      api.open({
        message: (
          <div className={styles.messageNotify}>
            {title || "Create Successfully!"}
          </div>
        ),
        description: (
          <div className={styles.descripNotify}>
            {description || "Business Object has been created successfully."}
          </div>
        ),
        icon: (
          <div className={styles.iconNotify}>
            {isSuccess ? (
              // <StoryEditEntCreated className={styles.iconSuccessNotify} />
              <img src={success} alt="" width={"50px"} height={"50px"} />
            ) : (
              // <StoryEditEntDelete className={styles.iconSuccessNotify} />
              <img src={error} alt="" width={"50px"} height={"50px"} />
            )}
          </div>
        ),
        duration: 2,
      });
    }
  }, [trigger]);

  return <>{contextHolder}</>;
}

export default MessageNofify;
