import React from "react";

const StoryGenPageAddBelow = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5H4V10H20V5ZM4 3C2.89543 3 2 3.89543 2 5V10C2 11.1046 2.89543 12 4 12H20C21.1046 12 22 11.1046 22 10V5C22 3.89543 21.1046 3 20 3H4Z"
        fill="#393939"
      />
      <path
        d="M11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V15Z"
        fill="#393939"
      />
      <path
        d="M9.9981 17.9956C9.44686 17.998 8.99809 17.553 8.99574 17.0018C8.99339 16.4505 9.43835 16.0018 9.98959 15.9994L13.9933 15.9824C14.5446 15.98 14.9933 16.425 14.9957 16.9762C14.998 17.5274 14.5531 17.9762 14.0018 17.9786L9.9981 17.9956Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageAddBelow;
