import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type PaymentStepperState = {
  paymentStepper: number;
  loading: boolean;
};

const initialValues: PaymentStepperState = {
  paymentStepper: 0,
  loading: false,
};

const paymentStepperSlice = createSlice({
  name: "payment Stepper",
  initialState: initialValues,
  reducers: {
    setStepper: (state: PaymentStepperState, data: PayloadAction<void>) => {
      state.paymentStepper += 1;
    },
    reBackStepper: (state: PaymentStepperState, data: PayloadAction<void>) => {
      state.paymentStepper -= 1;
    },
  },
  extraReducers: (builder) => {},
});

export const { setStepper, reBackStepper } = paymentStepperSlice.actions;
export const paymentStepperSelector = (store: RootState) =>
  store.paymentStepperReducer;
export default paymentStepperSlice.reducer;
