import {
  CHECK_BOX,
  DATE,
  DATETIME,
  DROPDOWN,
  EMAIL,
  FILE_INPUT,
  IMAGE_INPUT,
  INT,
  NUMBER,
  PASSWORD,
  RADIO,
  TEXT,
  TEXTAREA,
  VARCHAR,
  OBJECT,
  BOOLEAN,
  DECIMAL,
} from "src/constants/InputType";

export const INPUT_TYPE_SELECTS = [
  { value: TEXT, label: "Text" },
  { value: TEXTAREA, label: "Text Area" },
  { value: PASSWORD, label: "Password" },
  { value: EMAIL, label: "Email" },
  { value: DATE, label: "Date" },
  { value: RADIO, label: "Radio" },
  { value: NUMBER, label: "Number" },
  { value: DROPDOWN, label: "Dropdown" },
  { value: CHECK_BOX, label: "CheckBox" },
  { value: FILE_INPUT, label: "File Input" },
  { value: IMAGE_INPUT, label: "Image Input" },
];

export const DATA_TYPE_SELECTS = [
  { value: VARCHAR, label: VARCHAR },
  { value: DATETIME, label: DATETIME },
  { value: INT, label: INT },
  { value: BOOLEAN, label: BOOLEAN },
  { value: DECIMAL, label: DECIMAL },
  { value: OBJECT, label: OBJECT },
];
