import React, { useEffect, useState } from "react";
import styles from "./StoryComponentPreview.module.scss";
import { Button, Modal } from "antd";
import ComponentPreviewIframe from "../../iframe/ComponentPreviewIframe";
import { pathRoutes } from "src/page/props/util.props";
type Props = {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
};

function StoryComponentPreview({ isModalOpen, onOk, onCancel }: Props) {
  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        width={"85%"}
        centered={true}
        footer={<div></div>}
        closeIcon={<></>}
      >
        <div className={styles.createContentModal}>
          <ComponentPreviewIframe url={`/zero/${pathRoutes.MainDashboard}/database-connection`} />
        </div>
      </Modal>
    </>
  );
}

export default StoryComponentPreview;
