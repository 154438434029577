import React from "react";

const StoryGenPageArrowLeft = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11.0008H9L12.29 7.71079C12.3837 7.61783 12.4581 7.50723 12.5089 7.38537C12.5597 7.26351 12.5858 7.1328 12.5858 7.00079C12.5858 6.86878 12.5597 6.73807 12.5089 6.61621C12.4581 6.49436 12.3837 6.38375 12.29 6.29079C12.1026 6.10454 11.8492 6 11.585 6C11.3208 6 11.0674 6.10454 10.88 6.29079L6.59 10.5908C6.21441 10.9641 6.00223 11.4712 6 12.0008C6.00487 12.5269 6.21684 13.0299 6.59 13.4008L10.88 17.7008C10.9732 17.7934 11.0838 17.8667 11.2054 17.9165C11.3269 17.9664 11.4571 17.9918 11.5885 17.9913C11.7199 17.9909 11.8499 17.9645 11.9712 17.9138C12.0924 17.8631 12.2024 17.789 12.295 17.6958C12.3876 17.6025 12.4609 17.492 12.5107 17.3704C12.5606 17.2488 12.586 17.1186 12.5856 16.9872C12.5851 16.8558 12.5588 16.7258 12.508 16.6046C12.4573 16.4834 12.3832 16.3734 12.29 16.2808L9 13.0008H19C19.2652 13.0008 19.5196 12.8954 19.7071 12.7079C19.8946 12.5204 20 12.266 20 12.0008C20 11.7356 19.8946 11.4812 19.7071 11.2937C19.5196 11.1061 19.2652 11.0008 19 11.0008Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageArrowLeft;
