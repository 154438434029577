const STATUS_OK = 200;
const STATUS_CREATED = 201;
const STATUS_ACCEPTED = 202;
const STATUS_BAD_REQUEST = 400;
const STATUS_UNAUTHORIZED = 401;

export {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_ACCEPTED,
  STATUS_BAD_REQUEST,
  STATUS_UNAUTHORIZED,
};
