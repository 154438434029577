import renderBOScreens from "src/template/renderBoScreens";
import renderButtonScreens from "src/template/renderButtonScreens";
import renderBreadcrumbScreens from "src/template/renderBreadcrumbScreens";
import renderLabelScreens from "src/template/renderLabelScreens";
import renderTabsScreenContainer from "src/template/renderTabsScreensContainer";
import renderTableContainer from "src/template/renderTableContainer";
import {
  BO,
  BREADCRUMP,
  BUTTON,
  ICON,
  IMAGE,
  LABEL,
  TAB,
  TABLE,
} from "src/constants/PageConfig";
import renderImageScreens from "src/template/renderImageScreens";
import renderIconScreens from "src/template/renderIconScreens";

export default function addListTileOneToManyScreens(
  mainContainer,
  mainScreen,
  screensEditWithPosition,
  filehubUrlImage,
  index,
  pageName,
  JsonCONFIGSCREEN
) {
  const container = document.createElement("div");
  container.id = "container-add-list-tile-" + mainScreen.key;

  const containerWrapper = document.createElement("div");
  const backButton = document.createElement("button");
  backButton.id = "backButton-container-add-Wrapper-" + mainScreen.key;
  backButton.className = "ant-btn button back-btn";
  backButton.textContent = "back";

  let maxRow = 0;
  let maxColumn = 0;

  screensEditWithPosition.forEach((screen) => {
    const { rownumber, columnnumber } = screen;

    maxRow = Math.max(maxRow, screen.rownumber);
    maxColumn = Math.max(maxColumn, screen.columnnumber);

    containerWrapper.style.display = "grid";
    containerWrapper.style.gridAutoRows = "auto";
    containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

    const screenElement = document.createElement("div");
    screenElement.id = "screenElement-" + screen.configType + "-" + screen.key;
    screenElement.style.gridRow = rownumber;
    screenElement.style.gridColumn = columnnumber;
    screenElement.style.padding = "10px";

    if (screen.configType === BO) {
      renderBOScreens(screenElement, screen, filehubUrlImage, "add");
    } else if (screen.configType === BUTTON) {
      renderButtonScreens(screenElement, screen, "add", mainScreen, pageName);
    } else if (screen.configType === LABEL) {
      renderLabelScreens(screenElement, screen);
    } else if (screen.configType === BREADCRUMP) {
      renderBreadcrumbScreens(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TAB) {
      renderTabsScreenContainer(screenElement, container, screen, maxColumn);
    } else if (screen.configType === TABLE) {
      renderTableContainer(
        screenElement,
        screen,
        mainScreen,
        maxColumn,
        "add",
        JsonCONFIGSCREEN,
        pageName
      );
    } else if (screen.configType === IMAGE) {
      renderImageScreens(screenElement, screen);
    } else if (screen.configType === ICON) {
      renderIconScreens(screenElement, screen);
    }
    containerWrapper.appendChild(screenElement);
  });
  container.appendChild(backButton);
  container.appendChild(containerWrapper);

  mainContainer.appendChild(container);
  console.log("container>>> ", container);
}
