import React from "react";

const PlusIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width||"70"} 
        height={height||"68"} 
        viewBox="0 0 70 58" 
        className={className}
        style={style}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path d="M49.5066 31.1667H37.8991V19.8333C37.8991 19.0819 37.5934 18.3612 37.0492 17.8299C36.505 17.2985 35.7669 17 34.9972 17C34.2276 17 33.4895 17.2985 32.9453 17.8299C32.4011 18.3612 32.0953 19.0819 32.0953 19.8333V31.1667H20.4878C19.7182 31.1667 18.9801 31.4652 18.4359 31.9965C17.8917 32.5279 17.5859 33.2486 17.5859 34C17.5859 34.7514 17.8917 35.4721 18.4359 36.0035C18.9801 36.5348 19.7182 36.8333 20.4878 36.8333H32.0953V48.1667C32.0953 48.9181 32.4011 49.6388 32.9453 50.1701C33.4895 50.7015 34.2276 51 34.9972 51C35.7669 51 36.505 50.7015 37.0492 50.1701C37.5934 49.6388 37.8991 48.9181 37.8991 48.1667V36.8333H49.5066C50.2763 36.8333 51.0144 36.5348 51.5586 36.0035C52.1028 35.4721 52.4085 34.7514 52.4085 34C52.4085 33.2486 52.1028 32.5279 51.5586 31.9965C51.0144 31.4652 50.2763 31.1667 49.5066 31.1667Z" fill={fill||"#ABABAB"} fillOpacity={opacity||"0.4"} />
        </svg>

    );
};

export default PlusIcon;
