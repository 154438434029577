
//default parameter

export const checkEmpty = (val: any) => {
    if (
      val === undefined ||
      val === null ||
      val === "null" ||
      val === "undefined"
    )
      return true;
    if (val.length === 0) return true;
  
    return false;
  };
export const ParamConstant = {
    DEFAULT_PARAMETER_NAME: "parameter",
    DEFAULT_PARAMETER_PARAMETER_NAME: 500,
    DEFAULT_DIAGRAM_HEIGHT_MIGRATION: 380,

    //category


    STR_DESC: "String",
    OBJ_DESC: "Object",
    INT_DESC: "Integer",
    DECIMAL_DESC: "Decimal",
    DATE_DESC: "Date",
    TIME_DESC: "Time",
    BOOLEAN_DESC: "Boolean",
    //prefix
    PREFIX_PARAMETER: "param_",
    PREFIX_BO_PARAMETER: "boParam_",
    PREFIX_BO: "bo_",
    PREFIX_NODE_PARAM_: "nodeParam_",

    //code color
    COLOR_WHITE: "#FFFFFF",

    ///////// ROOT Group name /////////////////
    SOURCE_MAIN_GROUP: "sourceMainGroup",
    TARGET_MAIN_GROUP: "targetMainGroup",

    /////////////////////////////////////////////////////////////

    SOURCE_KEY_INIT: "sourceKeyInit",
    TARGET_KEY_INIT: "targetKeyInit",
    PARAMETER_KEY_INIT: ["sourceKeyInit", "targetKeyInit"],


    SOURCE_INIT_GROUP: "sourceInitGroup",
    TARGET_INIT_GROUP: "targetInitGroup",

    ////////////////////////////////////////////////////////////


    NODE_IS_GROUP: "nodeIsGroup",
    NODE_IS_NOT_GROUP: "nodeIsNotGroup",




    //////////// Mapping Type //////////////////////
    FILE_WRITE_MAPPING_PARAMETER: "fileWriteMappingParameter",
    JAVA_MAPPING_PARAMETER: "javaMappingParameter",
    DATABASE_MAPPING_PARAMETER: "databaseMappingParameter",
    FR_FIX_WIDTH_MAPPING_PARAMETER: "frFixWidthMappingParameter",
    FR_XLS_MAPPING_PARAMETER: "frXlsMappingParameter",
    FR_DELIMITED_MAPPING_PARAMETER: "frDelimitedMappingParameter",
    FR_XML_MAPPING_PARAMETER: "frXmlthMappingParameter",
    PUSH_NOTI_MAPPING_PARAMETER: "pushNotiMappingParameter",
    EXTENSION_MAPPING_PARAMETER: "extensionMappingParameter",


    FRP_XLS_MAPPING_PARAMETER: "frXlsProviderMappingParameter",
    FRP_FIX_WIDTH_MAPPING_PARAMETER: "frFixWidthProviderMappingParameter",
    FRP_DELIMITED_MAPPING_PARAMETER: "frDelimitedProviderMappingParameter",
    FRP_XML_MAPPING_PARAMETER: "frXmlthProviderMappingParameter",
    SUBPROCESS_MAPPING_PARAMETER: "subprocessMappingParameter",

    WSDL_MAPPING_PARAMETER: "wsdlMappingParameter",
    REST_MAPPING_PARAMETER: "restMappingParameter",
    GENERAL_MAPPING_PARAMETER: "generalMappingParameter",
    WSP_MAPPING_PARAMETER: "webServiceProviderMappingParameter",
    ERROR_BOUNDARY_MAPPING: "errorBoundaryMapping",
    MIGRATION_MAPPING: "migrationMapping",

    //////////// Margin //////////////////////
    INITIAL_MARGIN: 30,
    INTERVAL_MARGIN: 20,
    INTERVAL_LINK: 5,

    INITIAl_STATUS: "initial",

    SOURCE_MAPPING: "SOURCE_MAPPING",
    TARGET_MAPPING: "TARGET_MAPPING",

    APP_DESIGNER_DUMMY_TEXT: "AppDesignerDummyObject",
    DATABASE_DUMMY_TEXT: "DatabaseDummyObject",
    FR_DELIMITED_DUMMY_TEXT: "DelimitedDummyObject",
    FR_EXCEL_DUMMY_TEXT: "ExcelDummyObject",
    FR_FIXED_WIDTH_DUMMY_TEXT: "FixedWidthDummyObject",
    FR_XML_DUMMY_TEXT: "XMLDummyObject",
    JAVA_DUMMY_TEXT: "JavaDummyObject",
    REST_DUMMY_TEXT: "RestDummyObject",
    WSP_DUMMY_TEXT: "WSPDummyObject",
    WSDL_DUMMY_TEXT: "WSDLDummyObject",
    PUSH_NOTI_DUMMY_TEXT: "PushNotificationParameter",
    EXTENSION_DUMMY_TEXT: "ExtensionParameter",

    DEFAULT_PARAMETER: "defaultParameter",
    REST_PARAMETER: "restParameter",
    WS_PARAMETER: "wsParameter",
    EXTENSION_PARAMETER: "extensionParameter",

    ACTIVITY_TXT: "Activity",

    SOURCE_TEXT: "source",
    TARGET_TEXT: "target",


}