import React, { useContext, useEffect, useState } from "react";
import styles from "../ControlItem.module.scss";
import { Button } from "antd";
import StoryEditEntGenPageRemove from "src/assets/svg/story-edit-ent-genpage-remove";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import AddBreadcrumpModal from "src/components/zero/zero-story/modal/modal-entity-genpage/create-edit-page-config/controls/AddBreadcrumpModal";
import { v4 as uuidv4 } from "uuid";
import StoryEditEntGenPageBreadcrumpDot from "src/assets/svg/story-edit-ent-genpage-breadcrump-dot";
import StoryEditEntGenPageBreadcrumpLine from "src/assets/svg/story-edit-ent-genpage-breadcrump-line";
import ConfirmDeleteModal from "src/components/zero/zero-story/modal/ConfirmDeleteModal";
import {
  defineRowIdInControlComponent,
  getCellPositionFromCellId,
  getComponentContainerIdByMainRowId,
  getGridItemsByActiveId,
  getRowIdFromCellId,
  getSubCellIdByMainRowIdAndColId,
  getSubFieldIdByMainRowIdAndColId,
} from "../../../utils/controls.util";
import BoFieldTool from "../../BoFieldTool";
import {
  getDefaultComponentInCellConfigByConfigType,
  getPositionToolInMainPanel,
  getUpdatedTabActiveItemsWithConfigs,
} from "src/utils/story-genpage/StoryGeneratePageUtility";
import { BO, CONTROL } from "src/constants/ComponentType";

type Props = {
  rowNum: number;
  colNum: number;
  boFieldItems: any;
  controlItems: any;
  getControlComponentByControlId: (controlId: string) => React.JSX.Element;
  mainRowId?: string;
  onUpdateItems: (tabItems: any, rowId: string) => void;
};

type BreadcrumpItemType = {
  id: string;
  tabNumId: number;
  name: string;
  componentRowItems?: RowItemType[];
};
type RowItemType = {
  id: string;
  rowId: string;
  cols: ColumnItemType[];
};

type ColumnItemType = {
  id: string;
  colId: string;
  childRef?: string;
  isControlItem?: boolean;
  controlComponent?: React.JSX.Element;
};

type ControlItemType = {
  id: string;
  controlName: string;
  icon: React.JSX.Element;
  isRowReplace: boolean;
};

function ControlBreadcrump({
  rowNum,
  colNum,
  boFieldItems,
  controlItems,
  getControlComponentByControlId,
  mainRowId,
  onUpdateItems,
}: Props) {
  let genPageContext = useContext(StoryGeneratePageContext);

  let [breadcrumpItems, setBreadcrumpItems] = useState<BreadcrumpItemType[]>(
    []
  );
  let [breadcrumpItemsShow, setBreadcrumpItemsShow] = useState<RowItemType[]>(
    []
  );
  let [activeBread, setActiveBread] = useState<string>("");
  let [tabNumIdActive, setTabNumIdActive] = useState<number>();

  //BREADCRUMP STATE
  let [isOpenDeleteBcModal, setIsOpenDeleteBcModal] = useState<boolean>(false);
  let [isOpenAddBcModal, setIsOpenAddBcModal] = useState<boolean>(false);

  //CONFIRM DELETE MODAL STATE
  let [itemIdToDelete, setItemIdToDelete] = useState<string>("");

  //BO COMPONENT STATE
  let [isOpenDeleteFieldInCell, setIsOpenDeleteFieldInCell] =
    useState<boolean>(false);

  //DRAG STATE
  let [dragOverItem, setDragOverItem] = useState<string>("");

  //TOOL STATE
  let [isCellToolOpen, setIsCellToolOpen] = useState<boolean>(false);
  let [isBoFieldToolOpen, setIsBoFieldToolOpen] = useState<boolean>(false);
  let [positionTool, setPositionTool] = useState<{
    top: number;
    left: number;
  } | null>();
  let [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  //SET BREADCRUMP FROM VERSION SAVED
  useEffect(() => {
    if(genPageContext.isVersionGenerated && isFirstLoad) {
      let component = genPageContext.versionSubComponent
      component = component.filter((item:any) => item.configType && item.configType === 'BREADCRUMP');
      console.log("breadCrump component -----", component)
      if(component.length > 0) setActiveBread(component[0].componentConfig[0].id)
      component.forEach((item:any) => {
        setBreadcrumpItems(item.componentConfig)
        item.componentConfig.forEach((comp:any) => {
          setBreadcrumpItemsShow(comp.componentRowItems)
        });
      });
      setIsFirstLoad(false)
    }
 
  }, [isFirstLoad])

  //REMOVE STATE
  let [isRemoveFieldComponent, setIsRemoveFieldComponent] =
    useState<boolean>(false);

  //CHECK ON NUMBER OF ROW AND COLUMN CHANGE
  useEffect(() => {
    console.log(`row: ${rowNum}, col: ${colNum}`);

    if (breadcrumpItems.length === 0) return;

    let newBreadcrumItems = getGridItemsAllTabFromNumberOfRowAndCol();
    setBreadcrumpItems(newBreadcrumItems);

    let bcGridItems = getGridItemsByActiveId(newBreadcrumItems, activeBread);
    setBreadcrumpItemsShow(bcGridItems as RowItemType[]);

    closeAllTool();
  }, [rowNum, colNum]);

  //CHECK AND SET ACTIVE TAB ON ITEMS === 1
  useEffect(() => {
    if (breadcrumpItems.length === 1) setActiveToFirstTab();
  }, [breadcrumpItems]);

  //CHECK ON ACTIVE TAB
  useEffect(() => {
    console.log("Active tab => ", activeBread);
    if (!activeBread) return;

    let bcGridItems = getGridItemsByActiveId(breadcrumpItems, activeBread);
    let updatedBcActiveItems = getUpdatedTabActiveItemsWithConfigs(bcGridItems);
    setBreadcrumpItemsShow(updatedBcActiveItems as RowItemType[]);

    //CHECK TAB NAME
    const breadObj = breadcrumpItems.find(
      (item: any) => item?.id === activeBread
    );
    console.log("activeBread breadObj => ", breadObj);
    setTabNumIdActive(breadObj?.tabNumId || 0);

    removeFucusItem();
  }, [activeBread]);

  //CHECK FOCUS ON PARENT
  useEffect(() => {
    let itemFocus = genPageContext.itemFocusOnConfigPage;
    console.log("itemFocusOnConfigPage => ", itemFocus);

    if (!itemFocus || itemFocus.includes("main")) {
      closeAllTool();
      return;
    }

    let { rowId: focusRowId, cellId: focusCellId } = getCellIdFromFocusItem();

    //DIV FOR GET_POSITION NOT NULL AND CHECK ROW SELECTED
    if (mainRowId !== focusRowId) {
      closeAllTool();
      return;
    }

    let cellFocusEle = document.getElementById(focusCellId); //GET CELL POSITION BY ELEMENT_ID
    let outerDivEle = document.getElementById("component-" + mainRowId); //GET CELL CONTAINER POSITION BY ELEMENT_ID

    if (cellFocusEle && outerDivEle) {
      let { top, left } = getPositionToolInMainPanel(cellFocusEle, outerDivEle);

      if (itemFocus.includes("field")) {
        //{sub-r3_field-r1-c1}
        setIsBoFieldToolOpen(true);
        setIsCellToolOpen(false);
      } else if (itemFocus.includes("cell")) {
        //{sub-r3_cell-r1-c1}
        setIsBoFieldToolOpen(false);
        setIsCellToolOpen(true);
      }

      setPositionTool({ left, top });
    }
  }, [genPageContext.itemFocusOnConfigPage]);

  //BREADCRUMP HANDLER
  const onClickBreadcrump = (
    e: React.MouseEvent<HTMLElement>,
    breadId: string
  ) => {
    e.stopPropagation();
    // console.log("onClickBreadcrump on breadId => ", breadId);
    setActiveBread(breadId);
  };

  //MODAL DELETE BREADCRUMP CONFIRMATION
  const onClickRemoveBreadcrump = (
    e: React.MouseEvent<HTMLElement>,
    idToRemove: string
  ) => {
    e.stopPropagation();
    // console.log("onClickBreadcrump on idToRemove => ", idToRemove);
    setItemIdToDelete(idToRemove);
    setIsOpenDeleteBcModal(true);
  };

  const onOkDeleteBcHandler = () => {
    if (itemIdToDelete) {
      let newBcItems = breadcrumpItems.filter(
        (item) => item.id !== itemIdToDelete
      );
      onDeleteBc(newBcItems);
    }

    setIsOpenDeleteBcModal(false);
  };

  const onCancelDeleteBcHandler = () => {
    setIsOpenDeleteBcModal(false);
  };

  const actionAddNewBreadcrump = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpenAddBcModal(true);
  };

  //DRAG DROP HANDLER
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let cellId = e.currentTarget.id;
    console.log("handleDrop in cellId : ", cellId);
    setDragOverItem("");

    let dropItemDetailStr = e.dataTransfer.getData("text/plain");
    let dropItemDetailObj: any =
      getDropItemObjFromDataTransfer(dropItemDetailStr);
    console.log("Item dropped droppedItemId ", dropItemDetailObj);
    let { componentId, componentType, isRowReplace } = dropItemDetailObj;

    if (componentType === BO) {
      // console.log("BO item drop => ", componentId);
      onAddChildInCellByBofieldItem(cellId, componentId);
    } else if (componentType === CONTROL) {
      // console.log("CONTROL item drop => ", componentId);
      onAddChildInCellByControlItem(cellId, componentId, isRowReplace);
    }
  };

  const getDropItemObjFromDataTransfer = (dropItemDetailStr: string) => {
    let result = {};

    let dropItemDetailObj = JSON.parse(dropItemDetailStr);
    let { componentId, componentType } = dropItemDetailObj;

    if (componentType === CONTROL) {
      let itemObjFromControlItem = controlItems.find(
        (item: any) => item.id === componentId
      );

      if (!itemObjFromControlItem) result = dropItemDetailObj;
      result = {
        ...dropItemDetailObj,
        isRowReplace: (itemObjFromControlItem as ControlItemType).isRowReplace,
      };
    } else {
      result = dropItemDetailObj;
    }

    return result;
  };

  // const getDropItemObjFromDataTransfer = (dropItemDetailStr: string) => {
  //   let dropItemDetailObj = JSON.parse(dropItemDetailStr);
  //   return dropItemDetailObj;
  // };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragOver id : ", id);
    setDragOverItem(id);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    console.log("handleDragLeave id : ", id);
    setDragOverItem("");
  };

  //ADD BREADCRUMP HANDLER
  const onCancelAddBc = () => {
    setIsOpenAddBcModal(false);
  };

  const onOkAddBc = () => {
    setIsOpenAddBcModal(false);
  };

  const getMaxTabNum = () => {
    let bread = [...breadcrumpItems];
    if (bread.length === 0) return 0;
    let max = Math.max(...bread.map((obj) => obj?.tabNumId));
    console.log(max);
    return max;
  };

  const onSaveNewBcName = (newBcName: string) => {
    console.log("onSaveNewBcName newBcName => ", newBcName);
    const id = uuidv4();

    let tabNumId = getMaxTabNum() + 1;
    let rowColItems = getGridItemsFromNumberOfRowAndCol(tabNumId);
    let newBcItem: BreadcrumpItemType = {
      id,
      tabNumId,
      name: newBcName,
      componentRowItems: rowColItems,
    };
    let newBcItems = [...breadcrumpItems, newBcItem];

    onAddBc(newBcItems);
    setIsOpenAddBcModal(false);
  };

  //BREADCRUMP MANAGEMENT
  const onDeleteBc = (newBcItems: BreadcrumpItemType[]) => {
    if (newBcItems.length === 0) {
      setBreadcrumpItemsShow([]);
      onUpdateItems([], mainRowId as string);
    } else if (newBcItems.length > 0 && activeBread === itemIdToDelete) {
      setActiveToFirstTab();
      let newBcGridItems = getGridItemsByActiveId(breadcrumpItems, activeBread);
      setBreadcrumpItemsShow(newBcGridItems as RowItemType[]);
      onUpdateItems(newBcItems, mainRowId as string);
    }

    setItemIdToDelete("");
    setBreadcrumpItems(newBcItems);
  };
  const onAddBc = (newBcItems: BreadcrumpItemType[]) => {
    setBreadcrumpItems(newBcItems);
    onUpdateItems(newBcItems, mainRowId as string);
  };

  //CELL DATA MANAGEMENT
  const onClickCell = (e: React.MouseEvent<HTMLDivElement>, colId: string) => {
    e.stopPropagation();
    console.log("onClickCell in component colID => ", colId);
    genPageContext.setItemFocusOnConfigPage(colId);
  };

  const getCombinateOldAndNewColsInBreadcrumpItem = (
    oldCols: ColumnItemType[],
    newCols: ColumnItemType[]
  ): ColumnItemType[] => {
    let result: ColumnItemType[] = [];
    for (const newCol of newCols) {
      let newCombinateItem: ColumnItemType;
      let oldCol = oldCols.find((item) => item.colId === newCol.colId);
      if (oldCol) {
        newCombinateItem = oldCol;
      } else {
        newCombinateItem = newCol;
      }
      result.push(newCombinateItem);
    }
    return result;
  };

  const getCombinateOldAndNewGridItems = (
    oldGridItems: BreadcrumpItemType[],
    newGridItems: BreadcrumpItemType[]
  ) => {
    let result: BreadcrumpItemType[] = [];

    for (let index = 0; index < oldGridItems.length; index++) {
      let newRowItems = newGridItems[index]?.componentRowItems;
      let oldRowItems = oldGridItems[index]?.componentRowItems;
      // console.log("tab name => ", newGridItems[index].name);

      let newRowUpdateItems: RowItemType[] = [];
      for (const iRow in newRowItems as RowItemType[]) {
        let rowItem: RowItemType = (newRowItems as RowItemType[])[iRow];
        // console.log("row => ", rowItem.rowId);
        // console.log("cols in old oldRowItems => ", (oldRowItems as RowItemType[])[iRow]);

        let newCombinateCols: ColumnItemType[] = [];
        if (!(oldRowItems as RowItemType[])[iRow]) {
          newCombinateCols = (newRowItems as RowItemType[])[iRow].cols;
        } else {
          let oldCols = (oldRowItems as RowItemType[])[iRow].cols;
          // console.log("cols in old row => ", oldCols);
          let newCols = (newRowItems as RowItemType[])[iRow].cols;
          // console.log("cols in new row => ", newCols);

          newCombinateCols = getCombinateOldAndNewColsInBreadcrumpItem(
            oldCols,
            newCols
          );
        }
        // console.log("newCombinateCols => ", newCombinateCols);

        let newRowUpdateItem: RowItemType = {
          id: rowItem.id,
          rowId: rowItem.rowId,
          cols: newCombinateCols,
        };

        newRowUpdateItems.push(newRowUpdateItem);
      }

      let newBcItem: BreadcrumpItemType = {
        id: oldGridItems[index].id,
        tabNumId: oldGridItems[index].tabNumId,
        name: oldGridItems[index].name,
        componentRowItems: newRowUpdateItems,
      };
      result.push(newBcItem);
    }

    return result;
  };

  const getGridItemsAllTabFromNumberOfRowAndCol = () => {
    let newBreadcrumpItems: BreadcrumpItemType[] = [];
    for (const item of breadcrumpItems) {
      let componentRowItems = getGridItemsFromNumberOfRowAndCol(item.tabNumId);
      let newItem: BreadcrumpItemType = {
        id: item.id,
        tabNumId: item.tabNumId,
        name: item.name,
        componentRowItems,
      };
      newBreadcrumpItems.push(newItem);
    }

    // console.log(
    //   "getGridItemsAllTabFromNumberOfRowAndCol old breadcrumpItems",
    //   breadcrumpItems
    // );
    // console.log(
    //   "getGridItemsAllTabFromNumberOfRowAndCol new newBreadcrumpItems",
    //   newBreadcrumpItems
    // );

    let newCombinateGridItems = getCombinateOldAndNewGridItems(
      breadcrumpItems,
      newBreadcrumpItems
    );
    // console.log(
    //   "getGridItemsAllTabFromNumberOfRowAndCol newCombinateGridItems",
    //   newCombinateGridItems
    // );
    return newCombinateGridItems;
  };

  const getGridItemsFromNumberOfRowAndCol = (
    tabNumId: number
  ): RowItemType[] => {
    let result: RowItemType[] = [];

    for (let row = 1; row <= rowNum; row++) {
      let rId = uuidv4();
      let rowId = `r${row}`;

      let cols: ColumnItemType[] = [];
      for (let col = 1; col <= colNum; col++) {
        let cId = uuidv4();
        let colId = `r${row}-c${col}_${tabNumId}`;

        let colItem: ColumnItemType = {
          id: cId,
          colId,
        };
        cols.push(colItem);
      }

      let rowItem = {
        id: rId,
        rowId,
        cols,
      };

      result.push(rowItem);
    }

    return result;
  };

  const updateChildInCellWithBoFieldItem = (
    cellId: string,
    childRef: string
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let cellPosition = getCellPositionFromCellId(cellId) + "_" + tabNumIdActive;
    let newRowItem: any = breadcrumpItemsShow.find(
      (item: any) => item.rowId === rowId
    );
    
    let gPanelItem = newRowItem.cols.map((item: any) => {
      if (item.colId === cellPosition) {
        return {
          ...item,
          childRef,
        };
      } else {
        return item;
      }
    });
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
    };
    // console.log("newRowItem => ", newRowItem);
    return newRowItem;
  };

  const updateChildInCellWithControlItemNotRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);

    let props = controlComponent?.props;
    console.log(
      "updateChildInCellWithControlItemNotRowReplace props : ",
      props
    );
    let { configType } = props;
    let configs = getDefaultComponentInCellConfigByConfigType(configType);
    controlComponent = {
      ...controlComponent,
      props: {
        ...props,
        cellId,
        onRemove: onRemoveComponentFieldInCell,
      },
    };
    // console.log(
    //   "ControlBreadcrump updateChildInCellWithControlItemNotRowReplace controlComponent => ",
    //   controlComponent
    // );

    let rowId = getRowIdFromCellId(cellId);
    let newRowItem: any = breadcrumpItemsShow.find(
      (item: any) => item.rowId === rowId
    );

    let colId = getCellPositionFromCellId(cellId) + "_" + tabNumIdActive;
    let colsInRowItem = newRowItem.cols.map((item: any) => {
      if (item.colId === colId) {
        return {
          ...item,
          childIdRef: "",
          isControlItem: true,
          controlComponent: controlComponent,
          configs,
        };
      } else {
        return item;
      }
    });
    // console.log("new colsInRowItem => ", colsInRowItem);
    newRowItem = {
      ...newRowItem,
      cols: colsInRowItem,
    };
    return newRowItem;
  };

  const updateChildInCellWithControlItemRowReplace = (
    cellId: string,
    controlId: string
  ) => {
    let controlComponent = getControlComponentByControlId(controlId);

    let rowId = getRowIdFromCellId(cellId);
    let newRowItem: any = breadcrumpItemsShow.find(
      (item: any) => item.rowId === rowId
    );

    let newControlComponent = defineRowIdInControlComponent(
      controlComponent,
      rowId
    );

    let gPanelItem = [
      {
        colId: "main_cell-" + rowId + "-c1",
        childRef: "",
      },
    ];
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
      isControlItem: true,
      controlComponent: newControlComponent,
    };
    // console.log("newRowItem => ", newRowItem);
    return newRowItem;
  };

  const getComponentFromChildIdRef = (childRef: string) => {
    if (!childRef || boFieldItems?.length === 0) return;
    let componentItem = boFieldItems.find((item: any) => item.id === childRef);
    return componentItem?.boField;
  };

  const getIdForRemoveComponentField = (focusItem: string) => {
    let cellId = getSubCellIdForRemoveByFocusItem(focusItem);
    let cellPosition = cellId.split("_")[1];
    let cellPositionSplit = cellPosition.split("-");
    let idForRemove = `${cellPositionSplit[1]}-${cellPositionSplit[2]}_${
      cellId.split("_")[2]
    }`;
    return idForRemove;
  };

  const getSubCellIdForRemoveByFocusItem = (focusItem: string) => {
    let focusItemSplit = focusItem.split("_");
    return `${focusItemSplit[0]}_${focusItemSplit[1]}_${focusItemSplit[2]}`;
  };

  const replaceChildInsideCellInGridPanelItemsComponentBc = (
    cellId: string,
    newRowItem: any
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let itemChangedIndex = breadcrumpItemsShow.findIndex(
      (item: any) => item.rowId === rowId
    );

    //SPLIT ITEMS AS 2 SIDE BY INDEX OF ITEM_CHANGED
    let leftGridItems = [...breadcrumpItemsShow].splice(0, itemChangedIndex);
    let rightGridItems = [...breadcrumpItemsShow].splice(
      itemChangedIndex + 1,
      breadcrumpItemsShow.length
    );

    let newGridPanelItems = leftGridItems.concat(newRowItem, rightGridItems);

    return newGridPanelItems;
  };

  const replaceChildInsideCellInGridPanelItems = (
    cellId: string,
    newRowItem: any
  ) => {
    let rowId = getRowIdFromCellId(cellId);
    let itemChangedIndex = breadcrumpItemsShow.findIndex(
      (item: any) => item.rowId === rowId
    );

    //SPLIT ITEMS AS 2 SIDE BY INDEX OF ITEM_CHANGED
    let leftGridItems = [...breadcrumpItemsShow].splice(0, itemChangedIndex);
    let rightGridItems = [...breadcrumpItemsShow].splice(
      itemChangedIndex + 1,
      breadcrumpItemsShow.length
    );

    let newGridPanelItems = leftGridItems.concat(newRowItem, rightGridItems);

    return newGridPanelItems;
  };

  const onAddChildInCellByBofieldItem = (
    cellId: string,
    componentId: string
  ) => {
    let newRowItem = updateChildInCellWithBoFieldItem(cellId, componentId);
    console.log("onAddChildInCellByBofieldItem newRowItem => ", newRowItem);

    let newComponentGridItems = replaceChildInsideCellInGridPanelItems(
      cellId,
      newRowItem
    );
    // console.log(
    //   "onAddChildInCellByBofieldItem newComponentGridItems => ",
    //   newComponentGridItems
    // );

    setNewBreadcrumpItemsOnDropItem(newComponentGridItems);
  };

  const onAddChildInCellByControlItem = (
    cellId: string,
    componentId: string,
    isRowReplace?: boolean
  ) => {
    let newRowItem: any;
    if (isRowReplace) {
      newRowItem = updateChildInCellWithControlItemRowReplace(
        cellId,
        componentId
      );
    } else {
      newRowItem = updateChildInCellWithControlItemNotRowReplace(
        cellId,
        componentId
      );
    }

    let newGridPanelItems: any =
      replaceChildInsideCellInGridPanelItemsComponentBc(cellId, newRowItem);
    setNewBreadcrumpItemsOnDropItem(newGridPanelItems);
  };

  const setNewBreadcrumpItemsOnDropItem = (newComponentGridItems: any) => {
    if (newComponentGridItems.length === 0) return;

    let newBcItems = breadcrumpItems.map((item) => {
      if (item.id === activeBread) {
        return {
          ...item,
          componentRowItems: newComponentGridItems,
        };
      } else {
        return item;
      }
    });

    // console.log("setNewTabItemsByActiveTab newTabItems => ", newTabItems)
    setBreadcrumpItems(newBcItems);
    setBreadcrumpItemsShow(newComponentGridItems);
    onUpdateItems(newBcItems, mainRowId as string);
  };

  const onRemoveFieldInCell = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let cellPosition = focusItem.split("_")[1];
    let cellPositionSplit = cellPosition.split("-");
    let idForRemove =
      cellPositionSplit[1] + "-" + cellPositionSplit[2] + "_" + tabNumIdActive; //r1-c1_1;
    console.log("onRemoveFieldInCell idForRemove => ", idForRemove);

    //GET NEW ROW AFTER REMOVE CHILD
    let newRowItem = removeFieldInCell(idForRemove);
    console.log("onRemoveFieldInCell newRowItem => ", newRowItem);

    //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
    let newComponentRowItems = replaceChildInsideCellInGridPanelItems(
      focusItem,
      newRowItem
    );
    // console.log(
    //   "onRemoveFieldInCell newComponentRowItems => ",
    //   newComponentRowItems
    // );
    setNewBreadcrumpItemsOnDropItem(newComponentRowItems);
    genPageContext.setItemFocusOnConfigPage("");

    setIsBoFieldToolOpen(false);
  };

  const removeFieldInCell = (cellId: string) => {
    let rowId = cellId.split("-")[0]; //GET ROW_ID FROM BO_ID {cf-r1-c0}
    let newRowItem: any = breadcrumpItemsShow.find(
      (item: any) => item.rowId === rowId
    );

    let gPanelItem = newRowItem.cols.map((item: any) => {
      if (item.colId === cellId) {
        return {
          ...item,
          childRef: "",
          isControlItem: false,
          controlComponent: null,
          configs: null,
        };
      } else {
        return item;
      }
    });
    // console.log("new gPanelItem => ", gPanelItem);
    newRowItem = {
      ...newRowItem,
      cols: gPanelItem,
    };
    return newRowItem;
  };

  //-----------------------REMOVE COMPONENT FIELD IN CONTROL_TAB-----------------------//
  const onRemoveComponentFieldInCell = (cellId: string) => {
    console.log("onRemoveComponentFieldInCell cellId => ", cellId);
    setIsRemoveFieldComponent(true);
  };

  useEffect(() => {
    if (isRemoveFieldComponent) {
      let focusItem = genPageContext.itemFocusOnConfigPage;
      let cellId = getSubCellIdForRemoveByFocusItem(focusItem);
      let idForRemove = getIdForRemoveComponentField(focusItem);
      // console.log("onRemoveFieldInCell idForRemove => ", idForRemove);

      //GET NEW ROW AFTER REMOVE CHILD
      let newRowItem = removeFieldInCell(idForRemove);
      // console.log("onRemoveFieldInCell newRowItem => ", newRowItem);

      //REPLACE ALL ITEM IN PANEL WITH NEW ROW ITEM
      let newComponentGridItems =
        replaceChildInsideCellInGridPanelItemsComponentBc(cellId, newRowItem);
      // console.log(
      //   "onRemoveFieldInCell newComponentGridItems => ",
      //   newComponentGridItems
      // );
      setNewBreadcrumpItemsOnDropItem(newComponentGridItems);

      genPageContext.setItemFocusOnConfigPage("");
      setIsRemoveFieldComponent(false);
    }
  }, [isRemoveFieldComponent]);
  //-----------------------^REMOVE COMPONENT FIELD IN CONTROL_TAB^-----------------------//

  //UTILITY FUNCTION
  const removeFucusItem = () => {
    genPageContext.setItemFocusOnConfigPage("");
  };

  const closeAllTool = () => {
    setIsBoFieldToolOpen(false);
    setIsCellToolOpen(false);
    // setPositionTool(null)
  };

  const getCellIdFromFocusItem = () => {
    let focusItem = genPageContext.itemFocusOnConfigPage;
    let rowDetail = focusItem.split("_")[0];
    let rowId = rowDetail.split("-")[1];
    if (focusItem.includes("field")) {
      return { rowId, cellId: focusItem.replace("field", "cell") };
    } else {
      return { rowId, cellId: focusItem };
    }
  };

  const setActiveToFirstTab = () => {
    if (breadcrumpItems.length === 0) return;

    let bcId = breadcrumpItems[0].id;
    setActiveBread(bcId);
  };

  const getSubCellIdByColId = (colId: string) => {
    return getSubCellIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getSubFieldIdByColId = (colId: string) => {
    return getSubFieldIdByMainRowIdAndColId(mainRowId as string, colId);
  };

  const getTabContainerId = () => {
    return getComponentContainerIdByMainRowId(mainRowId as string);
  };

  //MODAL DELETE BO FIELD CONFIRMATION
  const onClickRemoveFieldInCellHandler = () => {
    setIsOpenDeleteFieldInCell(true);
  };

  const onOkDeleteFieldInCellHandler = () => {
    let focusBoItem = genPageContext.itemFocusOnConfigPage;
    console.log("onOkDeleteFieldInCellHandler focusBoItem => ", focusBoItem);

    if (focusBoItem.includes("field")) {
      onRemoveFieldInCell();
    }

    setIsOpenDeleteFieldInCell(false);
  };

  const onCancelDeleteFieldInCellHandler = () => {
    setIsOpenDeleteFieldInCell(false);
  };

  //COMPONENT
  const AddBreadcrump = () => {
    return (
      <Button
        className={styles.bcHeaderAddBtn}
        type="dashed"
        size={"middle"}
        onClick={(e) => actionAddNewBreadcrump(e)}
      >
        Add Breadcrump
      </Button>
    );
  };

  const BreadcrumpHeader = () => {
    return (
      <div className={styles.breadcrumpHeader}>
        {breadcrumpItems.map((item) => {
          return (
            <div
              key={item.id}
              className={[
                styles.bcHeaderBtn,
                // styles.bcHeaderActiveBtn,
                item.id === activeBread ? styles.bcHeaderActiveBtn : "",
              ].join(" ")}
              onClick={(e) => onClickBreadcrump(e, item.id)}
            >
              <div className={styles.stepNameWrapper}>
                <span className={styles.textBc}>{item.name}</span>
                <div onClick={(e) => onClickRemoveBreadcrump(e, item.id)}>
                  <StoryEditEntGenPageRemove style={{ fill: "#1c87f2" }} />
                </div>
              </div>
              <div className={styles.stepIconWrapper}>
                <StoryEditEntGenPageBreadcrumpDot />
                <StoryEditEntGenPageBreadcrumpLine />
              </div>
            </div>
          );
        })}
        <AddBreadcrump />
      </div>
    );
  };

  return (
    <>
      <div id={getTabContainerId()} className={styles.controlTabContainer}>
        {/* <h3 style={{ textAlign: "center", margin: 0 }}>
          {"BreadcrumpComponent focus on => " +
            genPageContext.itemFocusOnConfigPage}
        </h3> */}
        <BreadcrumpHeader />
        <table className={styles.table}>
          <tbody>
            {breadcrumpItemsShow?.map((row) => {
              return (
                <tr key={row.id} id={row.rowId} className={styles.tableTr}>
                  {row.cols?.map((col) => {
                    return (
                      <td
                        key={col.id}
                        id={getSubCellIdByColId(col.colId)}
                        className={[
                          dragOverItem === getSubCellIdByColId(col.colId)
                            ? styles.tableTdDragOver
                            : styles.tableTd,
                          genPageContext.itemFocusOnConfigPage ===
                          getSubCellIdByColId(col.colId)
                            ? styles.tableTdFocus
                            : "",
                        ].join(" ")}
                        onClick={(e) =>
                          onClickCell(e, getSubCellIdByColId(col.colId))
                        }
                        onDrop={(e) => handleDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                      >
                        {col.isControlItem ? (
                          <div
                            className={
                              genPageContext.itemFocusOnConfigPage ===
                              col.colId + "_field"
                                ? styles.controlComponentCellFocus
                                : styles.controlComponentCell
                            }
                            onClick={(e) =>
                              onClickCell(e, getSubFieldIdByColId(col.colId))
                            }
                          >
                            {col.isControlItem
                              ? col.controlComponent
                              : getComponentFromChildIdRef(
                                  col.childRef as string
                                )}
                          </div>
                        ) : col.childRef ? (
                          <div
                            className={
                              genPageContext.itemFocusOnConfigPage ===
                              col.colId + "_field"
                                ? styles.insideTableTdFocus
                                : styles.insideTableTd
                            }
                            onClick={(e) =>
                              onClickCell(e, getSubFieldIdByColId(col.colId))
                            }
                          >
                            {col.isControlItem
                              ? col.controlComponent
                              : getComponentFromChildIdRef(
                                  col.childRef as string
                                )}
                          </div>
                        ) : null}

                        {/* {col.childRef && (
                          <div
                            className={
                              genPageContext.itemFocusOnConfigPage ===
                              getSubFieldIdByColId(col.colId)
                                ? styles.insideTableTdFocus
                                : styles.insideTableTd
                            }
                            onClick={(e) =>
                              onClickCell(e, getSubFieldIdByColId(col.colId))
                            }
                          >
                            {getComponentFromChildIdRef(col.childRef as string)}
                          </div>
                        )} */}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* CONFIRM DELETE BREADCRUMP */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Step?",
          description: `Are you sure you want to delete this Step? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteBcModal}
        onOk={onOkDeleteBcHandler}
        onCancel={onCancelDeleteBcHandler}
      />

      {/* ADD TAB  MODAL*/}
      <AddBreadcrumpModal
        isModalOpen={isOpenAddBcModal}
        onOk={onOkAddBc}
        onCancel={onCancelAddBc}
        onSaveNewBcName={onSaveNewBcName}
        bcNameList={breadcrumpItems.map((item) => item.name)}
      />

      {/* TOOL */}
      <BoFieldTool
        isShow={isBoFieldToolOpen}
        left={positionTool?.left as number}
        top={positionTool?.top as number}
        onClick={onClickRemoveFieldInCellHandler}
      />

      {/* CONFIRM DELETE BO FIELD */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete Field?",
          description: `Are you sure you want to delete this Field? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenDeleteFieldInCell}
        onOk={onOkDeleteFieldInCellHandler}
        onCancel={onCancelDeleteFieldInCellHandler}
      />
    </>
  );
}

export default ControlBreadcrump;
