import React, { useState } from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./StyleTool.module.scss";
import StoryGenPageText from "src/assets/svg/story-genpage-tool-text";
import { Select } from "antd";

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onRemove: () => void;
  onEdit: () => void;
  onSelectBtnType: (type: string) => void;
  btnType?: string;
};

function ButtonTool({
  isShow,
  top,
  left,
  onRemove,
  onEdit,
  onSelectBtnType,
  btnType,
}: Props) {

  let [buttonType, setButtonType] = useState<string>(btnType || "default")

  const buttonTypes = [
    { label: "Default", value: "default" },
    { label: "Primary", value: "primary" },
    { label: "Dashed", value: "dashed" },
    { label: "Link", value: "link" },
    { label: "Text", value: "text" },
  ];

  const onClickRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemove();
  };

  const onClickEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onEdit();
  };

  const onChangeBtnType = (type: string) => {
    console.log("onChangeBtnType type => ", type);
    setButtonType(type)
    onSelectBtnType(type);
  };

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: top + "px",
            left: left + "px",
          }}
          className={styles.cellToolContainer}
        >
          <div
            className={styles.actionWrapperBtn}
          >
            <Select
              variant={"borderless"}
              value={buttonType}
              style={{ width: 120 }}
              onChange={onChangeBtnType}
              options={buttonTypes}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className={styles.lineDivide} />
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickEdit(e)}
          >
            <StoryGenPageText width={"16px"} height={"16px"} />
          </div>
          <div className={styles.lineDivide} />
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickRemove(e)}
          >
            <StoryEditEntDelete width={"16px"} height={"16px"} />
          </div>
        </div>
      )}
    </>
  );
}

export default ButtonTool;
