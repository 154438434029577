import React, { useState } from "react";
import { Breadcrumb } from "antd";
import NavigationBar from "../menu/NavigationBar";
import styles from 'src/page/header-dashboard/HeaderDashboardZero.module.scss';
import AngleLeftIcon from "src/assets/svg/angle-left";

type HeaderDashboardZeroProps = {

}

const breadcrumb = [
    {
        title: 'AppSpace',
    },
    {
        title: 'Zero Project',
    },
    {
        title: 'Order Management',
        active: true
    },
];


const HeaderDashboardZero = ({ }: HeaderDashboardZeroProps) => {
    const [activeItemIndex, setActiveItemIndex] = useState(breadcrumb.length - 1);

    const handleBreadcrumbClick = (index: number) => {
        setActiveItemIndex(index);
    };

    return (
        <>
            <NavigationBar
                header={
                    <Breadcrumb separator={<AngleLeftIcon className={styles.angleLeftIcon} />}>
                        {breadcrumb.map((item, index) => (
                            <Breadcrumb.Item key={index}
                                className={` ${index === activeItemIndex ? styles.active : styles.breadcrumbItem}`}
                                onClick={() => handleBreadcrumbClick(index)}
                            >
                                {item.title}
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                } />
        </>
    );
}

export default HeaderDashboardZero;