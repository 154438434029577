import React from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./StyleTool.module.scss";
import StoryGenPageUploadImage from "src/assets/svg/story-genpage-tool-image-upload";

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onRemove: () => void;
  onUpload: () => void;
};

function ImageIconTool({ isShow, top, left, onRemove, onUpload }: Props) {
  const onClickRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemove();
  };

  const onClickUpload = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onUpload();
  };

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: top + "px",
            left: left + "px",
          }}
          className={styles.cellToolContainer}
        >
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickUpload(e)}
          >
            <StoryGenPageUploadImage width={"16px"} height={"16px"} />
          </div>
          <div className={styles.lineDivide} />
          <div
            className={styles.actionWrapperBtn}
            onClick={(e) => onClickRemove(e)}
          >
            <StoryEditEntDelete width={"16px"} height={"16px"} />
          </div>
        </div>
      )}
    </>
  );
}

export default ImageIconTool;
