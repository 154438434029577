export const TEXT = "text";
export const TEXTAREA = "textarea";
export const PASSWORD = "password";
export const EMAIL = "email";
export const DATE = "date";
export const RADIO = "radio";
export const NUMBER = "number";
export const DROPDOWN = "dropdown";
export const FILE_INPUT = "file_input";
export const IMAGE_INPUT = "image_input";
export const CHECK_BOX = "checkbox";
export const SHOW_AS_LABEL = "show_as_label";
export const INPUT_READ_ONLY = "input_read_only";
export const VARCHAR = "Varchar";
export const DATETIME = "Datetime";
export const INT = "Integer";
export const DECIMAL = "Decimal";
export const BOOLEAN = "Boolean";
export const OBJECT = "Object";
export const LIST_TILE = "LIST_TILE";
export const LIST_CARD = "LIST_CARD";
export const LIST_TABLE = "LIST_TABLE";
export const LIST = "LIST";

