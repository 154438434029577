// import React, { useState, useEffect, useRef } from "react";
// import styles from "../modal/PreviewScreensModal.module.scss";
// import { ConfigProvider, Modal, Select } from "antd";

// type Screen = {
//   screen_name: string;
//   HTML_code: string;
// };

// type PreviewScreensModalProps = {
//   open: boolean;
//   onClose: () => void;
//   resultPreview: {
//     project_name: string;
//     screens: Screen[];
//   };
// };

// function PreviewScreensModal({ open, onClose, resultPreview }: PreviewScreensModalProps) {
//   const [selectedScreen, setSelectedScreen] = useState<string>("");
//   const [iframeContent, setIframeContent] = useState<string>("");

//   useEffect(() => {
//     if (resultPreview && resultPreview.screens && resultPreview.screens.length > 0) {
//       const firstScreen = resultPreview.screens[0];
//       setSelectedScreen(firstScreen.screen_name);
//       setIframeContent(firstScreen.HTML_code);
//     }
//   }, [resultPreview]);

//   const handleScreenChange = (value: string) => {
//     if (resultPreview && resultPreview.screens) {
//       const selected = resultPreview.screens.find(screen => screen.screen_name === value);
//       if (selected) {
//         setSelectedScreen(selected.screen_name);
//         setIframeContent(selected.HTML_code);
//       }
//     }
//   };

//   const classNames = {
//     body: styles['my-modal-body'],
//     mask: styles['my-modal-mask'],
//     header: styles['my-modal-header'],
//     footer: styles['my-modal-footer'],
//     content: styles['my-modal-content'],
//   };

//   const modalStyles = {
//     header: {
//       borderRadius: 0,
//       paddingInlineStart: 5,
//       backgroundColor: 'rgba(217, 217, 217, 1)',
//     },
//     body: {
//       borderRadius: 5,
//     },
//     mask: {
//       backdropFilter: 'blur(10px)',
//     },
//     content: {
//       boxShadow: '0 0 30px #999',
//       backgroundColor: 'rgba(217, 217, 217, 1)',
//     },
//   };

//   const selectStyles = {
//     width: '100%',
//     height: '40px',
//     marginBottom: '20px',
//   };

//   const dropdownStyles = {
//     boxShadow: '0 0 10px rgba(0,0,0,0.2)',
//   };

//   const iframeRef: any = useRef(null);
//   const handleIframeLoad = () => {
//     const iframeContent = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document; 
//     const contentHeight = iframeContent.body.scrollHeight; 
//     const contentWidth = iframeContent.body.scrollWidth;

//     iframeRef.current.style.height = `${contentHeight}px`;
//     iframeRef.current.style.width = `${contentWidth}px`;
//   };

// return (
//   <ConfigProvider
//     modal={{
//       classNames,
//       styles: modalStyles,
//     }}
//   >
//     <Modal
//       title="Please select screen"
//       width={"70%"}
//       open={open}
//       onCancel={onClose}
//       footer={null}
//       className={styles.modalContainer}
//     >
//       <div className={styles.modalContent}>
//         <Select
//           value={selectedScreen}
//           onChange={handleScreenChange}
//           style={selectStyles}
//           dropdownStyle={dropdownStyles}
//           disabled={!resultPreview || !resultPreview.screens || resultPreview.screens.length === 0}
//         >
//           {resultPreview?.screens?.map(screen => (
//             <Select.Option key={screen.screen_name} value={screen.screen_name}>
//               {screen.screen_name}
//             </Select.Option>
//           ))}
//         </Select>

//         <div className={styles.iframeContainer}>
//           <iframe
//             // onLoad={handleIframeLoad}
//             ref={iframeRef}
//             srcDoc={iframeContent}
//             className={styles.iframeStyle}
//             title="Preview"
//           />
//         </div>
//       </div>
//     </Modal>
//   </ConfigProvider>
// );
// }

// export default PreviewScreensModal;

import React, { useEffect, useRef, useState } from "react";
import styles from "../modal/PreviewScreensModal.module.scss";
import { ConfigProvider, Modal, Image, Skeleton } from "antd";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import cardLoad from "src/assets/png/Card load complete.png";

type Screen = {
  screen_name: string;
  HTML_code: string;
};

type PreviewScreensModalProps = {
  open: boolean;
  onClose: () => void;
  resultPreview: {
    project_name: string;
    screens: Screen[];
  };
};

function PreviewScreensModal({ open, onClose, resultPreview }: PreviewScreensModalProps) {
  const [galleryImages, setGalleryImages] = useState<{ img: any; name: string, html: any }[]>([]); // เก็บภาพสกรีนช็อต
  const iframeRefs = useRef<(HTMLIFrameElement | null)[]>([]);
  const [iframeContent, setIframeContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [screenshot, takeScreenshot] = useScreenshot();
  const [screens, setScreens] = useState<Screen[]>([]);

  useEffect(() => {
    if (resultPreview && resultPreview.screens && resultPreview.screens.length > 0) {
      setScreens(resultPreview?.screens);
      // setIframeContent(resultPreview.screens[0].HTML_code); // ตั้งค่า HTML code ของหน้าจอแรก
    }
  }, [resultPreview?.screens]);

  useEffect(() => {
    if (screens.length > 0) {
      takeScreenshots();
    }
  }, [screens]);

  const takeScreenshots = async () => {
    setLoading(true);
    const screenshots: { img: any; name: string, html: any }[] = [];  // Use a separate array for screenshots

    for (let i = 0; i < screens.length; i++) {
      const screen = screens[i];

      if (iframeRefs.current[i]) {
        iframeRefs.current[i]!.srcdoc = screen.HTML_code;
      }

      await new Promise((resolve) => {
        if (iframeRefs.current[i]) {
          iframeRefs.current[i]!.onload = async () => {
            const iframe: any = iframeRefs.current[i];
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

            if (iframeDocument) {
              const checkReady = () => {
                const images = iframeDocument.querySelectorAll('img');
                const allImagesLoaded = Array.from(images).every((img: any) => img.complete);

                if (allImagesLoaded) {
                  takeScreenshot(iframeDocument.body, { width: iframe.offsetWidth, height: iframe.offsetHeight })
                    .then((img: any) => {
                      if (img) {

                        screenshots.push({ img: img, name: screens[i].screen_name, html: screens[i].HTML_code }); // Push to the separate screenshots array
                      }
                      resolve(null);
                    });
                } else {
                  requestAnimationFrame(checkReady);
                }
              };

              setTimeout(() => {
                checkReady();
              }, 2000);
            } else {
              resolve(null);
            }
          };
        } else {
          resolve(null);
        }
      });
    }

    setGalleryImages(screenshots); // Set the state with the screenshots
    setLoading(false);
  };


  const classNames = {
    body: styles['my-modal-body'],
    mask: styles['my-modal-mask'],
    header: styles['my-modal-header'],
    footer: styles['my-modal-footer'],
    content: styles['my-modal-content'],
  };

  const modalStyles = {
    header: {
      borderRadius: 0,
      paddingInlineStart: 5,
      // backgroundColor: 'rgba(217, 217, 217, 1)',
    },
    body: {
      borderRadius: 5,
      // height: "100%",
    },
    mask: {
      backdropFilter: 'blur(10px)',
    },
    content: {
      boxShadow: '0 0 30px #999',
      // height: "500px"
      // backgroundColor: 'rgba(217, 217, 217, 1)',
    },
  };

  const selectStyles = {
    width: '100%',
    height: '40px',
    marginBottom: '20px',
  };

  const dropdownStyles = {
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
  };


  return (
    <ConfigProvider modal={{
      classNames,
      styles: modalStyles,
    }}>
      <Modal
        title={`The generation results ${screens.length} pages.`}
        width={"100%"}
        open={open}
        onCancel={onClose}
        footer={null}
        className={styles.modalContainer}
      >

        {/* แสดงภาพสกรีนช็อตเป็นแกลเลอรี */}
        <div className={styles.gallery}>
          {loading ? 
          (
            Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className={styles.card}>
                {/* <Skeleton.Image active style={{ width: 250, height: 200 }} /> */}
                <Image
                  key={index}
                  src={cardLoad}
                  className={styles.galleryImage}
                  preview={false}
                />
                <Skeleton active paragraph={{ rows: 0 }} className={styles.Skeleton} />
              </div>
            ))
          )
        : (galleryImages.map(({ img, name, html }: any, index) => (
            <div key={index} className={styles.cardGallery} style={{cursor:"pointer"}}
              onClick={() => {
                // Open the iframe content in a new tab
                const newTab:any = window.open();
                newTab.document.body.innerHTML = `
                <iframe
                  srcdoc="${html.replace(/"/g, '&quot;')}"
                  style="width: 100%; height: 100%; border: none;"
                  title="Preview ${index}"
                />
              `;
              }}
            >
              <Image
                key={index}
                src={img}
                alt={`Screenshot ${name}`}
                className={styles.galleryImage}
                preview={false}

              />
              <div className={styles.titleName}>
                <div className={styles.cardTitle}>Page : </div>
                <div className={styles.fileName}>{name}</div>
              </div>
            </div>
          )))}

        </div>

        <div className={styles.modalContent}>
          {/* iframe สำหรับจับภาพ */}
          {screens?.map((item: any, index: number) => (
            <iframe
              key={index} // ใช้ key สำหรับแต่ละ iframe
              ref={(el) => (iframeRefs.current[index] = el)}
              srcDoc={item?.HTML_code} // ตั้งค่า srcDoc ที่นี่
              style={{
                position: 'absolute',
                top: '-9999px', // ทำให้ iframe อยู่ข้างนอกมุมมอง
                left: '-9999px',
                width: '1000px',
                height: '1000px',
                border: 'none',
                visibility: 'hidden', // ซ่อน iframe
                zIndex: -1, // ทำให้ iframe อยู่ด้านล่าง
              }}  // ตั้งค่าเริ่มต้นให้ใหญ่
              // onLoad={takeScreenshots}
              title={`Preview ${index}`}
            />
          ))}

        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default PreviewScreensModal;
