import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LogoutOutlined } from "@ant-design/icons";
import { AuthKeys } from "src/services/auth.service";
import { pathRoutes } from "src/page/props/util.props";
import { useNavigate } from "react-router";

interface TokenExpireModalProps {
  open: boolean;
  onClose: () => void;
  onRenewToken: () => void;
  onLogout: () => void;
  canRefreshToken?: boolean;
}

export const TokenExpireModal: React.FunctionComponent<
  TokenExpireModalProps
> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canRefreshToken } = props;

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const showLogoutConfirm = () => {
    // setShowLogoutModal(true);
  };

  const logout = () => {
    console.log("logout");
    localStorage.removeItem(AuthKeys.openID);
    localStorage.removeItem(AuthKeys._key_user);
    localStorage.removeItem(AuthKeys.user);
    // setIsModalVisible(true);
    navigate(pathRoutes.Generate);
    window.location.reload();
  };

  return (
    <Modal
      title={t("Token is about to expire.")}
      centered
      closable={false}
      // style={{ height: "50vh"}}
      open={props.open}
      // onCancel={props.onClose}
      // onOk={handleOk}
      // confirmLoading={saveSetting.isLoading}
      // onCancel={props.handleCancel}
      footer={[
        <>
          {canRefreshToken && (
            <Button
              type="primary"
              //  onClick={handleRenewToken} loading={refreshTokenMutation.isLoading}
            >
              {t("Renew the token.")}
            </Button>
          )}
        </>,
        <Button
          danger
          icon={<LogoutOutlined />}
          size={"middle"}
          onClick={logout}
        >
          {t("Logout")}
        </Button>,
      ]}
    >
      <p>
        {t(
          "The session has ended. Please press 'Logout' to return to the login page."
        )}
        {/* {t("The session has ended. Please press 'Renew Token' to continue using the service, or press 'Logout' to return to the login page.")} */}
      </p>
    </Modal>
  );
};
export default TokenExpireModal;
