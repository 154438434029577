import React, { createContext, useState } from "react";
type ChooseTemplateProps = {
  list_template_id: string;
  create_template_id: string;
};
type GenerateBoProps = {
  enyity_bo_item_id: string;
  enyity_bo_item_name: string;
  bo_config_type: "SEARCH_FILTER" | "SEARCH_RESULT";
  default_search?: string;
  sort_by?: string;
};
export type BoFieldForDataProps = {
  key: string;
  boName: string;
  boFieldName: string;
  type: string;
  isPrimaryKey?: boolean;
  rootPath?: string;
};
type BoFieldForSelectProps = {
  value: string;
  label: string;
};
type BoFieldForConfig = {
  id: string;
  boField: string;
};

interface StoryGeneratePageState {
  storyName: string;
  setStoryName: (value: string) => void;
  currentStep: number;
  setCurrentStep: (value: number) => void;
  chooseTemplate: ChooseTemplateProps;
  setChooseTemplate: ({
    list_template_id,
    create_template_id,
  }: ChooseTemplateProps) => void;
  isSaveChooseTemplate: boolean;
  setIsSaveChooseTemplate: (value: boolean) => void;
  generateBoConfig: GenerateBoProps[];
  setGenerateBoConfig: (value: GenerateBoProps[]) => void;
  isSaveSearchFilter: boolean;
  setIsSaveSearchFilter: (value: boolean) => void;
  isSaveSearchResult: boolean;
  setIsSaveSearchResult: (value: boolean) => void;
  boFieldForData: BoFieldForDataProps[];
  setBoFieldForData: (value: BoFieldForDataProps[]) => void;
  boFieldForSelect: BoFieldForSelectProps[];
  setBoFieldForSelect: (value: BoFieldForSelectProps[]) => void;
  boFieldForConfig: BoFieldForConfig[];
  setBoFieldForConfig: (value: BoFieldForConfig[]) => void;
  itemFocusOnConfigPage: string;
  setItemFocusOnConfigPage: (value: string) => void;
  panelItems: any;
  setPanelItems: (value: any) => void;
  updateComponents: any;
  setUpdateComponents: (value: any) => void;
  selectComponentTable: BoFieldForSelectProps[];
  setSelectComponentTable: (value: BoFieldForSelectProps[]) => void;
  mappingParentChildComponentTable: any;
  setMappingParentChildComponentTable: (value: any) => void;
  isLoadingBtnGeneratePage: boolean;
  setIsLoadingBtnGeneratePage: (value: boolean) => void;
  conTrolconfigs: any;
  setConTrolconfigs: (value: any) => void;
  isVersionGenerated: boolean;
  setIsVersionGenerated: (value: any) => void;
  generateVersionStorage: any;
  setGenerateVersionStorage: (value: any) => void;
  versionSubComponent: any;
  setVersionSubComponent: (value: any) => void;
  isIconItemSeted: boolean;
  setIsIconItemSeted: (value: boolean) => void;
  isImageItemSeted: boolean;
  setIsImageItemSeted: (value: boolean) => void;
  isTextItemSeted: boolean;
  setIsTextItemSeted: (value: boolean) => void;
  parentSelectedComponentTable: any;
  setParentSelectedComponentTable: (value: any) => void;
}

const StoryGeneratePageContext = createContext<StoryGeneratePageState>({
  storyName: "",
  setStoryName: () => {},
  currentStep: 0,
  setCurrentStep: () => {},
  chooseTemplate: {
    list_template_id: "",
    create_template_id: "",
  },
  setChooseTemplate: () => {},
  isSaveChooseTemplate: false,
  setIsSaveChooseTemplate: () => {},
  generateBoConfig: [],
  setGenerateBoConfig: () => {},
  isSaveSearchFilter: false,
  setIsSaveSearchFilter: () => {},
  isSaveSearchResult: false,
  setIsSaveSearchResult: () => {},
  boFieldForData: [],
  setBoFieldForData: () => {},
  boFieldForSelect: [],
  setBoFieldForSelect: () => {},
  boFieldForConfig: [],
  setBoFieldForConfig: () => {},
  itemFocusOnConfigPage: "",
  setItemFocusOnConfigPage: () => {},
  panelItems: [],
  setPanelItems: () => {},
  updateComponents: [],
  setUpdateComponents: () => {},
  selectComponentTable: [],
  setSelectComponentTable: () => {},
  mappingParentChildComponentTable: [],
  setMappingParentChildComponentTable: () => {},
  isLoadingBtnGeneratePage: false,
  setIsLoadingBtnGeneratePage: () => {},
  conTrolconfigs: [],
  setConTrolconfigs: () => {},
  isVersionGenerated: false,
  setIsVersionGenerated: () => {},
  generateVersionStorage: [],
  setGenerateVersionStorage: () => {},
  versionSubComponent: [],
  setVersionSubComponent: () => {},
  isIconItemSeted: false,
  setIsIconItemSeted: () => {},
  isImageItemSeted: false,
  setIsImageItemSeted: () => {},
  isTextItemSeted: false,
  setIsTextItemSeted: () => {},
  parentSelectedComponentTable: [],
  setParentSelectedComponentTable: () => {},
});

const StoryGeneratePageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<StoryGeneratePageState>({
    storyName: "",
    setStoryName: (value: string) => {
      setState((prevState) => ({ ...prevState, storyName: value }));
    },
    currentStep: 0,
    setCurrentStep: (value: number) => {
      setState((prevState) => ({ ...prevState, currentStep: value }));
    },
    chooseTemplate: {
      list_template_id: "",
      create_template_id: "",
    },
    setChooseTemplate: ({
      list_template_id,
      create_template_id,
    }: ChooseTemplateProps) => {
      setState((prevState) => ({
        ...prevState,
        chooseTemplate: { list_template_id, create_template_id },
      }));
    },
    isSaveChooseTemplate: false,
    setIsSaveChooseTemplate: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isSaveChooseTemplate: value }));
    },
    generateBoConfig: [],
    setGenerateBoConfig: (value: GenerateBoProps[]) => {
      setState((prevState) => ({ ...prevState, generateBoConfig: value }));
    },
    isSaveSearchFilter: false,
    setIsSaveSearchFilter: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isSaveSearchFilter: value }));
    },
    isSaveSearchResult: false,
    setIsSaveSearchResult: (value: boolean) => {
      setState((prevState) => ({ ...prevState, isSaveSearchResult: value }));
    },
    boFieldForData: [],
    setBoFieldForData: (value: BoFieldForDataProps[]) => {
      setState((prevState) => ({ ...prevState, boFieldForData: value }));
    },
    boFieldForSelect: [],
    setBoFieldForSelect: (value: BoFieldForSelectProps[]) => {
      setState((prevState) => ({ ...prevState, boFieldForSelect: value }));
    },
    boFieldForConfig: [],
    setBoFieldForConfig: (value: BoFieldForConfig[]) => {
      setState((prevState) => ({ ...prevState, boFieldForConfig: value }));
    },
    itemFocusOnConfigPage: "",
    setItemFocusOnConfigPage: (value: string) => {
      setState((prevState) => ({ ...prevState, itemFocusOnConfigPage: value }));
    },

    panelItems: [],
    setPanelItems: (value: any[]) => {
      setState((prevState) => ({ ...prevState, panelItems: value }));
    },
    updateComponents: [],
    setUpdateComponents: (value: any[]) => {
      setState((prevState) => ({ ...prevState, updateComponents: value }));
    },
    selectComponentTable: [],
    setSelectComponentTable: (value: BoFieldForSelectProps[]) => {
      setState((prevState) => ({ ...prevState, selectComponentTable: value }));
    },
    mappingParentChildComponentTable: [],
    setMappingParentChildComponentTable: (value: any[]) => {
      setState((prevState) => ({
        ...prevState,
        mappingParentChildComponentTable: value,
      }));
    },

    conTrolconfigs: [],
    setConTrolconfigs: (value: any[]) => {
      setState((prevState) => ({
        ...prevState,
        conTrolconfigs: value,
      }));
    },
    
    isLoadingBtnGeneratePage: false,
    setIsLoadingBtnGeneratePage: (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isLoadingBtnGeneratePage: value,
      }));
    },
    
    isVersionGenerated: false,
    setIsVersionGenerated: (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isVersionGenerated: value,
      }));
    },
    
    generateVersionStorage: [],
    setGenerateVersionStorage: (value: any[]) => {
      setState((prevState) => ({
        ...prevState,
        generateVersionStorage: value,
      }));
    },
    
    versionSubComponent: [],
    setVersionSubComponent: (value: any[]) => {
      setState((prevState) => ({
        ...prevState,
        versionSubComponent: value,
      }));
    },
    
    isIconItemSeted: false,
    setIsIconItemSeted: (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isIconItemSeted: value,
      }));
    },
    
    isImageItemSeted: false,
    setIsImageItemSeted: (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isImageItemSeted: value,
      }));
    },
    
    isTextItemSeted: false,
    setIsTextItemSeted: (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isTextItemSeted: value,
      }));
    },
    
    parentSelectedComponentTable: [],
    setParentSelectedComponentTable: (value: any[]) => {
      setState((prevState) => ({
        ...prevState,
        parentSelectedComponentTable: value,
      }));
    },
  });
  
  return (
    <StoryGeneratePageContext.Provider value={state}>
      {children}
    </StoryGeneratePageContext.Provider>
  );
};

export { StoryGeneratePageContext, StoryGeneratePageProvider };
