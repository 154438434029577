export default function renderIconScreens(screenElement, screen) {
  screenElement.style.display = "flex";
  screenElement.style.justifyContent = "center";
  let iconPath = screen.iconPath;

  const icon = document.createElement("img");
  icon.src = iconPath;
  icon.alt = "Description of icon";
  icon.style.borderRadius = "100%";
  screenElement.appendChild(icon);
}
