export default function renderImageScreens(screenElement, screen) {
  screenElement.style.display = "flex";
  screenElement.style.justifyContent = "center";
  let imgPath = screen.imagePath;

  const image = document.createElement("img");
  image.src = imgPath;
  image.alt = "Description of image";
  screenElement.appendChild(image);
}
