import React from "react";
import styles from "./StyleTool.module.scss";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import StoryGenPageAddBelow from "src/assets/svg/story-genpage-tool-add-below";
import StoryGenPageAddRight from "src/assets/svg/story-genpage-tool-add-right";
import StoryGenPageArrowLeft from "src/assets/svg/story-genpage-tool-arrow-left";
import StoryGenPageArrowRight from "src/assets/svg/story-genpage-tool-arrow-right";
import StoryGenPageAlignLeft from "src/assets/svg/story-genpage-tool-align-left";
import StoryGenPageAlignCenter from "src/assets/svg/story-genpage-tool-align-center";
import StoryGenPageAlignRight from "src/assets/svg/story-genpage-tool-align-right";

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onClickDelete: () => void;
};

function CellTool({ isShow, top, left, onClickDelete }: Props) {
  const leftPositionFixBySizeOfTool = 120;
  let newLeftPosition = left - leftPositionFixBySizeOfTool;
  let newTopPosition = top - 15;

  //ACTION HANDLER
  const onClickDeleteTool = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onClickDelete();
  }
  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: newTopPosition + "px",
            left: newLeftPosition > 0 ? newLeftPosition : 0 + "px",
          }}
          className={styles.cellToolContainer}
        >
          <div className={styles.actionWrapper}>
            <StoryGenPageAddBelow width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageAddRight width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageArrowLeft width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageArrowRight width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageAlignLeft width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageAlignCenter width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper}>
            <StoryGenPageAlignRight width={"20px"} height={"20px"} />
          </div>
          <div className={styles.actionWrapper} onClick={(e) => onClickDeleteTool(e)} >
            <StoryEditEntDelete width={"16px"} height={"16px"} />
          </div>
        </div>
      )}
    </>
  );
}

export default CellTool;
