import React, { useEffect, useState } from "react";
import styles from "../CreateBoModal.module.scss";
import { Checkbox, CheckboxProps, Input, Select } from "antd";
import {
  SELECT_DATA_TYPE,
  SELECT_DATA_TYPE_OBJECT,
} from "src/utils/create-bo/DataTypeSelect";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import { LAYER_LIMIT } from "src/utils/create-bo/createBo.util";

type Props = {
  itemId: string;
  itemLength: number;
  isTypeObject: boolean;
  updateFieldByItemId: (
    itemId: string,
    fieldName: string,
    dataType: string,
    isPrimaryKey: boolean,
    foreignKey: string
  ) => void;
  onSetItemToDelete: (item: string) => void;
  isCreateMode: boolean;
  updateData?: {
    itemId: string;
    fieldName: string;
    dataType: string;
    isPrimaryKey: boolean;
    foreignKey: string;
  };
  layerOfParent: number;
  fkSelects: { value: string, label: string }[]
};

function AddBoInputItem({
  itemId,
  itemLength,
  isTypeObject,
  updateFieldByItemId,
  onSetItemToDelete,
  isCreateMode,
  updateData,
  layerOfParent,
  fkSelects,
}: Props) {
  let [selectDataTypes, setSelectDataTypes] = useState<
    { value: string; label: string }[]
  >([]);
  let [fieldName, setFieldName] = useState<string>("");
  let [dataType, setDataType] = useState<string>("");
  let [isCheckedPK, setIsCheckedPK] = useState<boolean>(false);
  let [foreignKey, setForeignKey] = useState<string>("");
  let [fkSelectsInBoItem, setFkSelectsInBoItem] =
    useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    let newSelectDataTypes = getSelectsDataTypeOnLoad();
    setSelectDataTypes(newSelectDataTypes);

    if (isCreateMode) {
      setDataType(newSelectDataTypes[0].value);
    } else {
      if (!updateData) return;
      setFieldName(updateData.fieldName);
      setDataType(updateData.dataType);
      setIsCheckedPK(updateData.isPrimaryKey);
      setForeignKey(updateData.foreignKey || "");
    }
  }, []);

  //ADD START SELECT_LIST WITH DEFAULT ITEM
  useEffect(() => {
    console.log("fkSelects", fkSelects)
    let defaultItem = {
      value: "",
      label: "None"
    }
    let newFkSelectsBoItem = [defaultItem, ...fkSelects]
    setFkSelectsInBoItem(newFkSelectsBoItem)
  }, [fkSelects])

  //CLEAR FK AND PK IF CHANGE DATA_TYPE = OBJECT
  useEffect(() => {
    if (isDataTypeObject()) {
      setOnChangeToTypeObject()
    }
  }, [dataType])

  //ACTION HANDLER
  const onChangeInputFieldnameHandler = (input: string) => {
    setFieldName(input);
    updateFieldByItemId(itemId, input, dataType, isCheckedPK, foreignKey);
  };
  const onChangeSelectDataTypeHandler = (input: string) => {
    setDataType(input);
    updateFieldByItemId(itemId, fieldName, input, isCheckedPK, foreignKey);
  };
  const onChangeIsCheckedPKHandler: CheckboxProps["onChange"] = (e) => {
    let isDefault = e.target.checked;
    console.log(`onChangeIsCheckedPK = ${isDefault}`);
    setIsCheckedPK(isDefault);
    updateFieldByItemId(itemId, fieldName, dataType, isDefault, foreignKey);
  };
  const onChangeFkHandler = (fk: string) => {
    setForeignKey(fk);
    updateFieldByItemId(itemId, fieldName, dataType, isCheckedPK, fk);
  }
  const onClickItemToRemoveHandler = (itemId: string) => {
    onSetItemToDelete(itemId);
  };

  //UTILITY FUNCTION 
  const setOnChangeToTypeObject = () => {
    setForeignKey("")
    setIsCheckedPK(false)
  }
  const isDataTypeObject = () => {
    return dataType === "object";
  }
  const getSelectsDataTypeOnLoad = () => {
    let dataTypes = isTypeObject ? SELECT_DATA_TYPE_OBJECT : SELECT_DATA_TYPE;
    let newSelectDataTypes = [...dataTypes];
    if (layerOfParent >= LAYER_LIMIT) {
      newSelectDataTypes = newSelectDataTypes.filter(
        (item) => item.value !== "object"
      );
    }
    return newSelectDataTypes;
  }

  return (
    <div
      className={
        itemLength > 1
          ? styles.createBoInputMoreOneColWrapper
          : styles.createBoInputOneColWrapper
      }
    >
      {/* INPUT FIELD */}
      <div className={styles.selectWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.textHeader}>Please input field name</span>
          <span className={styles.textImportant}>*</span>
        </div>
        <div className={styles.actionWrapper}>
          <Input
            placeholder={"Field Name"}
            className={styles.inputField}
            value={fieldName}
            onChange={(e) =>
              onChangeInputFieldnameHandler(e.currentTarget.value)
            }
          />
        </div>
      </div>

      {/* DATA TYPE */}
      <div className={styles.selectWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.textHeader}>Please select data type</span>
          <span className={styles.textImportant}>*</span>
        </div>
        <div className={styles.actionWrapper}>
          <Select
            value={dataType}
            options={selectDataTypes}
            style={{ width: "100%" }}
            placeholder={<div>&nbsp;&nbsp;&nbsp;Table</div>}
            onChange={onChangeSelectDataTypeHandler}
            optionFilterProp="children"
            className={styles.genTableSelect}
            disabled={isTypeObject ? true : false}
          />
        </div>
      </div>

      {/* PK AND FK ON IF ITEM MORE THAN ONE */}
      {itemLength > 1 && !isDataTypeObject() && (
        <div className={styles.primaryKeyWrapper}>
          {/* PRIMARY KEY */}
          <div className={styles.selectWrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.textHeader}>PK</span>
            </div>
            <div
              className={styles.actionWrapper}
              style={{ display: "flex", alignItems: "center", height: "40px" }}
            >
              <Checkbox checked={isCheckedPK} onChange={onChangeIsCheckedPKHandler} />
            </div>
          </div>

          {/* FOREIGN KEY */}
          <div className={styles.selectWrapper} style={{ flex: 1 }}>
            <div className={styles.titleWrapper}>
              <span className={styles.textHeader}>Foreign Key</span>
            </div>
            <div className={styles.actionWrapper}>
              <Select
                value={foreignKey}
                options={fkSelectsInBoItem}
                style={{ width: "120px" }}
                // placeholder={<div>&nbsp;&nbsp;&nbsp;Table</div>}
                onChange={onChangeFkHandler}
                optionFilterProp="children"
                className={styles.genTableSelect}
                disabled={isTypeObject ? true : false}
              />
              <div
                className={styles.actionIconWrapper}
                onClick={() => onClickItemToRemoveHandler(itemId)}
                style={{ marginLeft: "5px", width: "30px", height: "30px" }}
              >
                <StoryEditEntDelete className={styles.actionIcon} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PK AND FK ON IF ITEM EQUAL ONE */}
      {itemLength === 1 && !isDataTypeObject() && (
        <div className={styles.primaryKeyWrapper}>
          {/* PRIMARY KEY */}
          <div className={styles.selectWrapper} style={{ flex: 1 }}>
            <div className={styles.titleWrapper}>
              <span className={styles.textHeader}>Primary Key</span>
            </div>
            <div
              className={styles.actionWrapper}
              style={{ display: "flex", alignItems: "center", height: "40px" }}
            >
              <Checkbox checked={isCheckedPK} onChange={onChangeIsCheckedPKHandler} />
            </div>
          </div>

          {/* FOREIGN KEY */}
          <div className={styles.selectWrapper} style={{ flex: 1 }}>
            <div className={styles.titleWrapper}>
              <span className={styles.textHeader}>Foreign Key</span>
            </div>
            <div className={styles.actionWrapper}>
              <Select
                value={foreignKey}
                options={fkSelectsInBoItem}
                style={{ width: "100%" }}
                // placeholder={<div>&nbsp;&nbsp;&nbsp;Table</div>}
                onChange={onChangeFkHandler}
                optionFilterProp="children"
                className={styles.genTableSelect}
                disabled={isTypeObject ? true : false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBoInputItem;
