import React, { useContext, useEffect, useState } from "react";
import styles from "./MoreSettingConfigModal.module.scss";
import { Modal, Button, Tabs, TabsProps } from "antd";
import "./MoreSettingConfigModal.scss";
import { DROPDOWN } from "src/constants/InputType";
import { DataListType, SelectFromTableType } from "src/types/MoreSettingType";
import SelectFromTable from "./dropdown-config/select-from-table/SelectFromTable";
import DataListConfig from "./dropdown-config/data-list/DataListConfig";
import CreateBusinessObjectHandler from "src/utils/create-bo/CreateBusinessObjectHandler";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { SaveDataReferResponse } from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import { SaveDataReferRequest } from "src/types/request.type";
import { ADD_DATA_REFER_ENDPOINT } from "src/utils/endpoint/createbo.endpoint";
import { STATUS_OK } from "src/constants/ResponseStatus";
import { DATA_LIST, FROM_TABLE } from "src/constants/DataReferType";
import { getAspId } from "src/services/util.service";
import MessageNofify from "../../../notification/MessageNotify";

type InputOnSaveProps = {
  input_type: string;
  data_refer_name: string;
  data_refer_id: string;
};
type Props = {
  isModalOpen: boolean;
  onSave: ({
    input_type,
    data_refer_name,
    data_refer_id,
  }: InputOnSaveProps) => void;
  onCancel: () => void;
  createBoHandler: CreateBusinessObjectHandler;
};

const ADD_DATA_REFER_FK = "addDataRefFK";

function MoreSettingConfigModal({
  isModalOpen,
  onSave,
  onCancel,
  createBoHandler,
}: Props) {
  const { loading, error, responseData, fetchDataAsync, fetchKey } =
    useFetch<SaveDataReferResponse[]>();
  let aspId = getAspId();

  let storyEntityContext = useContext(StoryEntityContext);
  let [id, setId] = useState<string>("");
  let [title, setTitle] = useState<string>("");
  let [inputType, setInputType] = useState<string>("");
  let [activeKey, setActiveKey] = useState<string>("1");
  let [dataListItems, setDataListItems] = useState<DataListType[]>([]);
  let [selectTableItem, setSelectTableItem] = useState<SelectFromTableType>();
  let [isRerender, setIsReRender] = useState<boolean>(false);

  //NOTITY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  //--CHECK STATE OPEN FROM PARENT COMPONENT
  useEffect(() => {
    if (isModalOpen) {
      let dataReferData = storyEntityContext.dataReferConfig;
      if (dataReferData?.id) {
        let titleHeader = dataReferData.title + " Config";
        setId(dataReferData.id);
        setTitle(titleHeader);
        setInputType(dataReferData.input_type);
        setActiveKey("1");
        let msItem = createBoHandler.getMoreSettingItemById(dataReferData.id);
        if (msItem) {
          let dataList = msItem.configData?.dataList;
          let selectFromTable = msItem.configData?.selectFromTable;

          if (dataList?.length > 0) setDataListItems(dataList);
          if (selectFromTable) setSelectTableItem(selectFromTable);
        }

        setIsReRender(!isRerender);
      }
    }
  }, [isModalOpen]);

  //--CHECK RESPONSE BY STATE USEFETCH FROM CALL API SUCCESS
  useEffect(() => {
    if (!loading) {
      if (error || !responseData) return;

      switch (fetchKey) {
        case ADD_DATA_REFER_FK: {
          let data = responseData[0]?.data;
          let input: InputOnSaveProps = {
            input_type: data?.input_type as string,
            data_refer_name: data?.data_refer_name as string,
            data_refer_id: data?.data_refer_id as string,
          };
          onSave(input);
          clearStateItems();

          //SAVE SUCCESS
          let title = "Save data-refer success";
          let description = "data-reference has been create!";
          openNotifySuccess(title, description);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  const onClickCancelHandler = () => {
    onCancel();
    setDataListItems([]);
    setSelectTableItem(undefined);
  };

  const onClickSaveHandler = () => {
    let requestSaveDataRefers: SaveDataReferRequest[] = [];
    let dataReferType = getDataReferTypeByActiveKey();
    if (dataReferType === DATA_LIST) {
      if (!isPassValidateDataList(dataListItems)) {
        let title = "Save data-list Error!";
        let description = "Please add item at least 1!";
        openNotifyError(title, description);
        return;
      }

      let data_from_list = [...dataListItems].map((item) => {
        return {
          data_value: item.showValue,
          data_view: item.showName,
          default_check: item.isDefaultCheck.toString(),
        };
      });

      let req: SaveDataReferRequest = {
        action: "create",
        asp_id: aspId as string,
        data_refer_name: title,
        input_type: inputType,
        data_refer_type: "DATA_LIST",
        data_from_list,
      };
      requestSaveDataRefers.push(req);
    } else if (dataReferType === FROM_TABLE) {
      if (!isPassValidateFromTable(selectTableItem as SelectFromTableType)) {
        let title = "Save data-table Error!";
        let description = "Please input require field!";
        openNotifyError(title, description);
        return;
      }

      let req: SaveDataReferRequest = {
        action: "create",
        asp_id: aspId as string,
        data_refer_name: title,
        input_type: inputType,
        data_refer_type: "FROM_TABLE",
        data_from_table: {
          table_name: selectTableItem?.tableName as string,
          column_name: selectTableItem?.columnShow as string,
          column_value: selectTableItem?.columnValue as string,
          condition_value: selectTableItem?.conditionValue as string,
          advance_search: selectTableItem?.advanceSearch as string,
          connection_id: selectTableItem?.connectionId as string,
        },
      };
      requestSaveDataRefers.push(req);
    }
    addDataReferItemAsyncController(requestSaveDataRefers);
  };

  //ONSAVE CONFIG PROPS
  const onUpdateDataListHandler = (newDataListItems: DataListType[]) => {
    setDataListItems(newDataListItems);
  };
  const onUpdateSelectTableHandler = (
    newSelectTableItem: SelectFromTableType
  ) => {
    setSelectTableItem(newSelectTableItem);
  };

  //COMPONENT
  const TitleContent = ({ title }: { title: string }) => {
    return (
      <div className={styles.createTitleModal}>
        <div className={styles.title}>
          <span>{title}&nbsp;&nbsp;</span>
        </div>
      </div>
    );
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Data List",
      children: (
        <DataListConfig
          onUpdate={onUpdateDataListHandler}
          reRender={isRerender}
          data={dataListItems}
        />
      ),
    },
    {
      key: "2",
      label: "Select from Table",
      children: (
        <SelectFromTable
          onUpdate={onUpdateSelectTableHandler}
          reRender={isRerender}
          data={selectTableItem as SelectFromTableType}
        />
      ),
    },
  ];

  //COMPONENT HANDLER
  const onChangeTab = (key: string) => {
    console.log(key);
    setActiveKey(key);
  };

  //API CONTROLLER FUNCTION
  const addDataReferItemAsyncController = (
    inputReq: SaveDataReferRequest[]
  ) => {
    let request: any = inputReq.map((req) => {
      return {
        endpoint: ADD_DATA_REFER_ENDPOINT,
        method: "POST",
        reqBody: req,
      };
    });
    fetchDataAsync(request, ADD_DATA_REFER_FK);
  };

  //UTILITY FUNCTION
  const getDataReferTypeByActiveKey = () => {
    return activeKey === "1" ? DATA_LIST : FROM_TABLE;
  };

  const isPassValidateDataList = (dataListItems: DataListType[]) => {
    return dataListItems.length > 0 ? true : false;
  };

  const isPassValidateFromTable = (
    selectFromTableItem: SelectFromTableType
  ): boolean => {
    let { tableName, columnShow, columnValue, conditionValue, advanceSearch } =
      selectFromTableItem;

    return Boolean(
      tableName && columnShow && columnValue && conditionValue && advanceSearch
    );
  };

  const clearStateItems = () => {
    setDataListItems([]);
    setSelectTableItem(undefined);
  };

  //NOTIFY HANDLER
  const openNotifyError = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(false);
  };

  const openNotifySuccess = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(true);
  };

  return (
    <>
      <Modal
        title={<TitleContent title={title} />}
        footer={
          <div className={styles.createFooterModal}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={onClickCancelHandler}
            >
              Cancel
            </Button>

            <Button
              className={styles.confirmBtn}
              key="save"
              onClick={onClickSaveHandler}
              type="primary"
            >
              Save
            </Button>
          </div>
        }
        open={isModalOpen}
        // onOk={onOkModal}
        onCancel={onCancel}
        centered={true}
        width={"80%"}
      >
        <span className={styles.createContentModal}>
          {/* CHECK INPUTTYPE FOR MORE SETTING CONFIG */}
          {inputType === DROPDOWN ? (
            <Tabs
              defaultActiveKey="1"
              activeKey={activeKey}
              items={items}
              onChange={onChangeTab}
              style={{ width: "100%" }}
              size="small"
            />
          ) : (
            <DataListConfig
              onUpdate={onUpdateDataListHandler}
              reRender={isRerender}
              data={dataListItems}
            />
          )}
        </span>
      </Modal>

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );
}

export default MoreSettingConfigModal;
