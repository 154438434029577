const NodeInit = [
  {
    loc: "0, 0",
    text: "BusinessObject",
    isGroup: true,
    typeDesc: "BoInit",
    key: "boGroup",
  },
  {
    paramName: "BusinessObject",
    category: "sourceInitGroup",
    isGroup: false,
    typeDesc: "BoInit",
    key: "boKeyInit",
    group: "boGroup",
  },
  {
    loc: "400, 0",
    text: "Table",
    isGroup: true,
    typeDesc: "TableInit",
    key: "tableGroup",
  },
  {
    paramName: "Table",
    category: "targetInitGroup",
    isGroup: false,
    typeDesc: "TableInit",
    key: "tableKeyInit",
    group: "tableGroup",
  },
];

export default NodeInit;
