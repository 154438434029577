export let jsTextListTileEdit = `

{_JSONCONFIG_SCREEN_}

let ACTION_TYPE = {
  ACTION_SELECT_ALL_MAIN: "select_all_main",
  ACTION_SELECT_MAIN: "select_main",
  ACTION_INSERT_MAIN: "insert_main",
  ACTION_UPDATE_MAIN: "update_main",
  ACTION_DELETE_MAIN: "delete_main",
  ACTION_SELECT_CHILDS: "select_childs_foreign",
  ACTION_INSERT_CHILDS: "insert_childs",
  ACTION_DELETE_CHILDS: "delete_childs",
  ACTION_UPDATE_CHILDS: "update_childs"
}

let FILE_HUB_URL_IMAGE = JSON_CONFIG_SCREEN.config.filehubUrlImage ? JSON_CONFIG_SCREEN.config.filehubUrlImage : '';
const SCREENS_EDIT_WITH_POSITION = JSON_CONFIG_SCREEN.screensEdit.filter(screen => screen.rownumber && screen.columnnumber);
let INPUT_CHILD_OBJECT;
let UPDATE_CHILD_OBJECT;
let DELETE_CHILD_OBJECT;
let ROW_DATA = 0;
let SELECTED_ROWS = new Set(); 

function clickSaveItemFn($page,event) {
  console.log("===clickSaveItemFn===")
  console.log("$page",$page)
  console.log("event",event)

}

function getPrimaryKeyColumns(jsonConfig) {
  const primaryKeyColumns = [];

   // let mainBoName = jsonConfig.bo[0].boName;

  let primaryKeyColumnBo = [];
  let pathColumn;
  let pathFrom;
  jsonConfig.table.forEach(table => {
    table.columns.forEach(column => {
      if (column.primaryKey) {
        pathColumn = table.tableName + "." + column.column

        jsonConfig.mapping.forEach(mapping => {
          if (mapping.pathTo == pathColumn && mapping.pathFrom.split(".").length == 2) {
            pathFrom = mapping.pathFrom
          }
        })

      }
    });
  });

  

  let pathFroms = pathFrom.split(".");
  if (pathFroms.length > 0)
    primaryKeyColumnBo.push(pathFroms[pathFroms.length - 1])

  return primaryKeyColumnBo;
}


function transformBoData(bo, input) {
   const output = {};
  const filteredBo = JSON_CONFIG_SCREEN.bo.filter(item => item.boName === bo);
  filteredBo.forEach(item => {
    const boName = item.boName;
    const fields = item.fields;
    const boObject = {};

    fields.forEach(field => {
      const fieldName = field.fieldName;
      if (input.hasOwnProperty(fieldName)) {
        boObject[fieldName] = input[fieldName];
      } else {
        if (field.type == "Object") {
          boObject[fieldName] = null;
        }else {
          boObject[fieldName] = "";
        }
      }
    });

    output[boName] = boObject;
  });
  return output;
}


async function fetchDataFromServer(action, inputObject) {
  return new Promise((resolve, reject) => {
    try {
      let flow = JSON_CONFIG_SCREEN.flow;
      let config = JSON_CONFIG_SCREEN.config;

      let flowNameV = flow.flowName;
      let appName = flow.appName;
      let uniqId = flow.flowId;
      let snapVersion = flow.snapVersion;
      let MFR_URL = config.microFlowUrl;

      let inputname = flowNameV + "_flow_input";

      let object = {};
      object[inputname] = inputObject;
      object[inputname].action = action;
      let body = {
        flowName: flowNameV,
        appName: appName,
        snapVersion: snapVersion,
        uniqueId: uniqId,
        object: object,
      };

      $.ajax({
        url: MFR_URL,
        type: "POST",
        data: JSON.stringify(body),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data) {
          console.log("data:<> ", data);
          let map = data["responseObjectsMap"];
          let out = flowNameV + "_flow_output";
          let responseObj = map[out];

          if (responseObj === null) {
            reject(new Error('responseObj is null'));
          } else {
            resolve(responseObj);
          }
        },
        catch: function (e) {
          reject(e);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

async function getSelectMain(refId, screenKey) {
  let key = getPrimaryKeyColumns(JSON_CONFIG_SCREEN);
  console.log("inputObject key:: ", refId);

  let input = { [key]: refId }
  console.log("input:: ", input);
  const inputObject = { parameter: transformBoData(screenKey, input) };
  console.log("inputObject:: ", inputObject);

  try {
    const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_MAIN, inputObject);
    console.log("response :", screenKey);
    console.log("response :", response[screenKey]);
    return response[screenKey];

  } catch (error) {
    console.error(error);
  }
}

function getBoFKNameMappingWithTableForeignKey(tableData, mapping) {
  let columnsRootFK = [];

  tableData.forEach(table => {
    table.columns.forEach(column => {
      if (column.foreignKey !== "") {
        columnsRootFK.push(table.tableName + "." + column.column);
      }
    });
  });

  //Mapping
  let boRoot = mapping.find((bo) => bo.pathTo === columnsRootFK[0])
  if (boRoot) {
    let boRootArr = boRoot.pathFrom.split(".");
    let boFKName = boRootArr.at(-1)
    return boFKName || ""
  } else {
    return columnsRootFK[0] || ""
  }
}

function getColumnNamesWithForeignKey(tableData) {
  let columnsWithForeignKey = [];

  tableData.forEach(table => {
    table.columns.forEach(column => {
      if (column.foreignKey !== "") {
        columnsWithForeignKey.push(column.column);
      }
    });
  });

  return columnsWithForeignKey;
}
function toCamelCase(str) {
  // แปลงข้อความที่มี _ และทำตัวอักษรที่ตามหลัง _ ให้เป็นตัวพิมพ์ใหญ่
  if(!str) return;
  let camelCaseStr = str.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });

  // ตรวจสอบว่าลงท้ายด้วย "id" หรือไม่ ถ้าใช่ แปลงเป็น "ID"
  if (camelCaseStr.endsWith("Id")) {
    camelCaseStr = camelCaseStr.slice(0, -2) + "ID";
  }

  return camelCaseStr;
}

 async function handleClickEdit(e) {

  let screen = null;
  for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
    if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
      screen = JSON_CONFIG_SCREEN.screens[a]
    }
  }
  const urlParams = new URLSearchParams(window.location.search);
  let refId = urlParams.get('refId');
  let dataValue = await getSelectMain(refId, screen.key);
  let updatedData = {};
  let data = dataValue;

  if (dataValue) {
    let keys = Object.keys(dataValue);
    keys.forEach((key) => {
      const inputImageElement = document.getElementById('image-file-edit-' + key);
      const inputElement = document.getElementById('input-id-edit-' + key);
      if (inputElement) {
        inputElement.value = dataValue[key];
        updatedData[key] = dataValue[key];

        inputElement.addEventListener('input', function () {
          updatedData[key] = inputElement.value;
        },false);
      }

      if (inputImageElement) {
        updatedData[key] = dataValue[key];

        // อัปเดตรายการไฟล์เมื่อมีการเลือกไฟล์ใหม่
        const onChange = ({ FILE_LIST: newFileList }) => {
          console.log("6666 newFileList>>>", newFileList);
          setFileList(newFileList);
        };

        // แสดงตัวอย่างของไฟล์ภาพเมื่อคลิกที่ปุ่มดูตัวอย่าง
        const onPreview = async (files) => {
          console.log("6666 files[0]>>>", files[0]);
          const file = files[0];
          if (!file) return;

          const reader = new FileReader();
          reader.onload = function (event) {
            const imgElement = document.createElement('img');
            imgElement.src = event.target.result;
            imgElement.style.maxWidth = '100%';
            imgElement.style.maxHeight = '100%';

            const upload = document.getElementById('image-preview-edit-' + key);
            if (upload) {
              // ลบภาพเก่าออกจาก DOM
              const imgContainer = upload.querySelector('img');
              if (imgContainer) {
                imgContainer.remove();
              }



              // แทรกภาพลงใน DOM
              upload.appendChild(imgElement);
            }
          };
          reader.readAsDataURL(file);

          // เช็คว่ามี Token ใน Session ไหม
          let existingToken = sessionStorage.getItem('$$_center_token_$$');
          if (!existingToken) {
            let centerUser = JSON_CONFIG_SCREEN.config.centerUser;
            let centerPassword = JSON_CONFIG_SCREEN.config.centerPassword;
            if (centerUser && centerPassword) {
              let user = {
                "username": centerUser,
                "password": centerPassword
              };
              await fetchToken(user);
              existingToken = sessionStorage.getItem('$$_center_token_$$');
            }
          }

          const formData = new FormData();
          formData.append('fullName', file.name);
          const lastDotIndex = file.name.lastIndexOf('.');
          formData.append('fileName', file.name.substring(0, lastDotIndex));
          formData.append('file', file);

          let imageID = await uploadFileWithToken(formData, existingToken);
          updatedData[key] = imageID;
          console.log("imageID", imageID);
        };

        inputImageElement.addEventListener('change', (event) => {
          onChange({ FILE_LIST: event.target.files });
          onPreview(event.target.files);
          const uploadLabel = document.getElementById('image-preview-label-edit-' + key);
          if (uploadLabel) {
            console.log("6666 uploadLabel>>>", uploadLabel);
            uploadLabel.hidden = true;
          }
        }, false);



        const previewContainer = document.getElementById('image-preview-edit-' + key);
        if (previewContainer) {
          const imgContainer = previewContainer.querySelector('img');
          if (imgContainer) {
            imgContainer.remove(); // ลบภาพที่แสดงตัวอย่างออกจาก DOM
          }

          // ดึง URL ของภาพจาก dataValue เพื่อแสดงภาพที่มีอยู่เดิม
          const uploadLabel = document.getElementById('image-preview-label-edit-' + key);
          const imageUrl = dataValue[key];
          if (imageUrl) {
            const imgElement = document.createElement('img');
            imgElement.src = FILE_HUB_URL_IMAGE+imageUrl;
            imgElement.style.maxWidth = '100%';
            imgElement.style.maxHeight = '100%';
            previewContainer.appendChild(imgElement);
            uploadLabel.hidden = true;
          }else{
            uploadLabel.hidden = false;
          }
          
        }
      }
      
    });
  }

  updateListTileScreens(data, updatedData, screen);

  const backButton2 = document.getElementById('backButton-containerWrapper-' + screen.key);
  backButton2.addEventListener('click', function () { 
  if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {  
  const editScreen = SCREENS_EDIT_WITH_POSITION.find(editScreen => {
            return editScreen.configType === 'TABLE';
          });
          console.log("editScreen",editScreen);
          clearTableData(screen, editScreen, 'edit')

  }
    //have to click back
    {{CLICK_BACK}}

   },false);

}

function getNewDataArrayWithFk(arrayDataTable) {
  let boNameFK = getBoFKNameMappingWithTableForeignKey(JSON_CONFIG_SCREEN.table, JSON_CONFIG_SCREEN.mapping)
  const urlParams = new URLSearchParams(window.location.search);
  let refId = urlParams.get('refId');
  let newArrayDataTable = arrayDataTable.map((item) => {
    return {
      ...item,
      [boNameFK]: refId
    }
  })

  return newArrayDataTable;
}

async function renderTableOneToMany(mainScreen, screen, data, label) {
  console.log("renderTableOneToMany", data);
  const dataTable = document.getElementById('dataTable-' + label + '-' + screen.configType + '-' + screen.key + '-' + mainScreen.key);
  console.log("dataTable", dataTable);
  const tbody = dataTable.querySelector('tbody');
  console.log("tbody", tbody);

 let key = getColumnNamesWithForeignKey(JSON_CONFIG_SCREEN.table)
 let boNameFK = getBoFKNameMappingWithTableForeignKey(JSON_CONFIG_SCREEN.table, JSON_CONFIG_SCREEN.mapping)
    // const toCamel = toCamelCase(key[0]);
    const fkName = key[0];
    const inputObject = {
      parameter: {
        [mainScreen.key]: {
          [screen.key]: {
            [boNameFK]: data.id
          }
        }
        // transformChildsData(mainScreen.key, data.id, screen.key)
      }
    };

    // const saveButtonTable = document.getElementById('save-cell-' + label + '-data-button');
    // const deleteButtonTable = document.getElementById('delete-cell-' + label + '-data-button');
    // const buttonAddTable = document.getElementById('content-' + label + '-table-add-button-' + screen.key);

    const saveButtonTable = $("#save-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button")[0];
    const deleteButtonTable = $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button")[0];
    const buttonAddTable = $("#add-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button")[0];

    const newButtonAddTable = buttonAddTable.cloneNode(true);
    buttonAddTable.parentNode.replaceChild(newButtonAddTable, buttonAddTable);

    // const checkboxAll = document.getElementById('checkbox-all-childs');

    let arrayDataTable = [];
    const arrayUpdateDataTable = [];
   // เปลี่ยนจาก Array เป็น Set เพื่อเก็บข้อมูลที่ไม่ซ้ำกัน
    let clickCount = 0;


    // tbody.addEventListener('click', (event) => {
    //   if (event.target.type === 'checkbox' && event.target.classList.contains('row-checkbox')) {
    //     const checkbox = event.target;
    //     const rowData = JSON.parse(checkbox.dataset.rowData);
    //     console.log('Checkbox clicked, Row data:', rowData);

    //     if (checkbox.checked) {
    //       SELECTED_ROWS.add(JSON.stringify(rowData)); // เพิ่มข้อมูลลงใน Set โดยแปลงเป็น JSON string ก่อน
    //     } else {
    //       SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก Set
    //     }

    //     if (SELECTED_ROWS.size > 0) {
    //       deleteButtonTable.disabled = false;
    //     } else {
    //       deleteButtonTable.disabled = true;
    //     }
    //     console.log('Selected Rows:', Array.from(SELECTED_ROWS).map(JSON.parse)); // แปลง Set เป็น Array เพื่อให้ง่ายต่อการดูข้อมูล
    //   }
    // },false);

    // checkboxAll.addEventListener('change', () => {
    //   const checkboxes = tbody.querySelectorAll('.row-checkbox');

    //   checkboxes.forEach(checkbox => {
    //     checkbox.checked = checkboxAll.checked;
    //     const rowData = JSON.parse(checkbox.dataset.rowData);

    //     if (checkbox.checked) {
    //       SELECTED_ROWS.add(JSON.stringify(rowData));// เพิ่มข้อมูลลงใน Set
    //     } else {
    //       SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก Set
    //     }
    //   });

    //   if (SELECTED_ROWS.size > 0) {
    //     deleteButtonTable.disabled = false;
    //   } else {
    //     deleteButtonTable.disabled = true;
    //   }
    //   console.log('Selected Rows:', Array.from(SELECTED_ROWS).map(JSON.parse)); // แปลง Set เป็น Array เพื่อให้ง่ายต่อการดูข้อมูล
    // },false);

    deleteButtonTable.addEventListener('click', function () {
      DELETE_CHILD_OBJECT = {
        parameter: {
          [mainScreen.key]: {
            [screen.key + '_list']: Array.from(SELECTED_ROWS).map(JSON.parse)
          }
        }
      }

      const checkboxesToDelete = tbody.querySelectorAll('.row-checkbox:checked');
      checkboxesToDelete.forEach(checkbox => {
        const parentRow = checkbox.closest('tr');
        const dataIndex = parentRow.dataset.index;

        // ลบ row จาก arrayDataTable
        if (dataIndex > -1) {
          arrayDataTable.splice(dataIndex, 1);
        }

        parentRow.remove();
      });

      console.log("deleteChildObject", DELETE_CHILD_OBJECT);
      // deleteButtonTable.disabled = true;
      $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
      $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").prop('disabled', true);
    }, false)

     $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
     $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").prop('disabled', true);


    newButtonAddTable.addEventListener('click', function () {
      if (arrayDataTable.length === 0) {
        clickCount = 0;
      }
      clickCount++;
      const dataTable = collectTableData(mainScreen, screen, label);
      arrayDataTable.push(dataTable);
      console.log("dataTable", dataTable);
      arrayDataTable = getNewDataArrayWithFk(arrayDataTable);
      console.log("arrayDataTable", arrayDataTable);

      const row = document.createElement('tr');
      row.id = 'new-row-id-index-' + clickCount;
      row.dataset.index = clickCount - 1; // เก็บค่าของข้อมูลใน dataset

      const deleteNewRowCell = document.createElement('td');
      const deleteNewRowDiv = document.createElement('div');
      deleteNewRowDiv.innerHTML = '<button id="button-index-' + clickCount + '"type="button" class="delete-middle ant-table-row-expand-icon ant-table-row-expand-icon-expanded"></button>';
      const deleteButton = deleteNewRowDiv.firstChild;

      deleteNewRowCell.appendChild(deleteButton);
      row.appendChild(deleteNewRowCell);

      Object.values(dataTable).forEach(value => {
        const cell = document.createElement('td');
        cell.textContent = value;
        row.appendChild(cell);
      });
      tbody.appendChild(row);

      deleteButton.addEventListener('click', function (event) {
        const target = event.target;
        const parentRow = target.closest('tr');
        console.log("Parent row ID:", parentRow.id);

        // ดึงค่าจาก dataset
        const dataIndex = parentRow.dataset.index;
        console.log("Data index:", dataIndex);

        // ลบ row จาก arrayDataTable
        if (dataIndex > -1) {
          arrayDataTable.splice(dataIndex, 1);
        }
        // ลบแถวจาก DOM
        parentRow.remove();

        // อัปเดต dataset ของแถวที่เหลือ
        const rows = tbody.querySelectorAll('tr[id^="row-child-{{SCREEN_KEY}}-"]');
        rows.forEach((row, index) => {
          row.dataset.index = index;
        });


        console.log("Updated arrayDataTable:", arrayDataTable);
      }, false);

    let boNameFK = getBoFKNameMappingWithTableForeignKey(JSON_CONFIG_SCREEN.table, JSON_CONFIG_SCREEN.mapping)
    const urlParams = new URLSearchParams(window.location.search);
    let refId = urlParams.get('refId');

    let newArrayDataTable = arrayDataTable.map((item) => {
      return {
        ...item,
        [boNameFK]: refId //NEW LOGIC
      }
    })

      INPUT_CHILD_OBJECT = {
        parameter: {
          [mainScreen.key]: {
            [screen.key + '_list']: newArrayDataTable
          }
        }
      };

    }, false);

    saveButtonTable.addEventListener('click', function () {
      console.log("saveButtonTable click");
      const dataTable = collectTableData(mainScreen, screen, label);
      arrayUpdateDataTable.push(dataTable);
      console.log("arrayUpdateDataTable", arrayUpdateDataTable);

      // อัปเดตแถวในตาราง
      const rowIdToUpdate = 'row-child-{{SCREEN_KEY}}-' + ROW_DATA;
      console.log("rowIdToUpdate", rowIdToUpdate);
      const row = tbody.querySelector('#' + rowIdToUpdate);
      console.log("row", row);
      if (row) {
        Object.values(dataTable).forEach((value, idx) => {
          row.cells[idx + 1].textContent = value;
        });
      }

      UPDATE_CHILD_OBJECT = {
        parameter: {
          [mainScreen.key]: {
            [screen.key + '_list']: arrayUpdateDataTable
          }
        }
      };

    }, false);


    console.log("inputChildObject", INPUT_CHILD_OBJECT);
    console.log("updateChildObject", UPDATE_CHILD_OBJECT);

    // const clearButton = document.getElementById('clear-cell-' + label + '-data-button');
    // clearButton.addEventListener('click', function () {
    //   deleteButtonTable.disabled = true;
    //   saveButtonTable.style.display = "none";
    //   newButtonAddTable.style.display = "block";

    //   const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
    //   console.log("newRowsContainer ", newRowsContainer);
    //   const inputElements = newRowsContainer.querySelectorAll('input');
    //   inputElements.forEach(input => {
    //     input.value = '';
    //   });

    //   // ลบ checkbox ที่ checked ออก
    //   const checkboxes = tbody.querySelectorAll('.row-checkbox:checked');
    //   checkboxes.forEach(checkbox => {
    //     checkbox.checked = false; // ยกเลิกการเลือก checkbox
    //     const rowData = JSON.parse(checkbox.dataset.rowData);
    //     SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก SELECTED_ROWS
    //   });

    //   checkboxAll.checked = false;

    //   // เคลียร์ SELECTED_ROWS ทั้งหมด
    //   SELECTED_ROWS.clear();
    // }, false);

    // try {
    //   const response = await fetchDataFromServer(ACTION_TYPE.ACTION_SELECT_CHILDS, inputObject);
    //   console.log("response :", response[mainScreen.key][screen.key + "_list"]);
    //   const dataChilds = response[mainScreen.key][screen.key + "_list"];
    //   renderRowsTable(tbody, dataChilds, screen, newButtonAddTable, saveButtonTable);
    // } catch (error) {
    //   console.error(error);
    // }

}

function handleClearBtn($page,event) {
      // deleteButtonTable.disabled = true;
      // saveButtonTable.style.display = "none";
      // newButtonAddTable.style.display = "block";
      
      $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
      $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").prop('disabled', true);
      $("#save-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
      $("#add-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").show();

      const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
      console.log("newRowsContainer ", newRowsContainer);
      const inputElements = newRowsContainer.querySelectorAll('input');
      inputElements.forEach(input => {
        input.value = '';
      });

      // ลบ checkbox ที่ checked ออก
      let tbody= document.getElementById("dataTable-edit-TABLE-{{SCREEN_CHILD_KEY}}-{{SCREEN_KEY}}")
      const checkboxes = tbody.querySelectorAll('.row-checkbox:checked');
      checkboxes.forEach(checkbox => {
        checkbox.checked = false; // ยกเลิกการเลือก checkbox
        // const rowData = $page.item;
        // SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก SELECTED_ROWS
      });

      // checkboxAll.checked = false;
      $("#checkbox-all-childs").prop("checked",false)

      // เคลียร์ SELECTED_ROWS ทั้งหมด
      SELECTED_ROWS.clear();

}

function handleChangeCheckboxAll($page,event) {
      let tbody= document.getElementById("dataTable-edit-TABLE-{{SCREEN_CHILD_KEY}}-{{SCREEN_KEY}}")
      

      for (let a = 0; a < tbody.rows.length; a++) {
        let row = tbody.rows[a];
        let isChecked = $("#checkbox-all-childs").prop("checked");
        const checkboxes = row.querySelectorAll('.row-checkbox');
        checkboxes.forEach(checkbox => {
          checkbox.checked = isChecked;
        });

        let item = $(row).attr("item");
        if (item != null) {
            let rowData = JSON.parse(item);
            if (isChecked) {
              SELECTED_ROWS.add(JSON.stringify(rowData));// เพิ่มข้อมูลลงใน Set
            } else {
              SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก Set
            }
        }
      }
      

      if (SELECTED_ROWS.size > 0) {
        $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").show();
        $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").prop("disabled",false);
      } else {
        $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
      }
      console.log('Selected Rows:', Array.from(SELECTED_ROWS).map(JSON.parse)); // แปลง Set เป็น Array เพื่อให้ง่ายต่อการดูข้อมูล
}

function handleClickCheckBoxRow($page,event) {
      
      if (event.target.type === 'checkbox' && event.target.classList.contains('row-checkbox')) {
        const checkbox = event.target;
        const rowData = $page.item;
        console.log('Checkbox clicked, Row data:', rowData);

        if (checkbox.checked) {
          SELECTED_ROWS.add(JSON.stringify(rowData)); // เพิ่มข้อมูลลงใน Set โดยแปลงเป็น JSON string ก่อน
        } else {
          SELECTED_ROWS.delete(JSON.stringify(rowData)); // ลบข้อมูลออกจาก Set
        }

        if (SELECTED_ROWS.size > 0) {
          $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").show();
          $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").prop("disabled",false);
        } else {
          $("#delete-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
        }
        console.log('Selected Rows:', Array.from(SELECTED_ROWS).map(JSON.parse)); // แปลง Set เป็น Array เพื่อให้ง่ายต่อการดูข้อมูล
      }

}
function handleClickRowEdit($page,event) {
      const rowData = $page.item;
      if (event.target.type === 'checkbox' && event.target.classList.contains('row-checkbox')) {
        return; 
      }
      console.log('Row data:', rowData);
      $("#add-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").hide();
      $("#save-cell-{{PAGE_NAME}}-{{SCREEN_KEY}}-data-button").show();


      Object.entries(rowData).forEach(([key, value]) => {
        const inputElement = document.getElementById(key + '-childs-{{SCREEN_KEY}}');

        if (inputElement) {
          inputElement.value = value;
        }
      });

      ROW_DATA =  $page.$index;
}

function renderRowsTable(tbody, dataChilds, screen, buttonAdd, buttonSave) {
  console.log("dataChilds", dataChilds);
  console.log("buttonAdd", buttonAdd);
  dataChilds.forEach((child, index) => {
    const row = document.createElement('tr');
    row.id = 'row-id-index-' + index;

    const checkboxCell = document.createElement('td');
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.className = 'row-checkbox';
    checkboxCell.appendChild(checkbox);
    row.appendChild(checkboxCell);

    checkbox.dataset.rowData = JSON.stringify(child);

    // เก็บข้อมูลแถว
    const rowData = {};

    Object.entries(child).forEach(([key, value]) => {
      const cell = document.createElement('td');
      cell.textContent = value;
      row.appendChild(cell);
      rowData[key] = value; // เก็บข้อมูลใน rowData
    });

    row.addEventListener('click', (event) => {
      if (event.target.type === 'checkbox' && event.target.classList.contains('row-checkbox')) {
        return; 
      }
      console.log('Row data:', rowData);
      buttonAdd.style.display = "none";
      buttonSave.style.display = "block";


      Object.entries(rowData).forEach(([key, value]) => {
        const inputElement = document.getElementById(key + '-' + screen.key);

        if (inputElement) {
          inputElement.value = value;
        }
      });

      ROW_DATA = index;
    }, false);

    tbody.appendChild(row);
  });
}

function collectTableData(mainScreen, screen, label) {
  const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
  console.log("newRowsContainer ", newRowsContainer);

  const inputs = newRowsContainer.querySelectorAll('input');
  const rowData = {};

  inputs.forEach(input => {
    console.log("row", input);
    rowData[input.placeholder] = input.value;
  });

  return rowData;
}

function clearTableData(mainScreen, screen, label) {
  const newRowsContainer = document.getElementById('new-row-container-{{PAGE_NAME}}-{{SCREEN_KEY}}');
  if(newRowsContainer==null) return;
  console.log("newRowsContainer ", newRowsContainer);
  const inputsNewRows = newRowsContainer.querySelectorAll('input');

  inputsNewRows.forEach(function (input) {
    input.value = '';
  });

  var tbody = document.querySelectorAll('#dataTable-' + label + '-' + screen.configType + '-' + screen.key + '-' + mainScreen.key + ' tbody');
  // ลบทุกแถวใน tbody 
  while (tbody[0].rows.length > 0) {
    tbody[0].deleteRow(0);
  }

  // เคลียร์ค่าของ input fields ในแถวที่เหลือ
  const inputs = tbody[0].querySelectorAll('tr td input');
  console.log("tbody:: inputs", inputs);
  inputs.forEach(function (input) {
    input.value = '';
  });
}

function updateListTileScreens(data, updatedData, mainScreen) {

  SCREENS_EDIT_WITH_POSITION.forEach(screen => {
    if (screen.configType === 'BO') {
      renderBOScreens(screen, data);
    } else if (screen.configType === 'BUTTON') {
      renderButtonScreens(screen, updatedData, 'edit', mainScreen);
    } else if (screen.configType === 'BREADCRUMB') {
      //BREADCRUMB
    } else if (screen.configType === 'TAB') {
      //tab
    } else if (screen.configType === 'TABLE') {
      if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
        renderTableOneToMany(mainScreen, screen, data, 'edit');
      }
    }
  });
}


function renderButtonScreens(screen, data, label, mainScreen) {
  const buttonId = "btn-"+mainScreen.key+"-{{PAGE_NAME}}";
  const button = document.getElementById(buttonId);

  if (button) {
   
    button._handleClick = async function () {
      const inputObject = { parameter: transformBoData(mainScreen.key, data) };
      try {
        let response;
        response = await fetchDataFromServer(ACTION_TYPE.ACTION_UPDATE_MAIN, inputObject);
        if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
            if (INPUT_CHILD_OBJECT && Object.values(INPUT_CHILD_OBJECT.parameter[mainScreen.key]).some(list => list.length > 0)) {
              const responseChilds = await fetchDataFromServer(ACTION_TYPE.ACTION_INSERT_CHILDS, INPUT_CHILD_OBJECT);
            }
            if (UPDATE_CHILD_OBJECT && Object.values(UPDATE_CHILD_OBJECT.parameter[mainScreen.key]).some(list => list.length > 0)) {
              const responseChilds = await fetchDataFromServer(ACTION_TYPE.ACTION_UPDATE_CHILDS, UPDATE_CHILD_OBJECT);
            }
            if (DELETE_CHILD_OBJECT && Object.values(DELETE_CHILD_OBJECT.parameter[mainScreen.key]).some(list => list.length > 0)) {
              const responseChilds = await fetchDataFromServer(ACTION_TYPE.ACTION_DELETE_CHILDS, DELETE_CHILD_OBJECT);
            }
          }

        if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
          const editScreen = SCREENS_EDIT_WITH_POSITION.find(editScreen => {
          return editScreen.configType === 'TABLE';
        });
          clearTableData(mainScreen, editScreen, label)
        }
          
        //have to click back
        {{CLICK_BACK}}

      } catch (error) {
        console.error(error);
      }
    };
    button.addEventListener('click', button._handleClick,false);
  }
}

function renderBOScreens(screen, data) {
  let toggleButton = document.querySelector('#toggleButton-id-' + screen.key);
  let input = document.getElementById('input-id-' + screen.key);
  let isPasswordVisible = false;

  if (toggleButton) {
    toggleButton.addEventListener('click', () => {
      isPasswordVisible = !isPasswordVisible;
      input.type = isPasswordVisible ? 'text' : 'password';
      toggleButton.innerHTML = isPasswordVisible ? '<i class="fas fa-eye"></i>' : '<i class="fas fa-eye-slash"></i>';
    },false);
  }
}

let FILE_LIST = [];
function setFileList(newFileList) {
  FILE_LIST = newFileList;
  console.log('Updated FILE_LIST:', FILE_LIST);
};

function initLoad($page,event) {
  handleClickEdit();
}

function clickSaveItemFn($page,event) {
  console.log("===clickSaveItemFn===")
  console.log("$page",$page)
  console.log("event",event)
}

async function fetchToken(credentials) {
  try {
    const response = await fetch(JSON_CONFIG_SCREEN.config.centerDomain +'/center/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    });
    const data = await response.json();
    const token = data.token;
    sessionStorage.setItem('$$_center_token_$$', token); // เก็บ Token ใน Session

    console.log("data>>> ", token);
    return token;
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
}

async function uploadFileWithToken(formData, token) {
  try {
    const response = await fetch(JSON_CONFIG_SCREEN.config.centerDomain +'/FileService/uploadFile', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error('Failed to upload file.');
    }
    const responseData = await response.json();

    console.log('File uploaded successfully!', responseData);
    let imageId = responseData.data.id;
    return imageId;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}


function beforeLoadChilds($page,event) {
  console.log("===beforeLoadChilds===")
  let mainScreen = null,editScreen=null;
  for (let a = 0; a < JSON_CONFIG_SCREEN.screens.length; a++) {
    if (JSON_CONFIG_SCREEN.screens[a].configType.indexOf("LIST") != -1) {
      mainScreen = JSON_CONFIG_SCREEN.screens[a]
    }
  }

  SCREENS_EDIT_WITH_POSITION.forEach(screen => {
    if (screen.configType === 'TABLE') {
      if (JSON_CONFIG_SCREEN.refTemplate === "OneToMany") {
       editScreen  = screen;
      }
    }
  });

  //let key = getColumnNamesWithForeignKey(JSON_CONFIG_SCREEN.table)
  //const toCamel = toCamelCase(key[0]);

  let boNameFK = getBoFKNameMappingWithTableForeignKey(JSON_CONFIG_SCREEN.table, JSON_CONFIG_SCREEN.mapping)

  const urlParams = new URLSearchParams(window.location.search);
  let refId = urlParams.get('refId');
  $page.State[mainScreen.key][editScreen.key][boNameFK]=refId;
  $page.State.limit=100;
  $page.State.action = "select_childs_foreign";
  $page.update();
}

function clickSearchChildFn($page,event) {
    //$page.State.search="";
    console.log("===clickSearchChildFn===")
    beforeLoadChilds($page,event);
    $("#{{PAGE_CONTENT_ID}}").trigger("load");
}

`;