
import React from "react";

const AngleLeftIcon = (props) => {
    const { className, style, width, height, fill } = props;
    return (
        <svg
            width={width || "16"}
            height={height || "16"}
            className={className}
            style={style}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06788 8.47256C7.00539 8.41058 6.95579 8.33685 6.92195 8.25561C6.8881 8.17437 6.87068 8.08723 6.87068 7.99923C6.87068 7.91122 6.8881 7.82408 6.92195 7.74284C6.95579 7.6616 7.00539 7.58787 7.06788 7.52589L10.1279 4.47256C10.1904 4.41058 10.24 4.33685 10.2738 4.25561C10.3077 4.17437 10.3251 4.08723 10.3251 3.99923C10.3251 3.91122 10.3077 3.82408 10.2738 3.74284C10.24 3.6616 10.1904 3.58787 10.1279 3.52589C10.003 3.40173 9.834 3.33203 9.65788 3.33203C9.48175 3.33203 9.31278 3.40173 9.18788 3.52589L6.12788 6.58589C5.75334 6.96089 5.54297 7.46922 5.54297 7.99923C5.54297 8.52923 5.75334 9.03756 6.12788 9.41256L9.18788 12.4726C9.31205 12.5957 9.47965 12.6652 9.65454 12.6659C9.74228 12.6664 9.82926 12.6496 9.91048 12.6164C9.99171 12.5832 10.0656 12.5343 10.1279 12.4726C10.1904 12.4106 10.24 12.3369 10.2738 12.2556C10.3077 12.1744 10.3251 12.0872 10.3251 11.9992C10.3251 11.9112 10.3077 11.8241 10.2738 11.7428C10.24 11.6616 10.1904 11.5879 10.1279 11.5259L7.06788 8.47256Z" fill={fill||"#ABABAB"} />
        </svg>
    );
};


export default AngleLeftIcon;