
import React from "react";

const AppComponentIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg
            width={width || "13"}
            height={height || "12"}
            className={className}
            style={style}
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2817_18584)">
                <path d="M4 0H2.5C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2L0.5 3.5C0.5 4.03043 0.710714 4.53914 1.08579 4.91421C1.46086 5.28929 1.96957 5.5 2.5 5.5H4C4.53043 5.5 5.03914 5.28929 5.41421 4.91421C5.78929 4.53914 6 4.03043 6 3.5V2C6 1.46957 5.78929 0.960859 5.41421 0.585786C5.03914 0.210714 4.53043 0 4 0V0ZM5 3.5C5 3.76522 4.89464 4.01957 4.70711 4.20711C4.51957 4.39464 4.26522 4.5 4 4.5H2.5C2.23478 4.5 1.98043 4.39464 1.79289 4.20711C1.60536 4.01957 1.5 3.76522 1.5 3.5V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H4C4.26522 1 4.51957 1.10536 4.70711 1.29289C4.89464 1.48043 5 1.73478 5 2V3.5Z" fill={fill || "#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M10.5 0H9C8.46957 0 7.96086 0.210714 7.58579 0.585787C7.21071 0.96086 7 1.46957 7 2V3.5C7 4.03044 7.21071 4.53914 7.58579 4.91422C7.96086 5.28929 8.46957 5.5 9 5.5H10.5C11.0304 5.5 11.5391 5.28929 11.9142 4.91422C12.2893 4.53914 12.5 4.03044 12.5 3.5V2C12.5 1.46957 12.2893 0.96086 11.9142 0.585787C11.5391 0.210714 11.0304 0 10.5 0V0ZM11.5 3.5C11.5 3.76522 11.3946 4.01957 11.2071 4.20711C11.0196 4.39465 10.7652 4.5 10.5 4.5H9C8.73478 4.5 8.48043 4.39465 8.29289 4.20711C8.10536 4.01957 8 3.76522 8 3.5V2C8 1.73478 8.10536 1.48043 8.29289 1.29289C8.48043 1.10536 8.73478 1 9 1H10.5C10.7652 1 11.0196 1.10536 11.2071 1.29289C11.3946 1.48043 11.5 1.73478 11.5 2V3.5Z" fill={fill || "#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M4 6.5H2.5C1.96957 6.5 1.46086 6.71071 1.08579 7.08579C0.710714 7.46086 0.5 7.96957 0.5 8.5L0.5 10C0.5 10.5304 0.710714 11.0391 1.08579 11.4142C1.46086 11.7893 1.96957 12 2.5 12H4C4.53043 12 5.03914 11.7893 5.41421 11.4142C5.78929 11.0391 6 10.5304 6 10V8.5C6 7.96957 5.78929 7.46086 5.41421 7.08579C5.03914 6.71071 4.53043 6.5 4 6.5ZM5 10C5 10.2652 4.89464 10.5196 4.70711 10.7071C4.51957 10.8946 4.26522 11 4 11H2.5C2.23478 11 1.98043 10.8946 1.79289 10.7071C1.60536 10.5196 1.5 10.2652 1.5 10V8.5C1.5 8.23479 1.60536 7.98043 1.79289 7.7929C1.98043 7.60536 2.23478 7.5 2.5 7.5H4C4.26522 7.5 4.51957 7.60536 4.70711 7.7929C4.89464 7.98043 5 8.23479 5 8.5V10Z" fill={fill || "#272727"} fillOpacity={opacity ? opacity : ""} />
                <path d="M10.5 6.5H9C8.46957 6.5 7.96086 6.71071 7.58579 7.08579C7.21071 7.46086 7 7.96957 7 8.5V10C7 10.5304 7.21071 11.0391 7.58579 11.4142C7.96086 11.7893 8.46957 12 9 12H10.5C11.0304 12 11.5391 11.7893 11.9142 11.4142C12.2893 11.0391 12.5 10.5304 12.5 10V8.5C12.5 7.96957 12.2893 7.46086 11.9142 7.08579C11.5391 6.71071 11.0304 6.5 10.5 6.5ZM11.5 10C11.5 10.2652 11.3946 10.5196 11.2071 10.7071C11.0196 10.8946 10.7652 11 10.5 11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10V8.5C8 8.23479 8.10536 7.98043 8.29289 7.7929C8.48043 7.60536 8.73478 7.5 9 7.5H10.5C10.7652 7.5 11.0196 7.60536 11.2071 7.7929C11.3946 7.98043 11.5 8.23479 11.5 8.5V10Z" fill={fill || "#272727"} fillOpacity={opacity ? opacity : ""} />
            </g>
            <defs>
                <clipPath id="clip0_2817_18584">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};


export default AppComponentIcon;
