import React, { useState, useEffect } from "react";
import styles from "../app-component/AppComponent.module.scss";
import { Button, Card, Radio, Checkbox, notification } from "antd";
import {
  SettingOutlined,
  EditOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import ConfigurationModal from "./modal/ConfigurationModal";
import DashboardZeroContent from "../component/DashboardZeroContent";
import CreateEntitySuccessNotify from "../app-component/notification/CreateEntitySuccessNotify";

import AppNone from "../../assets/png/appcomponent-none.png";
import AppLeft from "../../assets/png/appcomponent-left.png";
import AppCenter from "../../assets/png/appcomponent-center.png";
import AppRight from "../../assets/png/appcomponent-right.png";

import AppVertical from "../../assets/png/appcomponent-vertical.png";
import AppHorizontal from "../../assets/png/appcomponent-horizontal.png";

import AppCpn2Column from "../../assets/png/appcomponent-2-column.png";
import AppCpn3Column from "../../assets/png/appcomponent-3-column.png";
import AppCpn4Column from "../../assets/png/appcomponent-4-column.png";

import error from "../../assets/png/error.png";

import ModalImportExcel from "./modal/ModalImportExcel"

import {
  AppComponentRequest,
  MasterAppComponentDetail,
  ConfigDetail,
  AppComponentFooterHeaderRequest,
  HeaderConfig,
  MenuConfig,
  FooterConfig,
  MenuItem,
} from "./models/AppComponentModel";
import {
  callApiMethodGet,
  callApiMethodPagePatch,
  callApiMethodPost,
  callApiMethodUploadLibsPost,
  getAspId,
} from "src/services/util.service";
import {
  GET_MASTER_APP_COMPONENT_ENDPOINT,
  GET_APP_COMPONENT_BY_ASP_ID_ENDPOINT,
  CREATE_APP_COMPONENT_ENDPOINT,
  UPDATE_APP_COMPONENT_ENDPOINT,
  GEN_PAGE_LAYOUT_ENDPOINT,
  UPLOAD_FILE_LIBS_ENDPOINT,
  GET_HEADER_PAGE_NODES_BY_PAGEID_ENDPOINT,
  GEN_PAGE_ACTION_ENDPOINT
} from "src/utils/endpoint/createbo.endpoint";
import { generatePageFile } from "src/services/page-service";
import { getFormDataWithFilesForGenPageUpload } from "src/utils/story-genpage/StoryGeneratePageUtility";
import {
  STATUS_ACCEPTED,
  STATUS_CREATED,
  STATUS_OK,
} from "src/constants/ResponseStatus";
import MessageNofify from "src/components/zero/zero-story/notification/MessageNotify";
import { generatePageFileFooter } from "src/services/page-service-footer";
import { generatePageFileHeader } from "src/services/page-service-header";
import { generatePageFileMenu } from "src/services/page-service-menu";
import {
  ALIGN_ITEM,
  DROPDOWN,
  FACEBOOK,
  FOOTER,
  HEADER,
  HEADER_TEMPLATE,
  INSTAGRAM,
  LOGO,
  MENU,
  MENU_TEMPLATE,
  NOTIFICATION,
  SEARCH,
  STORY,
  URL,
  USER_PROFILE,
  WEB,
  WITH_ICON,
  YOUTUBE,
} from "./constants/generate-layout.constant";
import useFetch from "src/hooks/useFetch";
import {
  GenPageActionResponse,
  GetPageNodesResponse,
} from "src/types/response.type";
import { GeneratePageActionRequest } from "src/types/request.type";
import { v4 as uuidv4 } from "uuid";

const { Meta } = Card;
const CheckboxGroup = Checkbox.Group;

const ComponentType = {
  Header: "Header",
  Menu: "Menu",
  Footer: "Footer",
};

function AppComponent() {
  const GET_PAGE_NODES_FK = "getPageNodesFK";
  const GEN_PAGE_ACTION_FK = "genPageActionFK";

  const [isOpenConfigurationModal, setIsOpenConfigurationModal] =
    useState<boolean>(false);
  const [isNotificationSuccess, setIsNotificationSuccess] =
    useState<boolean>(false);
  const [isNameConfigurationModal, setIsNameConfigurationModal] =
    useState<string>("");
  const [listHeaderMain, setListHeaderMain] = useState<
    MasterAppComponentDetail[]
  >([]);
  const [listHeaderOption, setListHeaderOption] = useState<
    MasterAppComponentDetail[]
  >([]);
  const [listMenuMain, setListMenuMain] = useState<MasterAppComponentDetail[]>(
    []
  );
  const [listMenuOption, setListMenuOption] = useState<
    MasterAppComponentDetail[]
  >([]);
  const [listFooterMain, setListFooterMain] = useState<
    MasterAppComponentDetail[]
  >([]);
  const [listFooterOption, setListFooterOption] = useState<
    MasterAppComponentDetail[]
  >([]);

  // State : Header
  let [appComponentIDHeader, setAppComponentIDHeader] = useState<string>("");
  let [inputMainHeader, setInputMainHeader] = useState<string>("");
  let [mAppComponentIDHeader, setMAppComponentIDHeader] = useState<any>();
  let [appComMainTypeHeader, setAppComMainTypeHeader] = useState<any>();
  let [checkedListOptionHeader, setCheckedListOptionHeader] = useState<
    string[]
  >([]);
  const [configHeader, setConfigHeader] = useState<ConfigDetail>({
    profile_file_id: "",
    logo_file_id: "",
    noti_file_id: "",
    list_menu: [],
  });
  let [pageId, setPageId] = useState<string>("");

  // State : Menu
  let [menuAppComponentID, setMenuAppComponentID] = useState<string>("");
  let [mAppComponentIDMenu, setMAppComponentIDMenu] = useState<any>();
  let [appComMainTypeMenu, setAppComMainTypeMenu] = useState<any>();
  let [inputMainMenu, setInputMainMenu] = useState<string>("");
  const [checkedListOptionMenu, setCheckedListOptionMenu] = useState<string[]>(
    []
  );
  const [configMenu, setConfigMenu] = useState<ConfigDetail>({
    profile_file_id: "",
    logo_file_id: "",
    noti_file_id: "",
    list_menu: [],
  });
  let [functionNameToGen, setFunctionNameToGen] = useState<string>("")

  // State : Footer
  let [appComponentIDFooter, setAppComponentIDFooter] = useState<string>("");
  let [mAppComponentIDFooter, setMAppComponentIDFooter] = useState<any>();
  let [appComMainTypeFooter, setAppComMainTypeFooter] = useState<any>();
  let [inputFooterMain, setInputFooterMain] = useState<string>("");
  const [checkedListOptionFooter, setCheckedListOptionFooter] = useState<
    string[]
  >([]);
  const [configFooter, setConfigFooter] = useState<ConfigDetail>({
    profile_file_id: "",
    logo_file_id: "",
    noti_file_id: "",
    list_menu: [],
  });

  //NOTITY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  //BUTTON LOADING STATE
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [pageType, setPageType] = useState<string>("");

  //MODAL STATE 
  let [isOpenModalImportExcel , setIsOpenModalImportExcel] = useState<boolean>(false)
  let [componentType , setComponentType] = useState<string>("")

  // useEffect(() => {
  //   APIGetMastComponent();
  //   APIGetAppComponentByAspID();
  //   setCheckedListOptionHeader(checkedListOptionHeader);
  //   setCheckedListOptionMenu(checkedListOptionMenu);
  //   setCheckedListOptionFooter(checkedListOptionFooter);
  //   setAppComMainTypeHeader(appComMainTypeHeader);
  //   setAppComMainTypeMenu(appComMainTypeMenu);
  //   setAppComMainTypeFooter(appComMainTypeFooter);
  // }, [appComMainTypeHeader, appComMainTypeMenu, appComMainTypeFooter]);

  const { loading, error: fetchError, responseData, fetchData, fetchKey } =
    useFetch< GetPageNodesResponse | GenPageActionResponse >();
    //   | GetPageNodesResponse
    //   | GenPageActionResponse
    // >();

  useEffect(() => {
    APIGetMastComponent();
    APIGetAppComponentByAspID();
  }, [appComMainTypeHeader, appComMainTypeMenu, appComMainTypeFooter]);

  const handleStateUpdate = (listmenu: any, checkedList: any[], mainTemplate: any) => {
    let objDetail = {
      // "config_id": "399bd6d5-deef-4d8e-b6e6-67365528bd26",
      "profile_file_id": "",
      "profile_file_page_id": "",
      "logo_file_id": "",
      "logo_file_page_id": "",
      "noti_file_id": "",
      "noti_file_page_id": "",
      "list_menu": listmenu
    }
    
    if (componentType.toLocaleLowerCase() === HEADER) {
      setConfigHeader(objDetail);
      setInputMainHeader(mainTemplate);
      setCheckedListOptionHeader(checkedList);
    } else if (componentType.toLocaleLowerCase() === MENU) {
      setConfigMenu(objDetail);
      setInputMainMenu(mainTemplate);
      setCheckedListOptionMenu(checkedList);
    } else if (componentType.toLocaleLowerCase() === FOOTER) {
      setConfigFooter(objDetail);
      setInputFooterMain(mainTemplate);
      setCheckedListOptionFooter(checkedList);
    }
    console.log("listmenu +++ ", listmenu)
    console.log("checkedList +++ ", checkedList)
    console.log("mainTemplate +++ ", mainTemplate)
  };

  const MapImg = (appComKey: string) => {
    switch (appComKey) {
      // Header
      case "LEFT":
        return AppLeft;
      case "CENTER":
        return AppCenter;
      case "RIGHT":
        return AppRight;

      // Menu
      case "HORIZONTAL":
        return AppHorizontal;
      case "VERTICAL":
        return AppVertical;

      // Footer
      case "2_COLUMN":
        return AppCpn2Column;
      case "3_COLUMN":
        return AppCpn3Column;
      case "4_COLUMN":
        return AppCpn4Column;

      default:
        return AppNone;
    }
  };

  const APIGetMastComponent = async () => {
    try {
      const response = await callApiMethodGet(
        GET_MASTER_APP_COMPONENT_ENDPOINT,
        {}
      );

      // State : Get Key Object
      const keys = Object.keys(response.data);

      // State : Set Master Header Main
      const newDataHeaderMain = response.data.header.main.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[0],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListHeaderMain(newDataHeaderMain);

      // State : Set Master Header Option
      const datHeaderOption = response.data.header.option.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[0],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListHeaderOption(datHeaderOption);

      // State : Set Master Menu Main
      const dataMenuMain = response.data.menu.main.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[1],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListMenuMain(dataMenuMain);

      // State : Set Master Menu Option
      const dataMenuOption = response.data.menu.option.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[1],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListMenuOption(dataMenuOption);

      // State : Set Master Footer Main
      const dataFooterMain = response.data.footer.main.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[2],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListFooterMain(dataFooterMain);

      // State : Set Master Footer Option
      const dataFooterOption = response.data.footer.option.map((item: any) => ({
        ID: item.id,
        AppComMaiType: keys[2],
        AppComKey: item.app_com_key,
        AppComName: item.app_com_name,
      }));
      setListFooterOption(dataFooterOption);
    } catch (error) {
      setListHeaderMain([]);
      setListHeaderOption([]);
      setListMenuMain([]);
      setListMenuOption([]);
      setListFooterMain([]);
      setListFooterOption([]);
    }
  };

  const APIGetAppComponentByAspID = async () => {
    await APIGetMastComponent();
    try {
      const response = await callApiMethodGet(
        GET_APP_COMPONENT_BY_ASP_ID_ENDPOINT,
        { asp_id: getAspId() }
      );

      let appCompConfigs = response?.data || [];
      for (const item of appCompConfigs) {
        // State : Set Data Header
        if (item?.app_com_main_type === HEADER) {
          setConfigHeader(item.config);
          setAppComponentIDHeader(item.app_com_id);
          setMAppComponentIDHeader(item.m_app_component_id);
          setAppComMainTypeHeader(item.app_com_main_type);
          const mHeader = listHeaderMain.find(
            (m: any) => m.ID == item.m_app_component_id
          );
          setInputMainHeader(String(mHeader?.AppComName));

          let optionHeaderList: string[] = [];
          item.option.map((itemOption: any, index: number) => {
            optionHeaderList.push(itemOption.app_com_option_use);
          });
          setCheckedListOptionHeader(optionHeaderList);

          // State : Set Data Menu
        } else if (item?.app_com_main_type === MENU) {
          setConfigMenu(item.config);
          setMenuAppComponentID(item.app_com_id);
          setMAppComponentIDMenu(item.m_app_component_id);
          setAppComMainTypeMenu(item.app_com_main_type);
          const mMenu = listMenuMain.find(
            (m: any) => m.ID == item.m_app_component_id
          );
          setInputMainMenu(String(mMenu?.AppComName));

          let optionMenuList: string[] = [];
          item.option.map((itemOption: any) => {
            optionMenuList.push(itemOption.app_com_option_use);
          });
          setCheckedListOptionMenu(optionMenuList);

          // State : Set Data Footer
        } else if (item?.app_com_main_type === FOOTER) {
          setConfigFooter(item.config);
          setAppComponentIDFooter(item.app_com_id);
          setMAppComponentIDFooter(item.m_app_component_id);
          setAppComMainTypeFooter(item.app_com_main_type);
          const mFooter = listFooterMain.find(
            (m: any) => m.ID == item.m_app_component_id
          );
          setInputFooterMain(String(mFooter?.AppComName));

          let optionFooterList: string[] = [];
          item.option.map((itemOption: any) => {
            optionFooterList.push(itemOption.app_com_option_use);
          });
          setCheckedListOptionFooter(optionFooterList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const APICreateAppComponent = async (
    body: any,
    pageType: "header" | "menu" | "footer"
  ) => {
    try {
      setEnableLoadingOnGenerateByPageType(pageType);

      const response = await callApiMethodPost(
        CREATE_APP_COMPONENT_ENDPOINT,
        body
      );
      // if (response.status == 200) {
      //   setIsNotificationSuccess(true);
      //   setTimeout(() => {
      //     setIsNotificationSuccess(false);
      //   }, 5000); // เปลี่ยนค่าเป็น false หลังจาก 5 วินาที
      // }
    } catch (error) {
      console.log(error);
      setDisableLoadingOnGenerate();
    }
  };

  const APIUpdateAppComponent = async (
    body: any,
    pageType: "header" | "menu" | "footer"
  ) => {
    try {
      setEnableLoadingOnGenerateByPageType(pageType);

      const response = await callApiMethodPost(
        UPDATE_APP_COMPONENT_ENDPOINT,
        body
      );

      // if (response.status == 200) {
      //   setIsNotificationSuccess(true);
      //   setTimeout(() => {
      //     setIsNotificationSuccess(false);
      //   }, 5000); // เปลี่ยนค่าเป็น false หลังจาก 5 วินาที
      // }
    } catch (error) {
      console.log(error);
      setDisableLoadingOnGenerate();
    }
  };

  const APIGetAppComponentByAspId = async () => {
    try {
      let asp_id = getAspId();
      let params = {
        asp_id,
      };
      const response = await callApiMethodGet(
        GET_APP_COMPONENT_BY_ASP_ID_ENDPOINT,
        params
      );
      return response;

      // if (response.status == 200) {
      //   setIsNotificationSuccess(true);
      //   setTimeout(() => {
      //     setIsNotificationSuccess(false);
      //   }, 5000); // เปลี่ยนค่าเป็น false หลังจาก 5 วินาที
      // }
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const onClickOpenConfiguration = (configurationName: string) => {
    setIsNameConfigurationModal(configurationName);
    setIsOpenConfigurationModal(true);
    console.log("checkedListOptionHeader " ,checkedListOptionHeader)
    console.log("checkedListOptionMenu " ,checkedListOptionMenu)
    console.log("checkedListOptionFooter " ,checkedListOptionFooter)
    console.log("inputMainHeader " ,inputMainHeader)
    console.log("inputMainMenu " ,inputMainMenu)
    console.log("inputFooterMain " ,inputFooterMain)
  };

  const onCloseSettingModel = () => {
    setIsOpenConfigurationModal(false);
  };

  const toggleModalImportExcel = (comType: string) => {
    setComponentType(comType)
    setIsOpenModalImportExcel(true);
  }

  const onCancelImportExcel = () => {
    setIsOpenModalImportExcel(false);
  }
  const onOkImportExcel = () => {
    setIsOpenModalImportExcel(false);
    let title = `Set config ${ componentType } success!`;
    let description = `Set config ${ componentType } success!`;
    openNotifySuccess(title, description);
  }

  const onClickConfirmModalConfiguration = (config: any, titleName: string) => {
    switch (titleName) {
      case ComponentType.Header:
        setConfigHeader(config);
        break;
      case ComponentType.Menu:
        setConfigMenu(config);
        break;
      default:
        setConfigFooter(config);
        break;
    }
    setIsOpenConfigurationModal(false);
  };

  const openNotificationError = (titleName: string) => {
    notification.open({
      message: (
        <div className={styles.messageNotify}>{`Error ${titleName}!`}</div>
      ),
      description: (
        <div
          className={styles.descriptionNotify}
        >{`Please add Component and Option`}</div>
      ),
      icon: (
        <div className={styles.iconNotify}>
          <img src={error} alt="" width={"50px"} height={"50px"} />
        </div>
      ),
    });
  };

  // State : Set Data App Component Header ==========================================================================================
  const onClickCreateAppComponentHeader = async () => {
    const request: AppComponentRequest = {
      app_component_id: appComponentIDHeader,
      m_app_component_id: mAppComponentIDHeader,
      app_com_main_type: appComMainTypeHeader,
      asp_id: String(getAspId()),
      option: checkedListOptionHeader,
      config: configHeader,
    };

    // Create
    if (appComponentIDHeader == "") {
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Header);
      } else {
        await APICreateAppComponent(request, HEADER);
        actionCallGeneratePageByPageType(HEADER);
      }
      // Update
    } else {
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Header);
      } else {
        await APIUpdateAppComponent(request, HEADER);
        actionCallGeneratePageByPageType(HEADER);
      }
    }
  };

  const onChangeAppComponentHeader = (
    mComponentID: string,
    appComMainType: string,
    value: string
  ) => {
    setMAppComponentIDHeader(mComponentID);
    setAppComMainTypeHeader(appComMainType);
    setInputMainHeader(value);
  };

  const onChangeOptionHeader = (itemName: string) => {
    const isChecked = checkedListOptionHeader.includes(itemName);
    if (isChecked) {
      setCheckedListOptionHeader((prevState) =>
        prevState.filter((item) => item !== itemName)
      );
    } else {
      setCheckedListOptionHeader((prevState) => [...prevState, itemName]);
    }
  };

  // State : Set Data App Component Menu ==========================================================================================
  const onClickCreateAppComponentMenu = async () => {
    const request: AppComponentRequest = {
      app_component_id: menuAppComponentID,
      m_app_component_id: mAppComponentIDMenu,
      app_com_main_type: appComMainTypeMenu,
      asp_id: String(getAspId()),
      option: checkedListOptionMenu,
      config: configMenu,
    };
    console.log("request menu ==> ",request)
    // Create
    if (menuAppComponentID == "") {
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Menu);
      } else {
        await APICreateAppComponent(request, MENU);
        actionCallGeneratePageByPageType(MENU);
      }
    } else {
      // Update
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Menu);
      } else {
        await APIUpdateAppComponent(request, MENU);
        actionCallGeneratePageByPageType(MENU);
      }
    }
  };

  const onChangeAppComponentMenu = (
    mComponentID: string,
    appComMainType: string,
    value: string
  ) => {
    setMAppComponentIDMenu(mComponentID);
    setAppComMainTypeMenu(appComMainType);
    setInputMainMenu(value);
  };

  const onChangeOptionMenu = (itemName: string) => {
    const isChecked = checkedListOptionMenu.includes(itemName);
    if (isChecked) {
      setCheckedListOptionMenu((prevState) =>
        prevState.filter((item) => item !== itemName)
      );
    } else {
      setCheckedListOptionMenu((prevState) => [...prevState, itemName]);
    }
  };

  // ================================================================================================================================

  // State : Set Data App Component Footer
  const onClickCreateAppComponentFooter = async () => {
    const request: AppComponentRequest = {
      app_component_id: appComponentIDFooter,
      m_app_component_id: mAppComponentIDFooter,
      app_com_main_type: appComMainTypeFooter,
      asp_id: String(getAspId()),
      option: checkedListOptionFooter,
      config: configFooter,
    };

    if (appComponentIDFooter == "") {
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Footer);
      } else {
        await APICreateAppComponent(request, FOOTER);
        console.log("onClickCreateAppComponentFooter request => ", request);
        actionCallGeneratePageByPageType(FOOTER);
      }
    } else {
      if (request.m_app_component_id === "" || request.option.length === 0) {
        openNotificationError(ComponentType.Footer);
      } else {
        await APIUpdateAppComponent(request, FOOTER);
        console.log("onClickCreateAppComponentFooter request => ", request);
        actionCallGeneratePageByPageType(FOOTER);
      }
    }
  };

  const onChangeAppComponentFooter = (
    mComponentID: string,
    appComMainType: string,
    value: string
  ) => {
    setMAppComponentIDFooter(mComponentID);
    setAppComMainTypeFooter(appComMainType);
    setInputFooterMain(value);
  };

  const onChangeOptionFooter = (itemName: string) => {
    const isChecked = checkedListOptionFooter.includes(itemName);
    if (isChecked) {
      setCheckedListOptionFooter((prevState) =>
        prevState.filter((item) => item !== itemName)
      );
    } else {
      setCheckedListOptionFooter((prevState) => [...prevState, itemName]);
    }
  };

  //NOTIFY HANDLER
  const openNotifyError = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(false);
  };

  const openNotifySuccess = (title: string, description: string) => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(true);
  };

  const openNotifyOnGenerateLayoutSuccessByPageType = (
    pageType: string = "layout"
  ) => {
    let title = `Generate ${pageType} success!`;
    let description = `Generate page ${pageType} success!`;
    openNotifySuccess(title, description);
  };

  const openNotifyOnGenerateLayoutFailedByPageType = (
    pageType: string = "layout"
  ) => {
    let title = `Generate ${pageType} failed!`;
    let description = `Generate page ${pageType} failed!`;
    openNotifyError(title, description);
  };

  // ============================================ CALL GENERATE PAGE ON ONEWEB=============================================

  //CALL GENERATE PAGE HEADER_FOOTER STEP
  // 1. GET REQUEST FOR GENERATE_FILE WITH N'PETCH FUNCTION (NEED TO CHECK REQUEST BODY OF IT?)
  // 2. CALL FUNCTION OF PAGE FOR GET_PAGE_NODES
  // 3. GET REQUEST FOR CALL /PageDesigner/api/v3/zero/updateHeaderFooterByZero
  // 4. CALL UPLOAD LIBS FUNCITON

  const actionCallGeneratePageByPageType = async (
    pageType: "header" | "footer" | "menu"
  ) => {
    //CALL GET APP_COMPONENT LIST
    let componentResp = await APIGetAppComponentByAspId();
    console.log(
      "actionCallGeneratePageByPageType componentResp => ",
      componentResp
    );
    if (!componentResp) {
      setDisableLoadingOnGenerate();
      return;
    }

    //GET REQUEST FOR CALL GENERATE_FILE
    let genereateFileReq = getGenerateFileRequestByPageType(
      pageType,
      componentResp
    );
    if(pageType === 'header'){
      let compResp = componentResp.data.filter((item: any) => item.app_com_main_type === "header")
      genereateFileReq =  {
        ...genereateFileReq,
        logo_file_page_id: compResp[0].config.logo_file_page_id,
        noti_file_page_id: compResp[0].config.noti_file_page_id,
        profile_file_page_id: compResp[0].config.profile_file_page_id
      }
    }

    console.log(
      "actionCallGeneratePageByPageType genereateFileReq => ",
      genereateFileReq
    );
    
    let { js, html, css, funcName } = callGeneratePageFileByPageType(
      genereateFileReq,
      pageType
    );

    if(pageType === 'menu'){
      // get Header 
      let genereateFileReqHeader = getGenerateFileRequestByPageType(
        "header",
        componentResp
      );
      genereateFileReqHeader =  {
        ...genereateFileReqHeader,
        logo_file_page_id: componentResp.logo_file_page_id,
        noti_file_page_id: componentResp.noti_file_page_id,
        profile_file_page_id: componentResp.profile_file_page_id
      }
      console.log("genereateFileReqHeader => ", genereateFileReqHeader)

      console.log("actionCallGeneratePageByPageType html => ", html);
      let Header =  callGeneratePageFileByPageType( 
        genereateFileReqHeader, 
        "header"
      );
  
      html = Header.html.replace("</header>", '') + html + `</header>`
      console.log("html all => ", html);
      css = Header.css + css
      js = Header.js + js

    }else if(pageType === 'header'){
      let genereateFileReqHeader = getGenerateFileRequestByPageType(
        "menu",
        componentResp
      );
      console.log("actionCallGeneratePageByPageType html => ", html);
      let Menu =  callGeneratePageFileByPageType( 
        genereateFileReqHeader, 
        "menu"
      );
  
      // console.log("Menu => ", Menu);
      // html = Header.html + html
      html = html.replace("</header>", '') + Menu.html + `</header>`
      console.log("html all => ", html);
      css = css + Menu.css
      js = js + Menu.js
      funcName = Menu.funcName

    }
    setFunctionNameToGen(funcName)
    if (!html) {
      console.error("not found any html!!!");
      setDisableLoadingOnGenerate();
      return;
    }
    
    let pageNodes
    if(pageType === 'menu'){
      // @ts-ignore
      pageNodes = CONVERT_HTML_TO_OBJECT_HEADER_FOOTER(html, "header");
    }else{
      // @ts-ignore
      pageNodes = CONVERT_HTML_TO_OBJECT_HEADER_FOOTER(html, pageType);

    }
    console.log("CONVERT_HTML_TO_OBJECT_HEADER_FOOTER pageNodes", pageNodes);
    let htmlPage = JSON.stringify(pageNodes);

    //CALL GET GENERATE_PAGE REQUEST
    let genPageAPIReq = getCreatePageFooterHeaderReq(
      pageType,
      js,
      css,
      htmlPage
    );
    console.log(
      "actionCallGeneratePageByPageType genPageAPIReq => ",
      genPageAPIReq
    );

    //CALL UPDATE GEN_PAGE LAYOUT
    let updateLayoutResp: any = await APIUpdateLayoutPageAppComponent(
      genPageAPIReq
    );
    console.log(
      "actionCallGeneratePageByPageType updateLayoutResp => ",
      updateLayoutResp
    );
    if (updateLayoutResp.status === STATUS_ACCEPTED) {
      openNotifyOnGenerateLayoutSuccessByPageType(pageType);

      //CALL UPLOAD LIBS
      let filesToUpload = await getFormDataWithFilesForGenPageUpload();
      await APIUploadLibsAppComponent(filesToUpload);
    } else {
      openNotifyOnGenerateLayoutFailedByPageType(pageType);
    }
    setDisableLoadingOnGenerate();
  };

  const getCreatePageFooterHeaderReq = (
    pageType: "header" | "footer" | "menu",
    js: string,
    css: string,
    html: string
  ): AppComponentFooterHeaderRequest => {
    let applicationId = getAspId() as string;
    let pageTypes = pageType;
    let platform = WEB;

    return {
      applicationId,
      pageTypes,
      platform,
      js,
      css,
      html,
    };
  };

  //CALL GENERATE_FILE REQUEST
  const callGeneratePageFileByPageType = (request: any, pageType: string) => {
    let pageName = pageType;
    let datetime = String(new Date().getTime());
    let html = "";
    let css = "";
    let js = "";
    let funcName = "";

    if (pageType === HEADER) {
      let { htmlText, cssText, jsText, functionName } = generatePageFileHeader(
        request,
        pageName,
        datetime
      );
      html = htmlText;
      css = cssText;
      js = jsText;
      funcName = functionName;
    } else if (pageType === MENU) {
      console.log("request => ", request)
      // WAITING FOR GENERATE_PAGE_FILE_MENU
      let { htmlText, cssText, jsText, functionName } = generatePageFileMenu(
        request,
        pageName,
        datetime
      );
      html = htmlText;
      css = cssText;
      js = jsText;
      funcName = functionName;
    } else if (pageType === FOOTER) {
      let { htmlText, cssText, jsText, functionName } = generatePageFileFooter(
        request,
        pageName,
        datetime
      );
      html = htmlText;
      css = cssText;
      js = jsText;
      funcName = functionName;
    }
    return {
      html,
      js,
      css,
      funcName,
    };
  };

  const getGenerateFileRequestByPageType = (
    pageType: "header" | "footer" | "menu",
    configDetail: any
  ) => {
    let request: HeaderConfig | MenuConfig | FooterConfig | any = {};
    let configDataByPageType = configDetail?.data?.find(
      (item: any) => item?.app_com_main_type === pageType
    );
    if (!configDataByPageType) return;

    //PREPARE DATA FOR GENERATE REQUEST
    let options = configDataByPageType?.option;
    options = options.map((item: any) =>
      item?.app_com_option_use.toLowerCase()
    );
    let configs = configDataByPageType?.config;
    let configType = configDataByPageType?.m_app_component_name;

    //GENERATE REQUEST BY PAGE_TYPE
    switch (pageType) {
      case HEADER:
        let header: MenuItem[] = getHeaderMenuFooterFromConfigs(configs);
        console.log("configs => ", configs)
        console.log("header => ", header)
        let genPageHeaderFileReq = getRequestForGeneragePageFileHeader(
          options,
          header,
          configType
        );

        console.log("json genPageHeaderFileReq => ", genPageHeaderFileReq);
        request = genPageHeaderFileReq;
        break;

      case MENU:
        let menu: MenuItem[] = getHeaderMenuFooterFromConfigs(configs);
        console.log("configs => ", configs)
        console.log("menu => ", menu)
        let genPageMenuFileReq = getRequestForGeneragePageFileMenu(
          options,
          menu,
          configType
        );

        console.log("json genPageMenuFileReq => ", genPageMenuFileReq);
        request = genPageMenuFileReq;
        break;

      case FOOTER:
        let footer: MenuItem[] = getHeaderMenuFooterFromConfigs(configs);
        let genPageFooterFileReq = getRequestForGeneragePageFileFooter(
          options,
          footer,
          configType
        );
        console.log("json genPageFooterFileReq => ", genPageFooterFileReq);
        request = genPageFooterFileReq;
        break;
      default:
        break;
    }

    return request;
  };

  const getRequestForGeneragePageFileHeader = (
    options: any,
    header: MenuItem[],
    configType: string
  ) => {
    return {
      refTemplate: HEADER_TEMPLATE,
      configType,
      notification: options.some((item: any) => item === NOTIFICATION)
        ? "Y"
        : "N",
      userProfile: options.some((item: any) => item === USER_PROFILE)
        ? "Y"
        : "N",
      logo: options.some((item: any) => item === LOGO) ? "Y" : "N",
      dropdown: options.some((item: any) => item === DROPDOWN) ? "Y" : "N",
      header,
    };
  };

  const getRequestForGeneragePageFileMenu = (
    options: any,
    menu: MenuItem[],
    configType: string
  ) => {
    return {
      refTemplate: MENU_TEMPLATE,
      configType,
      withIcon: options.some((item: any) => item === WITH_ICON) ? "Y" : "N",
      menu,
    };
  };

  const getRequestForGeneragePageFileFooter = (
    options: any,
    footer: MenuItem[],
    configType: string
  ) => {
    return {
      refTemplate: MENU_TEMPLATE,
      configType,
      alignItem: options.some((item: any) => item === ALIGN_ITEM) ? "Y" : "N",
      instagram: options.some((item: any) => item === INSTAGRAM) ? "Y" : "N",
      facebook: options.some((item: any) => item === FACEBOOK) ? "Y" : "N",
      youtube: options.some((item: any) => item === YOUTUBE) ? "Y" : "N",
      search: options.some((item: any) => item === SEARCH) ? "Y" : "N",
      footer,
    };
  };

  const getHeaderMenuFooterFromConfigs = (configs: any): MenuItem[] => {
    let listMenu = configs?.list_menu;
    if (!listMenu || listMenu?.lenght === 0) return [];

    let layoutConfigs: MenuItem[] = [];
    for (const menu of listMenu) {
      let listSubMenu = menu?.list_sub_menu;
      let childs: MenuItem[] = [];
      for (const submenu of listSubMenu) {
        let childObj: MenuItem = {
          seq: Number(submenu.index),
          label: submenu.config_name,
          file_page_id: submenu.file_page_id,
          link: {
            type: submenu?.config_story_id ? STORY : URL,
            url: submenu?.config_url,
            story: {
              storyId: submenu?.config_story_id,
              storyName: submenu?.config_story_name,
              boName: submenu?.config_story_name,
              lastVersion: submenu?.version_number,
              pageName: submenu?.config_component_name,
            },
          },
        };
        childs.push(childObj);
      }
      let layoutObj: MenuItem = {
        seq: Number(menu.index),
        label: menu.config_name,
        file_page_id: menu.file_page_id,
        link: {
          type: menu?.config_story_id ? STORY : URL,
          url: menu?.config_url,
          story: {
            storyId: menu?.config_story_id,
            storyName: menu?.config_story_name,
            boName: menu?.config_story_name,
            lastVersion: menu?.version_number,
            pageName: menu?.config_component_name,
          },
        },
        childs,
      };

      layoutConfigs.push(layoutObj);
    }

    return layoutConfigs;
  };

  const setEnableLoadingOnGenerateByPageType = (
    pageType: "header" | "menu" | "footer"
  ) => {
    setIsLoading(true);
    setPageType(pageType);
  };

  const setDisableLoadingOnGenerate = () => {
    setIsLoading(false);
    setPageType("");
  };

  //GENERATE PAGE CONTROLLER
  const APIUpdateLayoutPageAppComponent = async (body: any) => {
    try {
      const response = await callApiMethodPagePatch(
        GEN_PAGE_LAYOUT_ENDPOINT,
        body
      );
      console.log("APIUpdateLayoutPageAppComponent response => ", response);
      let pageId = response?.data.id;
      setPageId(pageId)
      getPageNodesController(pageId);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  const APIUploadLibsAppComponent = async (data: any) => {
    try {
      let params = {
        app_id: getAspId(),
        force: "N",
      };
      const response = await callApiMethodUploadLibsPost(
        UPLOAD_FILE_LIBS_ENDPOINT,
        data,
        params
      );
      console.log("response upload libs", response)
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  //MAPPING FUNCTION HANDLER
  const getPageNodesController = (pageId: string) => {
    fetchData(
      {
        endpoint: GET_HEADER_PAGE_NODES_BY_PAGEID_ENDPOINT, //waiting
        method: "GET",
        params: {
          page_id: pageId,
        },
      },
      GET_PAGE_NODES_FK
    );
  };

  const getActionGeneratePageRequest = (
    nodeId: string
  ): GeneratePageActionRequest => {
    const id = uuidv4();
    let result: GeneratePageActionRequest = {
      eventType: "load",
      action: {
        pageId: pageId,
        appId: getAspId() as string,
        actionType: "JavaScript",
        functionName: functionNameToGen,
        id,
      },
      nodeId,
    };
    return result;
  };

  const callActionGenPageController = (req: GeneratePageActionRequest) => {
    fetchData(
      {
        endpoint: GEN_PAGE_ACTION_ENDPOINT, //waiting
        method: "PUT",
        reqBody: req,
      },
      GEN_PAGE_ACTION_FK
    );
  };


  useEffect(() => {
    if (!loading) {
      if (fetchError || !responseData) {
        return;
      }

      switch (fetchKey) {
        case GET_PAGE_NODES_FK:
          let respGetPageNodes: GetPageNodesResponse =
            responseData as GetPageNodesResponse;
          console.log("respGetPageNodes => ", respGetPageNodes);
          let nodeId = respGetPageNodes?.data?.nodeId;
          let genPageActionReq = getActionGeneratePageRequest(nodeId);
          console.log("genPageActionReq => ", genPageActionReq);

          callActionGenPageController(genPageActionReq);

          break;

        case GEN_PAGE_ACTION_FK:
          let respGenPageAction: GenPageActionResponse = 
            responseData as GenPageActionResponse;
          console.log("respGenPageAction => ", respGenPageAction);
          
          break;

        default:
          break;
      }
    }
  }, [loading, responseData]);

  // ============================================ CALL GENERATE PAGE ON ONEWEB=============================================

  // ================================================================================================================================

  const content = (
    <>
      <h2>App Component (3)</h2>

      {/* Header */}
      <div className={styles.container}>
        <div className={styles.componentName}>
          <h3>{ComponentType.Header}</h3>
          <div className={styles.btnContainer}>
            <Button 
              onClick={ () => toggleModalImportExcel(ComponentType.Header)}
            >Import Excel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              icon={<SettingOutlined />}
              onClick={() => onClickOpenConfiguration(ComponentType.Header)}
            ></Button>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              ghost
              icon={<EditOutlined />}
            ></Button>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {listHeaderMain.map((item, index) => (
            <div key={index}>
              <Card
                hoverable
                className={styles.cardContainer}
                cover={<img alt="example" src={MapImg(item.AppComKey)} />}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                  }}
                >
                  <Meta title={item.AppComName} />
                  <RightCircleOutlined />
                </div>
              </Card>
              <div className={styles.radioContainer}>
                <Radio.Group
                  onChange={(e) =>
                    onChangeAppComponentHeader(
                      item.ID,
                      item.AppComMaiType,
                      e.target.value
                    )
                  }
                  value={inputMainHeader}
                >
                  <Radio
                    style={{ fontSize: "20px" }}
                    value={item.AppComName}
                  ></Radio>
                </Radio.Group>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.componentName}>
          <h3>Add Option</h3>
        </div>
        <div className={styles.checkboxContainer}>
          {listHeaderOption.map((item, index) => (
            <Checkbox
              key={index}
              checked={checkedListOptionHeader.includes(item.AppComName)}
              onChange={() => onChangeOptionHeader(item.AppComName)}
            >
              {item.AppComName}
            </Checkbox>
          ))}
        </div>
        <div style={{ padding: "20px" }}>
          <Button
            className={styles.buttonGenerate}
            type="primary"
            ghost
            onClick={onClickCreateAppComponentHeader}
            loading={isLoading && pageType === HEADER}
          >
            Generate
          </Button>
        </div>
      </div>

      {/* Menu */}
      <div className={styles.container}>
        <div className={styles.componentName}>
          <h3>{ComponentType.Menu}</h3>
          <div className={styles.btnContainer}>
            <Button 
              onClick={ () => toggleModalImportExcel(ComponentType.Menu)}
            >Import Excel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              icon={<SettingOutlined />}
              onClick={() => onClickOpenConfiguration(ComponentType.Menu)}
            ></Button>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              ghost
              icon={<EditOutlined />}
            ></Button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {listMenuMain.map((item, index) => (
            <div key={index}>
              <Card
                hoverable
                className={styles.cardContainer}
                cover={<img alt="example" src={MapImg(item.AppComKey)} />}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                  }}
                >
                  <Meta title={item.AppComName} />
                  <RightCircleOutlined />
                </div>
              </Card>
              <div className={styles.radioContainer}>
                <Radio.Group
                  onChange={(e) =>
                    onChangeAppComponentMenu(
                      item.ID,
                      item.AppComMaiType,
                      e.target.value
                    )
                  }
                  value={inputMainMenu}
                >
                  <Radio
                    style={{ fontSize: "20px" }}
                    value={item.AppComName}
                  ></Radio>
                </Radio.Group>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.componentName}>
          <h3>Add Option</h3>
        </div>
        <div className={styles.checkboxContainer}>
          {listMenuOption.map((item, index) => (
            <Checkbox
              key={index}
              checked={checkedListOptionMenu.includes(item.AppComName)}
              onChange={() => onChangeOptionMenu(item.AppComName)}
            >
              {item.AppComName}
            </Checkbox>
          ))}
        </div>
        <div style={{ padding: "20px" }}>
          <Button
            className={styles.buttonGenerate}
            type="primary"
            ghost
            onClick={onClickCreateAppComponentMenu}
            loading={isLoading && pageType === MENU}
          >
            Generate
          </Button>
        </div>
      </div>

      {/* Footer */}
      <div className={styles.container}>
        <div className={styles.componentName}>
          <h3>{ComponentType.Footer}</h3>
          <div className={styles.btnContainer}>
            <Button 
              onClick={ () => toggleModalImportExcel(ComponentType.Footer)}
            >Import Excel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              icon={<SettingOutlined />}
              onClick={() => onClickOpenConfiguration(ComponentType.Footer)}
            ></Button>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              ghost
              icon={<EditOutlined />}
            ></Button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {listFooterMain.map((item, index) => (
            <div key={index}>
              <Card
                hoverable
                className={styles.cardContainer}
                cover={<img alt="example" src={MapImg(item.AppComKey)} />}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                  }}
                >
                  <Meta title={item.AppComName} />
                  <RightCircleOutlined />
                </div>
              </Card>
              <div className={styles.radioContainer}>
                <Radio.Group
                  onChange={(e) =>
                    onChangeAppComponentFooter(
                      item.ID,
                      item.AppComMaiType,
                      e.target.value
                    )
                  }
                  value={inputFooterMain}
                >
                  <Radio
                    style={{ fontSize: "20px" }}
                    value={item.AppComName}
                  ></Radio>
                </Radio.Group>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.componentName}>
          <h3>Add Option</h3>
        </div>
        <div className={styles.checkboxContainer}>
          {listFooterOption.map((item, index) => (
            <Checkbox
              key={index}
              checked={checkedListOptionFooter.includes(item.AppComName)}
              onChange={() => onChangeOptionFooter(item.AppComName)}
            >
              {item.AppComName}
            </Checkbox>
          ))}
        </div>
        <div style={{ padding: "20px" }}>
          <Button
            className={styles.buttonGenerate}
            type="primary"
            ghost
            onClick={onClickCreateAppComponentFooter}
            loading={isLoading && pageType === FOOTER}
          >
            Generate
          </Button>
        </div>
      </div>
      <ConfigurationModal
        isOpenModal={isOpenConfigurationModal}
        configDetail={{
          titleName: isNameConfigurationModal,
          detail:
            isNameConfigurationModal == ComponentType.Menu
              ? configMenu
              : isNameConfigurationModal == ComponentType.Header
              ? configHeader
              : configFooter,
        }}
        onSave={onClickConfirmModalConfiguration}
        onClose={onCloseSettingModel}
      />
      {isNotificationSuccess == true && (
        <CreateEntitySuccessNotify isCreated={"Create App Component"} />
      )}

      {
        isOpenModalImportExcel === true && (
          <ModalImportExcel 
            isOpenModalImportExcel={isOpenModalImportExcel}
            onCancel={onCancelImportExcel}
            onOk={onOkImportExcel}
            componentType={componentType}
            handleStateUpdate={handleStateUpdate}
          />
        )}

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </>
  );

  return (
    <div>
      <div style={{ height: "100%" }}>
        <DashboardZeroContent content={content} />
      </div>
    </div>
  );
}

export default AppComponent;
