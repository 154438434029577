import React, { useState, useEffect, useCallback } from "react";
import { LoadingOutlined, FileExcelOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Modal, message, Upload } from "antd";
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import type { GetProp, UploadProps } from "antd";
import debounce from 'lodash/debounce';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from "uuid";
import styles from "./ModalUpload.module.scss";
import { callApiMethodGet, getAspId } from "src/services/util.service";
import MessageNofify from "src/components/zero/zero-story/notification/MessageNotify"
import {
    GET_STORY_BY_ASPID_ENDPOINT,
    GET_COMPONENT_BY_STORY_ID_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { HEADER, MENU } from "../constants/generate-layout.constant";
import {
    AppComponentRequest,
    MasterAppComponentDetail,
    ConfigDetail,
    AppComponentFooterHeaderRequest,
    HeaderConfig,
    MenuConfig,
    FooterConfig,
    MenuItem,
  } from "../models/AppComponentModel";
import { Item } from "rc-menu";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

interface Props {
  isOpenModalImportExcel: boolean;
//   onOk: (uploadID: string) => void;
  componentType: string;
  onCancel: () => void;
  onOk: () => void;
  handleStateUpdate: (listmenu: any, checkedList: any[], mainConfig: any) => void;
//   detail: { titleName: string; fileID: string; type: string };
}

interface ExcelRow {
    'Field Name': string; 
    [key: string]: any;   
}
interface RcFile extends File {
    lastModifiedDate?: Date;
    uid: string | number;
}
interface jsonExcelfile {
    file_id : string;
    file_name : string;
}

interface Detail {
    config_id : string
}

interface configDetail {
    detail : any,
    titleName : string
}

const { Dragger } = Upload;

function ModalImportExcel({ isOpenModalImportExcel, componentType, onCancel, onOk, handleStateUpdate }: Props) {
    const [componentList , setComponentList] = useState<any>([]);
    const [storyList, setStoryList] = useState<any>([]);
    //MESSAGE NOTIFY STATE
    let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
    let [titleNotify, setTitleNotify] = useState<string>("");
    let [descriptionNotify, setDescriptionNotify] = useState<string>("");
    let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

    //FILE STATE
    let [isInvalidFormat, setisInvalidFormat] = useState<boolean>(false);
    let [fileNameInvalid, setFileNameInvalid] = useState<jsonExcelfile[]>([]);
    
    //CONFIG STATE
    let [conFigdetail, setConFigdetail] =  useState<any[]>([]);

    //HEADER STATE
    let [inputMainHeader, setInputMainHeader] = useState<string>("");
    let [checkedListOptionHeader, setCheckedListOptionHeader] = useState<
        string[]
    >([]);
    const [configHeader, setConfigHeader] = useState<ConfigDetail>({
        profile_file_id: "",
        logo_file_id: "",
        noti_file_id: "",
        list_menu: [],
    });

    //MENU STATE
    let [inputMainMenu, setInputMainMenu] = useState<string>("");
    const [checkedListOptionMenu, setCheckedListOptionMenu] = useState<string[]>(
        []
    );
    const [configMenu, setConfigMenu] = useState<ConfigDetail>({
        profile_file_id: "",
        logo_file_id: "",
        noti_file_id: "",
        list_menu: [],
    });


    //MAIN STATE CONFIG
    const [listmenuState, setlistmenuState] = useState<any[]>([]);
    const [checkedListOptionState, setcheckedListOptionState] = useState<any[]>([]);
    const [mainTemplateState, setMainTemplateState] = useState<string>("");

    //FOOTER STATE
    let [inputFooterMain, setInputFooterinputFooterMain] = useState<string>("");
    const [checkedListOptionFooter, setCheckedListOptionFooter] = useState<
        string[]
    >([]);
    const [configFooter, setConfigFooter] = useState<ConfigDetail>({
        profile_file_id: "",
        logo_file_id: "",
        noti_file_id: "",
        list_menu: [],
    });

    const [loadingButtonContinue, setLoadingButtonContinue] =
        useState<boolean>(false);

    useEffect(() => {
        if (isOpenModalImportExcel) {
            window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
            APIGetStoryByAspID()
        } else {
            window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
        }
        // APIGetFileByID();
        // setImageUrl("");
        setLoadingButtonContinue(false);
    }, [isOpenModalImportExcel]);

    useEffect(() => {
        console.log("storyList ==>>> ", storyList)
    },[storyList])

    // useEffect(() => {
    //     console.log("  isInvalidFormat ======  ", isInvalidFormat)
    //     if (isInvalidFormat) {
    //         setTriggerNotify(!triggerNotify);
    //         setTitleNotify("File format invalid");
    //         setDescriptionNotify("Please upload new file");
    //         setIsSuccessNotify(true);
    //     }
    // }, [isInvalidFormat]);

    //FETCH DATA HANDLER
    const APIGetStoryByAspID = async () => {
        try {
          const response = await callApiMethodGet(GET_STORY_BY_ASPID_ENDPOINT, {
            asp_id: getAspId(),
          });
    
          if (response.status == 200) {
            // State : Set Story List
            const newDataStoryList = response.data.storyComponent.map((item: any) => ({
                id: item.id,
                story_name: item.story_name,
              })
            );
            setStoryList(newDataStoryList);
          }
        } catch (error) {
        
        }
    };
    
    const APIGetComponentByStoryID = async (storyID: string) => {
        try {
        const response = await callApiMethodGet(
            GET_COMPONENT_BY_STORY_ID_ENDPOINT,
            {
            story_id: storyID,
            }
        );
    
        if (response.status == 200) {
            // State : Set Story List
            const newData = response.data.Component.map((item: any) => ({
              id: item.id,
              component_name: item.component_name,
            }));
            setComponentList(newData);

            return newData
        }
        } catch (error) {
  
        }
    };

    const handleFileRead = async (file: RcFile, file_id: string, file_name: string) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json: ExcelRow[] = XLSX.utils.sheet_to_json(worksheet);
            console.log("json => ", json);
    
            let isInvalidKey = await checkFormatFileExcel(json, file_name, file_id);
            console.log("isInvalidKey :", isInvalidKey)
            if(!isInvalidKey){
                setTriggerNotify(!triggerNotify);
                setTitleNotify("Import Excel successfully");
                setDescriptionNotify(`Continue to config ${componentType}`);
                setIsSuccessNotify(true);
                
                const listMenu = await getConfigHeaderFromExcel(json);
                const checkedListOption = await getCheckedListOption(json);
                const MainTemplate = await getMainTemplate(json);
        
                console.log("list Menu => ", listMenu);
                console.log("checkedListOption => ", checkedListOption);
                console.log("MainTemplate => ", MainTemplate);
                
                setlistmenuState(listMenu)
                setcheckedListOptionState(checkedListOption)
                setMainTemplateState(MainTemplate)
            }{
                setTriggerNotify(!triggerNotify);
                setTitleNotify("File format invalid");
                setDescriptionNotify("Please upload new file");
                setIsSuccessNotify(false);
            }
            // handleStateUpdate(listmenuState, checkedListOptionState, MainTemplateState);
        };
        reader.readAsArrayBuffer(file);
    };
    

    const debouncedHandleFileRead = useCallback(
        debounce((fileList: UploadFile<any>[]) => {
            const uploadedFiles = fileList.filter(f => !f.status || f.status === 'done');
            //   setConFigdetail([]);
            if (uploadedFiles.length > 0) {
                Array.from(uploadedFiles).forEach(file => {
                    const uploadedFile = file;
                    const file_id = uploadedFile.originFileObj?.uid;
                    const file_name = uploadedFile.originFileObj?.name;
                    if (uploadedFile.originFileObj && file_id) {
                    handleFileRead(uploadedFile.originFileObj as any, file_id, file_name as string);
                    }
                });
            }
        }, 50),
        []
    );

    const checkFormatFileExcel = async (json:any[], fileName:string, file_id:string) => {
        let isValidKeys = false
        for (const row of json) {
            if (!isValidObjectKeys(row)) {
                isValidKeys = true
                setisInvalidFormat(true);
                setFileNameInvalid(prevItems => {
                    if (prevItems.some(item => item.file_id === file_id)) {
                        return prevItems;
                    }
                    return [...prevItems, {
                        file_name: fileName,
                        file_id: file_id
                    }];
                });
                console.log("!isValidObjectKeys(row)", !isValidObjectKeys(row));
        
                break;
            }
        }

        return isValidKeys
    }

    const expectedPropertiesHeader = [
        "Sequence",
        "Alignment",
        "Notification",
        "User_profile",
        "Dropdown",
        "Header_name",
        "url",
        "Parent",
        "Story_name",
        "Page_name",
        "Config_type",
    ];

    const expectedPropertiesMenu= [
        "Sequence",
        "Alignment",
        "With_icon",
        "Menu_name",
        "Parent",
        "url",
        "Story_name",
        "Page_name",
        "Config_type",
    ];

    const expectedPropertiesFooter= [
        "Sequence",
        "Column",
        "Align_item",
        "Instagram",
        "Facebook",
        "Youtube",
        "Search",
        "Parent",
        "url",
        "Story_name",
        "Page_name",
        "Config_type",
    ];

    function isValidObjectKeys(obj: Record<string, unknown>): boolean {
        const objKeys = Object.keys(obj);
        let expectedProperties = []
        if(componentType.toLocaleLowerCase() === HEADER){
            expectedProperties = expectedPropertiesHeader
        }else if(componentType.toLocaleLowerCase() === MENU){
            expectedProperties = expectedPropertiesMenu
        }else{
            expectedProperties = expectedPropertiesFooter
        }

        for (const key of objKeys) {
 
            if (!expectedProperties.some(expectedKey => expectedKey === key)) {
                console.log("key ; ", key)
                return false;
            }
        }

        
        return true;
    }

    
    //HANDLE CONFIG HEADER
    const getConfigHeaderFromExcel = async (json: any) => {
        console.log('header handler');
        console.log(json);
    
        let configMain = json.filter((item: any) => item.Config_type === 'main');
        console.log("configMain +++ > ", configMain)
        let listmenu = await Promise.all(configMain.map(async (item: any) => {
            let config_name = componentType.toLocaleLowerCase() == HEADER
                ? item.Header_name
                : componentType.toLocaleLowerCase() == MENU
                ? item.Menu_name
                : item.Footer_name;
    
            let storyName = item.Story_name;
            let storyId = '';
            if (storyName) {
                storyId = await getStoryId(storyName);
                console.log("storyId 1 => ", storyId)
            }
           
            let pageName = item.Page_name;
            let componentId = '';
            if (pageName) {
                console.log("storyId => ", storyId)
                componentId = await getComponentId(pageName, storyId);
                console.log("componentId => ", componentId)
            }
            return {
                id: uuidv4(),
                index: item.Sequence || '',
                config_name: config_name || '',
                config_url: item.url || '',
                config_story_id: storyId || '',
                config_story_name: item.Story_name || '',
                config_component_id: componentId || '',
                config_component_name: item.Page_name || '',
                file_id: "",
                file_page_id: "",
                version_name: "",
                list_sub_menu: [],
                version_number: 0,
            };
        }));
    
        let configSub = json.filter((item: any) => item.Config_type === 'sub');
        let listSubMenu = await Promise.all(configSub.map(async (item: any) => {
            let subconfig_name = componentType.toLocaleLowerCase() == HEADER
                ? item.Header_name
                : componentType.toLocaleLowerCase() == MENU
                ? item.Menu_name
                : item.Footer_name;
    
            let storyName = item.Story_name;
            let storyId = '';
            if (storyName) {
                storyId = await getStoryId(item.Story_name);
            }
            console.log("storyId => ", storyId)
            let pageName = item.Page_name;
            let componentId = '';
            if (pageName) {
                componentId = await getComponentId(pageName, storyId);
            }
    
            return {
                id: uuidv4(),
                index: item.Sequence || '',
                config_name: subconfig_name || '',
                config_url: item.url || '',
                config_story_id: storyId || '',
                config_story_name: item.Story_name || '',
                config_component_id: componentId || '',
                config_component_name: item.Page_name || '',
                file_id: "",
                file_page_id: "",
                version_name: "",
                version_number: 0,
                parent: item.Parent,
            };
        }));
    
        console.log("listmenu ", listmenu);
        console.log("listSubMenu ", listSubMenu);
    
        listmenu.forEach((main: any) => {
            listSubMenu.forEach((sub: any) => {
                if (main.config_name === sub.parent) {
                    main.list_sub_menu.push(sub);
                }
            });
        });
    
        return listmenu;
    };
    
    const getCheckedListOption = (json: any) => {
        console.log("get checked options")
        console.log(json)
        let checkedOptionList: any = []
        if(componentType.toLocaleLowerCase() == HEADER){
            let isNoti = json.some((item: any) => item.Notification && item.Notification.toLowerCase() === 'y')
            let isUserProfile = json.some((item: any) => item.User_profile && item.User_profile.toLowerCase() === 'y')
            let isLogo = json.some((item: any) => item.Logo && item.Logo.toLowerCase() === 'y')
            let isDropdown = json.some((item: any) => item.Dropdown && item.Dropdown.toLowerCase() === 'y')

            if(isNoti){
                checkedOptionList.push("Notification")
            }
            if(isUserProfile){
                checkedOptionList.push("User Profile")
            }
            if(isLogo){
                checkedOptionList.push("Logo")
            }
            if(isDropdown){
                checkedOptionList.push("Dropdown")
            }
            
        }else if(componentType.toLocaleLowerCase() == MENU){
            let isWith_icon = json.some((item: any) => item.With_icon && item.With_icon.toLowerCase() === 'y')

            if(isWith_icon){
                checkedOptionList.push("With Icon")
            }
        }else{
            let isYoutube = json.some((item: any) => item.Youtube && item.Youtube.toLowerCase() === 'y')
            let isFacebook = json.some((item: any) => item.Facebook && item.Facebook.toLowerCase() === 'y')
            let isInstagram = json.some((item: any) => item.Instagram && item.Instagram.toLowerCase() === 'y')
            let isAlign_item = json.some((item: any) => item.Align_item && item.Align_item.toLowerCase() === 'y')
            let isSearch = json.some((item: any) => item.Search && item.Search.toLowerCase() === 'y')

            if(isSearch){
                checkedOptionList.push("Search")
            }
            if(isYoutube){
                checkedOptionList.push("Youtube")
            }
            if(isFacebook){
                checkedOptionList.push("Facebook")
            }
            if(isInstagram){
                checkedOptionList.push("Instagram")
            }
            if(isAlign_item){
                checkedOptionList.push("Align Item'")
            }
        }

        return checkedOptionList
    }

    const getMainTemplate = async (json: any) => {
        let mainTemmplate = ''
        if(componentType.toLocaleLowerCase() == HEADER || componentType.toLocaleLowerCase() == MENU){
            json.forEach((item: any) => {
                if(item.Alignment && item.Alignment !== ''){
                    mainTemmplate = item.Alignment
                }
            });
        }else{
            const maxColumn = Math.max(...json.map((item: any) => item.Column));
            let column = maxColumn as 4 | 3 | 2 
            mainTemmplate = `${column} Column`
        }

        return mainTemmplate;
    }

    const getStoryId = (name: string): Promise<string> => {
        console.log("storyList : ",storyList)
        console.log("name : ",name)
        let name_ = name.toLocaleLowerCase()
        let story = []
        if(storyList.length > 0){
            story = storyList.filter((item: any ) => 
                item.story_name.toLocaleLowerCase().includes(name_)
            )
        }
        return story.length > 0 ? story[0].id : ''
    }

    const getComponentId = async (name: string, storyId: string): Promise<string> => {
        let componentList_ =  await APIGetComponentByStoryID(storyId)
        let name_ = name.toLocaleLowerCase()
        let component = []
        if(componentList_){
            component = componentList_.filter((item: any ) => 
                item.component_name.toLocaleLowerCase().includes(name_)
            )
        }
        // console.log("componentList_ ====>", componentList_)
        // console.log("component ====>", component)
        return component.length > 0 ? component[0].id : ''
    }


    const UploadProps = {
        name: 'file',
        multiple: true,
        accept: ".xlsx, .xls",
        onChange(info: UploadChangeParam<UploadFile<any>>) {
            const { file, fileList } = info;
            const { status } = file;
            
            if (status !== 'removed' && fileList.length) {
                console.log(fileList)
                console.log(componentType)
                debouncedHandleFileRead(fileList);
                // setTriggerNotify(!triggerNotify);
                // setTitleNotify("Import Excel successfully");
                // setDescriptionNotify(`Continue to config ${componentType}`);
                // setIsSuccessNotify(true);
            }else if(status === 'removed'){
                const removedFileid= file.uid;
                const removedFileName = file.name;
                console.log("removedFileName => ", removedFileName)
                // conFigdetail = conFigdetail.filter(item => item.file_id != removedFileid);
                // let fileNameInvalidFromState:jsonExcelfile[] = fileNameInvalid

                // setFileNameInvalid([])
                // let fileInvalidList:jsonExcelfile[] = []
                // fileNameInvalidFromState.forEach((item: jsonExcelfile) => {
                //     if(item.file_id !== removedFileid){
                //         fileInvalidList.push(item)
                //         setFileNameInvalid(fileInvalidList)
                //     }
                // })

                // if(fileInvalidList.length === 0 ){
                //     setisInvalidFormat(false)
                // }

                // if(conFigdetail.length > 0 ){
                //     setConFigdetail(conFigdetail)
                // }else{
                //     setConFigdetail([]);
                // }

                setTriggerNotify(!triggerNotify);
                setTitleNotify("File removed successfully");
                setDescriptionNotify("Upload new file");
                setIsSuccessNotify(true);
            }

            // if (status !== 'uploading') {
            //     // console.log(info.file, info.fileList);
            // }
            //   if (status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully.`);
            //   } else if (status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            //   }
        },
        onDrop(e: React.DragEvent<HTMLDivElement>) {
            console.log('Dropped files', e.dataTransfer.files);
            e.preventDefault(); 
            e.stopPropagation();
        },
        beforeUpload: () => false,
        iconRender(file: UploadFile<any>) {
            if (file.status === 'uploading') {
            return <LoadingOutlined />;
            }
            return <FileExcelOutlined />;
        },
    };

  
    const onClickConfirm = () => {
        handleStateUpdate(listmenuState, checkedListOptionState, mainTemplateState);
        // setLoadingButtonContinue(true);
        onOk()
    };

    return (
        <div>
        <Modal
            title={<div>{"Import Excel"}</div>}
            footer={
            <div className={styles.modalFooter}>
                <Button
                className={styles.cancelBtn}
                key="cancel"
                onClick={onCancel}
                type="text"
                >
                Cancel
                </Button>
                <Button
                className={styles.confirmBtn}
                key="ok"
                onClick={onClickConfirm}
                type="primary"
                loading={loadingButtonContinue}
                disabled={isInvalidFormat}
                >
                Continue
                </Button>
            </div>
            }
            open={isOpenModalImportExcel}
            onCancel={onCancel}
            centered
            width={400}
        >
            <div className={styles.modalContentExcel}>
            {/* <Upload
                name="avatar"
                listType="picture-card"
                // className={styles.uploadContent}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "50%" }} />
                ) : (
                uploadButton
                )}
            </Upload> */}
                <Dragger {...UploadProps} className={styles.ImportContent}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text" style={{color: "#393939" ,fontWeight: "600"} }  >Drag and drop files here</p>
                    <p style={{color: "gray",fontSize: "12px"} } className="ant-upload-text">*support file .xls, .xlsx</p>
                    <p>or</p>
                    <Button 
                        className={styles.conBtn}
                        key="browse"
                    >
                        Browse
                    </Button>
                </Dragger>
            </div>
        </Modal>

        <MessageNofify
                trigger={triggerNotify}
                title={titleNotify}
                description={descriptionNotify}
                isSuccess={isSuccessNotify}
            />
        </div>
    );
}

export default ModalImportExcel;
