import React from "react";

function compress() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M14.5669 8.02947L20.9104 1.76929L22.3153 3.19282L15.9915 9.43352L19.5613 9.44772L19.5534 11.4477L12.5534 11.4199L12.5813 4.41992L14.5813 4.42788L14.5669 8.02947Z"
          fill="#8c8c8c"
        />{" "}
        <path
          d="M7.97873 14.5429L4.4088 14.5457L4.40723 12.5457L11.4072 12.5402L11.4127 19.5402L9.41271 19.5417L9.40989 15.9402L3.09616 22.2306L1.68457 20.8138L7.97873 14.5429Z"
          fill="#8c8c8c"
        />{" "}
      </g>
    </svg>
  );
}

export default compress;
