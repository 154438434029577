import React from "react";

const StoryGenPageAddRight = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4L5.5 20H10.5V4H5.5ZM3.5 20C3.5 21.1046 4.39543 22 5.5 22H10.5C11.6046 22 12.5 21.1046 12.5 20L12.5 4C12.5 2.89543 11.6046 2 10.5 2H5.5C4.39543 2 3.5 2.89543 3.5 4L3.5 20Z"
        fill="#393939"
      />
      <path
        d="M15.5 13C14.9477 13 14.5 12.5523 14.5 12C14.5 11.4477 14.9477 11 15.5 11L19.5 11C20.0523 11 20.5 11.4477 20.5 12C20.5 12.5523 20.0523 13 19.5 13L15.5 13Z"
        fill="#393939"
      />
      <path
        d="M18.4956 14.0019C18.498 14.5531 18.053 15.0019 17.5018 15.0043C16.9505 15.0066 16.5018 14.5617 16.4994 14.0104L16.4824 10.0067C16.48 9.45544 16.925 9.00667 17.4762 9.00432C18.0274 9.00197 18.4762 9.44693 18.4786 9.99817L18.4956 14.0019Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageAddRight;
