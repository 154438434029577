export default function renderBOScreens(screenElement, screen, filehubUrlImage, label) {
    const labelText = document.createElement('label');
    labelText.textContent = screen.label;
    labelText.className = 'ant-form-item-label';
    screenElement.style.zIndex = 9;
    screenElement.appendChild(labelText);

    const divInput = document.createElement('div');
    divInput.className = 'divInput';

    console.log("hidden:: ", screen.hidden);
    if (screen.hidden === 'Y') {
        screenElement.hidden = true;
    }
    console.log("------------- screen --------> ", screen)
    console.log("------------- screen.inputType --------> ", screen.inputType)

    if (screen.inputType === 'password') {
        const input = document.createElement('input');
        input.id = 'input-id-' + screen.key;
        input.type = screen.inputType;
        input.placeholder = screen.inputType ?? `Enter ${screen.label}...`;
        input.classList.add('custom-position-input');
        divInput.appendChild(input);

        const toggleButton = document.createElement('div');
        toggleButton.id = 'toggleButton-id-' + screen.key;
        toggleButton.innerHTML = '<i class="fas fa-eye-slash"></i>';
        toggleButton.className = 'password-toggle-button';

        // กำหนดฟังก์ชันสำหรับปุ่ม toggle
        let isPasswordVisible = false;
        toggleButton.addEventListener('click', () => {
            isPasswordVisible = !isPasswordVisible;
            input.type = isPasswordVisible ? 'text' : 'password';

            toggleButton.innerHTML = isPasswordVisible ? '<i class="fas fa-eye"></i>' : '<i class="fas fa-eye-slash"></i>';
        });
        divInput.appendChild(toggleButton);

    } else if (screen.inputType === 'textarea') {
        const input = document.createElement('textarea');
        input.id = 'input-id-' + screen.key;

        divInput.appendChild(input);

    } else if (screen.inputType === 'file') {
        const input = document.createElement('input');
        input.id = 'input-id-' + screen.key;
        input.type = screen.inputType;

        input.addEventListener('change', function (event) {
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    alert('ไม่สามารถใช้ไฟล์รูปภาพได้ กรุณาเลือกไฟล์ที่ไม่ใช่รูปภาพเท่านั้น');
                    input.value = ''; // ล้างค่า input file
                }
            }
        });
        divInput.appendChild(input);

    } else if (screen.inputType === 'radio') {
        const divRadioGroup = document.createElement('div');
        divRadioGroup.className = 'radio-group';

        // Check 
        if (screen.dataRefer && screen.dataRefer.datas) {

            if (screen.dataRefer.type === 'table') {
                const dataFromDataConnect = fetchDataFromDataConnect(screen.dataRefer.dataconnect);
                // Map 
                if (dataFromDataConnect) {
                    dataFromDataConnect.forEach(data => {
                        screen.dataRefer.datas.push({
                            columnShow: data.columnShow,
                            columnValue: data.columnValue
                        });
                    });
                }
            }


            // Loop 
            screen.dataRefer.datas.forEach(data => {
                const radioContainer = document.createElement('div');
                radioContainer.className = 'radio-container';

                const radioButton = document.createElement('input');
                radioButton.type = 'radio';
                radioButton.name = screen.key;
                radioButton.value = data.columnValue;

                const radioLabel = document.createElement('label');
                radioLabel.textContent = data.columnShow;

                radioContainer.appendChild(radioButton);
                radioContainer.appendChild(radioLabel);

                divRadioGroup.appendChild(radioContainer);

                radioButton.addEventListener('click', () => {
                    console.log(`radioButton: ${radioButton.value}`);
                });
            });
        }
        screenElement.appendChild(divRadioGroup);

    } else if (screen.inputType === 'select') {

        const selectInput = document.createElement('select');
        selectInput.id = 'select-id-' + screen.key;

        if (screen.dataRefer && screen.dataRefer.datas) {
            if (screen.dataRefer.type === 'table') {
                const dataFromDataConnect = fetchDataFromDataConnect(screen.dataRefer.dataconnect);
                if (dataFromDataConnect) {
                    dataFromDataConnect.forEach(data => {
                        screen.dataRefer.datas.push({
                            columnShow: data.columnShow,
                            columnValue: data.columnValue
                        });
                    });
                }
            }
            screen.dataRefer.datas.forEach(data => {
                const option = document.createElement('option');
                option.value = data.columnValue;
                option.textContent = data.columnShow;
                selectInput.appendChild(option);
            });

            selectInput.addEventListener('change', () => {
                const selectedValue = selectInput.value;
                console.log('ค่าที่เลือก:', selectedValue);
            });

            divInput.appendChild(selectInput);
        }

    } else if (screen.inputType === 'checkbox') {

        if (screen.dataRefer && screen.dataRefer.datas) {
            const checkboxWrapper = document.createElement('div');
            checkboxWrapper.className = 'checkbox-wrapper';
            const selectedValues = [];

            if (screen.dataRefer.type === 'table') {
                const dataFromDataConnect = fetchDataFromDataConnect(screen.dataRefer.dataconnect);
                if (dataFromDataConnect) {
                    dataFromDataConnect.forEach(data => {
                        screen.dataRefer.datas.push({
                            columnShow: data.columnShow,
                            columnValue: data.columnValue
                        });
                    });
                }
            }

            screen.dataRefer.datas.forEach(data => {
                const checkboxContainer = document.createElement('div');
                checkboxContainer.className = 'checkbox-container';

                const checkbox = document.createElement('input');
                checkbox.type = 'checkbox';
                checkbox.name = screen.key;
                checkbox.value = data.columnValue;

                const checkboxLabel = document.createElement('label');
                checkboxLabel.textContent = data.columnShow;

                checkboxContainer.appendChild(checkbox);
                checkboxContainer.appendChild(checkboxLabel);

                checkboxWrapper.appendChild(checkboxContainer);
                checkbox.addEventListener('click', () => {
                    if (checkbox.checked) {
                        selectedValues.push(checkbox.value); // เพิ่มค่าของ checkbox ที่ถูกเลือกเข้าไปใน array
                    } else {
                        const index = selectedValues.indexOf(checkbox.value);
                        if (index !== -1) {
                            selectedValues.splice(index, 1); // ลบค่าของ checkbox ที่ไม่ถูกเลือกออกจาก array
                        }
                    }
                    console.log('checkbox:', selectedValues);
                });
            });

            divInput.appendChild(checkboxWrapper);
        }
    } else if (screen.inputType === 'email') {
        // สร้าง div element เพื่อใช้เป็น container ของ input elements
        const emailContainer = document.createElement('div');
        emailContainer.className = 'email-container';

        // สร้าง input element สำหรับการกรอกชื่อผู้ใช้งาน
        const usernameInput = document.createElement('input');
        usernameInput.id = 'username-input-' + screen.key;
        usernameInput.placeholder = 'Enter username...';


        // สร้าง input element สำหรับการเลือกนามสกุลเมล
        const emailSuffixInput = document.createElement('input');
        emailSuffixInput.id = 'email-suffix-input-' + screen.key;
        emailSuffixInput.placeholder = '@example.com';
        emailSuffixInput.setAttribute('list', 'email-suffixes');

        // สร้าง datalist element เพื่อระบุรายการของนามสกุลเมลที่เลือกได้
        const emailSuffixes = ['@gmail.com', '@yahoo.com', '@hotmail.com', '@qq.com'];
        const dataList = document.createElement('datalist');
        dataList.id = 'email-suffixes';

        emailSuffixes.forEach(suffix => {
            const option = document.createElement('option');
            option.value = suffix;
            dataList.appendChild(option);
        });


        emailContainer.appendChild(usernameInput);
        emailContainer.appendChild(emailSuffixInput);

        divInput.appendChild(emailContainer);
        divInput.appendChild(dataList);

        // สร้างตัวแปรเพื่อเก็บค่า email
        let email = '';

        // เพิ่ม event listener สำหรับเหตุการณ์ change ของ input elements
        emailSuffixInput.addEventListener('input', () => {
            const emailSuffix = emailSuffixInput.value;
            email = emailSuffix;
            console.log('อีเมลที่เลือก:', email);
        });

        usernameInput.addEventListener('input', () => {
            const username = usernameInput.value;
            email += username; // รวม username เข้ากับ email
            console.log('อีเมลที่สมบูรณ์:', email);
        });
        console.log("email:: ", email);

    } else if (screen.inputType === 'image_input') {
        const uploadInput = document.createElement('input');
        uploadInput.type = 'file';
        uploadInput.accept = 'image/*';
        uploadInput.multiple = true;

        const imgCrop = document.createElement('div');
        imgCrop.className = 'antd-img-crop';

        // const uploadList = document.createElement('div');
        // uploadList.className = 'ant-upload-list ant-upload-list-picture-card';

        const uploadButton = document.createElement('div');
        uploadButton.id = 'image-' + screen.key;
        uploadButton.className = 'ant-upload ant-upload-select ant-upload-select-picture-card upload-div';
        uploadButton.innerHTML = '<span id="image-preview-'+label+"-" + screen.key + '" tabindex="0" class="ant-upload upload-input-text " role="button" style="position: relative; overflow: hidden; direction: ltr;"><input id="image-file-' +label+"-"+ screen.key + '" type="file" accept="image/*" style="position: absolute; cursor: pointer; opacity: 0; top: 0; right: 0; bottom: 0; left: 0; font-size: 200px;"><label id="image-preview-label-'+label+"-" + screen.key + '">+ Upload</label></span>';

        // อัปเดตรายการไฟล์เมื่อมีการเลือกไฟล์ใหม่
        const onChange = ({ fileList: newFileList }) => {
            setFileList(newFileList);
        };

        // แสดงตัวอย่างของไฟล์ภาพเมื่อคลิกที่ปุ่มดูตัวอย่าง
        const onPreview = async (files) => {
            const file = files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = function (event) {
                const imgElement = document.createElement('img');
                imgElement.src = event.target.result;
                imgElement.style.maxWidth = '100%';
                imgElement.style.maxHeight = '100%';

                const upload = document.getElementById('image-preview-'+label+"-" + screen.key);
                // ลบภาพเก่าออกจาก DOM
                const imgContainer = upload.querySelector('img');
                if (imgContainer) {
                    imgContainer.remove();
                }

                // แทรกภาพลงใน DOM
                upload.appendChild(imgElement);
            };
            reader.readAsDataURL(file);
        };


        uploadButton.querySelector('input').addEventListener('change', (event) => {
            onChange({ fileList: event.target.files });
            onPreview(event.target.files);
            const uploadLabel = document.getElementById('image-preview-label-'+label+"-" + screen.key);
            uploadLabel.hidden = true;
        });

        uploadInput.addEventListener('change', (event) => {
            onChange({ fileList: event.target.files });
            onPreview(event.target.files);
        });

        // imgCrop.appendChild(uploadList);
        imgCrop.appendChild(uploadButton);

        divInput.appendChild(imgCrop);

    } else {
        // let type = "text";
        // if (screen.inputType === "image_input") {
        //     type = "text";
        // } else {
        //     type = screen.inputType;
        // }
        const input = document.createElement('input');
        input.readOnly = screen.displayView === "Read Only" ?? false;
        input.id = 'input-id-' + label + "-" + screen.key;
        input.type ='text';
        input.placeholder = screen.inputType ?? `Enter ${screen.label}...`;
        console.log("renderBoScreens BO input => ", input)

        divInput.appendChild(input);
        console.log("renderBoScreens divInput => ", divInput)
    }

    screenElement.appendChild(divInput);
}


function fetchDataFromDataConnect(dataconnect) {
    console.log("dataconnect", dataconnect);
    console.log("dataconnect", dataconnect.columnShow);
    console.log("dataconnect", dataconnect.columnValue);
    const data = [{
        columnShow: "วัยรุ่น",
        columnValue: "teenager"
    },
    {
        columnShow: "ผู้ใหญ่",
        columnValue: "adult"
    }]
    return data;
}

let fileList = [];

const setFileList = (newFileList) => {
    fileList = newFileList;
    console.log('Updated fileList:', fileList);
};
