import { Button, Checkbox, CheckboxProps, Select } from "antd";
import React, { useContext, useEffect, useState} from "react";
import SearchFilterItem from "./SearchFilterItem";
import "./SearchFilterConfigStep2.scss";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import styles from "./SearchFilterConfigStep2.module.scss";
import StoryEditEntAdd from "src/assets/svg/story-edit-ent-add";
import StoryGeneratePageHandler from "src/utils/story-genpage/StoryGeneratePageHandler";
import { SEARCH_BO_FIELD_SELECTS } from "src/utils/story-genpage/StoryGenPageMockUp";
import { StoryGeneratePageContext } from "src/page/context/StoryGeneratePage.context";
import {
  EntityBoMapping,
  GetEntityBoByIdResponse,
  GetEntityBoItem,
  GetEntityBoItemByStoryIDResponse,
} from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import { useParams } from "react-router";
import {
  GET_BO_BY_STORY_ID_ENDPOINT,
  GET_ENTITY_BO_BY_ID_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { useSearchParams } from "react-router-dom";
import { getSelectBoFieldFromJsonBoMapping } from "src/utils/story-genmf/StoryGenerateMFUtility";

type Props = {
  // storyGenPageHandler: StoryGeneratePageHandler;
};
type SearchFilterItemType = {
  id: string;
  boFieldName: string;
  isDefaultSearch: boolean;
};
function SearchFilterConfigStep2({}: Props) {
  let params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { loading, error, responseData, fetchData, fetchKey } = useFetch<
    GetEntityBoItemByStoryIDResponse | GetEntityBoByIdResponse
  >();

  let genPageContext = useContext(StoryGeneratePageContext);
  let [boFieldSelects, setBoFieldSelects] = useState<
    { value: string; label: string }[]
  >([]);
  let [allBoFieldSelects, setAllBoFieldSelects] = useState<
    { value: string; label: string }[]
  >([]);

  let [searchFilterItems, setSearchFilterItems] = useState<
    SearchFilterItemType[]
  >([]);
  let [boFieldCreate, setBoFieldCreate] = useState<string | null>();
  let [isDefaultSearchCreate, setIsDefaultSearchCreate] =
    useState<boolean>(true);
  let [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  let [itemToRemove, setItemToRemove] = useState<string | null>();
  let [isBoDataLoaded, setIsBoDataLoaded] = useState<boolean>(false);

  const GET_BO_ITEM_FK = "getBoItemFK";
  const GET_JSON_BO_MAPPING_FK = "getJsonBoFK";

  //SETUP ITEMS ON LOAD
  useEffect(() => {
    console.log("onload SearchFilterConfigStep2", genPageContext);
    //getBoItemByStoryIdController(); //fetch all bo_items by story_id
    getJsonBoMappingByIdController();
  }, []);


  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) {
        disableLoadingBtn();
        return;
      }

      switch (fetchKey) {
        case GET_BO_ITEM_FK: {
          let respGetAllBoItem =
            responseData?.data as unknown[] as GetEntityBoItem[];
          if (respGetAllBoItem?.length === 0) return;
          // console.log("respGetAllBoItem => ", respGetAllBoItem);
          let newBoFieldSelects = respGetAllBoItem.map((item) => {
            return {
              value: item.enyity_bo_item_id,
              label: item.bo_name,
            };
          });
          // console.log("newBoFieldSelects => ", newBoFieldSelects);
          setBoFieldOnFetchSuccess(newBoFieldSelects);

          setOldSearchFilterItems(); //set search_filter items from context saved
          break;
        }
        case GET_JSON_BO_MAPPING_FK: {
          let respGetJsonBoMapping =
            responseData?.data as unknown as EntityBoMapping;
          if (!respGetJsonBoMapping) return;
          console.log("respGetJsonBoMapping => ", respGetJsonBoMapping);

          //SET STORY_NAME TO CONTEXT
          let storyName = respGetJsonBoMapping?.bo_name;
          genPageContext.setStoryName(storyName);

          //SETUP JSON_BO_MAPPING
          let jsonBoMapping = JSON.parse(respGetJsonBoMapping.json_mapping_bo);
          console.log("jsonBoMapping => ", jsonBoMapping);
          let boFieldSelectByJsonMapping =
            getSelectBoFieldFromJsonBoMapping(jsonBoMapping);
          console.log("boFieldSelectByJsonMapping => ", boFieldSelectByJsonMapping);
          let newBoFieldSelects = boFieldSelectByJsonMapping.map((item) => {
            return {
              value: item.rootPath,
              label: item.rootPath,
            };
          });
          console.log("newBoFieldSelects => ", newBoFieldSelects);

          setBoFieldOnFetchSuccess(newBoFieldSelects);
          setBoFieldInContextData(
            boFieldSelectByJsonMapping,
            newBoFieldSelects
          );
          
          //SET CONTEXT DATA FOR COMPONENT_TABLE
          setContextForComponentTable(jsonBoMapping);
          disableLoadingBtn();
          setOldSearchFilterItems(); //set search_filter items from context saved

          //SET FOR MAPPING DATA VERSION
          if(genPageContext.isVersionGenerated && !isBoDataLoaded) setIsBoDataLoaded(true);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //GET COMPONENT_TABLE
  const setContextForComponentTable = (jsonBoMapping: any) => {
    let jsonMapping = jsonBoMapping?.nodeDataArray;

    //SET SELECT COMPONENT_TABLE
    let selectForComponentTable =
      getObjectSelectListForComponentTableFromJsonMapping(jsonMapping);
    console.log("selectForComponentTable => ", selectForComponentTable);
    genPageContext.setSelectComponentTable(selectForComponentTable);

    //SET MAPPING_PARENT_CHILD FOR COMPONENT_TABLE
    let parentChildMapping = getMappingParentAndChildByJsonMapping(jsonMapping);
    // console.log("parentChildMapping => ", parentChildMapping)
    genPageContext.setMappingParentChildComponentTable(parentChildMapping);
  };
  const getObjectSelectListForComponentTableFromJsonMapping = (
    jsonBoMapping: any
  ) => {
    let objectList = jsonBoMapping.filter(
      (item: any) => item?.typeDesc === "Object"
    );
    let result = objectList.map((item: any) => {
      return {
        value: item.paramName,
        label: item.paramName,
      };
    });

    return result;
  };

  const getMappingParentAndChildByJsonMapping = (jsonBoMapping: any) => {
    let parents = jsonBoMapping.filter(
      (item: any) => item?.typeDesc === "Object"
    );
    // console.log("getMappingParentAndChildByJsonMapping parents => ", parents)

    let result: any = [];
    for (const parent of parents) {
      let childs = jsonBoMapping.filter(
        (item: any) => item?.parentKey === parent.key
      );
      let parentObj = {
        ...parent,
        childs,
      };
      result.push(parentObj);
    }
    return result;
  };

  //SAVE DATA TRIGGER FROM PARENT
  useEffect(() => {
    let isSave = genPageContext.isSaveSearchFilter;
    let currentStep = genPageContext.currentStep;
    let nextStep = currentStep + 1;

    if (isSave) {
      let dataForSave = getSearchFilterConfigDataForSave();
      // console.log(
      //   "SearchFilterConfigStep2 onsave dataForSave => ",
      //   dataForSave
      // );
      let gerateBoConfigData: any[] = genPageContext.generateBoConfig;
      let newGenerateBoConfig = gerateBoConfigData.filter(
        (item) => item.bo_config_type !== "SEARCH_FILTER"
      );

      genPageContext.setGenerateBoConfig([
        ...newGenerateBoConfig,
        ...dataForSave,
      ]);
      genPageContext.setIsSaveSearchFilter(false);
      genPageContext.setCurrentStep(nextStep);
    }
  }, [genPageContext.isSaveSearchFilter]);

  //FILTER SELECTS BY SEARCH_FILTER_ITEMS
  useEffect(() => {
    if (searchFilterItems.length > 0) {
      let allSearchFilterId = searchFilterItems.map((item) => item.id);
      let newBoFieldSelects = allBoFieldSelects.filter(
        (item) => !allSearchFilterId.includes(item.value)
      );
      setBoFieldSelects(newBoFieldSelects);
    } else {
      setBoFieldSelects([...allBoFieldSelects]);
    }
  }, [searchFilterItems]);

  //DEFAULT ROW
  const onChangeSelectBoField = (value: string) => {
    console.log("onChangeSelectBoField value ", value);
    setBoFieldCreate(value);
    onAddBoFieldHandler()
  };
  useEffect(() => {
    if (boFieldCreate !== null) {
      onAddBoFieldHandler();
    }
  }, [boFieldCreate]);

  const onChangeIsDefaultSearch: CheckboxProps["onChange"] = (e) => {
    let isDefault = e.target.checked;
    console.log(`onChangeIsDefaultSearch = ${isDefault}`);
    setIsDefaultSearchCreate(isDefault);
  };
  const onAddBoFieldHandler = () => {
    console.log(
      `create new row step2 on add with ==> boFieldCreate=${boFieldCreate}, isDefaultSearchCreate=${isDefaultSearchCreate}`
    );
    let boSelect = boFieldSelects.find((item) => item.value === boFieldCreate);
    if (!isPassValidateAddItem()) return;
    addNewSearchFilterItem({
      boItemId: boSelect?.value as string,
      boFieldName: boSelect?.label as string,
      isDefaultSearch: isDefaultSearchCreate,
    });
  };
  const clearDefaultRow = () => {
    setBoFieldCreate(null);
    setIsDefaultSearchCreate(true);
  };

  //DELETE MODAL
  const onSearchFilterItemRemove = (id: string) => {
    setIsOpenModal(true);
    console.log("onSearchFilterItemRemove id :: ", id);
    setItemToRemove(id);
  };
  const onOkConfirmDeleteHandler = () => {
    setIsOpenModal(false);

    if (!itemToRemove) return;
    removeSearchFilterItem();
  };
  const onCancelConfirmDeleteHandler = () => {
    setIsOpenModal(false);
    setItemToRemove(null);
  };

  //UTILITY FUNCTION
  const addNewSearchFilterItem = ({
    boItemId,
    boFieldName,
    isDefaultSearch,
  }: {
    boItemId: string;
    boFieldName: string;
    isDefaultSearch: boolean;
  }) => {
    let newSearchFilterItem: SearchFilterItemType = {
      id: boItemId,
      boFieldName,
      isDefaultSearch,
    };
    // setRunningNumber((prev) => prev + 1);

    let newSearchFilterItems: SearchFilterItemType[] = [
      ...searchFilterItems,
      newSearchFilterItem,
    ];
    updateNewSearchFilterItems(newSearchFilterItems);
    clearDefaultRow();
  };
  const removeSearchFilterItem = () => {
    let newSearchFilterItems = [...searchFilterItems].filter(
      (item) => item.id !== itemToRemove
    );
    // setSearchFilterItems(newSearchFilterItems);
    updateNewSearchFilterItems(newSearchFilterItems);
    setItemToRemove(null);
  };
  const updateNewSearchFilterItems = (
    newSearchFilterItems: SearchFilterItemType[]
  ) => {
    setSearchFilterItems(newSearchFilterItems);
    // genPageContext.setGenerateBoConfig(newSearchFilterItems);
  };
  const isPassValidateAddItem = () => {
    let isPass = true;
    let textError = "";
    if (!boFieldCreate) {
      isPass = false;
      textError += "Please input bo!";
    }
    let hasThisBo = searchFilterItems.some(
      (item) => item.boFieldName === boFieldCreate
    );
    if (hasThisBo) {
      isPass = false;
      textError += "This bo already added!";
    }
    return isPass;
  };
  const getSearchFilterConfigDataForSave = () => {
    let dataToSave = searchFilterItems.map((item) => {
      return {
        enyity_bo_item_id: item.id,
        // enyity_bo_item_name: item.boFieldName,
        bo_config_type: "SEARCH_FILTER",
        default_search: item.isDefaultSearch.toString(),
      };
    });
    return dataToSave;
  };

  const getStoryId = () => {
    return params.id;
  };

  const getBoId = () => {
    return searchParams.get("bo_id");
  };

  const setOldSearchFilterItems = () => {
    // console.log("getSearchResultBySearchFilter ", genPageContext);
    let generateBoConfigSaved = genPageContext?.generateBoConfig;
    let searchFilterItemsSaved = generateBoConfigSaved.filter(
      (item) => item.bo_config_type === "SEARCH_FILTER"
    );
    if (searchFilterItemsSaved.length === 0) return;
    let newSearchFilterItems: SearchFilterItemType[] =
      searchFilterItemsSaved.map((item) => {
        return {
          id: item.enyity_bo_item_id,
          boFieldName: item.enyity_bo_item_id,
          isDefaultSearch: Boolean(item.default_search),
        };
      });
    setSearchFilterItems(newSearchFilterItems);
  };

  const setBoFieldOnFetchSuccess = (
    newBoFieldSelects: { label: string; value: string }[]
  ) => {
    setBoFieldSelects(newBoFieldSelects);
    setAllBoFieldSelects(newBoFieldSelects);
  };

  const setBoFieldInContextData = (
    boFieldSelectByJsonMapping: any,
    newBoFieldSelects: any
  ) => {
    genPageContext.setBoFieldForData(boFieldSelectByJsonMapping);
    genPageContext.setBoFieldForSelect(newBoFieldSelects);
  };

  const onChangeDefaultSearchItem = (id: string, defaultSearch: boolean) => {
    let index = searchFilterItems.findIndex((item) => item.id == id);
    searchFilterItems[index].isDefaultSearch = defaultSearch;
  };

  const enableLoadingBtn = () => {
    genPageContext.setIsLoadingBtnGeneratePage(true);
  };

  const disableLoadingBtn = () => {
    genPageContext.setIsLoadingBtnGeneratePage(false);
  };

  //API CONTROLLER FUNCTION
  const getBoItemByStoryIdController = () => {
    fetchData(
      {
        endpoint: GET_BO_BY_STORY_ID_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_BO_ITEM_FK
    );
  };

  const getJsonBoMappingByIdController = () => {
    enableLoadingBtn();
    let boId = getBoId();
    fetchData(
      {
        endpoint: GET_ENTITY_BO_BY_ID_ENDPOINT,
        method: "GET",
        params: {
          id: boId,
        },
      },
      GET_JSON_BO_MAPPING_FK
    );
  };

  return (
    <>
      {/* CONFIRM DELETE MODAL */}
      <table id="SearchFilterTb">
        <thead>
          <tr>
            <th>BO Field</th>
            <th style={{ textAlign: "center" }}>Default Search</th>
            <th></th>
            {/* <th>Search Criteria</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Select
                value={boFieldCreate}
                // defaultValue={boFieldSelects[0].value}
                options={boFieldSelects}
                style={{ width: "60%" }}
                placeholder={<div>&nbsp;&nbsp;&nbsp;Select BO Field</div>}
                onChange={onChangeSelectBoField}
                optionFilterProp="children"
                className={styles.genTableSelect}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Checkbox
                checked={isDefaultSearchCreate}
                onChange={onChangeIsDefaultSearch}
              />
            </td>
            <td className={styles.tdSetCenter}>
              <Button
                className={styles.addBtn}
                key="add"
                onClick={onAddBoFieldHandler}
                // value={boFieldCreate}
              >
                <StoryEditEntAdd
                  style={{ marginRight: "6px", fill: "#1c87f2" }}
                />
                Add
              </Button>
            </td>
          </tr>

          {searchFilterItems.map((item, index) => {
            return (
              <SearchFilterItem
                key={index}
                searchFilterItem={item}
                onChangeDefaultSearchItem={onChangeDefaultSearchItem}
                onRemoveItem={onSearchFilterItemRemove}
              />
            );
          })}
        </tbody>
      </table>
      <ConfirmDeleteModal
        modalDetail={{
          title: "Delete BO Field?",
          description: `Are you sure you want to delete this BO Field? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenModal}
        onOk={onOkConfirmDeleteHandler}
        onCancel={onCancelConfirmDeleteHandler}
      />
    </>
  );
}

export default SearchFilterConfigStep2;
