import {
  useInfiniteQuery,
} from "@tanstack/react-query";
import { getAllDBConnectorsByAspID } from "src/services/databaseConnectors.service";
import { GetAllDBConnectorsByAspIDProps } from "src/types/databaseConnectors.type";

export function useAllDBConnectorsByAspID(
  props: GetAllDBConnectorsByAspIDProps
) {
  return useInfiniteQuery({
    queryKey: ["databaseConnectorsByAspID", props],
    queryFn: ({ pageParam = 1 }) => getAllDBConnectorsByAspID({ ...props, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.data?.databaseConnectors === null? null : allPages?.length + 1;
    },
  });
}
