
import React, { useEffect, useState } from 'react';

const TextChild = (props) => {
  
  const [text, setText] = useState();
  useEffect(()=>{
    setText(props.usernameValid)
    console.log(" Text props",props);
  },[])
 

  return (
    <>
      <div>
        <div >
          <div>1: {props.usernameValid}</div>
          <div>2: {text}</div>
        </div>
      </div>
    </>
  );
};

export default TextChild;
