import React, { useEffect, useState } from "react";
import styles from "../../database-connection/drawer/DrawerDatabaseConnection.module.scss";
import { Button, Drawer, Form, Input } from "antd";
import SearchIcon from "src/assets/svg/searchIcon";
import { LeftOutlined } from "@ant-design/icons";
import CardDataSource from "../card/CardDataSource";
import LogoMySQL from "src/assets/png/LogoMySQL.png";
import LogoMongoDB from "src/assets/png/LogoMongoDB.png";
import LogoRedis from "src/assets/png/LogoRedis.png";
import LogoPostgreSQL from "src/assets/png/LogoPostgreSQL.png";
import LogoFirebase from "src/assets/png/LogoFirebase.png";
import LogoMarria from "src/assets/png/LogoMaria.png";
import LogoSQLServer from "src/assets/png/LogoSqlServer.png";
import LogoOracle from "src/assets/png/LogoOracle2.png";
import LogoGoogleSheet from "src/assets/png/LogoGoogle-sheet.png";
import { useMutation } from "@tanstack/react-query";
import { dbConnectorsTest } from "src/services/databaseConnectors.service";
import { useCreateDBConnector } from "src/hooks/database-connection/useDatabaseConnectorMutation";
import {
  CreateDBConnectorsProps,
  GetAllDBConnectorsByAspIDProps,
} from "src/types/databaseConnectors.type";

type DrawerDataConnectionProps = {
  isOpen: boolean;
  onClose: () => void;
  valueDatabaseType: string;
  activeDatabaseIndex: number | null;
  handleDatabaseCardClick: (index: number, title: string) => void;
  handleAPICardClick: (index: number, title: string) => void;
  activeAPIIndex: number | null;
  showModalFail: () => void;
  onDataName: (
    name: string,
    title: string,
    description: string,
    type?: string
  ) => void;
  showModalSuccess: () => void;
  dbConnectorsQuery: GetAllDBConnectorsByAspIDProps;
};

function DrawerDataConnection({
  isOpen,
  onClose,
  valueDatabaseType,
  activeAPIIndex,
  onDataName,
  showModalFail,
  activeDatabaseIndex,
  handleDatabaseCardClick,
  handleAPICardClick,
  showModalSuccess,
  dbConnectorsQuery,
}: DrawerDataConnectionProps) {
  const createDBConnectorMutation = useCreateDBConnector(dbConnectorsQuery);

  const [currentStep, setCurrentStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isTestConnectionPass, setIsTestConnectionPass] =
    useState<boolean>(false);
  const [dbConnector, setDBConnect] = useState<
    CreateDBConnectorsProps | undefined
  >(undefined);

  const [form] = Form.useForm();
  const [loadingButtonConnect, setLoadingButtonConnect] =
    useState<boolean>(false);

  const databaseType = [
    {
      title: "MySQL",
      logo: LogoMySQL,
    },
    // {
    //   title: "MongoDB",
    //   logo: LogoMongoDB,
    // },
    // {
    //   title: "Redis",
    //   logo: LogoRedis,
    // },
    {
      title: "PostgreSQL",
      logo: LogoPostgreSQL,
    },
    // {
    //   title: "Firebase",
    //   logo: LogoFirebase,
    // },
    {
      title: "MariaDB",
      logo: LogoMarria,
    },
    {
      title: "SQLServer",
      logo: LogoSQLServer,
    },
    {
      title: "Oracle",
      logo: LogoOracle
    }
  ];

  const APIs = [
    {
      title: "Sheets",
      logo: LogoGoogleSheet,
    },
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
    // form.resetFields();
    // setSearchTerm("");
  };

  const onCloseDrawer = () => {
    setSearchTerm("");
    onClose();
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    console.log("value", valueDatabaseType);
    // form.resetFields();
    setLoadingButtonConnect(false);
  }, [valueDatabaseType]);

  const stepContent1 = () => {
    return (
      <>
        <Input
          suffix={<SearchIcon />}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        {/* Database Type */}
        {databaseType.length > 0 && (
          <>
            {databaseType.filter((dbType) =>
              dbType.title.toLowerCase().includes(searchTerm.toLowerCase())
            ).length > 0 && (
              <div className={styles.textContainer}>
                <p className={styles.text}>Database Type</p>
                <span className={styles.textImportant}>*</span>
              </div>
            )}
            <div className={styles.cardDataSourceGrid}>
              {databaseType
                .filter((dbType) =>
                  dbType.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((dbType, index) => (
                  <CardDataSource
                    key={index}
                    title={dbType.title}
                    logo={dbType.logo}
                    isActive={index === activeDatabaseIndex}
                    onClick={() => handleDatabaseCardClick(index, dbType.title)}
                  />
                ))}
            </div>
          </>
        )}

        {/* APIs */}
        {/* {APIs.length > 0 && (
          <>
            {APIs.filter((api) =>
              api.title.toLowerCase().includes(searchTerm.toLowerCase())
            ).length > 0 && (
              <div className={styles.textContainer}>
                <p className={styles.text}>APIs</p>
              </div>
            )}
            <div className={styles.cardDataSourceGrid}>
              {APIs.filter((api) =>
                api.title.toLowerCase().includes(searchTerm.toLowerCase())
              ).map((api, index) => (
                <CardDataSource
                  key={index}
                  title={api.title}
                  logo={api.logo}
                  isActive={index === activeAPIIndex}
                  onClick={() => handleAPICardClick(index, api.title)}
                />
              ))}
            </div>
          </>
        )} */}
      </>
    );
  };

  // const onFinish = (values: any) => {
  //     console.log('Success:', values);
  // };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleConnect = (values: any) => {
    form
      .validateFields()
      .then(() => {
        if (dbConnector) {
          createDBConnectorMutation.mutate(dbConnector, {
            onSuccess: () => {
              onDataName(
                "",
                "Database Connected",
                "Database Connection Successful!"
              );

              form.resetFields();
              setCurrentStep(0);
              onClose();
            },
          });
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleTestConnection = () => {
    console.log("handleTestConnection");
    const {
      ConnectionName,
      ItemName,
      DatabaseType,
      Host,
      Password,
      Port,
      Username,
    } = form.getFieldsValue();
    form
      .validateFields()
      .then(() => {
        let asp_id = String(localStorage.getItem("asp_id"));
        const payload: CreateDBConnectorsProps = {
          jndi_name: "",
          connection_url: "",
          connection_name: ConnectionName,
          item_name: ItemName,
          db_type: DatabaseType?.toLowerCase(),
          host_port: Port,
          host_name: Host,
          db_name: ConnectionName,
          db_username: Username,
          db_password: Password,
          type_create: "manual",
          asp_id: asp_id,
          created_by: "admin",
          updated_by: "admin",
          is_active: true,
        };
        setDBConnect(payload);
        dbTestMutation.mutate(payload);
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleConnectionNameChange = (value: string) => {
    form.setFieldsValue({ ConnectionName: value });
  };

  const handleItemNameChange = (value: string) => {
    form.setFieldsValue({ ItemName: value });
  };

  const handleHostChange = (value: string) => {
    form.setFieldsValue({ Host: value });
  };

  const handlePortChange = (value: string) => {
    form.setFieldsValue({ Port: value });
  };

  const handleUsernameChange = (value: string) => {
    form.setFieldsValue({ Username: value });
  };

  const handlePasswordChange = (value: string) => {
    form.setFieldsValue({ Password: value });
  };

  useEffect(() => {
    form.setFieldsValue({ DatabaseType: valueDatabaseType });
  }, [valueDatabaseType]);

  const values = Form.useWatch([], form);
  useEffect(() => {
    setIsTestConnectionPass(false);
  }, [values]);

  const stepContent2 = () => {
    return (
      <>
        <div className={styles.formContainer}>Database Information</div>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleConnect}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Database Type"
            name="DatabaseType"
            className={styles.formItem}
          >
            <Input
              className={styles.inputField}
              value={valueDatabaseType}
              disabled
            />
          </Form.Item>

          <Form.Item
            name="ItemName"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <span className={styles.labelContainer}>
              Name
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input
              className={styles.inputField}
              onChange={(e) => {
                handleItemNameChange(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="ConnectionName"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <span className={styles.labelContainer}>
              Database Name
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input
              className={styles.inputField}
              onChange={(e) => {
                handleConnectionNameChange(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="Host"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <span className={styles.labelContainer}>
              Host
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input
              className={styles.inputField}
              onChange={(e) => {
                handleHostChange(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="Port"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <span className={styles.labelContainer}>
              Port
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input
              className={styles.inputField}
              onChange={(e) => {
                handlePortChange(e.target.value);
              }}
            />
          </Form.Item>

          <div className={styles.authenticationTitle}>Authentication</div>

          <Form.Item
            name="Username"
            rules={[{ required: true }]}
            className={styles.formItem}
          >
            <span className={styles.labelContainer}>
              Username
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input
              className={styles.inputField}
              onChange={(e) => {
                handleUsernameChange(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="Password"
            rules={[{ required: true }]}
            className={styles.passwordField}
          >
            <span className={styles.labelContainer}>
              Password
              <span className={styles.requiredIndicator}>*</span>
            </span>
            <Input.Password
              className={styles.inputField}
              onChange={(e) => {
                handlePasswordChange(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </>
    );
  };

  const steps: any = [
    {
      title: "1",
      children: stepContent1(),
    },
    {
      title: "2",
      children: stepContent2(),
    },
  ];

  const onClickConnect = () => {
    form.submit();
    setLoadingButtonConnect(true);
  };

  const FooterDrawer = () => {
    return (
      <div>
        {currentStep < steps.length - 1 && (
          <div className={styles.buttonStyle}>
            <Button onClick={() => onClose()}>Cancel</Button>
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          </div>
        )}

        {currentStep === steps.length - 1 && (
          <div className={styles.buttonStyle}>
            <Button
              loading={dbTestMutation?.isPending}
              onClick={() => handleTestConnection()}
            >
              Test Connection
            </Button>
            <Button
              disabled={!isTestConnectionPass}
              type="primary"
              onClick={onClickConnect}
              style={{ margin: "0 8px" }}
              loading={loadingButtonConnect}
            >
              Connect
            </Button>
          </div>
        )}
      </div>
    );
  };

  const dbTestMutation = useMutation({
    mutationFn: dbConnectorsTest,
    onSuccess: () => {
      const values = form.getFieldsValue();
      onDataName(
        values.ConnectionName,
        "Connected",
        "Test Connection Successful"
      );
      showModalSuccess();
      setIsTestConnectionPass(true);
    },
    onError: () => {
      const values = form.getFieldsValue();
      onDataName(
        values.ConnectionName,
        "Connected Fail",
        "Please try to connect again."
      );
      showModalFail();
      setIsTestConnectionPass(false);
    },
  });

  return (
    <Drawer
      title={
        <div className={styles.titleContainer}>
          Database Connection
          <div className={styles.subTitleContainer}>
            <p>{steps[currentStep].title}</p>
            <p
              style={
                currentStep + 1 === steps.length
                  ? { color: "#1C87F2" }
                  : { color: "#ABABAB" }
              }
            >
              /2
            </p>
          </div>
        </div>
      }
      closeIcon={<LeftOutlined />}
      onClose={currentStep > 0 ? prev : onCloseDrawer}
      open={isOpen}
      footer={<FooterDrawer />}
    >
      <div className={styles.customScrollbar}>
        {steps[currentStep].children}
      </div>
    </Drawer>
  );
}
export default DrawerDataConnection;
