import renderBOScreens from 'src/template/renderBoScreens';
import renderLabelScreens from 'src/template/renderLabelScreens';
import renderButtonScreens from 'src/template/renderButtonScreens';
import renderTableContainer from 'src/template/renderTableContainer';
import { BO, BUTTON, ICON, IMAGE, LABEL, TABLE } from 'src/constants/PageConfig';
import renderImageScreens from 'src/template/renderImageScreens';
import renderIconScreens from 'src/template/renderIconScreens';

export default function updateListOneToManyScreens(mainContainer, mainScreen, screensEditWithPosition, filehubUrlImage, pageName, JsonCONFIGSCREEN) {
    const container = document.createElement('div');
    container.id = 'container-edit-list-' + mainScreen.key;

    const containerWrapper = document.createElement('div');
    const backButton = document.createElement('button');
    backButton.id = 'backButton-containerWrapper-' + mainScreen.key;
    backButton.className = 'ant-btn button back-btn';
    backButton.textContent = 'back';

    let maxRow = 0;
    let maxColumn = 0;

    screensEditWithPosition.forEach(screen => {
        const { rownumber, columnnumber } = screen;

        maxRow = Math.max(maxRow, screen.rownumber);
        maxColumn = Math.max(maxColumn, screen.columnnumber);

        containerWrapper.style.display = 'grid';
        containerWrapper.style.gridAutoRows = 'auto';
        containerWrapper.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

        const screenElement = document.createElement('div');
        screenElement.id = 'screenElement-' + screen.configType + '-' + screen.key
        screenElement.style.gridRow = rownumber;
        screenElement.style.gridColumn = columnnumber;
        screenElement.style.padding = '10px';

        if (screen.configType === BO) {
            renderBOScreens(screenElement, screen, filehubUrlImage, "edit");
          } else if (screen.configType === BUTTON) {
            renderButtonScreens(screenElement, screen, "edit", mainScreen, pageName);
          } else if (screen.configType === LABEL) {
            renderLabelScreens(screenElement, screen);
          } else if (screen.configType === TABLE) {
            renderTableContainer(
              screenElement,
              screen,
              mainScreen,
              maxColumn,
              "edit",
              JsonCONFIGSCREEN,
              pageName
            );
          } else if (screen.configType === IMAGE) {
            renderImageScreens(screenElement, screen);
          } else if (screen.configType === ICON) {
            renderIconScreens(screenElement, screen);
          }
        containerWrapper.appendChild(screenElement);
    });
    container.appendChild(backButton);
    container.appendChild(containerWrapper);

    mainContainer.appendChild(container);
}

