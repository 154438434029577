import React from "react";

const AngleDownIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width||"16"} 
        height={height||"17"} 
        viewBox="0 0 16 17" 
        className={className}
        style={style}
        fill="none"
         xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4726 5.97454C12.4106 5.91206 12.3369 5.86246 12.2556 5.82861C12.1744 5.79477 12.0872 5.77734 11.9992 5.77734C11.9112 5.77734 11.8241 5.79477 11.7428 5.82861C11.6616 5.86246 11.5879 5.91206 11.5259 5.97454L8.47256 9.02787C8.41058 9.09035 8.33685 9.13995 8.25561 9.1738C8.17437 9.20764 8.08723 9.22507 7.99923 9.22507C7.91122 9.22507 7.82408 9.20764 7.74284 9.1738C7.6616 9.13995 7.58787 9.09035 7.52589 9.02787L4.47256 5.97454C4.41058 5.91206 4.33685 5.86246 4.25561 5.82861C4.17437 5.79477 4.08723 5.77734 3.99923 5.77734C3.91122 5.77734 3.82408 5.79477 3.74284 5.82861C3.6616 5.86246 3.58787 5.91206 3.52589 5.97454C3.40173 6.09945 3.33203 6.26842 3.33203 6.44454C3.33203 6.62066 3.40173 6.78963 3.52589 6.91454L6.58589 9.97453C6.96089 10.3491 7.46922 10.5594 7.99923 10.5594C8.52923 10.5594 9.03756 10.3491 9.41256 9.97453L12.4726 6.91454C12.5967 6.78963 12.6664 6.62066 12.6664 6.44454C12.6664 6.26842 12.5967 6.09945 12.4726 5.97454Z" fill={fill||"#374957"}  opacity={opacity||''}/>
        </svg>
    );
};

export default AngleDownIcon;
