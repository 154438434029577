import React, { useEffect, useRef, useState } from "react";
import styles from "../user-story/UserStories.module.scss";

import clipboardCopy from "clipboard-copy";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";

import ChatMessage from "../../chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../constants/genstep.constant";

type UserStoriesProps = {
  messages: any;
  setMessages: any;
};

function UserStories({ messages, setMessages }: UserStoriesProps) {
  const editorRef = useRef<any>(null);
  const copyText = () => {
    clipboardCopy(messages);
    // alert('คัดลอกข้อความเรียบร้อยแล้ว!');
  };

  useEffect(() => {
    if (messages) {
      // console.log("UserStories messages>>> ::.", messages);
    }
  }, [messages]);

  return (
    <div className={styles.chatContainer}>
      {messages?.map((msg: any, index: number) => (
        <ChatMessage
          key={index}
          agent={msg?.agent}
          message={msg?.text}
          profileImage={msg?.profileImage}
          chatColor={msg?.chatColor}
          // setMessage={setMessages}
          step={GENERATE_STEP.USER_STORIES}
        />
      ))}
    </div>

    /* <div className={styles.editorContainer}>
            <button className={styles.copyButton} onClick={copyText}>
                <CopyIcon />Copy
            </button>
            <CodeMirror
                className={styles.codeMirror}
                value={code}
                options={{
                    mode: 'javascript',
                    theme: 'Default',
                    lineNumbers: true,
                    lineWrapping: true,
                }}
                onBeforeChange={(editor, data, value) => {
                    setCode(value);
                }}
                editorDidMount={(editor) => {
                    editorRef.current = editor;
                }}
            />
        </div> */
  );
}
export default UserStories;
