import React from "react";

const StoryGenPageArrowRight = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 11.9986C17.9951 11.4725 17.7832 10.9695 17.41 10.5986L13.12 6.29861C12.9326 6.11235 12.6792 6.00781 12.415 6.00781C12.1508 6.00781 11.8974 6.11235 11.71 6.29861C11.6163 6.39157 11.5419 6.50217 11.4911 6.62403C11.4403 6.74589 11.4142 6.87659 11.4142 7.0086C11.4142 7.14062 11.4403 7.27132 11.4911 7.39318C11.5419 7.51504 11.6163 7.62564 11.71 7.7186L15 10.9986H5C4.73478 10.9986 4.48043 11.104 4.29289 11.2915C4.10536 11.479 4 11.7334 4 11.9986C4 12.2638 4.10536 12.5182 4.29289 12.7057C4.48043 12.8932 4.73478 12.9986 5 12.9986H15L11.71 16.2886C11.5217 16.4756 11.4154 16.7297 11.4144 16.9951C11.4135 17.2604 11.518 17.5153 11.705 17.7036C11.892 17.8919 12.1461 17.9982 12.4115 17.9991C12.6768 18.0001 12.9317 17.8956 13.12 17.7086L17.41 13.4086C17.7856 13.0352 17.9978 12.5282 18 11.9986Z"
        fill="#393939"
      />
    </svg>
  );
};
export default StoryGenPageArrowRight;
