import React from "react";

const StoryEmptyCreateIcon = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 30"
      fill="none"
    >
      <rect x="1.25" y="0.5" width="29" height="29" rx="6.5" fill="white" />
      <rect x="1.25" y="0.5" width="29" height="29" rx="6.5" stroke="#ABABAB" />
      <path
        d="M22 13.75H17V8.75C17 8.41848 16.8683 8.10054 16.6339 7.86612C16.3995 7.6317 16.0815 7.5 15.75 7.5C15.4185 7.5 15.1005 7.6317 14.8661 7.86612C14.6317 8.10054 14.5 8.41848 14.5 8.75V13.75H9.5C9.16848 13.75 8.85054 13.8817 8.61612 14.1161C8.3817 14.3505 8.25 14.6685 8.25 15C8.25 15.3315 8.3817 15.6495 8.61612 15.8839C8.85054 16.1183 9.16848 16.25 9.5 16.25H14.5V21.25C14.5 21.5815 14.6317 21.8995 14.8661 22.1339C15.1005 22.3683 15.4185 22.5 15.75 22.5C16.0815 22.5 16.3995 22.3683 16.6339 22.1339C16.8683 21.8995 17 21.5815 17 21.25V16.25H22C22.3315 16.25 22.6495 16.1183 22.8839 15.8839C23.1183 15.6495 23.25 15.3315 23.25 15C23.25 14.6685 23.1183 14.3505 22.8839 14.1161C22.6495 13.8817 22.3315 13.75 22 13.75Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
export default StoryEmptyCreateIcon;
