import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { User } from "src/types/User.type";

// สร้าง async action สำหรับดึงข้อมูลจาก API
//@ts-ignore
let api = window["env"]["REACT_APP_ZERO_URL"];
export const fetchUserByEmail = createAsyncThunk<User>(
  "workflow/fetchUserByEmail",
  async () => {
    const response = await axios.get(api + "/workflow-api/v1/get-user", {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }); // ใส่ endpoint ของ API ที่ต้องการดึงข้อมูล
    return response.data;
  }
);

type UserState = {
  user?: User;
  loading: boolean;
  error?: string;
};

const initialValues: UserState = {
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserByEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to load user";
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = userSlice.actions;
export const userSelector = (store: RootState) => store.userReducer;
export default userSlice.reducer;
