import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import plansReducer from "./slices/plansSlice";
import paymentReducer from "./slices/paymentSlice";
import creditcardReducer from "./slices/creditcardSlice";
import paymentStepperReducer from "./slices/paymentStepperSlice";
import stripeReducer from "./slices/stripeSlice";
import userReducer from "./slices/userSlice";

const reducer = {
  plansReducer,
  paymentReducer,
  creditcardReducer,
  paymentStepperReducer,
  stripeReducer,
  userReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
