import { getAuthorizationPmtx } from "src/services/util.service";
import axios from "axios";

export const apiPath = {
  getUser: "/workflow-api/v1/get-user",
  checkSession: "/workflow-api/v1/check-session",
};

//@ts-ignore
let api = window["env"]["REACT_APP_ZERO_URL"];
export const fetchUserByEmailSvc = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(api + apiPath.getUser, {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      }); // ใส่ endpoint ของ API ที่ต้องการดึงข้อมูล
      // console.log("response",response)
      return resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const callApiGet = async (subPath: any) => {
  return new Promise(async (resolve, reject) => {
    // "/workflow-api/v1/get-user"
    try {
      const response = await axios.get(api + subPath, {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      }); // ใส่ endpoint ของ API ที่ต้องการดึงข้อมูล
      console.log("response", response);
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

// export async function fetchData() {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.get(api + apiPath.checkSession, {
//         headers: { Authorization: getAuthorizationPmtx() },
//       });
//       console.log("Data:", response.data);
//       resolve(response.data);
//     } catch (error: any) {
//       if (error.response) {
//         // If the request was made and the server responded with a status code
//         // outside the range of 2xx
//         console.error("Error response:", error.response.data);
//         console.error("Status code:", error.response.status);

//         if (error.response.status === 401) {
//           console.error("Unauthorized access - please check your credentials.");
//         }
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.error("No response received:", error.request);
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.error("Error message:", error.message);
//       }
//       reject(error);
//     }
//   });
// }
