export let cssText = `

[pg-page-name] {
    height: unset !important;
}

.d-flex{
    display: flex !important;
}

.align-item-center{
    align-items: center !important;
}

.gap10{
    gap: 10px;
}

.avatar {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.notification-img {
    width: 32px;
    height: 32px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.logo-image {
    height: 28px;
}




.navbar {
    /*background: linear-gradient(to right, #316b8d, #003d60);*/
    padding: 15px 20px;
    box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
    margin-bottom: 15px;
    z-index: 1001;
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.justify-content-left {
    justify-content: left;
}

.justify-content-right {
    justify-content: right;
}

.justify-content-center {
    justify-content: center;
}

.logo-container {
    flex: 0 0 auto;
    margin-right: 50px;
}

.menu-container {
    /* flex: 1 1 auto; */
}

.ul-header {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 20px;
}

.nav-li-item {
    margin: 0 10px;
}

.nav-link {
    text-decoration: none;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
}

.nav-link:focus{
    color: #098eff;
}

.nav-link:hover {
    color: #1890ff;
    text-decoration: underline;
}

.dropdown-arrow svg {
    vertical-align: middle;
    /*margin-left: 10px;*/
    stroke: currentColor;
}

.btn-nav-al {
    flex: 0 0 auto;
}

.btn-form {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}
.nav-li-item.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: 10px 0;
    margin: 0;
    min-width: 200px;
    z-index: 1000;
    border-radius: 8px;
    /* transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden; */
}

.nav-li-item.dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu li {
    border-bottom: 1px solid #f1f1f1;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-link {
    display: block;
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-link:hover {
    background-color: #f1f1f1;
    color: #333;
}

.nav-li-item.dropdown:hover .dropdown-menu {
    display: block;
}


/* .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
} */


nav {
    &.primary-navigation {
        /*margin: 0 auto;*/
      display: block;

      /*padding: 120px 0 0 0;*/
      text-align: center;
      font-size: 16px;

      ul li {
        list-style: none;
        margin: 0 auto;
        /* border-left: 2px solid #3ca0e7; */
        display: inline-block;
        padding: 0 10px;
        position: relative;
        text-decoration: none;
        text-align: center;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      }

      li a {
        color: black;
        font-size: 20px;
      }

      li a:hover {
        color: #1C87F2;
      }

      li:hover {
        cursor: pointer;
        background: #F2F2F2;
        border-radius: 5px;
      }

      ul li ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        padding-left: 10px;
        left: 0;
        display: none;
        background: white;
        padding-right: 10px;
      }

      ul li:hover>ul,
      ul li ul:hover {
        visibility: visible;
        opacity: 1;
        display: block;
        min-width: 250px;
        text-align: left;
        padding-top: 20px;
        box-shadow: 0px 3px 5px -1px #ccc;
        border-radius: 13px;
      }

      ul li ul li {
        clear: both;
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
        border-style: none;
      }

      ul li ul li a:hover {
        padding-left: 10px;
        /* border-left: 2px solid #3ca0e7; */
        transition: all 0.3s ease;
      }
    }
  }

  a {

    text-decoration: none;

    &:hover {
      color: #3CA0E7;
    }

  }

  ul li ul li a {
    transition: all 0.5s ease;
  }
  
`;