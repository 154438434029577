import React from "react";

type Props = {
  url: string;
  title?: string;
  width?: string;
  height?: string;
  allowFullScreen?: boolean;
  additionalAttributes?: { [key: string]: string };
};

function ComponentPreviewIframe({
  url,
  title,
  width = "100%",
  height = "100%",
  allowFullScreen = false,
  additionalAttributes = {},
}: Props) {
  return (
    <iframe
      src={url}
      title={title}
      width={width}
      height={height}
      allowFullScreen={allowFullScreen}
      {...additionalAttributes}
      style={{
        border: "none" /* Remove border */,
        outline: "none",
      }}
    />
  );
}

export default ComponentPreviewIframe;
