import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import StoryEditEntAddMoreSetting from "src/assets/svg/story-edit-ent-add-more-setting";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { MoreSettingType } from "src/types/MoreSettingType";
import { INPUT_TYPE_SELECTS } from "src/utils/create-bo/InputTypeSelect";

type Props = {
  moreSettingItem: MoreSettingType;
  openModalConfig: () => void;
  dataReferSelects: { label: string; value: string }[];
  isEditMode: boolean;
};

function MoreSettingItem({
  moreSettingItem,
  openModalConfig,
  dataReferSelects,
  isEditMode
}: Props) {
  let storyEntityContext = useContext(StoryEntityContext);
  let [dataRefer, setDataRefer] = useState<string | undefined>();

  //ONLOAD SET DATA_REFER_ID FROM USECONTEXT DATA_REFER_MAPPING
  useEffect(() => {
    let dataReferMapping = storyEntityContext.dataReferMapping;
    let dataReferMappingObj = dataReferMapping.find((item) => item.id === moreSettingItem.id);
    let dataReferId = dataReferMappingObj?.data_refer_id;
    // SET VALUE EMPTY WHEN CHANGE INPUT TYPE
    const exists = dataReferSelects.some((item) => item.value === dataReferId);
    if (!exists) {
      setDataRefer(""); 
    }else{
      setDataRefer(dataReferId);
    }
  }, [])

  //ACTION HANDLER
  const onChangeDataReferHandler = (dataRef: string) => {
    let moreSettingItems = storyEntityContext.moreSettingItems;
    let newMoreSettingItems = moreSettingItems.map((item) => {
      if (item.id === moreSettingItem.id) {
        return {
          ...item,
          data_refer_id: dataRef,
        };
      } else {
        return item;
      }
    });
    setDataRefer(dataRef);
    console.log("newMoreSettingItems => ", newMoreSettingItems)
    console.log("storyEntityContext.moreSettingItems => ", storyEntityContext.moreSettingItems)
    if(isEditMode){
      let boItemsConfig = storyEntityContext.boConfigItems
      let newBoConfigItems = boItemsConfig.map((item) => {
        if(item.id === moreSettingItem.id){
          return {
            id: item.id,
            isChecked: item.isChecked,
            bo_name: item.bo_name,
            data_type: item.data_type,
            input_type: item.input_type,
            display_view: item.display_view,
            display_edit: item.display_edit,
            show_status: item.show_status,
            ref_id: item.ref_id,
            displayView: item.displayView,
            displayEdit: item.displayEdit,
            enyity_bo_item_id: item.enyity_bo_item_id,
            data_refer_id: dataRef
          }
        }else{
          return item;
        }
      })
      console.log("newBoConfigItems => ", newBoConfigItems)
      storyEntityContext.setBoConfigItems(newBoConfigItems)
    }
    storyEntityContext.setMoreSettingItems(newMoreSettingItems);
  };
  const onClickMoreSettingConfigHandler = () => {
    openModalConfig();
    storyEntityContext.setDataReferConfig({
      id: moreSettingItem.id,
      title: moreSettingItem.bo_name,
      input_type: moreSettingItem.input_type,
    });
  };
  return (
    <tr key={moreSettingItem.id}>
      <td>{moreSettingItem.bo_name}</td>
      <td>{moreSettingItem.input_type}</td>
      <td style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Select
          value={dataRefer}
          variant={"borderless"}
          placeholder={"Please select"}
          style={{ width: 180 }}
          onChange={onChangeDataReferHandler}
          options={dataReferSelects.map((dataRefer: any) => ({
            label: dataRefer.label,
            value: dataRefer.value,
          }))}
        />
        <div onClick={() => onClickMoreSettingConfigHandler()}>
          <StoryEditEntAddMoreSetting style={{ cursor: "pointer" }} />
        </div>
      </td>
    </tr>
  );
}

export default MoreSettingItem;
