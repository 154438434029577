import React, { useEffect, useState } from "react";
import styles from "./SetupTableForComponentTableModal.module.scss";
import { Button, Input, Modal, Select } from "antd";

type Props = {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  onSelectParent: (parentName: string) => void;
  parentList: { value: string; label: string }[];
};

export default function SetupTableForComponentTableModal({
  isModalOpen,
  onOk,
  onCancel,
  onSelectParent,
  parentList,
}: Props) {
  let [parentSelected, setParentSeleted] = useState<string>("");

  //FIX INTEGRATION ISSUE
  useEffect(() => {
    if (isModalOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isModalOpen]);

  //ONLOAD FUNCTION
  useEffect(() => {
    console.log(
      "------------------onload SetupTableForComponentTableModal------------------"
    );
    console.log("parentList => ", parentList);
  }, []);

  //ACTION HANDLER
  const onChangeBoParent = (boParent: string) => {
    console.log("onChangeBoParent boParent => ", boParent)
    setParentSeleted(boParent)
  }

  const actionClickSave = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onSelectParent(parentSelected)
  };

  const actionClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <>
      <Modal
        title={
          <div className={styles.modalTitle}>
            {/* <StoryEditDbRedBin /> */}
            {/* {modalDetail.title} */}
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelBtn}
              key="cancel"
              onClick={(e) => actionClickCancel(e)}
            >
              Cancel
            </Button>
            <Button
              className={styles.confirmBtn}
              key="save"
              onClick={(e) => actionClickSave(e)}
              type="primary"
            >
              Save
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={(e) => actionClickSave(e)}
        onCancel={(e) => actionClickCancel(e)}
        centered={true}
        width={400}
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.modalTitle}>Choose BO Parent</div>
          <div className={styles.modalContent}>
            <div className={styles.inputFieldContainer}>
              <div className={styles.textTopic}>
                <div className={styles.text}>BO Parent</div>
                <div className={styles.textImportant}>*</div>
              </div>
              <div className={styles.inputTabName}>
                <Select
                  value={parentSelected}
                  options={parentList}
                  style={{ width: "100%" }}
                  placeholder={<div>&nbsp;&nbsp;&nbsp;BO Parent</div>}
                  onChange={onChangeBoParent}
                  onClick={(e) => e.stopPropagation()}
                  optionFilterProp="children"
                  className={styles.genTableSelect}
                  // disabled={isTypeObject ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
