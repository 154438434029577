import React, { useContext, useEffect, useRef, useState } from "react";
import { MappingParam } from "src/shared/MappingParam/MappingParam";
import styles from "../../modal/modal-create-entity/CreateStoryEntityModal.module.scss";
import { Button, Select } from "antd";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import { BoMappingInit } from "src/utils/bo-initial-data/BoMappingInit";
import {
  ConnectorTableType,
  GenerateTableNodeBOResponse,
  GetAllDBConnectByStoryIDResponse,
  GetTableAndColumnResponse,
  TableType,
} from "src/types/response.type";
import useFetch from "src/hooks/useFetch";
import {
  GENERATE_TABLE_NODE_ENDPOINT,
  GET_DB_CONNECT_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { useParams } from "react-router";
import {
  GenerateTableNodeBORequest,
  GetTableAndColumnRequest,
} from "src/types/request.type";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import MessageNofify from "../../notification/MessageNotify";
import { checkEmpty } from "src/services/util.service";

type Props = {
  mappingParam: MappingParam;
};

type SelectProps = { value: string; label: string };

function CreateBusinessObjectStep1({ mappingParam }: Props) {
  let params = useParams();
  let storyEntityContext = useContext(StoryEntityContext);
  const { loading, error, responseData, fetchData, fetchKey } = useFetch<
    | GetAllDBConnectByStoryIDResponse
    | GetTableAndColumnResponse
    | GenerateTableNodeBOResponse
  >();
  let [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  let [selectedTable, setSelectedTable] = useState<string[]>([]);
  let [selectTableItems, setSelectTableItems] = useState<SelectProps[]>([]);
  let [tableColumnItems, setTableColumnItems] = useState<TableType[]>([]);
  let [isOpenRemoveModal, setIsOpenRemoveModal] = useState<boolean>(false);
  let [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  //MESSAGE NOTIFY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  const boMappingDiagram = useRef(null);

  const GET_DB_CONNECT_FK = "getDbConnectFK";
  const GET_TABLE_COL_FK = "getTablColFK";
  const GET_GEN_TABLE_FK = "getGenTablFK";

  //GET DB_CONNNECTION AND BO_INIT ONLOAD
  useEffect(() => {
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*"); //FIX INTEGRATTION
    getDBConnectByStoryIdController();
    if (isFirstTime) {
      mappingParam.init(boMappingDiagram.current);
      setIsFirstTime(false);
    }
    mappingParam.loadDataToDiagram(structuredClone(BoMappingInit));
    mappingParam.setBoMapping([]);

    let newNodeDataArray = [];
    let newLinkDataArray = [];
    for (const createBoField of storyEntityContext.boConfigItems) {
      if (createBoField.data_type == "Object") {
        let parentKey = checkEmpty(createBoField.parent_id)
          ? "boKeyInit"
          : createBoField.parent_id;
        let pathName = getPathName(
          storyEntityContext.boConfigItems,
          createBoField.parent_id,
          createBoField.bo_name
        );
        let margin = 30;
        let pathNames = pathName.split(".");
        margin = margin + (pathNames.length - 1) * 20;
        let newNodeData = {
          paramName: createBoField.bo_name || "",
          pathName: pathName,
          margin: `0 0 0 ${margin}`,
          typeDesc: createBoField.data_type,
          isList: false,
          category: "nodeIsGroup",
          isGroup: false,
          key: createBoField.id,
          parentKey: parentKey,
          group: "boGroup",
        };
        newNodeDataArray.push(newNodeData);
        newLinkDataArray.push({ from: parentKey, to: createBoField.id });
      } else {
        let pathName = getPathName(
          storyEntityContext.boConfigItems,
          createBoField.parent_id,
          createBoField.bo_name
        );
        let margin = 30;
        let pathNames = pathName.split(".");
        margin = margin + (pathNames.length - 1) * 20;
        let newNodeData = {
          paramName: createBoField.bo_name || "",
          pathName: pathName,
          margin: `0 0 0 ${margin}`,
          typeDesc: convertDataType(createBoField.data_type),
          isList: false,
          category: "nodeIsNotGroup",
          isGroup: false,
          key: createBoField.id,
          parentKey: createBoField.parent_id,
          group: "boGroup",
        };
        newNodeDataArray.push(newNodeData);
        newLinkDataArray.push({
          from: createBoField.parent_id,
          to: createBoField.id,
        });
      }
    }
    mappingParam.autoGenBo(newNodeDataArray, newLinkDataArray);
  }, []);

  const getPathName = (
    boConfigItems: any[],
    parent_id: string,
    currentName: string
  ): string => {
    if (checkEmpty(parent_id)) return `${currentName}`;
    for (let a = 0; a < boConfigItems.length; a++) {
      let item = boConfigItems[a];
      if (item.id === parent_id) {
        let parentPath = getPathName(
          boConfigItems,
          item.parent_id as string,
          item.bo_name as string
        );
        if (checkEmpty(parentPath)) return `${currentName}`;
        else return `${parentPath}.${currentName}`;
      }
    }
    return "";
  };

  const convertDataType = (dataType: string): string => {
    switch (dataType) {
      case "Varchar":
        return "String";
      case "Datetime":
        return "Datetime";
      case "Integer":
        return "Integer";
      case "Decimal":
        return "Decimal";
      case "Boolean":
        return "Boolean";
      default:
        return "Object";
    }
  };

  //ON RESPONSE SUCCESS BY FETCHKEY
  useEffect(() => {
    if (!loading) {
      if (error) return;

      console.log("fetchKey => ", fetchKey);
      switch (fetchKey) {
        case GET_DB_CONNECT_FK: {
          let respGetAllDBConnect = responseData?.data as unknown[];
          if (respGetAllDBConnect?.length === 0) return;
          respGetAllDBConnect = respGetAllDBConnect.map((item: any) => {
            return {
              ...item,
              is_active: Boolean(item.is_active),
            };
          });
          let reqGetTableCol: GetTableAndColumnRequest =
            respGetAllDBConnect[0] as GetTableAndColumnRequest;
          storyEntityContext.setDbConnectorRequest(reqGetTableCol);
          getTableAndColumnController(reqGetTableCol);
          setIsLoadingTable(true)
          break;
        }
        case GET_TABLE_COL_FK: {
          let respGetTableCol: any = responseData?.data;
          let allTable = respGetTableCol?.table || [];
          let tableSelects: SelectProps[] = [...(allTable as unknown[])].map(
            (item: any) => {
              return {
                label: item.table_name as string,
                value: item.table_name as string,
              };
            }
          );
          setTableColumnItems(allTable as unknown as TableType[]);
          setSelectTableItems(tableSelects);
          setIsLoadingTable(false)
          break;
        }
        case GET_GEN_TABLE_FK: {
          let respGenTables =
            responseData?.data as unknown as ConnectorTableType;
          console.log("respGenTables => ", respGenTables);
          let respTableCols = respGenTables?.name_tabl;
          setTableColumnItems((prev) => [...prev, ...respTableCols]);
          let newSelectTableItems = respTableCols.map((item) => {
            return {
              value: item.table_name,
              label: item.table_name,
            };
          });
          setSelectTableItems((prev) => [...prev, ...newSelectTableItems]);

          let newSelectTables = respTableCols.map((item) => item.table_name);
          setSelectedTable(newSelectTables);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //ONCHANGE SELECT_TABLE
  useEffect(() => {
    if (selectedTable.length > 0) {
      if (!tableColumnItems) return;
      let tableColObjs: TableType[] = tableColumnItems.filter((item) =>
        selectedTable.includes(item.table_name)
      );
      console.log("onchange select_table => ", tableColObjs);
      mappingParam.setTableByTableColumn(tableColObjs);
    } else {
      mappingParam.updateBoMappingOnRemoveTable();
    }
  }, [selectedTable]);

  //ACTION HANDLER
  const generateTableByBoHandler = () => {
    let tableColumnReq = mappingParam.getRequestTableColListFromNodeDataArray();
    if (tableColumnReq.length === 0) {
      generateTableNoItemError();
      return;
    }

    let dbConnector = storyEntityContext.dbConnectorRequest;
    if (tableColumnReq.length === 0) return;

    let reqGenerateTableNodeBo: GenerateTableNodeBORequest;
    reqGenerateTableNodeBo = {
      connector: dbConnector,
      name_tabl: tableColumnReq as TableType[],
    };
    getGenerateTableByBoController(reqGenerateTableNodeBo);
  };
  const removeTableHandler = () => {
    setIsOpenRemoveModal(true);
  };
  const onChangeTableHandler = (value: string[]) => {
    setSelectedTable(value);
  };

  //API CONTROLLER FUNCTION
  const getDBConnectByStoryIdController = () => {
    fetchData(
      {
        endpoint: GET_DB_CONNECT_ENDPOINT,
        method: "GET",
        params: {
          story_id: getStoryId(),
        },
      },
      GET_DB_CONNECT_FK
    );
  };

  const getTableAndColumnController = (req: GetTableAndColumnRequest) => {
    fetchData(
      {
        endpoint: GET_TABLE_COL_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GET_TABLE_COL_FK
    );
  };

  const getGenerateTableByBoController = (req: GenerateTableNodeBORequest) => {
    fetchData(
      {
        endpoint: GENERATE_TABLE_NODE_ENDPOINT,
        method: "POST",
        reqBody: req,
      },
      GET_GEN_TABLE_FK
    );
  };

  //UTILITY FUNCTION
  const getStoryId = () => {
    return params.id;
  };
  const generateTableNoItemError = () => {
    setTriggerNotify(!triggerNotify);
    setTitleNotify("Generate Table Failed!");
    setDescriptionNotify("Please add BusinessObject item");
    setIsSuccessNotify(false);
  };

  //REMOVE MODAL HANDLER
  const onOkRemoveModalHandler = () => {
    setIsOpenRemoveModal(false);
    setSelectedTable([]);
  };
  const onCancelRemoveModalHandler = () => {
    setIsOpenRemoveModal(false);
  };

  return (
    <div className={styles.createBusinessObjContainer}>
      <div className={styles.boTableMappingContainer}>
        <div style={{ height: "100%", width: "100%" }}>
          <div
            key={"cboMappingKey"}
            style={{ height: "100%", width: "100%" }}
            ref={boMappingDiagram}
          ></div>
        </div>
      </div>
      <div className={styles.genTableContainer}>
        <Button
          className={styles.genTableBtn}
          key="gen_table"
          onClick={() => generateTableByBoHandler()}
          loading={isLoadingTable}
        >
          Generate Table From BO
        </Button>
        <div className={styles.selectWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.textHeader}>Please select table</span>
            <span className={styles.textImportant}>*</span>
          </div>
          <div className={styles.actionWrapper}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              mode="multiple"
              value={selectedTable}
              options={selectTableItems}
              style={{ width: "100%", height: "auto" }}
              placeholder={<div>&nbsp;&nbsp;&nbsp;Table</div>}
              onChange={onChangeTableHandler}
              optionFilterProp="children"
              className={styles.genTableSelect}
              loading={selectTableItems.length > 0 ? false : true}
            />
            <div
              className={styles.actionIconWrapper}
              onClick={() => removeTableHandler()}
            >
              <StoryEditEntDelete className={styles.actionIcon} />
            </div>
          </div>
        </div>
      </div>

      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteModal
        modalDetail={{
          title: "Remove table select?",
          description: `Are you sure you want to remove table select? This action cannot be
      undone.`,
        }}
        isModalOpen={isOpenRemoveModal}
        onOk={onOkRemoveModalHandler}
        onCancel={onCancelRemoveModalHandler}
      />

      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
    </div>
  );
}

export default CreateBusinessObjectStep1;
