import React, { useEffect, useRef, useState } from "react";

type Props = {
  itemStyles: string;
  itemTextStyles: string;
  item: any;
};

function ControlItem({ itemStyles, item, itemTextStyles }: Props) {
  let [isDragging, setIsDragging] = useState<boolean>(false);
  const dragRef = useRef<HTMLDivElement>(null);

  const onDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    componentId: string
  ) => {
    console.log("onDragStart e ", e);
    setIsDragging(true);
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({ componentId, componentType: "control" })
    );
  };
  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    console.log("Drag ended e ", e);
    setIsDragging(false);
  };

  return (
    <div
      ref={dragRef}
      key={item.id}
      className={itemStyles}
      draggable
      onDragStart={(e) => onDragStart(e, item.id)}
      onDragEnd={(e) => onDragEnd(e)}
      style={{
        cursor: isDragging ? "grabbing" : "grab",
        fontSize: item?.id === "ct2" ? "10px" : "14px",
      }}
    >
      {item.icon}
      <span className={itemTextStyles}>{item.controlName}</span>
    </div>
  );
}

export default ControlItem;
