import StoryGenpageTile from "src/assets/png/story-genpage-tile.png";
import StoryGenpageCard from "src/assets/png/story-genpage-card.png";
import StoryGenpageTable from "src/assets/png/story-genpage-table.png";
import StoryGenpageList from "src/assets/png/story-genpage-list.png";

const CHOOSE_TEMPLATE_ITEMS = [
  {
    groupId: 1,
    titleName: "List",
    groupItems: [
      { id: "l1", img: StoryGenpageTile, title: "Tile", value: "list_1" },
      { id: "l2", img: StoryGenpageCard, title: "Card", value: "list_2" },
      { id: "l3", img: StoryGenpageTable, title: "Table", value: "list_3" },
      { id: "l4", img: StoryGenpageList, title: "List", value: "list_4" },
    ],
    groupItemSelected: "list_1",
  },
  {
    groupId: 2,
    titleName: "Create & Update",
    groupItems: [
      { id: "c1", img: StoryGenpageTile, title: "Form", value: "cnu_1" },
      { id: "c2", img: StoryGenpageCard, title: "Tab", value: "cnu_2" },
      {
        id: "c3",
        img: StoryGenpageTable,
        title: "Breadcrumb",
        value: "cnu_3",
      },
    ],
    groupItemSelected: "cnu_1",
  },
];

const SEARCH_BO_FIELD_SELECTS = [
  { value: "customer_id", label: "customer_id" },
  { value: "name", label: "name" },
  { value: "start_date", label: "start_date" },
  { value: "province", label: "province" },
];

const SEARCH_RESULT_SORT_BY_SELECTS = [
  { value: "asc", label: "ASC" },
  { value: "desc", label: "DESC" },
];

const CREATE_EDIT_BO_FIELD_ITEMS = [
  { id: "bf1", boField: "customer_id" },
  { id: "bf2", boField: "name" },
  { id: "bf3", boField: "description" },
  { id: "bf4", boField: "ccv number" },
  { id: "bf5", boField: "email" },
  { id: "bf6", boField: "start_date" },
  { id: "bf7", boField: "option" },
  { id: "bf8", boField: "phone_no" },
  { id: "bf9", boField: "province" },
  { id: "bf10", boField: "file" },
  { id: "bf11", boField: "image" },
];

export {
  CHOOSE_TEMPLATE_ITEMS,
  SEARCH_BO_FIELD_SELECTS,
  SEARCH_RESULT_SORT_BY_SELECTS,
  CREATE_EDIT_BO_FIELD_ITEMS,
};
