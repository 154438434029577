import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import styles from "../modal/ModalDelete.module.scss";
import DatabaseErrorIcon from "src/assets/svg/database-error-circle";

type ModalDeleteProps = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  title: string;
  description: string;
  additionalInfo?: string;
  okText?: string;
  cancelText?: string;
};

function ModalDelete({
  open,
  onCancel,
  onOk,
  title,
  description,
  additionalInfo,
  okText = "OK",
  cancelText = "Cancel",
}: ModalDeleteProps) {
  let [loadingButtonConfirm, setLoadingButtonConfirm] =
    useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setLoadingButtonConfirm(false);
    }
  }, [open]);

  const onClickConfirm = () => {
    setLoadingButtonConfirm(true);
    onOk();
  };

  return (
    <Modal
      open={open}
      width={400}
      footer={[
        <Button
          key="cancel"
          onClick={onCancel}
          style={{ width: "calc(50% - 5px)", marginRight: "5px" }}
        >
          {cancelText}
        </Button>,
        <Button
          key="ok"
          type="primary"
          danger
          onClick={onClickConfirm}
          style={{ width: "calc(50% - 5px)", marginLeft: "5px" }}
          loading={loadingButtonConfirm}
        >
          {okText}
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <div className={styles.modalContainer}>
        <DatabaseErrorIcon />
        <div className={styles.titleText}>{title}</div>
        <div className={styles.descriptionText}>
          <div>{description}</div>
          {additionalInfo && <div>{additionalInfo}</div>}
        </div>
      </div>
    </Modal>
  );
}
export default ModalDelete;
