export function formatISODateInReact(isoDateString: string) {
    const dateObject = new Date(isoDateString);
    const formattedDate = dateObject.toLocaleString();
    return formattedDate;
}

export function formatISODateToCustomFormat(isoDateString: string) {
    console.log("isoDateString:: ", isoDateString);

    const dateObject = new Date(isoDateString);
    const options = {
        year: 'numeric' as const,
        month: 'short' as const,
        day: 'numeric' as const,
        hour: 'numeric' as const,
        minute: 'numeric' as const,
        // second: 'numeric' as const,
    };
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
    return formattedDate;
}