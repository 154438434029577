import React from "react";

const LineListIcon = (props) => {
    const { className, style, width, height, fill, opacity } = props;
    return (
        <svg 
        width={width||"16" }
        height={height||"17" }
        viewBox="0 0 16 17" 
        className={className}
        style={style}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3281_11282)">
                <path d="M4.66667 4.5013H15.3333C15.5101 4.5013 15.6797 4.43106 15.8047 4.30604C15.9298 4.18101 16 4.01144 16 3.83463C16 3.65782 15.9298 3.48825 15.8047 3.36323C15.6797 3.23821 15.5101 3.16797 15.3333 3.16797H4.66667C4.48986 3.16797 4.32029 3.23821 4.19526 3.36323C4.07024 3.48825 4 3.65782 4 3.83463C4 4.01144 4.07024 4.18101 4.19526 4.30604C4.32029 4.43106 4.48986 4.5013 4.66667 4.5013Z" fill={fill||"#ABABAB"} opacity={opacity||''}/>
                <path d="M15.3333 7.83203H4.66667C4.48986 7.83203 4.32029 7.90227 4.19526 8.02729C4.07024 8.15232 4 8.32189 4 8.4987C4 8.67551 4.07024 8.84508 4.19526 8.97011C4.32029 9.09513 4.48986 9.16537 4.66667 9.16537H15.3333C15.5101 9.16537 15.6797 9.09513 15.8047 8.97011C15.9298 8.84508 16 8.67551 16 8.4987C16 8.32189 15.9298 8.15232 15.8047 8.02729C15.6797 7.90227 15.5101 7.83203 15.3333 7.83203Z" fill={fill||"#ABABAB"} opacity={opacity||''}/>
                <path d="M15.3333 12.5H4.66667C4.48986 12.5 4.32029 12.5702 4.19526 12.6953C4.07024 12.8203 4 12.9899 4 13.1667C4 13.3435 4.07024 13.5131 4.19526 13.6381C4.32029 13.7631 4.48986 13.8333 4.66667 13.8333H15.3333C15.5101 13.8333 15.6797 13.7631 15.8047 13.6381C15.9298 13.5131 16 13.3435 16 13.1667C16 12.9899 15.9298 12.8203 15.8047 12.6953C15.6797 12.5702 15.5101 12.5 15.3333 12.5Z" fill={fill||"#ABABAB"} opacity={opacity||''}/>
                <path d="M1.33333 5.16666C2.06971 5.16666 2.66667 4.56971 2.66667 3.83333C2.66667 3.09695 2.06971 2.5 1.33333 2.5C0.596954 2.5 0 3.09695 0 3.83333C0 4.56971 0.596954 5.16666 1.33333 5.16666Z" fill={fill||"#ABABAB"} opacity={opacity||''} />
                <path d="M1.33333 9.83463C2.06971 9.83463 2.66667 9.23768 2.66667 8.5013C2.66667 7.76492 2.06971 7.16797 1.33333 7.16797C0.596954 7.16797 0 7.76492 0 8.5013C0 9.23768 0.596954 9.83463 1.33333 9.83463Z" fill={fill||"#ABABAB"} opacity={opacity||''}/>
                <path d="M1.33333 14.4987C2.06971 14.4987 2.66667 13.9017 2.66667 13.1654C2.66667 12.429 2.06971 11.832 1.33333 11.832C0.596954 11.832 0 12.429 0 13.1654C0 13.9017 0.596954 14.4987 1.33333 14.4987Z" fill={fill||"#ABABAB"} opacity={opacity||''}/>
            </g>
            <defs>
                <clipPath id="clip0_3281_11282">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default LineListIcon;
