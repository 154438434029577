import React, { useContext, useEffect, useState } from "react";
import { BoConfigType } from "src/types/BoConfigType";
import BoObjectConfigItem from "./BoObjectConfigItem";
import { MappingParam } from "src/shared/MappingParam/MappingParam";
import { StoryEntityContext } from "src/page/context/StoryEntity.context";
import { StoryBoConfigResponse } from "src/types/response.type";

import { StoryBoConfigRequest } from "src/types/request.type";
import {
  ADD_BO_CONFIG_ENDPOINT,
  REMOVE_BO_CONFIG_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import { useParams } from "react-router";
import { STATUS_OK } from "src/constants/ResponseStatus";
import useFetch from "src/hooks/useFetch";
import { idText } from "typescript";

type Props = {
  mappingParam: MappingParam;
  isRerender: boolean;
  isFromUploadExcel: boolean;
  arrBOConfigItems: any[];
  isEditMode: boolean;
};

const ADD_BO_CONFIG_ITEM_FK = "addBoItemFK";
const REMOVE_STORY_ENTITY_FK = "removeStEntFK";

function BusinessObjectConfigStep2({ isRerender, isFromUploadExcel ,arrBOConfigItems, isEditMode}: Props) {
  let params = useParams();
  const { loading, error, responseData, fetchDataAsync, fetchKey } =
    useFetch<StoryBoConfigResponse[]>();
  let storyEntityContext = useContext(StoryEntityContext);
  let [boConfigItemsState, setBoConfigItemsState] = useState<BoConfigType[]>(
    []
  );
  let [rowSelected, setRowSelected] = useState<string>("");

  //--CHECK STATE RERENDER FROM PARENT COMPONENT
  useEffect(() => {
    window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*"); //FIX INTEGRATTION

    let boConfigItems = [...storyEntityContext.boConfigItems];
    setBoConfigItemsState(boConfigItems);
  }, [isRerender]);

  //--CHECK STATE FROM UPLOAD EXCEL OR EDIT MODE
  useEffect(() => {
    if((isFromUploadExcel || isEditMode) && !isRerender){
      console.log('--------- +++ ---------')
      console.log("data from ..", arrBOConfigItems)
      // storyEntityContext.setBoConfigItems([arrBOConfigItems]);
      storyEntityContext.setBoConfigItems(arrBOConfigItems);
      setBoConfigItemsState(arrBOConfigItems);
    }else if(isEditMode && isRerender){
      console.log('--------- +++ ---------')
      console.log('boConfigItems => ', storyEntityContext.boConfigItems)
      setBoConfigItemsState(storyEntityContext.boConfigItems)
    }
  }, [isFromUploadExcel, isEditMode]);

  //--CHECK RESPONSE BY STATE USEFETCH FROM CALL API SUCCESS
  useEffect(() => {
    if (!loading) {
      if (error || !responseData) return;

      switch (fetchKey) {
        case ADD_BO_CONFIG_ITEM_FK: {
          //GET NEW RESPONSE FROM ARRAY RESPONSE OF FETCH ASYNC
          let newResp = getCombineResponseFromFetchAsync(responseData);

          let nextStep = storyEntityContext.currentStep + 1;
          if (newResp?.status === STATUS_OK) {
            let savedBoConfigItems = newResp.data;
            console.log("savedBoConfigItems : ", savedBoConfigItems)
            let newBoConfigItems = savedBoConfigItems.map((item: any) => {
              // console.log("item.id = ", item.id)
              return {
                ...item,
                enyity_bo_item_id: item.id,
                show_status: item.show_status == "true" ? true : false,
              };
            });
            storyEntityContext.setBoConfigItems(newBoConfigItems);
            storyEntityContext.setCurrentStep(nextStep);
            storyEntityContext.setIsBoConfigSave(false);
            console.log("BoConfigItems repons : ", storyEntityContext.boConfigItems)
          }
          break;
        }
        case REMOVE_STORY_ENTITY_FK: {
          let removeId = storyEntityContext.boConfigRowSelectedID;
          updateBoConfigItemsByRemoveId(removeId);
          break;
        }
        default: {
          console.log(`Unknown fetchKey: ${fetchKey}`);
        }
      }
    }
  }, [loading, responseData]);

  //--CHECK STATE TRIGGER {isBoConfigSave} FROM PARENT COMPONENT
  useEffect(() => {
    if (!storyEntityContext.isBoConfigSave) return;

    let boConfigItems = storyEntityContext.boConfigItems;
    if (boConfigItems.length === 0) {
      storyEntityContext.setIsBoConfigSave(false);

      let nextStep = storyEntityContext.currentStep + 1;
      storyEntityContext.setCurrentStep(nextStep);
      return;
    }

    //GET DATA FROM BOCONFIG LIST SET TO REQUEST FORM
    console.log("save item boConfigItems => ", boConfigItems);
    let reqBodys = boConfigItems.map((item) => {
      return {
        ...item,
        story_id: params.id as string,
        show_status: item.show_status.toString(),
        display_edit: item.displayEdit,
        display_view: item.displayView,
      };
    });

    let reqInsert: StoryBoConfigRequest = {
      action: "create",
      data: [],
    };
    let reqUpdate: StoryBoConfigRequest = {
      action: "update",
      data: [],
    };

    //SEPERATE BY enyity_bo_item_id IN OBJECT
    for (const reqBody of reqBodys) {
      reqBody.enyity_bo_item_id
        ? reqUpdate.data.push(reqBody)
        : reqInsert.data.push(reqBody);
    }

    let resultReq: StoryBoConfigRequest[] = [];
    if (reqInsert.data.length > 0) resultReq.push(reqInsert);
    if (reqUpdate.data.length > 0) resultReq.push(reqUpdate);
    console.log("resultReq : ", resultReq)
    addBoConfigItemsAsyncController(resultReq);
  }, [storyEntityContext.isBoConfigSave]);

  //--CHECK STATE TRIGGER {isBoConfigRemove} FROM PARENT COMPONENT
  useEffect(() => {
    let removeId = storyEntityContext.boConfigRowSelectedID;
    let isRemove = storyEntityContext.isBoConfigRemove;
    if (!removeId || !isRemove) return;

    let boConfigItems = storyEntityContext.boConfigItems;
    // console.log("boConfigItems on remove => ", boConfigItems)
    let isSaved = boConfigItems.some(
      (item) => item.id === removeId && item.enyity_bo_item_id === removeId
    );

    isSaved
      ? removeStoryEntityController(removeId)
      : updateBoConfigItemsByRemoveId(removeId);
  }, [storyEntityContext.isBoConfigRemove]);

  //API CONTROLLER FUNCTION
  const addBoConfigItemsAsyncController = (
    inputReq: StoryBoConfigRequest[]
  ) => {
    let request: any = inputReq.map((req) => {
      return {
        endpoint: ADD_BO_CONFIG_ENDPOINT,
        method: "POST",
        reqBody: req,
      };
    });
    fetchDataAsync(request, ADD_BO_CONFIG_ITEM_FK);
  };
  const removeStoryEntityController = (itemToDelete: string) => {
    let request: any = {
      endpoint: REMOVE_BO_CONFIG_ENDPOINT,
      method: "DELETE",
      params: {
        id: itemToDelete,
      },
    };

    fetchDataAsync([request], REMOVE_STORY_ENTITY_FK);
  };

  //UTILITY FUNCTION
  const selectRowHandler = (itemId: string) => {
    setRowSelected(itemId);
    storyEntityContext.setBoConfigRowSelectedID(itemId);
  };

  const getCombineResponseFromFetchAsync = (resp: StoryBoConfigResponse[]) => {
    let respDatas: any = resp.map((item) => item.data);
    let combinedDataArray = [].concat(...respDatas);
    return {
      status: resp[0]?.status,
      message: resp[0]?.message,
      data: [...combinedDataArray],
    };
  };

  const updateBoConfigItemsByRemoveId = (removeId: string) => {
    let boConfigItems = storyEntityContext.boConfigItems;
    let newBoConfigItems = boConfigItems.filter((item) => item.id !== removeId);

    setBoConfigItemsState(newBoConfigItems);
    storyEntityContext.setBoConfigItems(newBoConfigItems);
    storyEntityContext.setIsBoConfigRemove(false);
    storyEntityContext.setBoConfigRowSelectedID("");
  };

  return (
    <>
      <table id="BoObjectConfigTb">
        <thead>
          <tr>
            {/* <th style={{width: '15px'}}></th> */}
            <th>Field Name</th>
            <th>Data Type</th>
            <th>Input Type</th>
            <th>Display (View)</th>
            <th>Display (Edit)</th>
            <th>Hidden</th>
            <th>Parent</th>
          </tr>
        </thead>
        <tbody>
          {boConfigItemsState.map((item, index) => {
            return (
              <BoObjectConfigItem
                key={item.id}
                boObjectItem={item}
                onSelectRow={selectRowHandler}
                rowSelected={rowSelected}
                listData={boConfigItemsState}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default BusinessObjectConfigStep2;
