import React, { useState, useEffect, useContext } from "react";
import { Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "./Login.module.scss";
import TextChild from "./TextChild";
import axios from "axios";

import { useTranslation } from "react-i18next";
import { AppContext } from "../context/App.context";
import { CONFIG_SCREEN, generatePageFile, testFn } from "src/services/page-service";
function Test(props) {

  useEffect(() => {
    // generatePageFile(CONFIG_SCREEN)
    testFn()
  }, []);

  return (
    <div>

    </div>
  );
}

export default Test;
