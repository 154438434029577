import React, { useEffect, useReducer, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Drawer, Input, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./StoryDrawer.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (newStoryName: string) => void;
};

function StoryDrawer({ isOpen, onClose, onCreate }: Props) {
  let [inputStoryName, setInputStoryName] = useState<string>("");
  let [loadingButtonCreate, setLoadingButtonCreate] = useState<boolean>(false);

  useEffect(() => {
    setLoadingButtonCreate(false);
    if (isOpen) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }
  }, [isOpen]);

  const onCreateHandler = () => {
    setLoadingButtonCreate(true);
    if (inputStoryName) {
      onCreate(inputStoryName);
      setInputStoryName("");
      return;
    }
    onClose();
  };
  const FooterDrawer = () => {
    const isDisabled = !inputStoryName;
    return (
      <div className={styles.footerContainer}>
        <Button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={styles.btnCreate}
          onClick={onCreateHandler}
          disabled={isDisabled}
          loading={loadingButtonCreate}
        >
          Create
        </Button>
      </div>
    );
  };
  return (
    <>
      <Drawer
        title="Create New Story"
        closeIcon={<LeftOutlined />}
        onClose={onClose}
        open={isOpen}
        footer={<FooterDrawer />}
      >
        <div className={styles.textInputContainer}>
          <p className={styles.textHeader}>Story Name</p>
          <span className={styles.textImportant}>*</span>
        </div>
        <Input
          className={styles.inputStory}
          placeholder="Story Name"
          value={inputStoryName}
          onChange={(e) => setInputStoryName(e.currentTarget.value)}
        />
      </Drawer>
    </>
  );
}

export default StoryDrawer;
