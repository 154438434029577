import axios from "axios";
import {
  CreateDBConnectorsProps,
  DBConnectorsTestProps,
  DeleteDBConnectorsByIdProps,
  GetAllDBConnectorsByAspIDProps,
  GetAllDBConnectorsProps,
  GetDBConnectorsByIdProps,
  UpdateDBConnectorsByIdProps,
} from "src/types/databaseConnectors.type";
import { getToken } from "./util.service";

// const token = getToken();
// const api = axios.create({
//   baseURL: `${window['env']['REACT_APP_ZERO_URL']}/api/v1/`,
// headers: {
//   Authorization: `Bearer ${getToken()}`,
// },
// });

const api = axios.create({
  //@ts-ignore
  baseURL: `${window["env"]["REACT_APP_ZERO_URL"]}/api/v1/`,
});

api.interceptors.request.use(
  function (config) {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export async function dbConnectorsTest(payload: DBConnectorsTestProps) {
  try {
    const res = await api.post("database-connectors-test", payload);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function createDBConnectors(payload: CreateDBConnectorsProps) {
  try {
    const res = await api.post("database-connectors", payload);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function deleteDBConnectorsById({
  id,
}: DeleteDBConnectorsByIdProps) {
  try {
    const res = await api.delete(`database-connectors?id=${id}`);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function updateDBConnectorsById({
  id,
  ...payload
}: UpdateDBConnectorsByIdProps) {
  try {
    const res = await api.patch(`database-connectors?id=${id}`, payload);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function getDBConnectorsById({ id }: GetDBConnectorsByIdProps) {
  try {
    const res = await api.get(`database-connectors?id=${id}`);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function getAllDBConnectors({
  page,
  pageSize,
  search,
  sortOrder,
}: GetAllDBConnectorsProps) {
  try {
    const queryString = `database-connectors?page=${page}&pageSize=${pageSize}&search=${search}&sortOrder=${sortOrder}`;
    const res = await api.get(queryString);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function getAllDBConnectorsByAspID({
  asp_id,
  page = 1,
  pageSize = 10,
  search = "",
  sortOrder = "DESC",
  filter,
}: GetAllDBConnectorsByAspIDProps) {
  try {
    let whereFilter = "";
    if (filter?.search("DBType_") == 0) {
      whereFilter = `db_type=${filter?.split("_")[1]}`;
    } else if (filter?.search("Owner_") == 0) {
      whereFilter = `owner=${filter?.split("_")[1]}`;
    }

    // const whereFilter = `&db_type=${"dbType"}`;
    const queryString = `database-connectors/asp-id?asp_id=${asp_id}&page=${page}&pageSize=${pageSize}&search=${search}&sortOrder=${sortOrder}&${whereFilter}`;
    const res = await api.get(queryString);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}

export async function jndis() {
  try {
    const res = await api.get("jndis");
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
}
