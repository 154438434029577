import {ParameterTemplate} from "../ParameterTemplate";
import * as go from "gojs";
//@ts-ignore
// let go = window.go;
export function TreeNode() {
	//@ts-ignore
	go.Node.call(this);
}

go.Diagram.inherit(TreeNode, go.Node);


/** @override */
TreeNode.prototype.findVisibleNode = function () {
	let n = this;
	while (n !== null && !n.isVisible()) {
		n = n.findTreeParentNode();
	}
	return n;
};

export class ParamIsNotGroup {

	// AbstractParameter(this);

	public linkable() {
		return { fromLinkable: true, toLinkable: true };
	};

	public createButtonEvent($$: any, paramTemplate: ParameterTemplate) {
		return {};
	};

	public createParameter($$: any, paramTemplate: ParameterTemplate) {
		return $$(TreeNode,
			{
				movable: false
				, selectionChanged: function (part: any) {
					// MyDiagramState.startTransaction("Edit Visible");
					// MyDiagramState.model.setDataProperty(part.data, "visible", part.isSelected ? true : false);
					// MyDiagramState.commitTransaction("Edit Visible");
					
				}
			}
			, this.linkable()
			, $$(go.Panel, "Vertical"
				, $$(go.Panel, "Horizontal"
					, { width: 350, contextMenu: $$(go.Adornment), height: 28 }
					, $$(go.Panel, "Horizontal"
						, { width: 200 }
						, $$(go.TextBlock
							, { alignment: go.Spot.Left, font: "12px sans-serif", stroke: "#000000" }
							, new go.Binding("text", "paramName", function (s: string, o: any) {
								if (s.length >= 35)
									return s.substring(0, 25) + "...";
								return s;
							})
							, new go.Binding("margin", "margin", go.Margin.parse)
							, new go.Binding("stroke", "willBeMapping", function (s: boolean) { return s ? "#113F8C" : "#000000"; })
							, new go.Binding("font", "willBeMapping", function (s: boolean) { return s ? "bold 12px sans-serif" : "12px sans-serif"; })
						)
						, this.createButtonEvent($$, paramTemplate)
					)

					, $$(go.Panel, "Horizontal"
						, { width: 80 }
						, $$(go.TextBlock
							, { alignment: go.Spot.Left, font: "12px sans-serif", stroke: "#778CA2" }
							, new go.Binding("text", "typeDesc", function (s: string, o: any) {
								return o.part.data.isList ? "List " + s + "" : "" + s + "";
							})
							, new go.Binding("stroke", "willBeMapping", function (s: string) { return s ? "#113F8C" : "#778CA2"; })
							, new go.Binding("font", "willBeMapping", function (s: string) { return s ? "bold 12px sans-serif" : "12px sans-serif"; })
						)
					)
				) // end Horizontal Panel				
				, $$(go.Panel, "Horizontal"
					, $$(go.Shape, "LineH", { stroke: "#FFFFFF", width: 350, height: 1 })
				)
			)
		); // end Node

	};



};

// ParamIsNotGroup.prototype = Object.create(AbstractParameter.prototype);

