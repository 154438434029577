import React from "react";
import StoryEditEntDelete from "src/assets/svg/story-edit-ent-del";
import styles from "./StyleTool.module.scss"

type Props = {
  isShow: boolean;
  top: number;
  left: number;
  onClick: () => void;
};

function BoFieldTool({ isShow, top, left, onClick }: Props) {
  const onClickRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick();

  }

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "absolute",
            top: top + "px",
            left: left + "px",
          }}
          className={styles.boFieldToolContainer}
           onClick={(e) => onClickRemove(e)}
        >
          <StoryEditEntDelete />
        </div>
      )}
    </>
  );
}

export default BoFieldTool;
