import React from "react";

const StoryEditEntGenPageIcon = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      viewBox="0 0 14 13"
      width={width || "14"}
      height={height || "13"}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.419 0.748718H7.58565C7.43094 0.748718 7.28257 0.810176 7.17317 0.919573C7.06378 1.02897 7.00232 1.17734 7.00232 1.33205V3.23955L5.60232 0.812885C5.50016 0.63472 5.35277 0.486681 5.17506 0.383729C4.99735 0.280778 4.79561 0.226562 4.59024 0.226562C4.38486 0.226562 4.18312 0.280778 4.00541 0.383729C3.8277 0.486681 3.68031 0.63472 3.57815 0.812885L0.0781519 6.87371C0.0269537 6.96239 0 7.06298 0 7.16538C0 7.26777 0.0269537 7.36837 0.0781519 7.45704C0.129546 7.54606 0.20355 7.61992 0.29267 7.67114C0.381791 7.72236 0.482863 7.74912 0.585652 7.74871H3.08815C2.97489 8.12721 2.91789 8.5203 2.91899 8.91537C2.91899 9.99834 3.34919 11.037 4.11497 11.8027C4.88074 12.5685 5.91935 12.9987 7.00232 12.9987C8.08529 12.9987 9.1239 12.5685 9.88967 11.8027C10.6554 11.037 11.0857 9.99834 11.0857 8.91537C11.0867 8.5203 11.0297 8.12721 10.9165 7.74871H13.419C13.5737 7.74871 13.7221 7.68725 13.8315 7.57786C13.9409 7.46846 14.0023 7.32009 14.0023 7.16538V1.33205C14.0023 1.17734 13.9409 1.02897 13.8315 0.919573C13.7221 0.810176 13.5737 0.748718 13.419 0.748718ZM1.59482 6.58204L4.58732 1.39622L6.58815 4.83205C6.00478 4.89697 5.44226 5.0869 4.93893 5.3889C4.4356 5.69089 4.0033 6.09785 3.67149 6.58204H1.59482ZM7.00232 11.832C6.42546 11.832 5.86155 11.661 5.38191 11.3405C4.90226 11.02 4.52843 10.5645 4.30767 10.0315C4.08691 9.49858 4.02915 8.91214 4.1417 8.34636C4.25424 7.78058 4.53202 7.26089 4.93992 6.85298C5.34783 6.44508 5.86753 6.16729 6.43331 6.05475C6.99908 5.94221 7.58553 5.99997 8.11848 6.22073C8.65143 6.44148 9.10695 6.81532 9.42744 7.29496C9.74793 7.77461 9.91899 8.33851 9.91899 8.91537C9.91899 9.68892 9.6117 10.4308 9.06471 10.9778C8.51773 11.5247 7.77587 11.832 7.00232 11.832ZM12.8357 6.58204H10.3507C9.82091 5.82573 9.05267 5.26906 8.16899 5.00121V1.91538H12.8357V6.58204Z"
        fill="#374957"
      />
    </svg>
  );
};
export default StoryEditEntGenPageIcon;
