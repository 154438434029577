import React from 'react';
import { Link, useResolvedPath } from 'react-router-dom';
import TopicRoutes from '../../routes/TopicRoutes';

function Topics() {
  const url = useResolvedPath("").pathname;

  return (
    <div>
      <h2>Topics</h2>

      <ul>
        <li>
          <Link to={`${url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>
     
     <TopicRoutes/>
    </div>
  );
}

export default Topics;