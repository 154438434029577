import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Radio, Form, Select, InputNumber } from "antd";
import styles from "./CreateDataReferModal.module.scss";
import EditImg from "../../../../../assets/svg/story-edit";
import AddImg from "../../../../../assets/svg/story-edit-ent-add";
import {
  callApiMethodGet,
  callApiMethodPost,
  getAspId,
} from "src/services/util.service";
import {
  SAVE_AND_UPDATE_DATA_REFER_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
  GET_DATA_CONNECTOR_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";

const FormItem = Form.Item;
const Option = Select.Option;

const InputTypeValues = {
  checkbox: "checkbox",
  radio: "radio",
  dropdown: "dropdown",
};

const DataReferTypeValues = {
  FROM_TABLE: "FROM_TABLE",
  DATA_LIST: "DATA_LIST",
};

type Props = {
  isOpenModal: boolean;
  data: { dataReferName: string; inputType: string };
  RefaceAPIGetDataByAspID: any;
  onClose: () => void;
};

type DataFromList = {
  data_value: string;
  data_view: string;
  default_check: string;
  seq: number;
};

type DataConnectorModel = {
  label: string;
  value: string;
  DataConnectorID: string;
  JndiName: string;
  ConnectionUrl: string;
  DBType: string;
  HostPort: string;
  HostName: string;
  DBName: string;
  DBUserName: string;
  DBPassword: string;
  TypeCreate: string;
};

type SelectedTableName = {
  value: string;
  NameColumn: any;
};

type SelectColumnName = {
  value: string;
  label: string;
};

function CreateDataReferModal({
  isOpenModal,
  data,
  RefaceAPIGetDataByAspID,
  onClose,
}: Props) {
  let [open, setOpen] = useState(isOpenModal);
  const [inputs, setInputs] = useState<DataFromList[]>([
    { data_value: "", data_view: "", seq: 1, default_check: "Yes" },
  ]);

  let [dataReferType, setDataReferType] = useState<string>(
    DataReferTypeValues.DATA_LIST
  );
  let [modalName, setModalName] = useState<string>("");
  let [inputDataReferName, setInputDataReferName] = useState<string>("");
  let [inputConnectionID, setInputConnectionID] = useState<string>("");
  let [inputTableName, setInputTableName] = useState<string>("");
  let [inputColumnName, setInputColumnName] = useState<string>("");
  let [inputColumnValue, setInputColumnValue] = useState<string>("");
  let [inputConditionValue, setInputConditionValue] = useState<string>("");
  let [inputAdvanceSearch, setInputAdvanceSearch] = useState<string>("");
  let [selectedTableName, setSelectedTableName] = useState<SelectedTableName[]>(
    []
  );
  const [isNotificationSuccess, setIsNotificationSuccess] =
    useState<boolean>(false);
  const [selectDataConnectorModel, setSelectDataConnectorModel] = useState<
    DataConnectorModel[]
  >([]);
  const [selectColumnName, setSelectColumnName] = useState<SelectColumnName[]>(
    []
  );
  const [editDataReferName, setEditDataReferName] = useState<boolean>(false);
  const [loadingTableName, setLoadingTableName] = useState<boolean>(false);
  const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

  useEffect(() => {
    setLoadingButtonSave(false);
    setOpen(isOpenModal);
    setEditDataReferName(false);
    setInputDataReferName(data.dataReferName);
    setDataReferType(DataReferTypeValues.DATA_LIST);
    setInputs([
      { data_value: "", data_view: "", seq: 1, default_check: "Yes" },
    ]);

    setInputTableName("");
    setInputColumnName("");
    setInputColumnValue("");
    setInputConditionValue("");

    if (isOpenModal) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }

    if (data.inputType == InputTypeValues.dropdown) {
      setModalName("Dropdown Configuration");
    } else if (data.inputType == InputTypeValues.radio) {
      setModalName("Data Radio Configuration");
    } else {
      setModalName("Data Checkbox Configuration");
    }
    GetDataConnectorBYAspID();
  }, [isOpenModal]);

  const GetDataConnectorBYAspID = async () => {
    try {
      const response = await callApiMethodGet(GET_DATA_CONNECTOR_ENDPOINT, {
        asp_id: getAspId(),
      });

      if (response.status == 200) {
        const newData = response.data.map((item: any) => ({
          label: item.item_name !== "" ? item.item_name : item.db_name,
          value: item.id,

          DataConnectorID: item.id,
          JndiName: item.jndi_name,
          ConnectionUrl: item.connection_url,
          DBType: item.db_type,
          DBTypeNew: item.db_type_new,
          HostPort: item.host_port,
          HostName: item.host_name,
          DBName: item.db_name,
          DBUserName: item.db_username,
          DBPassword: item.db_password,
          TypeCreate: item.type_create,
        }));
        setSelectDataConnectorModel(newData);
      }
    } catch (error) {
      setSelectDataConnectorModel([]);
    }
  };

  const GetDataTableAndColumn = async (id: string) => {
    const data = selectDataConnectorModel.find(
      (item) => item.DataConnectorID === id
    );

    try {
      const response = await callApiMethodPost(GET_TABLE_COL_ENDPOINT, {
        jndi_name: data?.JndiName,
        connection_url: data?.ConnectionUrl,
        db_type: data?.DBType,
        host_port: data?.HostPort,
        host_name: data?.HostName,
        db_name: data?.DBName,
        db_username: data?.DBUserName,
        db_password: data?.DBPassword,
        type_create: data?.TypeCreate,
        asp_id: getAspId(),
      });

      if (response.status == 200) {
        const newData = response.data.table.map((item: any) => ({
          value: item.table_name,
          NameColumn: item.name_column,
        }));
        setSelectedTableName(newData);
        setLoadingTableName(false);
      }
    } catch (error) {
      setSelectedTableName([]);
    }
  };

  const APISavaDataRefer = async () => {
    try {
      const response = await callApiMethodPost(
        SAVE_AND_UPDATE_DATA_REFER_ENDPOINT,
        {
          action: "create",
          asp_id: getAspId(),
          data_refer_name: inputDataReferName,
          input_type: data.inputType,
          data_refer_type: dataReferType,
          data_from_list: inputs,
          data_from_table: {
            connection_id: inputConnectionID,
            table_name: inputTableName,
            column_name: inputColumnName,
            column_value: inputColumnValue,
            condition_value: inputConditionValue,
            advance_search: inputAdvanceSearch,
          },
        }
      );

      if (response.status == 200) {
        await RefaceAPIGetDataByAspID();
        setIsNotificationSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCreateHandler = () => {
    setLoadingButtonSave(true);
    APISavaDataRefer();
    onClose();
  };

  const handleInputChange = (index: number, fieldName: string, value: any) => {
    const newInputs = [...inputs]; // สร้างสำเนาของอาร์เรย์ inputs
    newInputs[index] = { ...newInputs[index], [fieldName]: value };
    setInputs(newInputs);
  };

  const handleChangeInputConnection = (selectValue: string) => {
    setInputConnectionID(selectValue);
    GetDataTableAndColumn(selectValue);
    setLoadingTableName(true);
  };

  const handleChangeInputTableName = (selectValue: string) => {
    const nameColumn = selectedTableName.find(
      (item) => item.value === selectValue
    );
    const newDataSelectColumn = nameColumn?.NameColumn.map((item: any) => ({
      value: item.column_name,
    }));
    setInputTableName(selectValue);
    setSelectColumnName(newDataSelectColumn);
  };

  const handleChangeInputColumnName = (selectValue: string) => {
    setInputColumnName(selectValue);
  };

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      { data_value: "", data_view: "", seq: 1, default_check: "Yes" },
    ]);
  };

  const FooterDrawer = () => {
    return (
      <div className={styles.footerContainer}>
        <Button className={styles.btnCancel} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={styles.btnCreate}
          onClick={onCreateHandler}
          loading={loadingButtonSave}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        className={styles.contentModal}
        title={modalName}
        visible={open}
        onCancel={onClose}
        width={1000}
        footer={<FooterDrawer />}
      >
        <div
          className={styles.container}
          style={{
            marginTop: "25px",
            fontWeight: "bolder",
            display: "flex",
            alignItems: "center",
          }}
        >
          {editDataReferName == true ? (
            <>
              <Input
                type="text"
                value={inputDataReferName}
                style={{ width: 200 }}
                onChange={(e) => {
                  setInputDataReferName(e.target.value);
                }}
              />
            </>
          ) : (
            <>{inputDataReferName}</>
          )}
          <div
            style={{ marginLeft: "10px" }}
            onClick={() => setEditDataReferName(true)}
          >
            <EditImg width={"20px"} height={"20px"} />
          </div>
        </div>

        {data.inputType == "dropdown" && (
          <Radio.Group
            style={{
              display: "flex",
              padding: "25px",
              alignItems: "flex-start",
            }}
            onChange={(e) => setDataReferType(e.target.value)}
            value={dataReferType}
          >
            <Radio value={DataReferTypeValues.DATA_LIST}>Data List</Radio>

            <Radio value={DataReferTypeValues.FROM_TABLE}>
              Select form Table
            </Radio>
          </Radio.Group>
        )}

        {dataReferType == DataReferTypeValues.DATA_LIST ? (
          <>
            <div
              className={styles.container}
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                fontWeight: "bolder",
              }}
            >
              <div>Value</div>
              <div>Show Name</div>
              <div>SEQ</div>
              <div>Default Check</div>
              <Button
                className={styles.iconAdd}
                icon={<AddImg />}
                onClick={handleAddInput}
              >
                Add
              </Button>
            </div>
            {inputs.map((item, index) => (
              <div
                className={styles.container}
                style={{ marginTop: "10px", marginLeft: "20px" }}
                key={index}
              >
                <Input
                  type="text"
                  value={item.data_value}
                  onChange={(e) =>
                    handleInputChange(index, "data_value", e.target.value)
                  }
                />
                <Input
                  type="text"
                  value={item.data_view}
                  onChange={(e) =>
                    handleInputChange(index, "data_view", e.target.value)
                  }
                />
                <InputNumber
                  type="number"
                  min={1}
                  value={item.seq}
                  onChange={(value) => handleInputChange(index, "seq", value)}
                />
                <Select
                  style={{ width: 120, height: 27 }}
                  value={item.default_check}
                  onChange={(value) =>
                    handleInputChange(index, "default_check", value)
                  }
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </div>
            ))}
          </>
        ) : (
          <Form className={styles.containerSelectFormTable}>
            <FormItem
              label="Connection"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                style={{ width: 320 }}
                placeholder="Connection"
                onChange={handleChangeInputConnection}
                optionFilterProp="children"
                options={selectDataConnectorModel}
              />
            </FormItem>
            <FormItem
              label="Table Name"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                style={{ width: 320 }}
                placeholder="Table Name"
                onChange={handleChangeInputTableName}
                optionFilterProp="children"
                options={selectedTableName}
                loading={loadingTableName}
              />
            </FormItem>
            <FormItem
              label="Column Show"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                style={{ width: 320 }}
                placeholder="Column Show"
                onChange={handleChangeInputColumnName}
                optionFilterProp="children"
                options={selectColumnName}
              />
            </FormItem>
            <FormItem
              label="Column Value"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Input
                type="text"
                onChange={(e) => setInputColumnValue(e.target.value)}
              />
            </FormItem>
            <FormItem
              label="Condition Value"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Input
                type="text"
                onChange={(e) => setInputConditionValue(e.target.value)}
              />
            </FormItem>
          </Form>
        )}
      </Modal>
      {isNotificationSuccess === true && (
        <CreateEntitySuccessNotify isCreated={"componentNameCreated"} />
      )}
    </>
  );
}

export default CreateDataReferModal;
