import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Radio,
  Form,
  Select,
  InputNumber,
  Grid,
  Flex,
} from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import styles from "./CreateDataReferModal.module.scss";
import EditImg from "../../../../../assets/svg/story-edit";
import AddImg from "../../../../../assets/svg/story-edit-ent-add";
import DeleteIcon from "../../../../../assets/svg/delete";
import EditIcon from "../../../../../assets/svg/editIcon";
import {
  callApiMethodGet,
  callApiMethodPost,
  callApiMethodDelete,
  getAspId,
} from "src/services/util.service";
import {
  SAVE_AND_UPDATE_DATA_REFER_ENDPOINT,
  GET_TABLE_COL_ENDPOINT,
  GET_DATA_REFER_BY_ID_ENDPOINT,
  DELETE_DATA_REFER_LIST_ENDPOINT,
  GET_DATA_CONNECTOR_ENDPOINT,
} from "src/utils/endpoint/createbo.endpoint";
import ModalDelete from "src/page/component/database-connection/modal/ModalDelete";
import CreateEntitySuccessNotify from "src/components/zero/zero-story/notification/CreateEntitySuccessNotify";
const FormItem = Form.Item;
const Option = Select.Option;

const InputTypeValues = {
  checkbox: "checkbox",
  radio: "radio",
  dropdown: "dropdown",
};

const DataReferTypeValues = {
  FROM_TABLE: "FROM_TABLE",
  DATA_LIST: "DATA_LIST",
};

type Props = {
  dataReferID: string;
  isOpenModal: boolean;
  onClose: () => void;
  RefaceApi: any;
};

type SelectedTableName = {
  value: string;
  NameColumn: any;
};

type DataList = {
  data_refer_list_id: string;
  data_value: string;
  data_view: string;
  default_check: string;
  seq: number;
};

type DataConnectorModel = {
  label: string;
  value: string;
  DataConnectorID: string;
  JndiName: string;
  ConnectionUrl: string;
  DBType: string;
  DBTypeNew: string;
  HostPort: string;
  HostName: string;
  DBName: string;
  DBUsername: string;
  DBPassword: string;
  TypeCreate: string;
};

type SelectColumnName = {
  value: string;
  label: string;
};

function EditDataReferModal({
  dataReferID,
  isOpenModal,
  onClose,
  RefaceApi,
}: Props) {
  let [open, setOpen] = useState(isOpenModal);
  const [inputsDataList, setInputsDataList] = useState<DataList[]>([]);

  let [dataReferType, setDataReferType] = useState<string>("");
  let [modalName, setModalName] = useState<string>("");
  let [inputDataReferName, setInputDataReferName] = useState<string>("");
  let [inputType, setInputType] = useState<string>("");
  let [inputDataReferTableID, setInputDataReferTableID] = useState<string>("");
  let [inputConnectionID, setInputConnectionID] = useState<string | undefined>(
    ""
  );
  let [inputConnectionName, setInputConnectionName] = useState<
    string | undefined
  >("");
  let [inputTableName, setInputTableName] = useState<string>("");
  let [inputColumnName, setInputColumnName] = useState<string>("");
  let [inputColumnValue, setInputColumnValue] = useState<string>("");
  let [inputConditionValue, setInputConditionValue] = useState<string>("");
  const [selectedTableName, setSelectedTableName] = useState<
    SelectedTableName[]
  >([]);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [isNotificationSuccess, setIsNotificationSuccess] =
    useState<boolean>(false);
  const [deleteDataReferListID, setDeleteDataReferListID] =
    useState<string>("");
  const [selectDataConnectorModel, setSelectDataConnectorModel] = useState<
    DataConnectorModel[]
  >([]);
  const [selectColumnName, setSelectColumnName] = useState<SelectColumnName[]>(
    []
  );
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [editDataReferName, setEditDataReferName] = useState<boolean>(false);

  const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

  useEffect(() => {
    setLoadingButtonSave(false);
    setEditingIndex(-1);
    setEditDataReferName(false);

    setOpen(isOpenModal);
    if (isOpenModal) {
      window.parent.postMessage({ event: "zero_open_modal", key: "" }, "*");
    } else {
      window.parent.postMessage({ event: "zero_close_modal", key: "" }, "*");
    }

    if (inputType == InputTypeValues.dropdown) {
      setModalName("Dropdown Configuration");
    } else if (inputType == InputTypeValues.radio) {
      setModalName("Data Radio Configuration");
    } else {
      setModalName("Data Checkbox Configuration");
    }
    GetDataReferByID();
    GetDataConnectorBYAspID();
  }, [isOpenModal]);

  const GetDataConnectorBYAspID = async () => {
    try {
      const response = await callApiMethodGet(GET_DATA_CONNECTOR_ENDPOINT, {
        asp_id: getAspId(),
      });

      if (response.status == 200) {
        const newData = response.data.map((item: any) => ({
          label: item.item_name !== "" ? item.item_name : item.db_name,
          value: item.id,

          DataConnectorID: item.id,
          JndiName: item.jndi_name,
          ConnectionUrl: item.connection_url,
          DBType: item.db_type,
          DBTypeNew: item.db_type_new,
          HostPort: item.host_port,
          HostName: item.host_name,
          DBName: item.db_name,
          DBUsername: item.db_user_name,
          DBPassword: item.db_password,
          TypeCreate: item.type_create,
        }));
        setSelectDataConnectorModel(newData);
      }
    } catch (error) {
      setSelectDataConnectorModel([]);
    }
  };

  const GetDataTableAndColumn = async (id: string) => {
    const data = selectDataConnectorModel.find(
      (item) => item.DataConnectorID === id
    );

    try {
      const response = await callApiMethodPost(GET_TABLE_COL_ENDPOINT, {
        jndi_name: data?.JndiName,
        connection_url: data?.ConnectionUrl,
        db_type: data?.DBType,
        db_type_new: data?.DBTypeNew,
        host_port: data?.HostPort,
        host_name: data?.HostName,
        db_name: data?.DBName,
        db_username: data?.DBUsername,
        db_password: data?.DBPassword,
        type_create: data?.TypeCreate,
        asp_id: getAspId(),
      });

      if (response.status == 200) {
        const newData = response.data.table.map((item: any) => ({
          value: item.table_name,
          NameColumn: item.name_column,
        }));
        setSelectedTableName(newData);
      }
    } catch (error) {
      setSelectedTableName([]);
    }
  };

  const APISavaDataRefer = async () => {
    try {
      const response = await callApiMethodPost(
        SAVE_AND_UPDATE_DATA_REFER_ENDPOINT,
        {
          action: "update",
          data_refer_id: dataReferID,
          data_refer_name: inputDataReferName,
          asp_id: localStorage.getItem("asp_id"),
          input_type: inputType,
          data_refer_type: dataReferType,
          data_from_list: inputsDataList,
          data_from_table: {
            data_refer_table_id: inputDataReferTableID,
            connection_id: inputConnectionID,
            table_name: inputTableName,
            column_name: inputColumnName,
            column_value: inputColumnValue,
            condition_value: inputConditionValue,
          },
        }
      );

      if (response.status == 200) {
        await RefaceApi();
        await GetDataReferByID();
        setIsNotificationSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDataReferByID = async () => {
    try {
      const response = await callApiMethodGet(GET_DATA_REFER_BY_ID_ENDPOINT, {
        data_refer_id: dataReferID,
      });

      if (response.status == 200) {
        const getConnectionName = selectDataConnectorModel.find(
          (item) => item.value === response.data.data_from_table.connection_id
        );
        setInputConnectionName(getConnectionName?.label);

        setInputDataReferName(response.data.data_refer_name);
        setInputType(response.data.input_type);
        setDataReferType(response.data.data_refer_type);
        setInputDataReferTableID(
          response.data.data_from_table.data_refer_table_id
        );

        setInputTableName(response.data.data_from_table.table_name);
        setInputColumnName(response.data.data_from_table.column_name);
        setInputColumnValue(response.data.data_from_table.column_value);
        setInputConditionValue(response.data.data_from_table.condition_value);
        const newDataList = response.data.data_from_list.map((item: any) => ({
          data_refer_list_id: item.data_refer_list_id,
          data_value: item.data_value,
          data_view: item.data_view,
          default_check: item.default_check,
          seq: item.seq,
        }));
        setInputsDataList(newDataList);
      }
    } catch (error) {
      setInputsDataList([]);
    }
  };

  const APIDeleteDataRefList = async (id: string) => {
    try {
      const response = await callApiMethodDelete(
        DELETE_DATA_REFER_LIST_ENDPOINT,
        {
          id: id,
        }
      );

      if (response.status == 200) {
        GetDataReferByID();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCreateHandler = () => {
    setLoadingButtonSave(true);
    APISavaDataRefer();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleInputChange = (index: number, fieldName: string, value: any) => {
    const newInputs = [...inputsDataList]; // สร้างสำเนาของอาร์เรย์ inputs
    newInputs[index] = { ...newInputs[index], [fieldName]: value };
    setInputsDataList(newInputs);
  };

  const handleAddInput = () => {
    setInputsDataList([
      {
        data_refer_list_id: "",
        data_value: "",
        data_view: "",
        seq: 1,
        default_check: "Yes",
      },
      ...inputsDataList,
    ]);
  };

  function handleDeleteDataReferList(id: string) {
    setOpenModalDelete(true);
    setDeleteDataReferListID(id);
  }

  const handleOk = () => {
    APIDeleteDataRefList(deleteDataReferListID);
    setOpenModalDelete(false);
  };

  const handleEditButtonClick = (index: number) => {
    setEditingIndex(index); // เมื่อคลิกปุ่มแก้ไขให้เปิด Input
  };

  const handleChangeInputConnection = (selectValue: string) => {
    setInputConnectionID(selectValue);
    setInputConnectionName(selectValue);
    GetDataTableAndColumn(selectValue);
  };

  const handleChangeInputTableName = (selectValue: string) => {
    const nameColumn = selectedTableName.find(
      (item) => item.value === selectValue
    );
    const newDataSelectColumn = nameColumn?.NameColumn.map((item: any) => ({
      value: item.column_name,
    }));
    setInputTableName(selectValue);
    setSelectColumnName(newDataSelectColumn);
  };

  const handleChangeInputColumnName = (selectValue: string) => {
    setInputColumnName(selectValue);
  };

  const handleEditDataReferName = () => {
    setEditDataReferName(true);
  };

  const FooterDrawer = () => {
    return (
      <div className={styles.footerContainer}>
        <Button className={styles.btnCancel} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={styles.btnCreate}
          onClick={onCreateHandler}
          loading={loadingButtonSave}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        title={modalName}
        visible={open}
        onCancel={handleCancel}
        width={1000}
        footer={<FooterDrawer />}
      >
        <div
          className={styles.container}
          style={{
            marginTop: "25px",
            fontWeight: "bolder",
            display: "flex",
            alignItems: "center",
          }}
        >
          {editDataReferName == true ? (
            <>
              <Input
                type="text"
                value={inputDataReferName}
                style={{ width: 200 }}
                onChange={(e) => {
                  setInputDataReferName(e.target.value);
                }}
              />
            </>
          ) : (
            <>{inputDataReferName}</>
          )}
          <div onClick={handleEditDataReferName} style={{ marginLeft: "10px" }}>
            <EditImg width={"20px"} height={"20px"} />
          </div>
        </div>

        {dataReferType == DataReferTypeValues.DATA_LIST ? (
          <>
            <div
              className={styles.container}
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                fontWeight: "bolder",
              }}
            >
              <div>Value</div>
              <div>Show Name</div>
              <div>SEQ</div>
              <div>Default Check</div>
              <Button
                className={styles.iconAdd}
                icon={<AddImg />}
                onClick={handleAddInput}
              >
                Add
              </Button>
            </div>

            {inputsDataList.map((item, index) => (
              <div
                className={styles.container}
                style={{ marginTop: "10px", marginLeft: "20px" }}
                key={index}
              >
                {editingIndex === index || item.data_refer_list_id == "" ? (
                  <Input
                    type="text"
                    value={item.data_value}
                    onChange={(e) =>
                      handleInputChange(index, "data_value", e.target.value)
                    }
                  />
                ) : (
                  <span>{item.data_value}</span>
                )}
                {editingIndex === index || item.data_refer_list_id == "" ? (
                  <Input
                    type="text"
                    value={item.data_view}
                    onChange={(e) =>
                      handleInputChange(index, "data_view", e.target.value)
                    }
                  />
                ) : (
                  <span>{item.data_view}</span>
                )}
                {editingIndex === index || item.data_refer_list_id == "" ? (
                  <InputNumber
                    type="number"
                    min={1}
                    value={item.seq == 0 ? 1 : item.seq}
                    onChange={(value) => handleInputChange(index, "seq", value)}
                  />
                ) : (
                  <span>{item.seq}</span>
                )}
                {editingIndex === index || item.data_refer_list_id == "" ? (
                  <Select
                    value={item.default_check}
                    onChange={(value) =>
                      handleInputChange(index, "default_check", value)
                    }
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                ) : (
                  <span>{item.default_check}</span>
                )}
                {item.data_refer_list_id != "" && (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                      onClick={() => handleEditButtonClick(index)}
                    >
                      <EditIcon />
                    </div>
                    <div
                      onClick={() =>
                        handleDeleteDataReferList(item.data_refer_list_id)
                      }
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <Form className={styles.containerSelectFormTable}>
            <FormItem
              label="Connection"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                value={inputConnectionName}
                style={{ width: 320 }}
                placeholder="Connection"
                onChange={handleChangeInputConnection}
                optionFilterProp="children"
                options={selectDataConnectorModel}
              />
            </FormItem>
            <FormItem
              label="Table Name"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                value={inputTableName}
                style={{ width: 320 }}
                placeholder="Table Name"
                onChange={handleChangeInputTableName}
                optionFilterProp="children"
                options={selectedTableName}
              />
            </FormItem>
            <FormItem
              label="Column Show"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Select
                showSearch
                value={inputColumnName}
                style={{ width: 320 }}
                placeholder="Column Show"
                onChange={handleChangeInputColumnName}
                optionFilterProp="children"
                options={selectColumnName}
              />
            </FormItem>
            <FormItem
              label="Column Value"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Input
                type="text"
                value={inputColumnValue}
                onChange={(e) => setInputColumnValue(e.target.value)}
              />
            </FormItem>
            <FormItem
              label="Condition Value"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
            >
              <Input
                type="text"
                value={inputConditionValue}
                onChange={(e) => setInputConditionValue(e.target.value)}
              />
            </FormItem>
          </Form>
        )}
      </Modal>
      <ModalDelete
        title="Delete Data List ?"
        description="Are you sure want to delete this Data List?"
        additionalInfo="This action cannot be undone."
        okText="Confirm"
        onCancel={() => {
          setOpenModalDelete(false);
        }}
        onOk={() => handleOk()}
        open={openModalDelete}
      />
      {isNotificationSuccess === true && (
        <CreateEntitySuccessNotify isCreated={"componentNameCreated"} />
      )}
    </>
  );
}

export default EditDataReferModal;
