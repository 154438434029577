import React from "react";

const StoryEditEntEdit = (props) => {
  const { className, style, width, height } = props;
  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      className={className}
      style={style}
      viewBox="0 0 14 14"
    //   fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1287 1.12108C12.7605 0.753391 12.2614 0.546875 11.741 0.546875C11.2206 0.546875 10.7215 0.753391 10.3532 1.12108L1.54355 9.93074C1.29127 10.1816 1.09124 10.48 0.955028 10.8087C0.818818 11.1373 0.749134 11.4898 0.750008 11.8455V12.9576C0.750008 13.1012 0.807076 13.239 0.908659 13.3406C1.01024 13.4422 1.14802 13.4992 1.29167 13.4992H2.40372C2.75946 13.5002 3.11187 13.4307 3.44055 13.2946C3.76923 13.1584 4.06764 12.9585 4.31851 12.7062L13.1287 3.89603C13.4962 3.52781 13.7026 3.02881 13.7026 2.50856C13.7026 1.9883 13.4962 1.4893 13.1287 1.12108ZM3.55259 11.9403C3.24709 12.2438 2.83432 12.4147 2.40372 12.4159H1.83334V11.8455C1.83279 11.6321 1.87459 11.4206 1.95632 11.2234C2.03805 11.0262 2.15808 10.8472 2.30947 10.6967L8.99526 4.01087L10.2411 5.2567L3.55259 11.9403ZM12.3623 3.13012L11.0048 4.48808L9.75901 3.24495L11.117 1.88699C11.1988 1.80537 11.2958 1.74065 11.4027 1.69655C11.5095 1.65244 11.6239 1.6298 11.7395 1.62993C11.855 1.63006 11.9695 1.65294 12.0762 1.69728C12.1829 1.74162 12.2798 1.80654 12.3614 1.88835C12.4431 1.97015 12.5078 2.06723 12.5519 2.17404C12.596 2.28085 12.6186 2.39531 12.6185 2.51087C12.6184 2.62643 12.5955 2.74083 12.5512 2.84755C12.5068 2.95426 12.4419 3.0512 12.3601 3.13283L12.3623 3.13012Z"
        // fill="#ABABAB"
      />
    </svg>
  );
};
export default StoryEditEntEdit;
