import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type PaymentState = {
  paymentMthod: string;
  loading: boolean;
};

const initialValues: PaymentState = {
  paymentMthod: "creditCard",
  loading: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialValues,
  reducers: {
    setPaymentMethod: (state: PaymentState, data: PayloadAction<string>) => {
      state.paymentMthod = data.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setPaymentMethod } = paymentSlice.actions;
export const paymentSelector = (store: RootState) => store.paymentReducer;
export default paymentSlice.reducer;
