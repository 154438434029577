import React, { useEffect } from "react";
import { notification } from "antd";
import StoryEditEntCreated from "src/assets/svg/story-edit-ent-created";
import styles from "./CreateEntitySuccessNotify.module.scss";

type Props = {
  isCreated: string;
};

function CreateEntitySuccessNotify({ isCreated }: Props) {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (isCreated) {
      api.open({
        message: (
          <div className={styles.messageNotify}>Create Successfully!</div>
        ),
        description: (
          <div className={styles.descripNotify}>
            Component has been created successfully.
          </div>
        ),
        icon: (
          <div className={styles.iconNotify}>
            <StoryEditEntCreated className={styles.iconSuccessNotify} />
          </div>
        ),
        duration: 2,
      });
    }
  }, [isCreated]);

  return <>{contextHolder}</>;
}

export default CreateEntitySuccessNotify;
